<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="bg-white shadow-lg rounded-lg" @submit.prevent="handleSubmit(update)">
        <div class="p-10">
          <div class="pb-2 mb-4 border-b border-gray-200">
            <h2 class="text-gray-800 text-2xl font-medium">Edit Role</h2>
          </div>
          <div class="w-full mb-4">
            <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
              <label for="role_name" class="label-control">Name <span class="text-red-600">*</span></label>
              <input id="role_name" ref="role_name" type="text" v-model="roleName" maxlength="255" class="form-control" autofocus>
              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
            </ValidationProvider>
            <div v-if="error.role_name" class="text-sm text-red-600"><span>{{ error.role_name[0] }}</span></div>              
          </div>
          <div class="w-full mb-4">
            <ValidationProvider name="Description" rules="" v-slot="{ errors }">
              <label for="description" class="label-control">Description</label>
              <textarea id="description" v-model="description" class="form-control" rows="3"></textarea>
              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
            </ValidationProvider>
            <div v-if="error.description" class="text-sm text-red-600"><span>{{ error.description[0] }}</span></div>                   
          </div>
          <div class="w-full">
            <div class="pb-2 mb-2 border-b border-gray-200">
              <h3 class="font-bold">Permissions</h3>
            </div>
            <div class="w-full mb-6">
              <template v-for="masterItem in userPermissions">
                <div :key="masterItem.name" class="mb-4">
                  <label class="py-2 font-semibold uppercase label-control">{{ masterItem.name }}</label>
                  <template v-for="parentItem in masterItem.items">
                    <div :key="parentItem.group_key" class="ml-6">
                      <input type="checkbox" class="checkbox-purple" :value="parentItem.name" :id="parentItem.name" @change="checkChild(parentItem.name, parentItem.items, $event)">
                      <label :for="parentItem.name" class="inline-label-control cursor-pointer font-medium uppercase"> {{ parentItem.name }}</label>
                      <div class="flex gap-2">
                        <template v-for="(item, index) in parentItem.items">
                          <div :key="index" class="w-36 mb-4">
                            <input type="checkbox" class="checkbox-purple" v-model="checkedPermissions" :value="item.group_key+'-'+item.key" :id="item.group_key+'-'+item.key">
                            <label :for="item.group_key+'-'+item.key" class="inline-label-control cursor-pointer"> {{ item.permission_name }}</label>
                          </div>
                        </template> 
                      </div>                     
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </div>                  
          <div class="md:flex gap-4 mt-4 mb-2">
            <button :disabled="isLoading" type="submit" class="btn btn--primary" alt="Update" title="Update">
              <span v-if="isLoading" class="block mx-5">
                <Loading />
              </span>
              <SaveIcon v-else>
                <span class="ml-2 md:block hidden">Update</span>
              </SaveIcon>              
            </button>
            <button :disabled="isLoading" type="button" @click="goBack()" class="btn btn--secondary" alt="Back" title="Back">
              <BackIcon>
                <span class="ml-2 md:block hidden">Back</span>
              </BackIcon>              
            </button>
          </div>                                                  
        </div>
      </form>      
    </ValidationObserver>
  </div>
</template>

<script>
import roleServices from '@/services/role/roleServices'
import SaveIcon from '../../icons/SaveIcon'
import BackIcon from '../../icons/BackIcon'
import Loading from '../../icons/Loading'

export default {
  name: 'EditRole',
  components: {
    SaveIcon,
    BackIcon,
    Loading
  },  
  data(){
    return {
      roleName: '',
      description: '',  
      checkedPermissions: [],
      userPermissions: [],
      record: [],
      error: [],
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async fetchPermissionOptions() {
      try {
        const response = await roleServices.fetchPermissionOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.userPermissions = records
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },    
    async update(){
      try {
        this.isLoading = true

        const id = this.$route.params.id
        const payload = {
          role_name: this.roleName,
          description: this.description,
          permissions: this.checkedPermissions
        }
        const response = await roleServices.update(id, payload)
        if (response.data.status === 'success') {
          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          /* RETURN TO LIST */
          this.goBack()
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.unit) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },
    async fetchData(){
      try {
        const id = this.$route.params.id
        const response = await roleServices.fetchById(id)
        if (response.data.status === 'success') {
          this.record = response.data.data

          this.roleName = this.record.role_name
          this.description = this.record.description
          this.checkedPermissions = []
          this.record.permissions.forEach(element => {
            this.checkedPermissions.push(element.group_key+'-'+element.key)
          })
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)            
        }
      } catch (error) {
        console.log(error)
      }
    },
    checkChild(parent, items, event){
      items.forEach(element => {
        if (event.target.checked) {
          this.checkedPermissions.push(parent+'-'+element.key)
        } else {
          let value = parent+'-'+element.key
          this.checkedPermissions = this.checkedPermissions.filter(name => name !== value)
        }
      });
    },    
    clearForm(){
      this.roleName = ''
      this.description = ''
      this.checkedPermissions = []
    },
    goBack() {
      window.history.length > 1 ? this.$router.back() : this.$router.push('/roles')
    }        
  },
  created(){
    this.fetchPermissionOptions()
    this.fetchData()
  }
}
</script>