import ListAdjustment from '../components/feature/adjustment/List'
import CreateAdjustment from '../components/feature/adjustment/Create'
import EditAdjustment from '../components/feature/adjustment/Edit'
import ShowAdjustment from '../components/feature/adjustment/Show'

const routes = [
  {
    path: '/adjustments',
    name: 'adjustment',
    component: ListAdjustment,
    meta: {
      title: 'Adjustments',
      requiresAuth: true
    }    
  },
  {
    path: '/adjustments/create',
    name: 'adjustmentCreate',
    component: CreateAdjustment,
    meta: {
      title: 'Create Adjustment',
      requiresAuth: true
    }    
  },
  {
    path: '/adjustments/:id/edit',
    name: 'adjustmentEdit',
    component: EditAdjustment,
    meta: {
      title: 'Edit Adjustment',
      requiresAuth: true
    }    
  },
  {
    path: '/adjustments/:id',
    name: 'adjustmentShow',
    component: ShowAdjustment,
    meta: {
      title: 'Show Adjustment',
      requiresAuth: true
    }    
  }  
]

export default routes