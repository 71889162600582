import ListStockOpname from '../components/feature/opname/List'
import CreateStockOpname from '../components/feature/opname/Create'
import EditStockOpname from '../components/feature/opname/Edit'
import ShowStockOpname from '../components/feature/opname/Show'

const routes = [
  {
    path: '/stock-opname',
    name: 'opname',
    component: ListStockOpname,
    meta: {
      title: 'Stock Opname',
      requiresAuth: true
    }    
  },
  {
    path: '/stock-opname/create',
    name: 'opnameCreate',
    component: CreateStockOpname,
    meta: {
      title: 'Create Stock Opname',
      requiresAuth: true
    }    
  },
  {
    path: '/stock-opname/:id/edit',
    name: 'opnameEdit',
    component: EditStockOpname,
    meta: {
      title: 'Edit Stock Opname',
      requiresAuth: true
    }    
  },
  {
    path: '/stock-opname/:id',
    name: 'opnameShow',
    component: ShowStockOpname,
    meta: {
      title: 'Show Stock Opname',
      requiresAuth: true
    }    
  }  
]

export default routes