import ListRate from '../components/feature/rate/List'
import CreateRate from '../components/feature/rate/Create'
import EditRate from '../components/feature/rate/Edit'
import ShowRate from '../components/feature/rate/Show'

const routes = [
  {
    path: '/rates',
    name: 'rate',
    component: ListRate,
    meta: {
      title: 'Exchange Rates',
      requiresAuth: true
    }    
  },
  {
    path: '/rates/create',
    name: 'rateCreate',
    component: CreateRate,
    meta: {
      title: 'Create Exchange Rate',
      requiresAuth: true
    }    
  },
  {
    path: '/rates/:id/edit',
    name: 'rateEdit',
    component: EditRate,
    meta: {
      title: 'Edit Exchange Rate',
      requiresAuth: true
    }    
  },
  {
    path: '/rates/:id',
    name: 'rateShow',
    component: ShowRate,
    meta: {
      title: 'Show Exchange Rate',
      requiresAuth: true
    }    
  }  
]

export default routes