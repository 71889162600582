import axios from '@/axios'

export default {
  fetchAll(params){
    return axios.get(`/receives/all`, {params})
  },
  fetchLimit(params){
    return axios.get(`/receives`, {params})
  },  
  fetchById(id){
    return axios.get(`/receives/${id}`)
  },    
  create(payload){
    return axios.post(`/receives`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  },
  update(id, payload){
    return axios.put(`/receives/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }      
    })
  },
  delete(id){
    return axios.delete(`/receives/${id}`)
  }, 
  deleteMultiple(payload){
    return axios.delete(`/receives`, payload)
  },
  validate(id){
    return axios.post(`/receives/validate/${id}`)
  },
  cancelValidation(id){
    return axios.post(`/receives/cancel/${id}`)
  },  
  import(id){
    return axios.post(`/receives/import/${id}`)
  },  
}