import ListPurchase from '../components/feature/purchase/List'
import CreatePurchase from '../components/feature/purchase/Create'
import EditPurchase from '../components/feature/purchase/Edit'
import ShowPurchase from '../components/feature/purchase/Show'

const routes = [
  {
    path: '/purchases',
    name: 'purchase',
    component: ListPurchase,
    meta: {
      title: 'Purchases',
      requiresAuth: true
    }    
  },
  {
    path: '/purchases/create',
    name: 'purchaseCreate',
    component: CreatePurchase,
    meta: {
      title: 'Create Purchase',
      requiresAuth: true
    }    
  },
  {
    path: '/purchases/:id/edit',
    name: 'purchaseEdit',
    component: EditPurchase,
    meta: {
      title: 'Edit Purchase',
      requiresAuth: true
    }    
  },
  {
    path: '/purchases/:id',
    name: 'purchaseShow',
    component: ShowPurchase,
    meta: {
      title: 'Show Purchase',
      requiresAuth: true
    }    
  }  
]

export default routes