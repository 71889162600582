<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="bg-white shadow-lg rounded-lg" @submit.prevent="handleSubmit(create)">
        <div class="p-10">
          <div class="pb-2 mb-4 border-b border-gray-200">
            <h2 class="text-gray-800 text-2xl font-medium">Create Collection</h2>
          </div>
          <div class="md:flex gap-6">
            <div class="md:flex-col w-full md:w-4/6">
              <div class="md:flex gap-6">
                <div class="md:w-1/2 mb-4">
                  <ValidationProvider name="Collection Name" rules="required" v-slot="{ errors }">
                    <label for="collection_name" class="label-control">Collection Name <span class="text-red-600">*</span></label>
                    <input id="collection_name" ref="collection_name" type="text" v-model="collection_name" class="form-control">
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.collection_name" class="text-sm text-red-600"><span>{{ error.collection_name[0] }}</span></div>
                </div>
                <div class="md:w-1/2 mb-4">
                  <ValidationProvider name="Collection Code" rules="required" v-slot="{ errors }">
                    <label for="collection_code" class="label-control">Collection Code <span class="text-red-600">*</span></label>
                    <input id="collection_code" type="text" v-model="collection_code" class="form-control">
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.collection_code" class="text-sm text-red-600"><span>{{ error.collection_code[0] }}</span></div>
                </div>
              </div>
              <div class="md:flex gap-6">
                <div class="w-full mb-4">
                  <ValidationProvider name="Description" rules="" v-slot="{ errors }">
                    <label for="description" class="label-control">Description</label>
                    <textarea id="description" v-model="description" class="form-control" rows="5"></textarea>
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.description" class="text-sm text-red-600"><span>{{ error.description[0] }}</span></div>                   
                </div>
              </div>
              <div class="md:flex gap-6">
                <div class="md:w-1/2 mb-4">
                  <ValidationProvider name="Status" rules="required" v-slot="{ errors }">
                    <label for="status" class="label-control">Status <span class="text-red-600">*</span></label>
                    <MultiSelect id="status" ref="status" v-model="status" :options="statusData" :value="status" track-by="id" label="name" :searchable="false" :showLabels="false">
                      <template slot="caret">
                        <div>
                          <div class="multiselect__select">
                            <span>
                              <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                                <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </template>
                    </MultiSelect>
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.status" class="text-sm text-red-600"><span>{{ error.status[0] }}</span></div>
                </div>
                <div class="md:w-1/2 mb-4"></div>
              </div>                             
            </div>
            <div class="md:flex-col w-full md:w-2/6">
              <div class="md:flex gap-6">
                <div class="w-full mb-4">
                  <label for="image" class="label-control">Image</label>
                  <div v-if="!image">
                    <div class="md:h-52 border-2 border-dashed border-gray-400 flex items-center justify-center p-2 rounded-lg mb-2">
                      <img class="m-auto" :src="fileBox" />
                    </div>
                    <input type="file" id="image" ref="image" @change="onFileChange">
                  </div>
                  <div v-else>
                    <div class="h-auto border-2 border-dashed border-gray-400 flex items-center justify-center p-2 rounded-lg mb-2">
                      <img class="m-auto" :src="image" />
                    </div>
                    <div v-if="error.image" class="text-sm text-red-600 mb-2"><span>{{ error.image[0] }}</span></div>
                    <button class="btn btn--danger" @click="removeImage">Remove Image</button>
                  </div>
                </div>
              </div>            
            </div>
          </div>          
          <div class="md:flex gap-4 mt-4 mb-2">
            <button :disabled="isLoading" type="submit" class="btn btn--primary" alt="Save" title="Save">
              <span v-if="isLoading" class="block mx-5">
                <Loading />
              </span>
              <SaveIcon v-else>
                <span class="ml-2 md:block hidden">Save</span>
              </SaveIcon>
            </button>
            <button :disabled="isLoading" type="button" @click="goBack()" class="btn btn--secondary" alt="Back" title="Back">
              <BackIcon>
                <span class="ml-2 md:block hidden">Back</span>
              </BackIcon>              
            </button>
          </div>                                                  
        </div>
      </form>      
    </ValidationObserver>
  </div>
</template>

<script>
import collectionServices from '@/services/collection/collectionServices'
import SaveIcon from '../../icons/SaveIcon'
import BackIcon from '../../icons/BackIcon'
import Loading from '../../icons/Loading'
import imageBox from '../../../assets/images/file-box.png'

export default {
  name: 'CreateCollection',
  components: {
    SaveIcon,
    BackIcon,
    Loading
  },
  data(){
    return {
      collection_name: '',
      collection_code: '',
      description: '',
      image: '',
      collectionImage: '',
      fileBox: imageBox,      
      status: '',
      statusData: [
        { id: 1, name: 'Active' },
        { id: 0, name: 'Inactive' }
      ],      
      error: [],
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async create(){
      try {
        this.isLoading = true
        let payload = new FormData()
        payload.append('collection_name', this.collection_name)
        payload.append('collection_code', this.collection_code)
        payload.append('description', this.description)
        payload.append('image', this.collectionImage)
        payload.append('status', this.status.id)
        
        const response = await collectionServices.create(payload)
        if (response.data.status === 'success') {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false
                    
          /* CLEAR INPUT FORM */
          this.clearForm()

          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          /* SET FOCUS ON NAME INPUT */
          this.$refs.collection_name.focus()
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.collection_name || responseReturn.collection_code || responseReturn.description || responseReturn.image || responseReturn.status) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },
    clearForm(){
      this.collection_name = ''
      this.collection_code = ''
      this.description = ''
      this.image = ''
      this.status = ''
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }

      this.createImage(files[0])
    },
    createImage(file) {
      let reader = new FileReader()
      let vm = this

      reader.onload = (e) => {
        vm.image = e.target.result
      }
      reader.readAsDataURL(file)
      this.collectionImage = this.$refs.image.files[0]
    },
    removeImage() {
      this.image = ''
    },
    goBack() {
      window.history.length > 1 ? this.$router.back() : this.$router.push('/collections')
    }       
  }
}
</script>