<template>
  <div>
    <!-- ACTION PANEL SECTION -->
    <div class="flex border-b border-gray-200 text-gray-800 justify-between items-center mb-4 pb-2">
      <div class="md:w-1/2 w-full items-center">
        <h2 class="text-2xl font-medium">Purchase Order</h2>
      </div>
      <div class="flex items-center right-0">
        <router-link :to="{ name: 'purchaseCreate' }" class="btn btn--primary">
          <PlusIcon>
            <span class="ml-2 md:block hidden">Create</span>
          </PlusIcon>
        </router-link>   
      </div>
    </div>
    <!-- TABLE FILTER SECTION -->
    <div class="flex pb-4 justify-between items-center">
      <div class="relative">
        <select v-model="take" @change="updateTake()" class="px-4 py-2 pr-8 appearance-none rounded-md shadow-lg text-sm bg-white focus:bg-white text-black placeholder-gray-500 border border-gray-200 focus:border-purple-500 focus:outline-none">
          <option v-for="show in shows" :key="show.number" :value="show.number" :selected="show.number === take ? 'selected' : ''">{{ show.name }}</option>
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
          <svg class="w-5 h-5 fill-current" viewBox="0 0 24 24">
          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z" />
          </svg>
        </div>
      </div>
      <div class="flex w-96">
        <div class="relative flex-1">
          <svg width="20" height="20" fill="currentColor" class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"><path fill-rule="evenodd" clip-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"></path></svg>
          <input type="text" ref="search" v-model="search" @input="runSearch()" aria-label="Search" placeholder="Search ( Press &quot;/&quot; to focus )" class="w-full text-sm text-black placeholder-gray-500 border border-gray-200 rounded-md py-2 pl-10 focus:border-purple-500 focus:outline-none">  
        </div>
      </div>
    </div>
    <!-- TABLE SECTION -->
    <div class="mb-4 bg-white shadow-lg rounded-lg overflow-y-auto">
      <table>
        <thead>
          <tr>
            <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">PO No</th>
            <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Supplier</th>
            <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Season</th>
            <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Period</th>
            <th scope="col" class="px-2 border-r border-b border-purple-300 border-opacity-50 text-center" colspan="3">Date</th>
            <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Total Qty</th>
            <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Amount</th>
            <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Status</th>
            <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Receive Status</th>
            <th scope="col" class="px-2 text-center" rowspan="2">Actions</th>
          </tr>
          <tr class="bg-purple-200 text-purple-600 uppercase text-sm leading-normal">
            <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center date-column">Purchase</th>
            <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center date-column">Wish Delivery</th>
            <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center date-column">Confirm Delivery</th>
          </tr>            
        </thead>
        <tbody>
          <tr v-if="totalRecords === 0" class="border-b border-gray-200 hover:bg-gray-100"><td class="text-center" colspan="10">No data to display</td></tr>
          <tr v-else-if="totalFiltered === 0" class="border-b border-gray-200 hover:bg-gray-100"><td class="text-center" colspan="10">No matching records found</td></tr>
          <tr v-for="(purchase, index) in purchases" :key="purchase.id" class="border-b border-gray-200 hover:bg-gray-100" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">
            <td class="px-2 text-left">
              <span @click="toggleDetailModal(purchase.record_no)" class="pb-1 cursor-pointer border-b border-green-500">{{ purchase.record_no }}</span>
            </td>
            <td class="px-2 text-left">
              <span class="block pb-2">{{ purchase.supplier.supplier_name }}</span>
              <template v-if="purchase.remarks">
                <span class="block text-xs uppercase">Remarks:</span>
                <span class="text-xs uppercase">{{ purchase.remarks }}</span>
              </template>
            </td>
            <td class="px-2 text-left">{{ purchase.season.season_name }}</td>
            <td class="px-2 text-left">{{ purchase.period }}</td>
            <td class="px-2 text-left">{{ purchase.purchase_date }}</td>
            <td class="px-2 text-left">{{ purchase.wish_delivery_date }}</td>
            <td class="px-2 text-left">{{ purchase.confirm_delivery_date }}</td>
            <td class="px-2 text-right">{{ purchase.total_items }}</td>
            <td class="px-2 text-right">{{ formatNumber(purchase.grand_total) }} €</td>
            <td class="px-2 text-center">
              <span class="badge" :class="purchase.status === 1 ? 'badge--light-primary' : (purchase.status === 2 ? 'badge--success' : 'badge--danger')">{{ purchase.status_text }}</span>
            </td>
            <td class="px-2 text-center">
              <span class="badge" :class="purchase.receive_status === 3 ? 'badge--success' : (purchase.receive_status === 2 ? 'badge--warning' : 'badge--danger')">{{ purchase.receive_status_text }}</span>
            </td>
            <td class="px-2 text-center">
              <div class="flex item-center justify-center">
                <!-- Download Button Section -->
                <button @click="toggleModal(purchase.id)" type="button" class="btn-download" alt="Download" v-tooltip="'Download'">
                  <DownloadIcon />
                </button>
                <!-- Closed Button Section -->
                <button v-if="purchase.status == 2 && purchase.receive_status != 3" @click="toggleCloseModal(purchase.id, purchase.record_no)" type="button" class="btn-download" alt="Closed PO" v-tooltip="'Closed PO'">
                  <CloseIcon />
                </button>                
                <!-- Show Button Section -->
                <router-link :to="{ name: 'purchaseShow', params: { id: purchase.id } }" class="btn-show" alt="Show" v-tooltip="'Show'">
                  <ShowIcon />
                </router-link>
                <!-- Edit Button Section -->          
                <router-link :to="{ name: 'purchaseEdit', params: { id: purchase.id } }" class="btn-edit" alt="Edit" v-tooltip="'Edit'">
                  <EditIcon />
                </router-link>
                <!-- Delete Button Section -->
                <button v-if="purchase.status === 1" @click="confirmDialog( purchase.id )" type="button" class="btn-delete" alt="Delete" v-tooltip="'Delete'">
                  <DeleteIcon />
                </button>
                <button v-else type="button" class="btn-delete" alt="Delete not allowed" v-tooltip="'Delete not allowed'">
                  <DeleteIcon />
                </button>
              </div>
            </td>
          </tr>
        </tbody>                              
      </table>
      <div class="flex px-5 py-5 bg-white justify-between items-center">
        <div class="flex-shrink text-sm">
          <span>Showing {{ fromRecord }} to {{ toRecord }} of {{ totalFiltered }} Entries </span><span v-if="totalFiltered !== totalRecords"> ( filtered from {{ totalRecords }} total entries )</span>
        </div>
        <div class="pagination">
          <button type="button" :disabled="(currentPage - 1) === 0" @click="prevPage" class="page-item">
            <span class="text-sm">Prev</span>
          </button>
          <button type="button" :disabled="currentPage === lastPage || currentPage > lastPage" @click="nextPage" class="page-item">
            <span class="text-sm">Next</span>
          </button>
        </div>
      </div>                            
    </div>
    <!-- REPORT FILTER & ACTIONS -->
    <div class="fixed bg-gray-800 bg-opacity-25 w-full h-full inset-0 top-16 md:top-0 z-50" :class="{ 'hidden': !showFilter }">
      <div class="w-80 ml-auto h-full overflow-y-scroll bg-white shadow-lg right-0">
        <div class="flex p-5 border-b border-gray-200 items-center">
          <span class="flex-1 text-sm font-medium">Filters & Sort</span>
          <button type="button" class="flex right-0" @click="toggleFilter">
            <svg class="h-5 w-5 fill-current hover:text-gray-700" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
            </svg>
          </button>
        </div>
        <div class="p-5 items-center">
          <!-- LOCATION FILTER REPORT -->
          <div class="w-full mb-2">
            <div class="w-full mb-2">
              <label for="supplier" class="label-control">Supplier</label>
              <MultiSelect id="supplier" v-model="supplier" :options="supplierOptions" :value="supplier" track-by="id" label="name" :showLabels="false">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>
            </div>            
            <div class="flex w-full gap-2">
              <div class="w-1/2 mb-2">
                <label for="season" class="label-control">Season</label>
                <MultiSelect id="season" v-model="season" :options="seasonOptions" :value="season" track-by="id" label="name" :showLabels="false" placeholder="Tap to Search">
                  <template slot="caret">
                    <div>
                      <div class="multiselect__select">
                        <span>
                          <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                            <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </template>
                </MultiSelect>                
              </div>
              <div class="w-1/2 mb-2">
                <label for="period" class="label-control">Period</label>
                <MultiSelect id="period" ref="period" v-model="period" :options="periodOptions" :value="period" :searchable="true" track-by="id" label="name" :showLabels="false" placeholder="Tap to Search">
                  <template slot="caret">
                    <div>
                      <div class="multiselect__select">
                        <span>
                          <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                            <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </template>
                </MultiSelect>                
              </div>
            </div>
            <div class="w-full mb-2">
              <label for="search_field" class="label-control">Search</label>
              <input id="search_field" type="text" class="form-control" v-model="search" @input="runSearch()">
            </div>                                   
            <div class="w-full mb-2">
              <label for="date_field" class="label-control">Field</label>
              <MultiSelect id="date_field" v-model="dateField" :options="dateFields" :value="dateField" track-by="field" label="name" :allowEmpty="false" :showLabels="false">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>                 
            </div>             
            <v-date-picker ref="calendar" v-model="filterDate" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs" is-range>
              <template v-slot="{ inputValue, inputEvents, isDragging }">
                <div class="flex gap-2">
                  <div class="w-1/2 mb-2">                        
                    <label for="startDate" class="label-control">Start Date</label>
                    <div class="relative flex justify-between items-center">
                      <input id="startDate" ref="startDate" type="text" class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" autofocus>
                      <span class="h-full absolute pointer-events-none right-0">
                        <DateRange class="m-3" />
                      </span>                      
                    </div>
                  </div>
                  <div class="w-1/2 mb-2">
                    <label for="endDate" class="label-control">End Date</label>
                    <div class="relative flex justify-between items-center">
                      <input id="endDate" ref="endDate" type="text" class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end">
                      <span class="h-full absolute pointer-events-none right-0">
                        <DateRange class="m-3" />
                      </span>                      
                    </div>
                  </div>                  
                </div>
              </template>
            </v-date-picker>              
          </div>
          <div class="w-full mb-4">
            <div class="pb-2 mb-2 border-b border-gray-200">
              <span class="text-gray-800 font-medium text-sm">Sort By</span>
            </div>
            <div class="w-full mb-2">
              <label for="sort_field" class="label-control">Field</label>
              <MultiSelect id="sort_field" v-model="sortField" :options="sortFields" :value="sortField" :searchable="true" track-by="field" label="name" :allowEmpty="false" :showLabels="false" placeholder="Type to Search">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>                 
            </div>
            <div class="w-full">
              <label for="sort_option" class="label-control">Option</label>
              <MultiSelect id="sort_option" v-model="sortOption" :options="sortOptions" :value="sortOption" track-by="field" label="name" :allowEmpty="false">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>                 
            </div>
          </div>
          <!-- ACTION BUTTON -->
          <div class="w-full md:flex gap-2">
            <button :disabled="isLoading" @click.prevent="runFilter()" type="button" class="btn btn--primary" alt="Apply" v-tooltip="'Apply Data Filters'">
              <span v-if="isLoading" class="block">
                Loading...
              </span>
              <span :class="{ 'hidden' : isLoading }">Apply</span>
            </button>
            <button :disabled="isLoading" type="button" class="ml-1 sm:-pt-10 btn btn--secondary" @click="clearInput()">
              <span>Clear</span>           
            </button>
          </div>                    
        </div>
      </div>            
    </div>
    <div class="fixed top-0 bottom-0 right-0 z-20">
      <div class="absolute w-auto right-0 top-40 bg-green-500 text-white rounded-l-md shadow-lg" :class="{ 'hidden': showFilter }">
        <button class="block text-sm ml-1 px-2 py-4 cursor-pointer" v-tooltip="'Show Data Filters'" @click="toggleFilter">
          <FilterIcon></FilterIcon>
        </button>           
      </div>
    </div>    
    <!-- Export Modal Dialog -->
    <modal :show="showModal" @close="showModal = false" addClass="modal-xl" modalOrientation="pt-16 md:pt-10">
      <template v-slot:header><h3>{{ modalTitle }}</h3></template>
      <template v-slot:body>
        <div class="flex items-center justify-between mb-6">
          <div class="items-center"></div>
          <div class="items-center right-0">
            <button :disabled="isLoading" type="button" class="btn btn--danger" @click="downloadFile(exportID, 'pdf')">
              <span v-if="isLoading" class="block">
                Wait...
              </span>
              <span :class="{ 'hidden' : isLoading }">Save as PDF</span>
            </button>
            <button :disabled="isLoading" type="button" class="btn btn--success ml-2" @click="downloadFile(exportID, 'xlsx')">
              <span :disabled="isLoading">Save as XLS</span>
            </button>               
          </div>
        </div>
        <div class="mb-4 bg-white overflow-hidden">
          <div v-if="exportData" class="w-full">
            <div class="w-full mb-4">
              <h1 class="text-2xl text-right">PURCHASE ORDER</h1>
            </div>
            <div class="w-full md:flex justify-between mb-4 gap-4">
              <div class="md:w-1/2">
                <h1 class="block text-lg">DU JEU DE BOULES</h1>
                <span class="block text-sm">23 RUE DES BLANCS MANTEAUX, 75004 PARIS</span>
                <span class="block text-sm">FRANCE</span>
                <span class="block text-sm">Phone Number: 0033 153 36 07 79</span>
              </div>
              <div class="flex md:w-1/2 gap-6">
                <div class="w-full md:w-4/6">
                  <span class="block text-sm text-right">DATE</span>
                  <span class="block text-sm text-right">PO NUMBER</span>
                </div>
                <div class="w-full md:w-2/6">
                  <span class="block text-sm">: {{ exportData.purchase_date }}</span>
                  <span class="block text-sm">: {{ exportData.record_no }}</span>                
                </div>
              </div>
            </div>
            <div class="w-full md:flex justify-between mb-4 gap-32">
              <div class="md:w-1/2">
                <h1 class="p-1 block text-sm bg-gray-200">VENDOR</h1>
                <span class="block font-semibold text-sm">{{ exportData.supplier.supplier_name }}</span>
                <span class="block text-sm">{{ exportData.supplier.address }}</span>
                <span class="block text-sm">{{ exportData.supplier.city }}, {{ exportData.supplier.postal_code }}</span>
              </div>
              <div class="md:w-1/2">
                <h1 class="p-1 block text-sm bg-gray-200">SHIP TO</h1>
                <span class="block font-semibold text-sm">DU JEU DE BOULES</span>
                <span class="block text-sm">23 RUE DES BLANCS MANTEAUX, 75004 PARIS</span>
                <span class="block text-sm">FRANCE</span>
                <span class="block text-sm">Phone Number: 0033 153 36 07 79</span>
              </div>
            </div>
            <div class="w-full bg-gray-200 text-center p-2 mb-2">
              <span class="block font-semibold text-sm mb-1">WISH DELIVERY DATE</span>
              <span class="block text-sm">{{ exportData.wish_delivery_date }}</span>
            </div>
            <div class="w-full mb-4">
              <table>
                <thead class="bg-gray-100">
                  <tr>
                    <th scope="col" class="py-1 px-1 text-center text-gray-700 border border-gray-200">No</th>
                    <th scope="col" class="py-1 px-1 text-center text-gray-700 border border-gray-200">Reference</th>
                    <th scope="col" class="py-1 px-1 text-center text-gray-700 border border-gray-200">Size</th>
                    <th scope="col" class="py-1 px-1 text-center text-gray-700 border border-gray-200">Color</th>
                    <th scope="col" class="py-1 px-1 text-center text-gray-700 border border-gray-200">Qty</th>
                    <th scope="col" class="py-1 px-1 text-center text-gray-700 border border-gray-200">Unit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="exportData.details.length === 0"><td class="py-3 px-1 text-xs text-left border border-gray-200" colspan="6"></td></tr>
                  <tr v-for="(item, index) in exportData.details" :key="item.id">
                    <td class="py-1 px-1 text-xs text-center border border-gray-200">{{ index+1 }}</td>
                    <td class="py-1 px-1 text-xs text-left border border-gray-200">{{ item.material_name }}</td>
                    <td class="py-1 px-1 text-xs text-left border border-gray-200">{{ item.size }}</td>
                    <td class="py-1 px-1 text-xs text-left border border-gray-200">{{ item.color }}</td>
                    <td class="py-1 px-1 text-xs text-right border border-gray-200">{{ item.qty }}</td>
                    <td class="py-1 px-1 text-xs text-center border border-gray-200">{{ item.unit }}</td>
                  </tr>
                </tbody>
              </table>              
            </div>
            <div class="w-full">
              <div class="w-full mb-1"><span class="text-sm">REMARKS:</span></div>
              <div class="w-full">
                <p class="text-sm">{{ exportData.remarks }}</p>
              </div>
            </div>
          </div>
        </div>   
      </template>
      <template v-slot:footer>
      </template> 
    </modal>
    <DetailModal ref="detailModal"/>
    <CloseModal ref="closeModal"/>
  </div>
</template>

<script>
import format from '@/helpers/formatNumber'
import purchaseServices from '@/services/purchase/purchaseServices'
import seasonServices from '@/services/season/seasonServices'
import supplierServices from '@/services/supplier/supplierServices'
import PlusIcon from '../../icons/PlusIcon'
import DateRange from '../../icons/DateRange'
import ShowIcon from '../../icons/ShowIcon'
import EditIcon from '../../icons/EditIcon'
import DeleteIcon from '../../icons/DeleteIcon'
import CloseIcon from '../../icons/CloseIcon'
import Modal from '../../widgets/Modal'
import DownloadIcon from '../../icons/DownloadIcon'
import FilterIcon from '../../icons/Filter2Icon'
import DetailModal from '../purchase/Modal'
import CloseModal from '../purchase/Close'

export default {
  name: 'ListPurchase',
  components: {
    PlusIcon,
    DateRange,
    ShowIcon,
    EditIcon,
    DeleteIcon,
    CloseIcon,
    Modal,
    DownloadIcon,
    FilterIcon,
    DetailModal,
    CloseModal
  },
  data() {
    return {
      take: 25,
      page: 1,
      search: null,
      dateField: { field: 'created_at', name: 'Created Date' },
      dateFields: [
        { field: 'created_at', name: 'Created Date' },
        { field: 'updated_at', name: 'Updated Date' },
        { field: 'purchase_date', name: 'Purchase Date' },
        { field: 'wish_delivery_date', name: 'Wish Delivery Date' },
        { field: 'confirm_delivery_date', name: 'Confirm Delivery Date' }
      ],
      filterDate: {
        start: '',
        end: ''
      },
      masks: {
        input: 'YYYY/MM/DD',
      },
      attrs: [
        {
          key: 'today',
          highlight: 'red',
          dates: new Date(),
        },
      ],
      sortField: { field: 'id', name: 'ID (Default)' },
      sortFields: [
        { field: 'record_no', name: 'PO No' },
        { field: 'purchase_date', name: 'Purchase Date' },
        { field: 'delivery_date', name: 'Delivery Date' },
        { field: 'total_items', name: 'Items' },
        { field: 'grand_total', name: 'Amount' },
        { field: 'status', name: 'Status' },
        { field: 'updated_at', name: 'Updated' },
        { field: 'id', name: 'ID (Default)' }
      ],        
      sortOption: { field: 'desc', name: 'Descending' },
      sortOptions: [
        { field: 'asc', name: 'Ascending' },
        { field: 'desc', name: 'Descending' }
      ],
      supplier: '',
      supplierOptions: [],
      supplierField: '',         
      season: '',
      seasonOptions: [],
      seasonField: '',
      period: '',
      periodOptions: [
        { 'id': 1, 'name': 'Period 1' }, { 'id': 2, 'name': 'Period 2' }, { 'id': 3, 'name': 'Period 3' },
        { 'id': 4, 'name': 'Period 4' }, { 'id': 5, 'name': 'Period 5' }, { 'id': 6, 'name': 'Period 6' }
      ],
      perioField: '',   
      awaitingSearch: false,
      totalRecords: null,
      totalFiltered: null,
      fromRecord: null,
      toRecord: null,
      currentPage: null,
      lastPage: null,
      shows: [],
      purchases: [],
      error: [],
      showFilter: false,
      isLoading: false,
      exportID: '',
      exportData: null,
      modalTitle: '',
      showModal: false,      
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async fetchSupplierData() {
      try {
        const response = await supplierServices.fetchDataOptions()
        if (response.data.status === 'success') {
          const records = response.data.data
          this.supplierOptions = []
          records.forEach(element => {
            this.supplierOptions.push({
              id: element.id,
              name: element.supplier_name
            })
          })
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchSupplierById() {
      try {
        const id = this.supplierField || ''
        const response = await supplierServices.fetchById(id)
        if (response.data.status === 'success') {
          const record = response.data.data
          this.supplier = { 'id': record.id, 'name': record.supplier_name }
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },    
    async fetchSeasonData() {
      try {
        const response = await seasonServices.fetchDataOptions()
        if (response.data.status === 'success') {
          const records = response.data.data
          this.seasonOptions = []
          records.forEach(element => {
            this.seasonOptions.push({
              id: element.id,
              name: element.season_name
            })
          })
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchSeasonById() {
      try {
        const id = this.seasonField || ''
        const response = await seasonServices.fetchById(id)
        if (response.data.status === 'success') {
          const record = response.data.data
          this.season = { 'id': record.id, 'name': record.season_name }
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchData() {
      try {
        this.isLoading = true

        const params = {
          take: this.take,
          page: this.page,
          search: this.search,
          supplier: this.supplierField,
          season: this.seasonField,
          period: this.period || '',
          date_field: this.dateField.field,
          start: this.filterDate.start || '',
          end: this.filterDate.end || '',
          sort_field: this.sortField.field,
          sort_option: this.sortOption.field
        }
        const response = await purchaseServices.fetchLimit(params)
        if (response.data.status === 'success') {
          this.isLoading =false

          const records = response.data.data
          this.totalRecords = records.total
          this.totalFiltered = records.total_filter
          this.fromRecord = records.from
          this.toRecord = records.to
          this.currentPage = records.current_page
          this.lastPage = records.last_page
          this.shows = records.show
          this.purchases = records.data
        } else {
          this.isLoading =false

          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        this.isLoading =false
        console.log(error)
      }
    },
    async fetchById(id) {
      try {
        this.isLoading = true

        const response = await purchaseServices.fetchById(id)
        if (response.data.status === 'success') {
          this.isLoading =false

          this.exportID = id
          this.exportData = response.data.data
        } else {
          this.isLoading =false

          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        this.isLoading =false
        console.log(error)
      }
    },    
    async delete(id) {
      try {
        const response = await purchaseServices.delete(id)
        return response.data
      } catch (error) {     
        return error
      }
    },
    async confirmDialog(id) {
      // console.log(id)
      this.$swal.fire({
        title: 'Are you sure ?',
        text: "You won't be able to revert this!",
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#4b5563',
        confirmButtonText: 'Yes'
      }).then(async (result) => {
        if (result.isConfirmed) {
          /* CALL DELETE FUNCTION */
          const result = this.delete(id)
          const response = await result

          if (response.status) {
            if (response.status === 'success') {
              /* REFRESH DATA */
              this.fetchData()

              this.$swal.fire({
                title: 'Deleted!',
                text: "Your record has been deleted",
                icon: 'success',
                showConfirmButton: false,
                timer: 3000            
              })              
            } else {
              this.$swal.fire({
                title: 'Error!',
                text: response.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 3000            
              })              
            }
          } else {
            this.$toasted.error(response.message, this.toastedOptions)           
          }
        }
      })
    },
    updateQueryString() {
      const supplier = this.supplierField || ''
      const season = this.seasonField || ''
      const period = this.period ? this.period.id : ''      
      const search = this.search ? this.search.toLowerCase() : ''
      const start = this.filterDate.start ? new Date(this.filterDate.start).toISOString().slice(0,10) : ''
      const end = this.filterDate.end ? new Date(this.filterDate.end).toISOString().slice(0,10) : ''
      const page = this.page

      this.$router.replace({ 
        query: {
          take: this.take,
          page: page,
          search: search,
          supplier: supplier,
          season: season,
          period: period,
          date_field: this.dateField.field,
          start: start,
          end: end,
          sort_field: this.sortField.field,
          sort_option: this.sortOption.field
        } 
      }).catch(() => {})
    },
    clearInput() {
      this.supplier = []
      this.season = []
      this.period = []
      this.search = ''      
      this.filterDate = {}
      this.runFilter()
    },
    toggleFilter() {
      this.showFilter = !this.showFilter
    },    
    runFilter() {
      this.page = 1
      this.updateQueryString()
      this.fetchData()
    },
    searchFocus(event) {
      if (event.keyCode === 191) {
        setTimeout(() => {
          this.$refs.search.focus()
        }, 5)
      }
    },
    prevPage() {
      this.page = this.currentPage - 1
      this.updateQueryString()
      this.fetchData()
    },
    nextPage() {
      this.page = this.currentPage + 1
      this.updateQueryString()
      this.fetchData()
    },
    updateTake() {
      this.page = 1
      this.updateQueryString()
      this.fetchData()      
    },
    runSearch() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.page = 1
          this.updateQueryString()
          this.fetchData()
          this.awaitingSearch = false
        }, 1500)
      }
      this.awaitingSearch = true
    },        
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    },
    toggleModal(id) {
      this.showModal = true
      this.modalTitle = 'Export'
      this.fetchById(id)
    },
    toggleDetailModal(number) {
      this.$refs.detailModal.toggleModal(number)
    },
    toggleCloseModal(id, number) {
      this.$refs.closeModal.toggleModal(id, number)
    },    
    openDownloadTab(key) {
      let baseUrl = window.location.origin
      window.open(baseUrl+'/auth/download?key='+key, '_blank')
    },    
    async downloadFile(id, fileType) {
      try {
        this.isLoading = true
        const payload = {
          file_type: fileType
        }
        const response = await purchaseServices.export(id, payload)
        if (response.data.status === 'success') {
          this.isLoading = false
          this.$toasted.success(response.data.message, this.toastedOptions)

          const key = response.data.data
          this.openDownloadTab(key)
        } else {
          this.isLoading = false
          this.$toasted.error(response.data.message, this.toastedOptions)
        }
      } catch (error) {
        this.isLoading = false
        console.log(error)
      }
    }    
  },
  created() {
    this.fetchSupplierData()
    if (this.$route.query.supplier) {
      this.fetchSupplierById()
    }    
    this.fetchSeasonData()
    if (this.$route.query.season) {
      this.fetchSeasonById()
    }
    this.fetchData()
    document.addEventListener("keydown", this.searchFocus);
  },
  destroyed() {
    document.removeEventListener("keydown", this.searchFocus);
  },
  watch: {
    supplier: function() {
      this.supplierField = this.supplier ? this.supplier.id : ''
    },     
    season: function() {
      this.seasonField = this.season ? this.season.id : ''
    },
    period: function() {
      this.periodField = this.period ? this.period.id : ''
    },   
    '$route.query.take': {
      handler: function(take) {
        if (take) {
          this.take = take
        }
      },
      immediate: true
    },    
    '$route.query.page': {
      handler: function(page) {
        this.page = page
      },
      immediate: true
    },
    '$route.query.search': {
      handler: function(search) {
        this.search = search
      },
      immediate: true
    },
    '$route.query.supplier': {
      handler: function(supplier) {
        this.supplierField = supplier
      },
      immediate: true
    },     
    '$route.query.season': {
      handler: function(season) {
        this.seasonField = season
      },
      immediate: true
    },
    '$route.query.period': {
      handler: function(period) {
        try {
          const option = this.periodOptions.filter(item => period.includes(item.id))
          this.period = { id: option[0].id, name: option[0].name }
        } catch (error) {
          this.period = ''
        }
      },
      immediate: true
    },   
    '$route.query.date_field': {
      handler: function(date_field) {
        try {
          const option = this.dateFields.filter(item => date_field.includes(item.field))
          this.dateField = { field: option[0].field, name: option[0].name }          
        } catch (error) {
          this.dateField = { field: 'created_at', name: 'Created Date' }        
        }
      },
      immediate: true
    },
    '$route.query.start': {
      handler: function(start) {
        this.filterDate.start = start
      },
      immediate: true
    },
    '$route.query.end': {
      handler: function(end) {
        this.filterDate.end = end
      },
      immediate: true
    },
    '$route.query.sort_field': {
      handler: function(sort_field) {
        try {
        const option = this.sortFields.filter(item => sort_field.includes(item.field))
        this.sortField = { field: option[0].field, name: option[0].name }          
        } catch (error) {
          this.sortField = { field: 'id', name: 'Latest' }
        }
      },
      immediate: true
    },
    '$route.query.sort_option': {
      handler: function(sort_option) {
        try {
          const option = this.sortOptions.filter(item => sort_option.includes(item.field))
          this.sortOption = { field: option[0].field, name: option[0].name }          
        } catch (error) {
          this.sortOption = { field: 'desc', name: 'Descending' }
        }
      },
      immediate: true
    } 
  }
}
</script>