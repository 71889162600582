<template>
  <div>
    <!-- ACTION PANEL SECTION -->
    <div class="flex border-b border-gray-200 text-gray-800 justify-between items-center mb-4 pb-2">
      <div class="md:w-1/2 w-full items-center">
        <h2 class="text-2xl font-medium">Order Reports</h2>
      </div>
      <div class="flex items-center right-0">
      </div>
    </div>
    <div class="bg-white shadow-lg rounded-lg">
      <div class="p-8">
        <div class="flex justify-between text-sm mb-2">
          <div class="w-full md:w-1/2">
            <span v-if="isFilterYear === false">Date Range : {{ new Date(filterDate.start).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' }) }} - {{ new Date(filterDate.end).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' }) }}</span>
            <span v-if="isFilterYear === true">Range : {{ filterYear.start }} - {{ filterYear.end }}</span>          
          </div>
          <div class="w-full md:w-1/2 text-right">
            <span>Report : {{ report }}</span>
          </div>
        </div>
        <!-- TABLE SECTION -->
        <div class="mb-4 bg-white shadow-lg rounded-lg overflow-y-auto">
          <!-- ORDER REPORT SECTION -->
          <template v-if="report === 'Order Report'">
            <table class="min-w-max w-full table-auto">
              <thead>
                <tr class="bg-purple-200 text-purple-600 uppercase text-sm leading-normal">
                  <th scope="col" class="px-3 text-left">Collection</th>
                  <th scope="col" class="px-3 text-left">Barcode</th>
                  <th scope="col" class="px-3 text-left">Ref</th>
                  <th scope="col" class="px-3 text-left">Color</th>
                  <th scope="col" class="px-3 text-left">Size</th>
                  <th scope="col" class="px-3 text-left">Description</th>
                  <th scope="col" class="px-3 text-center">Qty</th>
                  <th scope="col" class="px-3 text-center">Unit</th>
                </tr>                                            
              </thead>
              <tbody class="text-gray-600 text-xs font-light">
                <tr v-if="reportData.length === 0" class="border-b border-gray-200 hover:bg-gray-100"><td class="py-3 px-6 text-sm text-center" colspan="8">No data to display</td></tr>
                <template v-for="(data, index) in reportData">
                  <tr :key="index" class="border-b">
                    <td class="px-3 text-left font-semibold" colspan="8">{{ data.order_no }} - {{ data.customer_name }} ({{ data.country }}) | {{ data.type === '1' ? 'RETAIL' : 'WHOLESALE' }}</td>
                  </tr>
                  <tr v-for="(item, idx) in data.details" :key="idx+'-'+item.id" class="border-b border-gray-200 hover:bg-gray-100" :class="{ 'bg-gray-50' : (idx % 2 !== 0) }">
                    <td class="px-3 py-2 text-left">{{ item.collection_code }}</td>
                    <td class="px-3 py-2 text-left">{{ item.barcode }}</td>
                    <td class="px-3 py-2 text-left">{{ item.reference }}</td>
                    <td class="px-3 py-2 text-left">{{ item.color }}</td>
                    <td class="px-3 py-2 text-left">{{ item.size }}</td>
                    <td class="px-3 py-2 text-left">{{ item.description }}</td>
                    <td class="px-3 py-2 text-right">{{ formatNumber(item.qty) }}</td>
                    <td class="px-3 py-2 text-left">{{ item.unit }}</td>
                  </tr>
                </template>
                <tr>
                  <td class="px-3 text-left font-semibold" colspan="6">GRAND TOTAL</td>
                  <td class="px-3 text-right font-semibold">{{ grandTotal }}</td>
                  <td class="px-3 text-left font-semibold"></td>
                </tr>                
              </tbody>
            </table>
          </template>
          <!-- ORDER SUMMARY SECTION -->
          <template v-else-if="report === 'Order Summary'">
            <table class="min-w-max w-full table-auto">
              <thead>
                <tr class="bg-purple-200 text-purple-600 uppercase text-sm leading-normal">
                  <th scope="col" class="px-3 text-left">Collection</th>
                  <th scope="col" class="px-3 text-left">Barcode</th>
                  <th scope="col" class="px-3 text-left">Ref</th>
                  <th scope="col" class="px-3 text-left">Color</th>
                  <th scope="col" class="px-3 text-left">Size</th>
                  <th scope="col" class="px-3 text-left">Description</th>
                  <th scope="col" class="px-3 text-center">Qty</th>
                  <th scope="col" class="px-3 text-center">Unit</th>
                </tr>                                            
              </thead>
              <tbody class="text-gray-600 text-xs font-light">
                <tr v-if="reportData.length === 0" class="border-b border-gray-200 hover:bg-gray-100"><td class="py-3 px-6 text-sm text-center" colspan="8">No data to display</td></tr>
                <tr v-for="(item, index) in reportData" :key="index" class="border-b border-gray-200 hover:bg-gray-100" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">
                  <td class="px-3 py-2 text-left">{{ item.collection_code }}</td>
                  <td class="px-3 py-2 text-left">{{ item.barcode }}</td>
                  <td class="px-3 py-2 text-left">{{ item.reference }}</td>
                  <td class="px-3 py-2 text-left">{{ item.color }}</td>
                  <td class="px-3 py-2 text-left">{{ item.size }}</td>
                  <td class="px-3 py-2 text-left">{{ item.description }}</td>
                  <td class="px-3 py-2 text-right">{{ formatNumber(item.qty) }}</td>
                  <td class="px-3 py-2 text-left">{{ item.unit }}</td>
                </tr>
                <tr>
                  <td class="px-3 text-left font-semibold" colspan="6">GRAND TOTAL</td>
                  <td class="px-3 text-right font-semibold">{{ grandTotal }}</td>
                  <td class="px-3 text-left font-semibold"></td>
                </tr>                
              </tbody>
            </table>
          </template>
          <!-- ORDER DETAILS SECTION -->
          <template v-else-if="report === 'Order Details'">
            <table class="min-w-max w-full table-auto">
              <thead>
                <tr class="bg-purple-200 text-purple-600 uppercase text-sm leading-normal">
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-left" rowspan="2">Season</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-left" rowspan="2">Period</th>                  
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-left" rowspan="2">Customer</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-left" rowspan="2">Order No</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-center date-column" rowspan="2">Delivery for Production</th>
                  <th scope="col" class="px-2 py-2 border-r border-b border-purple-300 border-opacity-50 text-center" colspan="2">Delivery for Customer</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-left" rowspan="2">Collection</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-left" rowspan="2">Barcode</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-left" rowspan="2">Ref</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-left" rowspan="2">Color</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-left" rowspan="2">Size</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-left" rowspan="2">Description</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Qty</th>
                  <th scope="col" class="px-2 py-2 text-center" rowspan="2">Unit</th>
                </tr> 
                <tr class="bg-purple-200 text-purple-600 uppercase text-xs leading-normal">
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-center date-column">From</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-center date-column">To</th>
                </tr>                                            
              </thead>
              <tbody class="text-gray-600 text-sm font-light">
                <tr v-if="reportData.length === 0" class="border-b border-gray-200 hover:bg-gray-100"><td class="py-3 px-6 text-sm text-center" colspan="15">No data to display</td></tr>
                <tr v-for="(item, index) in reportData" :key="index" class="border-b border-gray-200 hover:bg-gray-100" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">
                  <td class="px-2 py-2 text-xs text-left">{{ item.season_name }}</td>
                  <td class="px-2 py-2 text-xs text-left">{{ item.period }}</td>                  
                  <td class="px-2 py-2 text-xs text-left">{{ item.customer_name }}</td>
                  <td class="px-2 py-2 text-xs text-left">{{ item.order_no }}</td>
                  <td class="px-2 py-2 text-xs text-left">{{ item.delivery_date_production }}</td>
                  <td class="px-2 py-2 text-xs text-left">{{ item.delivery_date_from }}</td>
                  <td class="px-2 py-2 text-xs text-left">{{ item.delivery_date_to }}</td>
                  <td class="px-2 py-2 text-xs text-left">{{ item.collection_code }}</td>
                  <td class="px-2 py-2 text-xs text-left">{{ item.barcode }}</td>
                  <td class="px-2 py-2 text-xs text-left">{{ item.reference }}</td>
                  <td class="px-2 py-2 text-xs text-left">{{ item.color }}</td>
                  <td class="px-2 py-2 text-xs text-left">{{ item.size }}</td>
                  <td class="px-2 py-2 text-xs text-left">{{ item.description }}</td>
                  <td class="px-2 py-2 text-xs text-right">{{ formatNumber(item.qty) }}</td>
                  <td class="px-2 py-2 text-xs text-left">{{ item.unit }}</td>
                </tr>
                <tr>
                  <td class="px-3 text-left font-semibold" colspan="13">GRAND TOTAL</td>
                  <td class="px-3 text-right font-semibold">{{ grandTotal }}</td>
                  <td class="px-3 text-left font-semibold"></td>
                </tr>                
              </tbody>
            </table>
          </template>
          <!-- ORDER PERFORMANCE SECTION -->
          <template v-else-if="report === 'Order Performance'">
            <highcharts :options="chartOptions" ref="performanceCharts"></highcharts>
          </template>
        </div>        
      </div>
    </div>
    <!-- REPORT FILTER & ACTIONS -->
    <div class="fixed bg-gray-800 bg-opacity-25 w-full inset-0 top-16 md:top-0 z-50" :class="{ 'hidden': !showFilter }">
      <div class="w-80 ml-auto h-full overflow-y-scroll bg-white shadow-lg right-0">
        <div class="flex p-5 border-b border-gray-200 items-center">
          <span class="flex-1 text-sm font-medium">Report Filter</span>
          <button type="button" class="flex right-0" @click="toggleFilter">
            <svg class="h-5 w-5 fill-current hover:text-gray-700" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
            </svg>
          </button>
        </div>
        <div class="p-5 items-center">
          <!-- TYPE OF REPORT -->
          <div class="w-full mb-4">
            <ValidationProvider name="Report" rules="required" v-slot="{ errors }">
              <label for="report" class="label-control">Report Type <span class="text-red-600">*</span></label>
              <MultiSelect id="report" ref="report" v-model="report" :options="reports" :value="report" :searchable="true" placeholder="Type to Search" :showLabels="false">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>
              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
            </ValidationProvider>
            <div v-if="error.report" class="text-sm text-red-600"><span>{{ error.report[0] }}</span></div>
          </div>
          <!-- COLLECTION FILTER REPORT -->
          <div class="w-full mb-4">
            <label for="collection" class="label-control">Collection</label>
            <MultiSelect id="collection" ref="collection" v-model="collection" :options="collections" :value="collection" @input="fetchData()" :searchable="true" track-by="id" label="name" :showLabels="false" placeholder="Tap to Search">
              <template slot="caret">
                <div>
                  <div class="multiselect__select">
                    <span>
                      <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                        <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                      </svg>
                    </span>
                  </div>
                </div>
              </template>
            </MultiSelect>           
          </div>
          <!-- DATE FILTER REPORT -->
          <div v-if="isFilterYear === false" class="w-full mb-4">
            <ValidationProvider name="Filter Date" rules="required" v-slot="{ errors }">
              <v-date-picker ref="calendar" v-model="filterDate" mode="date" :masks="masks" color="purple" title-position="left" :attributes="attrs" is-range>
                <template v-slot="{ inputValue, inputEvents, isDragging }">
                  <label for="filter_date" class="label-control">Filter Date <span class="text-red-600">*</span></label>
                  <div class="flex flex-col sm:flex-row justify-start items-center gap-4">
                    <div class="w-full relative flex-grow mb-2 md:mb-0">
                      <input class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" readonly />
                      <span class="h-full absolute pointer-events-none right-0">
                        <DateRange class="m-3" />
                      </span>                  
                    </div>
                    <div class="w-full relative flex-grow">
                      <input class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end" readonly />
                      <span class="h-full absolute pointer-events-none right-0">
                        <DateRange class="m-3" />
                      </span>                  
                    </div>
                  </div>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </template>
              </v-date-picker>
            </ValidationProvider>
            <div v-if="error.start_date" class="text-sm text-red-600"><span>{{ error.start_date[0] }}</span></div>
            <div v-if="error.end_date" class="text-sm text-red-600"><span>{{ error.end_date[0] }}</span></div>
          </div>
          <!-- YEAR FILTER REPORT -->
          <div v-else-if="isFilterYear === true" class="w-full mb-6">
            <label for="filter_date" class="label-control">Filter Year <span class="text-red-600">*</span></label>
            <div class="flex gap-2">
              <div class="md:w-1/2">
                <ValidationProvider name="Start Year Filter" rules="numeric" v-slot="{ errors }">
                  <input v-model="filterYear.start" @input="handleInputYear($event)" class="form-control text-right" maxlength="4">
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="error.start_date" class="text-sm text-red-600"><span>{{ error.start_date[0] }}</span></div>                
              </div>
              <div class="md:w-1/2">
                <ValidationProvider name="End Year Filter" rules="numeric" v-slot="{ errors }">
                  <input v-model="filterYear.end" @input="handleInputYear($event)" class="form-control text-right" maxlength="4">
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="error.end_date" class="text-sm text-red-600"><span>{{ error.end_date[0] }}</span></div>                
              </div>
            </div>
          </div>          
          <!-- SORT FILTER REPORT -->
          <div class="w-full mb-6">
            <div class="pb-2 mb-2 border-b border-gray-200">
              <span class="text-gray-800 font-medium text-sm">Sort By</span>
            </div>
            <div class="w-full mb-2">
              <label for="sort_field" class="label-control">Field</label>
              <MultiSelect id="sort_field" v-model="sortField" :options="sortFields" :value="sortField" :searchable="true" track-by="field" label="name" :showLabels="false" :allowEmpty="false" placeholder="Type to Search">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>                 
            </div>
            <div class="w-full">
              <label for="sort_option" class="label-control">Option</label>
              <MultiSelect id="sort_option" v-model="sortOption" :options="sortOptions" :value="sortOption" track-by="field" label="name" :showLabels="false" :allowEmpty="false">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>                 
            </div>
          </div>
          <!-- ACTION BUTTON -->
          <div class="w-full md:flex gap-4">
            <button :disabled="isLoading" @click.prevent="fetchData()" type="button" class="btn btn--primary w-1/2" alt="Preview" v-tooltip="'Preview Report'">
              <span v-if="isLoading" class="block">
                <span class="md:block">Wait...</span>
              </span>                  
              <span v-else>Preview</span>
            </button>
            <button :disabled="isLoading" @click.prevent="downloadFile()" type="button" class="btn btn--success w-1/2 ml-2 md:ml-0" alt="Export" v-tooltip="'Export Report'">
              <span class="ml-2">Export</span>
            </button> 
          </div>                    
        </div>
      </div>            
    </div>
    <div class="fixed w-18 top-0 bottom-0 right-0 z-20">
      <div class="absolute w-18 right-0 top-28 bg-green-500 text-white rounded-l-md shadow-lg" :class="{ 'hidden': showFilter }">
        <button class="block text-sm ml-2 px-2 py-4 cursor-pointer" v-tooltip="'Show Report Filter'" @click="toggleFilter">
          Filter        
        </button>           
      </div>
    </div>          
  </div>
</template>

<script>
import format from '@/helpers/formatNumber'
import orderReportServices from '@/services/report/orderReportServices'
import collectionServices from '@/services/collection/collectionServices'
import DateRange from '../../icons/DateRange'
import {Chart} from 'highcharts-vue'
import Highcharts from 'highcharts'
import exportingInit from 'highcharts/modules/exporting'
import exportingData from 'highcharts/modules/export-data'

exportingInit(Highcharts)
exportingData(Highcharts)

export default {
  name: 'OrderReport',
  components: {
    highcharts: Chart,
    DateRange
  },
  data() {
    return {
      report: 'Order Report',
      reports: [
        'Order Report',
        'Order Summary',
        'Order Details',
        'Order Performance'
      ],
      collection: '',
      collections: [],      
      filterDate: {
        start: new Date(),
        end: new Date()
      },
      filterYear: {
        start: new Date().getFullYear()-1,
        end: new Date().getFullYear()
      },
      masks: {
        input: 'YYYY/MM/DD',
      },
      attrs: [
        {
          key: 'today',
          highlight: 'red',
          dates: new Date(),
        },
      ],
      sortField: { field: 'collection_id', name: 'Collection (Default)' },
      sortFields: [
        { field: 'collection_id', name: 'Collection (Default)' },
        { field: 'barcode', name: 'Barcode' },
        { field: 'reference', name: 'Reference' },
        { field: 'color', name: 'Color' },
        { field: 'description', name: 'Description' },
        { field: 'size', name: 'Size' },
        { field: 'qty', name: 'Qty' }
      ],        
      sortOption: { field: 'desc', name: 'Descending' },
      sortOptions: [
        { field: 'asc', name: 'Ascending' },
        { field: 'desc', name: 'Descending' }
      ],      
      reportData: [],
      grandTotal: 0,
      error: [],
      isFilterYear: false,
      showFilter: false,
      isLoading: false,
      chartData: [],
      chartOptions: {
        chart: {
          type: 'spline'
        },
        title: {
          text: 'Order Performance'
        },
        subtitle: {
          text: 'Source: Customer Order Data'
        },
        xAxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        },
        yAxis: {
          title: {
            text: 'Number of Products'
          }
        },
        tooltip: {
          crosshairs: true,
          shared: true,
          useHTML: true,
          formatter: function () {
            return ['<h5><b>Month Period : ' + this.x + '</b></h5><br>'].concat(
              this.points ? this.points.map(function (point) {
                return '<span>' + point.series.name + ' : ' + point.y + ' Products</span><br>' +
                        '<span>Wholesale' + ' : ' + point.point.wholesaleEURO + ' € / ' + point.point.wholesaleUSD + ' $</span><br>' +
                        '<span>Retail' + ' : ' + point.point.retailEURO + ' € / ' + point.point.retailUSD + ' $</span><br><br>'                  
              }) : []
            )
          }          
        },
        plotOptions: {
          spline: {
            marker: {
              radius: 4,
              lineColor: '#666666',
              lineWidth: 1
            }
          }
        },
        series: []      
      },
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async fetchCollectionData() {
      try {
        const response = await collectionServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.collections = []
          records.forEach(element => {
            this.collections.push({
              'id': element.id,
              'name': element.collection_name
            })
          });
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },  
    async fetchData() {
      try {
        this.isLoading = true

        const params = {
          report: this.report,
          collection: this.collection ? this.collection.id : '',
          start_date: this.isFilterYear ? this.filterYear.start : this.filterDate.start,
          end_date: this.isFilterYear ? this.filterYear.end : this.filterDate.end,
          sort_field: this.sortField.field,
          sort_option: this.sortOption.field       
        }
        const response = await orderReportServices.fetchData(params)
        if (response.data.status === 'success') {
          this.isLoading =false

          /* EMPTY ERRORS VARIABLE */
          this.error = []        

          const records = response.data.data
          this.reportData = records
          this.grandTotal = 0

          switch (this.report) {
            case 'Order Report':
              records.forEach(element => {
                element.details.forEach(item => {
                  this.grandTotal += parseFloat(item.qty)             
                })
              })              
              break;

            case 'Order Performance':
              this.chartData = []
              records.forEach(element => {
                let data = []
                element.data.forEach(item => {
                  data.push({
                    'y': parseFloat(item.amount),
                    'wholesaleEURO': this.formatNumber(item.wholesale_euro),
                    'wholesaleUSD': this.formatNumber(item.wholesale_usd),
                    'retailEURO': this.formatNumber(item.retail_euro),
                    'retailUSD': this.formatNumber(item.retail_usd)
                  })              
                })

                this.chartData.push({
                  name: element.name,
                  marker: {
                    symbol: 'square'
                  },
                  data: data
                })
              })

              this.chartOptions.series = this.chartData              
              break;              
          
            default:
              records.forEach(element => {
                this.grandTotal += parseFloat(element.qty)
              })              
              break;
          }
        } else {
          this.isLoading =false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.report || responseReturn.start_date || responseReturn.end_date) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }     
        }
      } catch (error) {
        this.isLoading =false
        console.log(error)
      }
    },
    openDownloadTab(key) {
      let baseUrl = window.location.origin
      window.open(baseUrl+'/auth/download?key='+key, '_blank')
    },    
    async downloadFile() {
      try {
        this.isLoading = true

        const params = {
          report: this.report,
          collection: this.collectionField ? this.collectionField : '',
          start_date: this.isFilterYear ? this.filterYear.start : this.filterDate.start,
          end_date: this.isFilterYear ? this.filterYear.end : this.filterDate.end,
          sort_field: this.sortField.field,
          sort_option: this.sortOption.field            
        }
        const response = await orderReportServices.export(params)
        if (response.data.status === 'success') {
          this.isLoading = false
          this.$toasted.success(response.data.message, this.toastedOptions)

          const key = response.data.data
          this.openDownloadTab(key)
        } else {
          this.isLoading =false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.report || responseReturn.start_date || responseReturn.end_date) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }       
        }
      } catch (error) {
        this.isLoading =false
        console.log(error)
      }
    },    
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    toFixed(num, digits) {
      let result = format.toFixed(num, digits)
      return result
    },
    onlyNumber(num) {
      let result = format.onlyNumber(num)
      return result
    },
    handleInputYear(event) {
      this.year = this.onlyNumber(event.target.value)
    },
    toggleFilter() {
      this.showFilter = !this.showFilter
    }           
  },
  mounted() {
    this.fetchCollectionData()
    this.fetchData()
  },
  watch: {
    report: function() {
      this.report === 'Order Performance' ? this.isFilterYear = true : this.isFilterYear = false
      this.reportData = []
    },                  
  }
}
</script>