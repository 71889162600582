<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="bg-white shadow-lg rounded-lg" @submit.prevent="handleSubmit(update)">
        <div class="p-10">
          <div class="pb-2 mb-4 border-b border-gray-200">
            <h2 class="text-gray-800 text-2xl font-medium">Edit Order</h2>
          </div>
          <div class="md:flex gap-14">
            <div class="md:flex-col w-full md:w-1/2">
              <div class="md:flex gap-4">
                <div class="flex md:w-1/2">
                  <div class="w-full md:mb-2">
                    <label for="order_no" class="label-control md:py-3">Order Number <span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="flex md:w-1/2">
                  <div class="w-full mb-2">
                    <ValidationProvider name="Order Number" rules="required" v-slot="{ errors }">
                      <div class="relative flex-1">
                        <span class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm badge" :class="status === 1 ? 'badge--light-primary' : (status === 2 ? 'badge--success' : 'badge--danger')">{{ statusText }}</span>
                        <input id="order_no" type="text" v-model="orderNumber" class="form-control" readonly>
                        <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                    <div v-if="error.order_no" class="text-sm text-red-600"><span>{{ error.order_no[0] }}</span></div>
                  </div>
                </div>
              </div>
              <div class="md:flex gap-4">
                <div class="flex md:w-1/2">
                  <div class="w-full md:mb-2">
                    <label for="type" class="label-control md:py-3">Order Type <span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="flex md:w-1/2">
                  <div class="w-full mb-2">
                    <ValidationProvider name="Order Type" rules="required" v-slot="{ errors }">
                      <MultiSelect id="type" v-model="orderType" :options="orderTypes" :value="orderType" track-by="id" label="name" :showLabels="false">
                        <template slot="caret">
                          <div>
                            <div class="multiselect__select">
                              <span>
                                <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                                  <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </template>
                      </MultiSelect>
                      <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <div v-if="error.type" class="text-sm text-red-600"><span>{{ error.type[0] }}</span></div>  
                  </div>
                </div>
              </div>         
              <div class="md:flex gap-4">
                <div class="flex md:w-1/2">
                  <div class="w-full md:mb-2">
                    <label for="delivery_date_production" class="label-control md:py-3">Delivery Date Production <span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="flex md:w-1/2">
                  <div class="w-full mb-2">
                    <ValidationProvider name="Delivery Date Production" rules="required" v-slot="{ errors }">
                      <v-date-picker v-model="deliveryDateProduction" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs">
                        <template v-slot="{ inputValue, inputEvents }">
                          <div class="md:flex gap-4">
                            <div class="w-full">
                              <div class="relative flex justify-between items-center">
                                <input id="delivery_date_production" type="text" class="form-control" :value="inputValue" v-on="inputEvents">
                                <span class="h-full absolute pointer-events-none right-0">
                                  <DateRange class="m-3" />
                                </span>                      
                              </div>
                              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                            </div>
                          </div>
                        </template>
                      </v-date-picker>
                    </ValidationProvider>
                    <div v-if="error.delivery_date_production" class="text-sm text-red-600"><span>{{ error.delivery_date_production[0] }}</span></div>  
                  </div>
                </div>
              </div>
              <div class="md:flex gap-4">
                <div class="flex md:w-1/2">
                  <div class="w-full md:mb-2">
                    <label for="delivery_date_from" class="label-control md:py-3">Delivery Date for Customer From<span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="flex md:w-1/2">
                  <div class="w-full mb-2">
                    <ValidationProvider name="Delivery Date for Customer From" rules="required" v-slot="{ errors }">
                      <v-date-picker v-model="customerDeliveryDateFrom" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs">
                        <template v-slot="{ inputValue, inputEvents }">
                          <div class="md:flex gap-4">
                            <div class="w-full">
                              <div class="relative flex justify-between items-center">
                                <input id="delivery_date_from" type="text" class="form-control" :value="inputValue" v-on="inputEvents">
                                <span class="h-full absolute pointer-events-none right-0">
                                  <DateRange class="m-3" />
                                </span>                      
                              </div>
                              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                            </div>
                          </div>
                        </template>
                      </v-date-picker>
                    </ValidationProvider>
                    <div v-if="error.delivery_date_from" class="text-sm text-red-600"><span>{{ error.delivery_date_from[0] }}</span></div>
                  </div>
                </div>
              </div>
              <div class="md:flex gap-4">
                <div class="flex md:w-1/2">
                  <div class="w-full md:mb-2">
                    <label for="delivery_date_to" class="label-control md:py-3">Delivery Date for Customer To<span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="flex md:w-1/2">
                  <div class="w-full mb-2">
                    <ValidationProvider name="Delivery Date for Customer To" rules="required" v-slot="{ errors }">
                      <v-date-picker v-model="customerDeliveryDateTo" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs">
                        <template v-slot="{ inputValue, inputEvents }">
                          <div class="md:flex gap-4">
                            <div class="w-full">
                              <div class="relative flex justify-between items-center">
                                <input id="delivery_date_to" type="text" class="form-control" :value="inputValue" v-on="inputEvents">
                                <span class="h-full absolute pointer-events-none right-0">
                                  <DateRange class="m-3" />
                                </span>                      
                              </div>
                              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                            </div>
                          </div>
                        </template>
                      </v-date-picker>
                    </ValidationProvider>
                    <div v-if="error.delivery_date_to" class="text-sm text-red-600"><span>{{ error.delivery_date_to[0] }}</span></div>
                  </div>
                </div>
              </div>              
            </div>
            <div class="md:flex-col w-full md:w-1/2">
              <div class="md:flex gap-4">
                <div class="flex md:w-1/6">
                  <div class="w-full md:mb-2">
                    <label for="customer_name" class="label-control md:py-3">Customer <span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="flex md:w-5/6">
                  <div class="w-full mb-2">
                    <ValidationProvider name="Customer" rules="required" v-slot="{ errors }">
                      <input id="customer_name" type="text" v-model="customerName" class="form-control">
                      <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <div v-if="error.customer_name" class="text-sm text-red-600"><span>{{ error.customer_name[0] }}</span></div>
                  </div>
                </div>
              </div>
              <div class="md:flex gap-4">
                <div class="flex md:w-1/6">
                </div>
                <div class="flex md:w-5/6">
                  <div class="w-full mb-2">
                    <ValidationProvider name="Country" rules="required" v-slot="{ errors }">
                      <input id="country" type="text" v-model="country" class="form-control">
                      <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <div v-if="error.country" class="text-sm text-red-600"><span>{{ error.country[0] }}</span></div>
                  </div>
                </div>
              </div>
              <div class="md:flex gap-4">
                <div class="flex md:w-1/6">
                  <div class="w-full md:mb-2">
                    <label for="season" class="label-control md:py-3">Season <span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="flex md:w-5/6">
                  <div class="w-full mb-2">
                    <ValidationProvider name="Season" rules="required" v-slot="{ errors }">
                      <MultiSelect id="season" v-model="season" :options="seasons" :value="season" track-by="id" label="name" :showLabels="false">
                        <template slot="caret">
                          <div>
                            <div class="multiselect__select">
                              <span>
                                <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                                  <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </template>
                      </MultiSelect>
                      <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <div v-if="error.season" class="text-sm text-red-600"><span>{{ error.season[0] }}</span></div> 
                  </div>
                </div>
              </div>              
              <div class="md:flex gap-4">
                <div class="flex md:w-1/6">
                  <div class="w-full md:mb-2">
                    <label for="period" class="label-control md:py-3">Period <span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="flex md:w-5/6">
                  <div class="w-full mb-2">
                    <ValidationProvider name="Period" rules="required" v-slot="{ errors }">
                      <MultiSelect id="period" v-model="period" :options="periods" :value="period" track-by="id" label="name" :showLabels="false">
                        <template slot="caret">
                          <div>
                            <div class="multiselect__select">
                              <span>
                                <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                                  <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </template>
                      </MultiSelect>
                      <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <div v-if="error.period" class="text-sm text-red-600"><span>{{ error.period[0] }}</span></div>
                  </div>
                </div>
              </div>
              <div class="md:flex gap-4">
                <div class="flex md:w-1/6">
                  <div class="w-full md:mb-2">
                    <label for="factory" class="label-control md:py-3">Factory <span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="flex md:w-5/6">
                  <div class="w-full mb-2">
                    <ValidationProvider name="Factory" rules="required" v-slot="{ errors }">
                      <MultiSelect id="factory" v-model="factory" :options="factories" :value="factory" track-by="id" label="name" :showLabels="false">
                        <template slot="caret">
                          <div>
                            <div class="multiselect__select">
                              <span>
                                <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                                  <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </template>
                      </MultiSelect>
                      <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <div v-if="error.factory" class="text-sm text-red-600"><span>{{ error.factory[0] }}</span></div>
                  </div>
                </div>
              </div>              
            </div>           
          </div>
          <!-- CALL PRODUCT RECIPE -->
          <TransactionDetail v-if="status === 1" show="true" :parentLoading="isLoading" class="mb-10" />
          <TransactionDetail v-else class="mb-10" />

          <!-- SUMMARY DATA SECTION -->
          <div class="md:flex gap-6">
            <div class="md:w-1/2 mb-4">
              <ValidationProvider name="Remarks" rules="" v-slot="{ errors }">
                <label for="remarks" class="label-control">Remarks</label>
                <textarea id="remarks" v-model="remarks" class="form-control" rows="3"></textarea>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
              </ValidationProvider>
              <div v-if="error.remarks" class="text-sm text-red-600"><span>{{ error.remarks[0] }}</span></div>  
            </div>
            <div class="md:w-1/2 mb-4">
            </div>
          </div>
          <div class="flex gap-4 md:justify-between items-center mt-10 mb-2">
            <div class="flex items-center gap-4">
              <button v-if="status === 1" :disabled="isLoading" type="submit" class="btn btn--primary" alt="Update" title="Update">
                <span v-if="isLoading" class="block mx-5">
                  <Loading />
                </span>
                <SaveIcon v-else>
                  <span class="ml-2 md:block hidden">Update</span>
                </SaveIcon>
              </button>
              <button :disabled="isLoading" type="button" @click="goBack" class="btn btn--secondary" alt="Back" title="Back">
                <BackIcon>
                  <span class="ml-2 md:block hidden">Back</span>
                </BackIcon>
              </button>
            </div>
            <div class="flex items-center gap-2">
              <button v-if="status === 1" :disabled="isLoading" type="button" @click="cancelDialog" class="btn btn--danger" alt="Cancel Adjustment" title="Cancel Adjustment">
                <OffIcon>
                  <span class="ml-2 md:block hidden">Cancel</span>
                </OffIcon>               
              </button>              
              <button v-if="status === 1" :disabled="isLoading" type="button" @click="validateDialog" class="btn btn--success" alt="Validate" title="Validate">
                <LockIcon>
                  <span class="ml-2 md:block hidden">Validate</span>
                </LockIcon>              
              </button>
              <button v-if="status === 2" :disabled="isLoading" type="button" @click="cancelValidateDialog" class="btn btn--danger" alt="Cancel Validation" title="Cancel Validation">
                <LockIcon>
                  <span class="ml-2 md:block hidden">Invalidate</span>
                </LockIcon>              
              </button>              
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import format from '@/helpers/formatNumber'
import seasonServices from '@/services/season/seasonServices'
import factoryServices from '@/services/factory/factoryServices'
import orderServices from '@/services/order/orderServices'
import SaveIcon from '../../icons/SaveIcon'
import BackIcon from '../../icons/BackIcon'
import LockIcon from '../../icons/LockIcon'
import OffIcon from '../../icons/OffIcon'
import Loading from '../../icons/Loading'
import DateRange from '../../icons/DateRange'
import TransactionDetail from './Detail'

export default {
  name: 'EditOrder',
  components: {
    SaveIcon,
    BackIcon,
    LockIcon,
    OffIcon,
    Loading,
    DateRange,
    TransactionDetail,
  },  
  data(){
    return {
      orderNumber: '',
      orderType: '',
      orderTypes: [
        { id: 1, name: 'RETAIL'},
        { id: 2, name: 'WHOLESALE'},
      ],
      season: '',
      seasons: [],
      factory: '',
      factories: [],           
      deliveryDateProduction: '',
      customerDeliveryDateFrom: '',
      customerDeliveryDateTo: '',
      period: '',
      periods: [
        { 'id': 1, 'name': 'Period 1' }, { 'id': 2, 'name': 'Period 2' }, { 'id': 3, 'name': 'Period 3' },
        { 'id': 4, 'name': 'Period 4' }, { 'id': 5, 'name': 'Period 5' }, { 'id': 6, 'name': 'Period 6' }
      ],       
      masks: {
        input: 'YYYY-MM-DD',
      },
      attrs: [
        {
          key: 'today',
          highlight: 'red',
          dates: new Date(),
        },
      ],      
      remarks: '',
      status: '',
      statusText: '',
      customerName: '',
      country: '',
      record: [],
      details: [],
      error: [],
      showFunction: false,
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async fetchSeasonData() {
      try {
        const response = await seasonServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.seasons = []
          records.forEach(element => {
            this.seasons.push({
              'id': element.id,
              'name': element.season_name
            })
          });          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchFactoryData() {
      try {
        const response = await factoryServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.factories = []
          records.forEach(element => {
            this.factories.push({
              'id': element.id,
              'name': element.factory_name
            })
          });          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },        
    async update(){
      try {
        this.isLoading = true

        const id = this.$route.params.id
        const payload = {
          customer_name: this.customerName,
          country: this.country,
          order_no: this.orderNumber,
          type: this.orderType.id,
          season: this.season.id,
          factory: this.factory.id,
          delivery_date_production: this.deliveryDateProduction,
          delivery_date_from: this.customerDeliveryDateFrom,
          delivery_date_to: this.customerDeliveryDateTo,
          period: this.period.id,
          remarks: this.remarks
        }
        const response = await orderServices.update(id, payload)
        if (response.data.status === 'success') {
          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          /* CHECK IF NEW ID IS EQUAL WITH CURRENT ID, THEN REDIRECT TO 2 STEP PREV PAGE */
          // console.log(this.newID, id)
          if (this.newID === id) {
            this.$router.push(this.indexRoute.fullPath)
            this.$store.dispatch('setNewOrderID', null) 
            this.$store.dispatch('setIndexOrderRoute', null)
          } else {
            /* RETURN TO LIST */
            window.history.length > 1 ? this.$router.back() : this.$router.push('/orders')
          }
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.customer_name || responseReturn.country || responseReturn.order_no || responseReturn.type || responseReturn.season || responseReturn.factory 
              || responseReturn.delivery_date_production || responseReturn.delivery_date_from || responseReturn.delivery_date_to || responseReturn.period || responseReturn.remarks) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },
    async fetchData(){
      try {
        const id = this.$route.params.id
        const response = await orderServices.fetchById(id)
        if (response.data.status === 'success') {
          this.record = response.data.data
          const dataType = [ this.record.type ]
          const type = this.orderTypes.filter(item => dataType.includes(item.id))
          const dataPeriod = [ this.record.period ]
          const period = this.periods.filter(item => dataPeriod.includes(item.id))

          this.customerName = this.record.customer_name
          this.country = this.record.country
          this.orderNumber = this.record.order_no
          this.orderType = { id: type[0].id, name: type[0].name }
          this.season = { id: this.record.season_id, name: this.record.season.season_name }
          this.factory = { id: this.record.factory_id, name: this.record.factory.factory_name }
          this.deliveryDateProduction = this.record.delivery_date_production
          this.customerDeliveryDateFrom = this.record.delivery_date_from
          this.customerDeliveryDateTo = this.record.delivery_date_to
          this.period = { id: period[0].id, name: period[0].name }
          this.remarks = this.record.remarks
          this.status = this.record.status
          this.statusText = this.record.status_text
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)            
        }
      } catch (error) {
        console.log(error)
      }
    },   
    async cancel(id) {
      try {
        const dataType = [ this.record.type ]
        const type = this.orderTypes.filter(item => dataType.includes(item.id))
        const dataPeriod = [ this.record.period ]
        const period = this.periods.filter(item => dataPeriod.includes(item.id))

        const payload = {
          customer_name: this.record.customer_name,
          country: this.record.country,
          order_no: this.record.order_no,
          type: type[0].id,
          season: this.record.season_id,
          factory: this.record.factory_id,
          delivery_date_production: this.record.delivery_date_production,
          delivery_date_from: this.record.delivery_date_from,
          delivery_date_to: this.record.delivery_date_to,
          period: period[0].id,
          remarks: this.record.remarks,
          status: 3
        }
        const response = await orderServices.update(id, payload)
        return response.data
      } catch (error) {     
        return error
      }      
    },
    async cancelDialog() {
      const id = this.$route.params.id

      this.$swal.fire({
        title: 'Do you want to continue ?',
        text: "Cancelation transaction will change the transaction status and lock the transaction from modify and delete. You won't be able to revert this!",
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#4b5563',
        confirmButtonText: 'Yes'
      }).then(async (result) => {
        if (result.isConfirmed) {
          /* SET IS LOADING STATE TRUE */
          this.isLoading = true

          /* CALL VALIDATE FUNCTION */
          const result = this.cancel(id)
          const response = await result

          if (response.status) {
            if (response.status === 'success') {
              /* SET IS LOADING STATE FALSE */
              this.isLoading = false

              /* REFRESH DATA */
              this.fetchData()

              this.$swal.fire({
                title: 'Validated!',
                text: "Your transaction has been updated",
                icon: 'success',
                showConfirmButton: false,
                timer: 3000            
              })              
            } else {
              /* SET IS LOADING STATE FALSE */
              this.isLoading = false
                            
              this.$swal.fire({
                title: 'Error!',
                text: response.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 3000            
              })              
            }
          } else {
            /* SET IS LOADING STATE FALSE */
            this.isLoading = false
                        
            this.$toasted.error(response.message, this.toastedOptions)           
          }
        }
      })      
    },
    async validate(id) {
      try {
        const response = await orderServices.validate(id)
        return response.data
      } catch (error) {     
        return error
      }      
    },    
    async validateDialog() {
      const id = this.$route.params.id

      this.$swal.fire({
        title: 'Do you want to continue ?',
        text: "Validate transaction will lock the transaction from modify and delete. You won't be able to revert this!",
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#4b5563',
        confirmButtonText: 'Yes'
      }).then(async (result) => {
        if (result.isConfirmed) {
          /* SET IS LOADING STATE TRUE */
          this.isLoading = true

          /* CALL VALIDATE FUNCTION */
          const result = this.validate(id)
          const response = await result

          if (response.status) {
            if (response.status === 'success') {
              /* SET IS LOADING STATE FALSE */
              this.isLoading = false

              /* REFRESH DATA */
              this.fetchData()

              this.$swal.fire({
                title: 'Validated!',
                text: "Your transaction has been validated",
                icon: 'success',
                showConfirmButton: false,
                timer: 3000            
              })              
            } else {
              /* SET IS LOADING STATE FALSE */
              this.isLoading = false
                            
              this.$swal.fire({
                title: 'Error!',
                text: response.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 3000            
              })              
            }
          } else {
            /* SET IS LOADING STATE FALSE */
            this.isLoading = false
            
            this.$toasted.error(response.message, this.toastedOptions)           
          }
        }
      })      
    },
    async cancelValidation(id) {
      try {
        const response = await orderServices.cancelValidation(id)
        return response.data
      } catch (error) {     
        return error
      }      
    },    
    async cancelValidateDialog() {
      const id = this.$route.params.id

      this.$swal.fire({
        title: 'Do you want to continue ?',
        text: "Cancelation validation will unlock the transaction from modify and delete!",
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#4b5563',
        confirmButtonText: 'Yes'
      }).then(async (result) => {
        if (result.isConfirmed) {
          /* SET IS LOADING STATE TRUE */
          this.isLoading = true

          /* CALL VALIDATE FUNCTION */
          const result = this.cancelValidation(id)
          const response = await result

          if (response.status) {
            if (response.status === 'success') {
              /* SET IS LOADING STATE FALSE */
              this.isLoading = false

              /* REFRESH DATA */
              this.fetchData()

              this.$swal.fire({
                title: 'Canceled!',
                text: "Your transaction has been unlocked",
                icon: 'success',
                showConfirmButton: false,
                timer: 3000            
              })              
            } else {
              /* SET IS LOADING STATE FALSE */
              this.isLoading = false
                            
              this.$swal.fire({
                title: 'Error!',
                text: response.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 3000            
              })              
            }
          } else {
            /* SET IS LOADING STATE FALSE */
            this.isLoading = false
                        
            this.$toasted.error(response.message, this.toastedOptions)           
          }
        }
      })      
    },     
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    },
    convertToEuro(value) {
      let convert = this.unformatNumber(value) / this.unformatNumber(this.change_rate)
      convert = this.formatNumber(this.toFixed(convert, 2))

      return convert
    },
    convertToRupiah(value) {
      let convert = this.unformatNumber(value) * this.unformatNumber(this.change_rate)
      convert = this.formatNumber(this.toFixed(convert, 2))

      return convert
    },
    goBack() {
      if (this.newID === this.$route.params.id) {
        this.$router.push(this.indexRoute.fullPath)
        this.$store.dispatch('setNewOrderID', null) 
        this.$store.dispatch('setIndexOrderRoute', null)    
      } else {
        window.history.length > 1 ? this.$router.back() : this.$router.push('/orders')
      }
    },
    toggleFunction() {
      this.showFunction = !this.showFunction
    }      
  },
  computed: {
    ...mapGetters({
      newID: 'newOrderID',
      indexRoute: 'indexOrderRoute'      
    })
  },  
  created(){
    this.fetchSeasonData()
    this.fetchFactoryData()
    this.fetchData()
  },
}
</script>