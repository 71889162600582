<template>
  <div class="flex flex-col md:flex-row h-screen items-center">
    <div class="bg-indigo-600 hidden lg:block w-full md:w-1/2 xl:w-2/3 h-screen">
      <img :src="background" alt="" class="w-full h-full object-cover">
    </div>
    <div class="bg-white w-full md:max-w-md lg:max-w-full md:mx-auto md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12
          flex items-center justify-center">
      <div class="w-full h-100">
        <span class="mt-12">Welcome Back</span>
        <h1 class="text-xl md:text-2xl font-bold leading-tight">Log in to your account</h1>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form class="mt-6" @submit.prevent="handleSubmit(authUser)">
            <div>
              <ValidationProvider name="Username" rules="required" v-slot="{ errors }">
                <label for="username" class="block text-gray-700">Username</label>
                <input type="text" id="username" v-model="username" placeholder="Enter Username" class="w-full px-4 py-3 rounded bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none" autofocus autocomplete>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
              </ValidationProvider>
              <div v-if="error.username" class="text-sm text-red-600"><span>{{ error.username[0] }}</span></div>
            </div>
            <div class="mt-4">
              <ValidationProvider name="Password" rules="required|min:5" v-slot="{ errors }">
                <label for="password" class="block text-gray-700">Password</label>
                <input type="password" id="password" v-model="password" placeholder="Enter Password" class="w-full px-4 py-3 rounded bg-gray-200 mt-2 border focus:border-blue-500
                      focus:bg-white focus:outline-none">
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
              </ValidationProvider>
              <div v-if="error.password" class="text-sm text-red-600"><span>{{ error.password[0] }}</span></div>          
            </div>
            <div class="text-right mt-2">
              <router-link v-if="!isLoading" :to="{ name: 'forgotPassword' }" class="text-sm font-semibold text-gray-700 hover:text-blue-700 focus:text-blue-700">Forgot Password?</router-link>
              <span v-else class="text-gray-500">Forgot Password?</span>
            </div>
            <button type="submit" :disabled="isLoading" class="w-full bg-indigo-600 hover:bg-indigo-700 focus:bg-indigo-700 text-white font-semibold rounded
                  px-4 py-3 mt-6 disabled:opacity-50">
              Log In                
            </button>           
          </form>
        </ValidationObserver>
      </div>
    </div>   
  </div>
</template>

<script>
import authServices from '@/services/user/authServices'
import Background from '../../../assets/images/marie-laure-chamorel.webp'

export default {
  name: 'Login',
  data(){
    return{
      username: '',
      password: '',
      background: Background,
      error: [],
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000 
      }     
    }
  },
  methods: {
    async authUser(){
      try {
        /* SET LOADING STATE IS TRUE */
        this.isLoading = true

        const payload = {
          username: this.username,
          password: this.password
        }
        const response = await authServices.login(payload)
        if (response.data.status === 'success') {
          /* CLEAR INPUT FORM */
          this.clearForm()

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE DATA TO LOCAL STORAGE */
          localStorage.setItem('accessToken', response.data.access_token)

          this.$store.dispatch('setAuthentication', response.data.access_token)
          this.$store.dispatch('setUser', response.data.data)

          /* SUCCESS MESSAGE */
          this.$toasted.success(response.data.message, this.toastedOptions).goAway(1000)

          /* WELCOME MESSAGE */
          this.$toasted.success('Welcome ' + this.$store.state.user.name, this.toastedOptions)

          /* REDIRECT TO REQUEST PAGE IF AVAILABLE */
          if (this.$route.query.redirect) {
            this.$router.push({ path: this.$route.query.redirect })
          } else {
            /* ELSE REDIRECT TO HOME / DASHBOARD */
            this.$router.push('/')
          }
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT USERNAME OR PASSWORD, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.username || responseReturn.password) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }
        }        
      } catch (error) {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          this.error = error.response.data.message  
          
          /* ELSE, THROW ERROR MESSAGES */
          this.$toasted.error(this.error, this.toastedOptions)
      }      
    },
    clearForm(){
      this.username = '',
      this.password = ''
    }    
  }  
}
</script>