<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="bg-white shadow-lg rounded-lg" @submit.prevent="handleSubmit(update)">
        <div class="p-10">
          <div class="pb-2 mb-4 border-b border-gray-200">
            <h2 class="text-gray-800 text-2xl font-medium">Edit Material</h2>
          </div>
          <div class="md:flex gap-6">
            <div class="md:flex-col w-full md:w-4/6">
              <div class="md:flex gap-6">
                <div class="md:w-1/2 mb-4">
                  <ValidationProvider name="Type" rules="required" v-slot="{ errors }">
                    <label for="collection" class="label-control">Type <span class="text-red-600">*</span></label>
                    <MultiSelect id="collection" v-model="collection" :options="collections" :value="collection" :searchable="true" track-by="id" label="code" :multiple="true" placeholder="Type to Search">
                      <template slot="caret">
                        <div>
                          <div class="multiselect__select">
                            <span>
                              <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                                <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </template>
                    </MultiSelect>
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.type" class="text-sm text-red-600"><span>{{ error.type[0] }}</span></div>
                </div>
                <div class="md:w-1/2 mb-4">
                  <ValidationProvider name="Supplier" rules="required" v-slot="{ errors }">
                    <label for="supplier" class="label-control">Supplier <span class="text-red-600">*</span></label>
                    <MultiSelect id="supplier" ref="supplier" v-model="supplier" :value="supplier" :options="suppliers" :searchable="true" track-by="id" label="name" placeholder="Type to Search">
                      <template slot="caret">
                        <div>
                          <div class="multiselect__select">
                            <span>
                              <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                                <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </template>
                    </MultiSelect>
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <div v-if="error.supplier" class="text-sm text-red-600"><span>{{ error.supplier[0] }}</span></div>
                </div>
              </div>
              <div class="md:flex gap-6">
                <div class="w-full mb-4">
                  <ValidationProvider name="Reference" rules="required" v-slot="{ errors }">
                    <label for="reference" class="label-control">Reference <span class="text-red-600">*</span></label>
                    <input id="reference" ref="reference" type="text" v-model="reference" maxlength="255" class="form-control">
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.reference" class="text-sm text-red-600"><span>{{ error.reference[0] }}</span></div>              
                </div>
              </div>
              <div class="md:flex gap-6">
                <div class="w-full mb-4">
                  <ValidationProvider name="Description" rules="" v-slot="{ errors }">
                    <label for="description" class="label-control">Description <span class="text-red-600">*</span></label>
                    <textarea id="description" ref="description" v-model="description" class="form-control"></textarea>
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.description" class="text-sm text-red-600"><span>{{ error.description[0] }}</span></div>              
                </div>
              </div>           
              <div class="md:flex gap-6">
                <div class="md:w-1/2 mb-4">
                  <ValidationProvider name="Color" rules="" v-slot="{ errors }">
                    <label for="color" class="label-control">Color</label>
                    <input id="color" type="text" v-model="color" class="form-control">
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.color" class="text-sm text-red-600"><span>{{ error.color[0] }}</span></div>
                </div>
                <div class="md:w-1/2 mb-4">
                  <ValidationProvider name="Size" rules="" v-slot="{ errors }">
                    <label for="size" class="label-control">Size</label>
                    <input id="size" type="text" v-model="size" class="form-control">
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.size" class="text-sm text-red-600"><span>{{ error.size[0] }}</span></div>
                </div>
              </div>
              <div class="md:flex gap-6">
                <div class="w-full md:w-1/2 mb-4">
                  <ValidationProvider name="Unit" rules="required" v-slot="{ errors }">
                    <label for="unit" class="label-control">Unit <span class="text-red-600">*</span></label>
                    <MultiSelect id="unit" ref="unit" v-model="unit" :options="units" :value="unit" :searchable="true" placeholder="Type to Search">
                      <template slot="caret">
                        <div>
                          <div class="multiselect__select">
                            <span>
                              <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                                <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </template>
                    </MultiSelect>
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <div v-if="error.unit" class="text-sm text-red-600"><span>{{ error.unit[0] }}</span></div>              
                </div>
                <div class="flex md:w-1/2 gap-4 md:gap-6 mb-4">
                  <div class="w-full md:w-1/2">
                    <ValidationProvider name="Buy Currency" rules="required" v-slot="{ errors }">
                      <label for="buy_currency" class="label-control">Buy Currency <span class="text-red-600">*</span></label>
                      <MultiSelect @input="handleInputCurrency" id="buy_currency" ref="buy_currency" v-model="buyCurrency" :value="buyCurrency" :options="currencies" :searchable="false" track-by="currency" label="currency" :showLabels="false" placeholder="">
                        <template slot="caret">
                          <div>
                            <div class="multiselect__select">
                              <span>
                                <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                                  <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </template>
                      </MultiSelect>
                      <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                    </ValidationProvider>
                    <div v-if="error.buy_currency" class="text-sm text-red-600"><span>{{ error.buy_currency[0] }}</span></div>                
                  </div>
                  <div class="w-full md:w-1/2">
                    <ValidationProvider name="Buy Rate" rules="required" v-slot="{ errors }">
                      <label for="buy_rate" class="label-control">Buy Rate <span class="text-red-600">*</span></label>
                      <input id="buy_rate" type="text" v-model="buyRate" class="form-control text-right" @input="handleInputRate($event)">
                      <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                    </ValidationProvider>
                    <div v-if="error.buy_rate" class="text-sm text-red-600"><span>{{ error.buy_rate[0] }}</span></div>                 
                  </div>
                </div>
              </div>                    
              <div class="md:flex gap-6">
                <div class="w-full md:flex md:w-1/2 gap-4 md:gap-6 mb-4">
                  <div class="w-full md:w-1/2">
                    <ValidationProvider name="Buy Price" rules="required" v-slot="{ errors }">
                      <label for="buy_price" class="label-control">Buy Price <span class="text-red-600">*</span></label>
                      <div class="relative flex-1">
                        <span fill="currentColor" class="absolute left-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">{{ buyCurrency ? buyCurrency.symbol : '' }}</span>
                        <input id="buy_price" type="text" v-model="buyPrice" class="form-control text-right" @input="handleInputPrice($event)">
                      </div>
                      <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                    </ValidationProvider>
                    <div v-if="error.buy_price" class="text-sm text-red-600"><span>{{ error.buy_price[0] }}</span></div>              
                  </div>
                  <div class="w-full md:w-1/2">
                    <ValidationProvider name="Price" rules="required" v-slot="{ errors }">
                      <label for="price" class="label-control">Convertion Price <span class="text-red-600">*</span></label>
                      <div class="relative flex-1">
                        <span fill="currentColor" class="absolute left-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
                        <input id="price" type="text" v-model="price" class="form-control text-right" readonly>
                      </div>
                      <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                    </ValidationProvider>
                    <div v-if="error.price" class="text-sm text-red-600"><span>{{ error.price[0] }}</span></div>            
                  </div>
                </div>
                <div class="w-full md:flex md:w-1/2 gap-6 mb-4">
                  <div class="w-full md:w-1/2">
                    <ValidationProvider name="Minimum Stock" rules="required" v-slot="{ errors }">
                      <label for="minimum_stock" class="label-control">Minimum Stock <span class="text-red-600">*</span></label>
                      <input id="minimum_stock" type="text" v-model="minimumStock" class="form-control" @input="handleInputMinimumStock($event)">
                      <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                    </ValidationProvider>
                    <div v-if="error.minimum_stock" class="text-sm text-red-600"><span>{{ error.minimum_stock[0] }}</span></div>           
                  </div>
                  <div class="w-full md:w-1/2">
                    <ValidationProvider name="Status" rules="required" v-slot="{ errors }">
                      <label for="status" class="label-control">Status <span class="text-red-600">*</span></label>
                      <MultiSelect id="status" ref="status" v-model="status" :options="statusData" :value="status" track-by="id" label="name" :searchable="false" :showLabels="false">
                        <template slot="caret">
                          <div>
                            <div class="multiselect__select">
                              <span>
                                <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                                  <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </template>
                      </MultiSelect>
                      <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                    </ValidationProvider>
                    <div v-if="error.status" class="text-sm text-red-600"><span>{{ error.status[0] }}</span></div>          
                  </div>
                </div>
              </div>
              <div class="md:flex gap-6">
                <div class="md:flex w-full md:w-1/2 gap-6 mb-4">
                  <div class="w-full md:w-1/2">
                    <label for="stock" class="label-control">On Hand Stock</label>
                    <input id="stock" type="text" v-model="stock" class="form-control" readonly>            
                  </div>
                </div>
                <div class="md:flex w-full md:w-1/2 gap-6 mb-4"></div>
              </div>              
            </div>
            <div class="md:flex-col w-full md:w-2/6">
              <div class="w-full mb-2">
                <label for="image" class="label-control">Image</label>
                <div v-if="!image">
                  <div class="md:h-64 border-2 border-dashed border-gray-400 flex items-center justify-center p-2 rounded-lg mb-2">
                    <img class="m-auto" :src="fileBox" />
                  </div>
                  <input type="file" id="image" ref="image" @change="onFileChange">
                </div>
                <div v-else>
                  <div class="md:h-auto border-2 border-dashed border-gray-400 flex items-center justify-center p-2 rounded-lg mb-2">
                    <img class="m-auto" :src="image" />
                  </div>
                  <div v-if="error.image" class="text-sm text-red-600 mb-2"><span>{{ error.image[0] }}</span></div>
                  <button class="btn btn--danger" @click="removeImage">Remove Image</button>
                </div>
              </div>
            </div>
          </div>
          <div class="md:flex gap-4 mt-4 mb-2">
            <button :disabled="isLoading" type="submit" class="btn btn--primary" alt="Save" title="Save">
              <span v-if="isLoading" class="block mx-5">
                <Loading />
              </span>
              <SaveIcon v-else>
                <span class="ml-2 md:block hidden">Save</span>
              </SaveIcon>
            </button>
            <button :disabled="isLoading" type="button" @click="goBack()" class="btn btn--secondary" alt="Back" title="Back">
              <BackIcon>
                <span class="ml-2 md:block hidden">Back</span>
              </BackIcon>              
            </button>
          </div>                                                  
        </div>
      </form>   
    </ValidationObserver>
    <div class="fixed bg-gray-800 bg-opacity-25 w-full md:inset-0 top-16 md:top-0 z-50" :class="{ 'hidden': !showFunction }">
      <div class="w-52 ml-auto h-full bg-white shadow-lg right-0">
        <div class="flex p-5 border-b border-gray-200 items-center">
          <span class="flex-1 text-sm font-medium">Functions</span>
          <button type="button" class="flex right-0" @click="toggleFunction">
            <svg class="h-5 w-5 fill-current hover:text-gray-700" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
            </svg>
          </button>
        </div>
        <div class="p-5 items-center">
          <CollectionModal class="mb-4" />
          <SupplierModal class="mb-4" />
          <UnitModal class="mb-4" />
          <RateModal class="mb-4" /> 
        </div>
      </div>            
    </div>
    <div class="fixed w-20 top-0 bottom-0 right-0 z-20">
      <div class="absolute w-16 right-0 top-1/2 bg-green-500 text-white rounded-l-full shadow-lg" :class="{ 'hidden': showFunction }">
        <button class="block ml-2 p-3 cursor-pointer" v-tooltip="'Show Function'" @click="toggleFunction">
          <svg class="h-5 w-5 fill-current" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M22.6558 18.9896L13.5758 9.90957C14.5058 7.56957 14.0258 4.80957 12.1358 2.90957C9.83584 0.609575 6.25584 0.399574 3.70584 2.25957L7.54584 6.10957L6.12584 7.51957L2.29584 3.68957C0.435837 6.22957 0.645837 9.81957 2.94584 12.1096C4.80584 13.9696 7.51584 14.4596 9.83584 13.5896L18.9458 22.6996C19.3358 23.0896 19.9658 23.0896 20.3558 22.6996L22.6558 20.3996C23.0558 20.0196 23.0558 19.3896 22.6558 18.9896ZM19.6558 20.5896L10.1958 11.1296C9.58584 11.5796 8.90584 11.8496 8.19584 11.9496C6.83584 12.1496 5.40584 11.7396 4.36584 10.6996C3.41584 9.75957 2.97584 8.49957 3.04584 7.25957L6.13584 10.3496L10.3758 6.10957L7.28584 3.01957C8.52584 2.94957 9.77584 3.38957 10.7258 4.32957C11.8058 5.40957 12.2158 6.89957 11.9658 8.28957C11.8458 8.99957 11.5458 9.65957 11.0858 10.2496L20.5358 19.6996L19.6558 20.5896Z" />
          </svg>        
        </button>           
      </div>
    </div>    
  </div>
</template>

<script>
import format from '@/helpers/formatNumber'
import collectionServices from '@/services/collection/collectionServices'
import supplierServices from '@/services/supplier/supplierServices'
import unitServices from '@/services/unit/unitServices'
import rateServices from '@/services/rate/rateServices'
import materialServices from '@/services/material/materialServices'
import SaveIcon from '../../icons/SaveIcon'
import BackIcon from '../../icons/BackIcon'
import Loading from '../../icons/Loading'
import UnitModal from '../unit/Modal'
import CollectionModal from '../collection/Modal'
import RateModal from '../rate/Modal'
import SupplierModal from '../supplier/Modal'
import imageBox from '../../../assets/images/file-box.png'

export default {
  name: 'EditMaterial',
  components: {
    SaveIcon,
    BackIcon,
    Loading,
    UnitModal,
    CollectionModal,
    RateModal,
    SupplierModal,    
  },  
  data(){
    return {
      collection: [],
      supplier: '',
      reference: '',
      description: '',
      color: '',
      size: '',
      unit: '',
      euroRate: 0,
      buyCurrency: '',
      buyRate: '',
      buyPrice: '',
      price: '',
      minimumStock: '',
      image: '',
      materialImage: '',
      fileBox: imageBox,
      stock: 0,
      status: '',
      statusData: [
        { id: 1, name: 'Discontinue' },
        { id: 0, name: 'Available' }        
      ],       
      collections: [],
      suppliers: [],
      units: [],
      currencies: [],
      record: [],
      error: [],
      showFunction: false,
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async update(){
      try {
        this.isLoading = true

        const id = this.$route.params.id
        let payload = new FormData()
        payload.append('type', JSON.stringify(this.collection))
        payload.append('supplier', this.supplier.id)
        payload.append('reference', this.reference)
        payload.append('description', this.description)
        payload.append('color', this.color)
        payload.append('size', this.size ?? '')
        payload.append('unit', this.unit)
        payload.append('buy_currency', this.buyCurrency.currency)
        payload.append('buy_rate', this.unformatNumber(this.buyRate))
        payload.append('buy_price', this.unformatNumber(this.buyPrice))
        payload.append('price', this.unformatNumber(this.price))
        payload.append('minimum_stock', this.unformatNumber(this.minimumStock))
        payload.append('status', this.status.id)
        payload.append('image', this.materialImage)
        payload.append('_method', 'PUT')

        const response = await materialServices.update(id, payload)
        if (response.data.status === 'success') {
          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          /* RETURN TO LIST */
          this.goBack()
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.type || responseReturn.supplier || responseReturn.reference || responseReturn.description 
            || responseReturn.color || responseReturn.size || responseReturn.unit || responseReturn.currency 
            || responseReturn.price || responseReturn.minimum_stock || responseReturn.status || responseReturn.image) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },
    async fetchData(){
      try {
        const id = this.$route.params.id
        const response = await materialServices.fetchById(id)
        if (response.data.status === 'success') {
          this.record = response.data.data

          this.record.type.forEach(element => {
            this.collection.push({ 'id': element.id, 'name': element.name, 'code': element.code })
          });
          this.supplier = { 'id': this.record.supplier_id, 'name': this.record.supplier.supplier_name}
          this.reference = this.record.material_name
          this.description = this.record.description
          this.color = this.record.color
          this.size = this.record.size
          this.unit = this.record.unit
          this.buyCurrency = { 'currency': this.record.buy_currency, 'symbol': this.record.currency_detail.symbol, 'rate': this.record.currency_detail.rate }
          this.buyRate = this.formatNumber(this.record.buy_rate)
          this.buyPrice = this.formatNumber(this.record.buy_price)
          this.price = this.formatNumber(this.record.current_price)
          this.minimumStock = this.formatNumber(this.record.minimum_stock)
          this.image = this.record.image
          this.status = this.record.is_discontinue === 1 ? { id: 1, name: 'Discontinue' } : { id: 0, name: 'Available' }
          this.stock = this.formatNumber(this.record.stock)
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)            
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchCollectionData() {
      try {
        const response = await collectionServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.collections = []
          records.forEach(element => {
            this.collections.push({
              'id': element.id,
              'name': element.collection_name,
              'code': element.collection_code
            })
          });          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchSupplierData() {
      try {
        const response = await supplierServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.suppliers = []
          records.forEach(element => {
            this.suppliers.push({
              'id': element.id,
              'name': element.supplier_name
            })
          });
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchUnitData() {
      try {
        const response = await unitServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.units = []
          records.forEach(element => {
            this.units.push(element.unit)
          });
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    }, 
    async fetchCurrencyData() {
      try {
        const response = await rateServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.currencies = []
          records.forEach(element => {
            this.currencies.push({
              'currency': element.currency,
              'symbol': element.symbol,
              'rate': element.rate
            })
          });
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchEuroRate() {
      try {
        const response = await rateServices.fetchByCurrency('EUR')
        if (response.data.status === 'success') {
          const record = response.data.data
          this.euroRate = record.rate
        } else {
          console.log(response.data.message)       
        }
      } catch (error) {
        console.log(error)
      }
    },    
    convertBuyPrice(price, currency) {
      if (currency === 'EUR') {
        return this.formatNumber(this.toFixed(price, 2))
      } else {
        let convertToEUR = price / this.buyCurrency.rate
        convertToEUR = this.formatNumber(this.toFixed(convertToEUR, 2))

        return convertToEUR
      }
    },
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    },
    handleInputCurrency() {
      if (this.buyCurrency) {
        this.price = this.convertBuyPrice(this.unformatNumber(this.buyPrice), this.buyCurrency.currency)
        this.buyRate = this.formatNumber(this.buyCurrency.rate)        
      } else {
        this.price = 0
        this.buyRate = 0
      }
    },
    handleInputRate(event) {
      if (this.buyCurrency) {
        this.buyRate = this.formatNumber(event.target.value)
        this.price = this.convertBuyPrice(this.unformatNumber(this.buyPrice), this.buyCurrency.currency) 
      } else {
        this.buyRate = this.formatNumber(event.target.value)
        this.price = 0
      } 
    },
    handleInputPrice(event) {
      if (this.buyCurrency) {
        this.buyPrice = this.formatNumber(event.target.value)
        this.price = this.convertBuyPrice(this.unformatNumber(this.buyPrice), this.buyCurrency.currency)
      } else {
        this.buyRate = 0
        this.buyPrice = this.formatNumber(event.target.value)
        this.price = 0
      }
    },
    handleInputMinimumStock(event) {
      this.minimumStock = this.formatNumber(event.target.value)
    },    
    toggleFunction() {
      this.showFunction = !this.showFunction
    },
    goBack() {
      window.history.length > 1 ? this.$router.back() : this.$router.push('/materials')
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }

      this.createImage(files[0])
    },
    createImage(file) {
      let reader = new FileReader()
      let vm = this

      reader.onload = (e) => {
        vm.image = e.target.result
      }
      reader.readAsDataURL(file)
      this.materialImage = this.$refs.image.files[0]
    },
    removeImage() {
      this.image = ''
    },    
  },
  created(){
    this.fetchCollectionData()
    this.fetchSupplierData()
    this.fetchUnitData()
    this.fetchCurrencyData()
    this.fetchEuroRate()
    this.fetchData()
  },
  mounted() {
    const thisInstance = this
    this.$root.$on('fetchUnit', function(){
      thisInstance.fetchUnitData()
    }),
    this.$root.$on('fetchCollection', function(){
      thisInstance.fetchCollectionData()
    }),
    this.$root.$on('fetchRate', function(){
      thisInstance.fetchCurrencyData()
    }),
    this.$root.$on('fetchSupplier', function(){
      thisInstance.fetchSupplierData()
    })               
  }  
}
</script>