import ListRole from '../components/feature/role/List'
import CreateRole from '../components/feature/role/Create'
import EditRole from '../components/feature/role/Edit'
import ShowRole from '../components/feature/role/Show'

const routes = [
  {
    path: '/roles',
    name: 'role',
    component: ListRole,
    meta: {
      title: 'Roles',
      requiresAuth: true
    }    
  },
  {
    path: '/roles/create',
    name: 'roleCreate',
    component: CreateRole,
    meta: {
      title: 'Create Role',
      requiresAuth: true
    }    
  },
  {
    path: '/roles/:id/edit',
    name: 'roleEdit',
    component: EditRole,
    meta: {
      title: 'Edit Role',
      requiresAuth: true
    }    
  },
  {
    path: '/roles/:id',
    name: 'roleShow',
    component: ShowRole,
    meta: {
      title: 'Show Role',
      requiresAuth: true
    }    
  }  
]

export default routes