<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="bg-white shadow-lg rounded-lg" @submit.prevent="handleSubmit(create)">
        <div class="p-10">
          <div class="pb-2 mb-4 border-b border-gray-200">
            <h2 class="text-gray-800 text-2xl font-medium">Create Order</h2>
          </div>
          <div class="md:flex gap-6">
            <div class="w-full md:w-1/2 mb-4">
              <ValidationProvider name="Customer Name" rules="required" v-slot="{ errors }">
                <label for="customer_name" class="label-control">Customer Name <span class="text-red-600">*</span></label>
                <input id="customer_name" type="text" v-model="customerName" class="form-control">
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
              </ValidationProvider>
              <div v-if="error.customer_name" class="text-sm text-red-600"><span>{{ error.customer_name[0] }}</span></div>
            </div>
            <div class="w-full md:w-1/2 mb-4">
              <ValidationProvider name="Country" rules="required" v-slot="{ errors }">
                <label for="country" class="label-control">Country <span class="text-red-600">*</span></label>
                <input id="country" type="text" v-model="country" class="form-control">
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
              </ValidationProvider>
              <div v-if="error.country" class="text-sm text-red-600"><span>{{ error.country[0] }}</span></div>
            </div>
          </div>
          <div class="md:flex gap-6">
            <div class="flex md:w-1/2 gap-4 md:gap-6 mb-4">
              <div class="w-full md:w-1/2">
                <ValidationProvider name="Order Number" rules="required" v-slot="{ errors }">
                  <label for="order_no" class="label-control">Order Number <span class="text-red-600">*</span></label>
                  <input id="order_no" type="text" v-model="orderNumber" class="form-control">
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="error.order_no" class="text-sm text-red-600"><span>{{ error.order_no[0] }}</span></div>
              </div>
              <div class="w-full md:w-1/2">
                <ValidationProvider name="Order Type" rules="required" v-slot="{ errors }">
                  <label for="type" class="label-control">Order Type <span class="text-red-600">*</span></label>
                  <MultiSelect id="type" v-model="orderType" :options="orderTypes" :value="orderType" track-by="id" label="name" :showLabels="false">
                    <template slot="caret">
                      <div>
                        <div class="multiselect__select">
                          <span>
                            <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                              <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </template>
                  </MultiSelect>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="error.type" class="text-sm text-red-600"><span>{{ error.type[0] }}</span></div>
              </div>              
            </div>
            <div class="flex md:w-1/2 gap-4 md:gap-6 mb-4">
              <div class="md:w-1/2">
                <ValidationProvider name="Delivery Date Production" rules="required" v-slot="{ errors }">
                  <v-date-picker v-model="deliveryDateProduction" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs">
                    <template v-slot="{ inputValue, inputEvents }">
                      <div class="md:flex gap-6">
                        <div class="w-full">
                          <label for="delivery_date_production" class="label-control">Delivery Date Production <span class="text-red-600">*</span></label>
                          <div class="relative flex justify-between items-center">
                            <input id="delivery_date_production" type="text" class="form-control" :value="inputValue" v-on="inputEvents">
                            <span class="h-full absolute pointer-events-none right-0">
                              <DateRange class="m-3" />
                            </span>                      
                          </div>
                          <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </template>
                  </v-date-picker>
                </ValidationProvider>
                <div v-if="error.delivery_date_production" class="text-sm text-red-600"><span>{{ error.delivery_date_production[0] }}</span></div>                
              </div>
              <div class="md:w-1/2">
                <ValidationProvider name="Delivery Date for Customer From" rules="required" v-slot="{ errors }">
                  <v-date-picker v-model="customerDeliveryDateFrom" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs">
                    <template v-slot="{ inputValue, inputEvents }">
                      <div class="md:flex gap-6">
                        <div class="w-full">
                          <label for="delivery_date_from" class="label-control">Delivery Date for Customer From <span class="text-red-600">*</span></label>
                          <div class="relative flex justify-between items-center">
                            <input id="delivery_date_from" type="text" class="form-control" :value="inputValue" v-on="inputEvents">
                            <span class="h-full absolute pointer-events-none right-0">
                              <DateRange class="m-3" />
                            </span>                      
                          </div>
                          <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </template>
                  </v-date-picker>
                </ValidationProvider>
                <div v-if="error.delivery_date_from" class="text-sm text-red-600"><span>{{ error.delivery_date_from[0] }}</span></div>                
              </div>
            </div>
          </div>
          <div class="md:flex gap-6">
            <div class="flex md:w-1/2 gap-4 md:gap-6 mb-4">
              <div class="md:w-1/2">
                <ValidationProvider name="Delivery Date for Customer To" rules="required" v-slot="{ errors }">
                  <v-date-picker v-model="customerDeliveryDateTo" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs">
                    <template v-slot="{ inputValue, inputEvents }">
                      <div class="md:flex gap-6">
                        <div class="w-full">
                          <label for="delivery_date_to" class="label-control">Delivery Date for Customer To <span class="text-red-600">*</span></label>
                          <div class="relative flex justify-between items-center">
                            <input id="delivery_date_to" type="text" class="form-control" :value="inputValue" v-on="inputEvents">
                            <span class="h-full absolute pointer-events-none right-0">
                              <DateRange class="m-3" />
                            </span>                      
                          </div>
                          <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </template>
                  </v-date-picker>
                </ValidationProvider>
                <div v-if="error.delivery_date_to" class="text-sm text-red-600"><span>{{ error.delivery_date_to[0] }}</span></div>                
              </div>            
              <div class="w-full md:w-1/2">
                <ValidationProvider name="Season" rules="required" v-slot="{ errors }">
                  <label for="season" class="label-control">Season <span class="text-red-600">*</span></label>
                  <MultiSelect id="season" v-model="season" :options="seasons" :value="season" track-by="id" label="name" :showLabels="false">
                    <template slot="caret">
                      <div>
                        <div class="multiselect__select">
                          <span>
                            <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                              <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </template>
                  </MultiSelect>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="error.season" class="text-sm text-red-600"><span>{{ error.season[0] }}</span></div>
              </div>              
            </div>
            <div class="flex md:w-1/2 gap-4 md:gap-6 mb-4">
              <div class="w-full md:w-1/2">
                <ValidationProvider name="Period" rules="required" v-slot="{ errors }">
                  <label for="period" class="label-control">Period <span class="text-red-600">*</span></label>
                  <MultiSelect id="period" v-model="period" :options="periods" :value="period" track-by="id" label="name" :showLabels="false">
                    <template slot="caret">
                      <div>
                        <div class="multiselect__select">
                          <span>
                            <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                              <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </template>
                  </MultiSelect>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="error.period" class="text-sm text-red-600"><span>{{ error.period[0] }}</span></div>
              </div>
              <div class="w-full md:w-1/2">
                <ValidationProvider name="Factory" rules="required" v-slot="{ errors }">
                  <label for="factory" class="label-control">Factory <span class="text-red-600">*</span></label>
                  <MultiSelect id="factory" v-model="factory" :options="factories" :value="factory" track-by="id" label="name" :showLabels="false">
                    <template slot="caret">
                      <div>
                        <div class="multiselect__select">
                          <span>
                            <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                              <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </template>
                  </MultiSelect>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="error.factory" class="text-sm text-red-600"><span>{{ error.factory[0] }}</span></div>
              </div>                          
            </div>
          </div>                 
          <div class="md:flex gap-6">
            <div class="w-full mb-4">
              <ValidationProvider name="Remarks" rules="" v-slot="{ errors }">
                <label for="remarks" class="label-control">Remarks</label>
                <textarea id="remarks" v-model="remarks" class="form-control" rows="3"></textarea>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.remarks" class="text-sm text-red-600"><span>{{ error.remarks[0] }}</span></div>              
            </div>
          </div>
          <div class="md:flex gap-4 mt-4 mb-2">
            <button :disabled="isLoading" type="submit" class="btn btn--primary" alt="Save" title="Save">
              <span v-if="isLoading" class="block mx-5">
                <Loading />
              </span>
              <SaveIcon v-else>
                <span class="ml-2 md:block hidden">Save &amp; Continue</span>
              </SaveIcon>
            </button>
            <button :disabled="isLoading" type="button" @click="goBack()" class="btn btn--secondary" alt="Back" title="Back">
              <BackIcon>
                <span class="ml-2 md:block hidden">Back</span>
              </BackIcon>              
            </button>
          </div>                                                  
        </div>
      </form>      
    </ValidationObserver> 
  </div>
</template>

<script>
import seasonServices from '@/services/season/seasonServices'
import factoryServices from '@/services/factory/factoryServices'
import orderServices from '@/services/order/orderServices'
import SaveIcon from '../../icons/SaveIcon'
import BackIcon from '../../icons/BackIcon'
import Loading from '../../icons/Loading'
import DateRange from '../../icons/DateRange'

export default {
  name: 'CreateOrder',
  components: {
    SaveIcon,
    BackIcon,
    Loading,
    DateRange,
  },
  data(){
    return {
      orderNumber: '',
      orderType: '',
      orderTypes: [
        { id: 1, name: 'RETAIL'},
        { id: 2, name: 'WHOLESALE'},
      ],
      season: '',
      seasons: [],
      factory: '',
      factories: [],          
      deliveryDateProduction: '',
      customerDeliveryDateFrom: '',
      customerDeliveryDateTo: '',
      period: '',
      periods: [
        { 'id': 1, 'name': 'Period 1' }, { 'id': 2, 'name': 'Period 2' }, { 'id': 3, 'name': 'Period 3' },
        { 'id': 4, 'name': 'Period 4' }, { 'id': 5, 'name': 'Period 5' }, { 'id': 6, 'name': 'Period 6' }
      ],      
      masks: {
        input: 'YYYY-MM-DD',
      },
      attrs: [
        {
          key: 'today',
          highlight: 'red',
          dates: new Date(),
        },
      ],      
      remarks: '',
      customerName: '',
      country: '',
      error: [],
      prevRoute: null,
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },  
  methods: {
    async fetchSeasonData() {
      try {
        const response = await seasonServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.seasons = []
          records.forEach(element => {
            this.seasons.push({
              'id': element.id,
              'name': element.season_name
            })
          });          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchFactoryData() {
      try {
        const response = await factoryServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.factories = []
          records.forEach(element => {
            this.factories.push({
              'id': element.id,
              'name': element.factory_name
            })
          });          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },        
    async create(){
      try {
        this.isLoading = true
        const payload = {
          customer_name: this.customerName,
          country: this.country,
          order_no: this.orderNumber,
          type: this.orderType.id,
          season: this.season.id,
          factory: this.factory.id,
          delivery_date_production: this.deliveryDateProduction,
          delivery_date_from: this.customerDeliveryDateFrom,
          delivery_date_to: this.customerDeliveryDateTo,
          period: this.period.id,
          remarks: this.remarks
        }

        const response = await orderServices.create(payload)
        if (response.data.status === 'success') {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false
                    
          /* CLEAR INPUT FORM */
          this.clearForm()

          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          /* SET NEW ID & REDIRECT TO EDIT PAGE WITH CREATED ID */
          this.$store.dispatch('setNewOrderID', response.data.data.id)
          this.$router.push({ name: 'orderEdit', params: { id: response.data.data.id} })
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.customer_name || responseReturn.country || responseReturn.order_no || responseReturn.type || responseReturn.season || responseReturn.factory 
              || responseReturn.delivery_date_production || responseReturn.delivery_date_from || responseReturn.delivery_date_to || responseReturn.period || responseReturn.remarks) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },      
    clearForm(){
      this.customerName = ''
      this.country = ''
      this.orderNumber = ''
      this.orderType = ''
      this.season = ''
      this.deliveryDateProduction = ''
      this.customerDeliveryDateFrom = ''
      this.customerDeliveryDateTo = ''
      this.period = ''
      this.factory = ''
      this.remarks = ''
    },
    toggleFunction() {
      this.showFunction = !this.showFunction
    },
    goBack() {
      window.history.length > 1 ? this.$router.back() : this.$router.push('/orders')
    }        
  },
  mounted() {
    this.fetchSeasonData()
    this.fetchFactoryData()
  },  
  watch: {
    prevRoute: function() {
      this.$store.dispatch('setIndexOrderRoute', this.prevRoute)
    }
  }  
}
</script>