const state = {
  newReceiveID: null,
  indexReceiveRoute: null
};

const getters = {
  newReceiveID: state => state.newReceiveID,
  indexReceiveRoute: state => state.indexReceiveRoute
};

const actions = {
  setNewReceiveID({commit}, newReceiveID){
    commit('setNewReceiveID', newReceiveID)
  },
  setIndexReceiveRoute({commit}, fullPath){
    commit('setIndexReceiveRoute', fullPath)
  }
};

const mutations = {
  setNewReceiveID(state, newReceiveID){
    state.newReceiveID = newReceiveID
  },
  setIndexReceiveRoute(state, fullPath){
    state.indexReceiveRoute = fullPath
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};