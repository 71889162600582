import axios from '@/axios'

export default {
  fetchAll(params){
    return axios.get(`/materials/all`, {params})
  },
  fetchLimit(params){
    return axios.get(`/materials`, {params})
  },  
  fetchById(id){
    return axios.get(`/materials/${id}`)
  },
  create(payload){
    return axios.post(`/materials`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }      
    })
  },
  update(id, payload){
    return axios.post(`/materials/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }      
    })
  },
  delete(id){
    return axios.delete(`/materials/${id}`)
  }, 
  deleteMultiple(payload){
    return axios.delete(`/materials`, payload)
  },
  import(payload){
    return axios.post(`/materials/import`, payload)
  },
  export(payload){
    return axios.post(`/materials/export`, payload)
  },
  exportDraft(){
    return axios.post(`/materials/draft`)
  },  
  fetchStockByFactory(params){
    return axios.get(`/materials/stock`, {params})
  },
  fetchDataOptions(params){
    return axios.get(`/materials/options`, {params})
  },
  syncPrice(id){
    return axios.post(`/materials/sync/${id}`)
  }
}