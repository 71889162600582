import axios from '@/axios'

export default {
  fetchAll(params){
    return axios.get(`/orders/all`, {params})
  },
  fetchLimit(params){
    return axios.get(`/orders`, {params})
  },  
  fetchById(id){
    return axios.get(`/orders/${id}`)
  },    
  create(payload){
    return axios.post(`/orders`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }      
    })
  },
  update(id, payload){
    return axios.put(`/orders/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }      
    })
  },
  delete(id){
    return axios.delete(`/orders/${id}`)
  }, 
  deleteMultiple(payload){
    return axios.delete(`/orders`, payload)
  },
  import(payload){
    return axios.post(`/orders/import`, payload)
  },
  validate(id){
    return axios.post(`/orders/validate/${id}`)
  },
  cancelValidation(id){
    return axios.post(`/orders/cancel/${id}`)
  },
  exportDraft(){
    return axios.post(`/orders/draft`)
  },
  export(payload){
    return axios.post(`/orders/export`, payload)
  }  
}