<template>
  <div class="bg-white shadow-lg rounded-lg">
    <div class="p-10">
      <div class="pb-2 mb-4 border-b border-gray-200">
        <h2 class="text-gray-800 text-2xl font-medium">Show Season</h2>
      </div>
      <div class="md:flex gap-6">
        <div class="w-full mb-4">
          <label for="season_name" class="label-control">Season Name</label>
          <input id="season_name" ref="season_name" type="text" v-model="seasonName" maxlength="255" class="form-control" autofocus>            
        </div>
      </div>
      <div class="md:flex gap-6">
        <div class="w-full mb-4">
          <label for="description" class="label-control">Description</label>
          <textarea id="description" v-model="description" class="form-control" rows="5"></textarea>              
        </div>
      </div>
      <div class="md:flex gap-6">
        <div class="md:w-1/2 mb-4">
          <label for="status" class="label-control">Status <span class="text-red-600">*</span></label>
          <MultiSelect id="status" ref="status" v-model="status" :options="statusData" :value="status" track-by="id" label="name" :searchable="false" :showLabels="false">
            <template slot="caret">
              <div>
                <div class="multiselect__select">
                  <span>
                    <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                      <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                    </svg>
                  </span>
                </div>
              </div>
            </template>
          </MultiSelect>
        </div>
        <div class="md:w-1/2 mb-4"></div>
      </div>       
      <div class="md:flex gap-4 mt-4 mb-2">
        <button :disabled="isLoading" type="button" @click="goBack()" class="btn btn--secondary" alt="Back" title="Back">
          <BackIcon>
            <span class="ml-2 md:block hidden">Back</span>
          </BackIcon>              
        </button>
      </div>                                                  
    </div>    
  </div>
</template>

<script>
import seasonServices from '@/services/season/seasonServices'
import BackIcon from '../../icons/BackIcon'

export default {
  name: 'ShowSeason',
  components: {
    BackIcon
  },
  data(){
    return {
      seasonName: '',
      description: '',
      status: '',
      statusData: [
        { id: 1, name: 'Active' },
        { id: 0, name: 'Inactive' }        
      ],
      record: [],
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async fetchData(){
      try {
        const id = this.$route.params.id
        const response = await seasonServices.fetchById(id)
        if (response.data.status === 'success') {
          this.record = response.data.data

          this.seasonName = this.record.season_name
          this.description = this.record.description
          this.status = this.record.status === 1 ? { id: 1, name: 'Active' } : { id: 0, name: 'Inactive' }
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)            
        }
      } catch (error) {
        console.log(error)
      }
    },
    goBack() {
      window.history.length > 1 ? this.$router.back() : this.$router.push('/seasons')
    }        
  },
  created(){
    this.fetchData()
  }
}
</script>