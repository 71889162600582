import ListCollection from '../components/feature/collection/List'
import CreateCollection from '../components/feature/collection/Create'
import EditCollection from '../components/feature/collection/Edit'
import ShowCollection from '../components/feature/collection/Show'

const routes = [
  {
    path: '/collections',
    name: 'collection',
    component: ListCollection,
    meta: {
      title: 'Collections',
      requiresAuth: true
    }    
  },
  {
    path: '/collections/create',
    name: 'collectionCreate',
    component: CreateCollection,
    meta: {
      title: 'Create Collection',
      requiresAuth: true
    }    
  },
  {
    path: '/collections/:id/edit',
    name: 'collectionEdit',
    component: EditCollection,
    meta: {
      title: 'Edit Collection',
      requiresAuth: true
    }    
  },
  {
    path: '/collections/:id',
    name: 'collectionShow',
    component: ShowCollection,
    meta: {
      title: 'Show Collection',
      requiresAuth: true
    }    
  }  
]

export default routes