import ListMaterialAnalysis from '../components/feature/materialAnalysis/List'

const routes = [
  {
    path: '/analysis',
    name: 'analysis',
    component: ListMaterialAnalysis,
    meta: {
      title: 'Material Analysis',
      requiresAuth: true
    }    
  }
]

export default routes