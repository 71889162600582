const state = {
  newOrderID: null,
  indexOrderRoute: null
};

const getters = {
  newOrderID: state => state.newOrderID,
  indexOrderRoute: state => state.indexOrderRoute
};

const actions = {
  setNewOrderID({commit}, newOrderID){
    commit('setNewOrderID', newOrderID)
  },
  setIndexOrderRoute({commit}, fullPath){
    commit('setIndexOrderRoute', fullPath)
  }
};

const mutations = {
  setNewOrderID(state, newOrderID){
    state.newOrderID = newOrderID
  },
  setIndexOrderRoute(state, fullPath){
    state.indexOrderRoute = fullPath
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};