<template>
  <div>
    <!-- Import Modal Dialog -->
    <modal :show="showModal" @close="showModal = false" addClass="modal-2xl" modalOrientation="pt-16 md:pt-5 md:items-center">
      <template v-slot:header><h3>{{ modalTitle }}</h3></template>
      <template v-slot:body>
        <!-- TABLE SECTION -->
        <div class="mb-4 bg-white shadow-lg rounded-lg overflow-y-auto">
          <table>
            <thead>
              <tr>
                <th scope="col" class="px-3 text-left">Supplier</th>
                <th scope="col" class="px-3 text-left">Reference</th>
                <th scope="col" class="px-3 text-left">Color</th>
                <th scope="col" class="px-3 text-left">Size</th>
                <th scope="col" class="px-3 text-left">Unit</th>
                <th scope="col" class="px-3 text-center">Request</th>
                <th scope="col" class="px-3 text-center">Receive</th>
                <th scope="col" class="px-3 text-center">Shortage</th>
              </tr>           
            </thead>
            <tbody>
              <tr v-if="records.length === 0" class="border-b border-gray-200 hover:bg-gray-100"><td class="text-center" colspan="8">No matching records found</td></tr>
              <tr v-for="(item, index) in records" :key="index" class="border-b border-gray-200 hover:bg-gray-100" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">
                <td class="px-3 py-2 text-left">{{ item.supplier_name }}</td>
                <td class="px-3 py-2 text-left">{{ item.material_name }}</td>
                <td class="px-3 py-2 text-left">{{ item.color }}</td>
                <td class="px-3 py-2 text-left">{{ item.size }}</td>
                <td class="px-3 py-2 text-left">{{ item.unit }}</td>
                <td class="px-3 py-2 text-right">{{ formatNumber(item.request) }}</td>
                <td class="px-3 py-2 text-right">{{ formatNumber(item.receive) }}</td>
                <td class="px-3 py-2 text-right">{{ item.qty > 0 ? formatNumber(item.qty) : '0' }}</td>
              </tr>
            </tbody>                              
          </table>                          
        </div>
      </template>
      <template v-slot:footer>
      </template> 
    </modal>  
  </div>
</template>

<script>
import format from '@/helpers/formatNumber'
import purchaseDetailServices from '@/services/purchase/purchaseDetailServices'
import Modal from '../../widgets/Modal'

export default {
  name: 'PurchaseOrderDetailModal',
  components: {
    Modal,
  },
  data() {
    return {
      recordNumber: null,
      records: [],
      error: [],
      isLoading: false,
      modalTitle: '',     
      showModal: false,      
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }         
    }
  },
  methods: {
    toggleModal(number) {
      this.recordNumber = number
      this.showModal = true
      this.modalTitle = 'Trace Purchase Order'
      this.records = []
      this.fetchData()
    },
    async fetchData() {
      try {
        const params = {
          po_number: this.recordNumber
        }
        const response = await purchaseDetailServices.fetchAllByReceiving(params)
        if (response.data.status === 'success') {
          this.records = response.data.data         
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    },      
  },
}
</script>