<template>
  <div>
    <!-- ACTION PANEL SECTION -->
    <div class="flex border-b border-gray-200 text-gray-800 justify-between items-center mb-4 pb-2">
      <div class="md:w-1/2 w-full items-center">
        <h2 class="text-2xl font-medium">Material Reports</h2>
      </div>
      <div class="flex items-center right-0">
      </div>
    </div>
    <div class="bg-white shadow-lg rounded-lg">
      <div class="p-8">
        <div class="flex text-sm mb-2">
          <div class="w-full md:w-1/3">
            <span>Date Range : {{ new Date(filterDate.start).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' }) }} - {{ new Date(filterDate.end).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' }) }}</span>      
          </div>
          <div class="w-full md:w-1/3">
            <span v-if="!isDisabledLocation">Location : {{ location.name }}</span>
          </div>          
          <div class="w-full md:w-1/3 text-right">
            <span>Report : {{ report }}</span>
          </div>
        </div>        
        <!-- TABLE SECTION -->
        <div class="mb-4 bg-white shadow-lg rounded-lg overflow-y-auto">
          <!-- MATERIAL STOCK MOVEMENT REPORT SECTION -->
          <template v-if="report === 'Stock Movement'">
            <table class="min-w-max w-full table-auto">
              <thead>
                <tr class="bg-purple-200 text-purple-600 uppercase text-sm leading-normal">
                  <th scope="col" class="px-3 text-left">Date</th>
                  <th scope="col" class="px-3 text-left">Action</th>
                  <th scope="col" class="px-3 text-left">Type</th>
                  <th scope="col" class="px-3 text-left">Reference</th>
                  <th scope="col" class="px-3 text-left">Color</th>
                  <th scope="col" class="px-3 text-left">Size</th>
                  <th scope="col" class="px-3 text-left">Movement Remarks</th>
                  <th scope="col" class="px-3 text-center">Qty</th>
                  <th scope="col" class="px-3 text-center">Unit</th>
                </tr>                                            
              </thead>
              <tbody class="text-gray-600 text-xs font-light">
                <tr v-if="reportData.length === 0" class="border-b border-gray-200 hover:bg-gray-100"><td class="py-3 px-6 text-sm text-center" colspan="9">No data to display</td></tr>
                <tr v-for="(item, index) in reportData" :key="index" class="border-b border-gray-200 hover:bg-gray-100" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">
                  <td class="px-3 py-2 text-left">{{ item.date }}</td>
                  <td class="px-3 py-2 text-left">{{ item.action }}</td>
                  <td class="px-3 py-2 text-left">
                    <span v-for="type in item.type" :key="type.id" class="my-1 badge badge--danger">{{ type.code }}</span>
                  </td>
                  <td class="px-3 py-2 text-left">{{ item.reference }}</td>
                  <td class="px-3 py-2 text-left">{{ item.color }}</td>
                  <td class="px-3 py-2 text-left">{{ item.size }}</td>
                  <td class="px-3 py-2 text-left">{{ item.reason }}</td>
                  <td class="px-3 py-2 text-right">{{ item.disposition === '+' ? formatNumber(item.qty) : '('+formatNumber(item.qty)+')' }}</td>
                  <td class="px-3 py-2 text-left">{{ item.unit }}</td>
                </tr>
              </tbody>
            </table>
          </template>
          <!-- MATERIAL STOCK RECAPITULATION REPORT SECTION -->
          <template v-else-if="report === 'Stock Recapitulation'">
            <table class="min-w-max w-full table-auto">
              <thead>
                <tr class="bg-purple-200 text-purple-600 uppercase text-sm leading-normal">
                  <th scope="col" class="px-2 py-2 text-left border-r border-purple-300 border-opacity-50" rowspan="2"></th>
                  <th scope="col" class="px-2 py-2 text-center border-r border-purple-300 border-opacity-50 qty-column" rowspan="2">Begining</th>
                  <th scope="col" class="px-2 py-2 text-center border-r border-purple-300 border-opacity-50 qty-column" rowspan="2">Receive</th>
                  <th scope="col" class="px-2 py-2 text-center border-r border-purple-300 border-opacity-50 qty-column" rowspan="2">Production</th>
                  <th scope="col" class="px-2 py-2 text-center border-r border-b border-purple-300 border-opacity-50" colspan="2">Transfer</th>
                  <th scope="col" class="px-2 py-2 text-center border-r border-b border-purple-300 border-opacity-50" colspan="2">Adjustment</th>
                  <th scope="col" class="px-2 py-2 text-center qty-column" rowspan="2">Balance</th>
                </tr>
                <tr class="bg-purple-200 text-purple-600 uppercase text-sm leading-normal">
                  <th scope="col" class="px-2 py-2 text-center border-r border-purple-300 border-opacity-50 qty-column">IN</th>
                  <th scope="col" class="px-2 py-2 text-center border-r border-purple-300 border-opacity-50 qty-column">OUT</th>
                  <th scope="col" class="px-2 py-2 text-center border-r border-purple-300 border-opacity-50 qty-column">IN</th>
                  <th scope="col" class="px-2 py-2 text-center border-r border-purple-300 border-opacity-50 qty-column">OUT</th>
                </tr>                
              </thead>
              <tbody class="text-gray-600 text-xs font-light">
                <tr v-if="reportData.length === 0" class="border-b border-gray-200 hover:bg-gray-100"><td class="py-3 px-6 text-sm text-center" colspan="9">No data to display</td></tr>
                <template v-for="(data, index) in reportData">
                  <tr :key="index" class="border-b">
                    <td class="px-2 text-left font-semibold" colspan="9">LOCATION : {{ data.location }}</td>
                  </tr>
                  <tr v-for="(item, idx) in data.data" :key="index+'-'+idx+'-'+item.material_id" class="border-b border-gray-200 hover:bg-gray-100" :class="{ 'bg-gray-50' : (idx % 2 !== 0) }">
                    <td class="px-2 py-2 text-left"><span class="pl-4">{{ item.reference }} - {{ item.color }} [ {{ item.size }} ] / {{ item.unit }}</span></td>
                    <td class="px-2 py-2 text-right">{{ formatNumber(toFixed((item.begining_in - item.begining_out), 2)) }}</td>
                    <td class="px-2 py-2 text-right">{{ formatNumber(toFixed(item.receive, 2)) }}</td>
                    <td class="px-2 py-2 text-right">{{ formatNumber(toFixed(item.production, 2)) }}</td>
                    <td class="px-2 py-2 text-right">{{ formatNumber(toFixed(item.transfer_in, 2)) }}</td>
                    <td class="px-2 py-2 text-right">{{ formatNumber(toFixed(item.transfer_out, 2)) }}</td>
                    <td class="px-2 py-2 text-right">{{ formatNumber(toFixed(item.adjustment_in, 2)) }}</td>
                    <td class="px-2 py-2 text-right">{{ formatNumber(toFixed(item.adjustment_out, 2)) }}</td>
                    <td class="px-2 py-2 text-right">{{ (toFixed(((item.begining_in - item.begining_out) + item.receive + item.transfer_in + item.adjustment_in) - (item.production + item.transfer_out + item.adjustment_out), 2)).toLocaleString() }}</td>
                  </tr>                  
                </template>
              </tbody>
            </table>
          </template>                    
        </div>             
      </div>
    </div>
    <!-- REPORT FILTER & ACTIONS -->
    <div class="fixed bg-gray-800 bg-opacity-25 w-full inset-0 top-16 md:top-0 z-50" :class="{ 'hidden': !showFilter }">
      <div class="w-80 ml-auto h-full overflow-y-scroll bg-white shadow-lg right-0">
        <div class="flex p-5 border-b border-gray-200 items-center">
          <span class="flex-1 text-sm font-medium">Report Filter</span>
          <button type="button" class="flex right-0" @click="toggleFilter">
            <svg class="h-5 w-5 fill-current hover:text-gray-700" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
            </svg>
          </button>
        </div>
        <div class="p-5 items-center">
          <!-- TYPE OF REPORT -->
          <div class="w-full mb-6">
            <ValidationProvider name="Report" rules="required" v-slot="{ errors }">
              <label for="report" class="label-control">Report Type <span class="text-red-600">*</span></label>
              <MultiSelect id="report" ref="report" v-model="report" :options="reports" :value="report" :searchable="true" placeholder="Type to Search" :showLabels="false">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>
              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
            </ValidationProvider>
            <div v-if="error.report" class="text-sm text-red-600"><span>{{ error.report[0] }}</span></div>
          </div>
          <!-- LOCATION FILTER REPORT -->
          <div class="w-full mb-6">
            <ValidationProvider name="Location" rules="" v-slot="{ errors }">
              <label for="location" class="label-control">Location <span class="text-red-600">*</span></label>
              <MultiSelect id="location" v-model="location" :options="locations" :value="location" :searchable="true" @input="fetchData()" track-by="id" label="name" placeholder="Type to Search" :allowEmpty="false" :showLabels="false" :disabled="isDisabledLocation">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>
              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
            </ValidationProvider>
            <div v-if="error.location" class="text-sm text-red-600"><span>{{ error.location[0] }}</span></div>                 
          </div>          
          <!-- DATE FILTER REPORT -->
          <div class="w-full mb-6">
            <ValidationProvider name="Filter Date" rules="required" v-slot="{ errors }">
              <v-date-picker ref="calendar" v-model="filterDate" mode="date" :masks="masks" color="purple" title-position="left" :attributes="attrs" is-range>
                <template v-slot="{ inputValue, inputEvents, isDragging }">
                  <label for="filter_date" class="label-control">Filter Date <span class="text-red-600">*</span></label>
                  <div class="flex flex-col sm:flex-row justify-start items-center gap-4">
                    <div class="w-full relative flex-grow mb-2 md:mb-0">
                      <input class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" readonly />
                      <span class="h-full absolute pointer-events-none right-0">
                        <DateRange class="m-3" />
                      </span>                  
                    </div>
                    <div class="w-full relative flex-grow">
                      <input class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end" readonly />
                      <span class="h-full absolute pointer-events-none right-0">
                        <DateRange class="m-3" />
                      </span>                  
                    </div>
                  </div>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </template>
              </v-date-picker>
            </ValidationProvider>
            <div v-if="error.start_date" class="text-sm text-red-600"><span>{{ error.start_date[0] }}</span></div>
            <div v-if="error.end_date" class="text-sm text-red-600"><span>{{ error.end_date[0] }}</span></div>
          </div>
          <!-- ACTION BUTTON -->
          <div class="w-full md:flex gap-4">
            <button :disabled="isLoading" @click.prevent="fetchData()" type="button" class="btn btn--primary w-1/2" alt="Preview" v-tooltip="'Preview Report'">
              <span v-if="isLoading" class="block">
                <span class="md:block">Wait...</span>
              </span>                  
              <span v-else>Preview</span>
            </button>
            <button :disabled="isLoading" @click.prevent="downloadFile()" type="button" class="btn btn--success w-1/2 ml-2 md:ml-0" alt="Export" v-tooltip="'Export Report'">
              <span class="ml-2">Export</span>
            </button> 
          </div>                    
        </div>
      </div>            
    </div>
    <div class="fixed w-28 top-0 bottom-0 right-0 z-20">
      <div class="absolute w-28 right-0 top-28 bg-green-500 text-white rounded-l-md shadow-lg" :class="{ 'hidden': showFilter }">
        <button class="block text-sm ml-2 px-2 py-4 cursor-pointer" v-tooltip="'Show Report Filter'" @click="toggleFilter">
          Report Filter        
        </button>           
      </div>
    </div>             
  </div>
</template>

<script>
import format from '@/helpers/formatNumber'
import factoryServices from '@/services/factory/factoryServices'
import materialReportServices from '@/services/report/materialReportServices'
import DateRange from '../../icons/DateRange'

export default {
  name: 'MaterialReport',
  components: {
    DateRange
  },
  data() {
    return {
      report: 'Stock Movement',
      reports: [
        'Stock Movement', 
        'Stock Recapitulation'
      ],
      reportData: [],
      filterDate: {
        start: new Date(),
        end: new Date()
      },
      location: '',
      locations: [], 
      masks: {
        input: 'YYYY/MM/DD',
      },
      attrs: [
        {
          key: 'today',
          highlight: 'red',
          dates: new Date(),
        },
      ],
      error: [],
      isDisabledLocation: false,
      showFilter: false,
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }
    }
  },
  methods: {
    async fetchFactory() {
      try {
        const response = await factoryServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.locations = []
          records.forEach(element => {
            this.locations.push({
              'id': element.id,
              'name': element.factory_name,
              'code': element.factory_code
            })
            this.location = {
              'id': element.id,
              'name': element.factory_name,
              'code': element.factory_code
            }
          })
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }        
      } catch (error) {
        console.log(error)
      }
    },    
    async fetchData() {
      try {
        this.isLoading = true

        const params = {
          report: this.report,
          location: this.location.id,
          start_date: this.filterDate.start,
          end_date: this.filterDate.end
        }
        const response = await materialReportServices.fetchData(params)
        if (response.data.status === 'success') {
          this.isLoading =false

          /* EMPTY ERRORS VARIABLE */
          this.error = []          

          const records = response.data.data
          this.reportData = records
        } else {
          this.isLoading =false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.report || responseReturn.location || responseReturn.start_date || responseReturn.end_date) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }        
        }
      } catch (error) {
        this.isLoading =false
        console.log(error)
      }
    },
    openDownloadTab(key) {
      let baseUrl = window.location.origin
      window.open(baseUrl+'/auth/download?key='+key, '_blank')
    },
    async downloadFile() {
      try {
        this.isLoading = true

        const params = {
          report: this.report,
          location: this.location.id,
          start_date: this.filterDate.start,
          end_date: this.filterDate.end
        }
        const response = await materialReportServices.export(params)
        if (response.data.status === 'success') {
          this.isLoading =false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          this.$toasted.success(response.data.message, this.toastedOptions)

          const key = response.data.data
          this.openDownloadTab(key)
        } else {
          this.isLoading =false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.report || responseReturn.location || responseReturn.start_date || responseReturn.end_date) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }        
        }
      } catch (error) {
        this.isLoading =false
        console.log(error)
      }
    },    
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    toFixed(num, digits) {
      let result = format.toFixed(num, digits)
      return result
    },
    toggleFilter() {
      this.showFilter = !this.showFilter
    }
  },
  created() {
    this.fetchFactory()
  },
  mounted() {
    setTimeout(() => {
      this.fetchData()
    }, 2500);
  },
  watch: {
    report: function() {
      this.report === 'Stock Recapitulation' ? this.isDisabledLocation = true : this.isDisabledLocation = false
      this.reportData = []
    },                  
  } 
}
</script>