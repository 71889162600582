import axios from '@/axios'

export default {
  fetchAll(params){
    return axios.get(`/collections/all`, {params})
  },
  fetchLimit(params){
    return axios.get(`/collections`, {params})
  },  
  fetchById(id){
    return axios.get(`/collections/${id}`)
  },    
  create(payload){
    return axios.post(`/collections`, payload)
  },
  update(id, payload){
    return axios.post(`/collections/${id}`, payload)
  },
  delete(id){
    return axios.delete(`/collections/${id}`)
  }, 
  deleteMultiple(payload){
    return axios.delete(`/collections`, payload)
  },
  fetchDataOptions(params){
    return axios.get(`/collections/options`, {params})
  } 
}