var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"bg-white shadow-lg rounded-lg",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.create)}}},[_c('div',{staticClass:"p-10"},[_c('div',{staticClass:"pb-2 mb-4 border-b border-gray-200"},[_c('h2',{staticClass:"text-gray-800 text-2xl font-medium"},[_vm._v("Create Order")])]),_c('div',{staticClass:"md:flex gap-6"},[_c('div',{staticClass:"w-full md:w-1/2 mb-4"},[_c('ValidationProvider',{attrs:{"name":"Customer Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-control",attrs:{"for":"customer_name"}},[_vm._v("Customer Name "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customerName),expression:"customerName"}],staticClass:"form-control",attrs:{"id":"customer_name","type":"text"},domProps:{"value":(_vm.customerName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.customerName=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.customer_name)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.customer_name[0]))])]):_vm._e()],1),_c('div',{staticClass:"w-full md:w-1/2 mb-4"},[_c('ValidationProvider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-control",attrs:{"for":"country"}},[_vm._v("Country "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.country),expression:"country"}],staticClass:"form-control",attrs:{"id":"country","type":"text"},domProps:{"value":(_vm.country)},on:{"input":function($event){if($event.target.composing){ return; }_vm.country=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.country)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.country[0]))])]):_vm._e()],1)]),_c('div',{staticClass:"md:flex gap-6"},[_c('div',{staticClass:"flex md:w-1/2 gap-4 md:gap-6 mb-4"},[_c('div',{staticClass:"w-full md:w-1/2"},[_c('ValidationProvider',{attrs:{"name":"Order Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-control",attrs:{"for":"order_no"}},[_vm._v("Order Number "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.orderNumber),expression:"orderNumber"}],staticClass:"form-control",attrs:{"id":"order_no","type":"text"},domProps:{"value":(_vm.orderNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.orderNumber=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.order_no)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.order_no[0]))])]):_vm._e()],1),_c('div',{staticClass:"w-full md:w-1/2"},[_c('ValidationProvider',{attrs:{"name":"Order Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-control",attrs:{"for":"type"}},[_vm._v("Order Type "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('MultiSelect',{attrs:{"id":"type","options":_vm.orderTypes,"value":_vm.orderType,"track-by":"id","label":"name","showLabels":false},model:{value:(_vm.orderType),callback:function ($$v) {_vm.orderType=$$v},expression:"orderType"}},[_c('template',{slot:"caret"},[_c('div',[_c('div',{staticClass:"multiselect__select"},[_c('span',[_c('svg',{staticClass:"text-gray-500 mt-2 ml-2 w-5 h-5 fill-current",attrs:{"viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"}})])])])])])],2),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.type)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.type[0]))])]):_vm._e()],1)]),_c('div',{staticClass:"flex md:w-1/2 gap-4 md:gap-6 mb-4"},[_c('div',{staticClass:"md:w-1/2"},[_c('ValidationProvider',{attrs:{"name":"Delivery Date Production","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-date-picker',{attrs:{"mode":"date","masks":_vm.masks,"color":"purple","title-position":"left","popover":{ visibility: 'click' },"attributes":_vm.attrs},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('div',{staticClass:"md:flex gap-6"},[_c('div',{staticClass:"w-full"},[_c('label',{staticClass:"label-control",attrs:{"for":"delivery_date_production"}},[_vm._v("Delivery Date Production "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('div',{staticClass:"relative flex justify-between items-center"},[_c('input',_vm._g({staticClass:"form-control",attrs:{"id":"delivery_date_production","type":"text"},domProps:{"value":inputValue}},inputEvents)),_c('span',{staticClass:"h-full absolute pointer-events-none right-0"},[_c('DateRange',{staticClass:"m-3"})],1)]),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true),model:{value:(_vm.deliveryDateProduction),callback:function ($$v) {_vm.deliveryDateProduction=$$v},expression:"deliveryDateProduction"}})]}}],null,true)}),(_vm.error.delivery_date_production)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.delivery_date_production[0]))])]):_vm._e()],1),_c('div',{staticClass:"md:w-1/2"},[_c('ValidationProvider',{attrs:{"name":"Delivery Date for Customer From","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-date-picker',{attrs:{"mode":"date","masks":_vm.masks,"color":"purple","title-position":"left","popover":{ visibility: 'click' },"attributes":_vm.attrs},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('div',{staticClass:"md:flex gap-6"},[_c('div',{staticClass:"w-full"},[_c('label',{staticClass:"label-control",attrs:{"for":"delivery_date_from"}},[_vm._v("Delivery Date for Customer From "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('div',{staticClass:"relative flex justify-between items-center"},[_c('input',_vm._g({staticClass:"form-control",attrs:{"id":"delivery_date_from","type":"text"},domProps:{"value":inputValue}},inputEvents)),_c('span',{staticClass:"h-full absolute pointer-events-none right-0"},[_c('DateRange',{staticClass:"m-3"})],1)]),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true),model:{value:(_vm.customerDeliveryDateFrom),callback:function ($$v) {_vm.customerDeliveryDateFrom=$$v},expression:"customerDeliveryDateFrom"}})]}}],null,true)}),(_vm.error.delivery_date_from)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.delivery_date_from[0]))])]):_vm._e()],1)])]),_c('div',{staticClass:"md:flex gap-6"},[_c('div',{staticClass:"flex md:w-1/2 gap-4 md:gap-6 mb-4"},[_c('div',{staticClass:"md:w-1/2"},[_c('ValidationProvider',{attrs:{"name":"Delivery Date for Customer To","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-date-picker',{attrs:{"mode":"date","masks":_vm.masks,"color":"purple","title-position":"left","popover":{ visibility: 'click' },"attributes":_vm.attrs},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('div',{staticClass:"md:flex gap-6"},[_c('div',{staticClass:"w-full"},[_c('label',{staticClass:"label-control",attrs:{"for":"delivery_date_to"}},[_vm._v("Delivery Date for Customer To "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('div',{staticClass:"relative flex justify-between items-center"},[_c('input',_vm._g({staticClass:"form-control",attrs:{"id":"delivery_date_to","type":"text"},domProps:{"value":inputValue}},inputEvents)),_c('span',{staticClass:"h-full absolute pointer-events-none right-0"},[_c('DateRange',{staticClass:"m-3"})],1)]),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true),model:{value:(_vm.customerDeliveryDateTo),callback:function ($$v) {_vm.customerDeliveryDateTo=$$v},expression:"customerDeliveryDateTo"}})]}}],null,true)}),(_vm.error.delivery_date_to)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.delivery_date_to[0]))])]):_vm._e()],1),_c('div',{staticClass:"w-full md:w-1/2"},[_c('ValidationProvider',{attrs:{"name":"Season","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-control",attrs:{"for":"season"}},[_vm._v("Season "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('MultiSelect',{attrs:{"id":"season","options":_vm.seasons,"value":_vm.season,"track-by":"id","label":"name","showLabels":false},model:{value:(_vm.season),callback:function ($$v) {_vm.season=$$v},expression:"season"}},[_c('template',{slot:"caret"},[_c('div',[_c('div',{staticClass:"multiselect__select"},[_c('span',[_c('svg',{staticClass:"text-gray-500 mt-2 ml-2 w-5 h-5 fill-current",attrs:{"viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"}})])])])])])],2),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.season)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.season[0]))])]):_vm._e()],1)]),_c('div',{staticClass:"flex md:w-1/2 gap-4 md:gap-6 mb-4"},[_c('div',{staticClass:"w-full md:w-1/2"},[_c('ValidationProvider',{attrs:{"name":"Period","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-control",attrs:{"for":"period"}},[_vm._v("Period "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('MultiSelect',{attrs:{"id":"period","options":_vm.periods,"value":_vm.period,"track-by":"id","label":"name","showLabels":false},model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}},[_c('template',{slot:"caret"},[_c('div',[_c('div',{staticClass:"multiselect__select"},[_c('span',[_c('svg',{staticClass:"text-gray-500 mt-2 ml-2 w-5 h-5 fill-current",attrs:{"viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"}})])])])])])],2),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.period)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.period[0]))])]):_vm._e()],1),_c('div',{staticClass:"w-full md:w-1/2"},[_c('ValidationProvider',{attrs:{"name":"Factory","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-control",attrs:{"for":"factory"}},[_vm._v("Factory "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('MultiSelect',{attrs:{"id":"factory","options":_vm.factories,"value":_vm.factory,"track-by":"id","label":"name","showLabels":false},model:{value:(_vm.factory),callback:function ($$v) {_vm.factory=$$v},expression:"factory"}},[_c('template',{slot:"caret"},[_c('div',[_c('div',{staticClass:"multiselect__select"},[_c('span',[_c('svg',{staticClass:"text-gray-500 mt-2 ml-2 w-5 h-5 fill-current",attrs:{"viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"}})])])])])])],2),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.factory)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.factory[0]))])]):_vm._e()],1)])]),_c('div',{staticClass:"md:flex gap-6"},[_c('div',{staticClass:"w-full mb-4"},[_c('ValidationProvider',{attrs:{"name":"Remarks","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-control",attrs:{"for":"remarks"}},[_vm._v("Remarks")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.remarks),expression:"remarks"}],staticClass:"form-control",attrs:{"id":"remarks","rows":"3"},domProps:{"value":(_vm.remarks)},on:{"input":function($event){if($event.target.composing){ return; }_vm.remarks=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.remarks)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.remarks[0]))])]):_vm._e()],1)]),_c('div',{staticClass:"md:flex gap-4 mt-4 mb-2"},[_c('button',{staticClass:"btn btn--primary",attrs:{"disabled":_vm.isLoading,"type":"submit","alt":"Save","title":"Save"}},[(_vm.isLoading)?_c('span',{staticClass:"block mx-5"},[_c('Loading')],1):_c('SaveIcon',[_c('span',{staticClass:"ml-2 md:block hidden"},[_vm._v("Save & Continue")])])],1),_c('button',{staticClass:"btn btn--secondary",attrs:{"disabled":_vm.isLoading,"type":"button","alt":"Back","title":"Back"},on:{"click":function($event){return _vm.goBack()}}},[_c('BackIcon',[_c('span',{staticClass:"ml-2 md:block hidden"},[_vm._v("Back")])])],1)])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }