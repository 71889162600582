import axios from '@/axios'

export default {
  fetchAll(params){
    return axios.get(`/seasons/all`, {params})
  },
  fetchLimit(params){
    return axios.get(`/seasons`, {params})
  },  
  fetchById(id){
    return axios.get(`/seasons/${id}`)
  },    
  create(payload){
    return axios.post(`/seasons`, payload)
  },
  update(id, payload){
    return axios.put(`/seasons/${id}`, payload)
  },
  delete(id){
    return axios.delete(`/seasons/${id}`)
  }, 
  deleteMultiple(payload){
    return axios.delete(`/seasons`, payload)
  },
  fetchDataOptions(params){
    return axios.get(`/seasons/options`, {params})
  }  
}