import axios from '@/axios'

export default {
  fetchAll(params){
    return axios.get(`/products/all`, {params})
  },
  fetchLimit(params){
    return axios.get(`/products`, {params})
  },
  fetchSeasonDataOptions(){
    return axios.get(`/products/seasons`)
  },  
  fetchAllGroupByReference(params){
    return axios.get(`/products/reference`, {params})
  },
  fetchAllGroupByColor(params){
    return axios.get(`/products/color`, {params})
  },    
  fetchById(id){
    return axios.get(`/products/${id}`)
  },    
  create(payload){
    return axios.post(`/products`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }      
    })
  },
  update(id, payload){
    return axios.post(`/products/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }      
    })
  },
  delete(id){
    return axios.delete(`/products/${id}`)
  }, 
  deleteMultiple(payload){
    return axios.delete(`/products`, payload)
  },
  import(payload){
    return axios.post(`/products/import`, payload)
  },
  export(payload){
    return axios.post(`/products/export`, payload)
  },
  exportDraft(){
    return axios.post(`/products/draft`)
  },
  fetchDataOptions(params){
    return axios.get(`/products/options`, {params})
  },
  duplicate(id, payload){
    return axios.post(`/products/duplicate/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }      
    })
  },  
}