<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="bg-white shadow-lg rounded-lg" @submit.prevent="handleSubmit(update)">
        <div class="p-10">
          <div class="pb-2 mb-4 border-b border-gray-200">
            <h2 class="text-gray-800 text-2xl font-medium">Edit User</h2>
          </div>
          <div class="md:flex gap-6">
            <div class="w-full mb-4">
              <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                <label for="name" class="label-control">Name <span class="text-red-600">*</span></label>
                <input id="name" ref="name" type="text" v-model="name" maxlength="255" class="form-control" autofocus>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.name" class="text-sm text-red-600"><span>{{ error.name[0] }}</span></div>              
            </div>
          </div>          
          <div class="md:flex gap-6">
            <div class="md:w-1/2 mb-4">
              <ValidationProvider name="Username" rules="required" v-slot="{ errors }">
                <label for="username" class="label-control">Username <span class="text-red-600">*</span></label>
                <input id="username" type="text" v-model="username" class="form-control">
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.username" class="text-sm text-red-600"><span>{{ error.username[0] }}</span></div>
            </div>
            <div class="md:w-1/2 mb-4">
              <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                <label for="email" class="label-control">Email <span class="text-red-600">*</span></label>
                <input id="email" type="text" v-model="email" class="form-control">
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.email" class="text-sm text-red-600"><span>{{ error.email[0] }}</span></div>
            </div>
          </div>         
          <div class="md:flex gap-6">
            <div class="md:w-1/2 mb-4">
              <ValidationProvider name="Status" rules="required" v-slot="{ errors }">
                <label for="status" class="label-control">Status <span class="text-red-600">*</span></label>
                <div class="relative">
                  <select id="status" v-model="status" class="form-control appearance-none">
                    <option value="0" :selected="status === 0 ? 'selected' : ''">Active</option>
                    <option value="1" :selected="status === 1 ? 'selected' : ''">Blocked</option>
                  </select>
                  <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4">
                    <svg class="w-5 h-5 fill-current" viewBox="0 0 24 24">
                    <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z" />
                    </svg>
                  </div>
                </div>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.status" class="text-sm text-red-600"><span>{{ error.status[0] }}</span></div>
            </div>
            <div class="md:w-1/2 mb-4"></div>
          </div>
          <div class="w-full mb-6">
            <label class="py-2 font-semibold uppercase label-control">Roles</label>
            <div class="w-full p-3 bg-gray-100 border border-gray-200 rounded-md">
              <template v-for="(item, index) in userRoles">
                <div :key="index" class="w-full mb-2">
                  <input type="checkbox" class="checkbox-purple" v-model="checkedRoles" :value="item.id" :id="item.role_name">
                  <label :for="item.role_name" class="inline-label-control cursor-pointer"> {{ item.role_name }}</label>
                </div>
              </template>
            </div>
          </div>           
          <div class="md:flex gap-4 mt-4 mb-2">
            <button :disabled="isLoading" type="submit" class="btn btn--primary" alt="Save" title="Save">
              <span v-if="isLoading" class="block mx-5">
                <Loading />
              </span>
              <SaveIcon v-else>
                <span class="ml-2 md:block hidden">Save</span>
              </SaveIcon>
            </button>
            <button :disabled="isLoading" type="button" @click="goBack()" class="btn btn--secondary" alt="Back" title="Back">
              <BackIcon>
                <span class="ml-2 md:block hidden">Back</span>
              </BackIcon>              
            </button>
          </div>                                                  
        </div>
      </form>      
    </ValidationObserver>
  </div>
</template>

<script>
import userServices from '@/services/user/userServices'
import roleServices from '@/services/role/roleServices'
import SaveIcon from '../../icons/SaveIcon'
import BackIcon from '../../icons/BackIcon'
import Loading from '../../icons/Loading'

export default {
  name: 'EditUser',
  components: {
    SaveIcon,
    BackIcon,
    Loading
  },  
  data(){
    return {
      name: '',
      username: '',
      email: '',
      status: '',
      checkedRoles: [],
      userRoles: [],      
      record: [],
      error: [],
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async fetchRoleOptions() {
      try {
        const response = await roleServices.fetchAll(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.userRoles = records
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },     
    async update(){
      try {
        this.isLoading = true

        const id = this.$route.params.id
        const payload = {
          name: this.name,
          username: this.username,
          email: this.email,
          status: this.status,
          roles: this.checkedRoles
        }
        const response = await userServices.update(id, payload)
        if (response.data.status === 'success') {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false
                    
          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          /* RETURN TO LIST */
          this.goBack()
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.name || responseReturn.username || responseReturn.email || responseReturn.status) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },
    async fetchData(){
      try {
        const id = this.$route.params.id
        const response = await userServices.fetchById(id)
        if (response.data.status === 'success') {
          this.record = response.data.data

          this.name = this.record.name
          this.username = this.record.username
          this.email = this.record.email
          this.status = this.record.is_block
          this.checkedRoles = []
          this.record.roles.forEach(element => {
            this.checkedRoles.push(element.role_id)
          })
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)            
        }
      } catch (error) {
        console.log(error)
      }
    },
    clearForm(){
      this.name = ''
      this.username = ''
      this.email = ''
      this.status = ''
      this.checkedRoles = []
    },
    goBack() {
      window.history.length > 1 ? this.$router.back() : this.$router.push('/users')
    }       
  },
  created(){
    this.fetchRoleOptions()
    this.fetchData()
  }
}
</script>