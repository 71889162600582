<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="bg-white shadow-lg rounded-lg" @submit.prevent="handleSubmit(create)">
        <div class="p-10">
          <div class="pb-2 mb-4 border-b border-gray-200">
            <h2 class="text-gray-800 text-2xl font-medium">Create Receiving</h2>
          </div>
          <div class="md:flex gap-6">
            <div class="w-full md:flex md:w-1/2 gap-6 mb-4">
              <div class="w-full">
                <ValidationProvider name="Supplier" rules="required" v-slot="{ errors }">
                  <label for="supplier" class="label-control">Supplier <span class="text-red-600">*</span></label>
                  <MultiSelect id="supplier" v-model="supplier" :options="suppliers" :value="supplier" :searchable="true" track-by="id" label="name" :custom-label="nameWithAddress" placeholder="Type to Search">
                    <template slot="caret">
                      <div>
                        <div class="multiselect__select">
                          <span>
                            <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                              <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </template>
                  </MultiSelect>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="error.supplier" class="text-sm text-red-600"><span>{{ error.supplier[0] }}</span></div>
              </div>
            </div>
            <div class="w-full md:flex md:w-1/2 gap-6">
              <div class="w-full md:w-1/2 mb-4">
                <ValidationProvider name="Receive Location" rules="" v-slot="{ errors }">
                  <label for="location" class="label-control">Receive Location <span class="text-red-600">*</span></label>
                  <MultiSelect id="location" v-model="receiveLocation" :options="receiveLocations" :value="receiveLocation" :searchable="true" track-by="id" label="name" placeholder="Type to Search" :showLabels="false">
                    <template slot="caret">
                      <div>
                        <div class="multiselect__select">
                          <span>
                            <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                              <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </template>
                  </MultiSelect>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="error.location" class="text-sm text-red-600"><span>{{ error.location[0] }}</span></div>
              </div>
              <div class="w-full md:w-1/2 mb-4">
                <ValidationProvider name="Receive Date" rules="required" v-slot="{ errors }">
                  <v-date-picker v-model="receiveDate" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs">
                    <template v-slot="{ inputValue, inputEvents }">
                      <div class="md:flex gap-6">
                        <div class="w-full">
                          <label for="receive_date" class="label-control">Receive Date <span class="text-red-600">*</span></label>
                          <div class="relative flex justify-between items-center">
                            <input id="receive_date" type="text" class="form-control" :value="inputValue" v-on="inputEvents">
                            <span class="h-full absolute pointer-events-none right-0">
                              <DateRange class="m-3" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </template>
                  </v-date-picker>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="error.receive_date" class="text-sm text-red-600"><span>{{ error.receive_date[0] }}</span></div>
              </div>
            </div>
          </div>
          <div class="md:flex gap-6">
            <div class="w-full md:flex md:w-1/2 gap-6">
              <div class="w-full md:w-1/2 mb-4">
                <ValidationProvider name="Season" rules="required" v-slot="{ errors }">
                  <label for="season" class="label-control">Season <span class="text-red-600">*</span></label>
                  <MultiSelect id="season" v-model="season" :options="seasons" :value="season" track-by="id" label="name" :showLabels="false">
                    <template slot="caret">
                      <div>
                        <div class="multiselect__select">
                          <span>
                            <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                              <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </template>
                  </MultiSelect>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="error.season" class="text-sm text-red-600"><span>{{ error.season[0] }}</span></div>
              </div>
              <div class="w-full md:w-1/2 mb-4">
                <ValidationProvider name="Period" rules="required" v-slot="{ errors }">
                  <label for="period" class="label-control">Period <span class="text-red-600">*</span></label>
                  <MultiSelect id="period" v-model="period" :options="periods" :value="period" track-by="id" label="name" :showLabels="false">
                    <template slot="caret">
                      <div>
                        <div class="multiselect__select">
                          <span>
                            <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                              <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </template>
                  </MultiSelect>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="error.period" class="text-sm text-red-600"><span>{{ error.period[0] }}</span></div>
              </div>
            </div>
            <div class="w-full md:flex md:w-1/2 gap-6 mb-4"></div>
          </div>          
          <div class="md:flex gap-6">
            <div class="w-full mb-4">
              <ValidationProvider name="Remarks" rules="" v-slot="{ errors }">
                <label for="remarks" class="label-control">Remarks</label>
                <textarea id="remarks" v-model="remarks" class="form-control" rows="3"></textarea>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.remarks" class="text-sm text-red-600"><span>{{ error.remarks[0] }}</span></div>              
            </div>
          </div>
          <div class="md:flex gap-4 mt-4 mb-2">
            <button :disabled="isLoading" type="submit" class="btn btn--primary" alt="Save" title="Save">
              <span v-if="isLoading" class="block mx-5">
                <Loading />
              </span>
              <SaveIcon v-else>
                <span class="ml-2 md:block hidden">Save &amp; Continue</span>
              </SaveIcon>
            </button>
            <button :disabled="isLoading" type="button" @click="goBack()" class="btn btn--secondary" alt="Back" title="Back">
              <BackIcon>
                <span class="ml-2 md:block hidden">Back</span>
              </BackIcon>              
            </button>
          </div>                                                  
        </div>
      </form>      
    </ValidationObserver>
    <div class="fixed bg-gray-800 bg-opacity-25 w-full inset-0 top-16 md:top-0 z-50" :class="{ 'hidden': !showFunction }">
      <div class="w-52 ml-auto h-full bg-white shadow-lg right-0">
        <div class="flex p-5 border-b border-gray-200 items-center">
          <span class="flex-1 text-sm font-medium">Functions</span>
          <button type="button" class="flex right-0" @click="toggleFunction">
            <svg class="h-5 w-5 fill-current hover:text-gray-700" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
            </svg>
          </button>
        </div>
        <div class="p-5 items-center">
          <SupplierModal class="mb-4" />
        </div>
      </div>            
    </div>
    <div class="fixed w-20 top-0 bottom-0 right-0 z-20">
      <div class="absolute w-16 right-0 top-1/2 bg-green-500 text-white rounded-l-full shadow-lg" :class="{ 'hidden': showFunction }">
        <button class="block ml-2 p-3 cursor-pointer" v-tooltip="'Show Function'" @click="toggleFunction">
          <svg class="h-5 w-5 fill-current" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M22.6558 18.9896L13.5758 9.90957C14.5058 7.56957 14.0258 4.80957 12.1358 2.90957C9.83584 0.609575 6.25584 0.399574 3.70584 2.25957L7.54584 6.10957L6.12584 7.51957L2.29584 3.68957C0.435837 6.22957 0.645837 9.81957 2.94584 12.1096C4.80584 13.9696 7.51584 14.4596 9.83584 13.5896L18.9458 22.6996C19.3358 23.0896 19.9658 23.0896 20.3558 22.6996L22.6558 20.3996C23.0558 20.0196 23.0558 19.3896 22.6558 18.9896ZM19.6558 20.5896L10.1958 11.1296C9.58584 11.5796 8.90584 11.8496 8.19584 11.9496C6.83584 12.1496 5.40584 11.7396 4.36584 10.6996C3.41584 9.75957 2.97584 8.49957 3.04584 7.25957L6.13584 10.3496L10.3758 6.10957L7.28584 3.01957C8.52584 2.94957 9.77584 3.38957 10.7258 4.32957C11.8058 5.40957 12.2158 6.89957 11.9658 8.28957C11.8458 8.99957 11.5458 9.65957 11.0858 10.2496L20.5358 19.6996L19.6558 20.5896Z" />
          </svg>        
        </button>           
      </div>
    </div>    
  </div>
</template>

<script>
import factoryServices from '@/services/factory/factoryServices'
import receiveServices from '@/services/receive/receiveServices'
import supplierServices from '@/services/supplier/supplierServices'
import seasonServices from '@/services/season/seasonServices'
import SaveIcon from '../../icons/SaveIcon'
import BackIcon from '../../icons/BackIcon'
import Loading from '../../icons/Loading'
import DateRange from '../../icons/DateRange'
import SupplierModal from '../supplier/Modal'

export default {
  name: 'CreateReceive',
  components: {
    SaveIcon,
    BackIcon,
    Loading,
    DateRange,
    SupplierModal
  },
  data(){
    return {
      reffNumber: '',
      receiveDate: '',
      masks: {
        input: 'YYYY-MM-DD',
      },
      attrs: [
        {
          key: 'today',
          highlight: 'red',
          dates: new Date(),
        },
      ],      
      remarks: '',
      supplier: '',
      suppliers: [],
      receiveLocation: '',
      receiveLocations: [],
      season: '',
      seasons: [],
      period: '',
      periods: [
        { 'id': 1, 'name': 'Period 1' }, { 'id': 2, 'name': 'Period 2' }, { 'id': 3, 'name': 'Period 3' },
        { 'id': 4, 'name': 'Period 4' }, { 'id': 5, 'name': 'Period 5' }, { 'id': 6, 'name': 'Period 6' }
      ],      
      error: [],
      prevRoute: null,
      showFunction: false,
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },  
  methods: {
    async create(){
      try {
        this.isLoading = true
        const payload = {
          supplier: this.supplier.id,
          location: this.receiveLocation.id,
          reff_no: this.reffNumber,
          receive_date: this.receiveDate,
          season: this.season.id,
          period: this.period.id,          
          remarks: this.remarks
        }

        const response = await receiveServices.create(payload)
        if (response.data.status === 'success') {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false
                    
          /* CLEAR INPUT FORM */
          this.clearForm()

          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          /* SET NEW ID & REDIRECT TO EDIT PAGE WITH CREATED ID */
          this.$store.dispatch('setNewReceiveID', response.data.data.id)
          this.$router.push({ name: 'receiveEdit', params: { id: response.data.data.id} })
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.supplier || responseReturn.location || responseReturn.reff_no || responseReturn.receive_date 
              || responseReturn.season || responseReturn.period || responseReturn.remarks) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },
    async fetchSupplierData() {
      try {
        const response = await supplierServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.suppliers = []
          records.forEach(element => {
            this.suppliers.push({
              'id': element.id,
              'name': element.supplier_name,
              'address': element.address
            })
          })
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchSeasonData() {
      try {
        const response = await seasonServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.seasons = []
          records.forEach(element => {
            this.seasons.push({
              'id': element.id,
              'name': element.season_name
            })
          });          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },    
    async fetchFactory() {
      try {
        const response = await factoryServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.receiveLocations = []
          records.forEach(element => {
            this.receiveLocations.push({
              'id': element.id,
              'name': element.factory_name,
              'code': element.factory_code
            })
          })
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }        
      } catch (error) {
        console.log(error)
      }
    },      
    clearForm(){
      this.supplier = ''
      this.season = ''
      this.period = ''
      this.reffNumber = ''
      this.receiveDate = ''
      this.remarks = ''
    },
    nameWithAddress ({ name, address }) {
      if (address) {
        return `${name} [ ${address} ]`
      }
      return `${name}`
    },  
    toggleFunction() {
      this.showFunction = !this.showFunction
    },
    goBack() {
      window.history.length > 1 ? this.$router.back() : this.$router.push('/receives')
    }        
  },
  created(){
    this.fetchSupplierData()
    this.fetchSeasonData()
    this.fetchFactory()
  },
  mounted() {
    const thisInstance = this
    this.$root.$on('fetchSupplier', function(){
      thisInstance.fetchSupplierData()
    })               
  },  
  watch: {
    prevRoute: function() {
      this.$store.dispatch('setIndexReceiveRoute', this.prevRoute)
    }
  }  
}
</script>