<template>
  <div>
    <button type="button" class="btn btn--success ml-2" @click="toggleModal">
      <DownloadIcon>
        <span class="ml-2 md:block hidden">Export</span>
      </DownloadIcon>
    </button>
    <!-- Import Modal Dialog -->
    <modal :show="showModal" @close="showModal = false" addClass="modal-xl" modalOrientation="pt-16 md:pt-10">
      <template v-slot:header><h3>{{ modalTitle }}</h3></template>
      <template v-slot:body>
        <div class="w-full md:flex justify-between items-center gap-4 mb-2">
          <div class="md:flex">
          </div>
          <div class="md:flex">
            <button :disabled="isLoading" @click="downloadFile()" type="button" class="btn btn--success ml-2">
              <DownloadIcon>
                <span class="ml-2 md:block hidden">Download</span>
              </DownloadIcon>
            </button>                
          </div>
        </div>
        <div class="mb-4 bg-white shadow-lg rounded-lg overflow-y-auto">
          <table>
            <thead>
              <tr>
                <th scope="col" class="text-left">Supplier Name</th>
                <th scope="col" class="text-left">Address</th>
                <th scope="col" class="text-left">Postal Code</th>
                <th scope="col" class="text-left">City</th>
                <th scope="col" class="text-left">Country</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="suppliers.length === 0" class="border-b border-gray-200"><td class="text-center" colspan="5">No data to display</td></tr>
              <tr v-for="(supplier, index) in suppliers" :key="supplier.id" class="border-b border-gray-200 hover:bg-gray-100" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">
                <td class="text-left">{{ supplier.supplier_name }}</td>
                <td class="text-left">{{ supplier.address }}</td>
                <td class="text-left">{{ supplier.postal_code }}</td>
                <td class="text-left">{{ supplier.city }}</td>
                <td class="text-left">{{ supplier.country }}</td>
              </tr>              
            </tbody>
          </table>
        </div>
      </template>
      <template v-slot:footer>     
      </template> 
    </modal>
  </div>
</template>

<script>
import supplierServices from '@/services/supplier/supplierServices'
import Modal from '../../widgets/Modal'
import DownloadIcon from '../../icons/DownloadIcon'

export default {
  name: 'ExportSupplier',
  components: {
    Modal,
    DownloadIcon
  },
  data() {
    return {
      suppliers: [],    
      error: [],
      isLoading: false, 
      modalTitle: '',     
      showModal: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }     
    }
  },
  methods: {
    async fetchData() {
      try {
        this.isLoading = true

        const response = await supplierServices.fetchAll()
        if (response.data.status === 'success') {
          this.isLoading =false
          this.suppliers = response.data.data
        } else {
          this.isLoading =false

          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        this.isLoading =false
        console.log(error)
      }
    },    
    toggleModal() {
      this.fetchData()
      this.showModal = true
      this.modalTitle = 'Export Suppliers'
    },
    openDownloadTab(key) {
      let baseUrl = window.location.origin
      window.open(baseUrl+'/auth/download?key='+key, '_blank')
    },       
    async downloadFile() {
      try {
        this.isLoading = true
        const params = {
          take: '',
          page: '',
          search: '',
          date_field: '',
          start: '',
          end: '',
          sort_field: '',
          sort_option: ''          
        }
        const response = await supplierServices.export(params)
        if (response.data.status === 'success') {
          this.isLoading = false
          this.$toasted.success(response.data.message, this.toastedOptions)

          const key = response.data.data
          this.openDownloadTab(key)
        } else {
          this.isLoading = false
          this.$toasted.error(response.data.message, this.toastedOptions)
        }
      } catch (error) {
        this.isLoading = false
        console.log(error)
      }
    } 
  }
}
</script>