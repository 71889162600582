var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"bg-white shadow-lg rounded-lg",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('div',{staticClass:"p-10"},[_c('div',{staticClass:"pb-2 mb-4 border-b border-gray-200"},[_c('h2',{staticClass:"text-gray-800 text-2xl font-medium"},[_vm._v("Default Variable")])]),_c('div',{staticClass:"md:flex gap-6"},[_c('div',{staticClass:"md:w-1/2 mb-4"},[_c('ValidationProvider',{attrs:{"name":"Tax / Customs","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-control",attrs:{"for":"tax_customs"}},[_vm._v("Tax / Customs "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('div',{staticClass:"relative flex-1"},[_c('span',{staticClass:"absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400",attrs:{"fill":"currentColor"}},[_vm._v("%")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.taxCustoms),expression:"taxCustoms"}],staticClass:"form-control",attrs:{"id":"tax_customs","type":"text"},domProps:{"value":(_vm.taxCustoms)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.taxCustoms=$event.target.value},_vm.handleInputTaxCustoms]}})]),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.tax_customs)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.tax_customs[0]))])]):_vm._e()],1),_c('div',{staticClass:"md:w-1/2 mb-4"},[_c('ValidationProvider',{attrs:{"name":"Transport / Packaging","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-control",attrs:{"for":"transport_packaging"}},[_vm._v("Transport / Packaging "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('div',{staticClass:"relative flex-1"},[_c('span',{staticClass:"absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400",attrs:{"fill":"currentColor"}},[_vm._v("€")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.transportPackaging),expression:"transportPackaging"}],staticClass:"form-control",attrs:{"id":"transport_packaging","type":"text"},domProps:{"value":(_vm.transportPackaging)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.transportPackaging=$event.target.value},_vm.handleInputTransportPackaging]}})]),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.transport_packaging)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.transport_packaging[0]))])]):_vm._e()],1)]),_c('div',{staticClass:"md:flex gap-6 border-b border-gray-200"},[_c('div',{staticClass:"md:w-1/2 mb-4"},[_c('ValidationProvider',{attrs:{"name":"Agent Commision","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-control",attrs:{"for":"agent_commision"}},[_vm._v("Agent Commision "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('div',{staticClass:"relative flex-1"},[_c('span',{staticClass:"absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400",attrs:{"fill":"currentColor"}},[_vm._v("%")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.agentCommision),expression:"agentCommision"}],staticClass:"form-control",attrs:{"id":"agent_commision","type":"text"},domProps:{"value":(_vm.agentCommision)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.agentCommision=$event.target.value},_vm.handleInputAgentCommision]}})]),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.agent_commision)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.agent_commision[0]))])]):_vm._e()],1),_c('div',{staticClass:"md:w-1/2 mb-4"})]),_c('div',{staticClass:"md:flex gap-6 mt-4"},[_c('div',{staticClass:"flex md:w-1/2 gap-6 mb-4"},[_c('div',{staticClass:"md:w-1/2"},[_c('ValidationProvider',{attrs:{"name":"Coef. Wholesale Price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-control",attrs:{"for":"coef_wholesale_price"}},[_vm._v("Coef. Wholesale Price "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.coefWholesalePrice),expression:"coefWholesalePrice"}],staticClass:"form-control",attrs:{"id":"coef_wholesale_price","type":"text"},domProps:{"value":(_vm.coefWholesalePrice)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.coefWholesalePrice=$event.target.value},_vm.handleInputCoefWholesalePrice]}}),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.coef_wholesale_price)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.coef_wholesale_price[0]))])]):_vm._e()],1),_c('div',{staticClass:"md:w-1/2"},[_c('ValidationProvider',{attrs:{"name":"Coef. Retail Price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-control",attrs:{"for":"coef_retail_price"}},[_vm._v("Coef. Retail Price "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.coefRetailPrice),expression:"coefRetailPrice"}],staticClass:"form-control",attrs:{"id":"coef_retail_price","type":"text"},domProps:{"value":(_vm.coefRetailPrice)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.coefRetailPrice=$event.target.value},_vm.handleInputCoefRetailPrice]}}),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.coef_retail_price)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.coef_retail_price[0]))])]):_vm._e()],1)]),_c('div',{staticClass:"flex md:w-1/2 gap-6 mb-4"},[_c('div',{staticClass:"md:w-1/2"},[_c('ValidationProvider',{attrs:{"name":"Coef. Wholesale (USD)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-control",attrs:{"for":"coef_wholesale_usd"}},[_vm._v("Coef. Wholesale (USD) "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.coefWholesaleUSD),expression:"coefWholesaleUSD"}],staticClass:"form-control",attrs:{"id":"coef_wholesale_usd","type":"text"},domProps:{"value":(_vm.coefWholesaleUSD)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.coefWholesaleUSD=$event.target.value},_vm.handleInputCoefWholesaleUSD]}}),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.coef_wholesale_usd)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.coef_wholesale_usd[0]))])]):_vm._e()],1),_c('div',{staticClass:"md:w-1/2"},[_c('ValidationProvider',{attrs:{"name":"Coef. Retail (USD)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-control",attrs:{"for":"coef_retail_usd"}},[_vm._v("Coef. Retail (USD) "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.coefRetailUSD),expression:"coefRetailUSD"}],staticClass:"form-control",attrs:{"id":"coef_retail_usd","type":"text"},domProps:{"value":(_vm.coefRetailUSD)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.coefRetailUSD=$event.target.value},_vm.handleInputCoefRetailUSD]}}),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.coef_retail_usd)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.coef_retail_usd[0]))])]):_vm._e()],1)])]),_c('div',{staticClass:"md:flex gap-4 mt-4 mb-2"},[_c('button',{staticClass:"btn btn--primary",attrs:{"disabled":_vm.isLoading,"type":"submit","alt":"Save","title":"Save"}},[(_vm.isLoading)?_c('span',{staticClass:"block mx-5"},[_c('Loading')],1):_c('SaveIcon',[_c('span',{staticClass:"ml-2 md:block hidden"},[_vm._v("Save")])])],1),_c('button',{staticClass:"btn btn--secondary",attrs:{"disabled":_vm.isLoading,"type":"button","alt":"Back","title":"Back"},on:{"click":function($event){return _vm.goBack()}}},[_c('BackIcon',[_c('span',{staticClass:"ml-2 md:block hidden"},[_vm._v("Back")])])],1)])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }