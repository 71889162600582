<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="bg-white shadow-lg rounded-lg" @submit.prevent="handleSubmit(update)">    
        <div class="p-10">
          <div class="pb-2 mb-4 border-b border-gray-200">
            <h2 class="text-gray-800 text-2xl font-medium">Edit Product</h2>
          </div>
          <div class="md:flex gap-6">
            <div class="md:flex-col w-full md:w-4/6">
              <div class="md:flex gap-4">
                <div class="md:w-1/2 mb-2">
                  <ValidationProvider name="Season Creation" rules="" v-slot="{ errors }">
                    <label for="season_creation" class="label-control">Season Creation <span class="text-red-600">*</span></label>
                    <input id="season_creation" ref="season_creation" type="text" v-model="seasonCreation" maxlength="255" class="form-control">
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <div v-if="error.season_creation" class="text-sm text-red-600"><span>{{ error.season_creation[0] }}</span></div>
                </div>
                <div class="md:w-1/2 mb-2">
                  <ValidationProvider name="Collection" rules="required" v-slot="{ errors }">
                    <label for="collection" class="label-control">Collection <span class="text-red-600">*</span></label>
                    <MultiSelect id="collection" v-model="collection" :options="collections" :value="collection" :searchable="true" track-by="id" label="name" placeholder="Type to Search">
                      <template slot="caret">
                        <div>
                          <div class="multiselect__select">
                            <span>
                              <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                                <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </template>
                    </MultiSelect>
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.collection" class="text-sm text-red-600"><span>{{ error.collection[0] }}</span></div>
                </div>
              </div>            
              <div class="md:flex gap-4">
                <div class="md:w-1/2 mb-2">
                  <ValidationProvider name="Barcode" rules="required|numeric" v-slot="{ errors }">
                    <label for="barcode" class="label-control">Barcode <span class="text-red-600">*</span></label>
                    <input id="barcode" ref="barcode" type="text" v-model="barcode" maxlength="255" class="form-control">
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <div v-if="error.barcode" class="text-sm text-red-600"><span>{{ error.barcode[0] }}</span></div>
                </div>
                <div class="md:w-1/2 mb-2">
                  <ValidationProvider name="Reference" rules="required" v-slot="{ errors }">
                    <label for="reference" class="label-control">Reference <span class="text-red-600">*</span></label>
                    <input id="reference" type="text" v-model="reference" class="form-control">
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.reference" class="text-sm text-red-600"><span>{{ error.reference[0] }}</span></div>
                </div>
              </div>
              <div class="md:flex gap-4">
                <div class="md:w-1/2 mb-2">
                  <ValidationProvider name="Color" rules="required" v-slot="{ errors }">
                    <label for="color" class="label-control">Color <span class="text-red-600">*</span></label>
                    <input id="color" type="text" v-model="color" class="form-control">
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.color" class="text-sm text-red-600"><span>{{ error.color[0] }}</span></div>
                </div>
                <div class="md:w-1/2 mb-2">
                  <ValidationProvider name="Size" rules="" v-slot="{ errors }">
                    <label for="size" class="label-control">Size</label>
                    <input id="size" type="text" v-model="size" class="form-control">
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.size" class="text-sm text-red-600"><span>{{ error.size[0] }}</span></div>
                </div>            
              </div>
              <div class="md:flex gap-4">
                <div class="w-full mb-2">
                  <ValidationProvider name="Description" rules="required" v-slot="{ errors }">
                    <label for="description" class="label-control">Description <span class="text-red-600">*</span></label>
                    <input id="description" type="text" v-model="description" class="form-control">
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <div v-if="error.description" class="text-sm text-red-600"><span>{{ error.description[0] }}</span></div>
                </div>
              </div>
              <div class="md:flex gap-4">
                <div class="md:w-1/2 mb-2">
                  <ValidationProvider name="Weight" rules="" v-slot="{ errors }">
                    <label for="weight" class="label-control">Weight</label>
                    <input id="weight" type="text" v-model="weight" class="form-control">
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.weight" class="text-sm text-red-600"><span>{{ error.weight[0] }}</span></div>
                </div>
                <div class="md:w-1/2 mb-2">
                  <ValidationProvider name="High" rules="" v-slot="{ errors }">
                    <label for="high" class="label-control">Height</label>
                    <input id="high" type="text" v-model="high" class="form-control">
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.high" class="text-sm text-red-600"><span>{{ error.high[0] }}</span></div>
                </div>            
              </div>
              <div class="md:flex gap-4">
                <div class="md:w-1/2 mb-2">
                  <ValidationProvider name="Width" rules="" v-slot="{ errors }">
                    <label for="width" class="label-control">Width</label>
                    <input id="width" type="text" v-model="width" class="form-control">
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.width" class="text-sm text-red-600"><span>{{ error.width[0] }}</span></div>
                </div>
                <div class="md:w-1/2 mb-2">
                  <ValidationProvider name="Long" rules="" v-slot="{ errors }">
                    <label for="long" class="label-control">Length</label>
                    <input id="long" type="text" v-model="long" class="form-control">
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.long" class="text-sm text-red-600"><span>{{ error.long[0] }}</span></div>
                </div>            
              </div>
              <div class="md:flex gap-4">
                <div class="w-full mb-2">
                  <ValidationProvider name="Carats" rules="" v-slot="{ errors }">
                    <label for="carats" class="label-control">Carats</label>
                    <input id="carats" type="text" v-model="carats" class="form-control">
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.carats" class="text-sm text-red-600"><span>{{ error.carats[0] }}</span></div>
                </div>           
              </div>
              <div class="md:flex gap-4">
                <div class="w-full mb-2">
                  <ValidationProvider name="Annotation" rules="" v-slot="{ errors }">
                    <label for="annotation" class="label-control">Annotation</label>
                    <textarea id="annotation" v-model="annotation" class="form-control" rows="3"></textarea>
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.annotation" class="text-sm text-red-600"><span>{{ error.annotation[0] }}</span></div>              
                </div>
              </div>
              <div class="md:flex gap-4">
                <div class="md:w-1/2 mb-2">
                  <ValidationProvider name="Unit" rules="required" v-slot="{ errors }">
                    <label for="unit" class="label-control">Unit <span class="text-red-600">*</span></label>
                    <MultiSelect id="unit" ref="unit" v-model="unit" :options="units" :value="unit" :searchable="true" placeholder="Type to Search">
                      <template slot="caret">
                        <div>
                          <div class="multiselect__select">
                            <span>
                              <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                                <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </template>
                    </MultiSelect>
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.unit" class="text-sm text-red-600"><span>{{ error.unit[0] }}</span></div>
                </div>
                <div class="flex md:w-1/2 gap-2 md:gap-4 mb-2">
                  <div class="w-full md:w-1/2">
                    <ValidationProvider name="Currency" rules="required" v-slot="{ errors }">
                      <label for="currency" class="label-control">Currency <span class="text-red-600">*</span></label>
                      <MultiSelect id="currency" ref="currency" v-model="currency" :options="currencies" :value="currency" :searchable="false" track-by="id" label="currency" :showLabels="false">
                        <template slot="caret">
                          <div>
                            <div class="multiselect__select">
                              <span>
                                <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                                  <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </template>
                      </MultiSelect>
                      <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                    </ValidationProvider>
                    <div v-if="error.currency" class="text-sm text-red-600"><span>{{ error.currency[0] }}</span></div>              
                  </div>
                  <div class="w-full md:w-1/2">
                    <ValidationProvider name="Rate" rules="required" v-slot="{ errors }">
                      <label for="change_rate" class="label-control">Rate (EUR to IDR) <span class="text-red-600">*</span></label>
                      <input id="change_rate" type="text" v-model="changeRate" class="form-control text-right" @input="handleInputRate($event)">
                      <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <div v-if="error.change_rate" class="text-sm text-red-600"><span>{{ error.change_rate[0] }}</span></div>              
                  </div>
                </div>
              </div>          
            </div>
            <div class="md:flex-col w-full md:w-2/6">
              <div class="w-full mb-2">
                <label for="image" class="label-control">Image</label>
                <div v-if="!image">
                  <div class="md:h-64 border-2 border-dashed border-gray-400 flex items-center justify-center p-2 rounded-lg mb-2">
                    <img class="m-auto" :src="fileBox" />
                  </div>
                  <input type="file" id="image" ref="image" @change="onFileChange">
                </div>
                <div v-else>
                  <div class="md:h-auto border-2 border-dashed border-gray-400 flex items-center justify-center p-2 rounded-lg mb-2">
                    <img class="m-auto" :src="image" />
                  </div>
                  <div v-if="error.image" class="text-sm text-red-600 mb-2"><span>{{ error.image[0] }}</span></div>
                  <button class="btn btn--danger" @click="removeImage">Remove Image</button>
                </div>
              </div>            
            </div>           
          </div>
          <!-- CALL PRODUCT RECIPE -->
          <ProductRecipe ref="productRecipe" show="true" class="mb-10" />

          <!-- SUMMARY DATA SECTION -->
          <div class="w-full md:flex gap-2 mb-4 md:mb-1">
            <div class="w-full md:w-1/2 gap-2">
              <label for="manufacturing_cost1" class="label-control md:pr-4 md:py-3 md:text-right">Manufacturing Cost 1</label>
            </div>            
            <div class="flex md:w-1/2 gap-2">
              <div class="w-full md:flex md:w-3/5 gap-2">
                <div class="w-full md:w-3/12">
                  <ValidationProvider name="Manufacturing Cost 1 Currency" rules="" v-slot="{ errors }">
                    <MultiSelect id="mfg_cost1_currency" ref="mfg_cost1_currency" v-model="mfgCost1Currency" :options="currencies" :value="mfgCost1Currency" :searchable="false" track-by="id" label="currency" :showLabels="false" placeholder="" @input="handleInputMfgCost1Currency">
                      <template slot="caret">
                        <div>
                          <div class="multiselect__select">
                            <span>
                              <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                                <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </template>
                    </MultiSelect>
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <div v-if="error.mfg_cost1_currency" class="text-sm text-red-600"><span>{{ error.mfg_cost1_currency[0] }}</span></div>
                </div>
                <div class="w-full md:w-4/12">
                  <ValidationProvider name="Manufacturing Cost 1 Rate" rules="" v-slot="{ errors }">
                    <input id="mfg_cost1_rate" type="text" v-model="mfgCost1Rate" class="form-control text-right" @input="handleInputMfgCost1Rate">
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <div v-if="error.mfg_cost1_rate" class="text-sm text-red-600"><span>{{ error.mfg_cost1_rate[0] }}</span></div>
                </div>                
                <div class="w-full md:w-5/12">
                  <ValidationProvider name="Manufacturing Cost 1" rules="required" v-slot="{ errors }">
                    <div class="relative flex-1">
                      <span fill="currentColor" class="absolute right-2 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">{{ mfgCost1Currency ? mfgCost1Currency.symbol : '' }}</span>
                      <input id="manufacturing_cost1" type="text" v-model="manufacturingCost1" class="form-control text-right right-symbol" @input="handleInputMfgCost1">
                    </div>
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <div v-if="error.manufacturing_cost1" class="text-sm text-red-600"><span>{{ error.manufacturing_cost1[0] }}</span></div>
                </div>
              </div>
              <div class="w-full md:w-2/5">
                <ValidationProvider name="Manufacturing Cost 1 ( € )" rules="required" v-slot="{ errors }">
                  <div class="relative flex-1">
                    <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
                    <input id="euro_manufacturing_cost1" type="text" v-model="euroManufacturingCost1" class="form-control text-right right-symbol" @input="handleInputEuroMfgCost1">
                  </div>                  
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
                <div v-if="error.euro_manufacturing_cost1" class="text-sm text-red-600"><span>{{ error.euro_manufacturing_cost1[0] }}</span></div>
              </div>
            </div>                                  
          </div>
          <div class="w-full md:flex gap-2 mb-4 md:mb-1">
            <div class="w-full md:w-1/2 gap-2">
              <label for="manufacturing_cost2" class="label-control md:pr-4 md:py-3 md:text-right">Manufacturing Cost 2</label>
            </div>
            <div class="flex md:w-1/2 gap-2">
              <div class="w-full md:flex md:w-3/5 gap-2">
                <div class="w-full md:w-3/12">
                  <ValidationProvider name="Manufacturing Cost 2 Currency" rules="" v-slot="{ errors }">
                    <MultiSelect id="mfg_cost2_currency" ref="mfg_cost2_currency" v-model="mfgCost2Currency" :options="currencies" :value="mfgCost2Currency" :searchable="false" track-by="id" label="currency" :showLabels="false" placeholder="" @input="handleInputMfgCost2Currency">
                      <template slot="caret">
                        <div>
                          <div class="multiselect__select">
                            <span>
                              <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                                <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </template>
                    </MultiSelect>
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <div v-if="error.mfg_cost2_currency" class="text-sm text-red-600"><span>{{ error.mfg_cost2_currency[0] }}</span></div>
                </div>
                <div class="w-full md:w-4/12">
                  <ValidationProvider name="Manufacturing Cost 2 Rate" rules="" v-slot="{ errors }">
                    <input id="mfg_cost2_rate" type="text" v-model="mfgCost2Rate" class="form-control text-right" @input="handleInputMfgCost2Rate">
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <div v-if="error.mfg_cost2_rate" class="text-sm text-red-600"><span>{{ error.mfg_cost2_rate[0] }}</span></div>
                </div>                
                <div class="w-full md:w-5/12">
                  <ValidationProvider name="Manufacturing Cost 2" rules="required" v-slot="{ errors }">
                    <div class="relative flex-1">
                      <span fill="currentColor" class="absolute right-2 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">{{ mfgCost2Currency ? mfgCost2Currency.symbol : '' }}</span>
                      <input id="manufacturing_cost2" type="text" v-model="manufacturingCost2" class="form-control text-right right-symbol" @input="handleInputMfgCost2">
                    </div>
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.manufacturing_cost2" class="text-sm text-red-600"><span>{{ error.manufacturing_cost2[0] }}</span></div>
                </div>
              </div>
              <div class="w-full md:w-2/5">
                <ValidationProvider name="Manufacturing Cost 2 ( € )" rules="required" v-slot="{ errors }">
                  <div class="relative flex-1">
                    <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
                    <input id="euro_manufacturing_cost2" type="text" v-model="euroManufacturingCost2" class="form-control text-right right-symbol" @input="handleInputEuroMfgCost2">
                  </div>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
                <div v-if="error.euro_manufacturing_cost2" class="text-sm text-red-600"><span>{{ error.euro_manufacturing_cost2[0] }}</span></div>
              </div>
            </div>                                  
          </div>
          <div v-if="euroManufacturingCost2 > 0" class="w-full md:flex gap-2 mb-4 md:mb-1.5">
            <div class="w-full md:w-1/2 gap-2"></div>           
            <div class="w-full md:w-1/2 gap-2">
              <input id="manufacturing_cost2_note" type="text" v-model="mfgCost2Note" class="form-control" placeholder="Manufacturing Cost 2 Note">
            </div>                                  
          </div>
          <div class="w-full md:flex gap-2 mb-4 md:mb-1">
            <div class="w-full md:w-1/2 gap-2">
              <label for="tax_customs" class="label-control md:pr-4 md:py-3 md:text-right">Tax / Customs</label>
            </div>           
            <div class="flex md:w-1/2 gap-2">
              <div class="w-full md:w-3/5">
                <ValidationProvider name="Tax / Customs" rules="required" v-slot="{ errors }">
                  <div class="relative flex-1">
                    <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">%</span>
                    <input id="tax_customs" type="text" v-model="taxCustoms" class="form-control text-right right-symbol" @input="handleInputTaxCustoms">
                  </div>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
                <div v-if="error.tax_customs" class="text-sm text-red-600"><span>{{ error.tax_customs[0] }}</span></div>
              </div>
              <div class="w-full md:w-2/5">
                <ValidationProvider name="Tax / Customs" rules="" v-slot="{ errors }">
                  <div class="relative flex-1">
                    <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
                    <input id="tax_customs_value" type="text" v-model="taxCustomsValue" class="form-control text-right right-symbol" readonly>
                  </div>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
              </div>
            </div>                                  
          </div>          
          <div class="w-full md:flex gap-2 mb-4 md:mb-1">
            <div class="w-full md:w-1/2 gap-2">
              <label for="manufacturing_cost3" class="label-control md:pr-4 md:py-3 md:text-right">Manufacturing Cost 3</label>
            </div>          
            <div class="flex md:w-1/2 gap-2">
              <div class="w-full md:flex md:w-3/5 gap-2">
                <div class="w-full md:w-3/12">
                  <ValidationProvider name="Manufacturing Cost 3 Currency" rules="" v-slot="{ errors }">
                    <MultiSelect id="mfg_cost3_currency" ref="mfg_cost3_currency" v-model="mfgCost3Currency" :options="currencies" :value="mfgCost3Currency" :searchable="false" track-by="id" label="currency" :showLabels="false" placeholder="" @input="handleInputMfgCost3Currency">
                      <template slot="caret">
                        <div>
                          <div class="multiselect__select">
                            <span>
                              <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                                <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </template>
                    </MultiSelect>
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <div v-if="error.mfg_cost3_currency" class="text-sm text-red-600"><span>{{ error.mfg_cost3_currency[0] }}</span></div>
                </div>
                <div class="w-full md:w-4/12">
                  <ValidationProvider name="Manufacturing Cost 3 Rate" rules="" v-slot="{ errors }">
                    <input id="mfg_cost3_rate" type="text" v-model="mfgCost3Rate" class="form-control text-right" @input="handleInputMfgCost3Rate">
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <div v-if="error.mfg_cost3_rate" class="text-sm text-red-600"><span>{{ error.mfg_cost3_rate[0] }}</span></div>
                </div>
                <div class="w-full md:w-5/12">
                  <ValidationProvider name="Manufacturing Cost 3" rules="required" v-slot="{ errors }">
                    <div class="relative flex-1">
                      <span fill="currentColor" class="absolute right-2 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">{{ mfgCost3Currency ? mfgCost3Currency.symbol : '' }}</span>
                      <input id="manufacturing_cost3" type="text" v-model="manufacturingCost3" class="form-control text-right right-symbol" @input="handleInputMfgCost3">
                    </div>
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.manufacturing_cost3" class="text-sm text-red-600"><span>{{ error.manufacturing_cost3[0] }}</span></div>                
                </div>
              </div>
              <div class="w-full md:w-2/5">
                <ValidationProvider name="Manufacturing Cost 3 ( € )" rules="required" v-slot="{ errors }">
                  <div class="relative flex-1">
                    <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
                    <input id="euro_manufacturing_cost3" type="text" v-model="euroManufacturingCost3" class="form-control text-right right-symbol"  @input="handleInputEuroMfgCost3">
                  </div>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
                <div v-if="error.euro_manufacturing_cost3" class="text-sm text-red-600"><span>{{ error.euro_manufacturing_cost3[0] }}</span></div>
              </div>
            </div>                                  
          </div>
          <div v-if="euroManufacturingCost3 > 0" class="w-full md:flex gap-2 mb-4 md:mb-1.5">
            <div class="w-full md:w-1/2 gap-2"></div>           
            <div class="w-full md:w-1/2 gap-2">
              <input id="manufacturing_cost3_note" type="text" v-model="mfgCost3Note" class="form-control" placeholder="Manufacturing Cost 3 Note">
            </div>                                  
          </div>                    
          <div class="w-full md:flex gap-2 mb-4 md:mb-1">
            <div class="w-full md:w-1/2 gap-2">
              <label for="transport_packaging" class="label-control md:pr-4 md:py-3 md:text-right">Transport / Packaging Package</label>
            </div>
            <div class="md:w-1/2">
              <ValidationProvider name="Transport / Packaging" rules="required" v-slot="{ errors }">
                <div class="relative flex-1">
                  <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
                  <input id="transport_packaging" type="text" v-model="transportPackaging" class="form-control text-right right-symbol" @input="handleInputTransportPackaging">
                </div>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.transport_packaging" class="text-sm text-red-600"><span>{{ error.transport_packaging[0] }}</span></div>
            </div>                                 
          </div>
          <div class="flex gap-2 mb-4 md:mb-1">
            <div class="w-1/2 gap-2">
              <label class="label-control pr-4 py-3 font-bold md:text-right">TOTAL COST PRICE</label>
            </div>          
            <div class="w-1/2">
              <div class="relative flex-1">
                <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
                <label class="label-control px-4 py-3 font-bold text-right right-symbol">{{ totalManufactureCost }}</label>
              </div>
            </div>
          </div>
          <div class="w-full md:flex gap-2 mb-4 md:mb-1">
            <div class="w-full md:w-1/2 gap-2">
              <label for="coef_wholesale_price" class="label-control md:pr-4 md:py-3 md:text-right">Merge / Wholesale Price</label>
            </div>
            <div class="flex md:w-1/2 gap-2">
              <div class="w-full md:w-3/5">
                <ValidationProvider name="Coef. Wholesale Price" rules="required" v-slot="{ errors }">
                  <input id="coef_wholesale_price" type="text" v-model="coefWholesalePrice" class="form-control text-right right-symbol" @input="handleInputCoefWholesalePrice">
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
                <div v-if="error.coef_wholesale_price" class="text-sm text-red-600"><span>{{ error.coef_wholesale_price[0] }}</span></div>
              </div>
              <div class="w-full md:w-2/5">
                <ValidationProvider name="Wholesale Price" rules="required" v-slot="{ errors }">
                  <div class="relative flex-1">
                    <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
                    <input id="wholesale_price" type="text" v-model="wholesalePrice" class="form-control text-right right-symbol" @input="handleInputWholesalePrice" readonly>
                  </div>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
                <div v-if="error.wholesale_price" class="text-sm text-red-600"><span>{{ error.wholesale_price[0] }}</span></div>
              </div> 
            </div>                                  
          </div>
          <div class="w-full md:flex gap-2 mb-4 md:mb-1">
            <div class="w-full md:w-1/2 gap-2">
              <label for="agent_commision" class="label-control md:pr-4 md:py-3 md:text-right">Agent Commision</label>
            </div>
            <div class="flex w-full md:w-1/2 gap-2">
              <div class="w-full md:w-3/5">
                <ValidationProvider name="Agent Commision" rules="required" v-slot="{ errors }">
                  <div class="relative flex-1">
                    <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">%</span>
                    <input id="agent_commision" type="text" v-model="agentCommision" class="form-control text-right right-symbol" @input="handleInputAgentCommision">
                  </div>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
                <div v-if="error.agent_commision" class="text-sm text-red-600"><span>{{ error.agent_commision[0] }}</span></div>
              </div>
              <div class="w-full md:w-2/5">
                <ValidationProvider name="Agent Commision ( € )" rules="required" v-slot="{ errors }">
                  <div class="relative flex-1">
                    <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
                    <input id="agent_commision_value" type="text" v-model="agentCommisionValue" class="form-control text-right right-symbol" readonly>
                  </div>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
              </div>
            </div>                                  
          </div>
          <div class="w-full md:flex gap-2 mb-4 md:mb-1">
            <div class="w-full md:w-1/2 gap-2">
              <label for="total_wholesale_price" class="label-control md:pr-4 md:py-3 md:text-right text-red">Total Wholesale Price in EURO</label>
            </div>        
            <div class="flex w-full md:w-1/2 gap-2">
              <div class="w-full md:w-3/5">
                <ValidationProvider name="Total Wholesale Price" rules="required" v-slot="{ errors }">
                  <div class="relative flex-1">
                    <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
                    <input id="total_wholesale_price" type="text" v-model="totalWholesalePrice" class="form-control bg-red text-right right-symbol" @input="handleInputWholesalePrice" readonly>
                  </div>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
                <div v-if="error.wholesale_price" class="text-sm text-red-600"><span>{{ error.wholesale_price[0] }}</span></div>
              </div>
              <div class="w-full md:w-2/5">
                <ValidationProvider name="Approved Wholesale Price" rules="" v-slot="{ errors }">
                  <div class="relative flex-1">
                    <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
                    <input id="approved_wholesale_price" type="text" v-model="approvedWholesalePrice" class="form-control bg-red border border-red-600 text-right right-symbol" @input="handleInputAppdWholesalePrice">
                  </div>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
                <div v-if="error.approved_wholesale_price" class="text-sm text-red-600"><span>{{ error.approved_wholesale_price[0] }}</span></div>
              </div>
            </div>                                  
          </div>
          <div class="w-full md:flex gap-2 mb-4 md:mb-1">
            <div class="w-full md:w-1/2 gap-2">
              <label for="coef_retail_price" class="label-control md:pr-4 md:py-3 md:text-right text-red">Retail Price in EURO</label>
            </div>
            <div class="flex w-full md:w-1/2 gap-2">
              <div class="flex w-full md:w-3/5 gap-2">
                <div class="w-full md:w-1/3">
                  <ValidationProvider name="Coef. Retail Price" rules="required" v-slot="{ errors }">
                    <input id="coef_retail_price" type="text" v-model="coefRetailPrice" class="form-control bg-red text-right" @input="handleInputCoefRetailPrice">
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.coef_retail_price" class="text-sm text-red-600"><span>{{ error.coef_retail_price[0] }}</span></div>
                </div>
                <div class="w-full md:w-2/3">
                  <ValidationProvider name="Retail Price" rules="required" v-slot="{ errors }">
                    <div class="relative flex-1">
                      <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
                      <input id="retail_price" type="text" v-model="retailPrice" class="form-control bg-red text-right right-symbol" @input="handleInputRetailPrice" readonly>
                    </div>
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.retail_price" class="text-sm text-red-600"><span>{{ error.retail_price[0] }}</span></div>
                </div>              
              </div>
              <div class="w-full md:w-2/5">
                <ValidationProvider name="Approved Retail Price" rules="" v-slot="{ errors }">
                  <div class="relative flex-1">
                    <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
                    <input id="approved_retail_price" type="text" v-model="approvedRetailPrice" class="form-control bg-red border border-blue-600 text-right right-symbol" @input="handleInputAppdRetailPrice">
                  </div>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
                <div v-if="error.approved_retail_price" class="text-sm text-red-600"><span>{{ error.approved_retail_price[0] }}</span></div>
              </div>              
            </div>                                  
          </div>
          <div class="w-full md:flex gap-2 mb-4 md:mb-1">
            <div class="w-full md:w-1/2 gap-2">
              <label for="coef_wholesale_usd" class="label-control md:pr-4 md:py-3 md:text-right text-yellow">Total Wholesale Price in USD</label>
            </div>
            <div class="flex w-full md:w-1/2 gap-2">
              <div class="flex w-full md:w-3/5 gap-2">
                <div class="w-full md:w-1/3">
                  <ValidationProvider name="USD Coef. Wholesale" rules="required" v-slot="{ errors }">
                    <input id="coef_wholesale_usd" type="text" v-model="coefWholesaleUSD" class="form-control bg-yellow text-right" @input="handleInputCoefWholesaleUSD">
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.coef_wholesale_usd" class="text-sm text-red-600"><span>{{ error.coef_wholesale_usd[0] }}</span></div>
                </div>
                <div class="w-full md:w-2/3">
                  <ValidationProvider name="USD Wholesale Price" rules="" v-slot="{ errors }">
                    <div class="relative flex-1">
                      <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">$</span>
                      <input id="wholesale_price_usd" type="text" v-model="wholesalePriceUSD" class="form-control bg-yellow text-right right-symbol" readonly>
                    </div>
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.wholesale_price_usd" class="text-sm text-red-600"><span>{{ error.wholesale_price_usd[0] }}</span></div>
                </div>              
              </div>
              <div class="w-full md:w-2/5">
                <ValidationProvider name="USD Approved Wholesale Price" rules="" v-slot="{ errors }">
                  <div class="relative flex-1">
                    <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">$</span>
                    <input id="approved_wholesale_price_usd" type="text" v-model="approvedWholesalePriceUSD" class="form-control bg-yellow text-right right-symbol" @input="handleInputAppdWholesaleUSD">
                  </div>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
                <div v-if="error.approved_wholesale_price_usd" class="text-sm text-red-600"><span>{{ error.approved_wholesale_price_usd[0] }}</span></div>
              </div>
            </div>                                  
          </div>
          <div class="w-full md:flex gap-2 mb-4 md:mb-1">
            <div class="w-full md:w-1/2 gap-2">
              <label for="coef_retail_usd" class="label-control md:pr-4 md:py-3 md:text-right text-yellow">Retail Price in USD</label>
            </div>
            <div class="flex w-full md:w-1/2 gap-2">
              <div class="flex w-full md:w-3/5 gap-2">
                <div class="w-full md:w-1/3">
                  <ValidationProvider name="USD Coef. Retail" rules="required" v-slot="{ errors }">
                    <input id="coef_retail_usd" type="text" v-model="coefRetailUSD" class="form-control bg-yellow text-right" @input="handleInputCoefRetailUSD">
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.coef_retail_usd" class="text-sm text-red-600"><span>{{ error.coef_retail_usd[0] }}</span></div>
                </div>
                <div class="w-full md:w-2/3">
                  <ValidationProvider name="USD Retail Price" rules="" v-slot="{ errors }">
                    <div class="relative flex-1">
                      <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">$</span>
                      <input id="retail_price_usd" type="text" v-model="retailPriceUSD" class="form-control bg-yellow text-right right-symbol" readonly>
                    </div>
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.retail_price_usd" class="text-sm text-red-600"><span>{{ error.retail_price_usd[0] }}</span></div>
                </div>              
              </div>
              <div class="w-full md:w-2/5">
                <ValidationProvider name="USD Approved Retail Price" rules="" v-slot="{ errors }">
                  <div class="relative flex-1">
                    <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">$</span>
                    <input id="approved_retail_price_usd" type="text" v-model="approvedRetailPriceUSD" class="form-control bg-yellow text-right right-symbol">
                  </div>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="error.approved_retail_price_usd" class="text-sm text-red-600"><span>{{ error.approved_retail_price_usd[0] }}</span></div>
              </div>
            </div>                                 
          </div>             
          <div class="md:flex gap-4 mt-10 mb-2">
            <button :disabled="isLoading" type="submit" class="btn btn--primary" alt="Save" title="Save">
              <span v-if="isLoading" class="block mx-5">
                <Loading />
              </span>
              <SaveIcon v-else>
                <span class="ml-2 md:block hidden">Update</span>
              </SaveIcon>
            </button>
            <button :disabled="isLoading" type="button" class="btn btn--success" @click="toggleModal()" alt="Duplicate" title="Duplicate">
              <span v-if="isLoading" class="block mx-5">
                <Loading />
              </span>
              <CopyIcon v-else>
                <span class="ml-2 md:block hidden">Duplicate</span>
              </CopyIcon>
            </button>            
            <button :disabled="isLoading" type="button" @click="goBack" class="btn btn--secondary" alt="Back" title="Back">
              <BackIcon>
                <span class="ml-2 md:block hidden">Back</span>
              </BackIcon>              
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <div class="fixed bg-gray-800 bg-opacity-25 w-full inset-0 top-16 md:top-0 z-50" :class="{ 'hidden': !showFunction }">
      <div class="w-52 ml-auto h-full bg-white shadow-lg right-0">
        <div class="flex p-5 border-b border-gray-200 items-center">
          <span class="flex-1 text-sm font-medium">Functions</span>
          <button type="button" class="flex right-0" @click="toggleFunction">
            <svg class="h-5 w-5 fill-current hover:text-gray-700" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
            </svg>
          </button>
        </div>
        <div class="p-5 items-center">
          <CollectionModal class="mb-4" />
          <UnitModal class="mb-4" />
          <RateModal class="mb-4" />
          <MaterialModal class="mb-4" />
        </div>
      </div>            
    </div>
    <div class="fixed w-20 top-0 bottom-0 right-0 z-20">
      <div class="absolute w-16 right-0 top-1/2 bg-green-500 text-white rounded-l-full shadow-lg" :class="{ 'hidden': showFunction }">
        <button class="block ml-2 p-3 cursor-pointer" v-tooltip="'Show Function'" @click="toggleFunction">
          <svg class="h-5 w-5 fill-current" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M22.6558 18.9896L13.5758 9.90957C14.5058 7.56957 14.0258 4.80957 12.1358 2.90957C9.83584 0.609575 6.25584 0.399574 3.70584 2.25957L7.54584 6.10957L6.12584 7.51957L2.29584 3.68957C0.435837 6.22957 0.645837 9.81957 2.94584 12.1096C4.80584 13.9696 7.51584 14.4596 9.83584 13.5896L18.9458 22.6996C19.3358 23.0896 19.9658 23.0896 20.3558 22.6996L22.6558 20.3996C23.0558 20.0196 23.0558 19.3896 22.6558 18.9896ZM19.6558 20.5896L10.1958 11.1296C9.58584 11.5796 8.90584 11.8496 8.19584 11.9496C6.83584 12.1496 5.40584 11.7396 4.36584 10.6996C3.41584 9.75957 2.97584 8.49957 3.04584 7.25957L6.13584 10.3496L10.3758 6.10957L7.28584 3.01957C8.52584 2.94957 9.77584 3.38957 10.7258 4.32957C11.8058 5.40957 12.2158 6.89957 11.9658 8.28957C11.8458 8.99957 11.5458 9.65957 11.0858 10.2496L20.5358 19.6996L19.6558 20.5896Z" />
          </svg>        
        </button>           
      </div>
    </div>

    <!-- Import Modal Dialog -->
    <modal :show="showModal" @close="showModal = false" addClass="modal-md" modalOrientation="pt-16 md:pt-10">
      <template v-slot:header><h3>{{ modalTitle }}</h3></template>
      <template v-slot:body>
        <div class="w-full md:flex gap-2">
          <div class="w-1/2 mb-2">
            <ValidationProvider name="Barcode" rules="required|numeric" v-slot="{ errors }">
              <label for="barcode" class="label-control">Barcode <span class="text-red-600">*</span></label>
              <input id="barcode" type="text" ref="barcodeDuplicate" v-model="barcodeDuplicate" maxlength="255" class="form-control">
              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
            </ValidationProvider>
            <div v-if="error.barcode" class="text-sm text-red-600"><span>{{ error.barcode[0] }}</span></div>             
          </div>
          <div class="w-1/2 mb-2">
            <ValidationProvider name="Reference" rules="required" v-slot="{ errors }">
              <label for="reference" class="label-control">Reference <span class="text-red-600">*</span></label>
              <input id="reference" type="text" v-model="referenceDuplicate" class="form-control">
              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
            </ValidationProvider>
            <div v-if="error.reference" class="text-sm text-red-600"><span>{{ error.reference[0] }}</span></div>
          </div>
        </div>
        <div class="w-full md:flex gap-2">
          <div class="w-1/2 mb-2">
            <ValidationProvider name="Color" rules="required" v-slot="{ errors }">
              <label for="color" class="label-control">Color <span class="text-red-600">*</span></label>
              <input id="color" type="text" v-model="colorDuplicate" class="form-control">
              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
            </ValidationProvider>
            <div v-if="error.color" class="text-sm text-red-600"><span>{{ error.color[0] }}</span></div>
          </div>
          <div class="w-1/2 mb-2">
            <ValidationProvider name="Size" rules="" v-slot="{ errors }">
              <label for="size" class="label-control">Size</label>
              <input id="size" type="text" v-model="sizeDuplicate" class="form-control">
              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
            </ValidationProvider>
            <div v-if="error.size" class="text-sm text-red-600"><span>{{ error.size[0] }}</span></div>        
          </div>
        </div>        
      </template>
      <template v-slot:footer>
        <button :disabled="isLoading" type="button" @click="duplicateProduct()" class="btn btn--success">
          Process
        </button>
      </template> 
    </modal>    
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import format from '@/helpers/formatNumber'
import productServices from '@/services/product/productServices'
import collectionServices from '@/services/collection/collectionServices'
import unitServices from '@/services/unit/unitServices'
import rateServices from '@/services/rate/rateServices'
import SaveIcon from '../../icons/SaveIcon'
import CopyIcon from '../../icons/CopyIcon'
import BackIcon from '../../icons/BackIcon'
import Loading from '../../icons/Loading'
import imageBox from '../../../assets/images/file-box.png'
import ProductRecipe from '../recipe/List'
import UnitModal from '../unit/Modal'
import CollectionModal from '../collection/Modal'
import RateModal from '../rate/Modal'
import MaterialModal from '../material/Modal'
import Modal from '../../widgets/Modal'

export default {
  name: 'EditProduct',
  components: {
    SaveIcon,
    CopyIcon,
    BackIcon,
    Loading,
    ProductRecipe,
    UnitModal,
    CollectionModal,
    RateModal,
    MaterialModal,
    Modal
  },  
  data(){
    return {
      seasonCreation: '',
      collection: '',
      barcode: '',
      reference: '',
      color: '',
      size: '',
      description: '',
      weight: '',
      high: '',
      width: '',
      long: '',
      carats: '',      
      annotation: '',
      unit: '',
      currency: '',
      changeRate: '',
      image: '',
      productImage: '',
      mfgCost1Currency: null,
      mfgCost1Rate: null,
      manufacturingCost1: 0,
      euroManufacturingCost1: 0,
      mfgCost2Currency: null,
      mfgCost2Rate: null,
      manufacturingCost2: 0,
      euroManufacturingCost2: 0,
      mfgCost2Note: null,
      mfgCost3Currency: null,
      mfgCost3Rate: null,
      manufacturingCost3: 0,
      euroManufacturingCost3: 0,
      mfgCost3Note: null,
      taxCustoms: 0,
      taxCustomsValue: 0,
      transportPackaging: 0,
      agentCommision: 0,
      agentCommisionValue: 0,
      coefWholesalePrice: 0,
      wholesalePrice: 0,
      totalWholesalePrice: 0,
      approvedWholesalePrice: 0,
      coefRetailPrice: 0,
      retailPrice: 0,
      approvedRetailPrice: 0,
      coefWholesaleUSD: 0,
      wholesalePriceUSD: 0,
      approvedWholesalePriceUSD: 0,
      coefRetailUSD: 0,
      retailPriceUSD: 0,
      approvedRetailPriceUSD: 0,      
      fileBox: imageBox,
      collections: [],
      units: [],
      currencies: [],
      record: [],
      details: [],
      error: [],
      modalTitle: '',
      showModal: false,
      barcodeDuplicate: '',
      referenceDuplicate: '',
      colorDuplicate: '',
      sizeDuplicate: '',
      showFunction: false,
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async update(){
      try {
        this.isLoading = true

        const id = this.$route.params.id
        let payload = new FormData()
        payload.append('season_creation', this.seasonCreation)
        payload.append('collection', this.collection.id)
        payload.append('barcode', this.barcode)
        payload.append('reference', this.reference)
        payload.append('color', this.color)
        payload.append('size', this.size ?? '')
        payload.append('description', this.description)
        payload.append('weight', this.weight ?? '')
        payload.append('high', this.high ?? '')
        payload.append('width', this.width ?? '')
        payload.append('long', this.long ?? '')
        payload.append('carats', this.carats ?? '')        
        payload.append('annotation', this.annotation ?? '')
        payload.append('unit', this.unit)
        payload.append('currency', this.currency.currency)
        payload.append('change_rate', this.unformatNumber(this.changeRate))
        payload.append('image', this.productImage)
        payload.append('mfg_cost1_currency', this.mfgCost1Currency ? this.mfgCost1Currency.currency : '')
        payload.append('mfg_cost1_rate', this.mfgCost1Currency ? this.unformatNumber(this.mfgCost1Rate) : '')
        payload.append('manufacturing_cost1', this.unformatNumber(this.manufacturingCost1) > 0 ? this.unformatNumber(this.manufacturingCost1) : '')
        payload.append('euro_manufacturing_cost1', this.unformatNumber(this.euroManufacturingCost1) > 0 ? this.unformatNumber(this.euroManufacturingCost1) : '')
        payload.append('mfg_cost2_currency', this.mfgCost2Currency ? this.mfgCost2Currency.currency : '')
        payload.append('mfg_cost2_rate', this.mfgCost2Currency ? this.unformatNumber(this.mfgCost2Rate) : '')
        payload.append('manufacturing_cost2', this.unformatNumber(this.manufacturingCost2) > 0 ? this.unformatNumber(this.manufacturingCost2) : '')
        payload.append('euro_manufacturing_cost2', this.unformatNumber(this.euroManufacturingCost2) > 0 ? this.unformatNumber(this.euroManufacturingCost2) : '')
        payload.append('manufacturing_cost2_note', this.mfgCost2Note)
        payload.append('mfg_cost3_currency', this.mfgCost3Currency ? this.mfgCost3Currency.currency : '')
        payload.append('mfg_cost3_rate', this.mfgCost3Currency ? this.unformatNumber(this.mfgCost3Rate) : '')
        payload.append('manufacturing_cost3', this.unformatNumber(this.manufacturingCost3) > 0 ? this.unformatNumber(this.manufacturingCost3) : '')
        payload.append('euro_manufacturing_cost3', this.unformatNumber(this.euroManufacturingCost3) > 0 ? this.unformatNumber(this.euroManufacturingCost3) : '')
        payload.append('manufacturing_cost3_note', this.mfgCost3Note)
        payload.append('tax_customs', this.unformatNumber(this.taxCustoms))
        payload.append('transport_packaging', this.unformatNumber(this.transportPackaging))
        payload.append('agent_commision', this.unformatNumber(this.agentCommision))
        payload.append('coef_wholesale_price', this.unformatNumber(this.coefWholesalePrice))
        payload.append('wholesale_price', this.unformatNumber(this.totalWholesalePrice))
        payload.append('approved_wholesale_price', this.approvedWholesalePrice ? this.unformatNumber(this.approvedWholesalePrice) : 0)
        payload.append('coef_retail_price', this.unformatNumber(this.coefRetailPrice))
        payload.append('retail_price', this.unformatNumber(this.retailPrice))
        payload.append('approved_retail_price', this.approvedRetailPrice ? this.unformatNumber(this.approvedRetailPrice) : 0)
        payload.append('coef_wholesale_usd', this.unformatNumber(this.coefWholesaleUSD))
        payload.append('wholesale_price_usd', this.wholesalePriceUSD ? this.unformatNumber(this.wholesalePriceUSD) : 0)
        payload.append('approved_wholesale_price_usd', this.approvedWholesalePriceUSD ? this.unformatNumber(this.approvedWholesalePriceUSD) : 0)
        payload.append('coef_retail_usd', this.unformatNumber(this.coefRetailUSD))
        payload.append('retail_price_usd', this.retailPriceUSD ? this.unformatNumber(this.retailPriceUSD) : 0)
        payload.append('approved_retail_price_usd', this.approvedRetailPriceUSD ? this.unformatNumber(this.approvedRetailPriceUSD) : 0)
        payload.append('_method', 'PUT')

        const response = await productServices.update(id, payload)
        if (response.data.status === 'success') {
          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          /* CHECK IF NEW ID IS EQUAL WITH CURRENT ID, THEN REDIRECT TO 2 STEP PREV PAGE */
          // console.log(this.newID, id)
          if (this.newID === id) {
            this.$router.push(this.indexRoute.fullPath)
            this.$store.dispatch('setNewProductID', null) 
            this.$store.dispatch('setIndexProductRoute', null)    
          } else {
            /* RETURN TO LIST */
            window.history.length > 1 ? this.$router.back() : this.$router.push('/products')
          }
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.season_creation || responseReturn.collection || responseReturn.barcode || responseReturn.reference 
              || responseReturn.color || responseReturn.size || responseReturn.description || responseReturn.weight
              || responseReturn.high || responseReturn.width || responseReturn.long || responseReturn.carats || responseReturn.annotation 
              || responseReturn.unit || responseReturn.currency || responseReturn.change_rate || responseReturn.image
              || responseReturn.mfg_cost1_currency || responseReturn.mfg_cost1_rate || responseReturn.manufacturing_cost1
              || responseReturn.euro_manufacturing_cost1 || responseReturn.mfg_cost2_currency || responseReturn.mfg_cost2_rate
              || responseReturn.manufacturing_cost2 || responseReturn.euro_manufacturing_cost2 || responseReturn.manufacturing_cost2_note || responseReturn.mfg_cost3_currency
              || responseReturn.mfg_cost3_rate || responseReturn.manufacturing_cost3 || responseReturn.euro_manufacturing_cost3 || responseReturn.manufacturing_cost3_note
              || responseReturn.tax_customs || responseReturn.transport_packaging || responseReturn.coef_wholesale_price
              || responseReturn.agent_commision || responseReturn.wholesale_price || responseReturn.approved_wholesale_price
              || responseReturn.coef_retail_price || responseReturn.retail_price || responseReturn.approved_retail_price
              || responseReturn.coef_wholesale_usd || responseReturn.wholesale_price_usd || responseReturn.approved_wholesale_price_usd
              || responseReturn.coef_retail_usd || responseReturn.retail_price_usd || responseReturn.approved_retail_price_usd) {

              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },
    async fetchData(){
      try {
        const id = this.$route.params.id
        const response = await productServices.fetchById(id)
        if (response.data.status === 'success') {
          this.record = response.data.data

          this.seasonCreation = this.record.season_creation
          this.collection = { 'id': this.record.collection_id, 'name': this.record.collection.collection_name}
          this.barcode = this.record.barcode
          this.reference = this.record.reference
          this.color = this.record.color
          this.size = this.record.size
          this.description = this.record.description
          this.weight = this.record.weight
          this.high = this.record.high
          this.width = this.record.width
          this.long = this.record.long
          this.carats = this.record.carats
          this.annotation = this.record.annotation
          this.unit = this.record.unit
          this.currency = { 'id': this.record.currency_detail.id, 'currency': this.record.currency_detail.currency, 'symbol': this.record.currency_detail.symbol, 'rate': this.record.currency_detail.rate}
          this.changeRate = this.formatNumber(this.record.change_rate)
          this.image = this.record.image
          this.mfgCost1Currency = this.record.mfg_cost1_currency_detail ? { 'id': this.record.mfg_cost1_currency_detail.id, 'currency': this.record.mfg_cost1_currency_detail.currency, 'symbol': this.record.mfg_cost1_currency_detail.symbol, 'rate': this.record.mfg_cost1_currency_detail.rate} : ''
          this.mfgCost1Rate = this.formatNumber(this.record.mfg_cost1_rate) ?? ''
          this.manufacturingCost1 = this.formatNumber(this.record.manufacturing_cost1) ?? 0
          this.euroManufacturingCost1 = this.formatNumber(this.record.euro_manufacturing_cost1) ?? 0
          this.mfgCost2Currency = this.record.mfg_cost2_currency_detail ? { 'id': this.record.mfg_cost2_currency_detail.id, 'currency': this.record.mfg_cost2_currency_detail.currency, 'symbol': this.record.mfg_cost2_currency_detail.symbol, 'rate': this.record.mfg_cost2_currency_detail.rate} : ''
          this.mfgCost2Rate = this.formatNumber(this.record.mfg_cost2_rate) ?? ''
          this.manufacturingCost2 = this.formatNumber(this.record.manufacturing_cost2) ?? 0
          this.euroManufacturingCost2 = this.formatNumber(this.record.euro_manufacturing_cost2) ?? 0
          this.mfgCost2Note = this.record.manufacturing_cost2_note
          this.mfgCost3Currency = this.record.mfg_cost3_currency_detail ? { 'id': this.record.mfg_cost3_currency_detail.id, 'currency': this.record.mfg_cost3_currency_detail.currency, 'symbol': this.record.mfg_cost3_currency_detail.symbol, 'rate': this.record.mfg_cost3_currency_detail.rate} : ''
          this.mfgCost3Rate = this.formatNumber(this.record.mfg_cost3_rate) ?? ''
          this.manufacturingCost3 = this.formatNumber(this.record.manufacturing_cost3) ?? 0
          this.euroManufacturingCost3 = this.formatNumber(this.record.euro_manufacturing_cost3) ?? 0
          this.mfgCost3Note = this.record.manufacturing_cost3_note
          this.taxCustoms = this.formatNumber(this.record.tax_customs) ?? 0
          this.transportPackaging = this.formatNumber(this.record.transport_packaging) ?? 0
          this.agentCommision = this.formatNumber(this.record.agent_commision) ?? 0
          this.coefWholesalePrice = this.formatNumber(this.record.coef_wholesale_price) ?? 0
          this.wholesalePrice = this.formatNumber(this.record.wholesale_price) ?? 0
          this.approvedWholesalePrice = this.record.approved_wholesale_price > 0 ? this.formatNumber(this.toFixed(this.record.approved_wholesale_price, 0)) : ''
          this.coefRetailPrice = this.formatNumber(this.record.coef_retail_price) ?? 0
          this.retailPrice = this.formatNumber(this.record.retail_price) ?? 0
          this.approvedRetailPrice = this.record.approved_retail_price > 0 ? this.formatNumber(this.toFixed(this.record.approved_retail_price, 0)) : ''
          this.coefWholesaleUSD = this.formatNumber(this.record.coef_wholesale_usd) ?? 0
          this.wholesalePriceUSD = this.record.wholesale_price_usd > 0 ? this.formatNumber(this.record.wholesale_price_usd) : ''
          this.approvedWholesalePriceUSD = this.record.approved_wholesale_price_usd > 0 ? this.formatNumber(this.toFixed(this.record.approved_wholesale_price_usd, 0)) : ''
          this.coefRetailUSD = this.formatNumber(this.record.coef_retail_usd) ?? 0
          this.retailPriceUSD = this.record.retail_price_usd > 0 ? this.formatNumber(this.record.retail_price_usd) : ''
          this.approvedRetailPriceUSD = this.record.approved_retail_price_usd > 0 ? this.formatNumber(this.toFixed(this.record.approved_retail_price_usd, 0)) : ''

          /* CALL TAX CUSTOMS */
          this.calculateTaxCustoms()
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)            
        }
      } catch (error) {
        console.log(error)
      }
    },  
    async fetchCollectionData() {
      try {
        const response = await collectionServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.collections = []
          records.forEach(element => {
            this.collections.push({
              'id': element.id,
              'name': element.collection_name
            })
          });          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchUnitData() {
      try {
        const response = await unitServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.units = []
          records.forEach(element => {
            this.units.push(element.unit)
          });
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    }, 
    async fetchCurrencyData() {
      try {
        const response = await rateServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.currencies = []
          records.forEach(element => {
            this.currencies.push({
              'id': element.id,
              'currency': element.currency,
              'symbol': element.symbol,
              'rate': element.rate
            })
          });
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },     
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    },    
    handleInputRate(event) {
      this.changeRate = this.formatNumber(event.target.value)
      this.calculateManufactureCost1(this.manufacturingCost1)
      this.calculateManufactureCost2(this.manufacturingCost2)
      this.calculateManufactureCost3(this.manufacturingCost3)
      this.calculateTaxCustoms()
    },
    handleInputMfgCost1Currency() {
      this.mfgCost1Rate = this.mfgCost1Currency ? this.formatNumber(this.mfgCost1Currency.rate) : ''
      this.calculateManufactureCost1(this.manufacturingCost1)
    },
    handleInputMfgCost1Rate(event) {
      this.mfgCost1Rate = this.formatNumber(event.target.value)
      this.calculateManufactureCost1(this.manufacturingCost1)
    },    
    handleInputMfgCost1(event) {
      this.calculateManufactureCost1(event.target.value)
    },
    calculateManufactureCost1(value) {
      if (this.mfgCost1Currency) {
        if (this.mfgCost1Currency.currency === 'EUR') {
          this.manufacturingCost1 = this.formatNumber(value)
          this.euroManufacturingCost1 = this.formatNumber(value)      
        } else {
          let input = this.unformatNumber(value)
          let rate = this.unformatNumber(this.mfgCost1Rate)
          let convertToEUR = input / rate

          this.manufacturingCost1 = this.formatNumber(value)
          this.euroManufacturingCost1 = this.formatNumber(this.toFixed(convertToEUR, 2))
        }
      } else {
        this.manufacturingCost1 = this.formatNumber(value)
        this.euroManufacturingCost1 = 0
      }

      this.calculateTaxCustoms()      
    },
    handleInputEuroMfgCost1(event) {
      if (this.mfgCost1Currency) {
        if (this.mfgCost1Currency.currency === 'EUR') {
          this.manufacturingCost1 = this.formatNumber(event.target.value)
          this.euroManufacturingCost1 = this.formatNumber(event.target.value)
        } else {
          let input = this.unformatNumber(event.target.value)
          let rate = this.unformatNumber(this.mfgCost1Rate)
          let convertToDestination = input * rate

          this.manufacturingCost1 = this.formatNumber(convertToDestination)
          this.euroManufacturingCost1 = this.formatNumber(event.target.value)
        }
      } else {
        this.euroManufacturingCost1 = this.formatNumber(event.target.value)
        this.manufacturingCost1 = 0       
      }

      this.calculateTaxCustoms()
    },
    handleInputMfgCost2Currency() {
      this.mfgCost2Rate = this.mfgCost2Currency ? this.formatNumber(this.mfgCost2Currency.rate) : ''
      this.calculateManufactureCost2(this.manufacturingCost2)
    },
    handleInputMfgCost2Rate(event) {
      this.mfgCost2Rate = this.formatNumber(event.target.value)
      this.calculateManufactureCost2(this.manufacturingCost2)
    },    
    handleInputMfgCost2(event) {
      this.calculateManufactureCost2(event.target.value)
    },
    calculateManufactureCost2(value) {
      if (this.mfgCost2Currency) {
        if (this.mfgCost2Currency.currency === 'EUR') {
          this.manufacturingCost2 = this.formatNumber(value)
          this.euroManufacturingCost2 = this.formatNumber(value)      
        } else {
          let input = this.unformatNumber(value)
          let rate = this.unformatNumber(this.mfgCost2Rate) ?? 0
          let convertToEUR = input / rate

          this.manufacturingCost2 = this.formatNumber(value)
          this.euroManufacturingCost2 = this.formatNumber(this.toFixed(convertToEUR, 2))
        }
      } else {
        this.manufacturingCost2 = this.formatNumber(value)
        this.euroManufacturingCost2 = 0       
      }

      this.calculateTaxCustoms()      
    },    
    handleInputEuroMfgCost2(event) {
      if (this.mfgCost2Currency) {
        if (this.mfgCost2Currency.currency === 'EUR') {
          this.manufacturingCost2 = this.formatNumber(event.target.value)
          this.euroManufacturingCost2 = this.formatNumber(event.target.value)
        } else {
          let input = this.unformatNumber(event.target.value)
          let rate = this.unformatNumber(this.mfgCost2Rate)
          let convertToDestination = input * rate

          this.manufacturingCost2 = this.formatNumber(convertToDestination)
          this.euroManufacturingCost2 = this.formatNumber(event.target.value)
        }
      } else {
        this.euroManufacturingCost2 = this.formatNumber(event.target.value)
        this.manufacturingCost2 = 0      
      }

      this.calculateTaxCustoms()
    },    
    handleInputMfgCost3Currency() {
      this.mfgCost3Rate = this.mfgCost3Currency ? this.formatNumber(this.mfgCost3Currency.rate) : ''
      this.calculateManufactureCost3(this.manufacturingCost3)
    },
    handleInputMfgCost3Rate(event) {
      this.mfgCost3Rate = this.formatNumber(event.target.value)
      this.calculateManufactureCost3(this.manufacturingCost3)
    },    
    handleInputMfgCost3(event) {
      this.calculateManufactureCost3(event.target.value)
    },
    calculateManufactureCost3(value) {
      if (this.mfgCost3Currency) {
        if (this.mfgCost3Currency.currency === 'EUR') {
          this.manufacturingCost3 = this.formatNumber(value)
          this.euroManufacturingCost3 = this.formatNumber(value)      
        } else {
          let input = this.unformatNumber(value)
          let rate = this.unformatNumber(this.mfgCost3Rate)
          let convertToEUR = input / rate

          this.manufacturingCost3 = this.formatNumber(value)
          this.euroManufacturingCost3 = this.formatNumber(this.toFixed(convertToEUR, 2))
        }
      } else {
        this.manufacturingCost3 = this.formatNumber(value)
        this.euroManufacturingCost3 = 0      
      }

      this.calculateTaxCustoms()      
    },    
    handleInputEuroMfgCost3(event) {
      if (this.mfgCost3Currency) {
        if (this.mfgCost3Currency.currency === 'EUR') {
          this.manufacturingCost3 = this.formatNumber(event.target.value)
          this.euroManufacturingCost3 = this.formatNumber(event.target.value)
        } else {
          let input = this.unformatNumber(event.target.value)
          let rate = this.unformatNumber(this.mfgCost3Rate)
          let convertToDestination = input * rate

          this.manufacturingCost3 = this.formatNumber(convertToDestination)
          this.euroManufacturingCost3 = this.formatNumber(event.target.value)
        }
      } else {
        this.euroManufacturingCost3 = this.formatNumber(event.target.value)
        this.manufacturingCost3 = 0     
      }

      this.calculateTaxCustoms()
    },                     
    handleInputTaxCustoms(event) {
      this.taxCustoms = this.formatNumber(event.target.value)
      this.calculateTaxCustoms()
    },
    handleInputTransportPackaging(event) {
      this.transportPackaging = this.formatNumber(event.target.value)
    },
    handleInputAgentCommision(event) {
      this.agentCommision = event.target.value ? this.formatNumber(event.target.value) : 0
      this.calculateAgentCommision()
      this.calculateTotalWholesalePrice()
    },    
    handleInputCoefWholesalePrice(event) {
      this.coefWholesalePrice = event.target.value ? this.formatNumber(event.target.value) : 0
      this.calculateWholesalePrice()
      this.calculateAgentCommision()
      this.calculateTotalWholesalePrice()
    },
    handleInputWholesalePrice(event) {
      this.wholesalePrice = this.formatNumber(event.target.value)
    },
    handleInputAppdWholesalePrice(event) {
      this.approvedWholesalePrice = event.target.value ? this.formatNumber(this.toFixed(event.target.value, 0)) : ''
      this.calculateRetailPrice()
      this.calculateTotalWholesalePriceUSD()
    },
    handleInputCoefWholesaleUSD(event) {
      this.coefWholesaleUSD = this.formatNumber(event.target.value)
      this.calculateTotalWholesalePriceUSD()
    },
    handleInputCoefRetailPrice(event) {
      this.coefRetailPrice = this.formatNumber(event.target.value)
      this.calculateRetailPrice()
    },
    handleInputRetailPrice(event) {
      this.retailPrice = this.formatNumber(event.target.value)
    },
    handleInputAppdRetailPrice(event) {
      this.approvedRetailPrice = event.target.value ? this.formatNumber(this.toFixed(event.target.value, 0)) : ''
    },
    handleInputAppdWholesaleUSD(event) {
      this.approvedWholesalePriceUSD = event.target.value ? this.formatNumber(this.toFixed(event.target.value, 0)) : ''
      this.calculateRetailPriceUSD()
    },
    handleInputAppdRetailUSD(event) {
      this.approvedRetailPriceUSD = event.target.value ? this.formatNumber(this.toFixed(event.target.value, 0)) : ''
    },        
    handleInputCoefRetailUSD(event) {
      this.coefRetailUSD = this.formatNumber(event.target.value)
      this.calculateRetailPriceUSD()
    },
    convertToEuro(value) {
      let convert = this.unformatNumber(value) / this.unformatNumber(this.changeRate)
      convert = this.formatNumber(this.toFixed(convert, 2))

      return convert
    },
    convertToRupiah(value) {
      let convert = this.unformatNumber(value) * this.unformatNumber(this.changeRate)
      convert = this.formatNumber(this.toFixed(convert, 2))

      return convert
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }

      this.createImage(files[0])
    },
    createImage(file) {
      let reader = new FileReader()
      let vm = this

      reader.onload = (e) => {
        vm.image = e.target.result
      }
      reader.readAsDataURL(file)
      this.productImage = this.$refs.image.files[0]
    },
    removeImage() {
      this.image = ''
    },
    calculateTaxCustoms() {
      let manufaturingCost = parseFloat(this.unformatNumber(this.euroManufacturingCost1)) + parseFloat(this.unformatNumber(this.euroManufacturingCost2))
      let taxCustoms = parseFloat(manufaturingCost) * parseFloat(this.unformatNumber(this.taxCustoms))
      taxCustoms = taxCustoms / 100
      this.taxCustomsValue = this.formatNumber(this.toFixed(taxCustoms, 2))
    },
    calculateTotalMfgCost() {
      const total = parseFloat(this.recipeTotal) + (parseFloat(this.euroManufacturingCost1) + parseFloat(this.euroManufacturingCost2) + parseFloat(this.taxCustomsValue) + parseFloat(this.euroManufacturingCost3) + parseFloat(this.transportPackaging))
      return this.formatNumber(this.toFixed(total, 2))
    },
    calculateWholesalePrice() {
      let wholesale_price = parseFloat(this.unformatNumber(this.totalManufactureCost)) * parseFloat(this.unformatNumber(this.coefWholesalePrice))
      this.wholesalePrice = this.formatNumber(this.toFixed(wholesale_price, 2))
    },
    calculateAgentCommision() {
      let agent_commision = parseFloat(this.unformatNumber(this.wholesalePrice)) * parseFloat(this.unformatNumber(this.agentCommision))
      agent_commision = agent_commision / 100
      this.agentCommisionValue = this.formatNumber(this.toFixed(agent_commision, 2))
    },
    calculateTotalWholesalePrice() {
      let totalPrice = parseFloat(this.unformatNumber(this.wholesalePrice)) + parseFloat(this.unformatNumber(this.agentCommisionValue))
      this.totalWholesalePrice = this.formatNumber(this.toFixed(totalPrice, 2))
    },
    calculateRetailPrice() {
      let totalPrice = parseFloat(this.unformatNumber(this.approvedWholesalePrice)) * parseFloat(this.unformatNumber(this.coefRetailPrice))
      this.retailPrice = this.formatNumber(this.toFixed(totalPrice, 2))
    },
    calculateTotalWholesalePriceUSD() {
      let totalPrice = parseFloat(this.unformatNumber(this.approvedWholesalePrice)) * parseFloat(this.unformatNumber(this.coefWholesaleUSD))
      this.wholesalePriceUSD = this.formatNumber(this.toFixed(totalPrice, 2))
    },
    calculateRetailPriceUSD() {
      let totalPrice = parseFloat(this.unformatNumber(this.approvedWholesalePriceUSD)) * parseFloat(this.unformatNumber(this.coefRetailUSD))
      this.retailPriceUSD = this.formatNumber(this.toFixed(totalPrice, 2))
    },    
    goBack() {
      if (this.newID === this.$route.params.id) {
        this.$router.push(this.indexRoute.fullPath)
        this.$store.dispatch('setNewProductID', null) 
        this.$store.dispatch('setIndexProductRoute', null)    
      } else {
        window.history.length > 1 ? this.$router.back() : this.$router.push('/products')
      }
    },
    toggleFunction() {
      this.showFunction = !this.showFunction
    },
    toggleModal() {
      this.barcodeDuplicate = ''
      this.referenceDuplicate = this.reference
      this.colorDuplicate = this.color
      this.sizeDuplicate = ''
      this.showModal = true
      this.modalTitle = 'Duplicate Product'
    },
    async duplicateProduct() {
      try {
        this.isLoading = true

        const id = this.$route.params.id
        const payload = {
          barcode: this.barcodeDuplicate,
          reference: this.referenceDuplicate,
          color: this.colorDuplicate,
          size: this.sizeDuplicate
        }
        const response = await productServices.duplicate(id, payload)
        if (response.data.status === 'success') {
          this.isLoading = false

          /* STORE DATA RESULT */
          const record = response.data.data

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* CLOSE MODAL */
          this.showModal = false          
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          /* OPEN DUPLICATE PRODUCT */
          let baseUrl = window.location.origin
          window.open(baseUrl+'/products/'+record.id+'/edit', '_blank')
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.barcode || responseReturn.reference || responseReturn.color || responseReturn.size) {

              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    }
  },
  computed: {
    ...mapGetters({
      recipeTotal: 'recipeTotal',
      newID: 'newProductID',
      indexRoute: 'indexProductRoute'
    }),
    totalManufactureCost: function() {
      return this.calculateTotalMfgCost()
    }
  },
  created(){
    this.fetchCollectionData()
    this.fetchUnitData()
    this.fetchCurrencyData()
    this.fetchData()
  },
  mounted() {
    const thisInstance = this
    this.$root.$on('fetchUnit', function(){
      thisInstance.fetchUnitData()
    }),
    this.$root.$on('fetchCollection', function(){
      thisInstance.fetchCollectionData()
    }),
    this.$root.$on('fetchRate', function(){
      thisInstance.fetchRateData()
    }),
    this.$root.$on('fetchMaterial', function(){
      thisInstance.$refs.productRecipe.fetchMaterialData()
    })    
  },  
  watch: {
    totalManufactureCost: function() {
      this.calculateWholesalePrice()
      this.calculateAgentCommision()
      this.calculateTotalWholesalePrice()
    },
    totalWholesalePrice: function() {
      this.calculateRetailPrice()
    }   
  } 
}
</script>