<template>
  <div>
    <button type="button" class="btn btn--success ml-2" @click="toggleModal">
      <DownloadIcon>
        <span class="ml-2 md:block hidden">Export</span>
      </DownloadIcon>
    </button>
    <!-- Import Modal Dialog -->
    <modal :show="showModal" @close="showModal = false" addClass="modal-xl" modalOrientation="pt-16 md:pt-10">
      <template v-slot:header><h3>{{ modalTitle }}</h3></template>
      <template v-slot:body>
        <div class="w-full md:flex gap-4 mb-2">
          <div class="w-full md:w-1/12 items-center">
            <label class="label-control py-3">Filter</label>
          </div>
          <div class="flex w-full md:w-8/12 gap-2">
            <div class="w-2/5 mb-2">
              <ValidationProvider name="Type" rules="required" v-slot="{ errors }">
                <MultiSelect id="type" ref="type" v-model="type" :options="types" :value="type" @input="clearMaterial()" :searchable="true" track-by="id" label="name" placeholder="Type to Search">
                  <template slot="caret">
                    <div>
                      <div class="multiselect__select">
                        <span>
                          <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                            <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </template>
                </MultiSelect>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
              </ValidationProvider>
              <div v-if="error.type" class="text-sm text-red-600"><span>{{ error.type[0] }}</span></div>
            </div>
            <div class="w-3/5 mb-2">
              <ValidationProvider name="Supplier" rules="required" v-slot="{ errors }">
                <MultiSelect id="supplier" ref="supplier" v-model="supplier" :options="suppliers" :value="supplier" @input="clearMaterial()" :searchable="true" track-by="id" label="name" placeholder="Type to Search">
                  <template slot="caret">
                    <div>
                      <div class="multiselect__select">
                        <span>
                          <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                            <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </template>
                </MultiSelect>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
              </ValidationProvider>
              <div v-if="error.supplier" class="text-sm text-red-600"><span>{{ error.supplier[0] }}</span></div>
            </div>
          </div>
          <div class="w-full md:w-3/12 mb-2">
            <button :disabled="isLoading" type="button" @click="fetchData()" class="btn btn--primary">
              <span v-if="isLoading" class="block">
                <Loading />
              </span>                  
              <FilterIcon :class="{ 'hidden' : isLoading }">
                <span class="ml-1 md:block hidden">Preview</span>
              </FilterIcon>
            </button>
            <button :disabled="isLoading" type="button" @click="downloadFile()" class="btn btn--success ml-2">
              <DownloadIcon>
                <span class="ml-2 md:block hidden">Download</span>
              </DownloadIcon>
            </button>                
          </div>
        </div>
        <div class="mb-4 bg-white shadow-lg rounded-lg overflow-y-auto">
          <table>
            <thead>
              <tr>
                <th scope="col" class="px-3 text-xs text-center">Type</th>
                <th scope="col" class="px-3 text-xs text-left">Supplier</th>
                <th scope="col" class="px-3 text-xs text-left">Reference</th>
                <th scope="col" class="px-3 text-xs text-left">Description</th>
                <th scope="col" class="px-3 text-xs text-left">Color</th>
                <th scope="col" class="px-3 text-xs text-left">Size</th>
                <th scope="col" class="px-3 text-xs text-left">Unit</th>
                <th scope="col" class="px-3 text-xs text-center">Price</th>
                <th scope="col" class="px-3 text-xs text-center">Min Stock</th>
                <th scope="col" class="px-3 text-xs text-center">On Hand</th>
                <th scope="col" class="px-3 text-xs text-center">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="materials.length === 0" class="border-b border-gray-200"><td class="text-center" colspan="10">No data to display</td></tr>
              <tr v-for="(material, index) in materials" :key="material.id" class="border-b border-gray-200 hover:bg-gray-100" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">
              <td class="px-3 text-xs text-center">
                <span v-for="type in material.type" :key="type.id" class="my-1 badge badge--danger">{{ type.code }}</span>
              </td>
                <td class="px-3 text-xs text-left">{{ material.supplier }}</td>
                <td class="px-3 text-xs text-left">{{ material.material_name }}</td>
                <td class="px-3 text-xs text-left">{{ material.description }}</td>
                <td class="px-3 text-xs text-left">{{ material.color }}</td>
                <td class="px-3 text-xs text-left">{{ material.size }}</td>
                <td class="px-3 text-xs text-left">{{ material.unit }}</td>
                <td class="px-3 text-xs text-right">{{ material.current_price }} €</td>
                <td class="px-3 text-xs text-right">{{ formatNumber(material.minimum_stock) }}</td>
                <td class="px-3 text-xs text-right">{{ material.stock > 0 ? formatNumber(material.stock) : material.stock }}</td>
                <td class="px-3 text-xs text-center">
                  <span class="badge" :class="material.is_discontinue ? 'badge--danger' : 'badge--success'">{{ material.status_text }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>           
      </template>
      <template v-slot:footer>     
      </template> 
    </modal>
  </div>
</template>

<script>
import format from '@/helpers/formatNumber'
import collectionServices from '@/services/collection/collectionServices'
import supplierServices from '@/services/supplier/supplierServices'
import materialServices from '@/services/material/materialServices'
import Modal from '../../widgets/Modal'
import DownloadIcon from '../../icons/DownloadIcon'
import FilterIcon from '../../icons/FilterIcon'
import Loading from '../../icons/Loading'

export default {
  name: 'ExportMaterial',
  components: {
    Modal,
    DownloadIcon,
    FilterIcon,
    Loading,
  },
  data() {
    return {
      materials: [],
      type: '',
      types: [],
      supplier: '',
      suppliers: [],       
      error: [],
      isLoading: false, 
      modalTitle: '',     
      showModal: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }     
    }
  },
  methods: {
    async fetchData() {
      try {
        this.isLoading = true
        
        const params = {
          take: '',
          page: '',
          search: '',
          collection: this.type ? this.type.id : '',
          supplier: this.supplier ? this.supplier.id : '',
          date_field: '',
          start: '',
          end: '',
          sort_field: '',
          sort_option: ''
        }
        const response = await materialServices.fetchAll(params)
        if (response.data.status === 'success') {
          this.isLoading =false
          this.materials = response.data.data
        } else {
          this.isLoading =false

          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        this.isLoading =false
        console.log(error)
      }
    },
    async fetchCollectionData() {
      try {
        const response = await collectionServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.types = []
          records.forEach(element => {
            this.types.push({
              'id': element.id,
              'name': element.collection_name,
              'code': element.collection_code
            })
          });
          this.types.push({
            'id': '',
            'name': 'ALL TYPE'
          })                   
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchSupplierData() {
      try {
        const response = await supplierServices.fetchDataOptions()
        if (response.data.status === 'success') {
          const records = response.data.data
          this.suppliers = []
          records.forEach(element => {
            this.suppliers.push({
              'id': element.id,
              'name': element.supplier_name
            })
          })          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },    
    toggleModal() {
      this.clearMaterial()
      this.fetchCollectionData()
      this.fetchSupplierData()
      this.showModal = true
      this.modalTitle = 'Export Raw Materials'
    },
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    },
    clearMaterial() {
      this.materials = []
    },
    openDownloadTab(key) {
      let baseUrl = window.location.origin
      window.open(baseUrl+'/auth/download?key='+key, '_blank')
    },       
    async downloadFile() {
      try {
        this.isLoading = true
        const params = {
          take: '',
          page: '',
          search: '',
          collection: this.type ? this.type.id : '',
          supplier: this.supplier ? this.supplier.id : '',
          date_field: '',
          start: '',
          end: '',
          sort_field: 'material_name',
          sort_option: 'asc'          
        }
        const response = await materialServices.export(params)
        if (response.data.status === 'success') {
          this.isLoading = false
          this.$toasted.success(response.data.message, this.toastedOptions)

          const key = response.data.data
          this.openDownloadTab(key)
        } else {
          this.isLoading = false
          this.$toasted.error(response.data.message, this.toastedOptions)
        }
      } catch (error) {
        this.isLoading = false
        console.log(error)
      }
    }      
  }
}
</script>