const state = {
  units: []
};

const getters = {
  units: state => state.units
};

const actions = {
  setUnits({commit}, units){
    commit('setUnits', units)
  }
};

const mutations = {
  setUnits(state, units){
    state.units = units
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};