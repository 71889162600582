<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="bg-white shadow-lg rounded-lg" @submit.prevent="handleSubmit(create)">
        <div class="p-10">
          <div class="pb-2 mb-4 border-b border-gray-200">
            <h2 class="text-gray-800 text-2xl font-medium">Create Transfer Stock</h2>
          </div>
          <div class="w-full md:flex gap-4">
            <div class="w-full md:w-2/6 mb-4">
              <ValidationProvider name="Transfer From" rules="" v-slot="{ errors }">
                <label for="transfer_from" class="label-control">Transfer From <span class="text-red-600">*</span></label>
                <MultiSelect id="transfer_from" v-model="transferFrom" :options="locations" :value="transferFrom" :searchable="true" track-by="id" label="name" placeholder="Type to Search" :showLabels="false">
                  <template slot="caret">
                    <div>
                      <div class="multiselect__select">
                        <span>
                          <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                            <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </template>
                </MultiSelect>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
              </ValidationProvider>
              <div v-if="error.transfer_from" class="text-sm text-red-600"><span>{{ error.transfer_from[0] }}</span></div>
            </div>
            <div class="w-full md:w-2/6 mb-4">
              <ValidationProvider name="Transfer To" rules="" v-slot="{ errors }">
                <label for="transfer_to" class="label-control">Transfer To <span class="text-red-600">*</span></label>
                <MultiSelect id="transfer_to" v-model="transferTo" :options="locations" :value="transferTo" :searchable="true" track-by="id" label="name" placeholder="Type to Search" :showLabels="false">
                  <template slot="caret">
                    <div>
                      <div class="multiselect__select">
                        <span>
                          <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                            <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </template>
                </MultiSelect>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
              </ValidationProvider>
              <div v-if="error.transfer_to" class="text-sm text-red-600"><span>{{ error.transfer_to[0] }}</span></div>
            </div>
            <div class="w-full md:w-2/6 mb-4">
              <ValidationProvider name="Transfer Date" rules="required" v-slot="{ errors }">
                <v-date-picker v-model="transferDate" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs">
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="md:flex gap-6">
                      <div class="w-full">
                        <label for="transfer_date" class="label-control">Transfer Date <span class="text-red-600">*</span></label>
                        <div class="relative flex justify-between items-center">
                          <input id="transfer_date" type="text" class="form-control" :value="inputValue" v-on="inputEvents">
                          <span class="h-full absolute pointer-events-none right-0">
                            <DateRange class="m-3" />
                          </span>                      
                        </div>
                        <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </ValidationProvider>
              <div v-if="error.transfer_date" class="text-sm text-red-600"><span>{{ error.transfer_date[0] }}</span></div>
            </div>
          </div>
          <div class="md:flex gap-6">
            <div class="w-full mb-4">
              <ValidationProvider name="Remarks" rules="" v-slot="{ errors }">
                <label for="remarks" class="label-control">Remarks</label>
                <textarea id="remarks" v-model="remarks" class="form-control" rows="3"></textarea>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.remarks" class="text-sm text-red-600"><span>{{ error.remarks[0] }}</span></div>              
            </div>
          </div>
          <div class="md:flex gap-4 mt-4 mb-2">
            <button :disabled="isLoading" type="submit" class="btn btn--primary" alt="Save" title="Save">
              <span v-if="isLoading" class="block mx-5">
                <Loading />
              </span>
              <SaveIcon v-else>
                <span class="ml-2 md:block hidden">Save &amp; Continue</span>
              </SaveIcon>
            </button>
            <button :disabled="isLoading" type="button" @click="goBack()" class="btn btn--secondary" alt="Back" title="Back">
              <BackIcon>
                <span class="ml-2 md:block hidden">Back</span>
              </BackIcon>              
            </button>
          </div>                                                  
        </div>
      </form>      
    </ValidationObserver>   
  </div>
</template>

<script>
import factoryServices from '@/services/factory/factoryServices'
import transferServices from '@/services/transfer/transferServices'
import SaveIcon from '../../icons/SaveIcon'
import BackIcon from '../../icons/BackIcon'
import Loading from '../../icons/Loading'
import DateRange from '../../icons/DateRange'

export default {
  name: 'CreateTransferStock',
  components: {
    SaveIcon,
    BackIcon,
    Loading,
    DateRange
  },
  data(){
    return {
      transferFrom: '',
      transferTo: '',
      locations: [],     
      transferDate: new Date(),
      masks: {
        input: 'YYYY-MM-DD',
      },
      attrs: [
        {
          key: 'today',
          highlight: 'red',
          dates: new Date(),
        },
      ],      
      remarks: '',
      error: [],
      prevRoute: null,
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },  
  methods: {
    async create(){
      try {
        this.isLoading = true
        const payload = {
          transfer_from: this.transferFrom.id,
          transfer_to: this.transferTo.id,
          transfer_date: this.transferDate,
          remarks: this.remarks
        }

        const response = await transferServices.create(payload)
        if (response.data.status === 'success') {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false
                    
          /* CLEAR INPUT FORM */
          this.clearForm()

          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          /* SET NEW ID & REDIRECT TO EDIT PAGE WITH CREATED ID */
          this.$store.dispatch('setNewTransferID', response.data.data.id)
          this.$router.push({ name: 'transferEdit', params: { id: response.data.data.id} })
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.transfer_from || responseReturn.transfer_to || responseReturn.transfer_date || responseReturn.remarks) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },
    async fetchFactory() {
      try {
        const response = await factoryServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.locations = []
          records.forEach(element => {
            this.locations.push({
              'id': element.id,
              'name': element.factory_name,
              'code': element.factory_code
            })
          })
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }        
      } catch (error) {
        console.log(error)
      }
    },     
    clearForm(){
      this.transferFrom = ''
      this.transferTo = ''
      this.transferDate = ''
      this.remarks = ''
    },
    goBack() {
      window.history.length > 1 ? this.$router.back() : this.$router.push('/transfer-stock')
    }     
  },
  created(){
    this.fetchFactory()
  },
  watch: {
    prevRoute: function() {
      this.$store.dispatch('setIndexTransferRoute', this.prevRoute)
    }
  }  
}
</script>