var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"bg-white shadow-lg rounded-lg",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.update)}}},[_c('div',{staticClass:"p-10"},[_c('div',{staticClass:"pb-2 mb-4 border-b border-gray-200"},[_c('h2',{staticClass:"text-gray-800 text-2xl font-medium"},[_vm._v("Change Password")])]),_c('div',{staticClass:"md:flex gap-6"},[_c('div',{staticClass:"w-full mb-4"},[_c('ValidationProvider',{attrs:{"name":"Current Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-control",attrs:{"for":"current_password"}},[_vm._v("Current Password "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentPassword),expression:"currentPassword"}],ref:"current_password",staticClass:"form-control",attrs:{"id":"current_password","type":"password","maxlength":"255","autofocus":""},domProps:{"value":(_vm.currentPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.currentPassword=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.current_password)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.current_password[0]))])]):_vm._e()],1)]),_c('div',{staticClass:"md:flex gap-6"},[_c('div',{staticClass:"md:w-1/2 mb-4"},[_c('ValidationProvider',{attrs:{"name":"New Password","rules":"required|min:6|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-control",attrs:{"for":"password"}},[_vm._v("New Password "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newPassword),expression:"newPassword"}],staticClass:"form-control",attrs:{"id":"password","type":"password"},domProps:{"value":(_vm.newPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.newPassword=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.password)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.password[0]))])]):_vm._e()],1),_c('div',{staticClass:"md:w-1/2 mb-4"},[_c('ValidationProvider',{attrs:{"name":"New Password Confirmation","rules":"required|min:6","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-control",attrs:{"for":"password_confirmation"}},[_vm._v("New Password Confirmation "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newPasswordConfirmation),expression:"newPasswordConfirmation"}],staticClass:"form-control",attrs:{"id":"password_confirmation","type":"password"},domProps:{"value":(_vm.newPasswordConfirmation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.newPasswordConfirmation=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.password_confirmation)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.password_confirmation[0]))])]):_vm._e()],1)]),_c('div',{staticClass:"md:flex gap-4 mt-4 mb-2"},[_c('button',{staticClass:"btn btn--primary",attrs:{"disabled":_vm.isLoading,"type":"submit","alt":"Save","title":"Save"}},[(_vm.isLoading)?_c('span',{staticClass:"block mx-5"},[_c('Loading')],1):_c('SaveIcon',[_c('span',{staticClass:"ml-2 md:block hidden"},[_vm._v("Save")])])],1),_c('button',{staticClass:"btn btn--secondary",attrs:{"disabled":_vm.isLoading,"type":"button","alt":"Back","title":"Back"},on:{"click":function($event){return _vm.goBack()}}},[_c('BackIcon',[_c('span',{staticClass:"ml-2 md:block hidden"},[_vm._v("Back")])])],1)])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }