import Vue from 'vue'
import Vuex from 'vuex'
import sidebar from './sidebar'
import unit from './unit'
import product from './product'
import recipe from './recipe'
import purchase from './purchase'
import detailPurchase from './detailPurchase'
import receive from './receive'
import adjustment from './adjustment'
import transfer from './transfer'
import stockOpname from './stockOpname'
import order from './order'
import calculation from './calculation'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: true,
  plugins: [createPersistedState()],
  modules: {
    sidebar,
    unit,
    product,
    recipe,
    purchase,
    detailPurchase,
    receive,
    adjustment,
    transfer,
    stockOpname,
    order,
    calculation
  },  
  state: {
    isUserLoggedIn: false,
    token: null,
    user: null
  },
  mutations: {
    setAuthentication(state, token){
      state.token = token
      state.isUserLoggedIn = !!(token)
    },
    setUser(state, user){
      state.user = user
    }
  },
  actions: {
    setAuthentication({commit}, token){
      commit('setAuthentication', token)
    },
    setUser({commit}, user){
      commit('setUser', user)
    }
  },
  getters: {
    isUserLoggedIn: state => state.isUserLoggedIn,
    token: state => state.token,
    user: state => state.user
  }
})

export default store