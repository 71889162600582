<template>
  <div>
    <button type="button" class="btn btn--success ml-2" @click="toggleModal">
      <DownloadIcon>
        <span class="ml-2 md:block hidden">Export</span>
      </DownloadIcon>
    </button>
    <!-- Import Modal Dialog -->
    <modal :show="showModal" @close="showModal = false" addClass="modal-2xl" modalOrientation="pt-16 md:pt-10">
      <template v-slot:header><h3>{{ modalTitle }}</h3></template>
      <template v-slot:body>
        <div class="w-full md:flex gap-2 mb-2 justify-between">
          <div class="flex w-1/2 mb-2"></div>
          <div class="flex gap-2 mb-2 right-0">
            <button :disabled="isLoading" type="button" @click="fetchData()" class="btn btn--primary flex">
              Refresh
            </button>
            <button :disabled="isLoading" type="button" @click="downloadFile()" class="btn btn--success flex">
              Download
            </button>
          </div>
        </div>
        <div class="mb-4 bg-white rounded-sm overflow-auto">
          <table class="min-w-max w-full table-auto">
            <thead>
              <tr class="bg-purple-200 text-purple-600 uppercase text-sm leading-normal">
                <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Season</th>
                <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Period</th>
                <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Customer</th>
                <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Order No</th>
                <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Type</th>
                <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Factory</th>
                <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Delivery Date Production</th>
                <th scope="col" class="px-2 border-r border-b border-purple-300 border-opacity-50 text-center" colspan="2">Delivery Date for Customer</th>
                <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Remarks</th>
                <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Total Qty</th>
                <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Status</th>
              </tr>
              <tr class="bg-purple-200 text-purple-600 uppercase text-xs leading-normal">
                <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center date-column">From</th>
                <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center date-column">To</th>             
              </tr>                                                          
            </thead>
            <tbody class="text-gray-600 text-xs font-light">
              <tr v-if="orders.length === 0" class="border-b border-gray-200 hover:bg-gray-100"><td class="py-3 px-6 text-sm text-center" colspan="12">No data to display</td></tr>
              <tr v-for="(order, index) in orders" :key="order.id" class="border-b border-gray-200 hover:bg-gray-100" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">
                <td class="px-2 text-left">{{ order.season.season_name }}</td>
                <td class="px-2 text-center">{{ order.period }}</td>
                <td class="px-2 text-left">{{ order.customer_name }}</td>
                <td class="px-2 text-left">{{ order.order_no }}</td>
                <td class="px-2 text-center">
                  <span class="badge" :class="order.type === 1 ? 'badge--info' : 'badge--success'">{{ order.type_text }}</span>
                </td>
                <td class="px-2 text-left">{{ order.factory.factory_name }}</td>
                <td class="px-2 text-left">{{ order.delivery_date_production }}</td>
                <td class="px-2 text-left">{{ order.delivery_date_from }}</td>
                <td class="px-2 text-left">{{ order.delivery_date_to }}</td>
                <td class="px-2 text-left">{{ order.remarks }}</td>
                <td class="px-2 text-right">{{ formatNumber(order.total_items) }}</td>
                <td class="px-2 text-center">
                  <span class="badge" :class="order.status === 1 ? 'badge--light-primary' : (order.status === 2 ? 'badge--success' : 'badge--danger')">{{ order.status_text }}</span>
                </td>
              </tr>          
            </tbody>
          </table>   
        </div>           
      </template>
      <template v-slot:footer>     
      </template> 
    </modal>
  </div>
</template>

<script>
import format from '@/helpers/formatNumber'
import orderServices from '@/services/order/orderServices'
import Modal from '../../widgets/Modal'
import DownloadIcon from '../../icons/DownloadIcon'

export default {
  name: 'ExportOrder',
  components: {
    Modal,
    DownloadIcon,
  },
  data() {
    return {
      orders: [],
      error: [],
      isLoading: false, 
      modalTitle: '',     
      showModal: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }     
    }
  },
  methods: {
    async fetchData() {
      try {
        this.isLoading = true
        
        const params = {
          take: this.$route.query.take,
          page: this.$route.query.page,
          search: this.$route.query.search,
          season: this.$route.query.season,
          period: this.$route.query.period ? this.$route.query.period : null,
          factory: this.$route.query.factory ? this.$route.query.factory : null,
          date_field: this.$route.query.date_field,
          start: this.$route.query.start,
          end: this.$route.query.end,
          sort_field: this.$route.query.sort_field,
          sort_option: this.$route.query.sort_option
        }
        const response = await orderServices.fetchAll(params)
        if (response.data.status === 'success') {
          this.isLoading = false
          this.orders = response.data.data
        } else {
          this.isLoading =false

          /* THROW ERROR MESSAGES */
          this.toast.error(response.data.message)          
        }
      } catch (error) {
        this.isLoading =false
        console.log(error)
      }
    },    
    toggleModal() {
      this.fetchData()
      this.showModal = true
      this.modalTitle = 'Export Summary Order'
    },
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    }, 
    clearProduct() {
      this.products = []
    },
    openDownloadTab(key) {
      let baseUrl = window.location.origin
      window.open(baseUrl+'/auth/download?key='+key, '_blank')
    },    
    async downloadFile() {
      try {
        this.isLoading = true
        const payload = {
          take: this.$route.query.take,
          page: this.$route.query.page,
          search: this.$route.query.search,
          season: this.$route.query.season,
          period: this.$route.query.period ? this.$route.query.period : null,
          factory: this.$route.query.factory ? this.$route.query.factory : null,
          date_field: this.$route.query.date_field,
          start: this.$route.query.start,
          end: this.$route.query.end,
          sort_field: this.$route.query.sort_field,
          sort_option: this.$route.query.sort_option
        }
        const response = await orderServices.export(payload)
        if (response.data.status === 'success') {
          this.isLoading = false
          this.$toasted.success(response.data.message, this.toastedOptions)

          const key = response.data.data
          this.openDownloadTab(key)
        } else {
          this.isLoading = false

          const returnType = typeof response.data.message
          if (returnType === 'object') {
            this.$toasted.error('Please fill collection type and reference!', this.toastedOptions)
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }
        }
      } catch (error) {
        this.isLoading = false
        console.log(error)
      }
    }    
  }
}
</script>