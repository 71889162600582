<template>
  <div>
    <button type="button" class="w-full btn btn--success" @click="toggleModal" v-tooltip="'Add Exchange Rate'">
      Add Rate
    </button>
    <!-- Import Modal Dialog -->
    <modal :show="showModal" @close="showModal = false" addClass="modal-sm" modalOrientation="pt-16 md:pt-5 md:flex md:items-center md:justify-center">
      <template v-slot:header><h3>{{ modalTitle }}</h3></template>
      <template v-slot:body>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form id="rateForm" @submit.prevent="handleSubmit(create)">
            <div class="md:flex gap-6">
              <div class="md:w-1/2 mb-4">
                <ValidationProvider name="Currency" rules="required" v-slot="{ errors }">
                  <label for="currency" class="label-control">Currency <span class="text-red-600">*</span></label>
                  <input id="currency" ref="currency" type="text" v-model="currency" class="form-control">
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
                <div v-if="error.currency" class="text-sm text-red-600"><span>{{ error.currency[0] }}</span></div>
              </div>
              <div class="md:w-1/2 mb-4">
                <ValidationProvider name="Symbol" rules="required" v-slot="{ errors }">
                  <label for="symbol" class="label-control">Symbol <span class="text-red-600">*</span></label>
                  <input id="symbol" type="text" v-model="symbol" class="form-control">
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
                <div v-if="error.symbol" class="text-sm text-red-600"><span>{{ error.symbol[0] }}</span></div>
              </div>
            </div>
            <div class="md:flex gap-6">
              <div class="md:w-1/2 mb-4">
                <ValidationProvider name="Rate" rules="required" v-slot="{ errors }">
                  <label for="rate" class="label-control">Rate <span class="text-red-600">*</span></label>
                  <input id="rate" type="text" v-model="rate" class="form-control" @input="handleInputRate">
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
                <div v-if="error.rate" class="text-sm text-red-600"><span>{{ error.rate[0] }}</span></div>
              </div>
              <div class="md:w-1/2 mb-4"></div>
            </div>
          </form> 
        </ValidationObserver>        
      </template>
      <template v-slot:footer>
        <button :disabled="isLoading" type="submit" form="rateForm" class="btn btn--primary">
          Save
        </button>      
      </template> 
    </modal>
  </div>
</template>

<script>
import format from '@/helpers/formatNumber'
import rateServices from '@/services/rate/rateServices'
import Modal from '../../widgets/Modal'

export default {
  name: 'UnitModal',
  components: {
    Modal,
  },
  data() {
    return {
      currency: '',
      symbol: '',
      rate: '',
      error: [],
      isLoading: false, 
      modalTitle: '',     
      showModal: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }         
    }
  },
  methods: {
    toggleModal() {
      this.showModal = true
      this.modalTitle = 'Exchange Rate'
    },
    async create(){
      try {
        this.isLoading = true       
        const payload = {
          currency: this.currency,
          symbol: this.symbol,
          rate: this.unformatNumber(this.rate)
        }
        const response = await rateServices.create(payload)
        if (response.data.status === 'success') {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false
                    
          /* CLEAR INPUT FORM */
          this.clearForm()

          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          this.fetchRate()

          this.showModal = false
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.currency || responseReturn.symbol || responseReturn.rate) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    },
    handleInputRate(event) {
      this.rate = this.formatNumber(event.target.value)
    },        
    clearForm(){
      this.currency = ''
      this.symbol = ''
      this.rate = ''
    },
    fetchRate() {
      this.$root.$emit('fetchRate')      
    }        
  }
}
</script>