<template>
  <nav class="px-1 pt-6 overflow-y-auto font-medium text-base sm:px-3 xl:px-5 lg:text-sm pb-10 lg:pt-10 lg:pb-14 h-screen">
    <ul>
      <li v-for="(section, index) in menu" :key="index" :class="{ 'mt-8' : section.header }">
        <h5 v-if="section.header !== null" class="nav-header">{{ section.header }}</h5>
        <span v-if="section.header === null" @click="toggleSidebar">
          <router-link :to="{ name: section.items[0].url }" class="nav-item">
            <span class="relative">{{ section.items[0].name }}</span>
          </router-link>
        </span>
        <ul v-if="section.header !== null">
          <li v-for="item in section.items" :key="item.name" @click="toggleSidebar">
            <router-link :to="{ name: item.url }" class="nav-item">
              <span class="relative">{{ item.name }}</span>
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'SidebarNav',
  data(){
    return {
      menu: [
        {
          header: null,
          items: [
            {
              name: 'Dashboard',
              isOpen: true,
              url: 'dashboard'
            }
          ]
        },
        {
          header: 'Data Masters',
          items: [
            {
              name: 'Suppliers',
              isOpen: false,
              url: 'supplier'            
            },
            {
              name: 'Units',
              isOpen: false,
              url: 'unit'            
            },
            {
              name: 'Seasons',
              isOpen: false,
              url: 'season'            
            },            
            {
              name: 'Collections',
              isOpen: false,
              url: 'collection'            
            },
            {
              name: 'Factory',
              isOpen: false,
              url: 'factory'            
            },            
            {
              name: 'Raw Materials',
              isOpen: false,
              url: 'material'            
            },
            {
              name: 'Products',
              isOpen: false,
              url: 'product'            
            }                                                                       
          ]
        },
        {
          header: 'Transactions',
          items: [
            {
              name: 'Customer Orders',
              isOpen: false,
              url: 'order'            
            }                                                                      
          ]
        },
        {
          header: 'Process',
          items: [
            {
              name: 'Materials Calculation',
              isOpen: false,
              url: 'calculation'            
            },
            {
              name: 'Order Recapitulation',
              isOpen: false,
              url: 'recapitulation'            
            },
            {
              name: 'Material Analysis',
              isOpen: false,
              url: 'analysis'            
            }                                                                                   
          ]
        },        
        {
          header: 'Inventory',
          items: [
            {
              name: 'Purchase Order',
              isOpen: false,
              url: 'purchase'
            },
            {
              name: 'Receiving',
              isOpen: false,
              url: 'receive'
            },
            {
              name: 'Adjustments',
              isOpen: false,
              url: 'adjustment'
            },
            {
              name: 'Transfer Stock',
              isOpen: false,
              url: 'transfer'
            },            
            {
              name: 'Stock Opname',
              isOpen: false,
              url: 'opname'
            }                                                                      
          ]
        },
        {
          header: 'Reports',
          items: [
            {
              name: 'Material Reports',
              isOpen: false,
              url: 'reportMaterial'            
            },
            {
              name: 'Order Reports',
              isOpen: false,
              url: 'reportOrder'            
            }                                                                       
          ]
        },
        {
          header: 'Settings',
          items: [
            {
              name: 'Default Variable',
              isOpen: false,
              url: 'variable'            
            },
            {
              name: 'Exchange Rate',
              isOpen: false,
              url: 'rate'            
            },
            {
              name: 'Adjustment Reasons',
              isOpen: false,
              url: 'adjustmentReason'
            },            
            {
              name: 'Users',
              isOpen: false,
              url: 'user'            
            },
            {
              name: 'Activity Logs',
              isOpen: false,
              url: 'activityLog'            
            }                                                                                  
          ]
        }                                
      ]
    }
  },
  methods: {
    // toggleBlockActive(name) {
    //   this.$store.dispatch('toggleSidebarActive', name)
    //   this.toggleSidebar()

    //   // let sectionItem = this.menu.find(obj => obj.header === section);

    //   // let block = sectionItem.items.find(obj => obj.name === name);

    //   // if (block) {
    //   //   block.isOpen = !block.isOpen;
    //   // }

    //   // // close all blocks
    //   // for (let section of this.menu) {
    //   //   for (let block of section.items) {
    //   //     if (block.name !== name) {
    //   //       block.isOpen = false;
    //   //     }
    //   //   }
    //   // }      
    // },
    toggleSidebar(){
      this.$store.dispatch('toggleSidebar', !this.isHidden)
    }     
  }
}
</script>