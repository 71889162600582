import axios from '@/axios'

export default {
  fetchAll(params, id){
    return axios.get(`/recipes/all/${id}`, {params})
  },
  fetchLimit(params, id){
    return axios.get(`/recipes/limit/${id}`, {params})
  },  
  fetchById(id){
    return axios.get(`/recipes/${id}`)
  },    
  create(payload){
    return axios.post(`/recipes`, payload)
  },
  update(id, payload){
    return axios.post(`/recipes/${id}/update`, payload)
  },
  delete(id){
    return axios.delete(`/recipes/${id}`)
  }, 
  deleteMultiple(payload){
    return axios.delete(`/recipes`, payload)
  }
}