const state = {
  newPurchaseID: null,
  indexPurchaseRoute: null,
  purchaseNumber: null
};

const getters = {
  newPurchaseID: state => state.newPurchaseID,
  indexPurchaseRoute: state => state.indexPurchaseRoute,
  purchaseNumber: state => state.purchaseNumber
};

const actions = {
  setNewPurchaseID({commit}, newPurchaseID){
    commit('setNewPurchaseID', newPurchaseID)
  },
  setIndexPurchaseRoute({commit}, fullPath){
    commit('setIndexPurchaseRoute', fullPath)
  },
  setPurchaseNumber({commit}, purchaseNumber){
    commit('setPurchaseNumber', purchaseNumber)
  }
};

const mutations = {
  setNewPurchaseID(state, newPurchaseID){
    state.newPurchaseID = newPurchaseID
  },
  setIndexPurchaseRoute(state, fullPath){
    state.indexPurchaseRoute = fullPath
  },
  setPurchaseNumber(state, purchaseNumber){
    state.purchaseNumber = purchaseNumber
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};