<template>
  <div>
    <!-- ACTION PANEL SECTION -->
    <div class="flex border-b border-gray-200 text-gray-800 justify-between items-center mb-4 pb-2">
      <div class="md:w-1/2 w-full items-center">
        <h2 class="text-2xl font-medium">Orders Recapitulation</h2>
      </div>
      <div class="flex items-center right-0">
      </div>
    </div>
    <div id="tabs">
      <div class="tabs">
        <a v-on:click="activeTab='1'" v-bind:class="[ activeTab === '1' ? 'active' : '' ]">Parameters</a>
        <a v-on:click="activeTab='2'" v-bind:class="[ activeTab === '2' ? 'active' : '' ]">Orders Recapitulation</a>
      </div>
      <div class="content">
        <div v-if="activeTab ==='1'" class="tab-content">
          <div class="flex border-b border-gray-200 text-gray-800 justify-between items-center mb-4 pb-2">
            <h2 class="text-2xl font-medium">Parameters Requirements</h2>
          </div>
          <div class="w-full md:flex gap-4">
            <div class="w-full md:flex gap-2 mb-4">
              <div class="flex md:w-3/12">
                <div class="w-full mb-2 md:mb-0">
                  <label for="season" class="label-control md:py-3">Season <span class="text-red-600">*</span></label>
                </div>
              </div>
              <div class="w-full md:w-9/12 mb-2 md:mb-0">
                <ValidationProvider name="Season" rules="required" v-slot="{ errors }">
                  <MultiSelect id="season" v-model="season" :options="seasons" :value="season" :searchable="true" track-by="id" label="name" placeholder="Type to Search">
                    <template slot="caret">
                      <div>
                        <div class="multiselect__select">
                          <span>
                            <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                              <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </template>
                  </MultiSelect>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
                <div v-if="error.season" class="text-sm text-red-600"><span>{{ error.season[0] }}</span></div>
              </div>
            </div>
            <div class="w-full md:flex gap-2 mb-4"></div>                       
          </div>          
          <div class="w-full md:flex gap-6">
            <div class="w-full md:w-1/2">
              <div class="w-full md:flex gap-2 mb-2">
                <div class="flex md:w-3/12">
                  <div class="w-full mb-2 md:mb-0">
                    <label for="period_1_start" class="label-control md:py-3">Period 1 <span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="w-full md:w-9/12 mb-2 md:mb-0">
                  <v-date-picker ref="calendar" v-model="periodDate1" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs" is-range>
                    <template v-slot="{ inputValue, inputEvents, isDragging }">
                      <div class="flex flex-col sm:flex-row justify-start items-center">
                        <div class="w-full flex-grow mb-2 md:mb-0">
                          <ValidationProvider name="Period 1 Start" rules="required" v-slot="{ errors }">
                            <div class="w-full relative flex-grow">
                              <input id="period_1_start" class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" />
                              <span class="h-full absolute pointer-events-none right-0">
                                <DateRange class="m-3" />
                              </span> 
                            </div>
                            <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                          </ValidationProvider>
                          <div v-if="error.period_1_start" class="text-sm text-red-600"><span>{{ error.period_1_start[0] }}</span></div>
                        </div>
                        <span class="flex-shrink-0 m-2 hidden md:block">
                          <svg class="w-4 h-4 stroke-current text-gray-600" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                          </svg>
                        </span>
                        <div class="w-full flex-grow">
                          <ValidationProvider name="Period 1 End" rules="required" v-slot="{ errors }">
                            <div class="w-full relative flex-grow">
                              <input class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end" />
                              <span class="h-full absolute pointer-events-none right-0">
                                <DateRange class="m-3" />
                              </span>
                            </div>
                            <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                          </ValidationProvider>
                          <div v-if="error.period_1_end" class="text-sm text-red-600"><span>{{ error.period_1_end[0] }}</span></div>                               
                        </div>                
                      </div>
                    </template>
                  </v-date-picker>            
                </div>
              </div>
              <div class="w-full md:flex gap-2 mb-2">
                <div class="flex md:w-3/12">
                  <div class="w-full mb-2 md:mb-0">
                    <label for="period_2_start" class="label-control md:py-3">Period 2 <span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="w-full md:w-9/12 mb-2 md:mb-0">
                  <v-date-picker ref="calendar" v-model="periodDate2" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs" :min-date="new Date(new Date(periodDate1.end || minDate2).setDate(new Date(periodDate1.end || minDate2).getDate()+1))" is-range>
                    <template v-slot="{ inputValue, inputEvents, isDragging }">
                      <div class="flex flex-col sm:flex-row justify-start items-center">
                        <div class="w-full flex-grow mb-2 md:mb-0">
                          <ValidationProvider name="Period 2 Start" rules="" v-slot="{ errors }">
                            <div class="w-full relative flex-grow">
                              <input id="period_2_start" class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" />
                              <span class="h-full absolute pointer-events-none right-0">
                                <DateRange class="m-3" />
                              </span> 
                            </div>
                            <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                          </ValidationProvider>
                          <div v-if="error.period_2_start" class="text-sm text-red-600"><span>{{ error.period_2_start[0] }}</span></div>
                        </div>
                        <span class="flex-shrink-0 m-2 hidden md:block">
                          <svg class="w-4 h-4 stroke-current text-gray-600" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                          </svg>
                        </span>
                        <div class="w-full flex-grow">
                          <ValidationProvider name="Period 2 End" rules="" v-slot="{ errors }">
                            <div class="w-full relative flex-grow">
                              <input class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end" />
                              <span class="h-full absolute pointer-events-none right-0">
                                <DateRange class="m-3" />
                              </span>
                            </div>
                            <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                          </ValidationProvider>
                          <div v-if="error.period_2_end" class="text-sm text-red-600"><span>{{ error.period_2_end[0] }}</span></div>                               
                        </div>                
                      </div>
                    </template>
                  </v-date-picker>            
                </div>
              </div>
              <div class="w-full md:flex gap-2 mb-2">
                <div class="flex md:w-3/12">
                  <div class="w-full mb-2 md:mb-0">
                    <label for="period_3_start" class="label-control md:py-3">Period 3 <span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="w-full md:w-9/12 mb-2 md:mb-0">
                  <v-date-picker ref="calendar" v-model="periodDate3" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs" :min-date="new Date(new Date(periodDate2.end || minDate3).setDate(new Date(periodDate2.end || minDate3).getDate()+1))" is-range>
                    <template v-slot="{ inputValue, inputEvents, isDragging }">
                      <div class="flex flex-col sm:flex-row justify-start items-center">
                        <div class="w-full flex-grow mb-2 md:mb-0">
                          <ValidationProvider name="Period 3 Start" rules="" v-slot="{ errors }">
                            <div class="w-full relative flex-grow">
                              <input id="period_3_start" class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" />
                              <span class="h-full absolute pointer-events-none right-0">
                                <DateRange class="m-3" />
                              </span> 
                            </div>
                            <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                          </ValidationProvider>
                          <div v-if="error.period_3_start" class="text-sm text-red-600"><span>{{ error.period_3_start[0] }}</span></div>
                        </div>
                        <span class="flex-shrink-0 m-2 hidden md:block">
                          <svg class="w-4 h-4 stroke-current text-gray-600" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                          </svg>
                        </span>
                        <div class="w-full flex-grow">
                          <ValidationProvider name="Period 3 End" rules="" v-slot="{ errors }">
                            <div class="w-full relative flex-grow">
                              <input class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end" />
                              <span class="h-full absolute pointer-events-none right-0">
                                <DateRange class="m-3" />
                              </span>
                            </div>
                            <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                          </ValidationProvider>
                          <div v-if="error.period_3_end" class="text-sm text-red-600"><span>{{ error.period_3_end[0] }}</span></div>                               
                        </div>                
                      </div>
                    </template>
                  </v-date-picker>            
                </div>
              </div>            
            </div>
            <div class="w-full md:w-1/2">
              <div class="w-full md:flex gap-2 mb-2">
                <div class="flex md:w-3/12">
                  <div class="w-full mb-2 md:mb-0">
                    <label for="period_4_start" class="label-control md:py-3">Period 4 <span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="w-full md:w-9/12 mb-2 md:mb-0">
                  <v-date-picker ref="calendar" v-model="periodDate4" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs" :min-date="new Date(new Date(periodDate3.end || minDate4).setDate(new Date(periodDate3.end || minDate4).getDate()+1))" is-range>
                    <template v-slot="{ inputValue, inputEvents, isDragging }">
                      <div class="flex flex-col sm:flex-row justify-start items-center">
                        <div class="w-full flex-grow mb-2 md:mb-0">
                          <ValidationProvider name="Period 4 Start" rules="" v-slot="{ errors }">
                            <div class="w-full relative flex-grow">
                              <input id="period_4_start" class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" />
                              <span class="h-full absolute pointer-events-none right-0">
                                <DateRange class="m-3" />
                              </span> 
                            </div>
                            <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                          </ValidationProvider>
                          <div v-if="error.period_4_start" class="text-sm text-red-600"><span>{{ error.period_4_start[0] }}</span></div>
                        </div>
                        <span class="flex-shrink-0 m-2 hidden md:block">
                          <svg class="w-4 h-4 stroke-current text-gray-600" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                          </svg>
                        </span>
                        <div class="w-full flex-grow">
                          <ValidationProvider name="Period 4 End" rules="" v-slot="{ errors }">
                            <div class="w-full relative flex-grow">
                              <input class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end" />
                              <span class="h-full absolute pointer-events-none right-0">
                                <DateRange class="m-3" />
                              </span>
                            </div>
                            <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                          </ValidationProvider>
                          <div v-if="error.period_4_end" class="text-sm text-red-600"><span>{{ error.period_4_end[0] }}</span></div>                               
                        </div>                
                      </div>
                    </template>
                  </v-date-picker>            
                </div>
              </div>
              <div class="w-full md:flex gap-2 mb-2">
                <div class="flex md:w-3/12">
                  <div class="w-full mb-2 md:mb-0">
                    <label for="period_5_start" class="label-control md:py-3">Period 5 <span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="w-full md:w-9/12 mb-2 md:mb-0">
                  <v-date-picker ref="calendar" v-model="periodDate5" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs" :min-date="new Date(new Date(periodDate4.end || minDate5).setDate(new Date(periodDate4.end || minDate5).getDate()+1))" is-range>
                    <template v-slot="{ inputValue, inputEvents, isDragging }">
                      <div class="flex flex-col sm:flex-row justify-start items-center">
                        <div class="w-full flex-grow mb-2 md:mb-0">
                          <ValidationProvider name="Period 5 Start" rules="" v-slot="{ errors }">
                            <div class="w-full relative flex-grow">
                              <input id="period_5_start" class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" />
                              <span class="h-full absolute pointer-events-none right-0">
                                <DateRange class="m-3" />
                              </span> 
                            </div>
                            <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                          </ValidationProvider>
                          <div v-if="error.period_5_start" class="text-sm text-red-600"><span>{{ error.period_5_start[0] }}</span></div>
                        </div>
                        <span class="flex-shrink-0 m-2 hidden md:block">
                          <svg class="w-4 h-4 stroke-current text-gray-600" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                          </svg>
                        </span>
                        <div class="w-full flex-grow">
                          <ValidationProvider name="Period 5 End" rules="" v-slot="{ errors }">
                            <div class="w-full relative flex-grow">
                              <input class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end" />
                              <span class="h-full absolute pointer-events-none right-0">
                                <DateRange class="m-3" />
                              </span>
                            </div>
                            <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                          </ValidationProvider>
                          <div v-if="error.period_5_end" class="text-sm text-red-600"><span>{{ error.period_5_end[0] }}</span></div>                               
                        </div>                
                      </div>
                    </template>
                  </v-date-picker>            
                </div>
              </div>
              <div class="w-full md:flex gap-2 mb-2">
                <div class="flex md:w-3/12">
                  <div class="w-full mb-2 md:mb-0">
                    <label for="period_6_start" class="label-control md:py-3">Period 6 <span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="w-full md:w-9/12 mb-2 md:mb-0">
                  <v-date-picker ref="calendar" v-model="periodDate6" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs" :min-date="new Date(new Date(periodDate5.end || minDate6).setDate(new Date(periodDate5.end || minDate6).getDate()+1))" is-range>
                    <template v-slot="{ inputValue, inputEvents, isDragging }">
                      <div class="flex flex-col sm:flex-row justify-start items-center">
                        <div class="w-full flex-grow mb-2 md:mb-0">
                          <ValidationProvider name="Period 6 Start" rules="" v-slot="{ errors }">
                            <div class="w-full relative flex-grow">
                              <input id="period_6_start" class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" />
                              <span class="h-full absolute pointer-events-none right-0">
                                <DateRange class="m-3" />
                              </span> 
                            </div>
                            <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                          </ValidationProvider>
                          <div v-if="error.period_6_start" class="text-sm text-red-600"><span>{{ error.period_6_start[0] }}</span></div>
                        </div>
                        <span class="flex-shrink-0 m-2 hidden md:block">
                          <svg class="w-4 h-4 stroke-current text-gray-600" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                          </svg>
                        </span>
                        <div class="w-full flex-grow">
                          <ValidationProvider name="Period 6 End" rules="" v-slot="{ errors }">
                            <div class="w-full relative flex-grow">
                              <input class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end" />
                              <span class="h-full absolute pointer-events-none right-0">
                                <DateRange class="m-3" />
                              </span>
                            </div>
                            <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                          </ValidationProvider>
                          <div v-if="error.period_6_end" class="text-sm text-red-600"><span>{{ error.period_6_end[0] }}</span></div>                               
                        </div>                
                      </div>
                    </template>
                  </v-date-picker>            
                </div>
              </div>            
            </div>
          </div>                              
        </div>
        <div v-if="activeTab ==='2'" class="tab-content">
          <div class="flex border-b border-gray-200 text-gray-800 justify-between items-center mb-4 pb-2">
            <div class="md:w-1/2 w-full items-center">
              <h2 class="text-2xl font-medium">Recapitulation</h2>
            </div>
            <div class="flex items-center right-0 gap-4">
              <button :disabled="isLoading" type="button" @click="fetchData()" id="btnProcess" class="btn btn--primary" alt="Process" v-tooltip="'Process'">
                <span v-if="isLoading" class="block mx-5">
                  <Loading />
                </span>
                <RefreshIcon v-else>
                  <span class="ml-2 md:block hidden">Process</span>
                </RefreshIcon>
              </button>              
              <button :disabled="isLoading" type="button" @click="downloadFile()" id="btnDownload" class="btn btn--success" alt="Download" v-tooltip="'Download'">
                <span v-if="isLoading" class="block mx-5">
                  <Loading />
                </span>
                <DownloadIcon v-else>
                  <span class="ml-2 md:block hidden">Download</span>
                </DownloadIcon>
              </button>
            </div>
          </div>
          <div class="mb-4 bg-white shadow-lg rounded-lg overflow-y-auto">
            <span class="text-xs font-bold">NUMBER OF ORDERS:</span>
            <table class="table-fixed">
              <thead>
                <tr>
                  <td scope="col" class="px-2 py-1 text-xs text-left bg-yellow-100 type-column"><span>PERIOD 1</span></td>
                  <td scope="col" class="px-2 py-1 text-xs text-right bg-yellow-100 reference-column"><span>{{ formatNumber(headers.periode1TotalOrders) }}</span></td>
                  <td scope="col" class="px-0 py-0 text-xs text-left bg-white" :rowspan="collections.length" colspan="8">
                    <template v-for="(collection, index) in collections">
                      <div class="w-full flex" :key="index">
                        <div class="color-column">
                          <span class="block px-1 py-1.5">{{ collection.name }}</span>
                        </div>
                        <div class="period-column bg-yellow-100">
                          <span class="block px-1 py-1.5 text-right" v-if="collection">{{ formatNumber(collection.amount.periode1) }}</span>
                        </div>
                        <div class="period-column bg-blue-100">
                          <span class="block px-1 py-1.5 text-right" v-if="collection">{{ formatNumber(collection.amount.periode2) }}</span>
                        </div>
                        <div class="period-column bg-gray-100">
                          <span class="block px-1 py-1.5 text-right" v-if="collection">{{ formatNumber(collection.amount.periode3) }}</span>
                        </div>
                        <div class="period-column bg-yellow-100">
                          <span class="block px-1 py-1.5 text-right" v-if="collection">{{ formatNumber(collection.amount.periode4) }}</span>
                        </div>
                        <div class="period-column bg-blue-100">
                          <span class="block px-1 py-1.5 text-right" v-if="collection">{{ formatNumber(collection.amount.periode5) }}</span>
                        </div>
                        <div class="period-column bg-gray-100">
                          <span class="block px-1 py-1.5 text-right" v-if="collection">{{ formatNumber(collection.amount.periode6) }}</span>
                        </div>                        
                        <div class="total-column bg-white">
                          <span class="block px-1 py-1.5 text-right" v-if="collection">{{ formatNumber(collection.amount.grandtotal) }}</span>
                        </div>                      
                      </div>
                    </template>                  
                  </td>
                  <td scope="col" class="px-0 py-0 text-xs text-left bg-yellow-100" v-if="isProcess" rowspan="3" :colspan="headerLength.periode1">
                    <template v-for="summary in summaries">
                      <template v-for="(content, key) in summary.periode1">
                        <div class="w-full flex" :key="key">
                          <template v-for="(item, index) in content">
                            <span class="customer-column px-2 py-1.5 text-right" :key="index">{{ formatNumber(item) }}</span>
                          </template>
                        </div>
                      </template>
                    </template>
                  </td>
                  <td scope="col" class="px-0 py-0 text-xs text-left bg-blue-100" v-if="isProcess" rowspan="3" :colspan="headerLength.periode2">
                    <template v-for="summary in summaries">
                      <template v-for="(content, key) in summary.periode2">
                        <div class="w-full flex" :key="key">
                          <template v-for="(item, index) in content">
                            <span class="customer-column px-2 py-1.5 text-right" :key="index">{{ formatNumber(item) }}</span>
                          </template>
                        </div>
                      </template>
                    </template>
                  </td>
                  <td scope="col" class="px-0 py-0 text-xs text-left bg-gray-100" v-if="isProcess" rowspan="3" :colspan="headerLength.periode3">
                    <template v-for="summary in summaries">
                      <template v-for="(content, key) in summary.periode3">
                        <div class="w-full flex" :key="key">
                          <template v-for="(item, index) in content">
                            <span class="customer-column px-2 py-1.5 text-right" :key="index">{{ formatNumber(item) }}</span>
                          </template>
                        </div>
                      </template>
                    </template>
                  </td>
                  <td scope="col" class="px-0 py-0 text-xs text-left bg-yellow-100" v-if="isProcess" rowspan="3" :colspan="headerLength.periode4">
                    <template v-for="summary in summaries">
                      <template v-for="(content, key) in summary.periode4">
                        <div class="w-full flex" :key="key">
                          <template v-for="(item, index) in content">
                            <span class="customer-column px-2 py-1.5 text-right" :key="index">{{ formatNumber(item) }}</span>
                          </template>
                        </div>
                      </template>
                    </template>
                  </td>
                  <td scope="col" class="px-0 py-0 text-xs text-left bg-blue-100" v-if="isProcess" rowspan="3" :colspan="headerLength.periode5">
                    <template v-for="summary in summaries">
                      <template v-for="(content, key) in summary.periode5">
                        <div class="w-full flex" :key="key">
                          <template v-for="(item, index) in content">
                            <span class="customer-column px-2 py-1.5 text-right" :key="index">{{ formatNumber(item) }}</span>
                          </template>
                        </div>
                      </template>
                    </template>
                  </td>
                  <td scope="col" class="px-0 py-0 text-xs text-left bg-gray-100" v-if="isProcess" rowspan="3" :colspan="headerLength.periode6">
                    <template v-for="summary in summaries">
                      <template v-for="(content, key) in summary.periode6">
                        <div class="w-full flex" :key="key">
                          <template v-for="(item, index) in content">
                            <span class="customer-column px-2 py-1.5 text-right" :key="index">{{ formatNumber(item) }}</span>
                          </template>
                        </div>
                      </template>
                    </template>
                  </td>                                    
                </tr>
                <tr>
                  <td scope="col" class="px-2 py-1 text-xs text-left bg-blue-100"><span>PERIOD 2</span></td>
                  <td scope="col" class="px-2 py-1 text-xs text-right bg-blue-100"><span>{{ formatNumber(headers.periode2TotalOrders) }}</span></td>
                </tr>
                <tr>
                  <td scope="col" class="px-2 py-1 text-xs text-left bg-gray-100"><span>PERIOD 3</span></td>
                  <td scope="col" class="px-2 py-1 text-xs text-right bg-gray-100"><span>{{ formatNumber(headers.periode3TotalOrders) }}</span></td>
                </tr>
                <tr>
                  <td scope="col" class="px-2 py-1 text-xs text-left bg-yellow-100"><span>PERIOD 4</span></td>
                  <td scope="col" class="px-2 py-1 text-xs text-right bg-yellow-100"><span>{{ formatNumber(headers.periode4TotalOrders) }}</span></td>
                </tr>                
                <tr>
                  <td scope="col" class="px-2 py-1 text-xs text-left bg-blue-100"><span>PERIOD 5</span></td>
                  <td scope="col" class="px-2 py-1 text-xs text-right bg-blue-100"><span>{{ formatNumber(headers.periode5TotalOrders) }}</span></td>
                </tr>
                <tr>
                  <td scope="col" class="px-2 py-1 text-xs text-left bg-gray-100"><span>PERIOD 6</span></td>
                  <td scope="col" class="px-2 py-1 text-xs text-right bg-gray-100"><span>{{ formatNumber(headers.periode6TotalOrders) }}</span></td>
                </tr>                
                <tr>
                  <td class="bg-white"></td>
                </tr>              
              </thead>
              <thead>            
                <tr>
                  <th scope="col" class="px-3 border-r border-b border-purple-300 border-opacity-50 text-center type-column" rowspan="3">Type</th>
                  <th scope="col" class="px-3 border-r border-b border-purple-300 border-opacity-50 text-center reference-column" rowspan="3">Reference</th>
                  <th scope="col" class="px-3 border-r border-b border-purple-300 border-opacity-50 text-center color-column" rowspan="3">Color</th>
                  <th scope="col" class="px-3 border-r border-b border-purple-300 border-opacity-50 text-center bg-yellow-100 period-column" rowspan="3">Total 1</th>
                  <th scope="col" class="px-3 border-r border-b border-purple-300 border-opacity-50 text-center bg-blue-100 period-column" rowspan="3">Total 2</th>
                  <th scope="col" class="px-3 border-r border-b border-purple-300 border-opacity-50 text-center bg-gray-100 period-column" rowspan="3">Total 3</th>
                  <th scope="col" class="px-3 border-r border-b border-purple-300 border-opacity-50 text-center bg-yellow-100 period-column" rowspan="3">Total 4</th>
                  <th scope="col" class="px-3 border-r border-b border-purple-300 border-opacity-50 text-center bg-blue-100 period-column" rowspan="3">Total 5</th>
                  <th scope="col" class="px-3 border-r border-b border-purple-300 border-opacity-50 text-center bg-gray-100 period-column" rowspan="3">Total 6</th>                  
                  <th scope="col" class="px-3 border-r border-b border-purple-300 border-opacity-50 text-center bg-white total-column" rowspan="3">Grand Total</th>
                  <th scope="col" class="px-2 py-0 border-r border-b border-purple-300 border-opacity-50 text-center bg-yellow-100" v-if="isProcess" :colspan="headerLength.periode1">
                    PERIOD 1
                  </th>
                  <th scope="col" class="px-2 py-0 border-r border-b border-purple-300 border-opacity-50 text-center bg-blue-100" v-if="isProcess" :colspan="headerLength.periode2">
                    PERIOD 2
                  </th>
                  <th scope="col" class="px-2 py-0 border-r border-b border-purple-300 border-opacity-50 text-center bg-gray-100" v-if="isProcess" :colspan="headerLength.periode3">
                    PERIOD 3
                  </th>
                  <th scope="col" class="px-2 py-0 border-r border-b border-purple-300 border-opacity-50 text-center bg-yellow-100" v-if="isProcess" :colspan="headerLength.periode4">
                    PERIOD 4
                  </th>
                  <th scope="col" class="px-2 py-0 border-r border-b border-purple-300 border-opacity-50 text-center bg-blue-100" v-if="isProcess" :colspan="headerLength.periode5">
                    PERIOD 5
                  </th>
                  <th scope="col" class="px-2 py-0 border-r border-b border-purple-300 border-opacity-50 text-center bg-gray-100" v-if="isProcess" :colspan="headerLength.periode6">
                    PERIOD 6
                  </th>
                </tr>
                <tr>
                  <template v-if="isProcess">
                    <td scope="col" v-if="headers.periode1.length === 0" class="px-1 py-0 text-xs text-purple-600 border-r border-b border-purple-300 border-opacity-50 text-center bg-yellow-100 customer-column"></td>
                    <td scope="col" v-for="header in headers.periode1" :key="header.id" class="px-1 py-0 text-xs text-purple-600 border-r border-b border-purple-300 border-opacity-50 text-center bg-yellow-100 customer-column">
                      {{ header.customer_name }}
                    </td>                                        
                  </template>
                  <template v-if="isProcess">
                    <td scope="col" v-if="headers.periode2.length === 0" class="px-1 py-0 text-xs text-purple-600 border-r border-b border-purple-300 border-opacity-50 text-center bg-blue-100 customer-column"></td>
                    <td scope="col" v-for="header in headers.periode2" :key="header.id" class="px-1 py-0 text-xs text-purple-600 border-r border-b border-purple-300 border-opacity-50 text-center bg-blue-100 customer-column">
                      {{ header.customer_name }}
                    </td>                    
                  </template>
                  <template v-if="isProcess">
                    <td scope="col" v-if="headers.periode3.length === 0" class="px-1 py-0 text-xs text-purple-600 border-r border-b border-purple-300 border-opacity-50 text-center bg-gray-100 customer-column"></td>
                    <td scope="col" v-for="header in headers.periode3" :key="header.id" class="px-1 py-0 text-xs text-purple-600 border-r border-b border-purple-300 border-opacity-50 text-center bg-gray-100 customer-column">
                      {{ header.customer_name }}
                    </td>                    
                  </template>
                  <template v-if="isProcess">
                    <td scope="col" v-if="headers.periode4.length === 0" class="px-1 py-0 text-xs text-purple-600 border-r border-b border-purple-300 border-opacity-50 text-center bg-yellow-100 customer-column"></td>
                    <td scope="col" v-for="header in headers.periode4" :key="header.id" class="px-1 py-0 text-xs text-purple-600 border-r border-b border-purple-300 border-opacity-50 text-center bg-yellow-100 customer-column">
                      {{ header.customer_name }}
                    </td>                    
                  </template>
                  <template v-if="isProcess">
                    <td scope="col" v-if="headers.periode5.length === 0" class="px-1 py-0 text-xs text-purple-600 border-r border-b border-purple-300 border-opacity-50 text-center bg-blue-100 customer-column"></td>
                    <td scope="col" v-for="header in headers.periode5" :key="header.id" class="px-1 py-0 text-xs text-purple-600 border-r border-b border-purple-300 border-opacity-50 text-center bg-blue-100 customer-column">
                      {{ header.customer_name }}
                    </td>                    
                  </template>
                  <template v-if="isProcess">
                    <td scope="col" v-if="headers.periode6.length === 0" class="px-1 py-0 text-xs text-purple-600 border-r border-b border-purple-300 border-opacity-50 text-center bg-gray-100 customer-column"></td>
                    <td scope="col" v-for="header in headers.periode6" :key="header.id" class="px-1 py-0 text-xs text-purple-600 border-r border-b border-purple-300 border-opacity-50 text-center bg-gray-100 customer-column">
                      {{ header.customer_name }}
                    </td>                    
                  </template>                 
                </tr>                
                <tr>
                  <template v-if="isProcess">
                    <td scope="col" v-if="headers.periode1.length === 0" class="px-1 py-0 text-xs text-purple-600 border-r border-b border-purple-300 border-opacity-50 text-center bg-yellow-100 customer-column"></td>
                    <td scope="col" v-for="header in headers.periode1" :key="header.id" class="px-1 py-0 text-xs text-purple-600 border-r border-b border-purple-300 border-opacity-50 text-center bg-yellow-100 customer-column">
                      {{ header.order_no }}
                    </td>                                        
                  </template>
                  <template v-if="isProcess">
                    <td scope="col" v-if="headers.periode2.length === 0" class="px-1 py-0 text-xs text-purple-600 border-r border-b border-purple-300 border-opacity-50 text-center bg-blue-100 customer-column"></td>
                    <td scope="col" v-for="header in headers.periode2" :key="header.id" class="px-1 py-0 text-xs text-purple-600 border-r border-b border-purple-300 border-opacity-50 text-center bg-blue-100 customer-column">
                      {{ header.order_no }}
                    </td>                    
                  </template>
                  <template v-if="isProcess">
                    <td scope="col" v-if="headers.periode3.length === 0" class="px-1 py-0 text-xs text-purple-600 border-r border-b border-purple-300 border-opacity-50 text-center bg-gray-100 customer-column"></td>
                    <td scope="col" v-for="header in headers.periode3" :key="header.id" class="px-1 py-0 text-xs text-purple-600 border-r border-b border-purple-300 border-opacity-50 text-center bg-gray-100 customer-column">
                      {{ header.order_no }}
                    </td>                    
                  </template>
                  <template v-if="isProcess">
                    <td scope="col" v-if="headers.periode4.length === 0" class="px-1 py-0 text-xs text-purple-600 border-r border-b border-purple-300 border-opacity-50 text-center bg-yellow-100 customer-column"></td>
                    <td scope="col" v-for="header in headers.periode4" :key="header.id" class="px-1 py-0 text-xs text-purple-600 border-r border-b border-purple-300 border-opacity-50 text-center bg-yellow-100 customer-column">
                      {{ header.order_no }}
                    </td>                    
                  </template>
                  <template v-if="isProcess">
                    <td scope="col" v-if="headers.periode5.length === 0" class="px-1 py-0 text-xs text-purple-600 border-r border-b border-purple-300 border-opacity-50 text-center bg-blue-100 customer-column"></td>
                    <td scope="col" v-for="header in headers.periode5" :key="header.id" class="px-1 py-0 text-xs text-purple-600 border-r border-b border-purple-300 border-opacity-50 text-center bg-blue-100 customer-column">
                      {{ header.order_no }}
                    </td>                    
                  </template>
                  <template v-if="isProcess">
                    <td scope="col" v-if="headers.periode6.length === 0" class="px-1 py-0 text-xs text-purple-600 border-r border-b border-purple-300 border-opacity-50 text-center bg-gray-100 customer-column"></td>
                    <td scope="col" v-for="header in headers.periode6" :key="header.id" class="px-1 py-0 text-xs text-purple-600 border-r border-b border-purple-300 border-opacity-50 text-center bg-gray-100 customer-column">
                      {{ header.order_no }}
                    </td>                    
                  </template>                 
                </tr>              
              </thead>
              <tbody>
                <tr v-if="products.length === 0" class="border-b border-gray-200"><td class="text-center" colspan="10">Run Process to Display Data</td></tr>
                <tr v-for="(product, index) in products" :key="index" class="border-b border-gray-200" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">
                  <td class="px-3 py-1 text-xs text-left">{{ product.type }}</td>
                  <td class="px-3 py-1 text-xs text-left">{{ product.reference }}</td>
                  <td class="px-3 py-1 text-xs text-left">{{ product.color }}</td>
                  <td class="px-3 py-1 text-xs text-right bg-yellow-100">{{ formatNumber(product.total_1) }}</td>
                  <td class="px-3 py-1 text-xs text-right bg-blue-100">{{ formatNumber(product.total_2) }}</td>
                  <td class="px-3 py-1 text-xs text-right bg-gray-100">{{ formatNumber(product.total_3) }}</td>
                  <td class="px-3 py-1 text-xs text-right bg-yellow-100">{{ formatNumber(product.total_4) }}</td>
                  <td class="px-3 py-1 text-xs text-right bg-blue-100">{{ formatNumber(product.total_5) }}</td>
                  <td class="px-3 py-1 text-xs text-right bg-gray-100">{{ formatNumber(product.total_6) }}</td>                  
                  <td class="px-3 py-1 text-xs text-right bg-white">{{ formatNumber(product.grand_total) }}</td>
                  <template>
                    <td scope="col" v-if="product.period_1.length === 0" class="px-2 py-0 text-xs text-right bg-yellow-100"></td>
                    <td scope="col" v-for="content in product.period_1" :key="content.id" class="px-2 py-0 text-xs text-right bg-yellow-100">
                      {{ formatNumber(content.qty) }}
                    </td>                    
                  </template>
                  <template>
                    <td scope="col" v-if="product.period_2.length === 0" class="px-2 py-0 text-xs text-right bg-blue-100"></td>
                    <td scope="col" v-for="content in product.period_2" :key="content.id" class="px-2 py-0 text-xs text-right bg-blue-100">
                      {{ formatNumber(content.qty) }}
                    </td>                    
                  </template>
                  <template>
                    <td scope="col" v-if="product.period_3.length === 0" class="px-2 py-0 text-xs text-right bg-gray-100"></td>
                    <td scope="col" v-for="content in product.period_3" :key="content.id" class="px-2 py-0 text-xs text-right bg-gray-100">
                      {{ formatNumber(content.qty) }}
                    </td>                    
                  </template>
                  <template>
                    <td scope="col" v-if="product.period_4.length === 0" class="px-2 py-0 text-xs text-right bg-yellow-100"></td>
                    <td scope="col" v-for="content in product.period_4" :key="content.id" class="px-2 py-0 text-xs text-right bg-yellow-100">
                      {{ formatNumber(content.qty) }}
                    </td>                    
                  </template>
                  <template>
                    <td scope="col" v-if="product.period_5.length === 0" class="px-2 py-0 text-xs text-right bg-blue-100"></td>
                    <td scope="col" v-for="content in product.period_5" :key="content.id" class="px-2 py-0 text-xs text-right bg-blue-100">
                      {{ formatNumber(content.qty) }}
                    </td>                    
                  </template>
                  <template>
                    <td scope="col" v-if="product.period_6.length === 0" class="px-2 py-0 text-xs text-right bg-gray-100"></td>
                    <td scope="col" v-for="content in product.period_6" :key="content.id" class="px-2 py-0 text-xs text-right bg-gray-100">
                      {{ formatNumber(content.qty) }}
                    </td>                  
                  </template>                 
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>  
    </div>       
  </div>
</template>

<script>
import format from '@/helpers/formatNumber'
import seasonServices from '@/services/season/seasonServices'
import recapitulationServices from '@/services/recapitulation/recapitulationServices'
import DownloadIcon from '../../icons/DownloadIcon'
import RefreshIcon from '../../icons/RefreshIcon'
import Loading from '../../icons/Loading'
import DateRange from '../../icons/DateRange'

export default {
  name: 'OrderRecapitulation',
  components: {
    DownloadIcon,
    RefreshIcon,
    Loading,
    DateRange
  },
  data() {
    return {
      activeTab: '1',
      season: '',
      seasons: [],    
      periodDate1: {
        start: '',
        end: ''
      },
      periodDate2: {
        start: '',
        end: ''
      },
      periodDate3: {
        start: '',
        end: ''
      },
      periodDate4: {
        start: '',
        end: ''
      },
      periodDate5: {
        start: '',
        end: ''
      },
      periodDate6: {
        start: '',
        end: ''
      },      
      masks: {
        input: 'YYYY/MM/DD',
      },
      attrs: [
        {
          key: 'today',
          highlight: 'red',
          dates: new Date(),
        },
      ],
      headers: {
        periode1: [],
        periode2: [],
        periode3: [],
        periode4: [],
        periode5: [],
        periode6: [],        
        periode1TotalOrders: 0,
        periode2TotalOrders: 0,
        periode3TotalOrders: 0,
        periode4TotalOrders: 0,
        periode5TotalOrders: 0,
        periode6TotalOrders: 0        
      },
      headerLength: {
        periode1: 0,
        periode2: 0,
        periode3: 0, 
        periode4: 0,
        periode5: 0,
        periode6: 0,               
      },
      collections: [],
      summaries: [],
      products: [],
      error: [],
      minDate2: new Date(),
      minDate3: new Date(),
      minDate4: new Date(),
      minDate5: new Date(), 
      minDate6: new Date(),
      isLoading: false,
      isProcess: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async fetchSeasonData() {
      try {
        const response = await seasonServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.seasons = []
          records.forEach(element => {
            this.seasons.push({
              'id': element.id,
              'name': element.season_name
            })
          });          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },    
    async fetchData() {
      try {
        this.isLoading = true

        const payload = {
          season: this.season ? this.season.id : '',
          period_1_start: this.periodDate1.start,
          period_1_end: this.periodDate1.end,
          period_2_start: this.periodDate2.start,
          period_2_end: this.periodDate2.end,
          period_3_start: this.periodDate3.start,
          period_3_end: this.periodDate3.end,
          period_4_start: this.periodDate4.start,
          period_4_end: this.periodDate4.end,
          period_5_start: this.periodDate5.start,
          period_5_end: this.periodDate5.end,
          period_6_start: this.periodDate6.start,
          period_6_end: this.periodDate6.end,
        }
        const response = await recapitulationServices.calculate(payload)
        if (response.data.status === 'success') {
          this.isLoading =false
          this.isProcess = true

          /* EMPTY ERRORS VARIABLE */
          this.error = []
                    
          const record = response.data.data

          this.headers = record.header
          this.collections = record.collections
          this.summaries = record.summary
          this.products = record.data
          this.headerLength = {
            periode1: this.headers.periode1.length,
            periode2: this.headers.periode2.length,
            periode3: this.headers.periode3.length,
            periode4: this.headers.periode4.length,
            periode5: this.headers.periode5.length,
            periode6: this.headers.periode6.length            
          }
        } else {
          this.isLoading = false
          this.isProcess = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.season || responseReturn.period_1_start || responseReturn.period_1_end || responseReturn.period_2_start 
              || responseReturn.period_2_end || responseReturn.period_3_start || responseReturn.period_3_end || responseReturn.period_4_start 
              || responseReturn.period_4_end || responseReturn.period_5_start || responseReturn.period_5_end || responseReturn.period_6_start || responseReturn.period_6_end) {
              this.error = response.data.message
              this.$toasted.error('Please fill the require parameters', this.toastedOptions)
          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }       
        }
      } catch (error) {
        this.isLoading = false
        this.isProcess = false
        console.log(error)
      }
    },
    openDownloadTab(key) {
      let baseUrl = window.location.origin
      window.open(baseUrl+'/auth/download?key='+key, '_blank')
    },    
    async downloadFile() {
      try {
        this.isLoading = true
        const payload = {
          season: this.season ? this.season.id : '',
          period_1_start: this.periodDate1.start,
          period_1_end: this.periodDate1.end,
          period_2_start: this.periodDate2.start,
          period_2_end: this.periodDate2.end,
          period_3_start: this.periodDate3.start,
          period_3_end: this.periodDate3.end,
          period_4_start: this.periodDate4.start,
          period_4_end: this.periodDate4.end,
          period_5_start: this.periodDate5.start,
          period_5_end: this.periodDate5.end,
          period_6_start: this.periodDate6.start,
          period_6_end: this.periodDate6.end, 
        }
        const response = await recapitulationServices.export(payload)
        if (response.data.status === 'success') {
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          this.$toasted.success(response.data.message, this.toastedOptions)

          const key = response.data.data
          this.openDownloadTab(key)
        } else {
          this.isLoading =false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.season || responseReturn.period_1_start || responseReturn.period_1_end || responseReturn.period_2_start 
              || responseReturn.period_2_end || responseReturn.period_3_start || responseReturn.period_3_end || responseReturn.period_4_start 
              || responseReturn.period_4_end || responseReturn.period_5_start || responseReturn.period_5_end || responseReturn.period_6_start || responseReturn.period_6_end) {
              this.error = response.data.message
              this.$toasted.error('Please fill the require parameters', this.toastedOptions)
          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }
        }
      } catch (error) {
        this.isLoading = false
        console.log(error)
      }
    },
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    },
  },
  mounted() {
    this.fetchSeasonData()
  }
}
</script>