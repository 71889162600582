import axios from '@/axios'

export default {
  fetchAll(params){
    return axios.get(`/roles/all`, {params})
  },
  fetchLimit(params){
    return axios.get(`/roles`, {params})
  },  
  fetchById(id){
    return axios.get(`/roles/${id}`)
  },    
  create(payload){
    return axios.post(`/roles`, payload)
  },
  update(id, payload){
    return axios.put(`/roles/${id}`, payload)
  },
  delete(id){
    return axios.delete(`/roles/${id}`)
  }, 
  deleteMultiple(payload){
    return axios.delete(`/roles`, payload)
  },
  fetchPermissionOptions(){
    return axios.get(`/roles/permissions/options`)
  }  
}