const state = {
  newStockOpnameID: null,
  indexStockOpnameRoute: null
};

const getters = {
  newStockOpnameID: state => state.newStockOpnameID,
  indexStockOpnameRoute: state => state.indexStockOpnameRoute
};

const actions = {
  setNewStockOpnameID({commit}, newStockOpnameID){
    commit('setNewStockOpnameID', newStockOpnameID)
  },
  setIndexStockOpnameRoute({commit}, fullPath){
    commit('setIndexStockOpnameRoute', fullPath)
  }
};

const mutations = {
  setNewStockOpnameID(state, newStockOpnameID){
    state.newStockOpnameID = newStockOpnameID
  },
  setIndexStockOpnameRoute(state, fullPath){
    state.indexStockOpnameRoute = fullPath
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};