<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="bg-white shadow-lg rounded-lg" @submit.prevent="handleSubmit(update)">
        <div class="p-10">
          <div class="pb-2 mb-4 border-b border-gray-200">
            <h2 class="text-gray-800 text-2xl font-medium">Change Password</h2>
          </div>
          <div class="md:flex gap-6">
            <div class="w-full mb-4">
              <ValidationProvider name="Current Password" rules="required" v-slot="{ errors }">
                <label for="current_password" class="label-control">Current Password <span class="text-red-600">*</span></label>
                <input id="current_password" ref="current_password" type="password" v-model="currentPassword" maxlength="255" class="form-control" autofocus>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.current_password" class="text-sm text-red-600"><span>{{ error.current_password[0] }}</span></div>              
            </div>
          </div>          
          <div class="md:flex gap-6">
            <div class="md:w-1/2 mb-4">
              <ValidationProvider name="New Password" rules="required|min:6|confirmed:confirmation" v-slot="{ errors }">
                <label for="password" class="label-control">New Password <span class="text-red-600">*</span></label>
                <input id="password" type="password" v-model="newPassword" class="form-control">
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.password" class="text-sm text-red-600"><span>{{ error.password[0] }}</span></div>
            </div>
            <div class="md:w-1/2 mb-4">
              <ValidationProvider name="New Password Confirmation" rules="required|min:6" vid="confirmation" v-slot="{ errors }">
                <label for="password_confirmation" class="label-control">New Password Confirmation <span class="text-red-600">*</span></label>
                <input id="password_confirmation" type="password" v-model="newPasswordConfirmation" class="form-control">
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
              </ValidationProvider>
              <div v-if="error.password_confirmation" class="text-sm text-red-600"><span>{{ error.password_confirmation[0] }}</span></div>
            </div>
          </div>        
          <div class="md:flex gap-4 mt-4 mb-2">
            <button :disabled="isLoading" type="submit" class="btn btn--primary" alt="Save" title="Save">
              <span v-if="isLoading" class="block mx-5">
                <Loading />
              </span>
              <SaveIcon v-else>
                <span class="ml-2 md:block hidden">Save</span>
              </SaveIcon>
            </button>
            <button :disabled="isLoading" type="button" @click="goBack()" class="btn btn--secondary" alt="Back" title="Back">
              <BackIcon>
                <span class="ml-2 md:block hidden">Back</span>
              </BackIcon>              
            </button>
          </div>                                                  
        </div>
      </form>      
    </ValidationObserver>
  </div>
</template>

<script>
import userServices from '@/services/user/userServices'
import SaveIcon from '../../icons/SaveIcon'
import BackIcon from '../../icons/BackIcon'
import Loading from '../../icons/Loading'

export default {
  name: 'ChangePassword',
  components: {
    SaveIcon,
    BackIcon,
    Loading
  },  
  data(){
    return {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
      record: [],
      error: [],
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async update(){
      try {
        this.isLoading = true

        const payload = {
          current_password: this.currentPassword,
          password: this.newPassword,
          password_confirmation: this.newPasswordConfirmation
        }
        const response = await userServices.updatePassword(payload)
        if (response.data.status === 'success') {
          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          /* RETURN TO LIST */
          this.$router.push({ name: 'dashboard' })
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.current_password || responseReturn.password || responseReturn.password_confirmation) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },
    goBack() {
      window.history.length > 1 ? this.$router.back() : this.$router.push('/dashboard')
    }      
  }
}
</script>