<template>
  <div class="bg-white shadow-lg rounded-lg">
    <div class="p-10">
      <div class="pb-2 mb-4 border-b border-gray-200">
        <h2 class="text-gray-800 text-2xl font-medium">Show Material</h2>
      </div>
      <div class="md:flex gap-6">
        <div class="md:flex-col w-full md:w-4/6">
          <div class="md:flex gap-6">
            <div class="md:w-1/2 mb-4">
              <label for="collection" class="label-control">Type <span class="text-red-600">*</span></label>
              <MultiSelect id="collection" v-model="collection" :options="collections" :value="collection" :searchable="true" track-by="id" label="code" :multiple="true" placeholder="Type to Search">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>
            </div>
            <div class="md:w-1/2 mb-4">
              <label for="supplier" class="label-control">Supplier <span class="text-red-600">*</span></label>
              <MultiSelect id="supplier" ref="supplier" v-model="supplier" :value="supplier" :options="suppliers" :searchable="true" track-by="id" label="name" placeholder="Type to Search">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>
            </div>
          </div>
          <div class="md:flex gap-6">
            <div class="w-full mb-4">
              <label for="reference" class="label-control">Reference <span class="text-red-600">*</span></label>
              <input id="reference" ref="reference" type="text" v-model="reference" maxlength="255" class="form-control" readonly>   
            </div>
          </div>
          <div class="md:flex gap-6">
            <div class="w-full mb-4">
              <label for="description" class="label-control">Description <span class="text-red-600">*</span></label>
              <textarea id="description" ref="description" v-model="description" class="form-control" disabled></textarea>            
            </div>
          </div>       
          <div class="md:flex gap-6">
            <div class="md:w-1/2 mb-4">
              <label for="color" class="label-control">Color</label>
              <input id="color" type="text" v-model="color" class="form-control" readonly>
            </div>
            <div class="md:w-1/2 mb-4">
              <label for="size" class="label-control">Size</label>
              <input id="size" type="text" v-model="size" class="form-control" readonly>
            </div>
          </div>
          <div class="md:flex gap-6">
            <div class="w-full md:w-1/2 mb-4">
              <label for="unit" class="label-control">Unit <span class="text-red-600">*</span></label>
              <MultiSelect id="unit" ref="unit" v-model="unit" :options="units" :value="unit" :searchable="true" placeholder="Type to Search">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>
            </div>
            <div class="flex md:w-1/2 gap-4 md:gap-6 mb-4">
              <div class="w-full md:w-1/2">
                <label for="buy_currency" class="label-control">Buy Currency <span class="text-red-600">*</span></label>
                <MultiSelect id="buy_currency" ref="buy_currency" v-model="buyCurrency" :value="buyCurrency" :options="currencies" :searchable="false" label="currency" :showLabels="false" placeholder="">
                  <template slot="caret">
                    <div>
                      <div class="multiselect__select">
                        <span>
                          <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                            <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </template>
                </MultiSelect>
              </div>
              <div class="w-full md:w-1/2">
                <label for="buy_rate" class="label-control">Buy Rate <span class="text-red-600">*</span></label>
                <input id="buy_rate" type="text" v-model="buyRate" class="form-control text-right" readonly>               
              </div>
            </div>
          </div>                  
          <div class="md:flex gap-6">
            <div class="md:flex w-full md:w-1/2 gap-6 mb-4">
              <div class="w-full md:w-1/2">
                <label for="buy_price" class="label-control">Buy Price <span class="text-red-600">*</span></label>
                <div class="relative flex-1">
                  <span fill="currentColor" class="absolute left-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">{{ buyCurrency.symbol }}</span>
                  <input id="buy_price" type="text" v-model="buyPrice" class="form-control text-right" readonly>
                </div>
              </div>
              <div class="w-full md:w-1/2">
                <label for="price" class="label-control">Convertion Price <span class="text-red-600">*</span></label>
                <div class="relative flex-1">
                  <span fill="currentColor" class="absolute left-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
                  <input id="price" type="text" v-model="price" class="form-control text-right" readonly>
                </div>
              </div>
            </div>
            <div class="md:flex w-full md:w-1/2 gap-6 mb-4">
              <div class="w-full md:w-1/2">
                <label for="minimum_stock" class="label-control">Minimum Stock <span class="text-red-600">*</span></label>
                <input id="minimum_stock" type="text" v-model="minimumStock" class="form-control" readonly>          
              </div>
              <div class="w-full md:w-1/2">
                <label for="status" class="label-control">Status <span class="text-red-600">*</span></label>
                <MultiSelect id="status" ref="status" v-model="status" :options="statusData" :value="status" track-by="id" label="name" :searchable="false" :showLabels="false">
                  <template slot="caret">
                    <div>
                      <div class="multiselect__select">
                        <span>
                          <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                            <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </template>
                </MultiSelect>
              </div>
            </div>
          </div>
          <div class="md:flex gap-6">
            <div class="md:flex w-full md:w-1/2 gap-6 mb-4">
              <div class="w-full md:w-1/2">
                <label for="stock" class="label-control">On Hand Stock</label>
                <input id="stock" type="text" v-model="stock" class="form-control" readonly>            
              </div>              
            </div>
            <div class="md:flex w-full md:w-1/2 gap-6 mb-4"></div>
          </div>
        </div>
        <div class="md:flex-col w-full md:w-2/6">
          <div class="w-full mb-4">
            <label for="image" class="label-control">Image</label>
            <div v-if="!image">
              <div class="md:h-64 border-2 border-dashed border-gray-400 flex items-center justify-center p-2 rounded-lg mb-2">
                <img class="m-auto" :src="fileBox" />
              </div>
            </div>
            <div v-else>
              <div class="md:h-auto border-2 border-dashed border-gray-400 flex items-center justify-center p-2 rounded-lg mb-2">
                <img class="m-auto" :src="image" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex gap-4 md:justify-between items-center mt-10 mb-2">
        <div class="flex items-center gap-4">
          <button :disabled="isLoading" type="button" @click="goBack" class="btn btn--secondary" alt="Back" title="Back">
            <BackIcon>
              <span class="ml-2 md:block hidden">Back</span>
            </BackIcon>
          </button>
        </div>
        <div class="flex items-center">
          <router-link :to="{ name: 'materialEdit', params: { id: $route.params.id } }"  class="btn btn--success" alt="Edit" v-tooltip="'Edit'">
            <Edit2Icon>
              <span class="ml-2 md:block hidden">Edit</span>
            </Edit2Icon>
          </router-link>
        </div>
      </div>                                                        
    </div>
  </div>
</template>

<script>
import format from '@/helpers/formatNumber'
import collectionServices from '@/services/collection/collectionServices'
import supplierServices from '@/services/supplier/supplierServices'
import unitServices from '@/services/unit/unitServices'
import rateServices from '@/services/rate/rateServices'
import materialServices from '@/services/material/materialServices'
import BackIcon from '../../icons/BackIcon'
import Edit2Icon from '../../icons/Edit2Icon'
import imageBox from '../../../assets/images/file-box.png'

export default {
  name: 'ShowMaterial',
  components: {
    BackIcon,
    Edit2Icon
  },  
  data(){
    return {
      collection: [],
      supplier: '',
      reference: '',
      description: '',
      color: '',
      size: '',
      unit: '',
      buyCurrency: '',
      buyRate: '',
      buyPrice: '',
      price: '',
      minimumStock: '',
      image: '',
      materialImage: '',
      fileBox: imageBox,      
      stock: 0,
      status: '',
      statusData: [
        { id: 1, name: 'Discontinue' },
        { id: 0, name: 'Available' }        
      ],      
      collections: [],
      suppliers: [],
      units: [],
      currencies: [],
      record: [],
      error: [],
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async fetchData(){
      try {
        const id = this.$route.params.id
        const response = await materialServices.fetchById(id)
        if (response.data.status === 'success') {
          this.record = response.data.data

          this.record.type.forEach(element => {
            this.collection.push({ 'id': element.id, 'name': element.name, 'code': element.code })
          })
          this.supplier = { 'id': this.record.supplier_id, 'name': this.record.supplier.supplier_name}
          this.reference = this.record.material_name
          this.color = this.record.color
          this.size = this.record.size
          this.unit = this.record.unit
          this.buyCurrency = { 'currency': this.record.buy_currency, 'symbol': this.record.currency_detail.symbol, 'rate': this.record.currency_detail.rate }
          this.buyRate = this.formatNumber(this.record.buy_rate)
          this.buyPrice = this.formatNumber(this.record.buy_price)
          this.price = this.formatNumber(this.record.current_price )
          this.minimumStock = this.formatNumber(this.record.minimum_stock)
          this.image = this.record.image
          this.status = this.record.is_discontinue === 1 ? { id: 1, name: 'Discontinue' } : { id: 0, name: 'Available' }
          this.stock = this.formatNumber(this.record.stock)
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)            
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchCollectionData() {
      try {
        const response = await collectionServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.collections = []
          records.forEach(element => {
            this.collections.push({
              'id': element.id,
              'name': element.collection_name,
              'code': element.collection_code
            })
          });          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchSupplierData() {
      try {
        const response = await supplierServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          records.forEach(element => {
            this.suppliers.push({
              'id': element.id,
              'name': element.supplier_name
            })
          });
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchUnitData() {
      try {
        const response = await unitServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          records.forEach(element => {
            this.units.push(element.unit)
          });
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    }, 
    async fetchCurrencyData() {
      try {
        const response = await rateServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          records.forEach(element => {
            this.currencies.push({
              'currency': element.currency,
              'symbol': element.symbol,
              'rate': element.rate
            })
          });
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    },
    goBack() {
      window.history.length > 1 ? this.$router.back() : this.$router.push('/materials')
    }       
  },
  created(){
    this.fetchCollectionData()
    this.fetchSupplierData()
    this.fetchUnitData()
    this.fetchCurrencyData()
    this.fetchData()
  }
}
</script>