<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="bg-white shadow-lg rounded-lg" @submit.prevent="handleSubmit(create)">
        <div class="p-10">
          <div class="pb-2 mb-4 border-b border-gray-200">
            <h2 class="text-gray-800 text-2xl font-medium">Create Season</h2>
          </div>
          <div class="md:flex gap-6">
            <div class="w-full mb-4">
              <ValidationProvider name="Season Name" rules="required" v-slot="{ errors }">
                <label for="season_name" class="label-control">Season Name <span class="text-red-600">*</span></label>
                <input id="season_name" ref="season_name" type="text" v-model="seasonName" maxlength="255" class="form-control" autofocus>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.season_name" class="text-sm text-red-600"><span>{{ error.season_name[0] }}</span></div>              
            </div>
          </div>
          <div class="md:flex gap-6">
            <div class="w-full mb-4">
              <ValidationProvider name="Description" rules="" v-slot="{ errors }">
                <label for="description" class="label-control">Description</label>
                <textarea id="description" v-model="description" class="form-control" rows="5"></textarea>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.description" class="text-sm text-red-600"><span>{{ error.description[0] }}</span></div>                   
            </div>
          </div>
          <div class="md:flex gap-6">
            <div class="md:w-1/2 mb-4">
              <ValidationProvider name="Status" rules="required" v-slot="{ errors }">
                <label for="status" class="label-control">Status <span class="text-red-600">*</span></label>
                <MultiSelect id="status" ref="status" v-model="status" :options="statusData" :value="status" track-by="id" label="name" :searchable="false" :showLabels="false">
                  <template slot="caret">
                    <div>
                      <div class="multiselect__select">
                        <span>
                          <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                            <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </template>
                </MultiSelect>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.status" class="text-sm text-red-600"><span>{{ error.status[0] }}</span></div>
            </div>
            <div class="md:w-1/2 mb-4"></div>
          </div>           
          <div class="md:flex gap-4 mt-4 mb-2">
            <button :disabled="isLoading" type="submit" class="btn btn--primary" alt="Save" title="Save">
              <span v-if="isLoading" class="block mx-5">
                <Loading />
              </span>
              <SaveIcon v-else>
                <span class="ml-2 md:block hidden">Save</span>
              </SaveIcon>
            </button>
            <button :disabled="isLoading" type="button" @click="goBack()" class="btn btn--secondary" alt="Back" title="Back">
              <BackIcon>
                <span class="ml-2 md:block hidden">Back</span>
              </BackIcon>              
            </button>
          </div>                                                  
        </div>
      </form>      
    </ValidationObserver>
  </div>
</template>

<script>
import seasonServices from '@/services/season/seasonServices'
import SaveIcon from '../../icons/SaveIcon'
import BackIcon from '../../icons/BackIcon'
import Loading from '../../icons/Loading'

export default {
  name: 'CreateSeason',
  components: {
    SaveIcon,
    BackIcon,
    Loading
  },
  data(){
    return {
      seasonName: '',
      description: '',
      status: '',
      statusData: [
        { id: 1, name: 'Active' },
        { id: 0, name: 'Inactive' }
      ],
      error: [],
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async create(){
      try {
        this.isLoading = true       
        const payload = {
          season_name: this.seasonName,
          description: this.description,
          status: this.status.id
        }
        const response = await seasonServices.create(payload)
        if (response.data.status === 'success') {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false
                    
          /* CLEAR INPUT FORM */
          this.clearForm()

          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          /* SET FOCUS ON SEASON NAME INPUT */
          this.$refs.season_name.focus()
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.season_name || responseReturn.status) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },
    clearForm(){
      this.seasonName = ''
      this.description = ''
      this.status = ''
    },
    goBack() {
      window.history.length > 1 ? this.$router.back() : this.$router.push('/seasons')
    }       
  }
}
</script>