import ListSupplier from '../components/feature/supplier/List'
import CreateSupplier from '../components/feature/supplier/Create'
import EditSupplier from '../components/feature/supplier/Edit'
import ShowSupplier from '../components/feature/supplier/Show'

const routes = [
  {
    path: '/suppliers',
    name: 'supplier',
    component: ListSupplier,
    meta: {
      title: 'Suppliers',
      requiresAuth: true
    }    
  },
  {
    path: '/suppliers/create',
    name: 'supplierCreate',
    component: CreateSupplier,
    meta: {
      title: 'Create Supplier',
      requiresAuth: true
    }    
  },
  {
    path: '/suppliers/:id/edit',
    name: 'supplierEdit',
    component: EditSupplier,
    meta: {
      title: 'Edit Supplier',
      requiresAuth: true
    }    
  },
  {
    path: '/suppliers/:id',
    name: 'supplierShow',
    component: ShowSupplier,
    meta: {
      title: 'Show Supplier',
      requiresAuth: true
    }    
  }  
]

export default routes