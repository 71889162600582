import ListTransferStock from '../components/feature/transfer/List'
import CreateTransferStock from '../components/feature/transfer/Create'
import EditTransferStock from '../components/feature/transfer/Edit'
import ShowTransferStock from '../components/feature/transfer/Show'

const routes = [
  {
    path: '/transfer-stock',
    name: 'transfer',
    component: ListTransferStock,
    meta: {
      title: 'Transfer Stock',
      requiresAuth: true
    }    
  },
  {
    path: '/transfer-stock/create',
    name: 'transferCreate',
    component: CreateTransferStock,
    meta: {
      title: 'Create Transfer Stock',
      requiresAuth: true
    }    
  },
  {
    path: '/transfer-stock/:id/edit',
    name: 'transferEdit',
    component: EditTransferStock,
    meta: {
      title: 'Edit Transfer Stock',
      requiresAuth: true
    }    
  },
  {
    path: '/transfer-stock/:id',
    name: 'transferShow',
    component: ShowTransferStock,
    meta: {
      title: 'Show Transfer Stock',
      requiresAuth: true
    }    
  }  
]

export default routes