const state = {
  newAdjustmentID: null,
  indexAdjustmentRoute: null
};

const getters = {
  newAdjustmentID: state => state.newAdjustmentID,
  indexAdjustmentRoute: state => state.indexAdjustmentRoute
};

const actions = {
  setNewAdjustmentID({commit}, newAdjustmentID){
    commit('setNewAdjustmentID', newAdjustmentID)
  },
  setIndexAdjustmentRoute({commit}, fullPath){
    commit('setIndexAdjustmentRoute', fullPath)
  }
};

const mutations = {
  setNewAdjustmentID(state, newAdjustmentID){
    state.newAdjustmentID = newAdjustmentID
  },
  setIndexAdjustmentRoute(state, fullPath){
    state.indexAdjustmentRoute = fullPath
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};