<template>
  <div class="bg-white shadow-lg rounded-lg">
    <div class="p-10">
      <div class="pb-2 mb-4 border-b border-gray-200">
        <h2 class="text-gray-800 text-2xl font-medium">Show Rate</h2>
      </div>
      <div class="md:flex gap-6">
        <div class="md:w-1/2 mb-4">
          <label for="currency" class="label-control">Currency</label>
          <input id="currency" type="text" v-model="currency" class="form-control">
        </div>
        <div class="md:w-1/2 mb-4">
          <label for="symbol" class="label-control">Symbol</label>
          <input id="symbol" type="text" v-model="symbol" class="form-control">
        </div>
      </div>
      <div class="md:flex gap-6">
        <div class="md:w-1/2 mb-4">
          <label for="rate" class="label-control">Rate</label>
          <input id="rate" type="text" v-model="rate" class="form-control">
        </div>
        <div class="md:w-1/2 mb-4"></div>
      </div>       
      <div class="md:flex gap-4 mt-4 mb-2">
        <button :disabled="isLoading" type="button" @click="goBack()" class="btn btn--secondary" alt="Back" title="Back">
          <BackIcon>
            <span class="ml-2 md:block hidden">Back</span>
          </BackIcon>              
        </button>
      </div>
    </div>    
  </div>
</template>

<script>
import format from '@/helpers/formatNumber'
import rateServices from '@/services/rate/rateServices'
import BackIcon from '../../icons/BackIcon'

export default {
  name: 'ShowRate',
  components: {
    BackIcon
  },
  data(){
    return {
      currency: '',
      symbol: '',
      rate: '',
      record: [],
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async fetchData(){
      try {
        const id = this.$route.params.id
        const response = await rateServices.fetchById(id)
        if (response.data.status === 'success') {
          this.record = response.data.data

          this.currency = this.record.currency
          this.symbol = this.record.symbol
          this.rate = this.formatNumber(this.record.rate)
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)            
        }
      } catch (error) {
        console.log(error)
      }
    },
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    },
    goBack() {
      window.history.length > 1 ? this.$router.back() : this.$router.push('/rates')
    }    
  },
  created(){
    this.fetchData()
  }
}
</script>