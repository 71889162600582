<template>
  <div>
    <button type="button" class="btn btn--success ml-2" @click="toggleModal">
      <DownloadIcon>
        <span class="ml-2 md:block hidden">Export</span>
      </DownloadIcon>
    </button>
    <!-- Import Modal Dialog -->
    <modal :show="showModal" @close="showModal = false" addClass="modal-2xl" modalOrientation="pt-16 md:pt-10">
      <template v-slot:header><h3>{{ modalTitle }}</h3></template>
      <template v-slot:body>
        <div class="w-full md:flex gap-2 mb-2">
          <div class="w-full md:w-2/12 mb-2 md:mb-0">
            <ValidationProvider name="Type" rules="required" v-slot="{ errors }">
              <MultiSelect id="type" ref="type" v-model="type" :options="types" :value="type" :searchable="true" @input="clearProduct" :showLabels="false" placeholder="Select Export Type">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>
              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
            </ValidationProvider>
            <div v-if="error.type" class="text-sm text-red-600"><span>{{ error.type[0] }}</span></div>              
          </div>
          <div class="w-full md:w-3/12 mb-2 md:mb-0">
            <ValidationProvider name="Season Creation" rules="" v-slot="{ errors }">
              <MultiSelect id="season" ref="season" v-model="season" :options="seasons" :value="season" :searchable="true" @input="clearProduct()" :showLabels="false" placeholder="Select a Season">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>
              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
            </ValidationProvider>
            <div v-if="error.season" class="text-sm text-red-600"><span>{{ error.season[0] }}</span></div>              
          </div>            
          <div class="w-full md:w-2/12 mb-2 md:mb-0">
            <ValidationProvider name="Collection" rules="" v-slot="{ errors }">
              <MultiSelect id="collection" ref="collection" v-model="collection" :options="collections" :value="collection" :searchable="true" @input="fetchReferenceData(), clearProduct()" track-by="id" label="name" :showLabels="false" placeholder="Select a Collection">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>
              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
            </ValidationProvider>
            <div v-if="error.collection" class="text-sm text-red-600"><span>{{ error.collection[0] }}</span></div>              
          </div>
          <div class="w-full md:w-2/12 mb-2 md:mb-0">
            <ValidationProvider name="Reference" rules="" v-slot="{ errors }">
              <MultiSelect id="reference" ref="reference" v-model="reference" :options="references" :value="reference" :searchable="true" @input="clearProduct()" :showLabels="false" placeholder="Select a Reference">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>
              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
            </ValidationProvider>
            <div v-if="error.reference" class="text-sm text-red-600"><span>{{ error.reference[0] }}</span></div>              
          </div>
          <div class="w-full md:w-2/12 mb-2 md:mb-0">
            <ValidationProvider name="Image" rules="" v-slot="{ errors }">
              <MultiSelect id="image" ref="image" v-model="image" :options="images" :value="image" :searchable="true" @input="clearProduct()" :showLabels="false" track-by="name" label="name" :allowEmpty="true" placeholder="Image Option">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>
              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
            </ValidationProvider>
            <div v-if="error.image" class="text-sm text-red-600"><span>{{ error.image[0] }}</span></div>              
          </div>          
          <div class="w-full md:w-2/12 mb-2 md:mb-0">
            <input id="search" ref="search" type="text" v-model="search" placeholder="Search" maxlength="255" class="form-control">
          </div>          
          <div class="flex md:w-2/12 gap-2 mb-2 md:mb-0">
            <button :disabled="isLoading" type="button" @click="fetchData()" class="btn btn--primary">
              Preview
            </button>
            <button :disabled="isLoading" type="button" @click="downloadFile()" class="btn btn--success">
              Download
            </button>
          </div>
        </div>
        <div class="mb-4 bg-white rounded-sm overflow-auto">
          <template v-if="type === 'Technical Sheet'">
            <template v-if="isLoading">
              <div class="w-full text-center">
                <p>Loading...</p>
              </div>
            </template>
            <template v-else>
              <div v-if="products.length > 0" class="w-full">
                <div class="w-full md:flex">
                  <div class="w-full md:w-4/6">
                    <div class="w-full mb-6">
                      <h3 class="text-center text-2xl font-medium">FICHE PRODUIT - {{ reference }}</h3>
                    </div>
                    <div class="w-full flex">
                      <div class="w-1/4">
                        <div class="w-full block mb-2">
                          <span class="text-sm">REFERENCE :</span>
                        </div>
                        <div class="w-full block mb-4">
                          <span class="text-sm">DESCRIPTION :</span>
                        </div>                 
                      </div>
                      <div class="w-3/4">
                        <div class="w-full block mb-2">
                          <span class="text-sm">{{ reference }}</span>
                        </div>
                        <div class="w-full block mb-4">
                          <span class="text-sm">{{ products[0]['description'] }}</span>
                        </div>                  
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:flex md:w-2/6">
                    <div class="w-full md:w-2/3">
                      <img class="max-h-48" :src="products[0]['image']">
                    </div>
                    <div class="w-full md:w-1/3">
                      <span class="block text-xs">Taux de change :</span>
                      <span class="block text-xs">1€ = Rp.{{ formatNumber(toFixed(products[0]['change_rate']), 0) }}</span>
                    </div>
                  </div>
                </div>
                <div v-for="product in products" :key="product.id" class="w-full md:flex gap-2 mb-6">
                  <div class="w-full md:w-4/6">
                    <div class="w-full flex">
                      <div class="w-1/4">
                        <div class="w-full block">
                          <span class="text-sm">{{ product.color }}</span>
                        </div>                
                      </div>
                      <div class="w-3/4">
                        <table>
                          <thead class="bg-gray-100">
                            <tr>
                              <th scope="col" class="py-1 px-1 text-left text-gray-700 border border-gray-200">N°</th>
                              <th scope="col" class="py-1 px-1 text-left text-gray-700 border border-gray-200">SUPPLIER</th>
                              <th scope="col" class="py-1 px-1 text-left text-gray-700 border border-gray-200">REF</th>
                              <th scope="col" class="py-1 px-1 text-left text-gray-700 border border-gray-200">SIZE</th>
                              <th scope="col" class="py-1 px-1 text-left text-gray-700 border border-gray-200">COLOR</th>
                              <th scope="col" class="py-1 px-1 text-left text-gray-700 border border-gray-200">PRICE</th>
                              <th scope="col" class="py-1 px-1 text-center text-gray-700 border border-gray-200">QTY 1</th>
                              <th scope="col" class="py-1 px-1 text-center text-gray-700 border border-gray-200">QTY 2</th>
                              <th scope="col" class="py-1 px-1 text-left text-gray-700 border border-gray-200">UNIT</th>
                              <th scope="col" class="py-1 px-1 text-left text-gray-700 border border-gray-200">TOTAL</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="product.recipes.length === 0"><td class="py-3 px-1 text-xs text-left border border-gray-200" colspan="10"></td></tr>
                            <tr v-for="(recipe, index) in product.recipes" :key="recipe.id">
                              <td class="py-1 px-1 text-xs text-right border border-gray-200">{{ index+1 }}</td>
                              <td class="py-1 px-1 text-xs text-left border border-gray-200">{{ recipe.material ? recipe.material.supplier.supplier_name : null }}</td>
                              <td class="py-1 px-1 text-xs text-left border border-gray-200">{{ recipe.material_name }}</td>
                              <td class="py-1 px-1 text-xs text-left border border-gray-200">{{ recipe.size }}</td>
                              <td class="py-1 px-1 text-xs text-left border border-gray-200">{{ recipe.color }}</td>
                              <td class="py-1 px-1 text-xs text-right border border-gray-200">{{ recipe.price }} <span v-if="recipe.price"> €</span></td>
                              <td class="py-1 px-1 text-xs text-right border border-gray-200">{{ recipe.qty1 }}</td>
                              <td class="py-1 px-1 text-xs text-right border border-gray-200">{{ recipe.qty2 }}</td>
                              <td class="py-1 px-1 text-xs text-center border border-gray-200">{{ recipe.unit }}</td>
                              <td class="py-1 px-1 text-xs text-right border border-gray-200">{{ recipe.total }} <span v-if="recipe.total"> €</span></td>
                            </tr>
                            <tr>
                              <td class="py-1 px-1 text-xs text-left border border-r-0 border-gray-200 font-medium" colspan="8">TOTAL</td>
                              <td class="py-1 px-1 text-xs text-right border border-l-0 border-gray-200 font-medium" colspan="2">{{ toFixed(totalRecipe[product.id], 2) }} <span v-if="totalRecipe[product.id]"> €</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:w-2/6">
                    <div class="w-full mb-2">
                      <table>
                        <tbody>
                          <tr>
                            <td scope="col" class="py-1 px-1 text-xs border border-r-0 border-gray-200 text-left">Façon</td>
                            <td scope="col" class="py-1 px-1 text-xs border border-l-0 border-gray-200 text-right">{{ formatNumber(toFixed(product.manufacturing_cost1, 0)) }} {{ product.mfg_cost1_currency_detail ? product.mfg_cost1_currency_detail.symbol : '' }}</td>
                            <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right">{{ formatNumber(product.euro_manufacturing_cost1) }} €</td>
                          </tr>
                          <tr>
                            <td scope="col" class="py-1 px-1 text-xs border border-r-0 border-gray-200 text-left">Façon Fabricant 2</td>
                            <td scope="col" class="py-1 px-1 text-xs border border-l-0 border-gray-200 text-right">{{ formatNumber(toFixed(product.manufacturing_cost2, 0)) }} {{ product.mfg_cost2_currency_detail ? product.mfg_cost2_currency_detail.symbol : '' }}</td>
                            <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right">{{ formatNumber(product.euro_manufacturing_cost2) }} €</td>
                          </tr>
                          <tr>
                            <td scope="col" class="py-1 px-1 text-xs border border-r-0 border-gray-200 text-left">Taxe/Douanes</td>
                            <td scope="col" class="py-1 px-1 text-xs border border-l-0 border-gray-200 text-right">{{ formatNumber(toFixed(product.tax_customs, 0)) }}%</td>
                            <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right">{{ formatNumber(toFixed(tax[product.id], 2)) }} €</td>
                          </tr>                      
                          <tr>
                            <td scope="col" class="py-1 px-1 text-xs border border-r-0 border-gray-200 text-left">Façon Fabricant 3</td>
                            <td scope="col" class="py-1 px-1 text-xs border border-l-0 border-gray-200 text-right">{{ formatNumber(toFixed(product.manufacturing_cost3, 0)) }} {{ product.mfg_cost3_currency_detail ? product.mfg_cost3_currency_detail.symbol : '' }}</td>
                            <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right">{{ formatNumber(product.euro_manufacturing_cost3) }} €</td>
                          </tr>
                          <tr>
                            <td scope="col" class="py-1 px-1 text-xs border border-r-0 border-gray-200 text-left">Forfait Transport / Emballage</td>
                            <td scope="col" class="py-1 px-1 text-xs border border-l-0 border-gray-200 text-right">{{ formatNumber(product.transport_packaging) }} €</td>
                            <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right">{{ formatNumber(product.transport_packaging) }} €</td>
                          </tr>
                          <tr>
                            <td scope="col" class="py-1 px-1 text-xs border border-r-0 border-gray-200 text-left font-medium">TOTAL</td>
                            <td scope="col" class="py-1 px-1 text-xs border border-l-0 border-gray-200 text-right"></td>
                            <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right font-medium">{{ formatNumber(toFixed(total[product.id], 2)) }} €</td>
                          </tr>
                          <tr>
                            <td scope="col" class="py-1 px-1 text-xs text-right" colspan="2">coef. Effectif</td>
                            <td scope="col" class="py-1 px-1 text-xs text-right"></td>
                          </tr>                      
                          <tr>
                            <td scope="col" class="py-1 px-1 text-xs border border-r-0 border-gray-200 text-left">Merge/Wholesale Price</td>
                            <td scope="col" class="py-1 px-1 text-xs border border-l-0 border-gray-200 text-center">{{ formatNumber(product.coef_wholesale_price) }}</td>
                            <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right">{{ formatNumber(toFixed(wholesalePrice[product.id], 2)) }} €</td>
                          </tr>
                          <tr>
                            <td scope="col" class="py-1 px-1 text-xs border border-r-0 border-gray-200 text-left">AGENT COMMISSION</td>
                            <td scope="col" class="py-1 px-1 text-xs border border-l-0 border-gray-200 text-center">{{ formatNumber(toFixed(product.agent_commision, 0)) }}%</td>
                            <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right">{{ formatNumber(toFixed(agentComission[product.id], 2)) }} €</td>
                          </tr>
                          <tr>
                            <td scope="col" class="py-1 px-1 text-xs border border-r-0 border-gray-200 text-left text-red-600">Total Wholesale Price in EURO</td>
                            <td scope="col" class="py-1 px-1 text-xs border border-l-0 border-gray-200 text-center"></td>
                            <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right text-red-600">{{ formatNumber(product.wholesale_price) }} €</td>
                            <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right text-red-600">{{ formatNumber(product.approved_wholesale_price) }} €</td>
                          </tr>
                          <tr>
                            <td scope="col" class="py-1 px-1 text-xs border border-r-0 border-gray-200 text-left text-blue-600">Retail Price in EURO</td>
                            <td scope="col" class="py-1 px-1 text-xs border border-l-0 border-gray-200 text-center text-blue-600">{{ formatNumber(product.coef_retail_price) }}</td>
                            <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right text-blue-600">{{ formatNumber(product.retail_price) }} €</td>
                            <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right text-blue-600">{{ formatNumber(product.approved_retail_price) }} €</td>
                          </tr>
                          <tr>
                            <td scope="col" class="py-1 px-1 text-xs border border-r-0 border-gray-200 text-left">Total Wholesale Price in USD</td>
                            <td scope="col" class="py-1 px-1 text-xs border border-l-0 border-gray-200 text-center">{{ formatNumber(product.coef_wholesale_usd) }}</td>
                            <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right">{{ formatNumber(product.wholesale_price_usd) }} $</td>
                            <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right">{{ formatNumber(product.approved_wholesale_price_usd) }} $</td>
                          </tr>
                          <tr>
                            <td scope="col" class="py-1 px-1 text-xs border border-r-0 border-gray-200 text-left">Retail Price in USD</td>
                            <td scope="col" class="py-1 px-1 text-xs border border-l-0 border-gray-200 text-center">{{ formatNumber(product.coef_retail_usd) }}</td>
                            <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right">{{ formatNumber(product.retail_price_usd) }} $</td>
                            <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right">{{ formatNumber(product.approved_retail_price_usd) }} $</td>
                          </tr>                                                                                                                                                                               
                        </tbody>
                      </table>
                    </div>
                  </div>              
                </div>
              </div>
            </template>
          </template>
          <template v-if="type === 'Product List'" class="overflow-y-auto">
            <table class="min-w-max w-full table-auto">
              <thead>
                <tr class="bg-purple-200 text-purple-600 uppercase text-sm leading-normal">
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-left" rowspan="2">Season Creation</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-left" rowspan="2">Collection</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-left" rowspan="2">Barcode</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-left" rowspan="2">Ref</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-left" rowspan="2">Color</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-left" rowspan="2">Size</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-left" rowspan="2">Description</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-left" rowspan="2">Weight</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-left" rowspan="2">Height</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-left" rowspan="2">Width</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-left" rowspan="2">Length</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-left" rowspan="2">Carats</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-left" rowspan="2">Annotation</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Unit</th>
                  <th scope="col" class="px-2 py-2 border-r border-b border-purple-300 border-opacity-50 text-center" colspan="2">EURO PRICE</th>
                  <th scope="col" class="px-2 py-2 border-r border-b border-purple-300 border-opacity-50 text-center" colspan="2">USD PRICE</th>
                </tr>
                <tr class="bg-purple-200 text-purple-600 uppercase text-xs leading-normal">
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-center">Wholesale</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-center">Retail</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-center">Wholesale</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-center">Retail</th>                  
                </tr>                                                          
              </thead>
              <tbody class="text-gray-600 text-xs font-light">
                <template v-if="isLoading">
                  <tr class="border-b border-gray-200 hover:bg-gray-100"><td class="py-3 px-6 text-sm text-center" colspan="18">Loading...</td></tr>
                </template>
                <template v-else>
                  <tr v-if="products.length === 0" class="border-b border-gray-200 hover:bg-gray-100"><td class="py-3 px-6 text-sm text-center" colspan="18">No data to display</td></tr>
                  <tr v-for="(product, index) in products" :key="product.id" class="border-b border-gray-200 hover:bg-gray-100" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">
                    <td class="px-2 py-2 text-left">{{ product.season_creation }}</td>
                    <td class="px-2 py-2 text-left">{{ product.collection }}</td>
                    <td class="px-2 py-2 text-left">{{ product.barcode }}</td>
                    <td class="px-2 py-2 text-left">{{ product.reference }}</td>
                    <td class="px-2 py-2 text-left">{{ product.color }}</td>
                    <td class="px-2 py-2 text-left">{{ product.size }}</td>
                    <td class="px-2 py-2 text-left">{{ product.description }}</td>
                    <td class="px-2 py-2 text-left">{{ product.weight }}</td>
                    <td class="px-2 py-2 text-left">{{ product.high }}</td>
                    <td class="px-2 py-2 text-left">{{ product.width }}</td>
                    <td class="px-2 py-2 text-left">{{ product.long }}</td>
                    <td class="px-2 py-2 text-left">{{ product.carats }}</td>
                    <td class="px-2 py-2 text-left">{{ product.annotation }}</td>
                    <td class="px-2 py-2 text-left">{{ product.unit }}</td>
                    <td class="px-2 py-2 text-right">{{ formatNumber(toFixed(product.approved_wholesale_price, 0)) + ' €' }}</td>
                    <td class="px-2 py-2 text-right">{{ formatNumber(toFixed(product.approved_retail_price, 0)) + ' €' }}</td>
                    <td class="px-2 py-2 text-right">{{ formatNumber(toFixed(product.approved_wholesale_price_usd, 0)) + ' $' }}</td>
                    <td class="px-2 py-2 text-right">{{ formatNumber(toFixed(product.approved_retail_price_usd, 0)) + ' $' }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </template>
          <template v-if="type === 'Product Details'">
            <template v-if="isLoading">
              <div class="w-full text-center">
                <p>Loading...</p>
              </div>
              <tr class="border-b border-gray-200 hover:bg-gray-100"><td class="py-3 px-6 text-sm text-center" colspan="10">Loading...</td></tr>
            </template>
            <template v-else>
              <div v-if="products.length > 0" class="w-full">
                <div v-for="product in products" :key="product.id">
                  <div class="w-full md:flex">
                    <div class="w-full md:w-4/6">
                      <div class="w-full mb-4">
                        <h3 class="text-center text-xl font-medium uppercase">Product Sheet - {{ product.reference }} {{ product.color }}</h3>
                      </div>
                      <div class="w-full flex">
                        <div class="w-1/4">
                          <div class="w-full">
                            <span class="text-xs">SEASON</span>
                          </div>
                          <div class="w-full">
                            <span class="text-xs">COLLECTION</span>
                          </div>
                          <div class="w-full">
                            <span class="text-xs">BARCODE</span>
                          </div>
                          <div class="w-full">
                            <span class="text-xs">REFERENCE</span>
                          </div>
                          <div class="w-full">
                            <span class="text-xs">COLOR</span>
                          </div>
                          <div class="w-full">
                            <span class="text-xs">SIZE</span>
                          </div>                                                                                                                        
                          <div class="w-full">
                            <span class="text-xs">DESCRIPTION</span>
                          </div>
                          <div class="w-full">
                            <span class="text-xs">WEIGHT</span>
                          </div> 
                          <div class="w-full">
                            <span class="text-xs">HEIGHT</span>
                          </div> 
                          <div class="w-full">
                            <span class="text-xs">WIDTH</span>
                          </div> 
                          <div class="w-full">
                            <span class="text-xs">LENGTH</span>
                          </div> 
                          <div class="w-full">
                            <span class="text-xs">CARATS</span>
                          </div> 
                          <div class="w-full">
                            <span class="text-xs">ANNOTATION</span>
                          </div>
                          <div class="w-full mb-4">
                            <span class="text-xs">UNIT</span>
                          </div>                                                                                                                                                                                        
                        </div>
                        <div class="w-3/4">
                          <div class="w-full">
                            <span class="text-xs">: {{ product.season_creation }}</span>
                          </div>
                          <div class="w-full">
                            <span class="text-xs">: {{ product.collection_name }}</span>
                          </div>
                          <div class="w-full">
                            <span class="text-xs">: {{ product.barcode }}</span>
                          </div>
                          <div class="w-full">
                            <span class="text-xs">: {{ product.reference }}</span>
                          </div>
                          <div class="w-full">
                            <span class="text-xs">: {{ product.color }}</span>
                          </div>
                          <div class="w-full">
                            <span class="text-xs">: {{ product.size }}</span>
                          </div>
                          <div class="w-full">
                            <span class="text-xs">: {{ product.description }}</span>
                          </div>
                          <div class="w-full">
                            <span class="text-xs">: {{ product.weight }}</span>
                          </div>
                          <div class="w-full">
                            <span class="text-xs">: {{ product.high }}</span>
                          </div>
                          <div class="w-full">
                            <span class="text-xs">: {{ product.width }}</span>
                          </div>
                          <div class="w-full">
                            <span class="text-xs">: {{ product.long }}</span>
                          </div>
                          <div class="w-full">
                            <span class="text-xs">: {{ product.carats }}</span>
                          </div>
                          <div class="w-full">
                            <span class="text-xs">: {{ product.annotation }}</span>
                          </div>
                          <div class="w-full mb-4">
                            <span class="text-xs">: {{ product.unit }}</span>
                          </div>                                                                                                                                                
                        </div>
                      </div>
                    </div>
                    <div class="w-full md:flex md:w-2/6">
                      <div class="w-full md:w-2/3">
                        <img class="max-h-48" :src="product.image">
                      </div>
                      <div class="w-full md:w-1/3">
                        <span class="block text-xs">Taux de change :</span>
                        <span class="block text-xs">1€ = Rp.{{ formatNumber(toFixed(product.change_rate), 0) }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:flex gap-2 mb-6">
                    <div class="w-full md:w-4/6">
                      <table>
                        <thead class="bg-gray-100">
                          <tr>
                            <th scope="col" class="py-1 px-1 text-left text-gray-700 border border-gray-200">N°</th>
                            <th scope="col" class="py-1 px-1 text-left text-gray-700 border border-gray-200">SUPPLIER</th>
                            <th scope="col" class="py-1 px-1 text-left text-gray-700 border border-gray-200">REF</th>
                            <th scope="col" class="py-1 px-1 text-left text-gray-700 border border-gray-200">SIZE</th>
                            <th scope="col" class="py-1 px-1 text-left text-gray-700 border border-gray-200">COLOR</th>
                            <th scope="col" class="py-1 px-1 text-left text-gray-700 border border-gray-200">PRICE</th>
                            <th scope="col" class="py-1 px-1 text-center text-gray-700 border border-gray-200">QTY 1</th>
                            <th scope="col" class="py-1 px-1 text-center text-gray-700 border border-gray-200">QTY 2</th>
                            <th scope="col" class="py-1 px-1 text-left text-gray-700 border border-gray-200">UNIT</th>
                            <th scope="col" class="py-1 px-1 text-left text-gray-700 border border-gray-200">TOTAL</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="product.recipes.length === 0"><td class="py-3 px-1 text-xs text-left border border-gray-200" colspan="10"></td></tr>
                          <tr v-for="(recipe, index) in product.recipes" :key="recipe.id">
                            <td class="py-1 px-1 text-xs text-right border border-gray-200">{{ index+1 }}</td>
                            <td class="py-1 px-1 text-xs text-left border border-gray-200">{{ recipe.material ? recipe.material.supplier.supplier_name : null }}</td>
                            <td class="py-1 px-1 text-xs text-left border border-gray-200">{{ recipe.material_name }}</td>
                            <td class="py-1 px-1 text-xs text-left border border-gray-200">{{ recipe.size }}</td>
                            <td class="py-1 px-1 text-xs text-left border border-gray-200">{{ recipe.color }}</td>
                            <td class="py-1 px-1 text-xs text-right border border-gray-200">{{ recipe.price }} <span v-if="recipe.price"> €</span></td>
                            <td class="py-1 px-1 text-xs text-right border border-gray-200">{{ recipe.qty1 }}</td>
                            <td class="py-1 px-1 text-xs text-right border border-gray-200">{{ recipe.qty2 }}</td>
                            <td class="py-1 px-1 text-xs text-center border border-gray-200">{{ recipe.unit }}</td>
                            <td class="py-1 px-1 text-xs text-right border border-gray-200">{{ recipe.total }} <span v-if="recipe.total"> €</span></td>
                          </tr>
                          <tr>
                            <td class="py-1 px-1 text-xs text-left border border-r-0 border-gray-200 font-medium" colspan="8">TOTAL</td>
                            <td class="py-1 px-1 text-xs text-right border border-l-0 border-gray-200 font-medium" colspan="2">{{ toFixed(totalRecipe[product.id], 2) }} <span v-if="totalRecipe[product.id]"> €</span></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="w-full md:w-2/6">
                      <div class="w-full mb-2">
                        <table>
                          <tbody>
                            <tr>
                              <td scope="col" class="py-1 px-1 text-xs border border-r-0 border-gray-200 text-left">Façon</td>
                              <td scope="col" class="py-1 px-1 text-xs border border-l-0 border-gray-200 text-right">{{ formatNumber(toFixed(product.manufacturing_cost1, 0)) }} {{ product.mfg_cost1_currency_detail ? product.mfg_cost1_currency_detail.symbol : '' }}</td>
                              <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right">{{ formatNumber(product.euro_manufacturing_cost1) }} €</td>
                            </tr>
                            <tr>
                              <td scope="col" class="py-1 px-1 text-xs border border-r-0 border-gray-200 text-left">Façon Fabricant 2</td>
                              <td scope="col" class="py-1 px-1 text-xs border border-l-0 border-gray-200 text-right">{{ formatNumber(toFixed(product.manufacturing_cost2, 0)) }} {{ product.mfg_cost2_currency_detail ? product.mfg_cost2_currency_detail.symbol : '' }}</td>
                              <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right">{{ formatNumber(product.euro_manufacturing_cost2) }} €</td>
                            </tr>
                            <tr>
                              <td scope="col" class="py-1 px-1 text-xs border border-r-0 border-gray-200 text-left">Taxe/Douanes</td>
                              <td scope="col" class="py-1 px-1 text-xs border border-l-0 border-gray-200 text-right">{{ formatNumber(toFixed(product.tax_customs, 0)) }}%</td>
                              <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right">{{ formatNumber(toFixed(tax[product.id], 2)) }} €</td>
                            </tr>                      
                            <tr>
                              <td scope="col" class="py-1 px-1 text-xs border border-r-0 border-gray-200 text-left">Façon Fabricant 3</td>
                              <td scope="col" class="py-1 px-1 text-xs border border-l-0 border-gray-200 text-right">{{ formatNumber(toFixed(product.manufacturing_cost3, 0)) }} {{ product.mfg_cost3_currency_detail ? product.mfg_cost3_currency_detail.symbol : '' }}</td>
                              <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right">{{ formatNumber(product.euro_manufacturing_cost3) }} €</td>
                            </tr>
                            <tr>
                              <td scope="col" class="py-1 px-1 text-xs border border-r-0 border-gray-200 text-left">Forfait Transport / Emballage</td>
                              <td scope="col" class="py-1 px-1 text-xs border border-l-0 border-gray-200 text-right">{{ formatNumber(product.transport_packaging) }} €</td>
                              <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right">{{ formatNumber(product.transport_packaging) }} €</td>
                            </tr>
                            <tr>
                              <td scope="col" class="py-1 px-1 text-xs border border-r-0 border-gray-200 text-left font-medium">TOTAL</td>
                              <td scope="col" class="py-1 px-1 text-xs border border-l-0 border-gray-200 text-right"></td>
                              <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right font-medium">{{ formatNumber(toFixed(total[product.id], 2)) }} €</td>
                            </tr>
                            <tr>
                              <td scope="col" class="py-1 px-1 text-xs text-right" colspan="2">coef. Effectif</td>
                              <td scope="col" class="py-1 px-1 text-xs text-right"></td>
                            </tr>                      
                            <tr>
                              <td scope="col" class="py-1 px-1 text-xs border border-r-0 border-gray-200 text-left">Merge/Wholesale Price</td>
                              <td scope="col" class="py-1 px-1 text-xs border border-l-0 border-gray-200 text-center">{{ formatNumber(product.coef_wholesale_price) }}</td>
                              <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right">{{ formatNumber(toFixed(wholesalePrice[product.id], 2)) }} €</td>
                            </tr>
                            <tr>
                              <td scope="col" class="py-1 px-1 text-xs border border-r-0 border-gray-200 text-left">AGENT COMMISSION</td>
                              <td scope="col" class="py-1 px-1 text-xs border border-l-0 border-gray-200 text-center">{{ formatNumber(toFixed(product.agent_commision, 0)) }}%</td>
                              <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right">{{ formatNumber(toFixed(agentComission[product.id], 2)) }} €</td>
                            </tr>
                            <tr>
                              <td scope="col" class="py-1 px-1 text-xs border border-r-0 border-gray-200 text-left text-red-600">Total Wholesale Price in EURO</td>
                              <td scope="col" class="py-1 px-1 text-xs border border-l-0 border-gray-200 text-center"></td>
                              <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right text-red-600">{{ formatNumber(product.wholesale_price) }} €</td>
                              <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right text-red-600">{{ formatNumber(product.approved_wholesale_price) }} €</td>
                            </tr>
                            <tr>
                              <td scope="col" class="py-1 px-1 text-xs border border-r-0 border-gray-200 text-left text-blue-600">Retail Price in EURO</td>
                              <td scope="col" class="py-1 px-1 text-xs border border-l-0 border-gray-200 text-center text-blue-600">{{ formatNumber(product.coef_retail_price) }}</td>
                              <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right text-blue-600">{{ formatNumber(product.retail_price) }} €</td>
                              <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right text-blue-600">{{ formatNumber(product.approved_retail_price) }} €</td>
                            </tr>
                            <tr>
                              <td scope="col" class="py-1 px-1 text-xs border border-r-0 border-gray-200 text-left">Total Wholesale Price in USD</td>
                              <td scope="col" class="py-1 px-1 text-xs border border-l-0 border-gray-200 text-center">{{ formatNumber(product.coef_wholesale_usd) }}</td>
                              <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right">{{ formatNumber(product.wholesale_price_usd) }} $</td>
                              <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right">{{ formatNumber(product.approved_wholesale_price_usd) }} $</td>
                            </tr>
                            <tr>
                              <td scope="col" class="py-1 px-1 text-xs border border-r-0 border-gray-200 text-left">Retail Price in USD</td>
                              <td scope="col" class="py-1 px-1 text-xs border border-l-0 border-gray-200 text-center">{{ formatNumber(product.coef_retail_usd) }}</td>
                              <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right">{{ formatNumber(product.retail_price_usd) }} $</td>
                              <td scope="col" class="py-1 px-1 text-xs border border-gray-200 text-right">{{ formatNumber(product.approved_retail_price_usd) }} $</td>
                            </tr>                                                                                                                                                                               
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </template>
          <template v-if="type === 'Price List'" class="overflow-y-auto">
            <table class="min-w-max w-full table-auto">
              <thead>
                <tr class="bg-purple-200 text-purple-600 uppercase text-sm leading-normal">
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-left" rowspan="2">Barcode</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-left" rowspan="2">Reference</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-left" rowspan="2">Description</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-left" rowspan="2">Color</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-left" rowspan="2">Length</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-left" rowspan="2">Width</th>
                  <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-left" rowspan="2">Height</th>
                  <th scope="col" class="w-20 px-2 py-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Total Cost Price</th>
                  <th class="w-20 px-2 py-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Merge / Wholesale Price Coeff</th>
                  <th scope="col" class="px-2 py-2 border-r border-b border-purple-300 border-opacity-50 text-center" colspan="4">Price</th>
                </tr>
                <tr class="bg-purple-200 text-purple-600 uppercase text-xs leading-normal">
                  <th class="w-20 px-2 py-2 border-r border-purple-300 border-opacity-50 text-center">Total Wholesale Price EUR</th>
                  <th class="w-20 px-2 py-2 border-r border-purple-300 border-opacity-50 text-center">Retail Price EUR</th>
                  <th class="w-20 px-2 py-2 border-r border-purple-300 border-opacity-50 text-center">Total Wholesale Price USD</th>
                  <th class="w-20 px-2 py-2 border-r border-purple-300 border-opacity-50 text-center">Retail Price USD</th>
                </tr>                                                          
              </thead>
              <tbody class="text-gray-600 text-xs font-light">
                <template v-if="isLoading">
                  <tr class="border-b border-gray-200 hover:bg-gray-100"><td class="py-3 px-6 text-sm text-center" colspan="13">Loading...</td></tr>
                </template>
                <template v-else>
                  <tr v-if="products.length === 0" class="border-b border-gray-200 hover:bg-gray-100"><td class="py-3 px-6 text-sm text-center" colspan="13">No data to display</td></tr>
                  <tr v-for="(product, index) in products" :key="product.id" class="border-b border-gray-200 hover:bg-gray-100" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">
                    <td class="px-2 py-2 text-left">{{ product.barcode }}</td>
                    <td class="px-2 py-2 text-left">{{ product.reference }}</td>
                    <td class="px-2 py-2 text-left">{{ product.description }}</td>
                    <td class="px-2 py-2 text-left">{{ product.color }}</td>
                    <td class="px-2 py-2 text-left">{{ product.long }}</td>
                    <td class="px-2 py-2 text-left">{{ product.width }}</td>
                    <td class="px-2 py-2 text-left">{{ product.high }}</td>
                    <td class="px-2 py-2 text-right">{{ formatNumber(toFixed(total[product.id], 2)) + ' €' }}</td>
                    <td class="px-2 py-2 text-right">{{ formatNumber(toFixed(product.coef_wholesale_price, 2)) }}</td>
                    <td class="px-2 py-2 text-right">{{ formatNumber(toFixed(product.approved_wholesale_price, 0)) + ' €' }}</td>
                    <td class="px-2 py-2 text-right">{{ formatNumber(toFixed(product.approved_retail_price, 0)) + ' €' }}</td>
                    <td class="px-2 py-2 text-right">{{ formatNumber(toFixed(product.approved_wholesale_price_usd, 0)) + ' $' }}</td>
                    <td class="px-2 py-2 text-right">{{ formatNumber(toFixed(product.approved_retail_price_usd, 0)) + ' $' }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </template>          
        </div>           
      </template>
      <template v-slot:footer>     
      </template> 
    </modal>
  </div>
</template>

<script>
import format from '@/helpers/formatNumber'
import collectionServices from '@/services/collection/collectionServices'
import productServices from '@/services/product/productServices'
import productRecipeServices from '@/services/product/productRecipeServices'
import Modal from '../../widgets/Modal'
import DownloadIcon from '../../icons/DownloadIcon'

export default {
  name: 'ExportProduct',
  components: {
    Modal,
    DownloadIcon,
  },
  data() {
    return {
      products: [],
      recipes: [],
      totalRecipe: [],
      tax: [],
      total: [],
      wholesalePrice: [],
      agentComission: [],
      type: 'Technical Sheet',
      types: ["Technical Sheet", "Product List", "Product Details", "Price List"],
      season: '',
      seasons: [],      
      collection: '',
      collections: [],
      reference: '',
      references: [],
      image: '',
      images: [
        { name: 'Have a Image', value: 'yes' },
        { name: 'No Image', value: 'no' }
      ],      
      search: '',
      currency: 'EUR',
      currencies: ["EUR", "USD", "ALL CURRENCY"],
      isFilter2: false,
      error: [],
      isLoading: false, 
      modalTitle: '',     
      showModal: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }
    }
  },
  methods: {
    async fetchData() {
      if (this.isFilter2 == false && (this.collection == '' || this.collection == null)) {
          /* THROW ERROR MESSAGES */
          this.$toasted.error('Please select the collection!', this.toastedOptions)
      } else if (this.isFilter2 == false && (this.reference == '' || this.reference == null)) {
          /* THROW ERROR MESSAGES */
          this.$toasted.error('Please select the reference!', this.toastedOptions)
      } else {
        try {
          this.isLoading = true
          
          const params = {
            take: '',
            page: '',
            search: this.search,
            season: this.season || '',
            collection: this.collection ? this.collection.id : '',
            reference: this.reference ? this.reference : '',
            image: this.image ? this.image.value : '',
            date_field: '',
            start: '',
            end: '',
            sort_field: this.isFilter2 ? 'reference' : 'color',
            sort_option: 'ASC',
            type: this.type
          }
          const response = await productServices.fetchAll(params)
          if (response.data.status === 'success') {
            this.isLoading =false
            this.products = response.data.data

            this.products.forEach( (element) => {
              let totalRecipe = 0
              element.recipes.forEach( (recipe) => {
                totalRecipe += parseFloat(recipe.total)
              })

              if (element.recipes.length < 11) {
                const length = 11 - element.recipes.length
                for (let i = 0; i < length; i++) {
                  element.recipes.push({
                    "id": null,
                    "supplier": null,
                    "material_id": null,
                    "material_name": null,
                    "color": null,
                    "size": null,
                    "unit": null,
                    "currency": null,
                    "price": null,
                    "qty1": null,
                    "qty2": null,
                    "total": null              
                  })
                }              
              }
          
              let manufacturingCost1 = element.euro_manufacturing_cost1 ? parseFloat(this.toFixed(element.euro_manufacturing_cost1, 2)) : 0
              let manufacturingCost2 = element.euro_manufacturing_cost2 ? parseFloat(element.euro_manufacturing_cost2) : 0
              let manufacturingCost3 = element.euro_manufacturing_cost3 ? parseFloat(element.euro_manufacturing_cost3) : 0
              let taxCustoms = element.tax_customs ? parseFloat(element.tax_customs) : 0
              let transportPackaging = element.transport_packaging ? parseFloat(element.transport_packaging) : 0
              let manufacturingCost = manufacturingCost1+manufacturingCost2
              let totalTax = ((manufacturingCost*taxCustoms) / 100)
              let totalCost = totalRecipe+manufacturingCost+totalTax+manufacturingCost3+transportPackaging
              let coefWholesale = element.coef_wholesale_price ? parseFloat(element.coef_wholesale_price) : 0
              let agentComission = element.agent_commision ? parseFloat(element.agent_commision) : 0
              let mergeWholesalePrice = coefWholesale*totalCost

              this.totalRecipe[element.id] = totalRecipe
              this.tax[element.id] = totalTax
              this.total[element.id] = totalCost
              this.wholesalePrice[element.id] = mergeWholesalePrice
              this.agentComission[element.id] = ((mergeWholesalePrice*agentComission) / 100)
            })
          } else {
            this.isLoading =false

            /* THROW ERROR MESSAGES */
            this.$toasted.error(response.data.message, this.toastedOptions)          
          }
        } catch (error) {
          this.isLoading =false
          console.log(error)
        }        
      }
    },  
    async fetchRecipeData(params, id) {
      try {
        const response = await productRecipeServices.fetchAll(params, id)
        if (response.data.status === 'success') {
          return response.data.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchSeasonData() {
      try {
        const response = await productServices.fetchSeasonDataOptions()
        if (response.data.status === 'success') {
          const records = response.data.data
          this.seasons = []
          this.seasons.push( 'All Season' )
          records.forEach(element => {
            this.seasons.push( element.season_creation )
          });
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },    
    async fetchCollectionData() {
      try {
        const response = await collectionServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.collections = []
          this.collections.push({'id': '', 'name': 'All Collection'})
          records.forEach(element => {
            this.collections.push({
              'id': element.id,
              'name': element.collection_name
            })
          });
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchReferenceData() {
      try {
        this.isLoading = true
        
        const params = {
          take: '',
          page: '',
          search: '',
          collection: this.collection ? this.collection.id : '',
          reference: '',          
          date_field: '',
          start: '',
          end: '',
          sort_field: '',
          sort_option: ''
        }
        const response = await productServices.fetchAllGroupByReference(params)
        if (response.data.status === 'success') {
          this.isLoading =false

          const records = response.data.data
          this.reference = ''
          this.references = []
          this.references.push( 'All Reference' )
          records.forEach(element => {
            this.references.push(element.reference)
          });
          const data = [ ...new Set(this.references) ]
          data.sort()
          this.references = data
        } else {
          this.isLoading =false

          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        this.isLoading =false
        console.log(error)
      }
    },    
    toggleModal() {
      this.collection = ''
      this.reference = ''
      this.image = ''
      this.search = ''
      this.clearProduct()
      this.fetchCollectionData()
      this.showModal = true
      this.modalTitle = 'Export Products'
    },
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    }, 
    clearProduct() {
      this.products = []
    },
    openDownloadTab(key) {
      let baseUrl = window.location.origin
      window.open(baseUrl+'/auth/download?key='+key, '_blank')
    },    
    async downloadFile() {
      try {
        this.isLoading = true
        const payload = {
          take: '',
          page: '',
          search: this.search,
          season: this.season || '',
          collection: this.collection ? this.collection.id : '',
          reference: this.reference ? this.reference : '',
          image: this.image ? this.image.value : '',
          date_field: '',
          start: '',
          end: '',
          sort_field: this.isFilter2 ? 'reference' : 'color',
          sort_option: 'ASC',
          type: this.type
        }
        const response = await productServices.export(payload)
        if (response.data.status === 'success') {
          this.isLoading = false
          this.$toasted.success(response.data.message, this.toastedOptions)

          const key = response.data.data
          this.openDownloadTab(key)
        } else {
          this.isLoading = false

          const returnType = typeof response.data.message
          if (returnType === 'object') {
            this.$toasted.error('Please fill collection type and reference!', this.toastedOptions)
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }
        }
      } catch (error) {
        this.isLoading = false
        console.log(error)
      }
    }    
  },
  mounted(){
    this.fetchSeasonData()
  },
  watch: {
    type: function(){
      if (this.type === 'Product List' || this.type === 'Price List') {
        this.isFilter2 = true
      } else {
        this.isFilter2 = false
      }
    },
  }
}
</script>