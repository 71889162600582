<template>
  <div>
    <!-- ACTION PANEL SECTION -->
    <div class="flex border-b border-gray-200 text-gray-800 justify-between items-center mb-4 pb-2">
      <div class="md:w-1/2 w-full items-center">
        <h2 class="text-2xl font-medium">Show Materials Calculation</h2>
      </div>
      <div class="flex items-center right-0">
      </div>
    </div>
    <div id="tabs">
      <div class="tabs">
        <a v-on:click="activeTab='1'" v-bind:class="[ activeTab == '1' ? 'active' : '' ]">Parameters</a>
        <a v-on:click="activeTab='2'" v-bind:class="[ activeTab == '2' ? 'active' : '' ]">Requirements Raw Materials</a>
      </div>
      <div class="content">
        <div v-if="activeTab == '1'" class="tab-content">
          <div class="flex border-b border-gray-200 text-gray-800 justify-between items-center mb-4 pb-2">
            <h2 class="text-2xl font-medium">Parameters Raw Material Requirements</h2>
          </div>
          <div class="w-full md:flex gap-4">
            <div class="w-full md:flex gap-2 mb-4">
              <div class="flex md:w-3/12">
                <div class="w-full mb-2 md:mb-0">
                  <label for="season" class="label-control md:py-3">Season <span class="text-red-600">*</span></label>
                </div>
              </div>
              <div class="w-full md:w-9/12 mb-2 md:mb-0">
                <ValidationProvider name="Season" rules="required" v-slot="{ errors }">
                  <MultiSelect id="season" v-model="season" :options="seasons" :value="season" :searchable="true" track-by="id" label="name" placeholder="Type to Search" :disabled="true">
                    <template slot="caret">
                      <div>
                        <div class="multiselect__select">
                          <span>
                            <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                              <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </template>
                  </MultiSelect>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
                <div v-if="error.season" class="text-sm text-red-600"><span>{{ error.season[0] }}</span></div>
              </div>
            </div>
            <div class="w-full md:flex gap-2 mb-4">
              <div class="flex md:w-3/12">
                <div class="w-full mb-2 md:mb-0">
                  <label for="next_season" class="label-control md:py-3">Next Season <span class="text-red-600">*</span></label>
                </div>
              </div>
              <div class="w-full md:w-9/12 mb-2 md:mb-0">
                <ValidationProvider name="NextSeason" rules="required" v-slot="{ errors }">
                  <MultiSelect id="next_season" v-model="nextSeason" :options="seasons" :value="nextSeason" :searchable="true" track-by="id" label="name" placeholder="Type to Search" :disabled="true">
                    <template slot="caret">
                      <div>
                        <div class="multiselect__select">
                          <span>
                            <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                              <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </template>
                  </MultiSelect>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
                <div v-if="error.next_season" class="text-sm text-red-600"><span>{{ error.next_season[0] }}</span></div>
              </div>              
            </div>                       
          </div>          
          <div class="w-full md:flex gap-6">
            <div class="w-full md:w-1/2">
              <div class="w-full md:flex gap-2 mb-2">
                <div class="flex md:w-3/12">
                  <div class="w-full mb-2 md:mb-0">
                    <label for="period_1_start" class="label-control md:py-3">Period 1 <span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="w-full md:w-9/12 mb-2 md:mb-0">
                  <v-date-picker ref="calendar" v-model="periodDate1" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs" is-range>
                    <template v-slot="{ inputValue, inputEvents, isDragging }">
                      <div class="flex flex-col sm:flex-row justify-start items-center">
                        <div class="w-full flex-grow mb-2 md:mb-0">
                          <ValidationProvider name="Period 1 Start" rules="required" v-slot="{ errors }">
                            <div class="w-full relative flex-grow">
                              <input id="period_1_start" class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" readonly/>
                              <span class="h-full absolute pointer-events-none right-0">
                                <DateRange class="m-3" />
                              </span> 
                            </div>
                            <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                          </ValidationProvider>
                          <div v-if="error.period_1_start" class="text-sm text-red-600"><span>{{ error.period_1_start[0] }}</span></div>
                        </div>
                        <span class="flex-shrink-0 m-2 hidden md:block">
                          <svg class="w-4 h-4 stroke-current text-gray-600" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                          </svg>
                        </span>
                        <div class="w-full flex-grow">
                          <ValidationProvider name="Period 1 End" rules="required" v-slot="{ errors }">
                            <div class="w-full relative flex-grow">
                              <input class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end" readonly/>
                              <span class="h-full absolute pointer-events-none right-0">
                                <DateRange class="m-3" />
                              </span>
                            </div>
                            <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                          </ValidationProvider>
                          <div v-if="error.period_1_end" class="text-sm text-red-600"><span>{{ error.period_1_end[0] }}</span></div>                               
                        </div>                
                      </div>
                    </template>
                  </v-date-picker>            
                </div>
              </div>
              <div class="w-full md:flex gap-2 mb-2">
                <div class="flex md:w-3/12">
                  <div class="w-full mb-2 md:mb-0">
                    <label for="period_2_start" class="label-control md:py-3">Period 2 <span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="w-full md:w-9/12 mb-2 md:mb-0">
                  <v-date-picker ref="calendar" v-model="periodDate2" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs" :min-date="new Date(new Date(periodDate1.end || minDate2).setDate(new Date(periodDate1.end || minDate2).getDate()+1))" is-range>
                    <template v-slot="{ inputValue, inputEvents, isDragging }">
                      <div class="flex flex-col sm:flex-row justify-start items-center">
                        <div class="w-full flex-grow mb-2 md:mb-0">
                          <ValidationProvider name="Period 2 Start" rules="" v-slot="{ errors }">
                            <div class="w-full relative flex-grow">
                              <input id="period_2_start" class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" readonly/>
                              <span class="h-full absolute pointer-events-none right-0">
                                <DateRange class="m-3" />
                              </span> 
                            </div>
                            <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                          </ValidationProvider>
                          <div v-if="error.period_2_start" class="text-sm text-red-600"><span>{{ error.period_2_start[0] }}</span></div>
                        </div>
                        <span class="flex-shrink-0 m-2 hidden md:block">
                          <svg class="w-4 h-4 stroke-current text-gray-600" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                          </svg>
                        </span>
                        <div class="w-full flex-grow">
                          <ValidationProvider name="Period 2 End" rules="" v-slot="{ errors }">
                            <div class="w-full relative flex-grow">
                              <input class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end" readonly/>
                              <span class="h-full absolute pointer-events-none right-0">
                                <DateRange class="m-3" />
                              </span>
                            </div>
                            <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                          </ValidationProvider>
                          <div v-if="error.period_2_end" class="text-sm text-red-600"><span>{{ error.period_2_end[0] }}</span></div>                               
                        </div>                
                      </div>
                    </template>
                  </v-date-picker>            
                </div>
              </div>
              <div class="w-full md:flex gap-2 mb-2">
                <div class="flex md:w-3/12">
                  <div class="w-full mb-2 md:mb-0">
                    <label for="period_3_start" class="label-control md:py-3">Period 3 <span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="w-full md:w-9/12 mb-2 md:mb-0">
                  <v-date-picker ref="calendar" v-model="periodDate3" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs" :min-date="new Date(new Date(periodDate2.end || minDate3).setDate(new Date(periodDate2.end || minDate3).getDate()+1))" is-range>
                    <template v-slot="{ inputValue, inputEvents, isDragging }">
                      <div class="flex flex-col sm:flex-row justify-start items-center">
                        <div class="w-full flex-grow mb-2 md:mb-0">
                          <ValidationProvider name="Period 3 Start" rules="" v-slot="{ errors }">
                            <div class="w-full relative flex-grow">
                              <input id="period_3_start" class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" readonly/>
                              <span class="h-full absolute pointer-events-none right-0">
                                <DateRange class="m-3" />
                              </span> 
                            </div>
                            <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                          </ValidationProvider>
                          <div v-if="error.period_3_start" class="text-sm text-red-600"><span>{{ error.period_3_start[0] }}</span></div>
                        </div>
                        <span class="flex-shrink-0 m-2 hidden md:block">
                          <svg class="w-4 h-4 stroke-current text-gray-600" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                          </svg>
                        </span>
                        <div class="w-full flex-grow">
                          <ValidationProvider name="Period 3 End" rules="" v-slot="{ errors }">
                            <div class="w-full relative flex-grow">
                              <input class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end" readonly/>
                              <span class="h-full absolute pointer-events-none right-0">
                                <DateRange class="m-3" />
                              </span>
                            </div>
                            <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                          </ValidationProvider>
                          <div v-if="error.period_3_end" class="text-sm text-red-600"><span>{{ error.period_3_end[0] }}</span></div>                               
                        </div>                
                      </div>
                    </template>
                  </v-date-picker>            
                </div>
              </div>            
            </div>
            <div class="w-full md:w-1/2">
              <div class="w-full md:flex gap-2 mb-2">
                <div class="flex md:w-3/12">
                  <div class="w-full mb-2 md:mb-0">
                    <label for="period_4_start" class="label-control md:py-3">Period 4 <span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="w-full md:w-9/12 mb-2 md:mb-0">
                  <v-date-picker ref="calendar" v-model="periodDate4" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs" :min-date="new Date(new Date(periodDate3.end || minDate4).setDate(new Date(periodDate3.end || minDate4).getDate()+1))" is-range>
                    <template v-slot="{ inputValue, inputEvents, isDragging }">
                      <div class="flex flex-col sm:flex-row justify-start items-center">
                        <div class="w-full flex-grow mb-2 md:mb-0">
                          <ValidationProvider name="Period 4 Start" rules="" v-slot="{ errors }">
                            <div class="w-full relative flex-grow">
                              <input id="period_4_start" class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" readonly/>
                              <span class="h-full absolute pointer-events-none right-0">
                                <DateRange class="m-3" />
                              </span> 
                            </div>
                            <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                          </ValidationProvider>
                          <div v-if="error.period_4_start" class="text-sm text-red-600"><span>{{ error.period_4_start[0] }}</span></div>
                        </div>
                        <span class="flex-shrink-0 m-2 hidden md:block">
                          <svg class="w-4 h-4 stroke-current text-gray-600" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                          </svg>
                        </span>
                        <div class="w-full flex-grow">
                          <ValidationProvider name="Period 4 End" rules="" v-slot="{ errors }">
                            <div class="w-full relative flex-grow">
                              <input class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end" readonly/>
                              <span class="h-full absolute pointer-events-none right-0">
                                <DateRange class="m-3" />
                              </span>
                            </div>
                            <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                          </ValidationProvider>
                          <div v-if="error.period_4_end" class="text-sm text-red-600"><span>{{ error.period_4_end[0] }}</span></div>                               
                        </div>                
                      </div>
                    </template>
                  </v-date-picker>            
                </div>
              </div>
              <div class="w-full md:flex gap-2 mb-2">
                <div class="flex md:w-3/12">
                  <div class="w-full mb-2 md:mb-0">
                    <label for="period_5_start" class="label-control md:py-3">Period 5 <span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="w-full md:w-9/12 mb-2 md:mb-0">
                  <v-date-picker ref="calendar" v-model="periodDate5" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs" :min-date="new Date(new Date(periodDate4.end || minDate5).setDate(new Date(periodDate4.end || minDate5).getDate()+1))" is-range>
                    <template v-slot="{ inputValue, inputEvents, isDragging }">
                      <div class="flex flex-col sm:flex-row justify-start items-center">
                        <div class="w-full flex-grow mb-2 md:mb-0">
                          <ValidationProvider name="Period 5 Start" rules="" v-slot="{ errors }">
                            <div class="w-full relative flex-grow">
                              <input id="period_5_start" class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" readonly/>
                              <span class="h-full absolute pointer-events-none right-0">
                                <DateRange class="m-3" />
                              </span> 
                            </div>
                            <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                          </ValidationProvider>
                          <div v-if="error.period_5_start" class="text-sm text-red-600"><span>{{ error.period_5_start[0] }}</span></div>
                        </div>
                        <span class="flex-shrink-0 m-2 hidden md:block">
                          <svg class="w-4 h-4 stroke-current text-gray-600" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                          </svg>
                        </span>
                        <div class="w-full flex-grow">
                          <ValidationProvider name="Period 5 End" rules="" v-slot="{ errors }">
                            <div class="w-full relative flex-grow">
                              <input class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end" readonly/>
                              <span class="h-full absolute pointer-events-none right-0">
                                <DateRange class="m-3" />
                              </span>
                            </div>
                            <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                          </ValidationProvider>
                          <div v-if="error.period_5_end" class="text-sm text-red-600"><span>{{ error.period_5_end[0] }}</span></div>                               
                        </div>                
                      </div>
                    </template>
                  </v-date-picker>            
                </div>
              </div>
              <div class="w-full md:flex gap-2 mb-2">
                <div class="flex md:w-3/12">
                  <div class="w-full mb-2 md:mb-0">
                    <label for="period_6_start" class="label-control md:py-3">Period 6 <span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="w-full md:w-9/12 mb-2 md:mb-0">
                  <v-date-picker ref="calendar" v-model="periodDate6" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs" :min-date="new Date(new Date(periodDate5.end || minDate6).setDate(new Date(periodDate5.end || minDate6).getDate()+1))" is-range>
                    <template v-slot="{ inputValue, inputEvents, isDragging }">
                      <div class="flex flex-col sm:flex-row justify-start items-center">
                        <div class="w-full flex-grow mb-2 md:mb-0">
                          <ValidationProvider name="Period 6 Start" rules="" v-slot="{ errors }">
                            <div class="w-full relative flex-grow">
                              <input id="period_6_start" class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" readonly/>
                              <span class="h-full absolute pointer-events-none right-0">
                                <DateRange class="m-3" />
                              </span> 
                            </div>
                            <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                          </ValidationProvider>
                          <div v-if="error.period_6_start" class="text-sm text-red-600"><span>{{ error.period_6_start[0] }}</span></div>
                        </div>
                        <span class="flex-shrink-0 m-2 hidden md:block">
                          <svg class="w-4 h-4 stroke-current text-gray-600" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                          </svg>
                        </span>
                        <div class="w-full flex-grow">
                          <ValidationProvider name="Period 6 End" rules="" v-slot="{ errors }">
                            <div class="w-full relative flex-grow">
                              <input class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end" readonly/>
                              <span class="h-full absolute pointer-events-none right-0">
                                <DateRange class="m-3" />
                              </span>
                            </div>
                            <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                          </ValidationProvider>
                          <div v-if="error.period_6_end" class="text-sm text-red-600"><span>{{ error.period_6_end[0] }}</span></div>                               
                        </div>                
                      </div>
                    </template>
                  </v-date-picker>            
                </div>
              </div>            
            </div>
          </div>                              
        </div>
        <div v-if="activeTab == '2'" class="tab-content">
          <div class="flex border-b border-gray-200 text-gray-800 justify-between items-center mb-4 pb-2">
            <div class="md:w-1/2 w-full items-center">
              <h2 class="text-2xl font-medium">Calculation</h2>
            </div>
            <div class="flex items-center right-0 gap-2">            
              <button :disabled="isLoading" type="button" @click="downloadCalculationFile()" id="btnDownload" class="btn btn--success" alt="Download" v-tooltip="'Download'">
                <span v-if="isLoading" class="block mx-5">
                  <Loading />
                </span>
                <DownloadIcon v-else>
                  <span class="ml-2 md:block hidden">Download</span>
                </DownloadIcon>
              </button>
            </div>
          </div>           
          <div class="mb-4 bg-white shadow-lg rounded-lg overflow-y-auto">
            <div class="flex flex-col h-screen">
              <div class="flex-grow overflow-auto">
                <table class="relative w-full border">
                  <thead class="sticky top-0 z-50">
                    <tr>
                      <th scope="col" class="px-2 box-br text-center sticky left-col-1 bg-purple-200" rowspan="2">Type</th>
                      <th scope="col" class="px-2 box-br text-center sticky left-col-2 bg-purple-200" rowspan="2">Supplier</th>
                      <th scope="col" class="px-2 box-br text-center sticky left-col-3 bg-purple-200" rowspan="2">Reference</th>
                      <th scope="col" class="px-2 box-br text-center sticky left-col-4 bg-purple-200" rowspan="2">Size</th>
                      <th scope="col" class="px-2 box-br text-center sticky left-col-5 bg-purple-200" rowspan="2">Color</th>
                      <th scope="col" class="px-2 box-br text-center sticky left-col-6 bg-purple-200 price-column" rowspan="2">Price</th>
                      <th scope="col" class="px-2 box-br text-center sticky left-col-7 bg-purple-200" rowspan="2">Min Stock</th>
                      <th scope="col" class="px-2 box-br text-center sticky left-col-8 bg-purple-200" rowspan="2">Estimate Stock</th>
                      <th scope="col" class="px-2 box-br text-center sticky left-col-9 bg-purple-200" rowspan="2">Initial Stock</th>
                      <th scope="col" class="px-2 box-br text-center sticky left-col-10 bg-purple-200" rowspan="2">Unit</th>
                      <th v-if="showPeriod1" scope="col" class="px-2 box-br text-center bg-yellow-100" :colspan="periodColumns">Period 1</th>
                      <th v-if="showPeriod2" scope="col" class="px-2 box-br text-center bg-blue-100" :colspan="periodColumns">Period 2</th>
                      <th v-if="showPeriod3" scope="col" class="px-2 box-br text-center bg-gray-100" :colspan="periodColumns">Period 3</th>
                      <th v-if="showPeriod4" scope="col" class="px-2 box-br text-center bg-yellow-100" :colspan="periodColumns">Period 4</th>
                      <th v-if="showPeriod5" scope="col" class="px-2 box-br text-center bg-blue-100" :colspan="periodColumns">Period 5</th>                                    
                      <th v-if="showPeriod6" scope="col" class="px-2 box-b text-center bg-gray-100" :colspan="periodColumns">Period 6</th>
                    </tr>
                    <tr>
                      <template v-if="showPeriod1">
                        <th scope="col" class="px-2 box-br text-center bg-yellow-100">NEEDS</th>
                        <th scope="col" class="px-2 box-br text-center bg-yellow-100">TO BUY STOCK</th>
                        <th scope="col" class="px-2 box-br text-center bg-yellow-100">WANT NEEDED</th>
                        <th scope="col" class="px-2 box-br text-center bg-yellow-100">PURCHASE ORDER</th>
                        <th scope="col" class="px-2 box-br text-center bg-yellow-100">RECEIVE</th>
                        <th scope="col" class="px-2 box-br text-center bg-yellow-100">UNIT</th>
                        <th scope="col" class="px-2 box-br text-center bg-yellow-100">COST</th>
                        <th scope="col" class="px-2 box-br text-center bg-yellow-100">ESTIMATE STOCK</th>
                        <th scope="col" class="px-2 box-br text-center bg-yellow-100">STOCK LEFT</th>
                      </template>
                      <template v-if="showPeriod2">
                        <th scope="col" class="px-2 box-br text-center bg-blue-100">NEEDS</th>
                        <th scope="col" class="px-2 box-br text-center bg-blue-100">TO BUY STOCK</th>
                        <th scope="col" class="px-2 box-br text-center bg-blue-100">WANT NEEDED</th>
                        <th scope="col" class="px-2 box-br text-center bg-blue-100">PURCHASE ORDER</th>
                        <th scope="col" class="px-2 box-br text-center bg-blue-100">RECEIVE</th>
                        <th scope="col" class="px-2 box-br text-center bg-blue-100">UNIT</th>
                        <th scope="col" class="px-2 box-br text-center bg-blue-100">COST</th>
                        <th scope="col" class="px-2 box-br text-center bg-blue-100">ESTIMATE STOCK</th>
                        <th scope="col" class="px-2 box-br text-center bg-blue-100">STOCK LEFT</th>
                      </template>
                      <template v-if="showPeriod3">
                        <th scope="col" class="px-2 box-br text-center bg-gray-100">NEEDS</th>
                        <th scope="col" class="px-2 box-br text-center bg-gray-100">TO BUY STOCK</th>
                        <th scope="col" class="px-2 box-br text-center bg-gray-100">WANT NEEDED</th>
                        <th scope="col" class="px-2 box-br text-center bg-gray-100">PURCHASE ORDER</th>
                        <th scope="col" class="px-2 box-br text-center bg-gray-100">RECEIVE</th>
                        <th scope="col" class="px-2 box-br text-center bg-gray-100">UNIT</th>
                        <th scope="col" class="px-2 box-br text-center bg-gray-100">COST</th>
                        <th scope="col" class="px-2 box-br text-center bg-gray-100">ESTIMATE STOCK</th>
                        <th scope="col" class="px-2 box-br text-center bg-gray-100">STOCK LEFT</th>
                      </template>
                      <template v-if="showPeriod4">
                        <th scope="col" class="px-2 box-br text-center bg-yellow-100">NEEDS</th>
                        <th scope="col" class="px-2 box-br text-center bg-yellow-100">TO BUY STOCK</th>
                        <th scope="col" class="px-2 box-br text-center bg-yellow-100">WANT NEEDED</th>
                        <th scope="col" class="px-2 box-br text-center bg-yellow-100">PURCHASE ORDER</th>
                        <th scope="col" class="px-2 box-br text-center bg-yellow-100">RECEIVE</th>
                        <th scope="col" class="px-2 box-br text-center bg-yellow-100">UNIT</th>
                        <th scope="col" class="px-2 box-br text-center bg-yellow-100">COST</th>
                        <th scope="col" class="px-2 box-br text-center bg-yellow-100">ESTIMATE STOCK</th>
                        <th scope="col" class="px-2 box-br text-center bg-yellow-100">STOCK LEFT</th>
                      </template>
                      <template v-if="showPeriod5">
                        <th scope="col" class="px-2 box-br text-center bg-blue-100">NEEDS</th>
                        <th scope="col" class="px-2 box-br text-center bg-blue-100">TO BUY STOCK</th>
                        <th scope="col" class="px-2 box-br text-center bg-blue-100">WANT NEEDED</th>
                        <th scope="col" class="px-2 box-br text-center bg-blue-100">PURCHASE ORDER</th>
                        <th scope="col" class="px-2 box-br text-center bg-blue-100">RECEIVE</th>
                        <th scope="col" class="px-2 box-br text-center bg-blue-100">UNIT</th>
                        <th scope="col" class="px-2 box-br text-center bg-blue-100">COST</th>
                        <th scope="col" class="px-2 box-br text-center bg-blue-100">ESTIMATE STOCK</th>
                        <th scope="col" class="px-2 box-br text-center bg-blue-100">STOCK LEFT</th>
                      </template>
                      <template v-if="showPeriod6">
                        <th scope="col" class="px-2 box-br text-center bg-gray-100">NEEDS</th>
                        <th scope="col" class="px-2 box-br text-center bg-gray-100">TO BUY STOCK</th>
                        <th scope="col" class="px-2 box-br text-center bg-gray-100">WANT NEEDED</th>
                        <th scope="col" class="px-2 box-br text-center bg-gray-100">PURCHASE ORDER</th>
                        <th scope="col" class="px-2 box-br text-center bg-gray-100">RECEIVE</th>
                        <th scope="col" class="px-2 box-br text-center bg-gray-100">UNIT</th>
                        <th scope="col" class="px-2 box-br text-center bg-gray-100">COST</th>
                        <th scope="col" class="px-2 box-b text-center bg-gray-100">ESTIMATE STOCK</th>
                        <th scope="col" class="px-2 box-b text-center bg-gray-100">STOCK LEFT</th>
                      </template>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="isLoading">
                      <tr class="border-b border-gray-200"><td class="text-center" :colspan="totalColumns">Loading Data...</td></tr>
                    </template>
                    <template v-else>
                      <tr v-if="details.length === 0" class="border-b border-gray-200"><td class="text-center" :colspan="totalColumns">Run Process to Display Data</td></tr>
                      <tr v-for="(detail, index) in details" :key="index" class="border-b border-gray-200 hover:bg-gray-100" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">
                        <td class="px-2 py-1 text-xs text-left sticky left-col-1 bg-white" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">
                          <span v-for="materialType in detail.material.type" :key="materialType.id" class="mr-0.5">{{ materialType.code }}</span>
                        </td>
                        <td class="px-2 py-1 text-xs text-left sticky left-col-2 bg-white" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">{{ detail.material.supplier.supplier_name }}</td>
                        <td class="px-2 py-1 text-xs text-left sticky left-col-3 bg-white" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">
                          <a class="cursor-pointer" href="javascript:void(0)" @click="toggleModal(detail.material.id)" alt="Analyze Material" v-tooltip="'Analyze Material'">{{ detail.material.material_name }}</a>
                        </td>
                        <td class="px-2 py-1 text-xs text-left sticky left-col-4 bg-white" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">{{ detail.material.size }}</td>
                        <td class="px-2 py-1 text-xs text-left sticky left-col-5 bg-white" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">{{ detail.material.color }}</td>
                        <td class="px-2 py-1 text-xs text-right sticky left-col-6 bg-white price-column" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">{{ detail.material.current_price }} €</td>
                        <td class="px-2 py-1 text-xs text-right sticky left-col-7 bg-white" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">{{ formatNumber(toFixed(detail.material.minimum_stock, 2)) }}</td>
                        <td class="px-2 py-1 text-xs text-right sticky left-col-8 bg-white" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">{{ formatNumber(toFixed(detail.estimate_stock, 2)) }}</td>
                        <td class="px-2 py-1 text-xs text-right sticky left-col-9 bg-white" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">{{ formatNumber(toFixed(detail.initial_stock, 2)) }}</td>
                        <td class="px-2 py-1 text-xs text-left sticky left-col-10 bg-white" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">{{ detail.material.unit }}</td>
                        <template v-if="showPeriod1">
                          <td class="px-1 py-1 text-right bg-yellow-100">
                            <input type="text" class="calc-form-control" v-model="detail.need_1" readonly>
                          </td>
                          <td class="px-1 py-1 text-right bg-yellow-100">
                            <input type="text" class="calc-form-control" v-model="detail.to_buy_1" readonly>
                          </td>
                          <td class="px-1 py-1 text-right bg-yellow-100">
                            <input type="text" class="calc-form-control" v-model="detail.want_needed_1" readonly>
                          </td>
                          <td class="px-1 py-1 text-right bg-yellow-100">
                            <input type="text" class="calc-form-control" :value="detail.purchase_1 > 0 ? formatNumber(toFixed(detail.purchase_1, 2)) : toFixed(detail.purchase_1, 2)" readonly>
                          </td>
                          <td class="px-1 py-1 text-right bg-yellow-100">
                            <input type="text" class="calc-form-control" :value="detail.receive_1 > 0 ? formatNumber(toFixed(detail.receive_1, 2)) : toFixed(detail.receive_1, 2)" readonly>
                          </td>                                          
                          <td class="px-1 py-1 text-xs text-left bg-yellow-100">{{ detail.unit_1 }}</td>
                          <td class="px-1 py-1 text-right bg-yellow-100">
                            <input type="text" class="calc-form-control" v-model="detail.cost_1" readonly>
                          </td>
                          <td class="px-1 py-1 text-right bg-yellow-100">
                            <input type="text" class="calc-form-control bg-pink" :value="detail.estimate_stock_1 > 0 ? formatNumber(toFixed(detail.estimate_stock_1, 2)) : toFixed(detail.estimate_stock_1, 2)" readonly>
                          </td>                        
                          <td class="px-1 py-1 text-right bg-yellow-100">
                            <input type="text" class="calc-form-control bg-green" :value="detail.stock_left_1 > 0 ? formatNumber(toFixed(detail.stock_left_1, 2)) : toFixed(detail.stock_left_1, 2)" readonly>
                          </td>
                        </template>
                        <template v-if="showPeriod2">
                          <td class="px-1 py-1 text-right bg-blue-100">
                            <input type="text" class="calc-form-control" v-model="detail.need_2" readonly>
                          </td>
                          <td class="px-1 py-1 text-right bg-blue-100">
                            <input type="text" class="calc-form-control" v-model="detail.to_buy_2" readonly>
                          </td>
                          <td class="px-1 py-1 text-right bg-blue-100">
                            <input type="text" class="calc-form-control" v-model="detail.want_needed_2" readonly>
                          </td>
                          <td class="px-1 py-1 text-right bg-blue-100">
                            <input type="text" class="calc-form-control" :value="detail.purchase_2 > 0 ? formatNumber(toFixed(detail.purchase_2, 2)) : toFixed(detail.purchase_2, 2)" readonly>
                          </td>
                          <td class="px-1 py-1 text-right bg-blue-100">
                            <input type="text" class="calc-form-control" :value="detail.receive_2 > 0 ? formatNumber(toFixed(detail.receive_2, 2)) : toFixed(detail.receive_2, 2)" readonly>
                          </td>                                          
                          <td class="px-1 py-1 text-xs text-left bg-blue-100">{{ detail.unit_2 }}</td>
                          <td class="px-1 py-1 text-right bg-blue-100">
                            <input type="text" class="calc-form-control" v-model="detail.cost_2" readonly>
                          </td>
                          <td class="px-1 py-1 text-right bg-blue-100">
                            <input type="text" class="calc-form-control bg-pink" :value="detail.estimate_stock_2 > 0 ? formatNumber(toFixed(detail.estimate_stock_2, 2)) : toFixed(detail.estimate_stock_2, 2)" readonly>
                          </td>                        
                          <td class="px-1 py-1 text-right bg-blue-100">
                            <input type="text" class="calc-form-control bg-green" :value="detail.stock_left_2 > 0 ? formatNumber(toFixed(detail.stock_left_2, 2)) : toFixed(detail.stock_left_2, 2)" readonly>
                          </td>                        
                        </template>
                        <template v-if="showPeriod3">
                          <td class="px-1 py-1 text-right bg-gray-100">
                            <input type="text" class="calc-form-control" v-model="detail.need_3" readonly>
                          </td>
                          <td class="px-1 py-1 text-right bg-gray-100">
                            <input type="text" class="calc-form-control" v-model="detail.to_buy_3" readonly>
                          </td>
                          <td class="px-1 py-1 text-right bg-gray-100">
                            <input type="text" class="calc-form-control" v-model="detail.want_needed_3" readonly>
                          </td>
                          <td class="px-1 py-1 text-right bg-gray-100">
                            <input type="text" class="calc-form-control" :value="detail.purchase_3 > 0 ? formatNumber(toFixed(detail.purchase_3, 2)) : toFixed(detail.purchase_3, 2)" readonly>
                          </td>
                          <td class="px-1 py-1 text-right bg-gray-100">
                            <input type="text" class="calc-form-control" :value="detail.receive_3 > 0 ? formatNumber(toFixed(detail.receive_3, 2)) : toFixed(detail.receive_3, 2)" readonly>
                          </td>                                          
                          <td class="px-1 py-1 text-xs text-left bg-gray-100">{{ detail.unit_3 }}</td>
                          <td class="px-1 py-1 text-right bg-gray-100">
                            <input type="text" class="calc-form-control" v-model="detail.cost_3" readonly>
                          </td>
                          <td class="px-1 py-1 text-right bg-gray-100">
                            <input type="text" class="calc-form-control bg-pink" :value="detail.estimate_stock_2 > 0 ? formatNumber(toFixed(detail.estimate_stock_2, 2)) : toFixed(detail.estimate_stock_2, 2)" readonly>
                          </td>                        
                          <td class="px-1 py-1 text-right bg-gray-100">
                            <input type="text" class="calc-form-control bg-green" :value="detail.stock_left_3 > 0 ? formatNumber(toFixed(detail.stock_left_3, 2)) : toFixed(detail.stock_left_3, 2)" readonly>
                          </td>                        
                        </template>
                        <template v-if="showPeriod4">
                          <td class="px-1 py-1 text-right bg-yellow-100">
                            <input type="text" class="calc-form-control" v-model="detail.need_4" readonly>
                          </td>
                          <td class="px-1 py-1 text-right bg-yellow-100">
                            <input type="text" class="calc-form-control" v-model="detail.to_buy_4" readonly>
                          </td>
                          <td class="px-1 py-1 text-right bg-yellow-100">
                            <input type="text" class="calc-form-control" v-model="detail.want_needed_4" readonly>
                          </td>
                          <td class="px-1 py-1 text-right bg-yellow-100">
                            <input type="text" class="calc-form-control" :value="detail.purchase_4 > 0 ? formatNumber(toFixed(detail.purchase_4, 2)) : toFixed(detail.purchase_4, 2)" readonly>
                          </td>
                          <td class="px-1 py-1 text-right bg-yellow-100">
                            <input type="text" class="calc-form-control" :value="detail.receive_4 > 0 ? formatNumber(toFixed(detail.receive_4, 2)) : toFixed(detail.receive_4, 2)" readonly>
                          </td>                                          
                          <td class="px-1 py-1 text-xs text-left bg-yellow-100">{{ detail.unit_4 }}</td>
                          <td class="px-1 py-1 text-right bg-yellow-100">
                            <input type="text" class="calc-form-control" v-model="detail.cost_4" readonly>
                          </td>
                          <td class="px-1 py-1 text-right bg-yellow-100">
                            <input type="text" class="calc-form-control bg-pink" :value="detail.estimate_stock_4 > 0 ? formatNumber(toFixed(detail.estimate_stock_4, 2)) : toFixed(detail.estimate_stock_4, 2)" readonly>
                          </td>                        
                          <td class="px-1 py-1 text-right bg-yellow-100">
                            <input type="text" class="calc-form-control bg-green" :value="detail.stock_left_4 > 0 ? formatNumber(toFixed(detail.stock_left_4, 2)) : toFixed(detail.stock_left_4, 2)" readonly>
                          </td>                        
                        </template>
                        <template v-if="showPeriod5">
                          <td class="px-1 py-1 text-right bg-blue-100">
                            <input type="text" class="calc-form-control" v-model="detail.need_5" readonly>
                          </td>
                          <td class="px-1 py-1 text-right bg-blue-100">
                            <input type="text" class="calc-form-control" v-model="detail.to_buy_5" readonly>
                          </td>
                          <td class="px-1 py-1 text-right bg-blue-100">
                            <input type="text" class="calc-form-control" v-model="detail.want_needed_5" readonly>
                          </td>
                          <td class="px-1 py-1 text-right bg-blue-100">
                            <input type="text" class="calc-form-control" :value="detail.purchase_5 > 0 ? formatNumber(toFixed(detail.purchase_5, 2)) : toFixed(detail.purchase_5, 2)" readonly>
                          </td>
                          <td class="px-1 py-1 text-right bg-blue-100">
                            <input type="text" class="calc-form-control" :value="detail.receive_5 > 0 ? formatNumber(toFixed(detail.receive_5, 2)) : toFixed(detail.receive_5, 2)" readonly>
                          </td>                                          
                          <td class="px-1 py-1 text-xs text-left bg-blue-100">{{ detail.unit_5 }}</td>
                          <td class="px-1 py-1 text-right bg-blue-100">
                            <input type="text" class="calc-form-control" v-model="detail.cost_5" readonly>
                          </td>
                          <td class="px-1 py-1 text-right bg-blue-100">
                            <input type="text" class="calc-form-control bg-pink" :value="detail.estimate_stock_5 > 0 ? formatNumber(toFixed(detail.estimate_stock_5, 2)) : toFixed(detail.estimate_stock_5, 2)" readonly>
                          </td>                        
                          <td class="px-1 py-1 text-right bg-blue-100">
                            <input type="text" class="calc-form-control bg-green" :value="detail.stock_left_5 > 0 ? formatNumber(toFixed(detail.stock_left_5, 2)) : toFixed(detail.stock_left_5, 2)" readonly>
                          </td>                        
                        </template>
                        <template v-if="showPeriod6">
                          <td class="px-1 py-1 text-right bg-gray-100">
                            <input type="text" class="calc-form-control" v-model="detail.need_6" readonly>
                          </td>
                          <td class="px-1 py-1 text-right bg-gray-100">
                            <input type="text" class="calc-form-control" v-model="detail.to_buy_6" readonly>
                          </td>
                          <td class="px-1 py-1 text-right bg-gray-100">
                            <input type="text" class="calc-form-control" v-model="detail.want_needed_6" readonly>
                          </td>
                          <td class="px-1 py-1 text-right bg-gray-100">
                            <input type="text" class="calc-form-control" :value="detail.purchase_6 > 0 ? formatNumber(toFixed(detail.purchase_6, 2)) : toFixed(detail.purchase_6, 2)" readonly>
                          </td>
                          <td class="px-1 py-1 text-right bg-gray-100">
                            <input type="text" class="calc-form-control" :value="detail.receive_6 > 0 ? formatNumber(toFixed(detail.receive_6, 2)) : toFixed(detail.receive_6, 2)" readonly>
                          </td>                                          
                          <td class="px-1 py-1 text-xs text-left bg-gray-100">{{ detail.unit_6 }}</td>
                          <td class="px-1 py-1 text-right bg-gray-100">
                            <input type="text" class="calc-form-control" v-model="detail.cost_6" readonly>
                          </td>
                          <td class="px-1 py-1 text-right bg-gray-100">
                            <input type="text" class="calc-form-control bg-pink" :value="detail.estimate_stock_6 > 0 ? formatNumber(toFixed(detail.estimate_stock_6, 2)) : toFixed(detail.estimate_stock_6, 2)" readonly>
                          </td>                        
                          <td class="px-1 py-1 text-right bg-gray-100">
                            <input type="text" class="calc-form-control bg-green" :value="detail.stock_left_6 > 0 ? formatNumber(toFixed(detail.stock_left_6, 2)) : toFixed(detail.stock_left_6, 2)" readonly>
                          </td>                        
                        </template>                                    
                      </tr>                      
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="md:flex gap-4 mt-4">
            <button :disabled="isLoading" type="button" @click="goBack()" class="btn btn--secondary" alt="Back" title="Back">
              <BackIcon>
                <span class="ml-2 md:block hidden">Back</span>
              </BackIcon>              
            </button>
          </div>          
        </div>        
      </div>  
    </div>
    <!-- REPORT FILTER & ACTIONS -->
    <div class="fixed bg-gray-800 bg-opacity-25 w-full h-full inset-0 top-16 md:top-0 z-50" :class="{ 'hidden': !showFilter }">
      <div class="w-80 ml-auto h-full overflow-y-scroll bg-white shadow-lg right-0">
        <div class="flex p-5 border-b border-gray-200 items-center">
          <span class="flex-1 text-sm font-medium">Filters & Sort</span>
          <button type="button" class="flex right-0" @click="toggleFilter">
            <svg class="h-5 w-5 fill-current hover:text-gray-700" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
            </svg>
          </button>
        </div>
        <div class="p-5 items-center">
          <!-- LOCATION FILTER REPORT -->
          <div class="w-full mb-4">
            <div class="w-full mb-2">              
              <label for="supplier" class="label-control">Supplier</label>
              <MultiSelect id="supplier" ref="supplier" v-model="supplier" :options="suppliers" :value="supplier" :searchable="true" track-by="id" label="name" placeholder="Type to Search">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>
            </div>            
            <div class="w-full mb-2">              
              <label for="collection" class="label-control">Collection</label>
              <MultiSelect id="collection" ref="collection" v-model="collection" :options="collections" :value="collection" :searchable="true" track-by="id" label="name" :showLabels="false" placeholder="Tap to Search">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>
            </div>
            <div class="w-full mb-2">              
              <label for="collection" class="label-control">Periods</label>
              <div class="w-full flex gap-2">
                <div class="w-1/2 mb-2">
                  <div class="form-check">
                    <input class="checkbox-purple" type="checkbox" value="" id="checkboxPeriod1" v-model="showPeriod1" @change="countPeriodColumns(showPeriod1)">
                    <label class="inline-block cursor-pointer" for="checkboxPeriod1">
                      Period 1
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="checkbox-purple" type="checkbox" value="" id="checkboxPeriod2" v-model="showPeriod2" @change="countPeriodColumns(showPeriod2)">
                    <label class="inline-block cursor-pointer" for="checkboxPeriod2">
                      Period 2
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="checkbox-purple" type="checkbox" value="" id="checkboxPeriod3" v-model="showPeriod3" @change="countPeriodColumns(showPeriod3)">
                    <label class="inline-block cursor-pointer" for="checkboxPeriod3">
                      Period 3
                    </label>
                  </div>                  
                </div>
                <div class="w-1/2 mb-2">
                  <div class="form-check">
                    <input class="checkbox-purple" type="checkbox" value="" id="checkboxPeriod4" v-model="showPeriod4" @change="countPeriodColumns(showPeriod4)">
                    <label class="inline-block cursor-pointer" for="checkboxPeriod4">
                      Period 4
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="checkbox-purple" type="checkbox" value="" id="checkboxPeriod5" v-model="showPeriod5" @change="countPeriodColumns(showPeriod5)">
                    <label class="inline-block cursor-pointer" for="checkboxPeriod5">
                      Period 5
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="checkbox-purple" type="checkbox" value="" id="checkboxPeriod6" v-model="showPeriod6" @change="countPeriodColumns(showPeriod6)">
                    <label class="inline-block cursor-pointer" for="checkboxPeriod6">
                      Period 6
                    </label>
                  </div>                   
                </div>
              </div>
            </div>
          </div>
          <div class="w-full mb-4">
            <div class="pb-2 mb-2 border-b border-gray-200">
              <span class="text-gray-800 font-medium text-sm">Sort By</span>
            </div>
            <div class="w-full mb-2">
              <label for="sort_field" class="label-control">Field</label>
              <MultiSelect id="sort_field" v-model="sortField" :options="sortFields" :value="sortField" :searchable="true" track-by="field" label="name" :allowEmpty="false" placeholder="Type to Search" :showLabels="false">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>                 
            </div>
            <div class="w-full">
              <label for="sort_option" class="label-control">Option</label>
              <MultiSelect id="sort_option" v-model="sortOption" :options="sortOptions" :value="sortOption" track-by="field" label="name" :allowEmpty="false">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>                 
            </div>
          </div>          
          <!-- ACTION BUTTON -->
          <div class="w-full md:flex gap-2">
            <button :disabled="isLoading" @click.prevent="runFilter()" type="button" class="btn btn--primary" alt="Apply" v-tooltip="'Apply Data Filters'">
              <span v-if="isLoading" class="block">
                Loading...
              </span>
              <span :class="{ 'hidden' : isLoading }">Apply</span>
            </button>
            <button :disabled="isLoading" type="button" class="ml-1 sm:-pt-10 btn btn--secondary" @click="clearInput()">
              <span>Clear</span>           
            </button>
          </div>                    
        </div>
      </div>            
    </div>
    <div class="fixed top-0 bottom-0 right-0 z-20">
      <div class="absolute w-auto right-0 top-40 bg-green-500 text-white rounded-l-md shadow-lg" :class="{ 'hidden': showFilter }">
        <button class="block text-sm ml-1 px-4 py-4 cursor-pointer" v-tooltip="'Show Data Filters'" @click="toggleFilter">
          <FilterIcon></FilterIcon>
        </button>           
      </div>
    </div>
    <MaterialAnalysisModal ref="resultModal"/>             
  </div>
</template>

<script>
import format from '@/helpers/formatNumber'
import seasonServices from '@/services/season/seasonServices'
import calculationServices from '@/services/calculation/calculationServices'
import supplierServices from '@/services/supplier/supplierServices'
import collectionServices from '@/services/collection/collectionServices'
import DownloadIcon from '../../icons/DownloadIcon'
import Loading from '../../icons/Loading'
import DateRange from '../../icons/DateRange'
import BackIcon from '../../icons/BackIcon'
import FilterIcon from '../../icons/Filter2Icon'
import MaterialAnalysisModal from '../materialAnalysis/Modal'

export default {
  name: 'ShowCalculation',
  components: {
    DownloadIcon,
    Loading,
    DateRange,
    BackIcon,
    FilterIcon,
    MaterialAnalysisModal
  },
  data() {
    return {
      activeTab: '2',
      season: '',
      nextSeason: '',
      seasons: [],
      periodDate1: {
        start: '',
        end: ''
      },
      periodDate2: {
        start: '',
        end: ''
      },
      periodDate3: {
        start: '',
        end: ''
      },
      periodDate4: {
        start: '',
        end: ''
      },
      periodDate5: {
        start: '',
        end: ''
      },
      periodDate6: {
        start: '',
        end: ''
      },                  
      masks: {
        input: 'YYYY/MM/DD',
      },
      attrs: [
        {
          key: 'today',
          highlight: 'red',
          dates: new Date(),
        },
      ],
      details: [],
      supplier: '',
      suppliers: [],
      collection: '',
      collections: [],
      showPeriod1: true,
      showPeriod2: true,
      showPeriod3: true,
      showPeriod4: true,
      showPeriod5: true,
      showPeriod6: true,
      sortField: { field: 'id', name: 'ID (Default)' },
      sortFields: [
        { field: 'initial_stock', name: 'Initial Stock' },
        { field: 'on_hand', name: 'Moving Stock' },
        { field: 'need_1', name: 'Needs Period 1' },
        { field: 'to_buy_1', name: 'To Buy Period 1' },
        { field: 'need_2', name: 'Needs Period 2' },
        { field: 'to_buy_2', name: 'To Buy Period 2' },
        { field: 'need_3', name: 'Needs Period 3' },
        { field: 'to_buy_3', name: 'To Buy Period 3' },
        { field: 'need_4', name: 'Needs Period 4' },
        { field: 'to_buy_4', name: 'To Buy Period 4' },
        { field: 'need_5', name: 'Needs Period 5' },
        { field: 'to_buy_5', name: 'To Buy Period 5' },
        { field: 'need_6', name: 'Needs Period 6' },
        { field: 'to_buy_6', name: 'To Buy Period 6' },                                      
        { field: 'id', name: 'ID (Default)' }
      ],        
      sortOption: { field: 'asc', name: 'Ascending' },
      sortOptions: [
        { field: 'asc', name: 'Ascending' },
        { field: 'desc', name: 'Descending' }
      ],
      totalColumns: 64,
      periodColumns: 9,
      awaitingSearch: false,      
      error: [],
      minDate2: new Date(),
      minDate3: new Date(),
      minDate4: new Date(),
      minDate5: new Date(), 
      minDate6: new Date(),
      showFilter: false,
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async fetchSeasonData() {
      try {
        const response = await seasonServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.seasons = []
          records.forEach(element => {
            this.seasons.push({
              'id': element.id,
              'name': element.season_name
            })
          });          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchSupplierData() {
      try {
        const response = await supplierServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.suppliers = []
          records.forEach(element => {
            this.suppliers.push({
              'id': element.id,
              'name': element.supplier_name
            })
          });
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },    
    async fetchCollectionData() {
      try {
        const response = await collectionServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.collections = []
          records.forEach(element => {
            this.collections.push({
              'id': element.id,
              'name': element.collection_name
            })
          });
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },    
    async fetchData() {
      try {
        this.isLoading = true
        const id = this.$route.params.id
        const params = {
          supplier: this.supplier ? this.supplier.id : null,
          collection: this.collection ? this.collection.name : null,
          period1: this.showPeriod1,
          period2: this.showPeriod2,
          period3: this.showPeriod3,
          period4: this.showPeriod4,
          period5: this.showPeriod5,
          period6: this.showPeriod6,
          sort_field: this.sortField.field,
          sort_option: this.sortOption.field
        }        
        const response = await calculationServices.fetchById(params, id)
        if (response.data.status === 'success') {
          this.isLoading =false

          const calculation = response.data.data
          this.season = { id: calculation.season_id, name: calculation.season.season_name }
          this.nextSeason = calculation.next_season_id ? { id: calculation.next_season_id, name: calculation.next_season.season_name } : ''
          this.periodDate1.start = calculation.period_1_start
          this.periodDate1.end = calculation.period_1_end
          this.periodDate2.start = calculation.period_2_start
          this.periodDate2.end = calculation.period_2_end
          this.periodDate3.start = calculation.period_3_start
          this.periodDate3.end = calculation.period_3_end
          this.periodDate4.start = calculation.period_4_start
          this.periodDate4.end = calculation.period_4_end
          this.periodDate5.start = calculation.period_5_start
          this.periodDate5.end = calculation.period_5_end
          this.periodDate6.start = calculation.period_6_start
          this.periodDate6.end = calculation.period_6_end                       
          this.details = calculation.details
        } else {
          this.isLoading =false

          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)       
        }
      } catch (error) {
        this.isLoading =false
        console.log(error)
      }
    },
    openDownloadTab(key) {
      let baseUrl = window.location.origin
      window.open(baseUrl+'/auth/download?key='+key, '_blank')
    },        
    async downloadCalculationFile() {
      try {
        this.isLoading = true

        const id = this.$route.params.id
        const params = {
          supplier: this.supplier ? this.supplier.id : null,
          collection: this.collection ? this.collection.name : null,
          period1: this.showPeriod1,
          period2: this.showPeriod2,
          period3: this.showPeriod3,
          period4: this.showPeriod4,
          period5: this.showPeriod5,
          period6: this.showPeriod6,
          sort_field: this.sortField.field,
          sort_option: this.sortOption.field
        }        
        const response = await calculationServices.export(params, id)
        if (response.data.status === 'success') {
          this.isLoading = false
          this.$toasted.success(response.data.message, this.toastedOptions)

          const key = response.data.data
          this.openDownloadTab(key)
        } else {
          this.isLoading =false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.season || responseReturn.period_1_start || responseReturn.period_1_end || responseReturn.period_2_start 
              || responseReturn.period_2_end || responseReturn.period_3_start || responseReturn.period_3_end || responseReturn.period_4_start 
              || responseReturn.period_4_end || responseReturn.period_5_start || responseReturn.period_5_end || responseReturn.period_6_start || responseReturn.period_6_end) {
              this.error = response.data.message
              this.$toasted.error('Please fill the require parameters', this.toastedOptions)
          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }
        }
      } catch (error) {
        this.isLoading = false
        console.log(error)
      }
    },    
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    },
    onlyNumber(num) {
      let result = format.onlyNumber(num)
      return result
    },
    goBack() {
      window.history.length > 1 ? this.$router.back() : this.$router.push('/calculations')
    },
    clearInput() {
      this.supplier = ''
      this.collection = ''
      this.runFilter()
    },
    toggleFilter() {
      this.showFilter = !this.showFilter
    },
    runFilter() {
      this.fetchData()
    },
    countPeriodColumns(status) {
      if (status === true) {
        this.totalColumns = this.totalColumns + this.periodColumns
      } else {
        this.totalColumns = this.totalColumns - this.periodColumns
      }
      console.log(this.totalColumns)
    },
    toggleModal(id) {
      this.$refs.resultModal.toggleModal(id)
    }    
  },
  mounted() {
    this.fetchSeasonData()
    this.fetchSupplierData()
    this.fetchCollectionData()
    this.fetchData()
  }
}
</script>