import axios from '@/axios'

export default { 
  fetchById(id){
    return axios.get(`/default-variable/${id}`)
  },    
  save(payload){
    return axios.post(`/default-variable`, payload)
  },
  delete(id){
    return axios.delete(`/default-variable/${id}`)
  }
}