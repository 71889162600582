import ListActivity from '../components/feature/activityLog/List'

const routes = [
  {
    path: '/activity-logs',
    name: 'activityLog',
    component: ListActivity,
    meta: {
      title: 'Activity Logs',
      requiresAuth: true
    }    
  } 
]

export default routes