<template>
  <div>
    <button type="button" class="btn btn--danger ml-2" @click="toggleModal">
      <UploadIcon>
        <span class="ml-2 md:block hidden">Import</span>
      </UploadIcon>
    </button>
    <!-- Import Modal Dialog -->
    <modal :show="showModal" @close="showModal = false" addClass="modal-xl" modalOrientation="py-16 md:py-10">
      <template v-slot:header><h3>{{ modalTitle }}</h3></template>
      <template v-slot:body>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form id="importForm" @submit.prevent="handleSubmit(importAdjustment)">
            <div class="md:flex gap-2">
              <div class="md:w-4/12 mb-4">
                <ValidationProvider name="Factory" rules="required" v-slot="{ errors }">
                  <MultiSelect id="factory" v-model="factory" :options="factories" :value="factory" :searchable="true" track-by="id" label="name" placeholder="Type to Search" :showLabels="false">
                    <template slot="caret">
                      <div>
                        <div class="multiselect__select">
                          <span>
                            <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                              <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </template>
                  </MultiSelect>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="error.factory" class="text-sm text-red-600"><span>{{ error.factory[0] }}</span></div>              
              </div>              
              <div class="md:w-6/12 mb-4">
                <ValidationProvider name="Type" rules="required" v-slot="{ errors }">
                  <MultiSelect id="type" v-model="type" :options="types" :value="type" :searchable="true" track-by="id" label="name" placeholder="Type to Search" :showLabels="false">
                    <template slot="caret">
                      <div>
                        <div class="multiselect__select">
                          <span>
                            <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                              <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </template>
                  </MultiSelect>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="error.type" class="text-sm text-red-600"><span>{{ error.type[0] }}</span></div>              
              </div>              
              <div class="md:w-2/12 mb-4">
                <ValidationProvider name="Effective Date" rules="required" v-slot="{ errors }">
                  <v-date-picker v-model="effectiveDate" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs">
                    <template v-slot="{ inputValue, inputEvents }">
                      <div class="md:flex gap-6">
                        <div class="w-full">
                          <div class="relative flex justify-between items-center">
                            <input id="effective_date" type="text" class="form-control" :value="inputValue" v-on="inputEvents">
                            <span class="h-full absolute pointer-events-none right-0">
                              <DateRange class="m-3" />
                            </span>                      
                          </div>
                          <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </template>
                  </v-date-picker>
                </ValidationProvider>
                <div v-if="error.effective_date" class="text-sm text-red-600"><span>{{ error.effective_date[0] }}</span></div>            
              </div>
            </div>
            <div class="md:flex gap-2">
              <div class="md:w-2/12 mb-4">
                <ValidationProvider name="Season" rules="required" v-slot="{ errors }">
                  <MultiSelect id="season" v-model="season" :options="seasons" :value="season" track-by="id" label="name" :showLabels="false">
                    <template slot="caret">
                      <div>
                        <div class="multiselect__select">
                          <span>
                            <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                              <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </template>
                  </MultiSelect>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="error.season" class="text-sm text-red-600"><span>{{ error.season[0] }}</span></div>
              </div>
              <div class="md:w-2/12 mb-4">
                <ValidationProvider name="Period" rules="required" v-slot="{ errors }">
                  <MultiSelect id="period" v-model="period" :options="periods" :value="period" track-by="id" label="name" :showLabels="false">
                    <template slot="caret">
                      <div>
                        <div class="multiselect__select">
                          <span>
                            <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                              <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </template>
                  </MultiSelect>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="error.period" class="text-sm text-red-600"><span>{{ error.period[0] }}</span></div>
              </div>              
              <div class="md:w-5/12 mb-4">
                <ValidationProvider name="File" rules="required" ref="provider" v-slot="{ validate, errors }">
                  <input id="file" ref="file" type="file" class="form-control" @change="onFileChange" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="error.file" class="text-sm text-red-600"><span>{{ error.file[0] }}</span></div>
              </div>
              <div class="md:w-3/12 mb-4">
                <button :disabled="isLoading" type="submit" form="importForm" class="btn-md btn--primary">
                  <span v-if="isLoading">Loading...</span>
                  <span v-if="!isLoading">Upload File</span>
                </button>
                <div class="relative inline-block text-left">
                  <div>
                    <button type="button" class="inline-flex btn-md btn--success ml-2" @click="toggleButton()">
                      <span v-if="isDownloadLoading">Loading...</span>
                      <span v-if="!isDownloadLoading">Export Draft</span>                      
                      <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                      </svg>
                    </button>
                  </div>
                  <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                    <div v-if="expandButton" class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div class="py-1" role="none">
                        <ul>
                          <li v-for="(collection, index) in collections" :key="index" class="hover:bg-gray-100">
                            <button type="button" class="text-gray-700 hover:text-gray-900 w-full block px-4 py-2 text-sm text-left" @click="downloadFile(collection.name)">{{ collection.name }}</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
            </div>
          </form> 
        </ValidationObserver>
        <div v-if="datas.length > 0" class="mb-4 bg-white shadow-lg rounded-lg overflow-y-auto">
          <table>
            <thead>
              <tr>
                <th scope="col" class="px-3 text-left">Supplier</th>
                <th scope="col" class="px-3 text-left">Reference</th>
                <th scope="col" class="px-3 text-left">Color</th>
                <th scope="col" class="px-3 text-left">Size</th>
                <th scope="col" class="px-3 text-left">Qty</th>
                <th scope="col" class="px-3 text-left">Unit</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, key) in datas" :key="key" :class="{ 'bg-gray-50' : (key % 2 !== 0) }">
                <td class="px-3 text-left">{{ data.supplier }}</td>
                <td class="px-3 text-left">{{ data.description }}</td>
                <td class="px-3 text-left">{{ data.color }}</td>
                <td class="px-3 text-left">{{ data.size }}</td>
                <td class="px-3 text-left">{{ data.qty }}</td>
                <td class="px-3 text-left">{{ data.unit }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template v-slot:footer>     
      </template> 
    </modal>
  </div>
</template>

<script>
import factoryServices from '@/services/factory/factoryServices'
import adjustmentServices from '@/services/adjustment/adjustmentServices'
import adjustmentReasonServices from '@/services/adjustment/adjustmentReasonServices'
import collectionServices from '@/services/collection/collectionServices'
import seasonServices from '@/services/season/seasonServices'
import Modal from '../../widgets/Modal'
import UploadIcon from '../../icons/UploadIcon'
import DateRange from '../../icons/DateRange'

export default {
  name: 'ImportAdjustment',
  components: {
    Modal,
    UploadIcon,
    DateRange
  },
  data() {
    return {
      factory: '',
      factories: [],
      type: '',
      types: [],
      effectiveDate: '',
      season: '',
      seasons: [],
      period: '',
      periods: [
        { 'id': 1, 'name': 'Period 1' }, { 'id': 2, 'name': 'Period 2' }, { 'id': 3, 'name': 'Period 3' },
        { 'id': 4, 'name': 'Period 4' }, { 'id': 5, 'name': 'Period 5' }, { 'id': 6, 'name': 'Period 6' }
      ],
      masks: {
        input: 'YYYY-MM-DD',
      },
      attrs: [
        {
          key: 'today',
          highlight: 'red',
          dates: new Date(),
        },
      ],
      collection: '',
      collections: [],
      file: null,
      datas: [],
      error: [],
      isLoading: false,
      expandButton: false,
      isDownloadLoading: false, 
      modalTitle: '',     
      showModal: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }         
    }
  },
  methods: {
    async fetchReasonData() {
      try {
        const response = await adjustmentReasonServices.fetchAll(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.types = []
          records.forEach(element => {
            this.types.push({
              'id': element.id,
              'name': element.description
            })
          });          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchFactoryData() {
      try {
        const response = await factoryServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.factories = []
          records.forEach(element => {
            this.factories.push({
              'id': element.id,
              'name': element.factory_name
            })
          });          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchCollectionData() {
      try {
        const response = await collectionServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.collections = []
          records.forEach(element => {
            this.collections.push({
              'id': element.id,
              'name': element.collection_name,
              'code': element.collection_code
            })
          });          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchSeasonData() {
      try {
        const response = await seasonServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.seasons = []
          records.forEach(element => {
            this.seasons.push({
              'id': element.id,
              'name': element.season_name
            })
          });          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },    
    async importAdjustment() {
      try {
        this.isLoading = true
        const date = this.effectiveDate ? (new Date(this.effectiveDate)).toISOString() : ''
        let payload = new FormData()
        payload.append('location', this.factory.id ?? '')
        payload.append('type', this.type.id ?? '')
        payload.append('date', date)
        payload.append('season', this.season ? this.season.id : '')
        payload.append('period', this.period ? this.period.id : '')
        payload.append('file', this.file)

        const response = await adjustmentServices.import(payload)
        if (response.data.status === 'success') {
          this.isLoading = false

          /* REFRESH ORDER LIST */
          this.fetchData()           

          /* THROW SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)           
        } else {
          this.isLoading = false

          /* STORE ERRORS DATA TO DATA VARIABLE */
          this.datas = response.data.data;

          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)            
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions) 
      }
    },
    async onFileChange(e) {
      const { valid } = await this.$refs.provider.validate(e)
      if (valid) {
        let files = e.target.files || e.dataTransfer.files
        if (!files.length) {
          return
        }

        this.file = this.$refs.file.files[0]
        this.datas = []
      }
    },
    toggleModal() {
      this.$refs.file.value = null
      this.datas = []
      this.showModal = true
      this.modalTitle = 'Import'
      this.fetchReasonData()
      this.fetchFactoryData()
      this.fetchCollectionData()
      this.fetchSeasonData()
    },
    toggleButton() {
      this.expandButton = !this.expandButton
    },
    hideToggleButton() {
      this.expandButton = false
    },    
    fetchData() {
      this.$root.$emit('fetchData')
    },
    openDownloadTab(key) {
      let baseUrl = window.location.origin
      window.open(baseUrl+'/auth/download?key='+key, '_blank')
    },    
    async downloadFile(name) {
      this.toggleButton()
      try {
        this.isLoading = true
        const params = {
          take: '',
          page: '',
          search: name || '',
          date_field: '',
          start: '',
          end: '',
          sort_field: 'material_name',
          sort_option: 'asc'          
        }
        const response = await adjustmentServices.exportDraft(params)
        if (response.data.status === 'success') {
          this.isLoading = false
          this.$toasted.success(response.data.message, this.toastedOptions)

          const key = response.data.data
          this.openDownloadTab(key)
        } else {
          this.isLoading = false
          this.$toasted.error(response.data.message, this.toastedOptions)
        }
      } catch (error) {
        this.isLoading = false
        console.log(error)
      }
    }     
  }
}
</script>