<template>
  <div>
    <!-- ACTION PANEL SECTION -->
    <div class="flex border-b border-gray-200 text-gray-800 justify-between items-center mb-4 pb-2">
      <div class="md:w-1/2 w-full items-center">
        <h2 class="text-2xl font-medium">Activity Logs</h2>
      </div>
      <div class="flex items-center right-0">
      </div>
    </div>
    <!-- TABLE FILTER SECTION -->
    <div class="flex pb-4 justify-between items-center">
      <div class="relative">
        <select v-model="take" @change="updateTake()" class="px-4 py-2 pr-8 appearance-none rounded-md shadow-lg text-sm bg-white focus:bg-white text-black placeholder-gray-500 border border-gray-200 focus:border-purple-500 focus:outline-none">
          <option v-for="(show, index) in shows" :key="index" :value="show.number" :selected="show.number === take ? 'selected' : ''">{{ show.name }}</option>
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
          <svg class="w-5 h-5 fill-current" viewBox="0 0 24 24">
          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z" />
          </svg>
        </div>
      </div>
      <div class="flex w-96">
        <div class="relative flex-1">
          <svg width="20" height="20" fill="currentColor" class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"><path fill-rule="evenodd" clip-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"></path></svg>
          <input type="text" ref="search" v-model="search" @input="runSearch()" aria-label="Search" placeholder="Search ( Press &quot;/&quot; to focus )" class="w-full text-sm text-black placeholder-gray-500 border border-gray-200 rounded-md py-2 pl-10 focus:border-purple-500 focus:outline-none">  
        </div>       
      </div>
    </div>
    <!-- TABLE SECTION -->
    <div class="mb-4 bg-white shadow-lg rounded-lg overflow-y-auto">
      <table>
        <thead>
          <tr>
            <th scope="col" class="px-3 w-28 text-left">Created At</th>
            <th scope="col" class="px-3 w-32 text-left">Created Time</th>
            <th scope="col" class="px-3 w-28 text-left">User</th>
            <th scope="col" class="px-3 text-left">Description</th>
            <th scope="col" class="px-3 text-left">Agent</th>
            <th scope="col" class="px-3 w-28 text-left">IP Address</th>
            <th scope="col" class="px-3 text-center">Actions</th>
          </tr>
        </thead>
        <tbody class="text-gray-600 text-xs font-light">
          <tr v-if="activityLogs.length === 0" class="border-b border-gray-200 hover:bg-gray-100"><td class="px-3 text-center" colspan="11">No data to display</td></tr>
          <tr v-else-if="totalFiltered === 0" class="border-b border-gray-200 hover:bg-gray-100"><td class="px-3 text-center" colspan="11">No matching records found</td></tr>
          <tr v-for="(activity, index) in activityLogs" :key="activity.id" class="border-b border-gray-200 hover:bg-gray-100" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">
            <td class="px-3 py-2 text-left">{{ activity.activity_date }}</td>
            <td class="px-3 py-2 text-left">{{ activity.activity_time }} UTC</td>
            <td class="px-3 py-2 text-left">{{ activity.user_id ? activity.user_id + ' - ' + activity.user.name : '' }}</td>
            <td class="px-3 py-2 text-left uppercase">
              {{ activity.description }}
              <span class="font-medium">({{ activity.module }})</span>
            </td>
            <td class="px-3 py-2 text-left">{{ activity.user_agent }}</td>
            <td class="px-3 py-2 text-left">{{ activity.ip_address }}</td>
            <td class="px-3 py-2 text-center">
              <div class="flex item-center justify-center">            
                <button @click="toggleModal(activity.id)" type="button" class="btn btn-xs btn--primary" alt="View Details" v-tooltip="'View Details'">
                  <TerminalIcon />
                </button>
              </div>
            </td>
          </tr>
        </tbody>                              
      </table>
      <div class="flex px-5 py-5 bg-white justify-between items-center">
        <div class="flex-shrink text-sm">
          <span>Showing {{ fromRecord }} to {{ toRecord }} of {{ totalFiltered }} Entries </span><span v-if="totalFiltered !== totalRecords"> ( filtered from {{ totalRecords }} total entries )</span>
        </div>
        <div class="pagination">
          <button type="button" :disabled="(currentPage - 1) === 0" @click="prevPage" class="page-item">
            <span class="text-sm">Prev</span>
          </button>
          <button type="button" :disabled="currentPage === lastPage || currentPage > lastPage" @click="nextPage" class="page-item">
            <span class="text-sm">Next</span>
          </button>
        </div>
      </div>                            
    </div>
    <!-- REPORT FILTER & ACTIONS -->
    <div class="fixed bg-gray-800 bg-opacity-25 w-full h-full inset-0 top-16 md:top-0 z-50" :class="{ 'hidden': !showFilter }">
      <div class="w-80 ml-auto h-full overflow-y-scroll bg-white shadow-lg right-0">
        <div class="flex p-5 border-b border-gray-200 items-center">
          <span class="flex-1 text-sm font-medium">Filters & Sort</span>
          <button type="button" class="flex right-0" @click="toggleFilter">
            <svg class="h-5 w-5 fill-current hover:text-gray-700" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
            </svg>
          </button>
        </div>
        <div class="p-5 items-center">
          <!-- LOCATION FILTER REPORT -->
          <div class="w-full mb-2">
            <div class="w-full mb-2">
              <label for="module" class="label-control">Module</label>
              <MultiSelect id="module" ref="module" v-model="moduleName" :options="moduleLists" :value="moduleName" :searchable="true" :showLabels="false" placeholder="Tap to Search">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>              
            </div>            
            <div class="w-full mb-2">
              <label for="event" class="label-control">Event</label>
              <MultiSelect id="event" ref="event" v-model="event" :options="events" :value="event" :searchable="true" track-by="id" label="name" :showLabels="false" placeholder="Tap to Search">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>              
            </div>
            <div class="w-full mb-2">
              <label for="user" class="label-control">User</label>
              <MultiSelect id="user" ref="user" v-model="user" :options="users" :value="user" :searchable="true" track-by="id" label="name" :showLabels="false" placeholder="Tap to Search">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>              
            </div>        
            <v-date-picker ref="calendar" v-model="filterDate" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs" is-range>
              <template v-slot="{ inputValue, inputEvents, isDragging }">
                <div class="flex gap-2">
                  <div class="w-1/2 mb-2">                        
                    <label for="startDate" class="label-control">Start Date</label>
                    <div class="relative flex justify-between items-center">
                      <input id="startDate" ref="startDate" type="text" class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" autofocus>
                      <span class="h-full absolute pointer-events-none right-0">
                        <DateRange class="m-3" />
                      </span>                      
                    </div>
                  </div>
                  <div class="w-1/2 mb-2">
                    <label for="endDate" class="label-control">End Date</label>
                    <div class="relative flex justify-between items-center">
                      <input id="endDate" ref="endDate" type="text" class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end">
                      <span class="h-full absolute pointer-events-none right-0">
                        <DateRange class="m-3" />
                      </span>                      
                    </div>
                  </div>                  
                </div>
              </template>
            </v-date-picker>              
          </div>
          <div class="w-full mb-4">
            <div class="pb-2 mb-2 border-b border-gray-200">
              <span class="text-gray-800 font-medium text-sm">Sort By</span>
            </div>
            <div class="w-full mb-2">
              <label for="sort_field" class="label-control">Field</label>
              <MultiSelect id="sort_field" v-model="sortField" :options="sortFields" :value="sortField" :searchable="true" track-by="field" label="name" :allowEmpty="false" placeholder="Type to Search">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>                 
            </div>
            <div class="w-full">
              <label for="sort_option" class="label-control">Option</label>
              <MultiSelect id="sort_option" v-model="sortOption" :options="sortOptions" :value="sortOption" track-by="field" label="name" :allowEmpty="false">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>                 
            </div>
          </div>
          <!-- ACTION BUTTON -->
          <div class="w-full md:flex gap-2">
            <button :disabled="isLoading" @click.prevent="runFilter()" type="button" class="btn btn--primary" alt="Apply" v-tooltip="'Apply Data Filters'">
              <span v-if="isLoading" class="block">
                Loading...
              </span>
              <span :class="{ 'hidden' : isLoading }">Apply</span>
            </button>
            <button :disabled="isLoading" type="button" class="ml-1 sm:-pt-10 btn btn--secondary" @click="clearInput()">
              <span>Clear</span>           
            </button>
          </div>                    
        </div>
      </div>            
    </div>
    <div class="fixed top-0 bottom-0 right-0 z-20">
      <div class="absolute w-auto right-0 top-40 bg-green-500 text-white rounded-l-md shadow-lg" :class="{ 'hidden': showFilter }">
        <button class="block text-sm ml-1 px-2 py-4 cursor-pointer" v-tooltip="'Show Data Filters'" @click="toggleFilter">
          <FilterIcon></FilterIcon>
        </button>           
      </div>
    </div>
    <DetailModal ref="detailModal"/>
  </div>
</template>

<script>
import format from '@/helpers/formatNumber'
import userServices from '@/services/user/userServices'
import activityLogServices from '@/services/activityLog/activityLogServices'
import TerminalIcon from '../../icons/TerminalIcon'
import FilterIcon from '../../icons/Filter2Icon'
import DateRange from '../../icons/DateRange'
import DetailModal from '../activityLog/Modal'

export default {
  name: 'ListActivityLog',
  components: {
    TerminalIcon,
    FilterIcon,
    DateRange,
    DetailModal
  },
  data() {
    return {
      take: 25,
      page: 1,
      search: null,
      moduleName: '',
      moduleLists: [
        'SUPPLIERS', 'UNITS', 'SEASONS', 'COLLECTIONS', 'FACTORY', 'RAW MATERIALS', 'PRODUCTS', 'ORDERS', 'MATERIAL CALCULATIONS',
        'PURCHASE ORDER', 'RECEIVING', 'ADJUSTMENTS', 'TRANSFER STOCK', 'STOCK OPNAME', 'DEFAULT VARIABLE', 'EXCHANGE RATE',
        'ADJUSTMENT REASONS', 'USERS'
      ],      
      event: '',
      events: [
        { id: 'created', name: 'Create' },
        { id: 'updated', name: 'Update' },
        { id: 'deleted', name: 'Delete' },
        { id: 'login', name: 'Login' },
        { id: 'logout', name: 'Logout' }
      ],
      user: '',
      users: [],
      dateField: { field: 'created_at', name: 'Created At' },
      filterDate: {
        start: '',
        end: ''
      },
      masks: {
        input: 'YYYY/MM/DD',
      },
      attrs: [
        {
          key: 'today',
          highlight: 'red',
          dates: new Date(),
        },
      ],
      sortField: { field: 'created_at', name: 'Created (Default)' },
      sortFields: [
        { field: 'event', name: 'Event' },
        { field: 'description', name: 'Description' },
        { field: 'user_id', name: 'User' },
        { field: 'user_agent', name: 'Agent' },
        { field: 'ip_address', name: 'IP Address' },
        { field: 'created_at', name: 'Created (Default)' },
        { field: 'id', name: 'ID' }
      ],        
      sortOption: { field: 'desc', name: 'Descending' },
      sortOptions: [
        { field: 'asc', name: 'Ascending' },
        { field: 'desc', name: 'Descending' }
      ],
      awaitingSearch: false,
      totalRecords: null,
      totalFiltered: null,
      fromRecord: null,
      toRecord: null,
      currentPage: null,
      lastPage: null,
      shows: [],
      activityLogs: [],
      error: [],
      showFilter: false,
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async fetchUserData() {
      try {
        const response = await userServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.users = []
          records.forEach(element => {
            this.users.push({
              'id': element.id,
              'name': element.name
            })
          });
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchUserById() {
      try {
        const id = this.userField ? this.userField : ''
        const response = await userServices.fetchById(id)
        if (response.data.status === 'success') {
          const record = response.data.data
          this.user = { 'id': record.id, 'name': record.name }
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },        
    async fetchData() {
      try {
        this.isLoading = true

        const params = {
          take: this.take,
          page: this.page,
          search: this.search,
          module: this.moduleName || '',
          event: this.event ? this.event.id : '',
          user: this.userField ? this.userField : '',          
          date_field: this.dateField,
          start: this.filterDate.start || '',
          end: this.filterDate.end || '',
          sort_field: this.sortField.field,
          sort_option: this.sortOption.field
        }
        const response = await activityLogServices.fetchLimit(params)
        if (response.data.status === 'success') {
          this.isLoading =false

          const records = response.data.data
          this.totalRecords = records.total
          this.totalFiltered = records.total_filter
          this.fromRecord = records.from
          this.toRecord = records.to
          this.currentPage = records.current_page
          this.lastPage = records.last_page
          this.shows = records.show
          this.activityLogs = records.data
        } else {
          this.isLoading =false

          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        this.isLoading =false
        console.log(error)
      }
    },
    updateQueryString() {
      const module = this.moduleName || ''
      const event = this.event ? this.event.id : ''
      const user = this.userField || ''
      const search = this.search ? this.search.toLowerCase() : ''
      const start = this.filterDate.start ? new Date(this.filterDate.start).toISOString().slice(0,10) : ''
      const end = this.filterDate.end ? new Date(this.filterDate.end).toISOString().slice(0,10) : ''
      const page = this.page

      this.$router.replace({ 
        query: {
          take: this.take,
          page: page,
          search: search,
          module: module,
          event: event,
          user: user,          
          date_field: this.dateField,
          start: start,
          end: end,
          sort_field: this.sortField.field,
          sort_option: this.sortOption.field
        } 
      }).catch(() => {})
    },
    clearInput() {
      this.moduleName = ''
      this.event = ''
      this.user = ''
      this.userField = ''
      this.filterDate = {}
      this.runFilter()
    },
    toggleFilter() {
      this.showFilter = !this.showFilter
    },
    runFilter() {
      this.page = 1
      this.updateQueryString()
      this.fetchData()
    },
    searchFocus(event) {
      if (event.keyCode === 191) {
        setTimeout(() => {
          this.$refs.search.focus()
        }, 5)
      }
    },
    prevPage() {
      this.page = this.currentPage - 1
      this.updateQueryString()
      this.fetchData()
    },
    nextPage() {
      this.page = this.currentPage + 1
      this.updateQueryString()
      this.fetchData()
    },
    updateTake() {
      this.page = 1
      this.updateQueryString()
      this.fetchData()      
    },
    runSearch() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.page = 1
          this.updateQueryString()
          this.fetchData()
          this.awaitingSearch = false
        }, 1500)
      }
      this.awaitingSearch = true
    },        
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    },
    toggleModal(id) {
      this.$refs.detailModal.toggleModal(id)
    }    
  },
  created() {
    this.fetchUserData()
    if (this.$route.query.module) {
      const query = this.$route.query.module
      const module = this.events.find(object => object === query)
      this.moduleName = module
    }     
    if (this.$route.query.event) {
      const eventId = this.$route.query.event
      const event = this.events.find(object => object.id === eventId)
      this.event = { 'id': event.id, 'name': event.name }
    }    
    if (this.$route.query.user) {
      this.userField = this.$route.query.user
      this.fetchUserById()
    }    
    this.fetchData()
    document.addEventListener("keydown", this.searchFocus);
  },
  destroyed() {
    document.removeEventListener("keydown", this.searchFocus);
  },
  watch: {
    moduleName: function() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.updateQueryString()
          this.awaitingSearch = false
        }, 1000)
      }
      this.awaitingSearch = true
    },    
    event: function() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.updateQueryString()
          this.awaitingSearch = false
        }, 1000)
      }
      this.awaitingSearch = true
    },    
    user: function() {
      this.userField = this.user ? this.user.id : ''
    },
    userField: function() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.updateQueryString()
          this.awaitingSearch = false
        }, 1000)
      }
      this.awaitingSearch = true
    },        
    '$route.query.take': {
      handler: function(take) {
        if (take) {
          this.take = take
        }
      },
      immediate: true
    },    
    '$route.query.page': {
      handler: function(page) {
        this.page = page
      },
      immediate: true
    },
    '$route.query.search': {
      handler: function(search) {
        this.search = search
      },
      immediate: true
    },
    '$route.query.date_field': {
      handler: function(date_field) {
        this.dateField = date_field
      },
      immediate: true
    },
    '$route.query.start': {
      handler: function(start) {
        this.filterDate.start = start
      },
      immediate: true
    },
    '$route.query.end': {
      handler: function(end) {
        this.filterDate.end = end
      },
      immediate: true
    },
    '$route.query.sort_field': {
      handler: function(sort_field) {
        try {
        const option = this.sortFields.filter(item => sort_field.includes(item.field))
        this.sortField = { field: option[0].field, name: option[0].name }          
        } catch (error) {
          this.sortField = { field: 'created_at', name: 'Created (Default)' }
        }
      },
      immediate: true
    },
    '$route.query.sort_option': {
      handler: function(sort_option) {
        try {
          const option = this.sortOptions.filter(item => sort_option.includes(item.field))
          this.sortOption = { field: option[0].field, name: option[0].name }          
        } catch (error) {
          this.sortOption = { field: 'desc', name: 'Descending' }
        }
      },
      immediate: true
    }                  
  },
  mounted() {
    const thisInstance = this
    this.$root.$on('fetchData', function(){
      thisInstance.fetchData()
    })           
  }  
}
</script>