<template>
  <div>
    <!-- CARD SECTION -->
    <div class="pb-5">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div v-for="(item, index) in productByCollection" :key="index" class="overflow-hidden flex rounded-lg shadow-lg transition duration-150 ease-in">
          <div class="flex-none w-32 relative">
            <img :src="item.image" class="absolute inset-0 w-full h-full object-cover">
          </div>
          <div class="flex-auto p-6 xl:p-8 text-white bg-gradient-to-br" :class="[ colors[index] ]">
            <h3 class="block text-2xl font-semibold">Products</h3>
            <span class="block text-sm h-16">{{ item.description }}</span>
            <h1 class="block text-4xl bottom-0">{{ formatNumber(toFixed(item.amount, 0)) }} <span class="text-lg">Items</span></h1>
          </div>
        </div>
      </div>
    </div>
    <!-- FORM SECTION -->
    <div class="pb-5">   
      <!-- TABLE FILTER SECTION -->
      <div class="flex pb-4 justify-between items-center">
        <div class="relative">
          <select v-model="take" @change="updateTake()" class="px-4 py-2 pr-8 appearance-none rounded-md shadow-lg text-sm bg-white focus:bg-white text-black placeholder-gray-500 border border-gray-200 focus:border-purple-500 focus:outline-none">
            <option v-for="show in shows" :key="show.number" :value="show.number" :selected="show.number === take ? 'selected' : ''">{{ show.name }}</option>
          </select>
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
            <svg class="w-5 h-5 fill-current" viewBox="0 0 24 24">
            <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z" />
            </svg>
          </div>
        </div>
        <div class="flex w-96">
          <div class="relative flex-1">
            <svg width="20" height="20" fill="currentColor" class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"><path fill-rule="evenodd" clip-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"></path></svg>
            <input type="text" ref="search" v-model="search" @input="runSearch()" aria-label="Search" placeholder="Search ( Press &quot;/&quot; to focus )" class="w-full text-sm text-black placeholder-gray-500 border border-gray-200 rounded-md py-2 pl-10 focus:border-purple-500 focus:outline-none">  
          </div>        
        </div>
      </div>      
      <!-- TABLE SECTION -->
      <div class="mb-4 bg-white shadow-lg rounded-lg overflow-y-auto">
        <table>
          <thead>
            <tr>
              <th scope="col" class="px-3 text-center">Type</th>
              <th scope="col" class="px-3 text-left">Supplier</th>
              <th scope="col" class="px-3 text-left">Description</th>
              <th scope="col" class="px-3 text-left">Color</th>
              <th scope="col" class="px-3 text-left">Size</th>
              <th scope="col" class="px-3 text-left">Unit</th>
              <th scope="col" class="px-3 text-center">Min Stock</th>
              <th scope="col" class="px-3 text-center">On Hand</th>
              <th scope="col" class="px-3 text-center">Stock Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="materials.length === 0" class="border-b border-gray-200"><td class="px-3 text-center" colspan="9">No data to display</td></tr>
            <tr v-else-if="totalFiltered === 0" class="border-b border-gray-200"><td class="px-3 text-center" colspan="9">No matching records found</td></tr>
            <tr v-for="(material, index) in materials" :key="material.id" class="border-b border-gray-200 hover:bg-gray-100" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">
              <td class="px-3 text-center">
                <span v-for="type in material.type" :key="type.id" class="block my-1 badge badge--danger">{{ type.code }}</span>
              </td>
              <td class="px-3 text-left">{{ material.supplier.supplier_name }}</td>
              <td class="px-3 text-left">{{ material.material_name }}</td>
              <td class="px-3 text-left">{{ material.color }}</td>
              <td class="px-3 text-left">{{ material.size }}</td>
              <td class="px-3 text-left">{{ material.unit }}</td>
              <td class="px-3 text-right">{{ formatNumber(toFixed(material.minimum_stock, 2)) }}</td>
              <td class="px-3 text-right">{{ material.stock > 0 ? formatNumber(toFixed(material.stock, 2)) : material.stock }}</td>
              <td class="px-3 text-center">
                <span class="badge badge--danger">Critical</span>
              </td>
            </tr>
          </tbody>                              
        </table>
        <div class="flex px-5 py-5 bg-white justify-between items-center">
          <div class="flex-shrink text-sm">
            <span>Showing {{ fromRecord }} to {{ toRecord }} of {{ totalFiltered }} Entries </span><span v-if="totalFiltered !== totalRecords"> ( filtered from {{ totalRecords }} total entries )</span>
          </div>
          <div class="pagination">
            <button type="button" :disabled="(currentPage - 1) === 0" @click="prevPage" class="page-item">
              <span class="text-sm">Prev</span>
            </button>
            <button type="button" :disabled="currentPage === lastPage || currentPage > lastPage" @click="nextPage" class="page-item">
              <span class="text-sm">Next</span>
            </button>
          </div>
        </div>                            
      </div>
    </div>
  </div>
</template>

<script>
import format from '@/helpers/formatNumber'
import dashboardServices from '@/services/dashboard/dashboardServices'

export default {
  name: 'Dashboard',
  data() {
    return {
      colors: ["from-purple-500 to-indigo-500", "from-pink-500 to-red-500", "from-yellow-400 to-yellow-600", "from-green-400 to-green-500", "from-blue-400 to-blue-500"],
      productByCollection: [],
      take: 25,
      page: 1,
      search: null,
      dateField: 'created_at',
      sortField: 'material_name',
      sortOption: 'asc',     
      awaitingSearch: false,
      totalRecords: null,
      totalFiltered: null,
      fromRecord: null,
      toRecord: null,
      currentPage: null,
      lastPage: null,
      shows: [],
      materials: [],
      error: [],
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async fetchProductByCollection(){
      try {
        const response = await dashboardServices.fetchProductByCollection()
        if (response.data.status === 'success') {
          this.productByCollection = response.data.data
        }       
      } catch (error) {
        console.log(error)
      }
    },
    async fetchCriticalMaterialData() {
      try {
        this.isLoading = true

        const params = {
          take: this.take,
          page: this.page,
          search: this.search,
          date_field: this.dateField,
          start: '',
          end: '',
          sort_field: this.sortField,
          sort_option: this.sortOption
        }
        const response = await dashboardServices.fetchCriticalMaterial(params)
        if (response.data.status === 'success') {
          this.isLoading =false

          const records = response.data.data
          this.totalRecords = records.total
          this.totalFiltered = records.total_filter
          this.fromRecord = records.from
          this.toRecord = records.to
          this.currentPage = records.current_page
          this.lastPage = records.last_page
          this.shows = records.show
          this.materials = records.data
        } else {
          this.isLoading =false

          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        this.isLoading =false
        console.log(error)
      }
    },
    searchFocus(event) {
      if (event.keyCode === 191) {
        setTimeout(() => {
          this.$refs.search.focus()
        }, 5)
      }
    },
    prevPage() {
      this.page = this.currentPage - 1
      this.fetchCriticalMaterialData()
    },
    nextPage() {
      this.page = this.currentPage + 1
      this.fetchCriticalMaterialData()
    },
    updateTake() {
      this.page = 1
      this.fetchCriticalMaterialData()      
    },
    runSearch() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.take = 25
          this.page = 1          
          this.fetchCriticalMaterialData()
          this.awaitingSearch = false
        }, 1500)
      }
      this.awaitingSearch = true
    },         
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    },       
  },
  mounted() {
    this.fetchProductByCollection()
    this.fetchCriticalMaterialData()
  },
  created() {
    document.addEventListener("keydown", this.searchFocus);
  },
  destroyed() {
    document.removeEventListener("keydown", this.searchFocus);
  }
}
</script>