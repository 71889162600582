import axios from '@/axios'

export default {
  fetchAll(params){
    return axios.get(`/calculations/all`, {params})
  },
  fetchLimit(params){
    return axios.get(`/calculations`, {params})
  },  
  fetchById(params, id){
    return axios.get(`/calculations/${id}`, {params})
  },    
  create(payload){
    return axios.post(`/calculations`, payload)
  },
  update(payload, id){
    return axios.put(`/calculations/${id}`, payload)
  },
  recalculate(payload, id){
    return axios.post(`/calculations/recalculate/${id}`, payload)
  },  
  delete(id){
    return axios.delete(`/calculations/${id}`)
  }, 
  export(params, id){
    return axios.post(`/calculations/export/${id}`, params)
  },
  lock(id){
    return axios.post(`/calculations/lock/${id}`)
  },
  unlock(id){
    return axios.post(`/calculations/unlock/${id}`)
  },    
}