<template>
  <div class="bg-white shadow-lg rounded-lg">
    <div class="p-10">
      <div class="pb-2 mb-4 border-b border-gray-200">
        <h2 class="text-gray-800 text-2xl font-medium">Show Supplier</h2>
      </div>
      <div class="md:flex gap-6">
        <div class="w-full mb-4">
          <label for="supplier_name" class="label-control">Supplier Name</label>
          <input id="supplier_name" ref="supplier_name" type="text" v-model="supplierName" maxlength="255" class="form-control" autofocus>            
        </div>
      </div>          
      <div class="md:flex gap-6">
        <div class="w-full mb-4">
          <label for="address" class="label-control">Address</label>
          <textarea id="address" v-model="address" class="form-control" rows="5"></textarea>                  
        </div>
      </div>
      <div class="md:flex gap-6">
        <div class="md:w-1/2 mb-4">
          <label for="postal_code" class="label-control">Postal Code</label>
          <input id="postal_code" type="text" v-model="postalCode" class="form-control">
        </div>
        <div class="md:w-1/2 mb-4">
          <label for="city" class="label-control">City</label>
          <input id="city" type="text" v-model="city" class="form-control">
        </div>
      </div>          
      <div class="md:flex gap-6">
        <div class="md:w-1/2 mb-4">
          <label for="country" class="label-control">Country</label>
          <input id="country" type="text" v-model="country" class="form-control">
        </div>
        <div class="md:w-1/2 mb-4"></div>
      </div>
      
      <!-- CALL SUPPLIER CONTACTS -->
      <Contacts class="mb-10" />

      <div class="md:flex gap-4 mt-4 mb-2">
        <button :disabled="isLoading" type="button" @click="goBack()" class="btn btn--secondary" alt="Back" title="Back">
          <BackIcon>
            <span class="ml-2 md:block hidden">Back</span>
          </BackIcon>              
        </button>
      </div>                                                  
    </div>    
  </div>
</template>

<script>
import supplierServices from '@/services/supplier/supplierServices'
import BackIcon from '../../icons/BackIcon'
import Contacts from './Contact'

export default {
  name: 'ShowSupplier',
  components: {
    BackIcon,
    Contacts
  },
  data(){
    return {
      supplierName: '',
      address: '',
      postalCode: '',
      city: '',
      country: '',
      record: [],
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async fetchData(){
      try {
        const id = this.$route.params.id
        const response = await supplierServices.fetchById(id)
        if (response.data.status === 'success') {
          this.record = response.data.data

          this.supplierName = this.record.supplier_name
          this.address = this.record.address
          this.postalCode = this.record.postal_code
          this.city = this.record.city
          this.country = this.record.country
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)            
        }
      } catch (error) {
        console.log(error)
      }
    },
    goBack() {
      window.history.length > 1 ? this.$router.back() : this.$router.push('/suppliers')
    }        
  },
  created(){
    this.fetchData()
  }
}
</script>