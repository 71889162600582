const state = {
  isHidden: true
}

const getters = {
  isHidden: state => state.isHidden
}

const actions = {
  async toggleSidebar({commit}, isHidden){
    commit('setSidebarVisible', isHidden)
  }
}

const mutations = {
  setSidebarVisible(state, isHidden) {
    state.isHidden = isHidden
  }
}

export default {
  state,
  getters,
  actions,
  mutations  
}