<template>
  <div class="w-full h-screen items-center">
    <div class="mx-auto w-full h-full">
      <div class="w-full text-center py-6">
        <h1 class="mt-12 text-xl md:text-xl font-bold leading-tight">Download File</h1>
        <span>Your request file will available soon</span>
      </div>
      <div class="w-full text-center py-6">
        <svg class="h-32 w-32 inline" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4C9.11 4 6.6 5.64 5.35 8.04C2.34 8.36 0 10.91 0 14C0 17.31 2.69 20 6 20H19C21.76 20 24 17.76 24 15C24 12.36 21.95 10.22 19.35 10.04ZM19 18H6C3.79 18 2 16.21 2 14C2 11.95 3.53 10.24 5.56 10.03L6.63 9.92L7.13 8.97C8.08 7.14 9.94 6 12 6C14.62 6 16.88 7.86 17.39 10.43L17.69 11.93L19.22 12.04C20.78 12.14 22 13.45 22 15C22 16.65 20.65 18 19 18ZM13.45 10H10.55V13H8L12 17L16 13H13.45V10Z" fill="#BB6BD9"/>
        </svg>      
      </div>
      <div class="w-full text-center py-6">
        <span class="block mb-4">Click the button below if the file download hasn't started yet</span>
        <button type="button" class="btn btn--primary" @click="downloadFile()">Download</button>
      </div>            
    </div>
  </div>
</template>

<script>
import { io } from 'socket.io-client'

export default {
  name: 'Download',
  data(){
    return {
      isConnected: false,
      returnResponse: [],
      socket: io(process.env.VUE_APP_SOCKET_URL, { transports: ["websocket"] }),
      error: [],
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000        
      }      
    }
  },
  methods: {
    downloadFile() {
      if (this.isConnected) {
        const link = document.createElement('a')
        link.href = this.returnResponse.url
        link.setAttribute('download', this.returnResponse.fileName)
        document.body.appendChild(link)
        link.click() 
      } else {
        this.$toasted.error('Download failed. Something error with your web socket!', this.toastedOptions)
      }    
    }
  },
  mounted() {
    /* CHECK CONNECTION STATUS */
    this.socket.on('connect', () => {
      this.isConnected = true
      console.log(this.socket.id)

      this.socket.on('disconnect', () => {
        this.isConnected = false
        console.log('disconnected')
      })
    })

    const userID = this.$store.state.user.id
    const key = this.$route.query.key || ''
    this.socket.emit('gate', userID)
    this.socket.on(key, (props) => {
      console.log(props)
      if (props) {
        this.returnResponse = { fileName: props.filename, url: props.url }

        const link = document.createElement('a')
        link.href = props.url
        link.setAttribute('download', props.filename)
        document.body.appendChild(link)
        link.click()        
      } else {
        this.returnResponse = []
      }
    })    
  } 
}
</script>