<template>
  <div class="bg-white shadow-lg rounded-lg">  
    <div class="p-10">
      <div class="pb-2 mb-4 border-b border-gray-200">
        <h2 class="text-gray-800 text-2xl font-medium">Show Product</h2>
      </div>
      <div class="md:flex gap-6">
        <div class="md:flex-col w-full md:w-4/6">
          <div class="md:flex gap-4">
            <div class="md:w-1/2 mb-4">
              <label for="season_creation" class="label-control">Season Creation <span class="text-red-600">*</span></label>
              <input id="season_creation" ref="season_creation" type="text" v-model="seasonCreation" maxlength="255" class="form-control">
            </div>
            <div class="md:w-1/2 mb-4">
              <label for="collection" class="label-control">Collection <span class="text-red-600">*</span></label>
              <MultiSelect id="collection" v-model="collection" :options="collections" :value="collection" :searchable="true" track-by="id" label="name" placeholder="Type to Search">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>
            </div>
          </div>            
          <div class="md:flex gap-4">
            <div class="md:w-1/2 mb-4">
              <label for="barcode" class="label-control">Barcode <span class="text-red-600">*</span></label>
              <input id="barcode" ref="barcode" type="text" v-model="barcode" maxlength="255" class="form-control" readonly>
            </div>
            <div class="md:w-1/2 mb-4">
              <label for="reference" class="label-control">Reference <span class="text-red-600">*</span></label>
              <input id="reference" type="text" v-model="reference" class="form-control" readonly>
            </div>
          </div>
          <div class="md:flex gap-4">
            <div class="md:w-1/2 mb-4">
              <label for="color" class="label-control">Color <span class="text-red-600">*</span></label>
              <input id="color" type="text" v-model="color" class="form-control" readonly>
            </div>
            <div class="md:w-1/2 mb-4">
              <label for="size" class="label-control">Size</label>
              <input id="size" type="text" v-model="size" class="form-control" readonly>
            </div>            
          </div>
          <div class="md:flex gap-4">
            <div class="w-full mb-4">
              <label for="description" class="label-control">Description <span class="text-red-600">*</span></label>
              <input id="description" type="text" v-model="description" class="form-control" readonly>
            </div>
          </div>
          <div class="md:flex gap-4">
            <div class="md:w-1/2 mb-2">
              <label for="weight" class="label-control">Weight</label>
              <input id="weight" type="text" v-model="weight" class="form-control">
            </div>
            <div class="md:w-1/2 mb-2">
              <label for="high" class="label-control">Height</label>
              <input id="high" type="text" v-model="high" class="form-control">
            </div>            
          </div>
          <div class="md:flex gap-4">
            <div class="md:w-1/2 mb-2">
              <label for="width" class="label-control">Width</label>
              <input id="width" type="text" v-model="width" class="form-control">
            </div>
            <div class="md:w-1/2 mb-2">
              <label for="long" class="label-control">Length</label>
              <input id="long" type="text" v-model="long" class="form-control">
            </div>            
          </div>
          <div class="md:flex gap-4">
            <div class="w-full mb-2">
              <label for="carats" class="label-control">Carats</label>
              <input id="carats" type="text" v-model="carats" class="form-control">
            </div>           
          </div>              
          <div class="md:flex gap-4">
            <div class="w-full mb-4">
              <label for="annotation" class="label-control">Annotation</label>
              <textarea id="annotation" v-model="annotation" class="form-control" rows="3" readonly></textarea>          
            </div>
          </div>
          <div class="md:flex gap-4">
            <div class="md:w-1/2 mb-4">
              <label for="unit" class="label-control">Unit <span class="text-red-600">*</span></label>
              <MultiSelect id="unit" ref="unit" v-model="unit" :options="units" :value="unit" :searchable="true" placeholder="Type to Search">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>
            </div>
            <div class="flex md:w-1/2 gap-2 md:gap-4 mb-4">
              <div class="w-full md:w-1/2">
                <label for="currency" class="label-control">Currency <span class="text-red-600">*</span></label>
                <MultiSelect id="currency" ref="currency" v-model="currency" :options="currencies" :value="currency" :searchable="false" track-by="id" label="currency" :showLabels="false">
                  <template slot="caret">
                    <div>
                      <div class="multiselect__select">
                        <span>
                          <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                            <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </template>
                </MultiSelect>           
              </div>
              <div class="w-full md:w-1/2">
                <label for="change_rate" class="label-control">Rate (EUR to IDR) <span class="text-red-600">*</span></label>
                <input id="change_rate" type="text" v-model="changeRate" class="form-control text-right" readonly>           
              </div>
            </div>
          </div>          
        </div>
        <div class="md:flex-col w-full md:w-2/6">
          <div class="w-full mb-4">
            <label for="image" class="label-control">Image</label>
            <div v-if="!image">
              <div class="md:h-64 border-2 border-dashed border-gray-400 flex items-center justify-center p-2 rounded-lg mb-2">
                <img class="m-auto" :src="fileBox" />
              </div>
            </div>
            <div v-else>
              <div class="md:h-auto border-2 border-dashed border-gray-400 flex items-center justify-center p-2 rounded-lg mb-2">
                <img class="m-auto" :src="image" />
              </div>
            </div>
          </div>           
        </div>           
      </div>
      <!-- CALL PRODUCT RECIPE -->
      <ProductRecipe class="mb-10" />

      <!-- SUMMARY DATA SECTION -->
      <div class="w-full md:flex gap-2 mb-4 md:mb-1">
        <div class="w-full md:w-1/2 gap-2">
          <label for="manufacturing_cost1" class="label-control md:pr-4 md:py-3 md:text-right">Manufacturing Cost 1</label>
        </div>            
        <div class="flex md:w-1/2 gap-2">
          <div class="w-full md:flex md:w-3/5 gap-2">
            <div class="w-full md:w-3/12">
              <MultiSelect id="mfg_cost1_currency" ref="mfg_cost1_currency" v-model="mfgCost1Currency" :options="currencies" :value="mfgCost1Currency" :searchable="false" track-by="id" label="currency" :showLabels="false" placeholder="">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>
            </div>
            <div class="w-full md:w-4/12">
              <input id="mfg_cost1_rate" type="text" v-model="mfgCost1Rate" class="form-control text-right" readonly>
            </div>                
            <div class="w-full md:w-5/12">
              <div class="relative flex-1">
                <span fill="currentColor" class="absolute right-2 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">{{ mfgCost1Currency ? mfgCost1Currency.symbol : '' }}</span>
                <input id="manufacturing_cost1" type="text" v-model="manufacturingCost1" class="form-control text-right right-symbol" readonly>
              </div>
            </div>
          </div>
          <div class="w-full md:w-2/5">
            <div class="relative flex-1">
              <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
              <input id="euro_manufacturing_cost1" type="text" v-model="euroManufacturingCost1" class="form-control text-right right-symbol" readonly>
            </div>
          </div>
        </div>                                  
      </div>
      <div class="w-full md:flex gap-2 mb-4 md:mb-1">
        <div class="w-full md:w-1/2 gap-2">
          <label for="manufacturing_cost2" class="label-control md:pr-4 md:py-3 md:text-right">Manufacturing Cost 2</label>
        </div>
        <div class="flex md:w-1/2 gap-2">
          <div class="w-full md:flex md:w-3/5 gap-2">
            <div class="w-full md:w-3/12">
              <MultiSelect id="mfg_cost2_currency" ref="mfg_cost2_currency" v-model="mfgCost2Currency" :options="currencies" :value="mfgCost2Currency" :searchable="false" track-by="id" label="currency" :showLabels="false" placeholder="">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>
            </div>
            <div class="w-full md:w-4/12">
              <input id="mfg_cost2_rate" type="text" v-model="mfgCost2Rate" class="form-control text-right">
            </div>                
            <div class="w-full md:w-5/12">
              <div class="relative flex-1">
                <span fill="currentColor" class="absolute right-2 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">{{ mfgCost2Currency ? mfgCost2Currency.symbol : '' }}</span>
                <input id="manufacturing_cost2" type="text" v-model="manufacturingCost2" class="form-control text-right right-symbol" readonly>
              </div>
            </div>
          </div>
          <div class="w-full md:w-2/5">
            <div class="relative flex-1">
              <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
              <input id="euro_manufacturing_cost2" type="text" v-model="euroManufacturingCost2" class="form-control text-right right-symbol" readonly>
            </div>
          </div>
        </div>
      </div>
      <div v-if="euroManufacturingCost2 > 0" class="w-full md:flex gap-2 mb-4 md:mb-1.5">
        <div class="w-full md:w-1/2 gap-2"></div>           
        <div class="w-full md:w-1/2 gap-2">
          <input id="manufacturing_cost2_note" type="text" v-model="mfgCost2Note" class="form-control" placeholder="Manufacturing Cost 2 Note">
        </div>                                  
      </div>      
      <div class="w-full md:flex gap-2 mb-4 md:mb-1">
        <div class="w-full md:w-1/2 gap-2">
          <label for="tax_customs" class="label-control md:pr-4 md:py-3 md:text-right">Tax / Customs</label>
        </div>           
        <div class="flex md:w-1/2 gap-2">
          <div class="w-full md:w-3/5">
            <div class="relative flex-1">
              <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">%</span>
              <input id="tax_customs" type="text" v-model="taxCustoms" class="form-control text-right right-symbol" readonly>
            </div>
          </div>
          <div class="w-full md:w-2/5">
            <div class="relative flex-1">
              <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
              <input id="tax_customs_value" type="text" v-model="taxCustomsValue" class="form-control text-right right-symbol" readonly>
            </div>
          </div>
        </div>                                  
      </div>          
      <div class="w-full md:flex gap-2 mb-4 md:mb-1">
        <div class="w-full md:w-1/2 gap-2">
          <label for="manufacturing_cost3" class="label-control md:pr-4 md:py-3 md:text-right">Manufacturing Cost 3</label>
        </div>          
        <div class="flex md:w-1/2 gap-2">
          <div class="w-full md:flex md:w-3/5 gap-2">
            <div class="w-full md:w-3/12">
              <MultiSelect id="mfg_cost3_currency" ref="mfg_cost3_currency" v-model="mfgCost3Currency" :options="currencies" :value="mfgCost3Currency" :searchable="false" track-by="id" label="currency" :showLabels="false" placeholder="">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>
            </div>
            <div class="w-full md:w-4/12">
              <input id="mfg_cost3_rate" type="text" v-model="mfgCost3Rate" class="form-control text-right" readonly>
            </div>
            <div class="w-full md:w-5/12">
              <div class="relative flex-1">
                <span fill="currentColor" class="absolute right-2 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">{{ mfgCost3Currency ? mfgCost3Currency.symbol : '' }}</span>
                <input id="manufacturing_cost3" type="text" v-model="manufacturingCost3" class="form-control text-right right-symbol" readonly>
              </div>          
            </div>
          </div>
          <div class="w-full md:w-2/5">
            <div class="relative flex-1">
              <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
              <input id="euro_manufacturing_cost3" type="text" v-model="euroManufacturingCost3" class="form-control text-right right-symbol" readonly>
            </div>
          </div>
        </div>                                  
      </div>
      <div v-if="euroManufacturingCost3 > 0" class="w-full md:flex gap-2 mb-4 md:mb-1.5">
        <div class="w-full md:w-1/2 gap-2"></div>           
        <div class="w-full md:w-1/2 gap-2">
          <input id="manufacturing_cost3_note" type="text" v-model="mfgCost3Note" class="form-control" placeholder="Manufacturing Cost 3 Note">
        </div>                                  
      </div>      
      <div class="w-full md:flex gap-2 mb-4 md:mb-1">
        <div class="w-full md:w-1/2 gap-2">
          <label for="transport_packaging" class="label-control md:pr-4 md:py-3 md:text-right">Transport / Packaging Package</label>
        </div>
        <div class="md:w-1/2">
          <div class="relative flex-1">
            <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
            <input id="transport_packaging" type="text" v-model="transportPackaging" class="form-control text-right right-symbol" readonly>
          </div>
        </div>                                 
      </div>
      <div class="flex gap-2 mb-4 md:mb-1">
        <div class="w-1/2 gap-2">
          <label class="label-control pr-4 py-3 font-bold md:text-right">TOTAL COST PRICE</label>
        </div>          
        <div class="w-1/2">
          <div class="relative flex-1">
            <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
            <label class="label-control px-4 py-3 font-bold text-right right-symbol">{{ totalManufactureCost }}</label>
          </div>
        </div>                                 
      </div>          
      <div class="w-full md:flex gap-2 mb-4 md:mb-1">
        <div class="w-full md:w-1/2 gap-2">
          <label for="coef_wholesale_price" class="label-control md:pr-4 md:py-3 md:text-right">Merge / Wholesale Price</label>
        </div>
        <div class="flex md:w-1/2 gap-2">
          <div class="w-full md:w-3/5">
            <input id="coef_wholesale_price" type="text" v-model="coefWholesalePrice" class="form-control text-right right-symbol" readonly>
          </div>
          <div class="w-full md:w-2/5">
            <div class="relative flex-1">
              <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
              <input id="wholesale_price" type="text" v-model="wholesalePrice" class="form-control text-right right-symbol" readonly>
            </div>
          </div> 
        </div>                                  
      </div>
      <div class="w-full md:flex gap-2 mb-4 md:mb-1">
        <div class="w-full md:w-1/2 gap-2">
          <label for="agent_commision" class="label-control md:pr-4 md:py-3 md:text-right">Agent Commision</label>
        </div>
        <div class="flex w-full md:w-1/2 gap-2">
          <div class="w-full md:w-3/5">
            <div class="relative flex-1">
              <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">%</span>
              <input id="agent_commision" type="text" v-model="agentCommision" class="form-control text-right right-symbol" readonly>
            </div>
          </div>
          <div class="w-full md:w-2/5">
            <div class="relative flex-1">
              <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
              <input id="agent_commision_value" type="text" v-model="agentCommisionValue" class="form-control text-right right-symbol" readonly>
            </div>
          </div>
        </div>                                  
      </div>
      <div class="w-full md:flex gap-2 mb-4 md:mb-1">
        <div class="w-full md:w-1/2 gap-2">
          <label for="total_wholesale_price" class="label-control md:pr-4 md:py-3 md:text-right text-red">Total Wholesale Price in EURO</label>
        </div>        
        <div class="flex w-full md:w-1/2 gap-2">
          <div class="w-full md:w-3/5">
            <div class="relative flex-1">
              <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
              <input id="total_wholesale_price" type="text" v-model="totalWholesalePrice" class="form-control bg-red text-right right-symbol" readonly>
            </div>
          </div>
          <div class="w-full md:w-2/5">
            <div class="relative flex-1">
              <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
              <input id="approved_wholesale_price" type="text" v-model="approvedWholesalePrice" class="form-control bg-red border border-red-600 text-right right-symbol" readonly>
            </div>
          </div>
        </div>                                  
      </div>
      <div class="w-full md:flex gap-2 mb-4 md:mb-1">
        <div class="w-full md:w-1/2 gap-2">
          <label for="coef_retail_price" class="label-control md:pr-4 md:py-3 md:text-right text-red">Retail Price in EURO</label>
        </div>
        <div class="flex w-full md:w-1/2 gap-2">
          <div class="flex w-full md:w-3/5 gap-2">
            <div class="w-full md:w-1/3">
              <input id="coef_retail_price" type="text" v-model="coefRetailPrice" class="form-control bg-red text-right" readonly>
            </div>
            <div class="w-full md:w-2/3">
              <div class="relative flex-1">
                <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
                <input id="retail_price" type="text" v-model="retailPrice" class="form-control bg-red text-right right-symbol" readonly>
              </div>
            </div>              
          </div>
          <div class="w-full md:w-2/5">
            <div class="relative flex-1">
              <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
              <input id="approved_retail_price" type="text" v-model="approvedRetailPrice" class="form-control bg-red border border-blue-600 text-right right-symbol" readonly>
            </div>
          </div>              
        </div>                                  
      </div>
      <div class="w-full md:flex gap-2 mb-4 md:mb-1">
        <div class="w-full md:w-1/2 gap-2">
          <label for="coef_wholesale_usd" class="label-control md:pr-4 md:py-3 md:text-right text-yellow">Total Wholesale Price in USD</label>
        </div>
        <div class="flex w-full md:w-1/2 gap-2">
          <div class="flex w-full md:w-3/5 gap-2">
            <div class="w-full md:w-1/3">
              <input id="coef_wholesale_usd" type="text" v-model="coefWholesaleUSD" class="form-control bg-yellow text-right" readonly>
            </div>
            <div class="w-full md:w-2/3">
              <div class="relative flex-1">
                <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">$</span>
                <input id="wholesale_price_usd" type="text" v-model="wholesalePriceUSD" class="form-control bg-yellow text-right right-symbol" readonly>
              </div>
            </div>              
          </div>
          <div class="w-full md:w-2/5">
            <div class="relative flex-1">
              <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">$</span>
              <input id="approved_wholesale_price_usd" type="text" v-model="approvedWholesalePriceUSD" class="form-control bg-yellow text-right right-symbol" readonly>
            </div>
          </div>
        </div>                                  
      </div>
      <div class="w-full md:flex gap-2 mb-4 md:mb-1">
        <div class="w-full md:w-1/2 gap-2">
          <label for="coef_retail_usd" class="label-control md:pr-4 md:py-3 md:text-right text-yellow">Retail Price in USD</label>
        </div>
        <div class="flex w-full md:w-1/2 gap-2">
          <div class="flex w-full md:w-3/5 gap-2">
            <div class="w-full md:w-1/3">
              <input id="coef_retail_usd" type="text" v-model="coefRetailUSD" class="form-control bg-yellow text-right" readonly>
            </div>
            <div class="w-full md:w-2/3">
              <div class="relative flex-1">
                <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">$</span>
                <input id="retail_price_usd" type="text" v-model="retailPriceUSD" class="form-control bg-yellow text-right right-symbol" readonly>
              </div>
            </div>              
          </div>
          <div class="w-full md:w-2/5">
            <div class="relative flex-1">
              <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">$</span>
              <input id="approved_retail_price_usd" type="text" v-model="approvedRetailPriceUSD" class="form-control bg-yellow text-right right-symbol" readonly>
            </div>
          </div>
        </div>                                 
      </div>
      <div class="flex gap-4 md:justify-between items-center mt-10 mb-2">
        <div class="flex items-center gap-4">
          <button :disabled="isLoading" type="button" @click="goBack" class="btn btn--secondary" alt="Back" title="Back">
            <BackIcon>
              <span class="ml-2 md:block hidden">Back</span>
            </BackIcon>
          </button>
        </div>
        <div class="flex items-center">
          <router-link :to="{ name: 'productEdit', params: { id: $route.params.id } }"  class="btn btn--success" alt="Edit" v-tooltip="'Edit'">
            <Edit2Icon>
              <span class="ml-2 md:block hidden">Edit</span>
            </Edit2Icon>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import format from '@/helpers/formatNumber'
import productServices from '@/services/product/productServices'
import collectionServices from '@/services/collection/collectionServices'
import unitServices from '@/services/unit/unitServices'
import rateServices from '@/services/rate/rateServices'
import BackIcon from '../../icons/BackIcon'
import Edit2Icon from '../../icons/Edit2Icon'
import imageBox from '../../../assets/images/file-box.png'
import ProductRecipe from '../recipe/List'

export default {
  name: 'ShowProduct',
  components: {
    BackIcon,
    Edit2Icon,
    ProductRecipe 
  },  
  data(){
    return {
      seasonCreation: '',
      collection: '',
      barcode: '',
      reference: '',
      color: '',
      size: '',
      description: '',
      weight: '',
      high: '',
      width: '',
      long: '',
      carats: '',       
      annotation: '',
      unit: '',
      currency: '',
      changeRate: '',
      image: '',
      productImage: '',
      mfgCost1Currency: null,
      mfgCost1Rate: null,
      manufacturingCost1: 0,
      euroManufacturingCost1: 0,
      mfgCost2Currency: null,
      mfgCost2Rate: null,
      manufacturingCost2: 0,
      euroManufacturingCost2: 0,
      mfgCost2Note: null,
      mfgCost3Currency: null,
      mfgCost3Rate: null,
      manufacturingCost3: 0,
      euroManufacturingCost3: 0,
      mfgCost3Note: null,
      taxCustoms: 0,
      taxCustomsValue: 0,
      transportPackaging: 0,
      agentCommision: 0,
      agentCommisionValue: 0,
      coefWholesalePrice: 0,
      wholesalePrice: 0,
      totalWholesalePrice: 0,
      approvedWholesalePrice: 0,
      coefRetailPrice: 0,
      retailPrice: 0,
      approvedRetailPrice: 0,
      coefWholesaleUSD: 0,
      wholesalePriceUSD: 0,
      approvedWholesalePriceUSD: 0,
      coefRetailUSD: 0,
      retailPriceUSD: 0,
      approvedRetailPriceUSD: 0,      
      fileBox: imageBox,
      collections: [],
      units: [],
      currencies: [],
      record: [],
      details: [],
      error: [],
      showFunction: false,
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async fetchData(){
      try {
        const id = this.$route.params.id
        const response = await productServices.fetchById(id)
        if (response.data.status === 'success') {
          this.record = response.data.data

          this.seasonCreation = this.record.season_creation
          this.collection = { 'id': this.record.collection_id, 'name': this.record.collection.collection_name}
          this.barcode = this.record.barcode
          this.reference = this.record.reference
          this.color = this.record.color
          this.size = this.record.size
          this.description = this.record.description
          this.weight = this.record.weight
          this.high = this.record.high
          this.width = this.record.width
          this.long = this.record.long
          this.carats = this.record.carats          
          this.annotation = this.record.annotation
          this.unit = this.record.unit
          this.currency = { 'id': this.record.currency_detail.id, 'currency': this.record.currency_detail.currency, 'symbol': this.record.currency_detail.symbol, 'rate': this.record.currency_detail.rate}
          this.changeRate = this.formatNumber(this.record.change_rate)
          this.image = this.record.image
          this.mfgCost1Currency = this.record.mfg_cost1_currency_detail ? { 'id': this.record.mfg_cost1_currency_detail.id, 'currency': this.record.mfg_cost1_currency_detail.currency, 'symbol': this.record.mfg_cost1_currency_detail.symbol, 'rate': this.record.mfg_cost1_currency_detail.rate} : ''
          this.mfgCost1Rate = this.formatNumber(this.record.mfg_cost1_rate)
          this.manufacturingCost1 = this.formatNumber(this.record.manufacturing_cost1) ?? 0
          this.euroManufacturingCost1 = this.formatNumber(this.record.euro_manufacturing_cost1) ?? 0
          this.mfgCost2Currency = this.record.mfg_cost2_currency_detail ? { 'id': this.record.mfg_cost2_currency_detail.id, 'currency': this.record.mfg_cost2_currency_detail.currency, 'symbol': this.record.mfg_cost2_currency_detail.symbol, 'rate': this.record.mfg_cost2_currency_detail.rate} : ''
          this.mfgCost2Rate = this.formatNumber(this.record.mfg_cost2_rate)
          this.manufacturingCost2 = this.formatNumber(this.record.manufacturing_cost2) ?? 0
          this.euroManufacturingCost2 = this.formatNumber(this.record.euro_manufacturing_cost2) ?? 0
          this.mfgCost2Note = this.record.manufacturing_cost2_note
          this.mfgCost3Currency = this.record.mfg_cost3_currency_detail ? { 'id': this.record.mfg_cost3_currency_detail.id, 'currency': this.record.mfg_cost3_currency_detail.currency, 'symbol': this.record.mfg_cost3_currency_detail.symbol, 'rate': this.record.mfg_cost3_currency_detail.rate} : ''
          this.mfgCost3Rate = this.formatNumber(this.record.mfg_cost3_rate)
          this.manufacturingCost3 = this.formatNumber(this.record.manufacturing_cost3) ?? 0
          this.euroManufacturingCost3 = this.formatNumber(this.record.euro_manufacturing_cost3) ?? 0
          this.mfgCost3Note = this.record.manufacturing_cost3_note
          this.taxCustoms = this.formatNumber(this.record.tax_customs) ?? 0
          this.transportPackaging = this.formatNumber(this.record.transport_packaging) ?? 0
          this.agentCommision = this.formatNumber(this.record.agent_commision) ?? 0
          this.coefWholesalePrice = this.formatNumber(this.record.coef_wholesale_price) ?? 0
          this.wholesalePrice = this.formatNumber(this.record.wholesale_price) ?? 0
          this.approvedWholesalePrice = this.formatNumber(this.toFixed(this.record.approved_wholesale_price, 0)) ?? 0
          this.coefRetailPrice = this.formatNumber(this.record.coef_retail_price) ?? 0
          this.retailPrice = this.formatNumber(this.record.retail_price) ?? 0
          this.approvedRetailPrice = this.formatNumber(this.toFixed(this.record.approved_retail_price, 0)) ?? 0
          this.coefWholesaleUSD = this.formatNumber(this.record.coef_wholesale_usd) ?? 0
          this.wholesalePriceUSD = this.formatNumber(this.record.wholesale_price_usd) ?? 0
          this.approvedWholesalePriceUSD = this.formatNumber(this.toFixed(this.record.approved_wholesale_price_usd, 0)) ?? 0
          this.coefRetailUSD = this.formatNumber(this.record.coef_retail_usd) ?? 0
          this.retailPriceUSD = this.formatNumber(this.record.retail_price_usd) ?? 0
          this.approvedRetailPriceUSD = this.formatNumber(this.toFixed(this.record.approved_retail_price_usd, 0)) ?? 0

          /* CALL FUNCTIONS */
          this.calculateManufactureCost1(this.manufacturingCost1)
          this.calculateManufactureCost2(this.manufacturingCost2)
          this.calculateManufactureCost3(this.manufacturingCost3)          
          this.calculateTaxCustoms()
          this.calculateTotalMfgCost()
          this.calculateWholesalePrice()
          this.calculateAgentCommision()
          this.calculateWholesalePrice()
          this.calculateAgentCommision()
          this.calculateTotalWholesalePrice()
          this.calculateTotalWholesalePriceUSD()
          this.calculateRetailPriceUSD()           
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)            
        }
      } catch (error) {
        console.log(error)
      }
    }, 
    async fetchCollectionData() {
      try {
        const response = await collectionServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.collections = []
          records.forEach(element => {
            this.collections.push({
              'id': element.id,
              'name': element.collection_name
            })
          });          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchUnitData() {
      try {
        const response = await unitServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.units = []
          records.forEach(element => {
            this.units.push(element.unit)
          });
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    }, 
    async fetchCurrencyData() {
      try {
        const response = await rateServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.currencies = []
          records.forEach(element => {
            this.currencies.push({
              'id': element.id,
              'currency': element.currency,
              'symbol': element.symbol,
              'rate': element.rate
            })
          });
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },     
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    },
    calculateManufactureCost1(value) {
      if (this.mfgCost1Currency) {
        if (this.mfgCost1Currency.currency === 'EUR') {
          this.manufacturingCost1 = this.formatNumber(value)
          this.euroManufacturingCost1 = this.formatNumber(value)      
        } else {
          let input = this.unformatNumber(value)
          let rate = this.unformatNumber(this.mfgCost1Rate)
          let convertToEUR = input / rate

          this.manufacturingCost1 = this.formatNumber(value)
          this.euroManufacturingCost1 = this.formatNumber(this.toFixed(convertToEUR, 2))
        }
      } else {
        this.manufacturingCost1 = this.formatNumber(value)
      }   
    },
    calculateManufactureCost2(value) {
      if (this.mfgCost2Currency) {
        if (this.mfgCost2Currency.currency === 'EUR') {
          this.manufacturingCost2 = this.formatNumber(value)
          this.euroManufacturingCost2 = this.formatNumber(value)      
        } else {
          let input = this.unformatNumber(value)
          let rate = this.unformatNumber(this.mfgCost2Rate)
          let convertToEUR = input / rate

          this.manufacturingCost2 = this.formatNumber(value)
          this.euroManufacturingCost2 = this.formatNumber(this.toFixed(convertToEUR, 2))
        }
      } else {
        this.manufacturingCost2 = this.formatNumber(value)
      }  
    },    
    calculateManufactureCost3(value) {
      if (this.mfgCost3Currency) {
        if (this.mfgCost3Currency.currency === 'EUR') {
          this.manufacturingCost3 = this.formatNumber(value)
          this.euroManufacturingCost3 = this.formatNumber(value)      
        } else {
          let input = this.unformatNumber(value)
          let rate = this.unformatNumber(this.mfgCost3Rate)
          let convertToEUR = input / rate

          this.manufacturingCost3 = this.formatNumber(value)
          this.euroManufacturingCost3 = this.formatNumber(this.toFixed(convertToEUR, 2))
        }
      } else {
        this.manufacturingCost3 = this.formatNumber(value)
      }    
    },
    calculateTaxCustoms() {
      let manufaturingCost = parseFloat(this.unformatNumber(this.euroManufacturingCost1)) + parseFloat(this.unformatNumber(this.euroManufacturingCost2)) + parseFloat(this.unformatNumber(this.euroManufacturingCost3))
      let taxCustoms = parseFloat(manufaturingCost) * parseFloat(this.unformatNumber(this.taxCustoms))
      taxCustoms = taxCustoms / 100
      this.taxCustomsValue = this.formatNumber(this.toFixed(taxCustoms, 2))
    },
    calculateTotalMfgCost() {
      const total = parseFloat(this.recipeTotal) + (parseFloat(this.euroManufacturingCost1) + parseFloat(this.taxCustomsValue) + parseFloat(this.euroManufacturingCost2) + parseFloat(this.euroManufacturingCost3) + parseFloat(this.transportPackaging))
      return this.formatNumber(this.toFixed(total, 2))
    },
    calculateWholesalePrice() {
      let wholesale_price = parseFloat(this.unformatNumber(this.totalManufactureCost)) * parseFloat(this.unformatNumber(this.coefWholesalePrice))
      this.wholesalePrice = this.formatNumber(this.toFixed(wholesale_price, 2))
    },
    calculateAgentCommision() {
      let agent_commision = parseFloat(this.unformatNumber(this.wholesalePrice)) * parseFloat(this.unformatNumber(this.agentCommision))
      agent_commision = agent_commision / 100
      this.agentCommisionValue = this.formatNumber(this.toFixed(agent_commision, 2))
    },
    calculateTotalWholesalePrice() {
      let totalPrice = parseFloat(this.unformatNumber(this.wholesalePrice)) + parseFloat(this.unformatNumber(this.agentCommisionValue))
      this.totalWholesalePrice = this.formatNumber(this.toFixed(totalPrice, 2))
    },
    calculateRetailPrice() {
      let totalPrice = parseFloat(this.unformatNumber(this.totalWholesalePrice)) * parseFloat(this.unformatNumber(this.coefRetailPrice))
      this.retailPrice = this.formatNumber(this.toFixed(totalPrice, 2))
    },
    calculateTotalWholesalePriceUSD() {
      let totalPrice = parseFloat(this.unformatNumber(this.approvedWholesalePrice)) * parseFloat(this.unformatNumber(this.coefWholesaleUSD))
      this.wholesalePriceUSD = this.formatNumber(this.toFixed(totalPrice, 2))
    },
    calculateRetailPriceUSD() {
      let totalPrice = parseFloat(this.unformatNumber(this.approvedWholesalePriceUSD)) * parseFloat(this.unformatNumber(this.coefRetailUSD))
      this.retailPriceUSD = this.formatNumber(this.toFixed(totalPrice, 2))
    },    
    goBack() {
      window.history.length > 1 ? this.$router.back() : this.$router.push('/products')
    }   
  },
  computed: {
    ...mapGetters({
      recipeTotal: 'recipeTotal'
    }),
    totalManufactureCost: function() {
      return  this.calculateTotalMfgCost()
    }
  },
  created(){
    this.fetchCollectionData()
    this.fetchUnitData()
    this.fetchCurrencyData()
    this.fetchData()
  }
}
</script>