<template>
  <div>
    <!-- Close PO Modal Dialog -->
    <modal :show="showModal" @close="showModal = false" addClass="modal-xl" modalOrientation="pt-16 md:pt-5 md:items-center">
      <template v-slot:header><h3>{{ modalTitle }}</h3></template>
      <template v-slot:body>
        <div class="flex border-b border-gray-200 text-gray-800 justify-between items-center mb-4 pb-2">
          <div class="md:w-1/2 w-full items-center">
            <h2 class="text-2xl font-medium">PO Items</h2>
          </div>
          <div class="flex items-center right-0 gap-2">           
            <button :disabled="isLoading" type="button" @click="closePODialog()" class="btn btn--danger" alt="Process Close PO" v-tooltip="'Process Close PO'">
              <span class="md:block hidden">Close PO</span>
            </button>
            <button :disabled="isLoading" type="button" @click="closeWizardDialog()" class="btn btn--primary" alt="Process Close PO and Recreate New PO with current PO" v-tooltip="'Process Close PO and Recreate New PO with current PO'">
              <span class="md:block hidden">Close & Recreate PO</span>
            </button>            
          </div>
        </div>
        <!-- TABLE SECTION -->
        <div class="bg-white shadow-lg rounded-lg overflow-y-auto">
          <table>
            <thead>
              <tr>
                <th scope="col" class="px-3 text-left">Supplier</th>
                <th scope="col" class="px-3 text-left">Reference</th>
                <th scope="col" class="px-3 text-left">Color</th>
                <th scope="col" class="px-3 text-left">Size</th>
                <th scope="col" class="px-3 text-left">Unit</th>
                <th scope="col" class="px-3 text-center">Request</th>
                <th scope="col" class="px-3 text-center">Receive</th>
                <th scope="col" class="px-3 text-center">Shortage</th>
                <th scope="col" class="px-3 text-center">Migrate</th>
              </tr>           
            </thead>
            <tbody>
              <tr v-if="records.length === 0" class="border-b border-gray-200 hover:bg-gray-100"><td class="text-center" colspan="9">No matching records found</td></tr>
              <tr v-for="(item, index) in records" :key="index" class="border-b border-gray-200 hover:bg-gray-100" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">
                <td class="px-3 py-2 text-left">{{ item.supplier_name }}</td>
                <td class="px-3 py-2 text-left">{{ item.material_name }}</td>
                <td class="px-3 py-2 text-left">{{ item.color }}</td>
                <td class="px-3 py-2 text-left">{{ item.size }}</td>
                <td class="px-3 py-2 text-left">{{ item.unit }}</td>
                <td class="px-3 py-2 text-right">{{ formatNumber(item.request) }}</td>
                <td class="px-3 py-2 text-right">{{ formatNumber(item.receive) }}</td>
                <td class="px-3 py-2 text-right">{{ item.qty > 0 ? formatNumber(item.qty) : '0' }}</td>
                <td class="px-3 py-2 text-right">
                  <input type="text" class="calc-form-control w-full" v-model="item.migrate" @input="handleInputMigrate($event, index)">
                </td>
              </tr>
            </tbody>                              
          </table>
        </div>        
      </template>
      <template v-slot:footer>
      </template> 
    </modal>

    <!-- Close PO Wizard Modal Dialog -->
    <modal :show="showWizardModal" @close="showWizardModal = false" addClass="modal-lg" modalOrientation="pt-16 md:pt-5 md:items-center">
      <template v-slot:header><h3>{{ modalTitle }}</h3></template>
      <template v-slot:body>
        <div class="md:flex gap-6">
          <div class="w-full md:flex md:w-1/2 gap-6">
            <div class="w-full md:w-1/2 mb-4">
              <ValidationProvider name="Season" rules="required" v-slot="{ errors }">
                <label for="season" class="label-control">Season <span class="text-red-600">*</span></label>
                <MultiSelect id="season" v-model="season" :options="seasons" :value="season" track-by="id" label="name" :showLabels="false">
                  <template slot="caret">
                    <div>
                      <div class="multiselect__select">
                        <span>
                          <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                            <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </template>
                </MultiSelect>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
              </ValidationProvider>
              <div v-if="error.season" class="text-sm text-red-600"><span>{{ error.season[0] }}</span></div>
            </div>
            <div class="w-full md:w-1/2 mb-4">
              <ValidationProvider name="Period" rules="required" v-slot="{ errors }">
                <label for="period" class="label-control">Period <span class="text-red-600">*</span></label>
                <MultiSelect id="period" v-model="period" :options="periods" :value="period" track-by="id" label="name" :showLabels="false">
                  <template slot="caret">
                    <div>
                      <div class="multiselect__select">
                        <span>
                          <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                            <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </template>
                </MultiSelect>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
              </ValidationProvider>
              <div v-if="error.period" class="text-sm text-red-600"><span>{{ error.period[0] }}</span></div>
            </div>
          </div>
          <div class="w-full md:flex md:w-1/2 gap-6">
            <div class="w-full md:w-1/2 mb-4">
              <ValidationProvider name="Purchase Date" rules="required" v-slot="{ errors }">
                <v-date-picker v-model="purchaseDate" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs">
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="md:flex gap-6">
                      <div class="w-full">
                        <label for="purchase_date" class="label-control">Purchase Date <span class="text-red-600">*</span></label>
                        <div class="relative flex justify-between items-center">
                          <input id="purchase_date" type="text" class="form-control" :value="inputValue" v-on="inputEvents" readonly>
                          <span class="h-full absolute pointer-events-none right-0">
                            <DateRange class="m-3" />
                          </span>                      
                        </div>
                        <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </ValidationProvider>
              <div v-if="error.purchase_date" class="text-sm text-red-600"><span>{{ error.purchase_date[0] }}</span></div>
            </div>
            <div class="w-full md:w-1/2 mb-4">
              <ValidationProvider name="Wish Delivery Date" rules="required" v-slot="{ errors }">
                <v-date-picker v-model="deliveryDate" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs">
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="md:flex gap-6">
                      <div class="w-full">
                        <label for="wish_delivery_date" class="label-control">Wish Delivery Date <span class="text-red-600">*</span></label>
                        <div class="relative flex justify-between items-center">
                          <input id="wish_delivery_date" type="text" class="form-control" :value="inputValue" v-on="inputEvents" readonly>
                          <span class="h-full absolute pointer-events-none right-0">
                            <DateRange class="m-3" />
                          </span>                      
                        </div>
                        <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </ValidationProvider>
              <div v-if="error.wish_delivery_date" class="text-sm text-red-600"><span>{{ error.wish_delivery_date[0] }}</span></div>
            </div>
          </div>          
        </div>
      </template>
      <template v-slot:footer>
        <button :disabled="isLoading" type="button" @click="closeAndRecreateDialog()" class="btn btn--primary" alt="Save" title="Save">
          <span class="md:block hidden">Save &amp; Continue</span>
        </button>        
      </template> 
    </modal>    
  </div>
</template>

<script>
import format from '@/helpers/formatNumber'
import purchaseServices from '@/services/purchase/purchaseServices'
import purchaseDetailServices from '@/services/purchase/purchaseDetailServices'
import seasonServices from '@/services/season/seasonServices'
import DateRange from '../../icons/DateRange'
import Modal from '../../widgets/Modal'

export default {
  name: 'PurchaseOrderCloseModal',
  components: {
    Modal,
    DateRange
  },
  data() {
    return {
      recordId: null,
      recordNumber: null,
      records: [],
      error: [],
      isLoading: false,
      modalTitle: '',     
      showModal: false,
      showWizardModal: false,
      purchaseDate: '',
      deliveryDate: '',
      masks: {
        input: 'YYYY-MM-DD',
      },
      attrs: [
        {
          key: 'today',
          highlight: 'red',
          dates: new Date(),
        },
      ],      
      remarks: '',
      season: '',
      seasons: [],
      period: '',
      periods: [
        { 'id': 1, 'name': 'Period 1' }, { 'id': 2, 'name': 'Period 2' }, { 'id': 3, 'name': 'Period 3' },
        { 'id': 4, 'name': 'Period 4' }, { 'id': 5, 'name': 'Period 5' }, { 'id': 6, 'name': 'Period 6' }
      ],      
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }         
    }
  },
  methods: {
    toggleModal(id, number) {
      this.recordId = id
      this.recordNumber = number
      this.showModal = true
      this.modalTitle = 'Close Purchase Order'
      this.records = []
      this.fetchData()
    },
    async fetchData() {
      try {
        this.isLoading = true
        const params = {
          po_number: this.recordNumber
        }
        const response = await purchaseDetailServices.fetchAllByReceiving(params)
        if (response.data.status === 'success') {
          this.isLoading = false
          const records = response.data.data
          this.records = []
          records.forEach(item => {
            this.records.push({
              material_id: item.id,
              supplier_name: item.supplier_name,
              material_name: item.material_name,
              color: item.color,
              size: item.size,
              unit: item.unit,
              currency: item.buy_currency,
              price: item.buy_price,
              request: item.request,
              receive: item.receive,
              qty: item.qty,
              migrate: item.qty > 0 ? item.qty : '0'        
            })
          })
        } else {
          this.isLoading = false

          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        this.isLoading = false
        console.log(error)
      }
    },
    async closePO(id) {
      try {
        this.isLoading = true

        const response = await purchaseServices.closedPurchase(id)
        return response.data
      } catch (error) {     
        this.isLoading = false

        return error
      }      
    },    
    async closePODialog() {
      const id = this.recordId

      this.$swal.fire({
        title: 'Do you want to continue ?',
        text: "Close PO will prevent you from receiving this PO on the future. If you still receive on different season or period, you can choose Close & Recreate PO. You won't be able to revert this!",
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#4b5563',
        confirmButtonText: 'Yes'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.isLoading = false

          /* CALL DELETE FUNCTION */
          const result = this.closePO(id)
          const response = await result

          if (response.status) {
            if (response.status === 'success') {
              this.showModal = false

              /* REFRESH DATA */
              this.$parent.fetchData()

              this.$swal.fire({
                title: 'Closed!',
                text: "Your purchase order has been closed",
                icon: 'success',
                showConfirmButton: false,
                timer: 3000            
              })              
            } else {
              this.$swal.fire({
                title: 'Error!',
                text: response.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 3000            
              })              
            }
          } else {
            this.$toasted.error(response.message, this.toastedOptions)           
          }
        }
      })      
    },
    async closeAndRecreate(id) {
      try {
        this.isLoading = true
        const payload = {
          season: this.season ? this.season.id : null,
          period: this.period ? this.period.id : null,          
          purchase_date: this.purchaseDate,
          wish_delivery_date: this.deliveryDate,
          details: this.records
        }
        const response = await purchaseServices.closedRecreatePurchase(id, payload)
        if (response.data.status === 'success') {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          return response.data
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.season || responseReturn.period || responseReturn.inv_no 
              || responseReturn.purchase_date || responseReturn.wish_delivery_date) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {  
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        return error
      }      
    },    
    async closeAndRecreateDialog() {
      const id = this.recordId

      this.$swal.fire({
        title: 'Do you want to continue ?',
        text: "This process will close current PO and recreate new PO based on migrate data. Please make sure your data is correct!",
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#4b5563',
        confirmButtonText: 'Yes'
      }).then(async (result) => {
        if (result.isConfirmed) {
          /* CALL DELETE FUNCTION */
          const result = this.closeAndRecreate(id)
          const response = await result

          if (response.status) {
            if (response.status === 'success') {
              this.showWizardModal = false
              this.showModal = false

              this.clearForm()

              /* REFRESH DATA */
              this.$parent.fetchData()

              this.$swal.fire({
                title: 'Closed!',
                text: "Your purchase order has been closed",
                icon: 'success',
                showConfirmButton: false,
                timer: 3000            
              })              
            } else {
              this.$swal.fire({
                title: 'Error!',
                text: response.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 3000            
              })              
            }
          } else {
            this.$toasted.error(response.message, this.toastedOptions)           
          }
        }
      })      
    },
    closeWizardDialog() {
      this.fetchSeasonData()
      this.showWizardModal = true
    },
    async fetchSeasonData() {
      try {
        const response = await seasonServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.seasons = []
          records.forEach(element => {
            this.seasons.push({
              'id': element.id,
              'name': element.season_name
            })
          });          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },    
    clearForm(){
      this.season = ''
      this.period = ''      
      this.purchaseDate = ''
      this.deliveryDate = ''
    },    
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    },
    onlyNumber(num) {
      let result = format.onlyNumber(num)
      return result
    },
    handleInputMigrate(event, index) {
      this.records[index].migrate = this.onlyNumber(event.target.value)
    },    
  },
}
</script>