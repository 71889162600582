import ListMaterial from '../components/feature/material/List'
import CreateMaterial from '../components/feature/material/Create'
import EditMaterial from '../components/feature/material/Edit'
import ShowMaterial from '../components/feature/material/Show'

const routes = [
  {
    path: '/materials',
    name: 'material',
    component: ListMaterial,
    meta: {
      title: 'Materials',
      requiresAuth: true
    }    
  },
  {
    path: '/materials/create',
    name: 'materialCreate',
    component: CreateMaterial,
    meta: {
      title: 'Create Material',
      requiresAuth: true
    }    
  },
  {
    path: '/materials/:id/edit',
    name: 'materialEdit',
    component: EditMaterial,
    meta: {
      title: 'Edit Material',
      requiresAuth: true
    }    
  },
  {
    path: '/materials/:id',
    name: 'materialShow',
    component: ShowMaterial,
    meta: {
      title: 'Show Material',
      requiresAuth: true
    }    
  }  
]

export default routes