<template>
  <div class="text-gray-500 antialiased bg-white">
    <slot />
  </div>
</template>

<script>
export default {

}
</script>