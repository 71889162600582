import ListFactory from '../components/feature/factory/List'
import CreateFactory from '../components/feature/factory/Create'
import EditFactory from '../components/feature/factory/Edit'
import ShowFactory from '../components/feature/factory/Show'

const routes = [
  {
    path: '/factories',
    name: 'factory',
    component: ListFactory,
    meta: {
      title: 'Factories',
      requiresAuth: true
    }    
  },
  {
    path: '/factories/create',
    name: 'factoryCreate',
    component: CreateFactory,
    meta: {
      title: 'Create Factory',
      requiresAuth: true
    }    
  },
  {
    path: '/factories/:id/edit',
    name: 'factoryEdit',
    component: EditFactory,
    meta: {
      title: 'Edit Factory',
      requiresAuth: true
    }    
  },
  {
    path: '/factories/:id',
    name: 'factoryShow',
    component: ShowFactory,
    meta: {
      title: 'Show Factory',
      requiresAuth: true
    }    
  }  
]

export default routes