<template>
  <div>
    <!-- ACTION PANEL SECTION -->
    <div class="flex border-b border-gray-200 text-gray-800 justify-between items-center mb-4 pb-2">
      <div class="md:w-1/2 w-full items-center">
        <h2 class="text-2xl font-medium">Contacts</h2>
      </div>
      <div class="flex items-center right-0">
        <button v-if="show" type="button" class="btn btn--success" id="createRecipe" @click="toggleNew">
          <PlusIcon>
            <span class="ml-2 md:block hidden">Add</span>
          </PlusIcon>
        </button>       
      </div>
    </div>     
    <!-- TABLE SECTION -->
    <div class="mb-4 bg-white shadow-lg rounded-lg overflow-y-auto">
      <table>
        <thead>
          <tr>
            <th scope="col" class="px-3 text-left">Contact Name</th>
            <th scope="col" class="px-3 text-left">Phone Number</th>
            <th scope="col" class="px-3 text-left">Email</th>
            <th scope="col" class="px-3 text-center" v-if="show">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="details.length === 0" class="border-b border-gray-200 hover:bg-gray-100"><td class="text-center" colspan="4">No data to display</td></tr>
          <tr v-for="(detail, index) in details" :key="detail.id" class="border-b border-gray-200 hover:bg-gray-100" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">
            <td class="px-3 text-left">{{ detail.contact }}</td>
            <td class="px-3 text-left">{{ detail.phone_no }}</td>
            <td class="px-3 text-left">{{ detail.email }}</td>
            <td class="px-3 text-center" v-if="show">
              <div class="flex item-center justify-center">
                <button @click="toggleEdit( detail.id )" type="button" class="btn-edit" alt="Edit" v-tooltip="'Edit'">
                  <EditIcon />
                </button>        
                <button @click="confirmDialog( detail.id )" type="button" class="btn-delete" alt="Delete" v-tooltip="'Delete'">
                  <DeleteIcon />
                </button>
              </div>
            </td>
          </tr>
        </tbody>                              
      </table>
      <div class="flex px-5 py-5 bg-white justify-between items-center"></div>                            
    </div>
    <!-- Modal Dialog -->
    <modal :show="showModal" @close="showModal = false" addClass="modal-lg" modalOrientation="pt-16 md:flex md:pt-0 md:items-center md:justify-center">
      <template v-slot:header><h3>{{ modalTitle }}</h3></template>
      <template v-slot:body>
        <form id="modalForm" @submit.prevent="save">
          <div class="md:flex gap-6">
            <div class="w-full mb-4">
              <label for="contact" class="label-control">Contact Name</label>
              <input id="contact" type="text" v-model="contact" class="form-control">
              <div v-if="error.contact" class="text-sm text-red-600"><span>{{ error.contact[0] }}</span></div>                
            </div>
          </div>          
          <div class="md:flex gap-6">
            <div class="md:w-1/2 mb-4">
              <label for="phone_no" class="label-control">Phone Number</label>
              <input id="phone_no" type="text" v-model="phoneNumber" class="form-control">
              <div v-if="error.phone_no" class="text-sm text-red-600"><span>{{ error.phone_no[0] }}</span></div> 
            </div>
            <div class="md:w-1/2 mb-4">
              <label for="email" class="label-control">Email</label>
              <input id="email" type="text" v-model="email" class="form-control">
              <div v-if="error.email" class="text-sm text-red-600"><span>{{ error.email[0] }}</span></div> 
            </div>
          </div>
        </form>
      </template>
      <template v-slot:footer>
        <button :disabled="isLoading" type="submit" form="modalForm" class="btn btn--primary">
          Save
        </button>      
      </template> 
    </modal>
  </div>  
</template>

<script>
import contactServices from '@/services/factory/factoryContactServices'
import Modal from '../../widgets/Modal'
import PlusIcon from '../../icons/PlusIcon'
import EditIcon from '../../icons/EditIcon'
import DeleteIcon from '../../icons/DeleteIcon'

export default {
  name: 'FactoryContact',
  components: {
    Modal,
    PlusIcon,
    EditIcon,
    DeleteIcon,
  },
  props: ['show'],
  data() {
    return {
      modalTitle: '',     
      showModal: false,
      details: [],
      detailId: '',
      contact: '',
      phoneNumber: '',
      email: '',
      error: [],
      isEdit: false,
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async fetchDetailData() {
      try {
        this.isLoading = true

        const id = this.$route.params.id
        const response = await contactServices.fetchAll(null, id)
        if (response.data.status === 'success') {
          this.isLoading =false

          this.details = response.data.data
        } else {
          this.isLoading =false

          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        this.isLoading =false
        console.log(error)
      }
    },
    async fetchData(id){
      try {
        const response = await contactServices.fetchById(id)
        if (response.data.status === 'success') {
          this.record = response.data.data

          this.contact = this.record.contact
          this.phoneNumber = this.record.phone_no
          this.email = this.record.email
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)            
        }
      } catch (error) {
        console.log(error)
      }
    },    
    async save(){
      try {
        this.isLoading = true
        const payload = {
          factory_id: this.$route.params.id,
          contact: this.contact,
          phone_no: this.phoneNumber,
          email: this.email,
        }

        let response = ''
        if (this.isEdit) {
          response = await contactServices.update(this.detailId, payload)
        } else {
          response = await contactServices.create(payload)
        }
        
        if (response.data.status === 'success') {
          /* SET IS EDIT STATE TO FALSE */
          this.isEdit = false

          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          if (this.detailId) {
            this.showModal = false
          }

          /* CLEAR INPUT FORM */
          this.clearForm()

          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          /* RELOAD DETAILS DATA */
          this.fetchDetailData()
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.contact || responseReturn.phone_no || responseReturn.email) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },
    async delete(id) {
      try {
        const response = await contactServices.delete(id)
        return response.data
      } catch (error) {     
        return error
      }
    },
    async confirmDialog(id) {
      // console.log(id)
      this.$swal.fire({
        title: 'Are you sure ?',
        text: "You won't be able to revert this!",
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#4b5563',
        confirmButtonText: 'Yes'
      }).then(async (result) => {
        if (result.isConfirmed) {
          /* CALL DELETE FUNCTION */
          const result = this.delete(id)
          const response = await result

          if (response.status) {
            if (response.status === 'success') {
              /* REFRESH DATA */
              this.fetchDetailData()

              this.$swal.fire({
                title: 'Deleted!',
                text: "Your record has been deleted",
                icon: 'success',
                showConfirmButton: false,
                timer: 3000            
              })              
            } else {
              this.$swal.fire({
                title: 'Error!',
                text: response.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 3000            
              })              
            }
          } else {
            this.$toasted.error(response.message, this.toastedOptions)           
          }
        }
      })
    },                 
    toggleNew() {
      this.isEdit = false
      this.error = []
      this.showModal = true
      this.modalTitle = 'Add Contact'
      this.clearForm()
    },
    toggleEdit(id) {
      this.isEdit = true
      this.error = []
      this.showModal = true
      this.modalTitle = 'Edit Contact'
      this.clearForm()
      this.detailId = id
      this.fetchData(id)
    },    
    clearForm() {
      this.detailId = ''
      this.contact = ''
      this.phoneNumber = '' 
      this.email = ''
    }
  },
  created() {
    this.fetchDetailData()
  }
}
</script>