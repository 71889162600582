import axios from '@/axios'

export default {
  fetchAll(params){
    return axios.get(`/rates/all`, {params})
  },
  fetchLimit(params){
    return axios.get(`/rates`, {params})
  },  
  fetchById(id){
    return axios.get(`/rates/${id}`)
  }, 
  fetchByCurrency(name){
    return axios.get(`/rates/currency/${name}`)
  },      
  create(payload){
    return axios.post(`/rates`, payload)
  },
  update(id, payload){
    return axios.put(`/rates/${id}`, payload)
  },
  delete(id){
    return axios.delete(`/rates/${id}`)
  }, 
  deleteMultiple(payload){
    return axios.delete(`/rates`, payload)
  },
  fetchDataOptions(params){
    return axios.get(`/rates/options`, {params})
  }  
}