import ListAdjustmentReason from '../components/feature/adjustmentReason/List'
import CreateAdjustmentReason from '../components/feature/adjustmentReason/Create'
import EditAdjustmentReason from '../components/feature/adjustmentReason/Edit'
import ShowAdjustmentReason from '../components/feature/adjustmentReason/Show'

const routes = [
  {
    path: '/reasons',
    name: 'adjustmentReason',
    component: ListAdjustmentReason,
    meta: {
      title: 'Adjustment Reasons',
      requiresAuth: true
    }    
  },
  {
    path: '/reasons/create',
    name: 'adjustmentReasonCreate',
    component: CreateAdjustmentReason,
    meta: {
      title: 'Create Adjustment Reason',
      requiresAuth: true
    }    
  },
  {
    path: '/reasons/:id/edit',
    name: 'adjustmentReasonEdit',
    component: EditAdjustmentReason,
    meta: {
      title: 'Edit Adjustment Reason',
      requiresAuth: true
    }    
  },
  {
    path: '/reasons/:id',
    name: 'adjustmentReasonShow',
    component: ShowAdjustmentReason,
    meta: {
      title: 'Show Adjustment Reason',
      requiresAuth: true
    }    
  }  
]

export default routes