<template>
  <div>
    <!-- ACTION PANEL SECTION -->
    <div class="flex border-b border-gray-200 text-gray-800 justify-between items-center mb-4 pb-2">
      <div class="md:w-1/2 w-full items-center">
        <h2 class="text-2xl font-medium">Transfer Stock</h2>
      </div>
      <div class="flex items-center right-0">
        <router-link :to="{ name: 'transferCreate' }" class="btn btn--primary">
          <PlusIcon>
            <span class="ml-2 md:block hidden">Create</span>
          </PlusIcon>
        </router-link>    
      </div>
    </div>
    <!-- TABLE FILTER SECTION -->
    <div class="flex pb-4 justify-between items-center">
      <div class="relative">
        <select v-model="take" @change="updateTake()" class="px-4 py-2 pr-8 appearance-none rounded-md shadow-lg text-sm bg-white focus:bg-white text-black placeholder-gray-500 border border-gray-200 focus:border-purple-500 focus:outline-none">
          <option v-for="show in shows" :key="show.number" :value="show.number" :selected="show.number === take ? 'selected' : ''">{{ show.name }}</option>
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
          <svg class="w-5 h-5 fill-current" viewBox="0 0 24 24">
          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z" />
          </svg>
        </div>
      </div>
      <div class="flex w-96">
        <div class="relative flex-1">
          <svg width="20" height="20" fill="currentColor" class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"><path fill-rule="evenodd" clip-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"></path></svg>
          <input type="text" ref="search" v-model="search" @input="runSearch()" aria-label="Search" placeholder="Search ( Press &quot;/&quot; to focus )" class="w-full text-sm text-black placeholder-gray-500 border border-gray-200 rounded-md py-2 pl-10 focus:border-purple-500 focus:outline-none">  
        </div>
        <DropdownFilter>
          <div class="w-auto md:flex bg-white">
            <div class="w-64 p-4">
              <div class="pb-2 mb-4 border-b border-gray-200">
                <h2 class="text-gray-800 font-medium">Sort By</h2>
              </div>
              <div class="w-full mb-4">
                <label for="sort_field" class="label-control">Field</label>
                <MultiSelect id="sort_field" v-model="sortField" :options="sortFields" :value="sortField" :searchable="true" track-by="field" label="name" :allowEmpty="false" :showLabels="false" placeholder="Type to Search">
                  <template slot="caret">
                    <div>
                      <div class="multiselect__select">
                        <span>
                          <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                            <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </template>
                </MultiSelect>                 
              </div>
              <div class="w-full">
                <label for="sort_option" class="label-control">Option</label>
                <MultiSelect id="sort_option" v-model="sortOption" :options="sortOptions" :value="sortOption" track-by="field" label="name" :allowEmpty="false" :showLabels="false">
                  <template slot="caret">
                    <div>
                      <div class="multiselect__select">
                        <span>
                          <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                            <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </template>
                </MultiSelect>                 
              </div>                            
            </div>
            <div class="w-64 p-4">
              <div class="pb-2 mb-4 border-b border-gray-200">
                <h2 class="text-gray-800 font-medium">Date Filter</h2>
              </div>
              <div class="w-full mb-4">
                <label for="date_field" class="label-control">Field</label>
                <MultiSelect id="date_field" v-model="dateField" :options="dateFields" :value="dateField" track-by="field" label="name" :allowEmpty="false" :showLabels="false">
                  <template slot="caret">
                    <div>
                      <div class="multiselect__select">
                        <span>
                          <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                            <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </template>
                </MultiSelect>                 
              </div>             
              <v-date-picker ref="calendar" v-model="filterDate" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs" is-range>
                <template v-slot="{ inputValue, inputEvents, isDragging }">
                  <div class="md:flex gap-6">
                    <div class="w-full mb-4">                        
                      <label for="startDate" class="label-control">Start Date</label>
                      <div class="relative flex justify-between items-center">
                        <input id="startDate" ref="startDate" type="text" class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" autofocus>
                        <span class="h-full absolute pointer-events-none right-0">
                          <DateRange class="m-3" />
                        </span>                      
                      </div>
                    </div>
                  </div>
                  <div class="md:flex gap-6">
                    <div class="w-full mb-4">
                      <label for="endDate" class="label-control">End Date</label>
                      <div class="relative flex justify-between items-center">
                        <input id="endDate" ref="endDate" type="text" class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end">
                        <span class="h-full absolute pointer-events-none right-0">
                          <DateRange class="m-3" />
                        </span>                      
                      </div>
                    </div>
                  </div>
                </template>
              </v-date-picker>
              <div class="md:flex">
                <button :disabled="isLoading" @click.prevent="runFilter" type="button" class="btn btn--primary" alt="Apply" v-tooltip="'Apply Data Filters'">
                  <span v-if="isLoading" class="block">
                    Loading...
                  </span>
                  <span :class="{ 'hidden' : isLoading }">Apply</span>
                </button>
                <button :disabled="isLoading" type="button" class="ml-1 sm:-pt-10 btn btn--secondary" @click="clearInput">
                  <span>Clear</span>           
                </button>
              </div>                                                  
            </div>
          </div>
        </DropdownFilter>        
      </div>
    </div>
    <!-- TABLE SECTION -->
    <div class="mb-4 bg-white shadow-lg rounded-lg overflow-y-auto">
      <table>
        <thead>
          <tr>
            <th scope="col" class="text-left">Transfer No</th>
            <th scope="col" class="text-left">Date</th>
            <th scope="col" class="text-left">Transfer From</th>
            <th scope="col" class="text-left">Transfer To</th>
            <th scope="col" class="text-center">Status</th>
            <th scope="col" class="text-left">Issued By</th>
            <th scope="col" class="text-center">Actions</th>
          </tr>           
        </thead>
        <tbody>
          <tr v-if="totalRecords === 0" class="border-b border-gray-200 hover:bg-gray-100"><td class="text-center" colspan="7">No data to display</td></tr>
          <tr v-else-if="totalFiltered === 0" class="border-b border-gray-200 hover:bg-gray-100"><td class="text-center" colspan="7">No matching records found</td></tr>
          <tr v-for="(transfer, index) in transfers" :key="transfer.id" class="border-b border-gray-200 hover:bg-gray-100" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">
            <td class="text-left">{{ transfer.record_no }}</td>
            <td class="text-left">{{ transfer.transfer_date }}</td>
            <td class="text-left">{{ transfer.transfer_from.factory_name }}</td>
            <td class="text-left">{{ transfer.transfer_to.factory_name }}</td>
            <td class="text-center">
              <span class="badge" :class="transfer.status === 1 ? 'badge--light-primary' : (transfer.status === 2 ? 'badge--success' : 'badge--danger')">{{ transfer.status_text }}</span>
            </td>
            <td class="text-left">{{ transfer.created_user }}</td>
            <td class="text-center">
              <div class="flex item-center justify-center">
                <!-- Download Button Section -->
                <button @click="toggleModal(transfer.id)" type="button" class="btn-download" alt="Download" v-tooltip="'Download'">
                  <DownloadIcon />
                </button>
                <!-- Show Button Section -->                
                <router-link :to="{ name: 'transferShow', params: { id: transfer.id } }" class="btn-show" alt="Show" v-tooltip="'Show'">
                  <ShowIcon />
                </router-link>
                <!-- Edit Button Section -->          
                <router-link :to="{ name: 'transferEdit', params: { id: transfer.id } }" class="btn-edit" alt="Edit" v-tooltip="'Edit'">
                  <EditIcon />
                </router-link>
                <!-- Delete Button Section -->
                <button v-if="transfer.status === 1" @click="confirmDialog( transfer.id )" type="button" class="btn-delete" alt="Delete" v-tooltip="'Delete'">
                  <DeleteIcon />
                </button>
                <button v-else type="button" class="btn-delete" alt="Delete not allowed" v-tooltip="'Delete not allowed'">
                  <DeleteIcon />
                </button>
              </div>
            </td>
          </tr>
        </tbody>                              
      </table>
      <div class="flex px-5 py-5 bg-white justify-between items-center">
        <div class="flex-shrink text-sm">
          <span>Showing {{ fromRecord }} to {{ toRecord }} of {{ totalFiltered }} Entries </span><span v-if="totalFiltered !== totalRecords"> ( filtered from {{ totalRecords }} total entries )</span>
        </div>
        <div class="pagination">
          <button type="button" :disabled="(currentPage - 1) === 0" @click="prevPage" class="page-item">
            <span class="text-sm">Prev</span>
          </button>
          <button type="button" :disabled="currentPage === lastPage || currentPage > lastPage" @click="nextPage" class="page-item">
            <span class="text-sm">Next</span>
          </button>
        </div>
      </div>                            
    </div>
    <!-- Export Modal Dialog -->
    <modal :show="showModal" @close="showModal = false" addClass="modal-xl" modalOrientation="pt-16 md:pt-10">
      <template v-slot:header><h3>{{ modalTitle }}</h3></template>
      <template v-slot:body>
        <div class="flex items-center justify-between mb-6">
          <div class="items-center"></div>
          <div class="items-center right-0">
            <button :disabled="isLoading" type="button" class="btn btn--danger" @click="downloadFile(exportID, 'pdf')">
              <span v-if="isLoading" class="block">
                Wait...
              </span>
              <span :class="{ 'hidden' : isLoading }">Save as PDF</span>
            </button>
            <button :disabled="isLoading" type="button" class="btn btn--success ml-2" @click="downloadFile(exportID, 'xlsx')">
              <span :disabled="isLoading">Save as XLS</span>
            </button>               
          </div>
        </div>
        <div class="mb-4 bg-white overflow-hidden">
          <div v-if="exportData" class="w-full">
            <div class="w-full mb-4">
              <h1 class="text-2xl text-right">TRANSFER STOCK</h1>
            </div>
            <div class="w-full md:flex justify-between mb-4 gap-4">
              <div class="md:w-1/2">
                <h1 class="block text-lg">DU JEU DE BOULES</h1>
                <span class="block text-sm">23 RUE DES BLANCS MANTEAUX, 75004 PARIS</span>
                <span class="block text-sm">FRANCE</span>
                <span class="block text-sm">Phone Number: 0033 153 36 07 79</span>
              </div>
              <div class="flex md:w-1/2 gap-6">
                <div class="w-full md:w-4/6">
                  <span class="block text-sm text-right">DATE</span>
                  <span class="block text-sm text-right">PO NUMBER</span>
                </div>
                <div class="w-full md:w-2/6">
                  <span class="block text-sm">: {{ exportData.transfer_date }}</span>
                  <span class="block text-sm">: {{ exportData.record_no }}</span>                
                </div>
              </div>
            </div>
            <div class="w-full md:flex justify-between mb-4 gap-32">
              <div class="md:w-1/2">
                <h1 class="p-1 block text-sm bg-gray-200">TRANSFER FROM</h1>
                <span class="block font-semibold text-sm">{{ exportData.transfer_from.factory_name }}</span>
                <span class="block text-sm">{{ exportData.transfer_from.address }}</span>
                <span class="block text-sm">{{ exportData.transfer_from.city }}, {{ exportData.transfer_from.country }}</span>
              </div>
              <div class="md:w-1/2">
                <h1 class="p-1 block text-sm bg-gray-200">TRANSFER TO</h1>
                <span class="block font-semibold text-sm">{{ exportData.transfer_to.factory_name }}</span>
                <span class="block text-sm">{{ exportData.transfer_to.address }}</span>
                <span class="block text-sm">{{ exportData.transfer_to.city }}, {{ exportData.transfer_to.country }}</span>
              </div>
            </div>
            <div class="w-full mb-4">
              <table>
                <thead class="bg-gray-100">
                  <tr>
                    <th scope="col" class="py-1 px-1 text-center text-gray-700 border border-gray-200">No</th>
                    <th scope="col" class="py-1 px-1 text-center text-gray-700 border border-gray-200">Supplier</th>
                    <th scope="col" class="py-1 px-1 text-center text-gray-700 border border-gray-200">Reference</th>
                    <th scope="col" class="py-1 px-1 text-center text-gray-700 border border-gray-200">Size</th>
                    <th scope="col" class="py-1 px-1 text-center text-gray-700 border border-gray-200">Color</th>
                    <th scope="col" class="py-1 px-1 text-center text-gray-700 border border-gray-200">Qty</th>
                    <th scope="col" class="py-1 px-1 text-center text-gray-700 border border-gray-200">Unit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="exportData.details.length === 0"><td class="py-3 px-1 text-xs text-left border border-gray-200" colspan="7"></td></tr>
                  <tr v-for="(item, index) in exportData.details" :key="item.id">
                    <td class="py-1 px-1 text-xs text-center border border-gray-200">{{ index+1 }}</td>
                    <td class="py-1 px-1 text-xs text-left border border-gray-200">{{ item.supplier }}</td>
                    <td class="py-1 px-1 text-xs text-left border border-gray-200">{{ item.material_name }}</td>
                    <td class="py-1 px-1 text-xs text-left border border-gray-200">{{ item.size }}</td>
                    <td class="py-1 px-1 text-xs text-left border border-gray-200">{{ item.color }}</td>
                    <td class="py-1 px-1 text-xs text-right border border-gray-200">{{ item.transfer_qty }}</td>
                    <td class="py-1 px-1 text-xs text-center border border-gray-200">{{ item.unit }}</td>
                  </tr>
                </tbody>
              </table>              
            </div>
            <div class="w-full">
              <div class="w-full mb-1"><span class="text-sm">REMARKS:</span></div>
              <div class="w-full">
                <p class="text-sm">{{ exportData.remarks }}</p>
              </div>
            </div>
          </div>
        </div>   
      </template>
      <template v-slot:footer>
      </template> 
    </modal>           
  </div>
</template>

<script>
import format from '@/helpers/formatNumber'
import transferServices from '@/services/transfer/transferServices'
import transferDetailServices from '@/services/transfer/transferDetailServices'
import DropdownFilter from '../../widgets/DropdownFilter'
import PlusIcon from '../../icons/PlusIcon'
import DateRange from '../../icons/DateRange'
import ShowIcon from '../../icons/ShowIcon'
import EditIcon from '../../icons/EditIcon'
import DeleteIcon from '../../icons/DeleteIcon'
import Modal from '../../widgets/Modal'
import DownloadIcon from '../../icons/DownloadIcon'

export default {
  name: 'ListTransferStock',
  components: {
    DropdownFilter,
    PlusIcon,
    DateRange,
    ShowIcon,
    EditIcon,
    DeleteIcon,
    Modal,
    DownloadIcon
  },
  data() {
    return {
      take: 25,
      page: 1,
      search: null,
      dateField: { field: 'created_at', name: 'Created Date' },
      dateFields: [
        { field: 'created_at', name: 'Created Date' },
        { field: 'updated_at', name: 'Updated Date' },
        { field: 'transfer_date', name: 'Transfer Date' }
      ],
      filterDate: {
        start: '',
        end: ''
      },
      masks: {
        input: 'YYYY/MM/DD',
      },
      attrs: [
        {
          key: 'today',
          highlight: 'red',
          dates: new Date(),
        },
      ],
      sortField: { field: 'id', name: 'ID (Default)' },
      sortFields: [
        { field: 'record_no', name: 'Transfer No' },
        { field: 'transfer_date', name: 'Transfer Date' },
        { field: 'transfer_from_id', name: 'Transfer From' },
        { field: 'transfer_to_id', name: 'Transfer To' },
        { field: 'status', name: 'Status' },
        { field: 'updated_at', name: 'Updated' },
        { field: 'id', name: 'ID (Default)' }
      ],        
      sortOption: { field: 'desc', name: 'Descending' },
      sortOptions: [
        { field: 'asc', name: 'Ascending' },
        { field: 'desc', name: 'Descending' }
      ],      
      awaitingSearch: false,
      totalRecords: null,
      totalFiltered: null,
      fromRecord: null,
      toRecord: null,
      currentPage: null,
      lastPage: null,
      shows: [],
      transfers: [],
      error: [],
      isLoading: false,
      exportID: '',
      exportData: null,
      modalTitle: '',
      showModal: false,       
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async fetchData() {
      try {
        this.isLoading = true

        const params = {
          take: this.take,
          page: this.page,
          search: this.search,
          date_field: this.dateField.field,
          start: this.filterDate.start || '',
          end: this.filterDate.end || '',
          sort_field: this.sortField.field,
          sort_option: this.sortOption.field
        }
        const response = await transferServices.fetchLimit(params)
        if (response.data.status === 'success') {
          this.isLoading =false

          const records = response.data.data
          this.totalRecords = records.total
          this.totalFiltered = records.total_filter
          this.fromRecord = records.from
          this.toRecord = records.to
          this.currentPage = records.current_page
          this.lastPage = records.last_page
          this.shows = records.show
          this.transfers = records.data
        } else {
          this.isLoading =false

          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        this.isLoading =false
        console.log(error)
      }
    },
    async fetchById(id) {
      try {
        this.isLoading = true

        const response = await transferServices.fetchById(id)
        if (response.data.status === 'success') {
          this.isLoading =false

          this.exportID = id
          this.exportData = response.data.data

          this.exportData.details = []
          const recipeParams = {
            take: '',
            page: '',
            search: '',
            date_field: '',
            start: '',
            end: '',
            sort_field: 'id',
            sort_option: 'ASC'
          }            
          let details = await this.fetchDetailData(recipeParams, this.exportData.id)

          details.forEach(detail => {
            this.exportData.details.push({
              "id": detail.id,
              "transfer_stock_id": detail.transfer_stock_id,
              "supplier": detail.material.supplier.supplier_name,
              "material_id": detail.material_id,
              "material_name": detail.material_name,
              "color": detail.color,
              "size": detail.size,
              "unit": detail.unit,
              "in_stock": detail.in_stock,
              "transfer_qty": detail.transfer_qty           
            })              
          })
        } else {
          this.isLoading =false

          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        this.isLoading =false
        console.log(error)
      }
    },
    async fetchDetailData(params, id) {
      try {
        const response = await transferDetailServices.fetchAll(params, id)
        if (response.data.status === 'success') {
          return response.data.data
        }
      } catch (error) {
        console.log(error)
      }
    },    
    async delete(id) {
      try {
        const response = await transferServices.delete(id)
        return response.data
      } catch (error) {     
        return error
      }
    },
    async confirmDialog(id) {
      // console.log(id)
      this.$swal.fire({
        title: 'Are you sure ?',
        text: "You won't be able to revert this!",
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#4b5563',
        confirmButtonText: 'Yes'
      }).then(async (result) => {
        if (result.isConfirmed) {
          /* CALL DELETE FUNCTION */
          const result = this.delete(id)
          const response = await result

          if (response.status) {
            if (response.status === 'success') {
              /* REFRESH DATA */
              this.fetchData()

              this.$swal.fire({
                title: 'Deleted!',
                text: "Your record has been deleted",
                icon: 'success',
                showConfirmButton: false,
                timer: 3000            
              })              
            } else {
              this.$swal.fire({
                title: 'Error!',
                text: response.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 3000            
              })              
            }
          } else {
            this.$toasted.error(response.message, this.toastedOptions)           
          }
        }
      })
    },
    updateQueryString() {
      const search = this.search ? this.search.toLowerCase() : ''
      const start = this.filterDate.start ? new Date(this.filterDate.start).toISOString().slice(0,10) : ''
      const end = this.filterDate.end ? new Date(this.filterDate.end).toISOString().slice(0,10) : ''
      const page = this.page

      this.$router.replace({ 
        query: {
          take: this.take,
          page: page,
          search: search,
          date_field: this.dateField.field,
          start: start,
          end: end,
          sort_field: this.sortField.field,
          sort_option: this.sortOption.field
        } 
      }).catch(() => {})
    },
    clearInput() {
      this.filterDate = {}
      this.runFilter()
    },
    runFilter() {
      this.page = 1
      this.updateQueryString()
      this.fetchData()
    },
    searchFocus(event) {
      if (event.keyCode === 191) {
        setTimeout(() => {
          this.$refs.search.focus()
        }, 5)
      }
    },
    prevPage() {
      this.page = this.currentPage - 1
      this.updateQueryString()
      this.fetchData()
    },
    nextPage() {
      this.page = this.currentPage + 1
      this.updateQueryString()
      this.fetchData()
    },
    updateTake() {
      this.page = 1
      this.updateQueryString()
      this.fetchData()      
    },
    runSearch() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.page = 1
          this.updateQueryString()
          this.fetchData()
          this.awaitingSearch = false
        }, 1500)
      }
      this.awaitingSearch = true
    },        
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    },
    toggleModal(id) {
      this.showModal = true
      this.modalTitle = 'Export'
      this.fetchById(id)
    },
    openDownloadTab(key) {
      let baseUrl = window.location.origin
      window.open(baseUrl+'/auth/download?key='+key, '_blank')
    },    
    async downloadFile(id, fileType) {
      try {
        this.isLoading = true
        const payload = {
          file_type: fileType
        }
        const response = await transferServices.export(id, payload)
        if (response.data.status === 'success') {
          this.isLoading = false
          this.$toasted.success(response.data.message, this.toastedOptions)

          const key = response.data.data
          this.openDownloadTab(key)
        } else {
          this.isLoading = false
          this.$toasted.error(response.data.message, this.toastedOptions)
        }
      } catch (error) {
        this.isLoading = false
        console.log(error)
      }
    }    
  },
  created() {
    this.fetchData()
    document.addEventListener("keydown", this.searchFocus);
  },
  destroyed() {
    document.removeEventListener("keydown", this.searchFocus);
  },
  watch: {
    '$route.query.take': {
      handler: function(take) {
        if (take) {
          this.take = take
        }
      },
      immediate: true
    },    
    '$route.query.page': {
      handler: function(page) {
        this.page = page
      },
      immediate: true
    },
    '$route.query.search': {
      handler: function(search) {
        this.search = search
      },
      immediate: true
    },
    '$route.query.date_field': {
      handler: function(date_field) {
        try {
          const option = this.dateFields.filter(item => date_field.includes(item.field))
          this.dateField = { field: option[0].field, name: option[0].name }          
        } catch (error) {
          this.dateField = { field: 'created_at', name: 'Created Date' }        
        }
      },
      immediate: true
    },
    '$route.query.start': {
      handler: function(start) {
        this.filterDate.start = start
      },
      immediate: true
    },
    '$route.query.end': {
      handler: function(end) {
        this.filterDate.end = end
      },
      immediate: true
    },
    '$route.query.sort_field': {
      handler: function(sort_field) {
        try {
        const option = this.sortFields.filter(item => sort_field.includes(item.field))
        this.sortField = { field: option[0].field, name: option[0].name }          
        } catch (error) {
          this.sortField = { field: 'id', name: 'Latest' }
        }
      },
      immediate: true
    },
    '$route.query.sort_option': {
      handler: function(sort_option) {
        try {
          const option = this.sortOptions.filter(item => sort_option.includes(item.field))
          this.sortOption = { field: option[0].field, name: option[0].name }          
        } catch (error) {
          this.sortOption = { field: 'desc', name: 'Descending' }
        }
      },
      immediate: true
    } 
  }
}
</script>