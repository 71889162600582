<template>
  <div class="sticky top-0 z-50 lg:z-50 w-full max-w-8xl mx-auto bg-white flex-none flex">
    <div class="flex-none pl-4 sm:pl-6 xl:pl-8 flex items-center border-b border-gray-200 lg:border-b-0 lg:w-52 xl:w-60">
      <!-- <img :src="LogoSource" alt="Logo" width="220px"> --> JEU DE BOULES
    </div>
    <div class="flex-auto border-b border-gray-200 h-16 flex items-center justify-between px-4 sm:px-6 lg:mx-6 lg:px-0 xl:mx-8">
      <div class="flex items-center transition-colors duration-200 py-2"></div>
      <DropdownUser />      
    </div>
  </div>
</template>

<script>
import DropdownUser from './DropdownUser'
// import Logo from '../../../assets/images/logo.png'

export default {
  components: {
    DropdownUser
  },
  data() {
    return {
      // LogoSource: Logo
    }
  }
}
</script>