<template>
  <div>
    <button type="button" class="btn btn--danger ml-2" @click="toggleModal">
      <UploadIcon>
        <span class="ml-2 md:block hidden">Import</span>
      </UploadIcon>
    </button>
    <!-- Import Modal Dialog -->
    <modal :show="showModal" @close="showModal = false" addClass="modal-xl" modalOrientation="py-16 md:py-10">
      <template v-slot:header><h3>{{ modalTitle }}</h3></template>
      <template v-slot:body>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form id="importForm" @submit.prevent="handleSubmit(importOrder)">
            <div class="md:flex gap-2">
              <div class="md:w-2/12 mb-4">
                <ValidationProvider name="Season" rules="" v-slot="{ errors }">
                  <MultiSelect id="season" v-model="season" :options="seasons" :value="season" :searchable="true" track-by="id" label="name" placeholder="Type to Search" :showLabels="false">
                    <template slot="caret">
                      <div>
                        <div class="multiselect__select">
                          <span>
                            <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                              <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </template>
                  </MultiSelect>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="error.season" class="text-sm text-red-600"><span>{{ error.season[0] }}</span></div>              
              </div>              
              <div class="md:w-2/12 mb-4">
                <ValidationProvider name="Delivery Period" rules="" v-slot="{ errors }">
                  <MultiSelect id="period" v-model="period" :options="periods" :value="period" :searchable="true" track-by="id" label="name" placeholder="Type to Search" :showLabels="false">
                    <template slot="caret">
                      <div>
                        <div class="multiselect__select">
                          <span>
                            <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                              <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </template>
                  </MultiSelect>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="error.period" class="text-sm text-red-600"><span>{{ error.period[0] }}</span></div>              
              </div>
              <div class="md:w-2/12 mb-4">
                <ValidationProvider name="Factory" rules="" v-slot="{ errors }">
                  <MultiSelect id="factory" v-model="factory" :options="factories" :value="factory" :searchable="true" track-by="id" label="name" placeholder="Type to Search" :showLabels="false">
                    <template slot="caret">
                      <div>
                        <div class="multiselect__select">
                          <span>
                            <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                              <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </template>
                  </MultiSelect>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="error.factory" class="text-sm text-red-600"><span>{{ error.factory[0] }}</span></div>              
              </div>                            
              <div class="md:w-3/12 mb-4">
                <ValidationProvider name="File" rules="required" ref="provider" v-slot="{ validate, errors }">
                  <input id="file" ref="file" type="file" class="form-control" @change="onFileChange" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="error.file" class="text-sm text-red-600"><span>{{ error.file[0] }}</span></div>
              </div>
              <div class="md:w-3/12 mb-4">
                <button :disabled="isLoading" type="submit" form="importForm" class="btn-md btn--primary">
                  <span v-if="isLoading">Loading...</span>
                  <span v-if="!isLoading">Upload File</span>
                </button>
                <button :disabled="isLoading" type="button" @click="downloadFile()" class="btn-md btn--success ml-2">
                  <span v-if="isDownloadLoading">Loading...</span>
                  <span v-if="!isDownloadLoading">Download Draft</span>
                </button> 
              </div>
            </div>
          </form> 
        </ValidationObserver>
        <div v-if="datas.length > 0" class="mb-4 bg-white shadow-lg rounded-lg overflow-y-auto">
          <table>
            <thead>
              <tr>
                <th scope="col" class="px-2 text-left border-r border-purple-300 border-opacity-50" rowspan="2">Customer</th>
                <th scope="col" class="px-2 text-left border-r border-purple-300 border-opacity-50" rowspan="2">Country</th>
                <th scope="col" class="px-2 text-left border-r border-purple-300 border-opacity-50" rowspan="2">Order No</th>
                <th scope="col" class="px-2 text-center border-r border-purple-300 border-opacity-50" rowspan="2">Delivery Date Production</th>
                <th scope="col" class="px-2 text-center border-r border-b border-purple-300 border-opacity-50" colspan="2">Delivery Date for Customer</th>
                <th scope="col" class="px-2 text-left border-r border-purple-300 border-opacity-50" rowspan="2">Barcode</th>
                <th scope="col" class="px-2 text-left border-r border-purple-300 border-opacity-50" rowspan="2">Ref</th>
                <th scope="col" class="px-2 text-left border-r border-purple-300 border-opacity-50" rowspan="2">Color</th>
                <th scope="col" class="px-2 text-left border-r border-purple-300 border-opacity-50" rowspan="2">Description</th>
                <th scope="col" class="px-2 text-center" rowspan="2">Qty</th>
              </tr>
              <tr>
                <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center date-column">From</th>
                <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center date-column">To</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, key) in datas" :key="key" :class="{ 'bg-gray-50' : (key % 2 !== 0) }">
                <td class="px-2 text-left">{{ data.customer }}</td>
                <td class="px-2 text-left">{{ data.country }}</td>
                <td class="px-2 text-left">{{ data.order_no }}</td>
                <td class="px-2 text-left">{{ data.delivery_date_production }}</td>
                <td class="px-2 text-left">{{ data.delivery_date_from }}</td>
                <td class="px-2 text-left">{{ data.delivery_date_to }}</td>
                <td class="px-2 text-left">{{ data.barcode }}</td>             
                <td class="px-2 text-left">{{ data.reference }}</td>
                <td class="px-2 text-left">{{ data.color }}</td>
                <td class="px-2 text-left">{{ data.description }}</td>
                <td class="px-2 text-center">{{ data.qty }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template v-slot:footer>     
      </template> 
    </modal>
  </div>
</template>

<script>
import seasonServices from '@/services/season/seasonServices'
import factoryServices from '@/services/factory/factoryServices'
import orderServices from '@/services/order/orderServices'
import Modal from '../../widgets/Modal'
import UploadIcon from '../../icons/UploadIcon'

export default {
  name: 'ImportOrder',
  components: {
    Modal,
    UploadIcon
  },
  data() {
    return {
      season: '',
      seasons: [],
      factory: '',
      factories: [],      
      period: '',
      periods: [
        { 'id': 1, 'name': 'Period 1' }, { 'id': 2, 'name': 'Period 2' }, { 'id': 3, 'name': 'Period 3' },
        { 'id': 4, 'name': 'Period 4' }, { 'id': 5, 'name': 'Period 5' }, { 'id': 6, 'name': 'Period 6' }
      ],
      file: null,
      datas: [],
      error: [],
      isLoading: false,
      isDownloadLoading: false, 
      modalTitle: '',     
      showModal: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }         
    }
  },
  methods: {
    async fetchSeasonData() {
      try {
        const response = await seasonServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.seasons = []
          records.forEach(element => {
            this.seasons.push({
              'id': element.id,
              'name': element.season_name
            })
          });          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchFactoryData() {
      try {
        const response = await factoryServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.factories = []
          records.forEach(element => {
            this.factories.push({
              'id': element.id,
              'name': element.factory_name
            })
          });          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },        
    async importOrder() {
      try {
        this.isLoading = true
        let payload = new FormData()
        payload.append('season', this.season.id ?? '')
        payload.append('period', this.period.id ?? '')
        payload.append('factory', this.factory.id ?? '')
        payload.append('file', this.file)

        const response = await orderServices.import(payload)
        if (response.data.status === 'success') {
          this.isLoading = false

          /* REFRESH ORDER LIST */
          this.fetchData()           

          /* THROW SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)           
        } else {
          this.isLoading = false

          /* STORE ERRORS DATA TO DATA VARIABLE */
          this.datas = response.data.data;

          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)            
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions) 
      }
    },
    async onFileChange(e) {
      const { valid } = await this.$refs.provider.validate(e)
      if (valid) {
        let files = e.target.files || e.dataTransfer.files
        if (!files.length) {
          return
        }

        this.file = this.$refs.file.files[0]
        this.datas = []
      }
    },
    toggleModal() {
      this.$refs.file.value = null
      this.datas = []
      this.showModal = true
      this.modalTitle = 'Import Orders'
      this.fetchSeasonData()
      this.fetchFactoryData()      
    },
    fetchData() {
      this.$root.$emit('fetchData')
    },
    openDownloadTab(key) {
      let baseUrl = window.location.origin
      window.open(baseUrl+'/auth/download?key='+key, '_blank')
    },    
    async downloadFile() {
      try {
        this.isLoading = true

        const response = await orderServices.exportDraft()
        if (response.data.status === 'success') {
          this.isLoading = false
          this.$toasted.success(response.data.message, this.toastedOptions)

          const key = response.data.data
          this.openDownloadTab(key)
        } else {
          this.isLoading = false
          this.$toasted.error(response.data.message, this.toastedOptions)
        }
      } catch (error) {
        this.isLoading = false
        console.log(error)
      }
    }     
  } 
}
</script>