<template>
  <div>
    <!-- ACTION PANEL SECTION -->
    <div class="flex border-b border-gray-200 text-gray-800 justify-between items-center mb-4 pb-2">
      <div class="md:w-1/2 w-full items-center">
        <h2 class="text-2xl font-medium">Items</h2>
      </div>
      <div class="flex items-center right-0">
        <template v-if="isPO">
          <button v-if="show" :disabled="parentLoading" type="button" class="btn btn--success" id="addPOItem" @click="toggleNewPO">
            <PlusIcon>
              <span class="ml-2 md:block hidden">Add PO Item</span>
            </PlusIcon>
          </button>
        </template>
        <button v-if="show" :disabled="parentLoading" type="button" class="btn btn--success ml-2" id="addItem" @click="toggleNew">
          <PlusIcon>
            <span class="ml-2 md:block hidden">Add Item</span>
          </PlusIcon>
        </button>       
      </div>
    </div>     
    <!-- TABLE SECTION -->
    <div class="mb-4 bg-white shadow-lg rounded-lg overflow-y-auto">
      <table>
        <thead>
          <tr>
            <th scope="col" class="px-3 text-left">Supplier</th>
            <th scope="col" class="px-3 text-left">Reference</th>
            <th scope="col" class="px-3 text-left">Color</th>
            <th scope="col" class="px-3 text-left">Size</th>
            <th scope="col" class="px-3 text-left">Unit</th>
            <th scope="col" class="px-3 text-center price-column">Buy Price</th>
            <th scope="col" class="px-3 text-center price-column">Convertion Price</th>
            <th scope="col" class="px-3 text-center">Qty</th>
            <th scope="col" class="px-3 text-center price-column">Total Price</th>
            <th scope="col" class="px-3 text-center" v-if="show">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="details.length === 0" class="border-b border-gray-200 hover:bg-gray-100"><td class="px-3 text-center" colspan="10">No data to display</td></tr>
          <tr v-for="(detail, index) in details" :key="detail.id" class="border-b border-gray-200 hover:bg-gray-100" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">
            <td class="px-3 text-left">{{ detail.supplier }}</td>
            <td class="px-3 text-left">
              <span class="block">{{ detail.material_name }}</span>
              <span>{{ detail.type === 1 ? '[ PO Item ]' : '' }}</span>
            </td>
            <td class="px-3 text-left">{{ detail.color }}</td>
            <td class="px-3 text-left">{{ detail.size }}</td>
            <td class="px-3 text-left">{{ detail.unit }}</td>
            <td class="px-3 text-right">{{ formatNumber(detail.buy_price) }} {{ detail.buy_symbol }}</td>
            <td class="px-3 text-right">{{ formatNumber(detail.price) }} €</td>
            <td class="px-3 text-right">{{ formatNumber(detail.qty) }}</td>
            <td class="px-3 text-right">{{ formatNumber(detail.total_price) }} €</td>
            <td class="px-3 text-center" v-if="show">
              <div class="flex item-center justify-center">
                <button v-if="detail.type === 1" :disabled="parentLoading" @click="toggleEditPO( detail.id )" type="button" class="btn-edit" alt="Edit" v-tooltip="'Edit'">
                  <EditIcon />
                </button>
                <button v-else :disabled="parentLoading" @click="toggleEdit( detail.id )" type="button" class="btn-edit" alt="Edit" v-tooltip="'Edit'">
                  <EditIcon />
                </button>                        
                <button :disabled="parentLoading" @click="confirmDialog( detail.id )" type="button" class="btn-delete" alt="Delete" v-tooltip="'Delete'">
                  <DeleteIcon />
                </button>
              </div>
            </td>
          </tr>
          <tr class="border-b border-gray-200 bg-gray-50">
            <td class="px-3 text-left" colspan="7"><span class="font-medium">GRAND TOTAL</span></td>
            <td class="px-3 text-right" colspan="2"><span class="font-medium">{{ formatNumber(this.grandTotal) }} €</span></td>
            <td class="px-3 text-center" v-if="show"></td>
          </tr>
        </tbody>
      </table>
      <div class="flex px-5 py-5 bg-white justify-between items-center"></div>                            
    </div>
    <!-- Modal Dialog -->
    <modal :show="showModal" @close="showModal = false" addClass="modal-lg" modalOrientation="flex items-center justify-center">
      <template v-slot:header><h3>{{ modalTitle }}</h3></template>
      <template v-slot:body>
        <form id="modalForm" @submit.prevent="save">
          <div class="md:flex gap-2">
            <div class="w-full mb-4">
              <label for="material" class="label-control">Material <span class="text-red-600">*</span></label>
              <MultiSelect id="material" v-model="material" :options="materials" :value="material" :searchable="true" @input="toggleFocus" track-by="id" label="name" :custom-label="nameWithColor" placeholder="Type to Search">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>
              <div v-if="error.material" class="text-sm text-red-600"><span>{{ error.material[0] }}</span></div>             
            </div>
          </div>
          <div class="md:flex gap-2">
            <div class="w-full mb-4">
              <h3 class="text-lg">Details</h3>
            </div>
          </div>
          <div class="md:flex gap-2">
            <div class="w-full mb-4">
              <label for="reference" class="label-control">Reference</label>
              <input id="reference" type="text" v-model="reference" class="form-control" readonly>
              <div v-if="error.reference" class="text-sm text-red-600"><span>{{ error.reference[0] }}</span></div>                
            </div>
          </div>          
          <div class="md:flex gap-2">
            <div class="md:w-1/2 mb-4">
              <label for="color" class="label-control">Color</label>
              <input id="color" type="text" v-model="color" class="form-control" readonly>
              <div v-if="error.color" class="text-sm text-red-600"><span>{{ error.color[0] }}</span></div> 
            </div>
            <div class="flex md:w-1/2 gap-2 mb-4">
              <div class="md:w-1/2">
                <label for="size" class="label-control">Size</label>
                <input id="size" type="text" v-model="size" class="form-control" readonly>
                <div v-if="error.size" class="text-sm text-red-600"><span>{{ error.size[0] }}</span></div>              
              </div>
              <div class="md:w-1/2">
                <label for="unit" class="label-control">Unit</label>
                <input id="unit" type="text" v-model="unit" class="form-control" readonly>
                <div v-if="error.unit" class="text-sm text-red-600"><span>{{ error.unit[0] }}</span></div>
              </div>
            </div>
          </div>
          <div class="md:flex gap-2">
            <div class="flex md:w-1/2 gap-2 mb-4">
              <div class="w-full md:flex md:w-3/5 gap-2">
                <div class="md:w-2/5">
                  <label for="buy_rate" class="label-control">Buy Rate <span class="text-red-600">*</span></label>
                  <input id="buy_rate" type="text" v-model="buyRate" @input="handleInputBuyRate" class="form-control text-right">
                  <div v-if="error.buy_rate" class="text-sm text-red-600"><span>{{ error.buy_rate[0] }}</span></div>                
                </div>
                <div class="md:w-3/5">
                  <label for="buy_price" class="label-control">Buy Price <span class="text-red-600">*</span></label>
                  <div class="relative flex-1">
                    <span fill="currentColor" class="absolute right-2 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">{{ buyCurrencySymbol }}</span>
                    <input id="buy_price" type="text" v-model="buyPrice" @input="handleInputBuyPrice" class="form-control text-right right-symbol">
                  </div>
                  <div v-if="error.buy_price" class="text-sm text-red-600"><span>{{ error.buy_price[0] }}</span></div>                
                </div>
              </div>
              <div class="md:w-2/5">
                <label for="price" class="label-control">Convertion Price <span class="text-red-600">*</span></label>
                <div class="relative flex-1">
                  <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
                  <input id="price" type="text" v-model="price" class="form-control text-right right-symbol" readonly>
                </div>
                <div v-if="error.price" class="text-sm text-red-600"><span>{{ error.price[0] }}</span></div>
              </div>
            </div>
            <div class="flex md:w-1/2 gap-2 mb-4">
              <div class="md:w-1/2">
                <label for="qty" class="label-control">Qty<span class="text-red-600">*</span></label>
                <input id="qty" ref="qty" type="text" v-model="qty" @input="handleInputQty" class="form-control text-right">
                <div v-if="error.qty" class="text-sm text-red-600"><span>{{ error.qty[0] }}</span></div>                          
              </div>
              <div class="md:w-1/2">
                <label for="total" class="label-control">Total Price</label>
                <div class="relative flex-1">
                  <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
                  <input id="total" type="text" v-model="total" class="form-control text-right right-symbol" readonly>
                </div>
                <div v-if="error.total" class="text-sm text-red-600"><span>{{ error.total[0] }}</span></div>
              </div>
            </div>
          </div>
        </form>     
      </template>
      <template v-slot:footer>
        <button :disabled="isLoading" type="submit" form="modalForm" class="btn btn--primary">
          Save
        </button>      
      </template> 
    </modal>
    <!-- Modal Dialog PO Item -->
    <modal :show="showModalPO" @close="showModalPO = false" addClass="modal-lg" modalOrientation="flex items-center justify-center">
      <template v-slot:header><h3>{{ modalPOTitle }}</h3></template>
      <template v-slot:body>
        <form id="modalPOForm" @submit.prevent="savePO">
          <div class="md:flex gap-2">
            <div class="w-full mb-4">
              <label for="po_material" class="label-control">Material <span class="text-red-600">*</span></label>
              <MultiSelect id="po_material" v-model="poMaterial" :options="poMaterials" :value="poMaterial" :searchable="true" @input="togglePoFocus" track-by="id" label="name" :custom-label="nameWithColor" placeholder="Type to Search">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>
              <div v-if="error.reference" class="text-sm text-red-600"><span>{{ error.reference[0] }}</span></div>             
            </div>
          </div>
          <div class="md:flex gap-2">
            <div class="w-full mb-4">
              <h3 class="text-lg">Details</h3>
            </div>
          </div>
          <div class="md:flex gap-2">
            <div class="w-full mb-4">
              <label for="po_reference" class="label-control">Reference</label>
              <input id="po_reference" type="text" v-model="poReference" class="form-control" readonly>
              <div v-if="error.reference" class="text-sm text-red-600"><span>{{ error.reference[0] }}</span></div>                
            </div>
          </div>          
          <div class="md:flex gap-2">
            <div class="md:w-1/2 mb-4">
              <label for="po_color" class="label-control">Color</label>
              <input id="po_color" type="text" v-model="poColor" class="form-control" readonly>
              <div v-if="error.color" class="text-sm text-red-600"><span>{{ error.color[0] }}</span></div> 
            </div>
            <div class="flex md:w-1/2 gap-2 mb-4">
              <div class="md:w-1/2">
                <label for="po_size" class="label-control">Size</label>
                <input id="po_size" type="text" v-model="poSize" class="form-control" readonly>
                <div v-if="error.size" class="text-sm text-red-600"><span>{{ error.size[0] }}</span></div>              
              </div>
              <div class="md:w-1/2">
                <label for="po_unit" class="label-control">Unit</label>
                <input id="po_unit" type="text" v-model="poUnit" class="form-control" readonly>
                <div v-if="error.unit" class="text-sm text-red-600"><span>{{ error.unit[0] }}</span></div>
              </div>
            </div>
          </div>
          <div class="md:flex gap-2">
            <div class="flex md:w-1/2 gap-2 mb-4">
              <div class="w-full md:flex md:w-3/5 gap-2">
                <div class="md:w-2/5">
                  <label for="po_buy_rate" class="label-control">Buy Rate <span class="text-red-600">*</span></label>
                  <input id="po_buy_rate" type="text" v-model="poBuyRate" @input="handleInputPoBuyRate" class="form-control text-right">
                  <div v-if="error.buy_rate" class="text-sm text-red-600"><span>{{ error.buy_rate[0] }}</span></div>                
                </div>
                <div class="md:w-3/5">
                  <label for="po_buy_price" class="label-control">Buy Price <span class="text-red-600">*</span></label>
                  <div class="relative flex-1">
                    <span fill="currentColor" class="absolute right-2 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">{{ poBuyCurrencySymbol }}</span>
                    <input id="po_buy_price" type="text" v-model="poBuyPrice" @input="handleInputPoBuyPrice" class="form-control text-right right-symbol">
                  </div>
                  <div v-if="error.buy_price" class="text-sm text-red-600"><span>{{ error.buy_price[0] }}</span></div>                
                </div>
              </div>
              <div class="md:w-2/5">
                <label for="po_price" class="label-control">Convertion Price <span class="text-red-600">*</span></label>
                <div class="relative flex-1">
                  <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
                  <input id="po_price" type="text" v-model="poPrice" class="form-control text-right right-symbol" readonly>
                </div>
                <div v-if="error.price" class="text-sm text-red-600"><span>{{ error.price[0] }}</span></div>
              </div>
            </div>
            <div class="flex md:w-1/2 gap-2 mb-4">
              <div class="md:w-1/2">
                <label for="po_qty" class="label-control">Qty<span class="text-red-600">*</span></label>
                <input id="po_qty" ref="po_qty" type="text" v-model="poQty" @input="handleInputPoQty" class="form-control text-right">
                <div v-if="error.qty" class="text-sm text-red-600"><span>{{ error.qty[0] }}</span></div>                          
              </div>
              <div class="md:w-1/2">
                <label for="po_total" class="label-control">Total Price</label>
                <div class="relative flex-1">
                  <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
                  <input id="po_total" type="text" v-model="poTotal" class="form-control text-right right-symbol" readonly>
                </div>
                <div v-if="error.total" class="text-sm text-red-600"><span>{{ error.total[0] }}</span></div>
              </div>
            </div>
          </div>
        </form>     
      </template>
      <template v-slot:footer>
        <button :disabled="isLoading" type="submit" form="modalPOForm" class="btn btn--primary">
          Save
        </button>      
      </template> 
    </modal>        
  </div>  
</template>

<script>
import { mapGetters } from 'vuex'
import format from '@/helpers/formatNumber'
import materialServices from '@/services/material/materialServices'
import receiveDetailServices from '@/services/receive/receiveDetailServices'
import purchaseDetailServices from '@/services/purchase/purchaseDetailServices'
import Modal from '../../widgets/Modal'
import PlusIcon from '../../icons/PlusIcon'
import EditIcon from '../../icons/EditIcon'
import DeleteIcon from '../../icons/DeleteIcon'

export default {
  name: 'ReceiveDetail',
  components: {
    Modal,
    PlusIcon,
    EditIcon,
    DeleteIcon,
  },
  props: ['show', 'isPO', 'parentLoading'],
  data() {
    return {
      modalTitle: '',
      modalPOTitle: '',  
      showModal: false,
      showModalPO: false,
      details: [],
      detailId: '',
      material: '',
      reference: '',
      color: '',
      size: '',
      unit: '',
      buyCurrency: '',
      buyCurrencySymbol: '',
      buyRate: '',
      buyPrice: '',
      price: 0,
      qty: 0,
      total: 0,
      grandTotal: 0,
      materials: [],
      poDetailId: '',
      poMaterial: '',
      poReference: '',
      poColor: '',
      poSize: '',
      poUnit: '',
      poBuyCurrency: '',
      poBuyCurrencySymbol: '',
      poBuyRate: '',
      poBuyPrice: '',
      poPrice: 0,
      poQty: 0,
      poTotal: 0,
      poGrandTotal: 0,
      poMaterials: [],
      error: [],
      isEdit: false,
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async fetchDetailData() {
      try {
        this.isLoading = true

        const id = this.$route.params.id
        const response = await receiveDetailServices.fetchAll(null, id)
        if (response.data.status === 'success') {
          this.isLoading =false

          this.details = response.data.data

          this.grandTotal = 0
          this.details.forEach(element => {
            this.grandTotal += parseFloat(element.total_price)
          });
          this.grandTotal = this.toFixed(this.grandTotal, 2)
        } else {
          this.isLoading =false

          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        this.isLoading =false
        console.log(error)
      }
    },
    async fetchMaterialData() {
      try {
        const params = {
          take: '',
          page: '',
          search: '',
          date_field: '',
          start: '',
          end: '',
          sort_field: 'material_name',
          sort_option: 'ASC'
        }        
        const response = await materialServices.fetchDataOptions(params)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.materials = []
          records.forEach(element => {
            this.materials.push({
              'id': element.id,
              'supplier': element.supplier,
              'name': element.material_name,
              'color': element.color,
              'size': element.size,
              'unit': element.unit,
              'buy_currency': element.buy_currency,
              'buy_symbol': element.buy_symbol,
              'buy_rate': element.buy_rate,
              'buy_price': element.buy_price,
              'price': element.current_price            
            })
          });          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchPOMaterialData() {
      try {       
        const params = {
          po_number: this.poNumber
        }
        const response = await purchaseDetailServices.fetchAllByReceiving(params)
        if (response.data.status === 'success') {
          const records = response.data.data

          this.poMaterials = []
          records.forEach(element => {
            this.poMaterials.push({
              'id': element.id,
              'supplier': element.supplier_name,
              'name': element.material_name,
              'color': element.color,
              'size': element.size,
              'unit': element.unit,
              'buy_currency': element.buy_currency,
              'buy_symbol': element.symbol,
              'buy_rate': element.buy_rate,
              'buy_price': element.buy_price,
              'price': element.current_price
            })
          });          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },    
    async fetchData(id){
      try {
        const response = await receiveDetailServices.fetchById(id)
        if (response.data.status === 'success') {
          this.record = response.data.data

          this.material = {
            'id': this.record.material_id,
            'supplier': this.record.supplier,
            'name': this.record.material_name,
            'color': this.record.color,
            'size': this.record.size,
            'unit': this.record.unit,
            'buy_currency': this.record.buy_currency,
            'buy_symbol': this.record.buy_symbol,
            'buy_rate': this.record.buy_rate,
            'buy_price': this.record.buy_price,            
            'price': this.record.price,
          }
          this.reference = this.record.material_name
          this.color = this.record.color
          this.size = this.record.size
          this.unit = this.record.unit
          this.buyCurrency = this.record.buy_currency
          this.buyCurrencySymbol = this.record.buy_symbol
          this.buyRate = this.formatNumber(this.record.buy_rate)
          this.buyPrice = this.formatNumber(this.record.buy_price)
          this.price = this.formatNumber(this.record.price)
          this.qty = this.formatNumber(this.record.qty)
          this.total = this.formatNumber(this.record.total_price)
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)            
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchPOData(id){
      try {
        const response = await receiveDetailServices.fetchById(id)
        if (response.data.status === 'success') {
          this.record = response.data.data

          this.poMaterial = {
            'id': this.record.material_id,
            'supplier': this.record.supplier,
            'name': this.record.material_name,
            'color': this.record.color,
            'size': this.record.size,
            'unit': this.record.unit,
            'buy_currency': this.record.buy_currency,
            'buy_symbol': this.record.buy_symbol,
            'buy_rate': this.record.buy_rate,
            'buy_price': this.record.buy_price,            
            'price': this.record.price,
          }
          this.poReference = this.record.material_name
          this.poColor = this.record.color
          this.poSize = this.record.size
          this.poUnit = this.record.unit
          this.poBuyCurrency = this.record.buy_currency
          this.poBuyCurrencySymbol = this.record.buy_symbol
          this.poBuyRate = this.formatNumber(this.record.buy_rate)
          this.poBuyPrice = this.formatNumber(this.record.buy_price)
          this.poPrice = this.formatNumber(this.record.price)
          this.poQty = this.formatNumber(this.record.qty)
          this.poTotal = this.formatNumber(this.record.total_price)
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)            
        }
      } catch (error) {
        console.log(error)
      }
    },        
    async save(){
      try {
        this.isLoading = true
        const payload = {
          receive_id: this.$route.params.id,
          type: 0,
          material: this.material.id,
          reference: this.reference,
          color: this.color,
          size: this.size,
          unit: this.unit,
          buy_currency: this.buyCurrency,
          buy_rate: this.unformatNumber(this.buyRate),
          buy_price: this.unformatNumber(this.buyPrice),
          price: this.unformatNumber(this.price),
          qty: this.unformatNumber(this.qty),
          total: this.unformatNumber(this.total)
        }

        let response = ''
        if (this.isEdit) {
          response = await receiveDetailServices.update(this.detailId, payload)
        } else {
          response = await receiveDetailServices.create(payload)
        }
        
        if (response.data.status === 'success') {
          /* SET IS EDIT STATE TO FALSE */
          this.isEdit = false

          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          if (this.detailId) {
            this.showModal = false
          }

          /* CLEAR INPUT FORM */
          this.clearForm()

          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          /* RELOAD DETAILS DATA */
          this.fetchDetailData()
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.material || responseReturn.buy_rate || responseReturn.buy_price || responseReturn.price || responseReturn.qty || responseReturn.total) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },
    async savePO(){
      try {
        this.isLoading = true
        const payload = {
          receive_id: this.$route.params.id,
          type: 1,
          material: this.poMaterial.id,
          reference: this.poReference,
          color: this.poColor,
          size: this.poSize,
          unit: this.poUnit,
          buy_currency: this.poBuyCurrency,
          buy_rate: this.unformatNumber(this.poBuyRate),
          buy_price: this.unformatNumber(this.poBuyPrice),
          price: this.unformatNumber(this.poPrice),
          qty: this.unformatNumber(this.poQty),
          total: this.unformatNumber(this.poTotal)
        }

        let response = ''
        if (this.isEdit) {
          response = await receiveDetailServices.update(this.poDetailId, payload)
        } else {
          response = await receiveDetailServices.create(payload)
        }
        
        if (response.data.status === 'success') {
          /* SET IS EDIT STATE TO FALSE */
          this.isEdit = false

          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          if (this.poDetailId) {
            this.showModalPO = false
          }

          /* CLEAR INPUT FORM */
          this.clearPOForm()

          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          /* RELOAD DETAILS DATA */
          this.fetchDetailData()
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.material || responseReturn.buy_rate || responseReturn.buy_price || responseReturn.price || responseReturn.qty || responseReturn.total) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },    
    async delete(id) {
      try {
        const response = await receiveDetailServices.delete(id)
        return response.data
      } catch (error) {     
        return error
      }
    },
    async confirmDialog(id) {
      // console.log(id)
      this.$swal.fire({
        title: 'Are you sure ?',
        text: "You won't be able to revert this!",
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#4b5563',
        confirmButtonText: 'Yes'
      }).then(async (result) => {
        if (result.isConfirmed) {
          /* CALL DELETE FUNCTION */
          const result = this.delete(id)
          const response = await result

          if (response.status) {
            if (response.status === 'success') {
              /* REFRESH DATA */
              this.fetchDetailData()

              this.$swal.fire({
                title: 'Deleted!',
                text: "Your record has been deleted",
                icon: 'success',
                showConfirmButton: false,
                timer: 3000            
              })              
            } else {
              this.$swal.fire({
                title: 'Error!',
                text: response.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 3000            
              })              
            }
          } else {
            this.$toasted.error(response.message, this.toastedOptions)           
          }
        }
      })
    },     
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    },
    nameWithColor ({ supplier, name, color, size }) {
      if (color && size) {
        return `${supplier} ${name} — ${color} [ ${size} ]`
      } else if (color) {
        return `${supplier} ${name} — ${color}`
      }
      return `${supplier} ${name}`
    },
    toggleFocus() {
      this.$refs.qty.focus()
    },
    handleInputBuyRate(event) {
      this.buyRate = this.formatNumber(event.target.value)
      this.calculateConvertionPrice()
    },
    handleInputBuyPrice(event) {
      this.buyPrice = this.formatNumber(event.target.value)
      this.calculateConvertionPrice()
    },
    calculateConvertionPrice() {
      let price = parseFloat(this.unformatNumber(this.buyPrice)) / parseFloat(this.unformatNumber(this.buyRate))
      this.price = this.formatNumber(this.toFixed(price, 2))
    },
    handleInputQty(event) {
      this.qty = this.formatNumber(event.target.value)
    },            
    toggleNew() {
      this.isEdit = false
      this.error = []
      this.showModal = true
      this.modalTitle = 'Add Item'
      this.clearForm()  
    },
    toggleEdit(id) {
      this.isEdit = true
      this.error = []
      this.showModal = true
      this.modalTitle = 'Edit Item'
      this.clearForm()
      this.detailId = id
      this.fetchData(id)     
    },
    togglePoFocus() {
      this.$refs.po_qty.focus()
    },    
    handleInputPoBuyRate(event) {
      this.poBuyRate = this.formatNumber(event.target.value)
      this.calculateConvertionPoPrice()
    },
    handleInputPoBuyPrice(event) {
      this.poBuyPrice = this.formatNumber(event.target.value)
      this.calculateConvertionPoPrice()
    },
    calculateConvertionPoPrice() {
      let price = parseFloat(this.unformatNumber(this.poBuyPrice)) / parseFloat(this.unformatNumber(this.poBuyRate))
      this.poPrice = this.formatNumber(this.toFixed(price, 2))
    },
    handleInputPoQty(event) {
      this.poQty = this.formatNumber(event.target.value)
    },
    toggleNewPO() {
      this.isEdit = false
      this.error = []
      this.showModalPO = true
      this.modalPOTitle = 'Add Item'
      this.clearPOForm()
      this.fetchPOMaterialData()   
    },
    toggleEditPO(id) {
      this.isEdit = true
      this.error = []
      this.showModalPO = true
      this.modalPOTitle = 'Edit Item'
      this.clearPOForm()
      this.poDetailId = id
      this.fetchPOData(id)
      this.fetchPOMaterialData()       
    },        
    calculateTotal() {
      const price = this.price ?? 0;
      const qty = this.qty ?? 0;
      let total = this.unformatNumber(price) * this.unformatNumber(qty);
      total = this.formatNumber(this.toFixed(total, 2));
      this.total = total;
    },
    calculatePOTotal() {
      const price = this.poPrice ?? 0;
      const qty = this.poQty ?? 0;
      let total = this.unformatNumber(price) * this.unformatNumber(qty);
      total = this.formatNumber(this.toFixed(total, 2));
      this.poTotal = total;
    },    
    clearForm() {
      this.detailId = ''
      this.material = ''
      this.reference = '' 
      this.color = ''
      this.size = ''
      this.unit = ''
      this.buyCurrency = ''
      this.buyCurrencySymbol = ''
      this.buyRate = ''
      this.buyPrice = ''
      this.price = 0
      this.qty = 0
      this.total = 0
    },
    clearPOForm() {
      this.poDetailId = ''
      this.poMaterial = ''
      this.poReference = '' 
      this.poColor = ''
      this.poSize = ''
      this.poUnit = ''
      this.poBuyCurrency = ''
      this.poBuyCurrencySymbol = ''
      this.poBuyRate = ''
      this.poBuyPrice = ''
      this.poPrice = 0
      this.poQty = 0
      this.poTotal = 0
    }   
  },
  created() {
    this.fetchDetailData()
    this.fetchMaterialData()
  }, 
  computed: {
    ...mapGetters({
      poNumber: 'purchaseNumber',
    })
  },  
  watch: {
    material: function() {
      this.reference = this.material.name
      this.color = this.material.color
      this.size = this.material.size
      this.unit = this.material.unit
      this.buyCurrency = this.material.buy_currency
      this.buyCurrencySymbol = this.material.buy_symbol
      this.buyRate = this.formatNumber(this.material.buy_rate)
      this.buyPrice = this.formatNumber(this.material.buy_price)
      this.price = this.formatNumber(this.material.price)
    },
    price: function() {
      this.calculateTotal()
    },
    qty: function() {
      this.calculateTotal()
    },
    poMaterial: function() {
      this.poReference = this.poMaterial.name
      this.poColor = this.poMaterial.color
      this.poSize = this.poMaterial.size
      this.poUnit = this.poMaterial.unit
      this.poBuyCurrency = this.poMaterial.buy_currency
      this.poBuyCurrencySymbol = this.poMaterial.buy_symbol
      this.poBuyRate = this.formatNumber(this.poMaterial.buy_rate)
      this.poBuyPrice = this.formatNumber(this.poMaterial.buy_price)
      this.poPrice = this.formatNumber(this.poMaterial.price)
    },
    poPrice: function() {
      this.calculatePOTotal()
    },
    poQty: function() {
      this.calculatePOTotal()
    }      
  }
}
</script>