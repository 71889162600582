<template>
  <div>
    <button type="button" class="w-full btn btn--success" @click="toggleModal" v-tooltip="'Add Factory'">
      Add Factory    
    </button>    
    <!-- Import Modal Dialog -->
    <modal :show="showModal" @close="showModal = false" addClass="modal-md" modalOrientation="pt-16 md:pt-5 md:flex md:items-center md:justify-center">
      <template v-slot:header><h3>{{ modalTitle }}</h3></template>
      <template v-slot:body>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form id="factoryForm" @submit.prevent="handleSubmit(create)">
            <div class="md:flex gap-6">
              <div class="md:w-1/2 mb-4">
                <ValidationProvider name="Factory Name" rules="required" v-slot="{ errors }">
                  <label for="factory_name" class="label-control">Factory Name <span class="text-red-600">*</span></label>
                  <input id="factory_name" ref="factory_name" type="text" v-model="factoryName" maxlength="255" class="form-control" autofocus>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
                <div v-if="error.factory_name" class="text-sm text-red-600"><span>{{ error.factory_name[0] }}</span></div>            
              </div>
              <div class="md:w-1/2 mb-4">
                <ValidationProvider name="Factory Code" rules="required" v-slot="{ errors }">
                  <label for="factory_code" class="label-control">Factory Code</label>
                  <input id="factory_code" type="text" v-model="factoryCode" class="form-control">
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
                <div v-if="error.factory_code" class="text-sm text-red-600"><span>{{ error.factory_code[0] }}</span></div>
              </div>
            </div>        
            <div class="md:flex gap-6">
              <div class="w-full mb-4">
                <ValidationProvider name="Address" rules="" v-slot="{ errors }">
                  <label for="address" class="label-control">Address</label>
                  <textarea id="address" v-model="address" class="form-control" rows="5"></textarea>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
                <div v-if="error.address" class="text-sm text-red-600"><span>{{ error.address[0] }}</span></div>                   
              </div>
            </div>
            <div class="md:flex gap-6">
              <div class="md:w-1/2 mb-4">
                <ValidationProvider name="City" rules="alpha_spaces" v-slot="{ errors }">
                  <label for="city" class="label-control">City</label>
                  <input id="city" type="text" v-model="city" class="form-control">
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
                <div v-if="error.city" class="text-sm text-red-600"><span>{{ error.city[0] }}</span></div>
              </div>
              <div class="md:w-1/2 mb-4">
                <ValidationProvider name="Country" rules="alpha_spaces" v-slot="{ errors }">
                  <label for="country" class="label-control">Country</label>
                  <input id="country" type="text" v-model="country" class="form-control">
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
                <div v-if="error.country" class="text-sm text-red-600"><span>{{ error.country[0] }}</span></div> 
              </div>
            </div>
          </form> 
        </ValidationObserver>        
      </template>
      <template v-slot:footer>
        <button :disabled="isLoading" type="submit" form="factoryForm" class="btn btn--primary">
          Save
        </button>      
      </template> 
    </modal>
  </div>
</template>

<script>
import factoryServices from '@/services/factory/factoryServices'
import Modal from '../../widgets/Modal'

export default {
  name: 'FactoryModal',
  components: {
    Modal,
  },
  data() {
    return {
      factoryName: '',
      factoryCode: '',
      address: '',
      city: '',
      country: '',
      error: [],
      isLoading: false, 
      modalTitle: '',     
      showModal: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }         
    }
  },
  methods: {
    toggleModal() {
      this.showModal = true
      this.modalTitle = 'Factory'
    },
    async create(){
      try {
        this.isLoading = true       
        const payload = {
          factory_name: this.factoryName,
          factory_code: this.factoryCode,
          address: this.address,
          city: this.city,
          country: this.country
        }
        const response = await factoryServices.create(payload)
        if (response.data.status === 'success') {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false
                    
          /* CLEAR INPUT FORM */
          this.clearForm()

          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          this.fetchFactory()

          this.showModal = false
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.factory_name || responseReturn.factory_code) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },
    clearForm(){
      this.factoryName = ''
      this.factoryCode = ''
      this.address = ''
      this.city = ''
      this.country = ''
    },    
    fetchFactory() {
      this.$root.$emit('fetchFactory')      
    }        
  }
}
</script>