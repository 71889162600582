import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VCalendar from 'v-calendar'
import Toasted from 'vue-toasted'
import VTooltip from 'v-tooltip'
import VueSweetalert2 from 'vue-sweetalert2'
import Multiselect from 'vue-multiselect'
// import PortalVue from 'portal-vue'
// import VuexRouterSync from 'vuex-router-sync'
import VueProgressBar from 'vue-progressbar'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/en.json'
import './assets/css/index.css'
import './assets/css/custom.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'

// Install vee validation rules
Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  });
});

// Install vee validation components globally
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

Vue.component('MultiSelect', Multiselect)

Vue.config.productionTip = false

// // IMPORT INTERCEPTORS HELPER
// import interceptorsSetup from '@/helpers/interceptors'

// // RUN INTERCEPTORS
// interceptorsSetup()

/* Vue Router Handle */
// VuexRouterSync.sync(store, router)

/* Vue Progress Bar Options */
const options = {
  color: 'rgb(124, 58, 237)',
  failedColor: 'rgb(219, 39, 119)',
  thickness: '5px',
  transition: {
    speed: '0.5s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
}

Vue.use(VueProgressBar, options)
// Vue.use(PortalVue)
Vue.use(VCalendar)
Vue.use(VTooltip)
Vue.use(VueSweetalert2)
Vue.use(Toasted)
new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
