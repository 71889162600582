import axios from '@/axios'

export default {
  fetchAll(params){
    return axios.get(`/units/all`, {params})
  },
  fetchLimit(params){
    return axios.get(`/units`, {params})
  },  
  fetchById(id){
    return axios.get(`/units/${id}`)
  },    
  create(payload){
    return axios.post(`/units`, payload)
  },
  update(id, payload){
    return axios.put(`/units/${id}`, payload)
  },
  delete(id){
    return axios.delete(`/units/${id}`)
  }, 
  deleteMultiple(payload){
    return axios.delete(`/units`, payload)
  },
  fetchDataOptions(params){
    return axios.get(`/units/options`, {params})
  }  
}