<template>
  <div class="bg-white shadow-lg rounded-lg">
    <div class="p-10">
      <div class="pb-2 mb-4 border-b border-gray-200">
        <h2 class="text-gray-800 text-2xl font-medium">Show Unit</h2>
      </div>
      <div class="md:flex gap-6">
        <div class="w-full mb-4">
          <label for="unit" class="label-control">Unit</label>
          <input id="unit" ref="unit" type="text" v-model="unit" maxlength="255" class="form-control" autofocus>            
        </div>
      </div>       
      <div class="md:flex gap-4 mt-4 mb-2">
        <button :disabled="isLoading" type="button" @click="goBack()" class="btn btn--secondary" alt="Back" title="Back">
          <BackIcon>
            <span class="ml-2 md:block hidden">Back</span>
          </BackIcon>              
        </button>
      </div>                                                  
    </div>    
  </div>
</template>

<script>
import unitServices from '@/services/unit/unitServices'
import BackIcon from '../../icons/BackIcon'

export default {
  name: 'ShowUnit',
  components: {
    BackIcon
  },
  data(){
    return {
      unit: '',
      record: [],
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async fetchData(){
      try {
        const id = this.$route.params.id
        const response = await unitServices.fetchById(id)
        if (response.data.status === 'success') {
          this.record = response.data.data

          this.unit = this.record.unit
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)            
        }
      } catch (error) {
        console.log(error)
      }
    },
    goBack() {
      window.history.length > 1 ? this.$router.back() : this.$router.push('/units')
    }        
  },
  created(){
    this.fetchData()
  }
}
</script>