<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="bg-white shadow-lg rounded-lg" @submit.prevent="handleSubmit(update)">
        <div class="p-10">
          <div class="pb-2 mb-4 border-b border-gray-200">
            <h2 class="text-gray-800 text-2xl font-medium">Profile</h2>
          </div>
          <div class="md:flex gap-6">
            <div class="w-full mb-4">
              <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                <label for="name" class="label-control">Name <span class="text-red-600">*</span></label>
                <input id="name" ref="name" type="text" v-model="name" maxlength="255" class="form-control" autofocus>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.name" class="text-sm text-red-600"><span>{{ error.name[0] }}</span></div>              
            </div>
          </div>          
          <div class="md:flex gap-6">
            <div class="md:w-1/2 mb-4">
              <ValidationProvider name="Username" rules="required" v-slot="{ errors }">
                <label for="username" class="label-control">Username <span class="text-red-600">*</span></label>
                <input id="username" type="text" v-model="username" class="form-control">
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.username" class="text-sm text-red-600"><span>{{ error.username[0] }}</span></div>
            </div>
            <div class="md:w-1/2 mb-4">
              <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                <label for="email" class="label-control">Email <span class="text-red-600">*</span></label>
                <input id="email" type="text" v-model="email" class="form-control">
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.email" class="text-sm text-red-600"><span>{{ error.email[0] }}</span></div>
            </div>
          </div>         
          <div class="md:flex gap-4 mt-4 mb-2">
            <button :disabled="isLoading" type="submit" class="btn btn--primary" alt="Save" title="Save">
              <span v-if="isLoading" class="block mx-5">
                <Loading />
              </span>
              <SaveIcon v-else>
                <span class="ml-2 md:block hidden">Save</span>
              </SaveIcon>
            </button>
            <button :disabled="isLoading" type="button" @click="goBack()" class="btn btn--secondary" alt="Back" title="Back">
              <BackIcon>
                <span class="ml-2 md:block hidden">Back</span>
              </BackIcon>              
            </button>
          </div>                                                  
        </div>
      </form>      
    </ValidationObserver>
  </div>
</template>

<script>
import userServices from '@/services/user/userServices'
import SaveIcon from '../../icons/SaveIcon'
import BackIcon from '../../icons/BackIcon'
import Loading from '../../icons/Loading'
import { mapGetters } from 'vuex'

export default {
  name: 'UserProfile',
  components: {
    SaveIcon,
    BackIcon,
    Loading
  },  
  data(){
    return {
      name: '',
      username: '',
      email: '',
      status: '',
      record: [],
      error: [],
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async update(){
      try {
        this.isLoading = true

        const id = this.user.id
        const payload = {
          name: this.name,
          username: this.username,
          email: this.email,
          status: this.status
        }
        const response = await userServices.update(id, payload)
        if (response.data.status === 'success') {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          // /* RETURN TO LIST */
          // this.$router.back()

          /* UPDATE STORE */
          this.$store.dispatch('setUser', response.data.data)
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.name || responseReturn.username || responseReturn.email || responseReturn.status) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },
    goBack() {
      window.history.length > 1 ? this.$router.back() : this.$router.push('/users')
    }       
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  mounted() {
    this.name = this.user.name
    this.username = this.user.username
    this.email = this.user.email
    this.status = this.user.is_block
  }
}
</script>