import ListProduct from '../components/feature/product/List'
import CreateProduct from '../components/feature/product/Create'
import EditProduct from '../components/feature/product/Edit'
import ShowProduct from '../components/feature/product/Show'

const routes = [
  {
    path: '/products',
    name: 'product',
    component: ListProduct,
    meta: {
      title: 'Products',
      requiresAuth: true
    }
  },
  {
    path: '/products/create',
    name: 'productCreate',
    component: CreateProduct,
    meta: {
      title: 'Create Product',
      requiresAuth: true
    }
  },
  {
    path: '/products/:id/edit',
    name: 'productEdit',
    component: EditProduct,
    meta: {
      title: 'Edit Product',
      requiresAuth: true
    }    
  },
  {
    path: '/products/:id',
    name: 'productShow',
    component: ShowProduct,
    meta: {
      title: 'Show Product',
      requiresAuth: true
    }    
  }  
]

export default routes