const state = {
  recipeTotal: 0
};

const getters = {
  recipeTotal: state => state.recipeTotal
};

const actions = {
  setRecipeTotal({commit}, total){
    commit('setRecipeTotal', total)
  }
};

const mutations = {
  setRecipeTotal(state, total){
    state.recipeTotal = total
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};