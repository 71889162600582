import ListUser from '../components/feature/user/List'
import CreateUser from '../components/feature/user/Create'
import EditUser from '../components/feature/user/Edit'
import ShowUser from '../components/feature/user/Show'
import UpdatePassword from '../components/feature/user/ChangePassword'
import ShowProfile from '../components/feature/user/Profile'

const routes = [
  {
    path: '/users',
    name: 'user',
    component: ListUser,
    meta: {
      title: 'Users',
      requiresAuth: true
    }    
  },
  {
    path: '/users/create',
    name: 'userCreate',
    component: CreateUser,
    meta: {
      title: 'Create User',
      requiresAuth: true
    }    
  },
  {
    path: '/users/:id/edit',
    name: 'userEdit',
    component: EditUser,
    meta: {
      title: 'Edit User',
      requiresAuth: true
    }    
  },
  {
    path: '/users/password',
    name: 'userUpdatePassword',
    component: UpdatePassword,
    meta: {
      title: 'Update Password',
      requiresAuth: true
    }    
  },
  {
    path: '/users/profile',
    name: 'userProfile',
    component: ShowProfile,
    meta: {
      title: 'Show Profile',
      requiresAuth: true
    }    
  },
  {
    path: '/users/:id',
    name: 'userShow',
    component: ShowUser,
    meta: {
      title: 'Show User',
      requiresAuth: true
    }    
  }   
]

export default routes