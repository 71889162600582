<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="bg-white shadow-lg rounded-lg" @submit.prevent="handleSubmit(update)">    
        <div class="p-10">
          <div class="pb-2 mb-4 border-b border-gray-200">
            <h2 class="text-gray-800 text-2xl font-medium">Edit Stock Opname</h2>
          </div>
          <div class="md:flex gap-10">
            <div class="md:flex-col w-full md:w-2/5">
              <div class="md:flex gap-4">
                <div class="flex md:w-2/5">
                  <div class="w-full md:mb-2">
                    <label for="record_no" class="label-control md:py-3">Stock Opname No <span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="flex md:w-3/5">
                  <div class="w-full mb-2">
                    <ValidationProvider name="Stock Opname No" rules="required" v-slot="{ errors }">
                      <div class="relative flex-1">
                        <span class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm badge" :class="status === 1 ? 'badge--light-primary' : (status === 2 ? 'badge--success' : 'badge--danger')">{{ statusText }}</span>
                        <input id="record_no" type="text" v-model="recordNumber" class="form-control" readonly>
                        <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                    <div v-if="error.record_no" class="text-sm text-red-600"><span>{{ error.record_no[0] }}</span></div>
                  </div>
                </div>
              </div>
              <div class="md:flex gap-4">
                <div class="flex md:w-2/5">
                  <div class="w-full md:mb-2">
                    <label for="location" class="label-control md:py-3">Location <span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="flex md:w-3/5">
                  <div class="w-full mb-2">
                    <ValidationProvider name="Location" rules="required" v-slot="{ errors }">
                      <MultiSelect id="location" v-model="stockOpnameLocation" :options="stockOpnameLocations" :value="stockOpnameLocation" :searchable="true" track-by="id" label="name" placeholder="Type to Search" :showLabels="false" :disabled="true">
                        <template slot="caret">
                          <div>
                            <div class="multiselect__select">
                              <span>
                                <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                                  <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </template>
                      </MultiSelect>
                      <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <div v-if="error.location" class="text-sm text-red-600"><span>{{ error.location[0] }}</span></div>  
                  </div>
                </div>
              </div>
              <div class="md:flex gap-4">
                <div class="flex md:w-2/5">
                  <div class="w-full md:mb-2">
                    <label for="receive_date" class="label-control md:py-3">Stock Opname Date <span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="flex md:w-3/5">
                  <div class="w-full mb-2">
                    <ValidationProvider name="Date" rules="required" v-slot="{ errors }">
                      <v-date-picker v-model="stockOpnameDate" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs">
                        <template v-slot="{ inputValue, inputEvents }">
                          <div class="md:flex gap-6">
                            <div class="w-full">
                              <div class="relative flex justify-between items-center">
                                <input id="stock_opname_date" type="text" class="form-control" :value="inputValue" v-on="inputEvents">
                                <span class="h-full absolute pointer-events-none right-0">
                                  <DateRange class="m-3" />
                                </span>                      
                              </div>
                              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                            </div>
                          </div>
                        </template>
                      </v-date-picker>
                    </ValidationProvider>
                    <div v-if="error.stock_opname_date" class="text-sm text-red-600"><span>{{ error.stock_opname_date[0] }}</span></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="md:flex-col w-full md:w-3/5">
              <div class="md:flex gap-4">
                <div class="flex md:w-2/6">
                  <div class="w-full md:mb-2">
                    <label for="stock_opname_season" class="label-control md:py-3">Stock Opname Season <span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="w-full md:w-4/6 gap-2">
                  <ValidationProvider name="Stock Opname Season" rules="required" v-slot="{ errors }">
                    <MultiSelect id="stock_opname_season" v-model="stockOpnameSeason" :options="seasons" :value="stockOpnameSeason" :searchable="true" track-by="id" label="name" placeholder="Type to Search" :disabled="true">
                      <template slot="caret">
                        <div>
                          <div class="multiselect__select">
                            <span>
                              <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                                <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </template>
                    </MultiSelect>
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.stock_opname_season" class="text-sm text-red-600"><span>{{ error.stock_opname_season[0] }}</span></div>
                </div>
              </div>
              <div class="md:flex gap-4">
                <div class="flex md:w-2/6">
                  <div class="w-full md:mb-2">
                    <label for="stock_opname_for_season" class="label-control md:py-3">Stock Opname For Season <span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="w-full md:w-4/6 gap-2">
                  <ValidationProvider name="Stock Opname For Season" rules="required" v-slot="{ errors }">
                    <MultiSelect id="stock_opname_for_season" v-model="stockOpnameForSeason" :options="seasons" :value="stockOpnameForSeason" :searchable="true" track-by="id" label="name" placeholder="Type to Search" :disabled="true">
                      <template slot="caret">
                        <div>
                          <div class="multiselect__select">
                            <span>
                              <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                                <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </template>
                    </MultiSelect>
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.stock_opname_for_season" class="text-sm text-red-600"><span>{{ error.stock_opname_for_season[0] }}</span></div>
                </div>
              </div>          
            </div>
          </div>
          <!-- CALL PRODUCT RECIPE -->
          <TransactionDetail v-if="status === 1" show="true" :parentLoading="isLoading" :location="stockOpnameLocation.id" class="mb-10" />
          <TransactionDetail v-else class="mb-10" />

          <!-- SUMMARY DATA SECTION -->
          <div class="md:flex gap-6">
            <div class="md:w-1/2 mb-4">
              <ValidationProvider name="Remarks" rules="" v-slot="{ errors }">
                <label for="remarks" class="label-control">Remarks</label>
                <textarea id="remarks" v-model="remarks" class="form-control" rows="3"></textarea>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.remarks" class="text-sm text-red-600"><span>{{ error.remarks[0] }}</span></div>  
            </div>
            <div class="md:w-1/2 mb-4">
            </div>
          </div>
          <div class="flex gap-4 md:justify-between items-center mt-10 mb-2">
            <div class="flex items-center gap-4">
              <button v-if="status === 1" :disabled="isLoading" type="submit" class="btn btn--primary" alt="Save" title="Save">
                <span v-if="isLoading" class="block mx-5">
                  <Loading />
                </span>
                <SaveIcon v-else>
                  <span class="ml-2 md:block hidden">Update</span>
                </SaveIcon>
              </button>
              <button :disabled="isLoading" type="button" @click="goBack()" class="btn btn--secondary" alt="Back" title="Back">
                <BackIcon>
                  <span class="ml-2 md:block hidden">Back</span>
                </BackIcon>
              </button>
            </div>
            <div class="flex items-center gap-2">
              <button v-if="status === 1" :disabled="isLoading" type="button" @click="cancelDialog" class="btn btn--danger" alt="Cancel Adjustment" title="Cancel Adjustment">
                <OffIcon>
                  <span class="ml-2 md:block hidden">Cancel</span>
                </OffIcon>              
              </button>              
              <button v-if="status === 1" :disabled="isLoading" type="button" @click="validateDialog" class="btn btn--success" alt="Validate" title="Validate">
                <LockIcon>
                  <span class="ml-2 md:block hidden">Validate</span>
                </LockIcon>              
              </button>
              <button v-if="status === 2" :disabled="isLoading" type="button" @click="cancelValidateDialog" class="btn btn--danger" alt="Cancel Validation" title="Cancel Validation">
                <LockIcon>
                  <span class="ml-2 md:block hidden">Invalidate</span>
                </LockIcon>              
              </button>              
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <div class="fixed bg-gray-800 bg-opacity-25 w-full inset-0 top-16 md:top-0 z-50" :class="{ 'hidden': !showFunction }">
      <div class="w-52 ml-auto h-full bg-white shadow-lg right-0">
        <div class="flex p-5 border-b border-gray-200 items-center">
          <span class="flex-1 text-sm font-medium">Functions</span>
          <button type="button" class="flex right-0" @click="toggleFunction">
            <svg class="h-5 w-5 fill-current hover:text-gray-700" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
            </svg>
          </button>
        </div>
        <div class="p-5 items-center">
          <FactoryModal class="mb-4" />
          <SeasonModal class="mb-4" />
        </div>
      </div>            
    </div>
    <div class="fixed w-20 top-0 bottom-0 right-0 z-20">
      <div class="absolute w-16 right-0 top-1/2 bg-green-500 text-white rounded-l-full shadow-lg" :class="{ 'hidden': showFunction }">
        <button class="block ml-2 p-3 cursor-pointer" v-tooltip="'Show Function'" @click="toggleFunction">
          <svg class="h-5 w-5 fill-current" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M22.6558 18.9896L13.5758 9.90957C14.5058 7.56957 14.0258 4.80957 12.1358 2.90957C9.83584 0.609575 6.25584 0.399574 3.70584 2.25957L7.54584 6.10957L6.12584 7.51957L2.29584 3.68957C0.435837 6.22957 0.645837 9.81957 2.94584 12.1096C4.80584 13.9696 7.51584 14.4596 9.83584 13.5896L18.9458 22.6996C19.3358 23.0896 19.9658 23.0896 20.3558 22.6996L22.6558 20.3996C23.0558 20.0196 23.0558 19.3896 22.6558 18.9896ZM19.6558 20.5896L10.1958 11.1296C9.58584 11.5796 8.90584 11.8496 8.19584 11.9496C6.83584 12.1496 5.40584 11.7396 4.36584 10.6996C3.41584 9.75957 2.97584 8.49957 3.04584 7.25957L6.13584 10.3496L10.3758 6.10957L7.28584 3.01957C8.52584 2.94957 9.77584 3.38957 10.7258 4.32957C11.8058 5.40957 12.2158 6.89957 11.9658 8.28957C11.8458 8.99957 11.5458 9.65957 11.0858 10.2496L20.5358 19.6996L19.6558 20.5896Z" />
          </svg>        
        </button>           
      </div>
    </div>    
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import format from '@/helpers/formatNumber'
import factoryServices from '@/services/factory/factoryServices'
import seasonServices from '@/services/season/seasonServices'
import opnameServices from '@/services/stockOpname/opnameServices'
import SaveIcon from '../../icons/SaveIcon'
import BackIcon from '../../icons/BackIcon'
import LockIcon from '../../icons/LockIcon'
import OffIcon from '../../icons/OffIcon'
import Loading from '../../icons/Loading'
import DateRange from '../../icons/DateRange'
import TransactionDetail from './Detail'
import FactoryModal from '../factory/Modal'
import SeasonModal from '../season/Modal'

export default {
  name: 'EditStockOpname',
  components: {
    SaveIcon,
    BackIcon,
    LockIcon,
    OffIcon,
    Loading,
    DateRange,
    TransactionDetail,
    FactoryModal,
    SeasonModal
  },  
  data(){
    return {
      recordNumber: '',
      stockOpnameLocation: '',
      stockOpnameLocations: [],
      stockOpnameSeason: '',
      stockOpnameForSeason: '',
      seasons: [],
      stockOpnameDate: new Date(),
      masks: {
        input: 'YYYY-MM-DD',
      },
      attrs: [
        {
          key: 'today',
          highlight: 'red',
          dates: new Date(),
        },
      ],      
      remarks: '',
      status: '',
      statusText: '',
      record: [],
      details: [],
      error: [],
      showFunction: false,
      isLoading: false,
      activeTab: '1',
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async update(){
      try {
        this.isLoading = true

        const id = this.$route.params.id
        const payload = {
          location: this.stockOpnameLocation.id,
          stock_opname_date: this.stockOpnameDate,
          stock_opname_season: this.stockOpnameSeason.id,
          stock_opname_for_season: this.stockOpnameForSeason.id,
          remarks: this.remarks
        }
        const response = await opnameServices.update(id, payload)
        if (response.data.status === 'success') {
          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          /* CHECK IF NEW ID IS EQUAL WITH CURRENT ID, THEN REDIRECT TO 2 STEP PREV PAGE */
          // console.log(this.newID, id)
          if (this.newID === id && this.indexRoute) {
            this.$router.push(this.indexRoute.fullPath)
            this.$store.dispatch('setNewStockOpnameID', null) 
            this.$store.dispatch('setIndexStockOpnameRoute', null)
          } else {
            /* RETURN TO LIST */
            window.history.length > 1 ? this.$router.back() : this.$router.push('/stock-opname')
          }
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.location || responseReturn.stock_opname_date || responseReturn.stock_opname_season || responseReturn.stock_opname_year || responseReturn.stock_opname_for_season || responseReturn.stock_opname_for_year || responseReturn.remarks) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },
    async fetchData(){
      try {
        const id = this.$route.params.id
        const response = await opnameServices.fetchById(id)
        if (response.data.status === 'success') {
          this.record = response.data.data

          this.recordNumber = this.record.record_no
          this.stockOpnameLocation = { 'id': this.record.factory_id, 'name': this.record.factory.factory_name, 'code': this.record.factory.factory_code }
          this.stockOpnameDate = this.record.stock_opname_date
          this.stockOpnameSeason = { 'id': this.record.so_season_id, 'name': this.record.stock_opname_season.season_name }
          this.stockOpnameForSeason = { 'id': this.record.so_for_season_id, 'name': this.record.stock_opname_for_season.season_name }
          this.remarks = this.record.remarks
          this.status = this.record.status
          this.statusText = this.record.status_text      
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)            
        }
      } catch (error) {
        console.log(error)
      }
    },   
    async fetchFactory() {
      try {
        const response = await factoryServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.stockOpnameLocations = []
          records.forEach(element => {
            this.stockOpnameLocations.push({
              'id': element.id,
              'name': element.factory_name,
              'code': element.factory_code
            })
          })
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }        
      } catch (error) {
        console.log(error)
      }
    },
    async fetchSeasonData() {
      try {
        const response = await seasonServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.seasons = []
          records.forEach(element => {
            this.seasons.push({
              'id': element.id,
              'name': element.season_name
            })
          });          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async cancel(id) {
      try {
        const payload = {
          location: this.record.factory_id,
          stock_opname_date: this.record.stock_opname_date,
          stock_opname_season: this.record.so_season_id,
          stock_opname_for_season: this.record.so_for_season_id,
          remarks: this.record.remarks,
          status: 3
        }
        const response = await opnameServices.update(id, payload)
        return response.data
      } catch (error) {     
        return error
      }      
    },
    async cancelDialog() {
      const id = this.$route.params.id

      this.$swal.fire({
        title: 'Do you want to continue ?',
        text: "Cancelation transaction will change the transaction status and lock the transaction from modify and delete. You won't be able to revert this!",
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#4b5563',
        confirmButtonText: 'Yes'
      }).then(async (result) => {
        if (result.isConfirmed) {
          /* SET IS LOADING STATE TRUE */
          this.isLoading = true

          /* CALL VALIDATE FUNCTION */
          const result = this.cancel(id)
          const response = await result

          if (response.status) {
            if (response.status === 'success') {
              /* SET IS LOADING STATE FALSE */
              this.isLoading = false

              /* REFRESH DATA */
              this.fetchData()

              this.$swal.fire({
                title: 'Validated!',
                text: "Your transaction has been updated",
                icon: 'success',
                showConfirmButton: false,
                timer: 3000            
              })              
            } else {
              /* SET IS LOADING STATE FALSE */
              this.isLoading = false
                            
              this.$swal.fire({
                title: 'Error!',
                text: response.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 3000            
              })              
            }
          } else {
            /* SET IS LOADING STATE FALSE */
            this.isLoading = false
                        
            this.$toasted.error(response.message, this.toastedOptions)           
          }
        }
      })      
    },
    async validate(id) {
      try {
        const response = await opnameServices.validate(id)
        return response.data
      } catch (error) {     
        return error
      }      
    },    
    async validateDialog() {
      const id = this.$route.params.id

      this.$swal.fire({
        title: 'Do you want to continue ?',
        text: "Validate transaction will lock the transaction from modify and delete. You won't be able to revert this!",
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#4b5563',
        confirmButtonText: 'Yes'
      }).then(async (result) => {
        if (result.isConfirmed) {
          /* SET IS LOADING STATE TRUE */
          this.isLoading = true

          /* CALL VALIDATE FUNCTION */
          const result = this.validate(id)
          const response = await result

          if (response.status) {
            if (response.status === 'success') {
              /* SET IS LOADING STATE FALSE */
              this.isLoading = false

              /* REFRESH DATA */
              this.fetchData()

              this.$swal.fire({
                title: 'Validated!',
                text: "Your transaction has been validated",
                icon: 'success',
                showConfirmButton: false,
                timer: 3000            
              })              
            } else {
              /* SET IS LOADING STATE FALSE */
              this.isLoading = false
                            
              this.$swal.fire({
                title: 'Error!',
                text: response.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 3000            
              })              
            }
          } else {
            /* SET IS LOADING STATE FALSE */
            this.isLoading = false

            this.$toasted.error(response.message, this.toastedOptions)           
          }
        }
      })      
    },
    async cancelValidation(id) {
      try {
        const response = await opnameServices.cancelValidation(id)
        return response.data
      } catch (error) {     
        return error
      }      
    },     
    async cancelValidateDialog() {
      const id = this.$route.params.id

      this.$swal.fire({
        title: 'Do you want to continue ?',
        text: "Cancelation validation will unlock the transaction from modify and delete!",
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#4b5563',
        confirmButtonText: 'Yes'
      }).then(async (result) => {
        if (result.isConfirmed) {
          /* SET IS LOADING STATE TRUE */
          this.isLoading = true

          /* CALL VALIDATE FUNCTION */
          const result = this.cancelValidation(id)
          const response = await result

          if (response.status) {
            if (response.status === 'success') {
              /* SET IS LOADING STATE FALSE */
              this.isLoading = false

              /* REFRESH DATA */
              this.fetchData()

              this.$swal.fire({
                title: 'Canceled!',
                text: "Your transaction has been unlocked",
                icon: 'success',
                showConfirmButton: false,
                timer: 3000            
              })              
            } else {
              /* SET IS LOADING STATE FALSE */
              this.isLoading = false
                            
              this.$swal.fire({
                title: 'Error!',
                text: response.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 3000            
              })              
            }
          } else {
            /* SET IS LOADING STATE FALSE */
            this.isLoading = false
                        
            this.$toasted.error(response.message, this.toastedOptions)           
          }
        }
      })      
    },     
    nameWithAddress ({ name, address }) {
      if (address) {
        return `${name} [ ${address} ]`
      }
      return `${name}`
    },    
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    },
    goBack() {
      if (this.newID === this.$route.params.id && this.indexRoute) {
        this.$router.push(this.indexRoute.fullPath)
        this.$store.dispatch('setNewStockOpnameID', null) 
        this.$store.dispatch('setIndexStockOpnameRoute', null)    
      } else {
        window.history.length > 1 ? this.$router.back() : this.$router.push('/stock-opname')
      }
    },
    toggleFunction() {
      this.showFunction = !this.showFunction
    }    
  },
  computed: {
    ...mapGetters({
      newID: 'newStockOpnameID',
      indexRoute: 'indexStockOpnameRoute'      
    })
  },
  created(){
    this.fetchFactory()
    this.fetchSeasonData()
    this.fetchData()
  },
  mounted() {
    const thisInstance = this
    this.$root.$on('fetchFactory', function(){
      thisInstance.fetchFactory()
    })
    this.$root.$on('fetchSeason', function(){
      thisInstance.fetchSeasonData()
    })    
  } 
}
</script>