<template>
  <div>
    <div class="bg-white shadow-lg rounded-lg">
      <div class="p-10">
        <div class="pb-2 mb-4 border-b border-gray-200">
          <h2 class="text-gray-800 text-2xl font-medium">Show Transfer Stock</h2>
        </div>
        <div class="w-full md:flex gap-10 mb-4">
          <div class="md:flex-col w-full md:w-2/5">
            <div class="md:flex gap-4">
              <div class="flex md:w-1/3">
                <div class="w-full md:mb-2">
                  <label for="record_no" class="label-control md:py-3">Transfer No <span class="text-red-600">*</span></label>
                </div>
              </div>
              <div class="flex md:w-2/3">
                <div class="w-full mb-2">
                  <ValidationProvider name="PO Number" rules="required" v-slot="{ errors }">
                    <div class="relative flex-1">
                      <span class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm badge" :class="status === 1 ? 'badge--light-primary' : (status === 2 ? 'badge--success' : 'badge--danger')">{{ statusText }}</span>
                      <input id="record_no" type="text" v-model="recordNumber" class="form-control" readonly>
                      <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                  <div v-if="error.record_no" class="text-sm text-red-600"><span>{{ error.record_no[0] }}</span></div>
                </div>
              </div>
            </div>
            <div class="md:flex gap-4">
              <div class="flex md:w-1/3">
                <div class="w-full md:mb-2">
                  <label for="transfer_date" class="label-control md:py-3">Transfer Date <span class="text-red-600">*</span></label>
                </div>
              </div>
              <div class="flex md:w-2/3">
                <div class="w-full mb-2">
                  <ValidationProvider name="Transfer Date" rules="required" v-slot="{ errors }">
                    <v-date-picker v-model="transferDate" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs">
                      <template v-slot="{ inputValue, inputEvents }">
                        <div class="md:flex gap-4">
                          <div class="w-full">
                            <div class="relative flex justify-between items-center">
                              <input id="transfer_date" type="text" class="form-control" :value="inputValue" v-on="inputEvents">
                              <span class="h-full absolute pointer-events-none right-0">
                                <DateRange class="m-3" />
                              </span>                      
                            </div>
                            <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                          </div>
                        </div>
                      </template>
                    </v-date-picker>
                  </ValidationProvider>
                  <div v-if="error.transfer_date" class="text-sm text-red-600"><span>{{ error.transfer_date[0] }}</span></div>
                </div>
              </div>
            </div>
          </div>
          <div class="md:flex-col w-full md:w-3/5">
            <div class="md:flex gap-4">
              <div class="flex md:w-1/3">
                <div class="w-full md:mb-2">
                  <label for="transfer_from" class="label-control md:py-3">Transfer From <span class="text-red-600">*</span></label>
                </div>
              </div>
              <div class="flex md:w-2/3">
                <div class="w-full mb-2">
                  <ValidationProvider name="Transfer From" rules="" v-slot="{ errors }">
                    <MultiSelect id="transfer_from" v-model="transferFrom" :options="locations" :value="transferFrom" :searchable="true" track-by="id" label="name" placeholder="Type to Search" :showLabels="false" :disabled="true">
                      <template slot="caret">
                        <div>
                          <div class="multiselect__select">
                            <span>
                              <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                                <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </template>
                    </MultiSelect>
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <div v-if="error.transfer_from" class="text-sm text-red-600"><span>{{ error.transfer_from[0] }}</span></div>
                </div>
              </div>
            </div>
            <div class="md:flex gap-4">
              <div class="flex md:w-1/3">
                <div class="w-full md:mb-2">
                  <label for="transfer_to" class="label-control md:py-3">Transfer To <span class="text-red-600">*</span></label>
                </div>
              </div>
              <div class="flex md:w-2/3">
                <div class="w-full mb-2">
                  <ValidationProvider name="Transfer From" rules="" v-slot="{ errors }">
                    <MultiSelect id="transfer_to" v-model="transferTo" :options="locations" :value="transferTo" :searchable="true" track-by="id" label="name" placeholder="Type to Search" :showLabels="false" :disabled="true">
                      <template slot="caret">
                        <div>
                          <div class="multiselect__select">
                            <span>
                              <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                                <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </template>
                    </MultiSelect>
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <div v-if="error.transfer_to" class="text-sm text-red-600"><span>{{ error.transfer_to[0] }}</span></div>
                </div>
              </div>
            </div>                        
          </div>
        </div>
        <!-- CALL DETAIL TRANSFER -->
        <TransactionDetail class="mb-10" />

        <!-- SUMMARY DATA SECTION -->
        <div class="md:flex gap-6">
          <div class="md:w-1/2 mb-4">
            <ValidationProvider name="Remarks" rules="" v-slot="{ errors }">
              <label for="remarks" class="label-control">Remarks</label>
              <textarea id="remarks" v-model="remarks" class="form-control" rows="3"></textarea>
              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
            </ValidationProvider>
            <div v-if="error.remarks" class="text-sm text-red-600"><span>{{ error.remarks[0] }}</span></div>  
          </div>
          <div class="md:w-1/2 mb-4">
          </div>
        </div>
        <div class="flex gap-4 md:justify-between items-center mt-10 mb-2">
          <button :disabled="isLoading" type="button" @click="goBack()" class="btn btn--secondary" alt="Back" title="Back">
            <BackIcon>
              <span class="ml-2 md:block hidden">Back</span>
            </BackIcon>              
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import format from '@/helpers/formatNumber'
import factoryServices from '@/services/factory/factoryServices'
import transferServices from '@/services/transfer/transferServices'
import BackIcon from '../../icons/BackIcon'
import DateRange from '../../icons/DateRange'
import TransactionDetail from './Detail'

export default {
  name: 'ShowTransferStock',
  components: {
    BackIcon,
    DateRange,
    TransactionDetail
  },  
  data(){
    return {
      recordNumber: '',
      transferFrom: '',
      transferTo: '',
      locations: [],   
      transferDate: '',
      masks: {
        input: 'YYYY-MM-DD',
      },
      attrs: [
        {
          key: 'today',
          highlight: 'red',
          dates: new Date(),
        },
      ],      
      remarks: '',
      status: '',
      statusText: '',
      record: [],
      details: [],
      error: [],
      isLoading: false,
      activeTab: '1',
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async fetchData(){
      try {
        const id = this.$route.params.id
        const response = await transferServices.fetchById(id)
        if (response.data.status === 'success') {
          this.record = response.data.data

          this.recordNumber = this.record.record_no
          this.transferFrom = this.record.transfer_from_id ? { 'id': this.record.transfer_from_id, 'name': this.record.transfer_from.factory_name, 'code': this.record.transfer_from.factory_code} : null
          this.transferTo = this.record.transfer_to_id ? { 'id': this.record.transfer_to_id, 'name': this.record.transfer_to.factory_name, 'code': this.record.transfer_to.factory_code} : null
          this.transferDate = this.record.transfer_date
          this.remarks = this.record.remarks
          this.status = this.record.status
          this.statusText = this.record.status_text
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)            
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchFactory() {
      try {
        const response = await factoryServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.locations = []
          records.forEach(element => {
            this.locations.push({
              'id': element.id,
              'name': element.factory_name,
              'code': element.factory_code
            })
          })
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }        
      } catch (error) {
        console.log(error)
      }
    }, 
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    },
    goBack() {
      window.history.length > 1 ? this.$router.back() : this.$router.push('/transfer-stock')
    }    
  },
  mounted(){
    this.fetchFactory()
    this.fetchData()
  },
}
</script>