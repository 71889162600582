<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="bg-white shadow-lg rounded-lg" @submit.prevent="handleSubmit(save)">
        <div class="p-10">
          <div class="pb-2 mb-4 border-b border-gray-200">
            <h2 class="text-gray-800 text-2xl font-medium">Default Variable</h2>
          </div>       
          <div class="md:flex gap-6">
            <div class="md:w-1/2 mb-4">
              <ValidationProvider name="Tax / Customs" rules="required" v-slot="{ errors }">
                <label for="tax_customs" class="label-control">Tax / Customs <span class="text-red-600">*</span></label>
                <div class="relative flex-1">
                  <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">%</span>
                  <input id="tax_customs" type="text" v-model="taxCustoms" class="form-control" @input="handleInputTaxCustoms">
                </div>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.tax_customs" class="text-sm text-red-600"><span>{{ error.tax_customs[0] }}</span></div>
            </div>
            <div class="md:w-1/2 mb-4">
              <ValidationProvider name="Transport / Packaging" rules="required" v-slot="{ errors }">
                <label for="transport_packaging" class="label-control">Transport / Packaging <span class="text-red-600">*</span></label>
                <div class="relative flex-1">
                  <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
                  <input id="transport_packaging" type="text" v-model="transportPackaging" class="form-control" @input="handleInputTransportPackaging">
                </div>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.transport_packaging" class="text-sm text-red-600"><span>{{ error.transport_packaging[0] }}</span></div>
            </div>
          </div>
          <div class="md:flex gap-6 border-b border-gray-200">
            <div class="md:w-1/2 mb-4">
              <ValidationProvider name="Agent Commision" rules="required" v-slot="{ errors }">
                <label for="agent_commision" class="label-control">Agent Commision <span class="text-red-600">*</span></label>
                <div class="relative flex-1">
                  <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">%</span>
                  <input id="agent_commision" type="text" v-model="agentCommision" class="form-control" @input="handleInputAgentCommision">
                </div>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.agent_commision" class="text-sm text-red-600"><span>{{ error.agent_commision[0] }}</span></div>
            </div>
            <div class="md:w-1/2 mb-4">
            </div>
          </div>
          <div class="md:flex gap-6 mt-4">
            <div class="flex md:w-1/2 gap-6 mb-4">
              <div class="md:w-1/2">
                <ValidationProvider name="Coef. Wholesale Price" rules="required" v-slot="{ errors }">
                  <label for="coef_wholesale_price" class="label-control">Coef. Wholesale Price <span class="text-red-600">*</span></label>
                  <input id="coef_wholesale_price" type="text" v-model="coefWholesalePrice" class="form-control" @input="handleInputCoefWholesalePrice">
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="error.coef_wholesale_price" class="text-sm text-red-600"><span>{{ error.coef_wholesale_price[0] }}</span></div>
              </div>
              <div class="md:w-1/2">
                <ValidationProvider name="Coef. Retail Price" rules="required" v-slot="{ errors }">
                  <label for="coef_retail_price" class="label-control">Coef. Retail Price <span class="text-red-600">*</span></label>
                  <input id="coef_retail_price" type="text" v-model="coefRetailPrice" class="form-control" @input="handleInputCoefRetailPrice">
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="error.coef_retail_price" class="text-sm text-red-600"><span>{{ error.coef_retail_price[0] }}</span></div>
              </div>
            </div>
            <div class="flex md:w-1/2 gap-6 mb-4">
              <div class="md:w-1/2">
                <ValidationProvider name="Coef. Wholesale (USD)" rules="required" v-slot="{ errors }">
                  <label for="coef_wholesale_usd" class="label-control">Coef. Wholesale (USD) <span class="text-red-600">*</span></label>
                  <input id="coef_wholesale_usd" type="text" v-model="coefWholesaleUSD" class="form-control" @input="handleInputCoefWholesaleUSD">
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="error.coef_wholesale_usd" class="text-sm text-red-600"><span>{{ error.coef_wholesale_usd[0] }}</span></div>
              </div>
              <div class="md:w-1/2">
                <ValidationProvider name="Coef. Retail (USD)" rules="required" v-slot="{ errors }">
                  <label for="coef_retail_usd" class="label-control">Coef. Retail (USD) <span class="text-red-600">*</span></label>
                  <input id="coef_retail_usd" type="text" v-model="coefRetailUSD" class="form-control" @input="handleInputCoefRetailUSD">
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="error.coef_retail_usd" class="text-sm text-red-600"><span>{{ error.coef_retail_usd[0] }}</span></div>
              </div>
            </div>        
          </div>          
          <div class="md:flex gap-4 mt-4 mb-2">
            <button :disabled="isLoading" type="submit" class="btn btn--primary" alt="Save" title="Save">
              <span v-if="isLoading" class="block mx-5">
                <Loading />
              </span>
              <SaveIcon v-else>
                <span class="ml-2 md:block hidden">Save</span>
              </SaveIcon>
            </button>
            <button :disabled="isLoading" type="button" @click="goBack()" class="btn btn--secondary" alt="Back" title="Back">
              <BackIcon>
                <span class="ml-2 md:block hidden">Back</span>
              </BackIcon>              
            </button>
          </div>                                                  
        </div>
      </form>      
    </ValidationObserver>
  </div>
</template>

<script>
import format from '@/helpers/formatNumber'
import defaultVariableServices from '@/services/defaultVariable/defaultVariableServices'
import SaveIcon from '../../icons/SaveIcon'
import BackIcon from '../../icons/BackIcon'
import Loading from '../../icons/Loading'

export default {
  name: 'FormVariable',
  components: {
    SaveIcon,
    BackIcon,
    Loading
  },  
  data(){
    return {
      taxCustoms: '',
      transportPackaging: '',
      agentCommision: '',
      coefWholesalePrice: '',
      coefRetailPrice: '',
      coefWholesaleUSD: '',
      coefRetailUSD: '',
      record: [],
      error: [],
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async save(){
      try {
        this.isLoading = true

        const payload = {
          tax_customs: this.unformatNumber(this.taxCustoms),
          transport_packaging: this.unformatNumber(this.transportPackaging),
          agent_commision: this.unformatNumber(this.agentCommision),
          coef_wholesale_price: this.unformatNumber(this.coefWholesalePrice),
          coef_retail_price: this.unformatNumber(this.coefRetailPrice),
          coef_wholesale_usd: this.unformatNumber(this.coefWholesaleUSD),
          coef_retail_usd: this.unformatNumber(this.coefRetailUSD)
        }
        const response = await defaultVariableServices.save(payload)
        if (response.data.status === 'success') {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.tax_customs || responseReturn.transport_packaging || responseReturn.agent_commision || responseReturn.coef_wholesale_price || responseReturn.coef_retail_price || responseReturn.coef_wholesale_usd || responseReturn.coef_retail_usd) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },
    async fetchData(){
      try {
        const id = 1
        const response = await defaultVariableServices.fetchById(id)
        if (response.data.status === 'success') {
          this.record = response.data.data

          this.taxCustoms = this.formatNumber(this.record.tax_customs)
          this.transportPackaging = this.formatNumber(this.record.transport_packaging)
          this.agentCommision = this.formatNumber(this.record.agent_commision)
          this.coefWholesalePrice = this.formatNumber(this.record.coef_wholesale_price)
          this.coefRetailPrice = this.formatNumber(this.record.coef_retail_price)
          this.coefWholesaleUSD = this.formatNumber(this.record.coef_wholesale_usd)
          this.coefRetailUSD = this.formatNumber(this.record.coef_retail_usd)  
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error('Default Variable not yet configure!', this.toastedOptions)            
        }
      } catch (error) {
        console.log(error)
      }
    },
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    },   
    handleInputTaxCustoms(event) {
      this.taxCustoms = this.formatNumber(event.target.value)
    },
    handleInputTransportPackaging(event) {
      this.transportPackaging = this.formatNumber(event.target.value)
    },
    handleInputAgentCommision(event) {
      this.agentCommision = this.formatNumber(event.target.value)
    },    
    handleInputCoefWholesalePrice(event) {
      this.coefWholesalePrice = this.formatNumber(event.target.value)
    },
    handleInputCoefRetailPrice(event) {
      this.coefRetailPrice = this.formatNumber(event.target.value)
    },
    handleInputCoefWholesaleUSD(event) {
      this.coefWholesaleUSD = this.formatNumber(event.target.value)
    },
    handleInputCoefRetailUSD(event) {
      this.coefRetailUSD = this.formatNumber(event.target.value)
    },
    goBack() {
      window.history.length > 1 ? this.$router.back() : this.$router.push('/default-variable')
    }                       
  },
  created() {
    this.fetchData()
  }
}
</script>