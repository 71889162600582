import DefaultVariable from '../components/feature/defaultVariable/FormVariable'

const routes = [
  {
    path: '/default-variable',
    name: 'variable',
    component: DefaultVariable,
    meta: {
      title: 'Default Variable',
      requiresAuth: true
    }    
  } 
]

export default routes