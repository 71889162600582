import ListCalculation from '../components/feature/calculation/List'
import CreateCalculation from '../components/feature/calculation/Create'
import EditCalculation from '../components/feature/calculation/Edit'
import ShowCalculation from '../components/feature/calculation/Show'

const routes = [
  {
    path: '/calculations',
    name: 'calculation',
    component: ListCalculation,
    meta: {
      title: 'Calculations',
      requiresAuth: true
    }    
  },
  {
    path: '/calculations/create',
    name: 'calculationCreate',
    component: CreateCalculation,
    meta: {
      title: 'Create Calculation',
      requiresAuth: true
    }    
  },
  {
    path: '/calculations/:id/edit',
    name: 'calculationEdit',
    component: EditCalculation,
    meta: {
      title: 'Edit Calculation',
      requiresAuth: true
    }    
  },
  {
    path: '/calculations/:id',
    name: 'calculationShow',
    component: ShowCalculation,
    meta: {
      title: 'Show Calculation',
      requiresAuth: true
    }    
  }    
]

export default routes