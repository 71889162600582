import axios from '@/axios'

export default {
  fetchAll(params){
    return axios.get(`/purchase/all`, {params})
  },
  fetchLimit(params){
    return axios.get(`/purchase`, {params})
  },  
  fetchById(id){
    return axios.get(`/purchase/${id}`)
  },    
  create(payload){
    return axios.post(`/purchase`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  },
  update(id, payload){
    return axios.put(`/purchase/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }      
    })
  },
  delete(id){
    return axios.delete(`/purchase/${id}`)
  }, 
  deleteMultiple(payload){
    return axios.delete(`/purchase`, payload)
  },
  validate(id){
    return axios.post(`/purchase/validate/${id}`)
  },
  cancelValidation(id){
    return axios.post(`/purchase/cancel/${id}`)
  },
  closedPurchase(id){
    return axios.post(`/purchase/closed/${id}`)
  }, 
  closedRecreatePurchase(id, payload){
    return axios.post(`/purchase/recreate/${id}`, payload)
  },   
  fetchAvailablePO(params){
    return axios.get(`/purchase/available-po`, {params})
  },
  export(id, payload){
    return axios.post(`/purchase/export/${id}`, payload)
  }  
}