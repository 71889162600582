import axios from '@/axios'

export default {
  fetchAll(params, id){
    return axios.get(`/supplier-contacts/all/${id}`, {params})
  },
  fetchLimit(params, id){
    return axios.get(`/supplier-contacts/limit/${id}`, {params})
  },  
  fetchById(id){
    return axios.get(`/supplier-contacts/${id}`)
  },    
  create(payload){
    return axios.post(`/supplier-contacts`, payload)
  },
  update(id, payload){
    return axios.put(`/supplier-contacts/${id}`, payload)
  },
  delete(id){
    return axios.delete(`/supplier-contacts/${id}`)
  }, 
  deleteMultiple(payload){
    return axios.delete(`/supplier-contacts`, payload)
  }
}