<template>
  <div>
    <!-- ACTION PANEL SECTION -->
    <div class="flex border-b border-gray-200 text-gray-800 justify-between items-center mb-4 pb-2">
      <div class="md:w-1/2 w-full items-center">
        <h2 class="text-2xl font-medium">Product</h2>
      </div>
      <div class="flex items-center right-0">
        <router-link :to="{ name: 'productCreate' }" class="btn btn--primary">
          <PlusIcon>
            <span class="ml-2 md:block hidden">Create</span>
          </PlusIcon>
        </router-link>
        <Import />
        <Export />
      </div>
    </div>
    <!-- TABLE FILTER SECTION -->
    <div class="flex pb-4 justify-between items-center">
      <div class="relative">
        <select v-model="take" @change="updateTake()" class="px-4 py-2 pr-8 appearance-none rounded-md shadow-lg text-sm bg-white focus:bg-white text-black placeholder-gray-500 border border-gray-200 focus:border-purple-500 focus:outline-none">
          <option v-for="show in shows" :key="show.number" :value="show.number" :selected="show.number === take ? 'selected' : ''">{{ show.name }}</option>
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
          <svg class="w-5 h-5 fill-current" viewBox="0 0 24 24">
          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z" />
          </svg>
        </div>
      </div>
      <div class="flex w-96">
        <div class="relative flex-1">
          <svg width="20" height="20" fill="currentColor" class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"><path fill-rule="evenodd" clip-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"></path></svg>
          <input type="text" ref="search" v-model="search" @input="runSearch()" aria-label="Search" placeholder="Search ( Press &quot;/&quot; to focus )" class="w-full text-sm text-black placeholder-gray-500 border border-gray-200 rounded-md py-2 pl-10 focus:border-purple-500 focus:outline-none">  
        </div>         
      </div>
    </div>
    <!-- TABLE SECTION -->
    <div class="mb-4 bg-white shadow-lg rounded-lg overflow-y-auto">
      <table>
        <thead>
          <tr>
            <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Season Creation</th>
            <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Collection</th>
            <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Ref</th>
            <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Color</th>
            <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Size</th>
            <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Description</th>
            <th scope="col" class="px-2 border-r border-b border-purple-300 border-opacity-50 text-center" colspan="2">EURO Price</th>
            <th scope="col" class="px-2 border-r border-b border-purple-300 border-opacity-50 text-center" colspan="2">USD Price</th>
            <th scope="col" class="px-2 text-center" rowspan="2">Actions</th>
          </tr>
          <tr>
            <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center">Wholesale</th>
            <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center">Retail</th>
            <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center">Wholesale</th>
            <th scope="col" class="px-2 border-r border-purple-300 border-opacity-50 text-center">Retail</th>            
          </tr>            
        </thead>
        <tbody>
          <tr v-if="totalRecords === 0" class="border-b border-gray-200 hover:bg-gray-100"><td class="text-center" colspan="11">No data to display</td></tr>
          <tr v-else-if="totalFiltered === 0" class="border-b border-gray-200 hover:bg-gray-100"><td class="text-center" colspan="11">No matching records found</td></tr>
          <tr v-for="(product, index) in products" :key="product.id" class="border-b border-gray-200 hover:bg-gray-100" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">
            <td class="px-2 text-left">{{ product.season_creation }}</td>
            <td class="px-2 text-left">{{ product.collection }}</td>
            <td class="px-2 text-left">{{ product.reference }}</td>
            <td class="px-2 text-left">{{ product.color }}</td>
            <td class="px-2 text-left">{{ product.size }}</td>
            <td class="px-2 text-left">{{ product.description }}</td>
            <td class="px-2 text-right" :class="{ 'bg-red-100' : (product.approved_wholesale_price > 0) }">{{ formatNumber(toFixed(product.approved_wholesale_price, 0)) }} €</td>
            <td class="px-2 text-right" :class="{ 'bg-red-100' : (product.approved_retail_price > 0) }">{{ formatNumber(toFixed(product.approved_retail_price, 0)) }} €</td>
            <td class="px-2 text-right" :class="{ 'bg-yellow-100' : (product.approved_wholesale_price_usd > 0) }">{{ formatNumber(toFixed(product.approved_wholesale_price_usd, 0)) }} $</td>
            <td class="px-2 text-right" :class="{ 'bg-yellow-100' : (product.approved_retail_price_usd > 0) }">{{ formatNumber(toFixed(product.approved_retail_price_usd, 0)) }} $</td>            
            <td class="px-2 text-center">
              <div class="flex item-center justify-center">
                <router-link :to="{ name: 'productShow', params: { id: product.id } }" class="btn-show" alt="Show" v-tooltip="'Show'">
                  <ShowIcon />
                </router-link>
                <router-link :to="{ name: 'productEdit', params: { id: product.id } }"  class="btn-edit" alt="Edit" v-tooltip="'Edit'">
                  <EditIcon />
                </router-link>
                <button @click="confirmDialog( product.id )" type="button" class="btn-delete" alt="Delete" v-tooltip="'Delete'">
                  <DeleteIcon />
                </button>
              </div>
            </td>
          </tr>
        </tbody>                              
      </table>
      <div class="flex px-5 py-5 bg-white justify-between items-center">
        <div class="flex-shrink text-sm">
          <span>Showing {{ fromRecord }} to {{ toRecord }} of {{ totalFiltered }} Entries </span><span v-if="totalFiltered !== totalRecords"> ( filtered from {{ totalRecords }} total entries )</span>
        </div>
        <div class="pagination">
          <button type="button" :disabled="(currentPage - 1) === 0" @click="prevPage" class="page-item">
            <span class="text-sm">Prev</span>
          </button>
          <button type="button" :disabled="currentPage === lastPage || currentPage > lastPage" @click="nextPage" class="page-item">
            <span class="text-sm">Next</span>
          </button>
        </div>
      </div>                            
    </div>
    <!-- REPORT FILTER & ACTIONS -->
    <div class="fixed bg-gray-800 bg-opacity-25 w-full h-full inset-0 top-16 md:top-0 z-50" :class="{ 'hidden': !showFilter }">
      <div class="w-80 ml-auto h-full overflow-y-scroll bg-white shadow-lg right-0">
        <div class="flex p-5 border-b border-gray-200 items-center">
          <span class="flex-1 text-sm font-medium">Filters & Sort</span>
          <button type="button" class="flex right-0" @click="toggleFilter">
            <svg class="h-5 w-5 fill-current hover:text-gray-700" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
            </svg>
          </button>
        </div>
        <div class="p-5 items-center">
          <!-- LOCATION FILTER REPORT -->
          <div class="w-full mb-2">
            <div class="w-full mb-2">
              <label for="season_creation" class="label-control">Season Creation</label>
              <MultiSelect id="season_creation" v-model="seasonField" :options="seasons" :value="seasonField" :showLabels="false" placeholder="Tap to Search">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>
            </div>            
            <div class="w-full flex gap-2">              
              <div class="w-1/2 mb-2">
                <label for="collection" class="label-control">Collection</label>
                <MultiSelect id="collection" ref="collection" v-model="collection" :options="collections" :value="collection" :searchable="true" track-by="id" label="name" :showLabels="false" placeholder="Tap to Search">
                  <template slot="caret">
                    <div>
                      <div class="multiselect__select">
                        <span>
                          <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                            <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </template>
                </MultiSelect>                
              </div>
              <div class="w-1/2 mb-2">
                <label for="reference" class="label-control">Reference</label>
                <MultiSelect id="reference" ref="reference" v-model="referenceField" :options="references" :value="referenceField" :searchable="true" :showLabels="false" placeholder="Tap to Search">
                  <template slot="caret">
                    <div>
                      <div class="multiselect__select">
                        <span>
                          <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                            <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </template>
                </MultiSelect>
              </div>
            </div>
            <div class="w-full mb-2">
              <label for="image" class="label-control">Images</label>
              <MultiSelect id="image" ref="image" v-model="image" :options="images" :searchable="true" :showLabels="false" track-by="name" label="name" :allowEmpty="true" placeholder="Tap to Search">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>
            </div>
            <div class="w-full mb-2">
              <label for="color_field" class="label-control">Search</label>
              <input id="color_field" type="text" class="form-control" v-model="search" @input="runSearch()">
            </div>            
            <div class="w-full mb-2">
              <label for="date_field" class="label-control">Field</label>
              <MultiSelect id="date_field" v-model="dateField" :options="dateFields" :value="dateField" track-by="field" label="name" :allowEmpty="false">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>                 
            </div>             
            <v-date-picker ref="calendar" v-model="filterDate" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs" is-range>
              <template v-slot="{ inputValue, inputEvents, isDragging }">
                <div class="flex gap-2">
                  <div class="w-1/2 mb-2">                        
                    <label for="startDate" class="label-control">Start Date</label>
                    <div class="relative flex justify-between items-center">
                      <input id="startDate" ref="startDate" type="text" class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" autofocus>
                      <span class="h-full absolute pointer-events-none right-0">
                        <DateRange class="m-3" />
                      </span>                      
                    </div>
                  </div>
                  <div class="w-1/2 mb-2">
                    <label for="endDate" class="label-control">End Date</label>
                    <div class="relative flex justify-between items-center">
                      <input id="endDate" ref="endDate" type="text" class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end">
                      <span class="h-full absolute pointer-events-none right-0">
                        <DateRange class="m-3" />
                      </span>                      
                    </div>
                  </div>                  
                </div>
              </template>
            </v-date-picker>              
          </div>
          <div class="w-full mb-4">
            <div class="pb-2 mb-2 border-b border-gray-200">
              <span class="text-gray-800 font-medium text-sm">Sort By</span>
            </div>
            <div class="w-full mb-2">
              <label for="sort_field" class="label-control">Field</label>
              <MultiSelect id="sort_field" v-model="sortField" :options="sortFields" :value="sortField" :searchable="true" track-by="field" label="name" :allowEmpty="false" placeholder="Type to Search">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>                 
            </div>
            <div class="w-full">
              <label for="sort_option" class="label-control">Option</label>
              <MultiSelect id="sort_option" v-model="sortOption" :options="sortOptions" :value="sortOption" track-by="field" label="name" :allowEmpty="false">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>                 
            </div>
          </div>
          <!-- ACTION BUTTON -->
          <div class="w-full md:flex gap-2">
            <button :disabled="isLoading" @click.prevent="runFilter()" type="button" class="btn btn--primary" alt="Apply" v-tooltip="'Apply Data Filters'">
              <span v-if="isLoading" class="block">
                Loading...
              </span>
              <span :class="{ 'hidden' : isLoading }">Apply</span>
            </button>
            <button :disabled="isLoading" type="button" class="ml-1 sm:-pt-10 btn btn--secondary" @click="clearInput()">
              <span>Clear</span>           
            </button>
          </div>                    
        </div>
      </div>            
    </div>
    <div class="fixed top-0 bottom-0 right-0 z-20">
      <div class="absolute w-auto right-0 top-40 bg-green-500 text-white rounded-l-md shadow-lg" :class="{ 'hidden': showFilter }">
        <button class="block text-sm ml-1 px-2 py-4 cursor-pointer" v-tooltip="'Show Data Filters'" @click="toggleFilter">
          <FilterIcon></FilterIcon>
        </button>           
      </div>
    </div>        
  </div>
</template>

<script>
import format from '@/helpers/formatNumber'
import collectionServices from '@/services/collection/collectionServices'
import productServices from '@/services/product/productServices'
import PlusIcon from '../../icons/PlusIcon'
import DateRange from '../../icons/DateRange'
import ShowIcon from '../../icons/ShowIcon'
import EditIcon from '../../icons/EditIcon'
import DeleteIcon from '../../icons/DeleteIcon'
import FilterIcon from '../../icons/Filter2Icon'
import Import from './Import'
import Export from './Export'

export default {
  name: 'ListProduct',
  components: {
    PlusIcon,
    DateRange,
    ShowIcon,
    EditIcon,
    DeleteIcon,
    FilterIcon,
    Import,
    Export
  },
  data() {
    return {
      take: 25,
      page: 1,
      search: null,
      seasonField: '',
      seasons: [],
      collectionField: '',
      collection: '',
      collections: [],
      referenceField: '',
      references: [],
      image: '',
      images: [
        { name: 'Have a Image', value: 'yes' },
        { name: 'No Image', value: 'no' }
      ],
      dateField: { field: 'created_at', name: 'Created At' },
      dateFields: [
        { field: 'created_at', name: 'Created At' },
        { field: 'updated_at', name: 'Updated At' }
      ],
      filterDate: {
        start: '',
        end: ''
      },
      masks: {
        input: 'YYYY/MM/DD',
      },
      attrs: [
        {
          key: 'today',
          highlight: 'red',
          dates: new Date(),
        },
      ],
      sortField: { field: 'id', name: 'ID (Default)' },
      sortFields: [
        { field: 'collection_id', name: 'Collection ID' },
        { field: 'reference', name: 'Reference' },
        { field: 'color', name: 'Color' },
        { field: 'description', name: 'Description' },
        { field: 'wholesale_price', name: 'Wholesale Price' },
        { field: 'retail_price', name: 'Retail Price' },
        { field: 'updated_at', name: 'Updated' },
        { field: 'id', name: 'ID (Default)' }
      ],        
      sortOption: { field: 'desc', name: 'Descending' },
      sortOptions: [
        { field: 'asc', name: 'Ascending' },
        { field: 'desc', name: 'Descending' }
      ],          
      awaitingSearch: false,
      totalRecords: null,
      totalFiltered: null,
      fromRecord: null,
      toRecord: null,
      currentPage: null,
      lastPage: null,
      shows: [],
      products: [],
      error: [],
      showFilter: false,
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async fetchSeasonData() {
      try {
        const response = await productServices.fetchSeasonDataOptions()
        if (response.data.status === 'success') {
          const records = response.data.data
          this.seasons = []
          records.forEach(element => {
            this.seasons.push( element.season_creation )
          });
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },    
    async fetchCollectionData() {
      try {
        const response = await collectionServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.collections = []
          records.forEach(element => {
            this.collections.push({
              'id': element.id,
              'name': element.collection_name
            })
          });
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchCollectionById() {
      try {
        const id = this.collectionField ? this.collectionField : ''
        const response = await collectionServices.fetchById(id)
        if (response.data.status === 'success') {
          const record = response.data.data
          this.collection = { 'id': record.id, 'name': record.collection_name }
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },    
    async fetchReferenceData() {
      try {
        this.isLoading = true
        
        const params = {
          take: '',
          page: '',
          search: '',
          collection: this.collection ? this.collection.id : '',
          reference: '',          
          date_field: '',
          start: '',
          end: '',
          sort_field: '',
          sort_option: ''
        }
        const response = await productServices.fetchAllGroupByReference(params)
        if (response.data.status === 'success') {
          this.isLoading =false

          const records = response.data.data
          this.references = []
          records.forEach(element => {
            this.references.push(element.reference)
          });
          const data = [ ...new Set(this.references) ]
          data.sort()
          this.references = data
          if (records.length === 0) {
            this.referenceField = ''
          }
        } else {
          this.isLoading =false

          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        this.isLoading =false
        console.log(error)
      }
    },        
    async fetchData() {
      try {
        this.isLoading = true

        let params = {
          take: this.take,
          page: this.page,
          search: this.search,
          season: this.seasonField ? this.seasonField : '',
          collection: this.collectionField ? this.collectionField : '',
          reference: this.referenceField ? this.referenceField : '',
          image: this.image ? this.image.value : '',
          date_field: this.dateField.field,
          start: this.filterDate.start || '',
          end: this.filterDate.end || '',
          sort_field: this.sortField.field,
          sort_option: this.sortOption.field
        }
        const response = await productServices.fetchLimit(params)
        if (response.data.status === 'success') {
          this.isLoading =false

          const records = response.data.data
          this.totalRecords = records.total
          this.totalFiltered = records.total_filter
          this.fromRecord = records.from
          this.toRecord = records.to
          this.currentPage = records.current_page
          this.lastPage = records.last_page
          this.shows = records.show
          this.products = records.data
        } else {
          this.isLoading =false

          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        this.isLoading =false
        console.log(error)
      }
    },
    async delete(id) {
      try {
        const response = await productServices.delete(id)
        return response.data
      } catch (error) {     
        return error
      }
    },
    async confirmDialog(id) {
      // console.log(id)
      this.$swal.fire({
        title: 'Are you sure ?',
        text: "You won't be able to revert this!",
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#4b5563',
        confirmButtonText: 'Yes'
      }).then(async (result) => {
        if (result.isConfirmed) {
          /* CALL DELETE FUNCTION */
          const result = this.delete(id)
          const response = await result

          if (response.status) {
            if (response.status === 'success') {
              /* REFRESH DATA */
              this.fetchData()

              this.$swal.fire({
                title: 'Deleted!',
                text: "Your record has been deleted",
                icon: 'success',
                showConfirmButton: false,
                timer: 3000            
              })              
            } else {
              this.$swal.fire({
                title: 'Error!',
                text: response.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 5000            
              })              
            }
          } else {
            this.$toasted.error(response.message, this.toastedOptions)           
          }
        }
      })
    },
    updateQueryString() {
      const season = this.seasonField || ''
      const collection = this.collectionField || ''
      const reference = this.referenceField ? this.referenceField : ''
      const image = this.image ? this.image.value : ''
      const search = this.search ? this.search.toLowerCase() : ''
      const start = this.filterDate.start ? new Date(this.filterDate.start).toISOString().slice(0,10) : ''
      const end = this.filterDate.end ? new Date(this.filterDate.end).toISOString().slice(0,10) : ''
      const page = this.page

      this.$router.replace({ 
        query: {
          take: this.take,
          page: page,
          search: search,
          season: season,
          collection: collection,
          reference: reference,
          image: image,
          date_field: this.dateField.field,
          start: start,
          end: end,
          sort_field: this.sortField.field,
          sort_option: this.sortOption.field
        } 
      }).catch(() => {})
    },
    clearInput() {
      this.collection = ''
      this.collectionField = ''
      this.referenceField = ''
      this.filterDate = {}
      this.runFilter()
    },
    toggleFilter() {
      this.showFilter = !this.showFilter
    },    
    runFilter() {
      this.page = 1
      this.updateQueryString()
      this.fetchData()
    },
    searchFocus(event) {
      if (event.keyCode === 191) {
        setTimeout(() => {
          this.$refs.search.focus()
        }, 5)
      }
    },
    prevPage() {
      this.page = this.currentPage - 1
      this.updateQueryString()
      this.fetchData()
    },
    nextPage() {
      this.page = this.currentPage + 1
      this.updateQueryString()
      this.fetchData()
    },
    updateTake() {
      this.page = 1
      this.updateQueryString()
      this.fetchData()      
    },
    runSearch() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.page = 1
          this.updateQueryString()
          this.fetchData()
          this.awaitingSearch = false
        }, 1500)
      }
      this.awaitingSearch = true
    },
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    },     
  },
  created() {
    this.fetchSeasonData()
    this.fetchCollectionData()
    if (this.$route.query.collection) {
      this.fetchCollectionById()
    }
    this.fetchData()
    document.addEventListener("keydown", this.searchFocus);
  },
  destroyed() {
    document.removeEventListener("keydown", this.searchFocus);
  },
  watch: {
    seasonField: function() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.updateQueryString()
          this.awaitingSearch = false
        }, 1000)
      }
      this.awaitingSearch = true      
    },
    collection: function() {
      this.collectionField = this.collection ? this.collection.id : ''
      this.fetchReferenceData()
    },
    referenceField: function() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.updateQueryString()
          this.awaitingSearch = false
        }, 1000)
      }
      this.awaitingSearch = true
    },
    '$route.query.take': {
      handler: function(take) {
        if (take) {
          this.take = take
        }
      },
      immediate: true
    },    
    '$route.query.page': {
      handler: function(page) {
        this.page = page
      },
      immediate: true
    },
    '$route.query.search': {
      handler: function(search) {
        this.search = search
      },
      immediate: true
    },
    '$route.query.season': {
      handler: function(season) {
        this.seasonField = season
      },
      immediate: true
    },    
    '$route.query.collection': {
      handler: function(collection) {
        this.collectionField = collection
      },
      immediate: true
    },    
    '$route.query.reference': {
      handler: function(reference) {
        this.referenceField = reference
      },
      immediate: true
    },
    '$route.query.image': {
      handler: function(image) {
        try {
          const option = this.images.filter(item => image.includes(item.value))
          this.image = { name: option[0].name, value: option[0].value }          
        } catch (error) {
          this.image = ''
        }
      },
      immediate: true
    },    
    '$route.query.date_field': {
      handler: function(date_field) {
        try {
          const option = this.dateFields.filter(item => date_field.includes(item.field))
          this.dateField = { field: option[0].field, name: option[0].name }          
        } catch (error) {
          this.dateField = { field: 'created_at', name: 'Created At' }        
        }
      },
      immediate: true
    },
    '$route.query.start': {
      handler: function(start) {
        this.filterDate.start = start
      },
      immediate: true
    },
    '$route.query.end': {
      handler: function(end) {
        this.filterDate.end = end
      },
      immediate: true
    },
    '$route.query.sort_field': {
      handler: function(sort_field) {
        try {
        const option = this.sortFields.filter(item => sort_field.includes(item.field))
        this.sortField = { field: option[0].field, name: option[0].name }          
        } catch (error) {
          this.sortField = { field: 'id', name: 'Latest' }
        }
      },
      immediate: true
    },
    '$route.query.sort_option': {
      handler: function(sort_option) {
        try {
          const option = this.sortOptions.filter(item => sort_option.includes(item.field))
          this.sortOption = { field: option[0].field, name: option[0].name }
        } catch (error) {
          this.sortOption = { field: 'desc', name: 'Descending' }
        }
      },
      immediate: true
    }                    
  },
  mounted() {
    const thisInstance = this
    this.$root.$on('fetchData', function(){
      thisInstance.fetchData()
    })           
  }  
}
</script>