<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="bg-white shadow-lg rounded-lg" @submit.prevent="handleSubmit(create)">
        <div class="p-10">
          <div class="pb-2 mb-4 border-b border-gray-200">
            <h2 class="text-gray-800 text-2xl font-medium">Create Calculation</h2>
          </div>
          <div class="w-full">
            <div class="w-full md:flex mb-2 gap-6">
              <div class="w-full md:w-1/2">
                <div class="w-full md:flex gap-2 mb-2">
                  <div class="flex md:w-3/12">
                    <div class="w-full mb-2 md:mb-0">
                      <label for="season" class="label-control md:py-3">Season <span class="text-red-600">*</span></label>
                    </div>
                  </div>
                  <div class="w-full md:w-9/12 mb-2 md:mb-0">
                    <ValidationProvider name="Season" rules="required" v-slot="{ errors }">
                      <MultiSelect id="season" v-model="season" :options="seasons" :value="season" :searchable="true" track-by="id" label="name" placeholder="Type to Search">
                        <template slot="caret">
                          <div>
                            <div class="multiselect__select">
                              <span>
                                <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                                  <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </template>
                      </MultiSelect>
                      <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                    </ValidationProvider>
                    <div v-if="error.season" class="text-sm text-red-600"><span>{{ error.season[0] }}</span></div>
                  </div>
                </div>
              </div>
              <div class="w-full md:w-1/2">
                <div class="w-full md:flex gap-2 mb-2">
                  <div class="flex md:w-3/12">
                    <div class="w-full mb-2 md:mb-0">
                      <label for="next_season" class="label-control md:py-3">Next Season <span class="text-red-600">*</span></label>
                    </div>
                  </div>
                  <div class="w-full md:w-9/12 mb-2 md:mb-0">
                    <ValidationProvider name="NextSeason" rules="" v-slot="{ errors }">
                      <MultiSelect id="next_season" v-model="nextSeason" :options="seasons" :value="nextSeason" :searchable="true" track-by="id" label="name" placeholder="Type to Search">
                        <template slot="caret">
                          <div>
                            <div class="multiselect__select">
                              <span>
                                <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                                  <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </template>
                      </MultiSelect>
                      <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                    </ValidationProvider>
                    <div v-if="error.next_season" class="text-sm text-red-600"><span>{{ error.next_season[0] }}</span></div>
                  </div>
                </div>                
              </div>
            </div>
            <div class="w-full md:flex gap-6">
              <div class="w-full md:w-1/2">
                <div class="w-full md:flex gap-2 mb-2">
                  <div class="flex md:w-3/12">
                    <div class="w-full mb-2 md:mb-0">
                      <label for="period_1_start" class="label-control md:py-3">Period 1 <span class="text-red-600">*</span></label>
                    </div>
                  </div>
                  <div class="w-full md:w-9/12 mb-2 md:mb-0">
                    <v-date-picker ref="calendar" v-model="periodDate1" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs" is-range>
                      <template v-slot="{ inputValue, inputEvents, isDragging }">
                        <div class="flex flex-col sm:flex-row justify-start items-center">
                          <div class="w-full flex-grow mb-2 md:mb-0">
                            <ValidationProvider name="Period 1 Start" rules="" v-slot="{ errors }">
                              <div class="w-full relative flex-grow">
                                <input id="period_1_start" class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" readonly/>
                                <span class="h-full absolute pointer-events-none right-0">
                                  <DateRange class="m-3" />
                                </span> 
                              </div>
                              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                            </ValidationProvider>
                            <div v-if="error.period_1_start" class="text-sm text-red-600"><span>{{ error.period_1_start[0] }}</span></div>
                          </div>
                          <span class="flex-shrink-0 m-2 hidden md:block">
                            <svg class="w-4 h-4 stroke-current text-gray-600" viewBox="0 0 24 24">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                            </svg>
                          </span>
                          <div class="w-full flex-grow">
                            <ValidationProvider name="Period 1 End" rules="" v-slot="{ errors }">
                              <div class="w-full relative flex-grow">
                                <input class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end" readonly/>
                                <span class="h-full absolute pointer-events-none right-0">
                                  <DateRange class="m-3" />
                                </span>
                              </div>
                              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                            </ValidationProvider>
                            <div v-if="error.period_1_end" class="text-sm text-red-600"><span>{{ error.period_1_end[0] }}</span></div>                               
                          </div>                
                        </div>
                      </template>
                    </v-date-picker>            
                  </div>
                </div>
                <div class="w-full md:flex gap-2 mb-2">
                  <div class="flex md:w-3/12">
                    <div class="w-full mb-2 md:mb-0">
                      <label for="period_2_start" class="label-control md:py-3">Period 2 <span class="text-red-600">*</span></label>
                    </div>
                  </div>
                  <div class="w-full md:w-9/12 mb-2 md:mb-0">
                    <v-date-picker ref="calendar" v-model="periodDate2" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs" :min-date="new Date(new Date(periodDate1.end || minDate2).setDate(new Date(periodDate1.end || minDate2).getDate()+1))" is-range>
                      <template v-slot="{ inputValue, inputEvents, isDragging }">
                        <div class="flex flex-col sm:flex-row justify-start items-center">
                          <div class="w-full flex-grow mb-2 md:mb-0">
                            <ValidationProvider name="Period 2 Start" rules="" v-slot="{ errors }">
                              <div class="w-full relative flex-grow">
                                <input id="period_2_start" class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" readonly/>
                                <span class="h-full absolute pointer-events-none right-0">
                                  <DateRange class="m-3" />
                                </span> 
                              </div>
                              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                            </ValidationProvider>
                            <div v-if="error.period_2_start" class="text-sm text-red-600"><span>{{ error.period_2_start[0] }}</span></div>
                          </div>
                          <span class="flex-shrink-0 m-2 hidden md:block">
                            <svg class="w-4 h-4 stroke-current text-gray-600" viewBox="0 0 24 24">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                            </svg>
                          </span>
                          <div class="w-full flex-grow">
                            <ValidationProvider name="Period 2 End" rules="" v-slot="{ errors }">
                              <div class="w-full relative flex-grow">
                                <input class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end" readonly/>
                                <span class="h-full absolute pointer-events-none right-0">
                                  <DateRange class="m-3" />
                                </span>
                              </div>
                              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                            </ValidationProvider>
                            <div v-if="error.period_2_end" class="text-sm text-red-600"><span>{{ error.period_2_end[0] }}</span></div>                               
                          </div>                
                        </div>
                      </template>
                    </v-date-picker>            
                  </div>
                </div>
                <div class="w-full md:flex gap-2 mb-2">
                  <div class="flex md:w-3/12">
                    <div class="w-full mb-2 md:mb-0">
                      <label for="period_3_start" class="label-control md:py-3">Period 3 <span class="text-red-600">*</span></label>
                    </div>
                  </div>
                  <div class="w-full md:w-9/12 mb-2 md:mb-0">
                    <v-date-picker ref="calendar" v-model="periodDate3" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs" :min-date="new Date(new Date(periodDate2.end || minDate3).setDate(new Date(periodDate2.end || minDate3).getDate()+1))" is-range>
                      <template v-slot="{ inputValue, inputEvents, isDragging }">
                        <div class="flex flex-col sm:flex-row justify-start items-center">
                          <div class="w-full flex-grow mb-2 md:mb-0">
                            <ValidationProvider name="Period 3 Start" rules="" v-slot="{ errors }">
                              <div class="w-full relative flex-grow">
                                <input id="period_3_start" class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" readonly/>
                                <span class="h-full absolute pointer-events-none right-0">
                                  <DateRange class="m-3" />
                                </span> 
                              </div>
                              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                            </ValidationProvider>
                            <div v-if="error.period_3_start" class="text-sm text-red-600"><span>{{ error.period_3_start[0] }}</span></div>
                          </div>
                          <span class="flex-shrink-0 m-2 hidden md:block">
                            <svg class="w-4 h-4 stroke-current text-gray-600" viewBox="0 0 24 24">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                            </svg>
                          </span>
                          <div class="w-full flex-grow">
                            <ValidationProvider name="Period 3 End" rules="" v-slot="{ errors }">
                              <div class="w-full relative flex-grow">
                                <input class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end" readonly/>
                                <span class="h-full absolute pointer-events-none right-0">
                                  <DateRange class="m-3" />
                                </span>
                              </div>
                              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                            </ValidationProvider>
                            <div v-if="error.period_3_end" class="text-sm text-red-600"><span>{{ error.period_3_end[0] }}</span></div>                               
                          </div>                
                        </div>
                      </template>
                    </v-date-picker>            
                  </div>
                </div>            
              </div>
              <div class="w-full md:w-1/2">
                <div class="w-full md:flex gap-2 mb-2">
                  <div class="flex md:w-3/12">
                    <div class="w-full mb-2 md:mb-0">
                      <label for="period_4_start" class="label-control md:py-3">Period 4 <span class="text-red-600">*</span></label>
                    </div>
                  </div>
                  <div class="w-full md:w-9/12 mb-2 md:mb-0">
                    <v-date-picker ref="calendar" v-model="periodDate4" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs" :min-date="new Date(new Date(periodDate3.end || minDate4).setDate(new Date(periodDate3.end || minDate4).getDate()+1))" is-range>
                      <template v-slot="{ inputValue, inputEvents, isDragging }">
                        <div class="flex flex-col sm:flex-row justify-start items-center">
                          <div class="w-full flex-grow mb-2 md:mb-0">
                            <ValidationProvider name="Period 4 Start" rules="" v-slot="{ errors }">
                              <div class="w-full relative flex-grow">
                                <input id="period_4_start" class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" readonly/>
                                <span class="h-full absolute pointer-events-none right-0">
                                  <DateRange class="m-3" />
                                </span> 
                              </div>
                              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                            </ValidationProvider>
                            <div v-if="error.period_4_start" class="text-sm text-red-600"><span>{{ error.period_4_start[0] }}</span></div>
                          </div>
                          <span class="flex-shrink-0 m-2 hidden md:block">
                            <svg class="w-4 h-4 stroke-current text-gray-600" viewBox="0 0 24 24">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                            </svg>
                          </span>
                          <div class="w-full flex-grow">
                            <ValidationProvider name="Period 4 End" rules="" v-slot="{ errors }">
                              <div class="w-full relative flex-grow">
                                <input class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end" readonly/>
                                <span class="h-full absolute pointer-events-none right-0">
                                  <DateRange class="m-3" />
                                </span>
                              </div>
                              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                            </ValidationProvider>
                            <div v-if="error.period_4_end" class="text-sm text-red-600"><span>{{ error.period_4_end[0] }}</span></div>                               
                          </div>                
                        </div>
                      </template>
                    </v-date-picker>            
                  </div>
                </div>
                <div class="w-full md:flex gap-2 mb-2">
                  <div class="flex md:w-3/12">
                    <div class="w-full mb-2 md:mb-0">
                      <label for="period_5_start" class="label-control md:py-3">Period 5 <span class="text-red-600">*</span></label>
                    </div>
                  </div>
                  <div class="w-full md:w-9/12 mb-2 md:mb-0">
                    <v-date-picker ref="calendar" v-model="periodDate5" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs" :min-date="new Date(new Date(periodDate4.end || minDate5).setDate(new Date(periodDate4.end || minDate5).getDate()+1))" is-range>
                      <template v-slot="{ inputValue, inputEvents, isDragging }">
                        <div class="flex flex-col sm:flex-row justify-start items-center">
                          <div class="w-full flex-grow mb-2 md:mb-0">
                            <ValidationProvider name="Period 5 Start" rules="" v-slot="{ errors }">
                              <div class="w-full relative flex-grow">
                                <input id="period_5_start" class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" readonly/>
                                <span class="h-full absolute pointer-events-none right-0">
                                  <DateRange class="m-3" />
                                </span> 
                              </div>
                              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                            </ValidationProvider>
                            <div v-if="error.period_5_start" class="text-sm text-red-600"><span>{{ error.period_5_start[0] }}</span></div>
                          </div>
                          <span class="flex-shrink-0 m-2 hidden md:block">
                            <svg class="w-4 h-4 stroke-current text-gray-600" viewBox="0 0 24 24">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                            </svg>
                          </span>
                          <div class="w-full flex-grow">
                            <ValidationProvider name="Period 5 End" rules="" v-slot="{ errors }">
                              <div class="w-full relative flex-grow">
                                <input class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end" readonly/>
                                <span class="h-full absolute pointer-events-none right-0">
                                  <DateRange class="m-3" />
                                </span>
                              </div>
                              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                            </ValidationProvider>
                            <div v-if="error.period_5_end" class="text-sm text-red-600"><span>{{ error.period_5_end[0] }}</span></div>                               
                          </div>                
                        </div>
                      </template>
                    </v-date-picker>            
                  </div>
                </div>
                <div class="w-full md:flex gap-2 mb-2">
                  <div class="flex md:w-3/12">
                    <div class="w-full mb-2 md:mb-0">
                      <label for="period_6_start" class="label-control md:py-3">Period 6 <span class="text-red-600">*</span></label>
                    </div>
                  </div>
                  <div class="w-full md:w-9/12 mb-2 md:mb-0">
                    <v-date-picker ref="calendar" v-model="periodDate6" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs" :min-date="new Date(new Date(periodDate5.end || minDate6).setDate(new Date(periodDate5.end || minDate6).getDate()+1))" is-range>
                      <template v-slot="{ inputValue, inputEvents, isDragging }">
                        <div class="flex flex-col sm:flex-row justify-start items-center">
                          <div class="w-full flex-grow mb-2 md:mb-0">
                            <ValidationProvider name="Period 6 Start" rules="" v-slot="{ errors }">
                              <div class="w-full relative flex-grow">
                                <input id="period_6_start" class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" readonly/>
                                <span class="h-full absolute pointer-events-none right-0">
                                  <DateRange class="m-3" />
                                </span> 
                              </div>
                              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                            </ValidationProvider>
                            <div v-if="error.period_6_start" class="text-sm text-red-600"><span>{{ error.period_6_start[0] }}</span></div>
                          </div>
                          <span class="flex-shrink-0 m-2 hidden md:block">
                            <svg class="w-4 h-4 stroke-current text-gray-600" viewBox="0 0 24 24">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                            </svg>
                          </span>
                          <div class="w-full flex-grow">
                            <ValidationProvider name="Period 6 End" rules="" v-slot="{ errors }">
                              <div class="w-full relative flex-grow">
                                <input class="form-control" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end" readonly/>
                                <span class="h-full absolute pointer-events-none right-0">
                                  <DateRange class="m-3" />
                                </span>
                              </div>
                              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                            </ValidationProvider>
                            <div v-if="error.period_6_end" class="text-sm text-red-600"><span>{{ error.period_6_end[0] }}</span></div>                               
                          </div>                
                        </div>
                      </template>
                    </v-date-picker>            
                  </div>
                </div>            
              </div>
            </div>
          </div>
          <div class="md:flex gap-4 mt-4 mb-2">
            <button :disabled="isLoading" type="submit" class="btn btn--primary" alt="Save" title="Save">
              <span v-if="isLoading" class="block mx-5">
                <Loading />
              </span>
              <SaveIcon v-else>
                <span class="ml-2 md:block hidden">Save &amp; Continue</span>
              </SaveIcon>
            </button>
            <button :disabled="isLoading" type="button" @click="goBack()" class="btn btn--secondary" alt="Back" title="Back">
              <BackIcon>
                <span class="ml-2 md:block hidden">Back</span>
              </BackIcon>              
            </button>
          </div>                                                  
        </div>
      </form>      
    </ValidationObserver>   
  </div>
</template>

<script>
import format from '@/helpers/formatNumber'
import seasonServices from '@/services/season/seasonServices'
import calculationServices from '@/services/calculation/calculationServices'
import SaveIcon from '../../icons/SaveIcon'
import BackIcon from '../../icons/BackIcon'
import Loading from '../../icons/Loading'
import DateRange from '../../icons/DateRange'

export default {
  name: 'CreateCalculation',
  components: {
    SaveIcon,
    BackIcon,
    Loading,
    DateRange
  },
  data(){
    return {
      season: '',
      nextSeason: '',
      seasons: [],
      periodDate1: {
        start: '',
        end: ''
      },
      periodDate2: {
        start: '',
        end: ''
      },
      periodDate3: {
        start: '',
        end: ''
      },
      periodDate4: {
        start: '',
        end: ''
      },
      periodDate5: {
        start: '',
        end: ''
      },
      periodDate6: {
        start: '',
        end: ''
      },                  
      masks: {
        input: 'YYYY/MM/DD',
      },
      attrs: [
        {
          key: 'today',
          highlight: 'red',
          dates: new Date(),
        },
      ],
      minDate2: new Date(),
      minDate3: new Date(),
      minDate4: new Date(),
      minDate5: new Date(), 
      minDate6: new Date(),      
      error: [],
      prevRoute: null,
      showFunction: false,
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },  
  methods: {
    async fetchSeasonData() {
      try {
        const response = await seasonServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.seasons = []
          records.forEach(element => {
            this.seasons.push({
              'id': element.id,
              'name': element.season_name
            })
          });          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },    
    async create(){
      try {
        this.isLoading = true
        const payload = {
          season: this.season.id,
          next_season: this.nextSeason ? this.nextSeason.id : null,
          period_1_start: this.periodDate1.start,
          period_1_end: this.periodDate1.end,
          period_2_start: this.periodDate2.start,
          period_2_end: this.periodDate2.end,
          period_3_start: this.periodDate3.start,
          period_3_end: this.periodDate3.end,
          period_4_start: this.periodDate4.start,
          period_4_end: this.periodDate4.end,
          period_5_start: this.periodDate5.start,
          period_5_end: this.periodDate5.end,
          period_6_start: this.periodDate6.start,
          period_6_end: this.periodDate6.end
        }

        const response = await calculationServices.create(payload)
        if (response.data.status === 'success') {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          /* SET NEW ID & REDIRECT TO EDIT PAGE WITH CREATED ID */
          this.$store.dispatch('setNewCalculationID', response.data.data.id)
          this.$router.push({ name: 'calculationEdit', params: { id: response.data.data.id} })
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.season || responseReturn.next_season || responseReturn.period_1_start || responseReturn.period_1_end || responseReturn.period_2_start 
              || responseReturn.period_2_end || responseReturn.period_3_start || responseReturn.period_3_end || responseReturn.period_4_start 
              || responseReturn.period_4_end || responseReturn.period_5_start || responseReturn.period_5_end || responseReturn.period_6_start || responseReturn.period_6_end) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },
    onlyNumber(num) {
      let result = format.onlyNumber(num)
      return result
    },
    goBack() {
      window.history.length > 1 ? this.$router.back() : this.$router.push('/calculations')
    }      
  },
  created(){
    this.fetchSeasonData()
  },
  watch: {
    prevRoute: function() {
      this.$store.dispatch('setIndexCalculationRoute', this.prevRoute)
    }
  }  
}
</script>