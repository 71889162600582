<template>
  <div>
    <!-- ACTION PANEL SECTION -->
    <div class="flex border-b border-gray-200 text-gray-800 justify-between items-center mb-4 pb-2">
      <div class="md:w-1/2 w-full items-center">
        <h2 class="text-2xl font-medium">Items</h2>
      </div>
      <div class="flex items-center right-0">
        <button v-if="show" :disabled="parentLoading" type="button" class="btn btn--success" id="addItem" @click="toggleNew">
          <PlusIcon>
            <span class="ml-2 md:block hidden">Add Item</span>
          </PlusIcon>
        </button>       
      </div>
    </div>     
    <!-- TABLE SECTION -->
    <div class="mb-4 bg-white shadow-lg rounded-lg overflow-y-auto">
      <table>
        <thead>
          <tr>
            <th scope="col" class="px-3 text-left">Supplier</th>
            <th scope="col" class="px-3 text-left">Reference</th>
            <th scope="col" class="px-3 text-left">Color</th>
            <th scope="col" class="px-3 text-left">Size</th>
            <th scope="col" class="px-3 text-right">In Stock</th>
            <th scope="col" class="px-3 text-left">Unit</th>
            <th scope="col" class="px-3 text-right">Transfer</th>
            <th scope="col" class="px-3 text-center" v-if="show">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="details.length === 0" class="border-b border-gray-200 hover:bg-gray-100"><td class="px-3 text-center" colspan="8">No data to display</td></tr>
          <tr v-for="(detail, index) in details" :key="detail.id" class="border-b border-gray-200 hover:bg-gray-100" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">
            <td class="px-3 text-left">{{ detail.supplier }}</td>
            <td class="px-3 text-left">{{ detail.material_name }}</td>
            <td class="px-3 text-left">{{ detail.color }}</td>
            <td class="px-3 text-left">{{ detail.size }}</td>
            <td class="px-3 text-right">{{ formatNumber(detail.in_stock) }}</td>
            <td class="px-3 text-left">{{ detail.unit }}</td>
            <td class="px-3 text-right">{{ formatNumber(detail.transfer_qty) }}</td>
            <td class="px-3 text-center" v-if="show">
              <div class="flex item-center justify-center">
                <button :disabled="parentLoading" @click="toggleEdit( detail.id )" type="button" class="btn-edit" alt="Edit" v-tooltip="'Edit'">
                  <EditIcon />
                </button>        
                <button :disabled="parentLoading" @click="confirmDialog( detail.id )" type="button" class="btn-delete" alt="Delete" v-tooltip="'Delete'">
                  <DeleteIcon />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="flex px-5 py-5 bg-white justify-between items-center"></div>                            
    </div>
    <!-- Modal Dialog -->
    <modal :show="showModal" @close="showModal = false" addClass="modal-lg" modalOrientation="flex items-center justify-center">
      <template v-slot:header><h3>{{ modalTitle }}</h3></template>
      <template v-slot:body>
        <form id="modalForm" @submit.prevent="save">
          <div class="md:flex gap-6">
            <div class="w-full mb-4">
              <label for="material" class="label-control">Material <span class="text-red-600">*</span></label>
              <MultiSelect id="material" v-model="material" :options="materials" :value="material" :searchable="true" @input="toggleFocus" track-by="id" label="name" :custom-label="nameWithColor" placeholder="Type to Search">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>
              <div v-if="error.material" class="text-sm text-red-600"><span>{{ error.material[0] }}</span></div>             
            </div>
          </div>
          <div class="md:flex gap-6">
            <div class="w-full mb-4">
              <h3 class="text-lg">Details</h3>
            </div>
          </div>
          <div class="md:flex gap-6">
            <div class="w-full mb-4">
              <label for="reference" class="label-control">Reference</label>
              <input id="reference" type="text" v-model="reference" class="form-control" readonly>
              <div v-if="error.reference" class="text-sm text-red-600"><span>{{ error.reference[0] }}</span></div>                
            </div>
          </div>          
          <div class="md:flex gap-6">
            <div class="md:w-1/2 mb-4">
              <label for="color" class="label-control">Color</label>
              <input id="color" type="text" v-model="color" class="form-control" readonly>
              <div v-if="error.color" class="text-sm text-red-600"><span>{{ error.color[0] }}</span></div> 
            </div>
            <div class="flex md:w-1/2 gap-6 mb-4">
              <div class="md:w-1/2">
                <label for="size" class="label-control">Size</label>
                <input id="size" type="text" v-model="size" class="form-control" readonly>
                <div v-if="error.size" class="text-sm text-red-600"><span>{{ error.size[0] }}</span></div>              
              </div>
              <div class="md:w-1/2">
                <label for="unit" class="label-control">Unit</label>
                <input id="unit" type="text" v-model="unit" class="form-control" readonly>
                <div v-if="error.unit" class="text-sm text-red-600"><span>{{ error.unit[0] }}</span></div>
              </div>
            </div>
          </div>
          <div class="md:flex gap-6">
            <div class="flex md:w-1/2 gap-6">
              <div class="md:w-1/2">
                <label for="in_stock" class="label-control">In Stock <span class="text-red-600">*</span></label>
                <input id="in_stock" type="text" v-model="inStock" class="form-control text-right" readonly>
                <div v-if="error.in_stock" class="text-sm text-red-600"><span>{{ error.in_stock[0] }}</span></div>          
              </div>
              <div class="md:w-1/2">
                <label for="transfer_qty" class="label-control">Transfer Qty<span class="text-red-600">*</span></label>
                <input id="transfer_qty" ref="transfer_qty" type="text" v-model="transferQty" @input="handleInputQty" class="form-control text-right">
                <div v-if="error.transfer_qty" class="text-sm text-red-600"><span>{{ error.transfer_qty[0] }}</span></div>  
              </div>
            </div>
            <div class="flex md:w-1/2 gap-6">
            </div>
          </div>
        </form>     
      </template>
      <template v-slot:footer>
        <button :disabled="isLoading" type="submit" form="modalForm" class="btn btn--primary">
          Save
        </button>      
      </template> 
    </modal>    
  </div>  
</template>

<script>
import format from '@/helpers/formatNumber'
import materialServices from '@/services/material/materialServices'
import transferDetailServices from '@/services/transfer/transferDetailServices'
import Modal from '../../widgets/Modal'
import PlusIcon from '../../icons/PlusIcon'
import EditIcon from '../../icons/EditIcon'
import DeleteIcon from '../../icons/DeleteIcon'

export default {
  name: 'TransferDetail',
  components: {
    Modal,
    PlusIcon,
    EditIcon,
    DeleteIcon,
  },
  props: ['show', 'source', 'parentLoading'],
  data() {
    return {
      modalTitle: '',     
      showModal: false,
      details: [],
      detailId: '',
      material: '',
      reference: '',
      color: '',
      size: '',
      unit: '',
      inStock: '',
      transferQty: '',
      grandTotal: '',
      materials: [],
      detailStock: '',
      error: [],
      isEdit: false,
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async fetchDetailData() {
      try {
        this.isLoading = true

        const id = this.$route.params.id
        const response = await transferDetailServices.fetchAll(null, id)
        if (response.data.status === 'success') {
          this.isLoading =false

          this.details = response.data.data

          this.grandTotal = 0
          this.details.forEach(element => {
            this.grandTotal += parseFloat(element.transfer_qty)
          });
          this.grandTotal = this.grandTotal.toFixed(2)
        } else {
          this.isLoading =false

          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        this.isLoading =false
        console.log(error)
      }
    },
    async fetchMaterialData() {
      try {
        const params = {
          take: '',
          page: '',
          search: '',
          date_field: '',
          start: '',
          end: '',
          sort_field: 'material_name',
          sort_option: 'ASC'
        }        
        const response = await materialServices.fetchDataOptions(params)
        if (response.data.status === 'success') {
          const records = response.data.data
          records.forEach(element => {
            this.materials.push({
              'id': element.id,
              'supplier': element.supplier,
              'name': element.material_name,
              'color': element.color,
              'size': element.size,
              'unit': element.unit
            })
          });          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchData(id){
      try {
        const response = await transferDetailServices.fetchById(id)
        if (response.data.status === 'success') {
          this.record = response.data.data

          this.material = {
            'id': this.record.material_id,
            'supplier': this.record.supplier,
            'name': this.record.material_name,
            'color': this.record.color,
            'size': this.record.size,
            'unit': this.record.unit
          }
          this.reference = this.record.material_name
          this.color = this.record.color
          this.size = this.record.size
          this.unit = this.record.unit
          this.inStock = this.formatNumber(this.record.in_stock)
          this.transferQty = this.formatNumber(this.record.transfer_qty)
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)            
        }
      } catch (error) {
        console.log(error)
      }
    },    
    async save(){
      try {
        this.isLoading = true
        const payload = {
          transfer_stock_id: this.$route.params.id,
          material: this.material.id,
          reference: this.reference,
          color: this.color,
          size: this.size,
          unit: this.unit,
          in_stock: this.unformatNumber(this.inStock),
          transfer_qty: this.unformatNumber(this.transferQty)
        }

        let response = ''
        if (this.isEdit) {
          response = await transferDetailServices.update(this.detailId, payload)
        } else {
          response = await transferDetailServices.create(payload)
        }
        
        if (response.data.status === 'success') {
          /* SET IS EDIT STATE TO FALSE */
          this.isEdit = false
          
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          if (this.detailId) {
            this.showModal = false
          }

          /* CLEAR INPUT FORM */
          this.clearForm()

          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          /* RELOAD DETAILS DATA */
          this.fetchDetailData()
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.material || responseReturn.in_stock || responseReturn.transfer_qty) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },
    async delete(id) {
      try {
        const response = await transferDetailServices.delete(id)
        return response.data
      } catch (error) {     
        return error
      }
    },
    async confirmDialog(id) {
      // console.log(id)
      this.$swal.fire({
        title: 'Are you sure ?',
        text: "You won't be able to revert this!",
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#4b5563',
        confirmButtonText: 'Yes'
      }).then(async (result) => {
        if (result.isConfirmed) {
          /* CALL DELETE FUNCTION */
          const result = this.delete(id)
          const response = await result

          if (response.status) {
            if (response.status === 'success') {
              /* REFRESH DATA */
              this.fetchDetailData()

              this.$swal.fire({
                title: 'Deleted!',
                text: "Your record has been deleted",
                icon: 'success',
                showConfirmButton: false,
                timer: 3000            
              })              
            } else {
              this.$swal.fire({
                title: 'Error!',
                text: response.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 3000            
              })              
            }
          } else {
            this.$toasted.error(response.message, this.toastedOptions)           
          }
        }
      })
    },     
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    },           
    nameWithColor ({ supplier, name, color, size }) {
      if (color && size) {
        return `${supplier} ${name} — ${color} [ ${size} ]`
      } else if (color) {
        return `${supplier} ${name} — ${color}`
      }
      return `${supplier} ${name}`
    },
    toggleFocus() {
      this.fetchDetailMaterialData(this.material.id)
      this.$refs.transfer_qty.focus()
    },
    handleInputQty(event) {
      this.transferQty = this.formatNumber(event.target.value)
    },            
    toggleNew() {
      this.isEdit = false
      this.error = []
      this.showModal = true
      this.modalTitle = 'Add Item'
      this.clearForm()
    },
    toggleEdit(id) {
      this.isEdit = true
      this.error = []
      this.showModal = true
      this.modalTitle = 'Edit Item'
      this.clearForm()
      this.detailId = id
      this.fetchData(id)
    },
    clearForm() {
      this.detailId = ''
      this.material = ''
      this.reference = '' 
      this.color = ''
      this.size = ''
      this.unit = ''
      this.inStock = ''
      this.transferQty = ''
    },
    async fetchDetailMaterialData(id) {
      const params = {
        factory: this.source,
        id: id
      }
      const response = await materialServices.fetchStockByFactory(params)
      if (response.data.status === 'success') {
        const data = response.data.data
        this.reference = data.material_name
        this.color = data.color
        this.size = data.size
        this.unit = data.unit
        this.inStock = this.formatNumber(data.qty)
      } else {
        /* ELSE, THROW ERROR MESSAGES */
        this.$toasted.error(response.data.message, this.toastedOptions)
      }
    }
  },
  created() {
    this.fetchDetailData()
    this.fetchMaterialData()
  }
}
</script>