const state = {
  totalPurchase: 0
};

const getters = {
  totalPurchase: state => state.totalPurchase
};

const actions = {
  setTotalPurchase({commit}, total){
    commit('setTotalPurchase', total)
  }
};

const mutations = {
  setTotalPurchase(state, total){
    state.totalPurchase = total
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};