const state = {
  newCalculationID: null,
  indexCalculationRoute: null
};

const getters = {
  newCalculationID: state => state.newCalculationID,
  indexCalculationRoute: state => state.indexCalculationRoute
};

const actions = {
  setNewCalculationID({commit}, newCalculationID){
    commit('setNewCalculationID', newCalculationID)
  },
  setIndexCalculationRoute({commit}, fullPath){
    commit('setIndexCalculationRoute', fullPath)
  }
};

const mutations = {
  setNewCalculationID(state, newCalculationID){
    state.newCalculationID = newCalculationID
  },
  setIndexCalculationRoute(state, fullPath){
    state.indexCalculationRoute = fullPath
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};