<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="bg-white shadow-lg rounded-lg" @submit.prevent="handleSubmit(update)">    
        <div class="p-10">
          <div class="pb-2 mb-4 border-b border-gray-200">
            <h2 class="text-gray-800 text-2xl font-medium">Edit Transfer Stock</h2>
          </div>
          <div class="w-full md:flex gap-10 mb-4">
            <div class="md:flex-col w-full md:w-2/5">
              <div class="md:flex gap-4">
                <div class="flex md:w-1/3">
                  <div class="w-full md:mb-2">
                    <label for="record_no" class="label-control md:py-3">Transfer No <span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="flex md:w-2/3">
                  <div class="w-full mb-2">
                    <ValidationProvider name="PO Number" rules="required" v-slot="{ errors }">
                      <div class="relative flex-1">
                        <span class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm badge" :class="status === 1 ? 'badge--light-primary' : (status === 2 ? 'badge--success' : 'badge--danger')">{{ statusText }}</span>
                        <input id="record_no" type="text" v-model="recordNumber" class="form-control" readonly>
                        <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                    <div v-if="error.record_no" class="text-sm text-red-600"><span>{{ error.record_no[0] }}</span></div>
                  </div>
                </div>
              </div>
              <div class="md:flex gap-4">
                <div class="flex md:w-1/3">
                  <div class="w-full md:mb-2">
                    <label for="transfer_date" class="label-control md:py-3">Transfer Date <span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="flex md:w-2/3">
                  <div class="w-full mb-2">
                    <ValidationProvider name="Transfer Date" rules="required" v-slot="{ errors }">
                      <v-date-picker v-model="transferDate" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs">
                        <template v-slot="{ inputValue, inputEvents }">
                          <div class="md:flex gap-4">
                            <div class="w-full">
                              <div class="relative flex justify-between items-center">
                                <input id="transfer_date" type="text" class="form-control" :value="inputValue" v-on="inputEvents">
                                <span class="h-full absolute pointer-events-none right-0">
                                  <DateRange class="m-3" />
                                </span>                      
                              </div>
                              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                            </div>
                          </div>
                        </template>
                      </v-date-picker>
                    </ValidationProvider>
                    <div v-if="error.transfer_date" class="text-sm text-red-600"><span>{{ error.transfer_date[0] }}</span></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="md:flex-col w-full md:w-3/5">
              <div class="md:flex gap-4">
                <div class="flex md:w-1/3">
                  <div class="w-full md:mb-2">
                    <label for="transfer_from" class="label-control md:py-3">Transfer From <span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="flex md:w-2/3">
                  <div class="w-full mb-2">
                    <ValidationProvider name="Transfer From" rules="" v-slot="{ errors }">
                      <MultiSelect id="transfer_from" v-model="transferFrom" :options="locations" :value="transferFrom" :searchable="true" track-by="id" label="name" placeholder="Type to Search" :showLabels="false" :disabled="true">
                        <template slot="caret">
                          <div>
                            <div class="multiselect__select">
                              <span>
                                <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                                  <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </template>
                      </MultiSelect>
                      <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <div v-if="error.transfer_from" class="text-sm text-red-600"><span>{{ error.transfer_from[0] }}</span></div>
                  </div>
                </div>
              </div>
              <div class="md:flex gap-4">
                <div class="flex md:w-1/3">
                  <div class="w-full md:mb-2">
                    <label for="transfer_to" class="label-control md:py-3">Transfer To <span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="flex md:w-2/3">
                  <div class="w-full mb-2">
                    <ValidationProvider name="Transfer From" rules="" v-slot="{ errors }">
                      <MultiSelect id="transfer_to" v-model="transferTo" :options="locations" :value="transferTo" :searchable="true" track-by="id" label="name" placeholder="Type to Search" :showLabels="false" :disabled="true">
                        <template slot="caret">
                          <div>
                            <div class="multiselect__select">
                              <span>
                                <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                                  <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </template>
                      </MultiSelect>
                      <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <div v-if="error.transfer_to" class="text-sm text-red-600"><span>{{ error.transfer_to[0] }}</span></div>
                  </div>
                </div>
              </div>                        
            </div>
          </div>
          <!-- CALL DETAIL TRANSFER -->
          <TransactionDetail v-if="status === 1" show="true" :parentLoading="isLoading" :source="transferFrom.id" class="mb-10" />
          <TransactionDetail v-else class="mb-10" />

          <!-- SUMMARY DATA SECTION -->
          <div class="md:flex gap-6">
            <div class="md:w-1/2 mb-4">
              <ValidationProvider name="Remarks" rules="" v-slot="{ errors }">
                <label for="remarks" class="label-control">Remarks</label>
                <textarea id="remarks" v-model="remarks" class="form-control" rows="3"></textarea>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.remarks" class="text-sm text-red-600"><span>{{ error.remarks[0] }}</span></div>  
            </div>
            <div class="md:w-1/2 mb-4">
            </div>
          </div>
          <div class="flex gap-4 md:justify-between items-center mt-10 mb-2">
            <div class="flex items-center gap-4">
              <button v-if="status === 1" :disabled="isLoading" type="submit" class="btn btn--primary" alt="Save" title="Save">
                <span v-if="isLoading" class="block mx-5">
                  <Loading />
                </span>
                <SaveIcon v-else>
                  <span class="ml-2 md:block hidden">Update</span>
                </SaveIcon>
              </button>
              <button :disabled="isLoading" type="button" @click="goBack()" class="btn btn--secondary" alt="Back" title="Back">
                <BackIcon>
                  <span class="ml-2 md:block hidden">Back</span>
                </BackIcon>
              </button>
            </div>
            <div class="flex items-center gap-2">
              <button v-if="status === 1" :disabled="isLoading" type="button" @click="cancelDialog" class="btn btn--danger" alt="Cancel Adjustment" title="Cancel Adjustment">
                <OffIcon>
                  <span class="ml-2 md:block hidden">Cancel</span>
                </OffIcon>           
              </button>              
              <button v-if="status === 1" :disabled="isLoading" type="button" @click="validateDialog" class="btn btn--success" alt="Validate" title="Validate">
                <LockIcon>
                  <span class="ml-2 md:block hidden">Validate</span>
                </LockIcon>              
              </button>
              <button v-if="status === 2" :disabled="isLoading" type="button" @click="cancelValidateDialog" class="btn btn--danger" alt="Cancel Validation" title="Cancel Validation">
                <LockIcon>
                  <span class="ml-2 md:block hidden">Invalidate</span>
                </LockIcon>              
              </button>              
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>  
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import format from '@/helpers/formatNumber'
import factoryServices from '@/services/factory/factoryServices'
import transferServices from '@/services/transfer/transferServices'
import SaveIcon from '../../icons/SaveIcon'
import BackIcon from '../../icons/BackIcon'
import LockIcon from '../../icons/LockIcon'
import OffIcon from '../../icons/OffIcon'
import Loading from '../../icons/Loading'
import DateRange from '../../icons/DateRange'
import TransactionDetail from './Detail'

export default {
  name: 'EditTransferStock',
  components: {
    SaveIcon,
    BackIcon,
    LockIcon,
    OffIcon,
    Loading,
    DateRange,
    TransactionDetail
  },  
  data(){
    return {
      recordNumber: '',
      transferFrom: '',
      transferTo: '',
      locations: [],   
      transferDate: '',
      masks: {
        input: 'YYYY-MM-DD',
      },
      attrs: [
        {
          key: 'today',
          highlight: 'red',
          dates: new Date(),
        },
      ],      
      remarks: '',
      status: '',
      statusText: '',
      record: [],
      details: [],
      error: [],
      isLoading: false,
      activeTab: '1',
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async update(){
      try {
        this.isLoading = true

        const id = this.$route.params.id
        const payload = {
          transfer_from: this.transferFrom.id,
          transfer_to: this.transferTo.id,
          transfer_date: this.transferDate,
          remarks: this.remarks
        }
        const response = await transferServices.update(id, payload)
        if (response.data.status === 'success') {
          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          /* CHECK IF NEW ID IS EQUAL WITH CURRENT ID, THEN REDIRECT TO 2 STEP PREV PAGE */
          // console.log(this.newID, id)
          if (this.newID === id && this.indexRoute) {
            this.$router.push(this.indexRoute.fullPath)
            this.$store.dispatch('setNewAdjustmentID', null) 
            this.$store.dispatch('setIndexAdjustmentRoute', null)
          } else {
            /* RETURN TO LIST */
            window.history.length > 1 ? this.$router.back() : this.$router.push('/transfer-stock')
          }
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.transfer_from || responseReturn.transfer_to || responseReturn.transfer_date || responseReturn.remarks) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },
    async fetchData(){
      try {
        const id = this.$route.params.id
        const response = await transferServices.fetchById(id)
        if (response.data.status === 'success') {
          this.record = response.data.data

          this.recordNumber = this.record.record_no
          this.transferFrom = this.record.transfer_from_id ? { 'id': this.record.transfer_from_id, 'name': this.record.transfer_from.factory_name, 'code': this.record.transfer_from.factory_code} : null
          this.transferTo = this.record.transfer_to_id ? { 'id': this.record.transfer_to_id, 'name': this.record.transfer_to.factory_name, 'code': this.record.transfer_to.factory_code} : null
          this.transferDate = this.record.transfer_date
          this.remarks = this.record.remarks
          this.status = this.record.status
          this.statusText = this.record.status_text
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)            
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchFactory() {
      try {
        const response = await factoryServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.locations = []
          records.forEach(element => {
            this.locations.push({
              'id': element.id,
              'name': element.factory_name,
              'code': element.factory_code
            })
          })
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }        
      } catch (error) {
        console.log(error)
      }
    },
    async cancel(id) {
      try {
        const payload = {
          transfer_from: this.record.transfer_from_id,
          transfer_to: this.record.transfer_to_id,
          transfer_date: this.record.transfer_date,
          remarks: this.record.remarks,
          status: 3
        }
        const response = await transferServices.update(id, payload)
        return response.data
      } catch (error) {     
        return error
      }      
    },
    async cancelDialog() {
      const id = this.$route.params.id

      this.$swal.fire({
        title: 'Do you want to continue ?',
        text: "Cancelation transaction will change the transaction status and lock the transaction from modify and delete. You won't be able to revert this!",
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#4b5563',
        confirmButtonText: 'Yes'
      }).then(async (result) => {
        if (result.isConfirmed) {
          /* SET IS LOADING STATE TRUE */
          this.isLoading = true

          /* CALL VALIDATE FUNCTION */
          const result = this.cancel(id)
          const response = await result

          if (response.status) {
            if (response.status === 'success') {
              /* SET IS LOADING STATE FALSE */
              this.isLoading = false

              /* REFRESH DATA */
              this.fetchData()

              this.$swal.fire({
                title: 'Validated!',
                text: "Your transaction has been updated",
                icon: 'success',
                showConfirmButton: false,
                timer: 3000            
              })              
            } else {
              /* SET IS LOADING STATE FALSE */
              this.isLoading = false
                            
              this.$swal.fire({
                title: 'Error!',
                text: response.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 3000            
              })              
            }
          } else {
            /* SET IS LOADING STATE FALSE */
            this.isLoading = false
                        
            this.$toasted.error(response.message, this.toastedOptions)           
          }
        }
      })      
    },
    async validate(id) {
      try {
        const response = await transferServices.validate(id)
        return response.data
      } catch (error) {     
        return error
      }      
    },    
    async validateDialog() {
      const id = this.$route.params.id

      this.$swal.fire({
        title: 'Do you want to continue ?',
        text: "Validate transaction will lock the transaction from modify and delete. You won't be able to revert this!",
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#4b5563',
        confirmButtonText: 'Yes'
      }).then(async (result) => {
        if (result.isConfirmed) {
          /* SET IS LOADING STATE TRUE */
          this.isLoading = true

          /* CALL VALIDATE FUNCTION */
          const result = this.validate(id)
          const response = await result

          if (response.status) {
            if (response.status === 'success') {
              /* SET IS LOADING STATE FALSE */
              this.isLoading = false

              /* REFRESH DATA */
              this.fetchData()

              this.$swal.fire({
                title: 'Validated!',
                text: "Your transaction has been validated",
                icon: 'success',
                showConfirmButton: false,
                timer: 3000            
              })              
            } else {
              /* SET IS LOADING STATE FALSE */
              this.isLoading = false
                            
              this.$swal.fire({
                title: 'Error!',
                text: response.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 3000            
              })              
            }
          } else {
            /* SET IS LOADING STATE FALSE */
            this.isLoading = false
                        
            this.$toasted.error(response.message, this.toastedOptions)           
          }
        }
      })      
    },
    async cancelValidation(id) {
      try {
        const response = await transferServices.cancelValidation(id)
        return response.data
      } catch (error) {     
        return error
      }      
    },    
    async cancelValidateDialog() {
      const id = this.$route.params.id

      this.$swal.fire({
        title: 'Do you want to continue ?',
        text: "Cancelation validation will unlock the transaction from modify and delete!",
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#4b5563',
        confirmButtonText: 'Yes'
      }).then(async (result) => {
        if (result.isConfirmed) {
          /* SET IS LOADING STATE TRUE */
          this.isLoading = true

          /* CALL VALIDATE FUNCTION */
          const result = this.cancelValidation(id)
          const response = await result

          if (response.status) {
            if (response.status === 'success') {
              /* SET IS LOADING STATE FALSE */
              this.isLoading = false

              /* REFRESH DATA */
              this.fetchData()

              this.$swal.fire({
                title: 'Canceled!',
                text: "Your transaction has been unlocked",
                icon: 'success',
                showConfirmButton: false,
                timer: 3000            
              })              
            } else {
              /* SET IS LOADING STATE FALSE */
              this.isLoading = false
                            
              this.$swal.fire({
                title: 'Error!',
                text: response.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 3000            
              })              
            }
          } else {
            /* SET IS LOADING STATE FALSE */
            this.isLoading = false
                        
            this.$toasted.error(response.message, this.toastedOptions)           
          }
        }
      })      
    },      
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    },
    goBack() {
      if (this.newID === this.$route.params.id && this.indexRoute) {
        this.$router.push(this.indexRoute.fullPath)
        this.$store.dispatch('setNewTransferID', null) 
        this.$store.dispatch('setIndexTransferRoute', null)
      } else {
        window.history.length > 1 ? this.$router.back() : this.$router.push('/transfer-stock')
      }
    }, 
  },
  computed: {
    ...mapGetters({
      newID: 'newTransferID',
      indexRoute: 'indexTransferRoute'
    })
  },
  mounted(){
    this.fetchFactory()
    this.fetchData()
  },
}
</script>