<template>
  <div class="flex flex-col md:flex-row min-h-screen items-center bg-gray-200">
    <div class="bg-white w-full md:max-w-md lg:max-w-full my-auto md:mx-auto md:w-1/2 xl:w-1/3 h-auto px-6 lg:px-16 xl:px-12
          flex items-center justify-center rounded shadow-md lg:mt-8 lg:mb-8 xl:mt-8 xl:mb-8">
      <div class="w-full py-6">
        <span>Reset Password</span>
        <h1 class="text-xl md:text-2xl font-bold leading-tight">Reset your account password</h1>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form class="mt-6" @submit.prevent="handleSubmit(resetPassword)">
            <div class="mt-4">
              <ValidationProvider name="Password" rules="required|min:6|confirmed:confirmation" v-slot="{ errors }">
                <label for="password" class="block text-gray-700">Password</label>
                <input type="password" id="password" v-model="password" placeholder="Enter Password" class="w-full px-4 py-3 rounded bg-gray-200 mt-2 border focus:border-blue-500
                      focus:bg-white focus:outline-none">
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
              </ValidationProvider>
              <div v-if="error.password" class="text-sm text-red-600"><span>{{ error.password[0] }}</span></div>               
            </div>
            <div class="mt-4">
              <ValidationProvider name="Repeat Password" rules="required|min:6" vid="confirmation" v-slot="{ errors }">
                <label for="password_confirmation" class="block text-gray-700">Repeat Password</label>
                <input type="password" id="password_confirmation" v-model="password_confirmation" placeholder="Repeat Password" class="w-full px-4 py-3 rounded bg-gray-200 mt-2 border focus:border-blue-500
                      focus:bg-white focus:outline-none">
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
              </ValidationProvider>
              <div v-if="error.password_confirmation" class="text-sm text-red-600"><span>{{ error.password_confirmation[0] }}</span></div>              
            </div>          
            <button type="submit" :disabled="isLoading" class="w-full block bg-indigo-600 hover:bg-indigo-700 focus:bg-indigo-700 text-white font-semibold rounded
                  px-4 py-3 mt-6 disabled:opacity-50">
              Submit
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>   
  </div>
</template>

<script>
import authServices from '@/services/user/authServices'

export default {
  name: 'Register',
  data(){
    return {
      token: '',
      password: '',
      password_confirmation: '',
      error: [],
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000        
      }      
    }
  },
  methods: {
    async resetPassword(){
      try {
        /* SET LOADING STATE IS TRUE */
        this.isLoading = true

        const payload = {
          token: this.$route.query.token,
          password: this.password,
          password_confirmation: this.password_confirmation
        }
        const response = await authServices.resetPasswordByUser(payload)
        if (response.data.status === 'success') {
          /* CLEAR INPUT FORM */
          this.clearForm()
          
          /* EMPTY ERRORS VARIABLE */
          this.error = []

          this.$toasted.success(response.data.message, this.toastedOptions)

          /* REDIRECT TO LOGIN PAGE */
          this.$router.push('/auth/login')       
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT USERNAME OR PASSWORD, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.password || responseReturn.password_confirmation) {
            this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }
        }        
      } catch (error) {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false
                  
          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          this.error = error.response.data.message  
          
          /* ELSE, THROW ERROR MESSAGES */
          this.$toasted.error(this.error, this.toastedOptions)
      }     
    },
    clearForm(){
      this.password = '',
      this.password_confirmation = ''
    }
  } 
}
</script>