<template>
  <div>
    <button type="button" class="btn btn--success ml-2" @click="toggleModal">
      <UploadIcon>
        <span class="ml-2 md:block hidden">Search PO</span>
      </UploadIcon>
    </button>
    <!-- Import Modal Dialog -->
    <modal :show="showModal" @close="showModal = false" addClass="modal-lg" modalOrientation="py-16 md:py-10">
      <template v-slot:header><h3>{{ modalTitle }}</h3></template>
      <template v-slot:body>
        <div class="md:flex gap-2">
          <div class="md:w-2/12 mb-2">
            <ValidationProvider name="Start Date" rules="" v-slot="{ errors }">
              <v-date-picker v-model="filterStartDate" mode="date" :masks="masks" color="purple" title-position="left" :attributes="attrs" @input="updateEndDate()">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="md:flex gap-6">
                    <div class="w-full">
                      <div class="relative flex justify-between items-center">
                        <input id="receive_date" type="text" class="form-control" :value="inputValue" v-on="inputEvents">
                        <span class="h-full absolute pointer-events-none right-0">
                          <DateRange class="m-3" />
                        </span>                      
                      </div>
                    </div>
                  </div>
                </template>
              </v-date-picker>
              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="md:w-2/12 mb-2">
            <ValidationProvider name="End Date" rules="" v-slot="{ errors }">
              <v-date-picker v-model="filterEndDate" mode="date" :masks="masks" color="purple" title-position="left" :attributes="attrs" @input="updateStartDate()">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="md:flex gap-6">
                    <div class="w-full">
                      <div class="relative flex justify-between items-center">
                        <input id="receive_date" type="text" class="form-control" :value="inputValue" v-on="inputEvents">
                        <span class="h-full absolute pointer-events-none right-0">
                          <DateRange class="m-3" />
                        </span>                      
                      </div>
                    </div>
                  </div>
                </template>
              </v-date-picker>
              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="md:w-4/12 mb-2">
            <ValidationProvider name="Supplier" rules="" v-slot="{ errors }">
              <MultiSelect id="supplier" v-model="supplier" :options="suppliers" :value="supplier" :searchable="true" track-by="id" label="name" :custom-label="nameWithAddress" placeholder="Choose Supplier" :showLabels="false">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>
              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="md:w-2/12 mb-2">
            <ValidationProvider name="PO Number" rules="" v-slot="{ errors }">
              <input id="po_number" type="text" v-model="poNumber" class="form-control" placeholder="PO Number">
              <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
            </ValidationProvider>
          </div>                              
          <div class="md:w-1/12 mb-2">
            <button :disabled="isLoading" @click="fetchPurchaseData()" type="button" class="btn-md btn--primary">
              <span v-if="isLoading">Wait...</span>
              <span v-if="!isLoading">Search</span>
            </button> 
          </div>
        </div>
        <div class="mb-4 bg-white shadow-lg rounded-lg overflow-y-auto">
          <table>
            <thead>
              <tr>
                <th scope="col" class="text-left">PO Number</th>
                <th scope="col" class="text-left">PO Date</th>
                <th scope="col" class="text-left">Wish Delivery</th>
                <th scope="col" class="text-left">Supplier</th>
                <th scope="col" class="text-left">Amount</th>
                <th scope="col" class="text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="datas.length === 0" class="border-b border-gray-200"><td class="text-center" colspan="6">No data to display</td></tr>           
              <tr v-for="(data, key) in datas" :key="key" :class="{ 'bg-gray-50' : (key % 2 !== 0) }">
                <td class="text-left">{{ data.record_no }}</td>
                <td class="text-left">{{ data.purchase_date }}</td>
                <td class="text-left">{{ data.wish_delivery_date }}</td>
                <td class="text-left">{{ data.supplier_name }}</td>
                <td class="text-right">{{ data.grand_total }} €</td>
                <td class="text-left">
                  <button :disabled="isLoading" type="button" @click="createReceiving(data.id)" class="btn btn-sm btn--success" alt="Create Receiving" v-tooltip="'Create Receiving'">
                    Receive
                  </button>                  
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template v-slot:footer>     
      </template> 
    </modal>
  </div>
</template>

<script>
import supplierServices from '@/services/supplier/supplierServices'
import purchaseServices from '@/services/purchase/purchaseServices'
import receiveServices from '@/services/receive/receiveServices'
import Modal from '../../widgets/Modal'
import UploadIcon from '../../icons/UploadIcon'
import DateRange from '../../icons/DateRange'

export default {
  name: 'ImportPO',
  components: {
    Modal,
    UploadIcon,
    DateRange
  },
  data() {
    return {
      filterStartDate: new Date(),
      filterEndDate: new Date(),
      masks: {
        input: 'YYYY-MM-DD',
      },
      attrs: [
        {
          key: 'today',
          highlight: 'red',
          dates: new Date(),
        },
      ],
      supplier: '',
      suppliers: [],
      poNumber: '',
      awaitingSearch: false,
      datas: [],
      error: [],
      isLoading: false,
      isDownloadLoading: false, 
      modalTitle: '',     
      showModal: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }         
    }
  },  
  methods: {
    updateStartDate() {
      if (new Date(this.filterEndDate) < new Date(this.filterStartDate)) {
        this.filterStartDate = this.filterEndDate
      }
    },    
    updateEndDate() {
      if (new Date(this.filterEndDate) < new Date(this.filterStartDate)) {
        this.filterEndDate = this.filterStartDate
      }
    },
    async fetchSupplierData() {
      try {
        const response = await supplierServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.suppliers = []
          records.forEach(element => {
            this.suppliers.push({
              'id': element.id,
              'name': element.supplier_name,
              'address': element.address
            })
          })
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    nameWithAddress ({ name, address }) {
      if (address) {
        return `${name} [ ${address} ]`
      }
      return `${name}`
    },    
    toggleModal() {
      this.showModal = true
      this.modalTitle = 'Purchase Order List'
    },
    async fetchPurchaseData() {
      try {
        this.isLoading = true

        let params = [];
        if (this.supplier) {
          params = {
            search: this.poNumber,
            start: this.filterStartDate || '',
            end: this.filterEndDate || '',
            filter: [
              ['supplier_id', this.supplier ? this.supplier.id : '']
            ]
          }
        } else {
          params = {
            search: this.poNumber,
            start: this.filterStartDate || '',
            end: this.filterEndDate || ''
          }          
        }
        const response = await purchaseServices.fetchAvailablePO(params)
        if (response.data.status === 'success') {
          this.isLoading =false

          this.datas = response.data.data
        } else {
          this.isLoading =false

          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        this.isLoading =false
        console.log(error)
      }
    },
    async createReceiving(id) {
      try {
        this.isLoading = true

        const response = await receiveServices.import(id)
        if (response.data.status === 'success') {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          /* SET NEW ID & REDIRECT TO EDIT PAGE WITH CREATED ID */
          this.$store.dispatch('setNewReceiveID', response.data.data.id)
          this.$router.push({ name: 'receiveEdit', params: { id: response.data.data.id} })
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          this.$toasted.error(response.data.message, this.toastedOptions)         
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }      
    }
  },
  mounted() {
    this.fetchSupplierData()
    this.fetchPurchaseData()
  }  
}
</script>