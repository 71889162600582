<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="bg-white shadow-lg rounded-lg" @submit.prevent="handleSubmit(create)">
        <div class="p-10">
          <div class="pb-2 mb-4 border-b border-gray-200">
            <h2 class="text-gray-800 text-2xl font-medium">Create Reason</h2>
          </div>
          <div class="md:flex gap-6">
            <div class="w-full mb-4">
              <ValidationProvider name="Description" rules="required" v-slot="{ errors }">
                <label for="description" class="label-control">Description <span class="text-red-600">*</span></label>
                <input id="description" ref="description" type="text" v-model="description" maxlength="255" class="form-control" autofocus>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.description" class="text-sm text-red-600"><span>{{ error.description[0] }}</span></div>              
            </div>
          </div>
          <div class="w-full md:flex gap-6">
            <div class="w-full md:w-1/2 mb-4">
              <ValidationProvider name="Disposition" rules="required" v-slot="{ errors }">
                <label for="disposition" class="label-control">Disposition <span class="text-red-600">*</span></label>
                <MultiSelect id="disposition" v-model="disposition" :options="dispositions" :value="disposition" :searchable="true" track-by="value" label="name" placeholder="Type to Search">
                  <template slot="caret">
                    <div>
                      <div class="multiselect__select">
                        <span>
                          <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                            <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </template>
                </MultiSelect>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
              </ValidationProvider>
              <div v-if="error.disposition" class="text-sm text-red-600"><span>{{ error.disposition[0] }}</span></div>  
            </div>
            <div class="w-full md:w-1/2 mb-4"></div>
          </div>              
          <div class="md:flex gap-4 mt-4 mb-2">
            <button :disabled="isLoading" type="submit" class="btn btn--primary" alt="Save" title="Save">
              <span v-if="isLoading" class="block mx-5">
                <Loading />
              </span>
              <SaveIcon v-else>
                <span class="ml-2 md:block hidden">Save</span>
              </SaveIcon>
            </button>
            <button :disabled="isLoading" type="button" @click="goBack()" class="btn btn--secondary" alt="Back" title="Back">
              <BackIcon>
                <span class="ml-2 md:block hidden">Back</span>
              </BackIcon>              
            </button>
          </div>                                                  
        </div>
      </form>      
    </ValidationObserver>
  </div>
</template>

<script>
import adjustmentReasonServices from '@/services/adjustment/adjustmentReasonServices'
import SaveIcon from '../../icons/SaveIcon'
import BackIcon from '../../icons/BackIcon'
import Loading from '../../icons/Loading'

export default {
  name: 'CreateReason',
  components: {
    SaveIcon,
    BackIcon,
    Loading
  },
  data(){
    return {
      description: '',
      disposition: '',
      dispositions: [
        {
          "name": "+ STOCK ON HAND",
          "value": "+"
        },
        {
          "name": "- STOCK ON HAND",
          "value": "-"
        },
      ],
      error: [],
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async create(){
      try {
        this.isLoading = true       
        const payload = {
          description: this.description,
          disposition: this.disposition.value
        }
        const response = await adjustmentReasonServices.create(payload)
        if (response.data.status === 'success') {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false
                    
          /* CLEAR INPUT FORM */
          this.clearForm()

          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          /* SET FOCUS ON DESCRIPTION NAME INPUT */
          this.$refs.description.focus()
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.description || responseReturn.disposition) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },
    clearForm(){
      this.description = ''
      this.disposition = ''
    },
    goBack() {
      window.history.length > 1 ? this.$router.back() : this.$router.push('/reasons')
    }       
  }
}
</script>