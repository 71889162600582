<template>
  <div class="bg-white shadow-lg rounded-lg">
    <div class="p-10">
      <div class="pb-2 mb-4 border-b border-gray-200">
        <h2 class="text-gray-800 text-2xl font-medium">Show Receiving</h2>
      </div>
      <div class="md:flex gap-10">
        <div class="md:flex-col w-full md:w-2/5">
          <div class="md:flex gap-4">
            <div class="flex md:w-1/3">
              <div class="w-full md:mb-2">
                <label for="record_no" class="label-control md:py-3">Record Number <span class="text-red-600">*</span></label>
              </div>
            </div>
            <div class="flex md:w-2/3">
              <div class="w-full mb-2">
                <div class="relative flex-1">
                  <span class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm badge" :class="status === 1 ? 'badge--light-primary' : (status === 2 ? 'badge--success' : 'badge--danger')">{{ statusText }}</span>
                  <input id="record_no" type="text" v-model="recordNumber" class="form-control" readonly>
                </div>
              </div>
            </div>
          </div>
          <div class="md:flex gap-4">
            <div class="flex md:w-1/3">
              <div class="w-full md:mb-2">
                <label for="location" class="label-control md:py-3">Receive Location <span class="text-red-600">*</span></label>
              </div>
            </div>
            <div class="flex md:w-2/3">
              <div class="w-full mb-2">
                <MultiSelect id="location" v-model="receiveLocation" :options="receiveLocations" :value="receiveLocation" :searchable="true" track-by="id" label="name" placeholder="Type to Search" :showLabels="false" :disabled="true">
                  <template slot="caret">
                    <div>
                      <div class="multiselect__select">
                        <span>
                          <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                            <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </template>
                </MultiSelect>
              </div>
            </div>
          </div>          
          <div class="md:flex gap-4">
            <div class="flex md:w-1/3">
              <div class="w-full md:mb-2">
                <label for="po_number" class="label-control md:py-3">PO Number <span class="text-red-600">*</span></label>
              </div>
            </div>
            <div class="flex md:w-2/3">
              <div class="w-full mb-2">
                <input id="po_number" type="text" v-model="reffNumber" class="form-control" readonly>
              </div>
            </div>
          </div>
          <div class="md:flex gap-4">
            <div class="flex md:w-1/3">
              <div class="w-full md:mb-2">
                <label for="receive_date" class="label-control md:py-3">Received Date <span class="text-red-600">*</span></label>
              </div>
            </div>
            <div class="flex md:w-2/3">
              <div class="w-full mb-2">
                <v-date-picker v-model="receiveDate" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs">
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="md:flex gap-4">
                      <div class="w-full">
                        <div class="relative flex justify-between items-center">
                          <input id="receive_date" type="text" class="form-control" :value="inputValue" v-on="inputEvents">
                          <span class="h-full absolute pointer-events-none right-0">
                            <DateRange class="m-3" />
                          </span>                      
                        </div>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="md:flex-col w-full md:w-3/5">
          <div class="md:flex gap-4">
            <div class="flex md:w-1/3">
              <div class="w-full md:mb-2">
                <label for="supplier" class="label-control md:py-3">Supplier <span class="text-red-600">*</span></label>
              </div>
            </div>
            <div class="flex md:w-2/3">
              <div class="w-full mb-2">
                <MultiSelect id="supplier" v-model="supplier" :options="suppliers" :value="supplier" :searchable="true" track-by="id" label="name" :custom-label="nameWithAddress" placeholder="Type to Search" :disabled="true">
                  <template slot="caret">
                    <div>
                      <div class="multiselect__select">
                        <span>
                          <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                            <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </template>
                </MultiSelect>
              </div>
            </div>
          </div>
          <div class="md:flex gap-4">
            <div class="flex md:w-1/3">
            </div>
            <div class="flex md:w-2/3">
              <div class="w-full mb-2">
                <textarea id="address" v-model="address" class="form-control" rows="3"></textarea>
              </div>
            </div>
          </div>
          <div class="md:flex gap-4">
            <div class="flex md:w-1/3">
              <div class="w-full md:mb-2">
                <label for="season" class="label-control md:py-3">Season / Period <span class="text-red-600">*</span></label>
              </div>
            </div>
            <div class="flex md:w-2/3">
              <div class="flex w-full gap-2">
                <div class="w-1/2 mb-2">
                  <MultiSelect id="season" v-model="season" :options="seasons" :value="season" track-by="id" label="name" :showLabels="false">
                    <template slot="caret">
                      <div>
                        <div class="multiselect__select">
                          <span>
                            <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                              <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </template>
                  </MultiSelect>
                </div>
                <div class="w-1/2 mb-2">
                  <MultiSelect id="period" v-model="period" :options="periods" :value="period" track-by="id" label="name" :showLabels="false">
                    <template slot="caret">
                      <div>
                        <div class="multiselect__select">
                          <span>
                            <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                              <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </template>
                  </MultiSelect>
                </div>
              </div>
            </div>
          </div>
        </div>           
      </div>
      <!-- CALL PRODUCT RECIPE -->
      <TransactionDetail class="mb-10" />

      <!-- SUMMARY DATA SECTION -->
      <div class="md:flex gap-6">
        <div class="md:w-1/2 mb-4">
          <ValidationProvider name="Remarks" rules="" v-slot="{ errors }">
            <label for="remarks" class="label-control">Remarks</label>
            <textarea id="remarks" v-model="remarks" class="form-control" rows="3"></textarea>
            <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
          </ValidationProvider>
          <div v-if="error.remarks" class="text-sm text-red-600"><span>{{ error.remarks[0] }}</span></div>  
        </div>
        <div class="md:w-1/2 mb-4">
        </div>
      </div>
      <div class="md:flex gap-4 mt-10 mb-2">
        <button :disabled="isLoading" type="button" @click="goBack()" class="btn btn--secondary" alt="Back" title="Back">
          <BackIcon>
            <span class="ml-2 md:block hidden">Back</span>
          </BackIcon>              
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import format from '@/helpers/formatNumber'
import factoryServices from '@/services/factory/factoryServices'
import receiveServices from '@/services/receive/receiveServices'
import supplierServices from '@/services/supplier/supplierServices'
import seasonServices from '@/services/season/seasonServices'
import BackIcon from '../../icons/BackIcon'
import DateRange from '../../icons/DateRange'
import TransactionDetail from './Detail'

export default {
  name: 'ShowReceive',
  components: {
    BackIcon,
    DateRange,
    TransactionDetail
  },  
  data(){
    return {
      recordNumber: '',
      reffNumber: '',
      receiveDate: '',
      masks: {
        input: 'YYYY-MM-DD',
      },
      attrs: [
        {
          key: 'today',
          highlight: 'red',
          dates: new Date(),
        },
      ],      
      remarks: '',
      status: '',
      statusText: '',
      supplier: '',
      address: '',
      suppliers: [],
      receiveLocation: '',
      receiveLocations: [],
      season: '',
      seasons: [],
      period: '',
      periods: [
        { 'id': 1, 'name': 'Period 1' }, { 'id': 2, 'name': 'Period 2' }, { 'id': 3, 'name': 'Period 3' },
        { 'id': 4, 'name': 'Period 4' }, { 'id': 5, 'name': 'Period 5' }, { 'id': 6, 'name': 'Period 6' }
      ],      
      record: [],
      details: [],
      error: [],
      isLoading: false,
      activeTab: '1',
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async fetchData(){
      try {
        const id = this.$route.params.id
        const response = await receiveServices.fetchById(id)
        if (response.data.status === 'success') {
          this.record = response.data.data
          const dataPeriod = [ this.record.period ]
          const period = this.periods.filter(item => dataPeriod.includes(item.id))          

          this.supplier = { 'id': this.record.supplier_id, 'name': this.record.supplier.supplier_name, 'address': this.record.supplier.address}
          this.address = this.record.supplier.address
          this.recordNumber = this.record.record_no
          this.reffNumber = this.record.reff_no
          this.season = { id: this.record.season_id, name: this.record.season.season_name }
          this.period = { id: period[0].id, name: period[0].name }          
          this.receiveLocation = { 'id': this.record.factory_id, 'name': this.record.factory.factory_name, 'code': this.record.factory.factory_code}
          this.receiveDate = this.record.receive_date
          this.remarks = this.record.remarks
          this.total_price = this.formatNumber(this.record.grand_total)
          this.status = this.record.status
          this.statusText = this.record.status_text
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)            
        }
      } catch (error) {
        console.log(error)
      }
    },   
    async fetchSupplierData() {
      try {
        const response = await supplierServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          records.forEach(element => {
            this.suppliers.push({
              'id': element.id,
              'name': element.supplier_name,
              'address': element.address
            })
          });          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchSeasonData() {
      try {
        const response = await seasonServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.seasons = []
          records.forEach(element => {
            this.seasons.push({
              'id': element.id,
              'name': element.season_name
            })
          });          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },    
    async fetchFactory() {
      try {
        const response = await factoryServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.receiveLocations = []
          records.forEach(element => {
            this.receiveLocations.push({
              'id': element.id,
              'name': element.factory_name,
              'code': element.factory_code
            })
          })
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }        
      } catch (error) {
        console.log(error)
      }
    },    
    nameWithAddress ({ name, address }) {
      if (address) {
        return `${name} [ ${address} ]`
      }
      return `${name}`
    },    
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    },
    goBack() {
      window.history.length > 1 ? this.$router.back() : this.$router.push('/receives')
    }    
  },
  created(){
    this.fetchSupplierData()
    this.fetchSeasonData()
    this.fetchData()
  }
}
</script>