<template>
  <div>
    <button type="button" class="btn btn--danger ml-2" @click="toggleModal">
      <UploadIcon>
        <span class="ml-2 md:block hidden">Import</span>
      </UploadIcon>
    </button>
    <!-- Import Modal Dialog -->
    <modal :show="showModal" @close="showModal = false" addClass="modal-xl" modalOrientation="py-16 md:py-10">
      <template v-slot:header><h3>{{ modalTitle }}</h3></template>
      <template v-slot:body>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form id="importForm" @submit.prevent="handleSubmit(importProduct)">
            <div class="md:flex gap-2">
              <div class="md:w-2/12 mb-4">
                <ValidationProvider name="Season" rules="required" v-slot="{ errors }">
                  <input id="season" ref="season" type="text" v-model="season" maxlength="255" class="form-control" placeholder="SEASON CREATION">
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
                <div v-if="error.season" class="text-sm text-red-600"><span>{{ error.season[0] }}</span></div>              
              </div>
              <div class="md:w-2/12 mb-4">
                <ValidationProvider name="Collection" rules="required" v-slot="{ errors }">
                  <MultiSelect id="collection" v-model="collection" :options="collections" :value="collection" :searchable="true" track-by="id" label="name" placeholder="Type to Search" :showLabels="false">
                    <template slot="caret">
                      <div>
                        <div class="multiselect__select">
                          <span>
                            <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                              <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </template>
                  </MultiSelect>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
                <div v-if="error.collection" class="text-sm text-red-600"><span>{{ error.collection[0] }}</span></div>              
              </div>              
              <div class="md:w-5/12 mb-4">
                <ValidationProvider name="Select File" rules="required" ref="provider" v-slot="{ validate, errors }">
                  <input id="file" ref="file" type="file" class="form-control" @change="onFileChange" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="error.file" class="text-sm text-red-600"><span>{{ error.file[0] }}</span></div>
              </div>
              <div class="md:w-3/12 mb-4">
                <button :disabled="isLoading" type="submit" form="importForm" class="btn-md btn--primary">
                  <span v-if="isLoading">Loading...</span>
                  <span v-if="!isLoading">Upload File</span>
                </button>
                <button :disabled="isLoading" type="button" @click="downloadFile()" class="btn-md btn--success ml-2">
                  <span v-if="isDownloadLoading">Loading...</span>
                  <span v-if="!isDownloadLoading">Download Draft</span>
                </button> 
              </div>
            </div>
          </form> 
        </ValidationObserver>
        <div v-if="datas.length > 0" class="mb-4 bg-white shadow-lg rounded-lg overflow-y-auto">
          <table>
            <thead>
              <tr>
                <th scope="col" class="text-left">Ref</th>
                <th scope="col" class="text-left">Color</th>
                <th scope="col" class="text-left">Description</th>
                <th scope="col" class="text-center">Cost / Price</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, key) in datas" :key="key" :class="{ 'bg-gray-50' : (key % 2 !== 0) }">
                <td class="text-left">{{ data.reference }}</td>
                <td class="text-left">{{ data.color }}</td>
                <td class="text-left">{{ data.description }}</td>
                <td class="text-right">{{ data.mfg_cost1 }} €</td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template v-slot:footer>     
      </template> 
    </modal>
  </div>
</template>

<script>
import collectionServices from '@/services/collection/collectionServices'
import productServices from '@/services/product/productServices'
import Modal from '../../widgets/Modal'
import UploadIcon from '../../icons/UploadIcon'

export default {
  name: 'ImportProduct',
  components: {
    Modal,
    UploadIcon
  },
  data() {
    return {
      season: '',
      collection: '',
      file: null,
      collections: [],
      datas: [],
      error: [],
      isLoading: false,
      isDownloadLoading: false, 
      modalTitle: '',     
      showModal: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }         
    }
  },
  methods: {   
    async fetchCollectionData() {
      try {
        const response = await collectionServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.collections = []
          records.forEach(element => {
            this.collections.push({
              'id': element.id,
              'name': element.collection_name
            })
          });          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },    
    async importProduct() {
      try {
        this.isLoading = true
        let payload = new FormData();
        payload.append('season', this.season)
        payload.append('collection', this.collection.id)
        payload.append('file', this.file)

        const response = await productServices.import(payload)
        if (response.data.status === 'success') {
          this.isLoading = false

          /* REFRESH PRODUCT LIST */
          this.fetchData()          

          /* THROW SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)           
        } else {
          this.isLoading = false

          /* STORE ERRORS DATA TO DATA VARIABLE */
          this.datas = response.data.data;

          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions) 
      }
    },
    async onFileChange(e) {
      const { valid } = await this.$refs.provider.validate(e)
      if (valid) {
        let files = e.target.files || e.dataTransfer.files
        if (!files.length) {
          return
        }

        this.file = this.$refs.file.files[0]
        this.datas = []
      }
    },
    toggleModal() {
      this.$refs.file.value = null
      this.datas = []
      this.showModal = true
      this.modalTitle = 'Import Products'
    },
    fetchData() {
      this.$root.$emit('fetchData')
    },
    openDownloadTab(key) {
      let baseUrl = window.location.origin
      window.open(baseUrl+'/auth/download?key='+key, '_blank')
    },    
    async downloadFile() {
      try {
        this.isLoading = true

        const response = await productServices.exportDraft()
        if (response.data.status === 'success') {
          this.isLoading = false
          this.$toasted.success(response.data.message, this.toastedOptions)

          const key = response.data.data
          this.openDownloadTab(key)
        } else {
          this.isLoading = false
          this.$toasted.error(response.data.message, this.toastedOptions)
        }
      } catch (error) {
        this.isLoading = false
        console.log(error)
      }
    }         
  },
  mounted() {
    this.fetchCollectionData()
  }
}
</script>