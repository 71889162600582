<template>
  <div>
    <!-- ACTION PANEL SECTION -->
    <div class="flex border-b border-gray-200 text-gray-800 justify-between items-center mb-4 pb-2">
      <div class="md:w-1/2 w-full items-center">
        <h2 class="text-2xl font-medium">Items</h2>
      </div>
      <div class="flex items-center right-0">
        <button v-if="show" :disabled="parentLoading" type="button" class="btn btn--success" id="addItem" @click="toggleNew">
          <PlusIcon>
            <span class="ml-2 md:block hidden">Add Item</span>
          </PlusIcon>
        </button>       
      </div>
    </div>     
    <!-- TABLE SECTION -->
    <div class="mb-4 bg-white shadow-lg rounded-lg overflow-y-auto">
      <table>
        <thead>
          <tr>
            <th scope="col" class="px-3 text-left">Barcode</th>
            <th scope="col" class="px-3 text-left">Ref</th>
            <th scope="col" class="px-3 text-left">Color</th>
            <th scope="col" class="px-3 text-left">Description</th>
            <th scope="col" class="px-3 text-left">Size</th>
            <th scope="col" class="px-3 text-left">Unit</th>
            <th scope="col" class="px-3 text-center">Qty</th>
            <th scope="col" class="px-3 text-center" v-if="show">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="details.length === 0" class="border-b border-gray-200 hover:bg-gray-100"><td class="px-3 text-center" colspan="8">No data to display</td></tr>
          <tr v-for="(detail, index) in details" :key="detail.id" class="border-b border-gray-200 hover:bg-gray-100" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">
            <td class="px-3 text-left">{{ detail.product.barcode }}</td>
            <td class="px-3 text-left">{{ detail.reference }}</td>
            <td class="px-3 text-left">{{ detail.color }}</td>
            <td class="px-3 text-left">{{ detail.description }}</td>
            <td class="px-3 text-left">{{ detail.size }}</td>
            <td class="px-3 text-left">{{ detail.unit }}</td>
            <td class="px-3 text-right">{{ formatNumber(detail.qty) }}</td>
            <td class="px-3 text-center" v-if="show">
              <div class="flex item-center justify-center">
                <button :disabled="parentLoading" @click="toggleEdit( detail.id )" type="button" class="btn-edit" alt="Edit" v-tooltip="'Edit'">
                  <EditIcon />
                </button>        
                <button :disabled="parentLoading" @click="confirmDialog( detail.id )" type="button" class="btn-delete" alt="Delete" v-tooltip="'Delete'">
                  <DeleteIcon />
                </button>
              </div>
            </td>
          </tr>
          <tr class="border-b border-gray-200 bg-gray-50">
            <td class="px-3 text-left" colspan="5"><span class="font-medium">TOTAL</span></td>
            <td class="px-3 text-right" colspan="2"><span class="font-medium">{{ this.totalItems }}</span></td>
            <td class="px-3 text-center" v-if="show"></td>
          </tr>
        </tbody>                              
      </table>
      <div class="flex px-5 py-5 bg-white justify-between items-center"></div>                            
    </div>
    <!-- Modal Dialog -->
    <modal :show="showModal" @close="showModal = false" addClass="modal-lg" modalOrientation="flex items-center justify-center">
      <template v-slot:header><h3>{{ modalTitle }}</h3></template>
      <template v-slot:body>
        <form id="modalForm" @submit.prevent="save">
          <div class="md:flex gap-6">
            <div class="w-full mb-4">
              <MultiSelect id="product" v-model="product" :options="products" :value="product" :searchable="true" @input="toggleFocus" track-by="id" label="description" :custom-label="nameWithColor" placeholder="Type to Search">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>
              <div v-if="error.description" class="text-sm text-red-600"><span>{{ error.description[0] }}</span></div>             
            </div>
          </div>
          <div class="md:flex gap-6">
            <div class="md:flex-col w-full md:w-4/5">
              <div class="md:flex gap-2">
                <div class="md:w-1/2 mb-2">
                  <label for="collection" class="label-control">Collection</label>
                  <input id="collection" ref="collection" type="text" v-model="collection" maxlength="255" class="form-control" readonly>
                  <div v-if="error.collection" class="text-sm text-red-600"><span>{{ error.collection[0] }}</span></div>
                </div>
                <div class="md:w-1/2 mb-2">
                  <label for="barcode" class="label-control">Barcode</label>
                  <input id="barcode" ref="barcode" type="text" v-model="barcode" maxlength="255" class="form-control" readonly>
                  <div v-if="error.barcode" class="text-sm text-red-600"><span>{{ error.barcode[0] }}</span></div>
                </div>
              </div>
              <div class="md:flex gap-2">
                <div class="md:w-1/2 mb-2">
                  <label for="reference" class="label-control">Reference</label>
                  <input id="reference" type="text" v-model="reference" class="form-control" readonly>
                  <div v-if="error.reference" class="text-sm text-red-600"><span>{{ error.reference[0] }}</span></div>
                </div>
                <div class="md:w-1/2 mb-2">
                  <label for="color" class="label-control">Color</label>
                  <input id="color" type="text" v-model="color" class="form-control" readonly>
                  <div v-if="error.color" class="text-sm text-red-600"><span>{{ error.color[0] }}</span></div>
                </div>            
              </div>
              <div class="md:flex gap-2">
                <div class="md:w-1/2 mb-2">
                  <label for="size" class="label-control">Size</label>
                  <input id="size" type="text" v-model="size" class="form-control" readonly>
                  <div v-if="error.size" class="text-sm text-red-600"><span>{{ error.size[0] }}</span></div>
                </div>            
                <div class="md:w-1/2 mb-2">
                  <label for="description" class="label-control">Description</label>
                  <input id="description" type="text" v-model="description" class="form-control" readonly>
                  <div v-if="error.description" class="text-sm text-red-600"><span>{{ error.description[0] }}</span></div>
                </div>
              </div>                    
              <div class="md:flex gap-2">
                <div class="w-full mb-2">
                  <label for="annotation" class="label-control">Annotation</label>
                  <textarea id="annotation" v-model="annotation" class="form-control" rows="2" readonly></textarea>
                  <div v-if="error.annotation" class="text-sm text-red-600"><span>{{ error.annotation[0] }}</span></div>              
                </div>
              </div>
              <div class="md:flex gap-2">
                <div class="md:w-1/2 mb-2">
                  <label for="unit" class="label-control">Unit</label>
                  <input id="unit" type="text" v-model="unit" class="form-control" readonly>
                  <div v-if="error.unit" class="text-sm text-red-600"><span>{{ error.unit[0] }}</span></div>
                </div>
                <div class="md:w-1/2 mb-2">
                  <label for="qty" class="label-control">Qty <span class="text-red-600">*</span></label>
                  <input id="qty" ref="qty" type="text" v-model="qty" @input="handleInputQty" class="form-control">
                  <div v-if="error.qty" class="text-sm text-red-600"><span>{{ error.qty[0] }}</span></div>
                </div>
              </div>
            </div>
            <div class="md:flex-col w-full md:w-1/5">
              <div class="md:flex gap-2">
                <div class="w-full mb-1">
                  <label for="image" class="label-control">Image</label>
                  <div v-if="!image">
                    <div class="md:h-40 border-2 border-dashed border-gray-400 flex items-center justify-center p-2 rounded-lg mb-2">
                      <img class="m-auto" :src="fileBox" />
                    </div>
                  </div>
                  <div v-else>
                    <div class="md:h-auto border-2 border-dashed border-gray-400 flex items-center justify-center p-2 rounded-lg mb-2">
                      <img class="m-auto" :src="image" />
                    </div>
                  </div>
                </div>
              </div>             
            </div>           
          </div>
        </form>
      </template>
      <template v-slot:footer>
        <button :disabled="isLoading" type="submit" form="modalForm" class="btn btn--primary">
          Save
        </button>      
      </template> 
    </modal>    
  </div>  
</template>

<script>
import format from '@/helpers/formatNumber'
import productServices from '@/services/product/productServices'
import orderDetailServices from '@/services/order/orderDetailServices'
import Modal from '../../widgets/Modal'
import PlusIcon from '../../icons/PlusIcon'
import EditIcon from '../../icons/EditIcon'
import DeleteIcon from '../../icons/DeleteIcon'
import imageBox from '../../../assets/images/file-box.png'

export default {
  name: 'OrderDetail',
  components: {
    Modal,
    PlusIcon,
    EditIcon,
    DeleteIcon,
  },
  props: ['show', 'parentLoading'],
  data() {
    return {
      modalTitle: '',     
      showModal: false,
      details: [],
      detailId: '',
      product: '',
      collection: '',
      barcode: '',
      reference: '',
      color: '',
      size: '',
      description: '',
      annotation: '',
      unit: '',
      image: '',
      qty: 0,
      totalItems: 0,
      products: [],
      error: [],
      fileBox: imageBox,
      isEdit: false,
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async fetchDetailData() {
      try {
        this.isLoading = true

        const id = this.$route.params.id
        const response = await orderDetailServices.fetchAll(null, id)
        if (response.data.status === 'success') {
          this.isLoading =false

          this.details = response.data.data

          this.totalItems = 0
          this.details.forEach(element => {
            this.totalItems += parseFloat(element.qty)
          });
          this.totalItems = this.toFixed(this.totalItems, 0)     
        } else {
          this.isLoading =false

          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        this.isLoading =false
        console.log(error)
      }
    },
    async fetchProductData() {
      try {
        const response = await productServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          records.forEach(element => {
            this.products.push({
              'id': element.id,
              'collection': element.collection,
              'barcode': element.barcode,
              'reference': element.reference,
              'color': element.color,
              'description': element.description,
              'annotation': element.annotation,
              'size': element.size,
              'unit': element.unit,
              'image': element.image
            })
          });          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchData(id){
      try {
        const response = await orderDetailServices.fetchById(id)
        if (response.data.status === 'success') {
          this.record = response.data.data

          this.product = {
            'id': this.record.product_id,
            'collection': this.record.collection.collection_name,
            'barcode': this.record.barcode,
            'reference': this.record.reference,
            'color': this.record.color,
            'description': this.record.description,
            'annotation': this.record.annotation,
            'size': this.record.size,
            'unit': this.record.unit,
            'image': this.record.image         
          }
          this.collection = this.record.collection.collection_name
          this.barcode = this.record.barcode
          this.reference = this.record.reference
          this.color = this.record.color
          this.description = this.record.description
          this.size = this.record.size
          this.unit = this.record.unit
          this.image = this.record.image
          this.qty = this.formatNumber(this.record.qty)
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)            
        }
      } catch (error) {
        console.log(error)
      }
    },    
    async save(){
      try {
        this.isLoading = true
        const payload = {
          order_id: this.$route.params.id,
          product: this.product.id,
          barcode: this.barcode,
          reference: this.reference,
          description: this.description,
          annotation: this.annotation,
          color: this.color,
          size: this.size,
          unit: this.unit,
          qty: this.unformatNumber(this.qty)
        }

        let response = ''
        if (this.isEdit) {
          response = await orderDetailServices.update(this.detailId, payload)
        } else {
          response = await orderDetailServices.create(payload)
        }
        
        if (response.data.status === 'success') {
          /* SET IS EDIT STATE TO FALSE */
          this.isEdit = false

          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          if (this.detailId) {
            this.showModal = false
          }

          /* CLEAR INPUT FORM */
          this.clearForm()

          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          /* RELOAD DETAILS DATA */
          this.fetchDetailData()
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.product || responseReturn.barcode || responseReturn.reference || responseReturn.description 
              || responseReturn.annotation || responseReturn.color || responseReturn.size || responseReturn.unit || responseReturn.qty) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },
    async delete(id) {
      try {
        const response = await orderDetailServices.delete(id)
        return response.data
      } catch (error) {     
        return error
      }
    },
    async confirmDialog(id) {
      // console.log(id)
      this.$swal.fire({
        title: 'Are you sure ?',
        text: "You won't be able to revert this!",
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#4b5563',
        confirmButtonText: 'Yes'
      }).then(async (result) => {
        if (result.isConfirmed) {
          /* CALL DELETE FUNCTION */
          const result = this.delete(id)
          const response = await result

          if (response.status) {
            if (response.status === 'success') {
              /* REFRESH DATA */
              this.fetchDetailData()

              this.$swal.fire({
                title: 'Deleted!',
                text: "Your record has been deleted",
                icon: 'success',
                showConfirmButton: false,
                timer: 3000            
              })              
            } else {
              this.$swal.fire({
                title: 'Error!',
                text: response.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 3000            
              })              
            }
          } else {
            this.$toasted.error(response.message, this.toastedOptions)           
          }
        }
      })
    },     
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    },      
    nameWithColor ({ barcode, reference, color, size, description }) {
      if (size) {
        return `${barcode} - ${reference} ${color} [ SIZE : ${size} - ${description} ]`
      }
      return `${barcode} - ${reference} ${color} [ ${description} ]`
    },
    toggleFocus() {
      this.$refs.qty.focus()
    },
    handleInputPrice(event) {
      this.price = this.formatNumber(event.target.value)
    },
    handleInputQty(event) {
      this.qty = this.formatNumber(event.target.value)
    },            
    toggleNew() {
      this.isEdit = false
      this.error = []
      this.showModal = true
      this.modalTitle = 'Add Product'
      this.clearForm()
    },
    toggleEdit(id) {
      this.isEdit = true
      this.error = []
      this.showModal = true
      this.modalTitle = 'Edit Product'
      this.clearForm()
      this.detailId = id
      this.fetchData(id)
    },
    clearForm() {
      this.detailId = ''
      this.product = ''
      this.barcode = ''
      this.reference = ''
      this.color = ''
      this.description = ''
      this.annotation = ''
      this.size = ''
      this.unit = ''
      this.qty = 0
    }
  },
  created() {
    this.fetchDetailData()
    this.fetchProductData()
  },
  watch: {
    product: function() {
      this.collection = this.product.collection
      this.barcode = this.product.barcode
      this.reference = this.product.reference
      this.color = this.product.color
      this.size = this.product.size
      this.description = this.product.description
      this.annotation = this.product.annotation
      this.unit = this.product.unit
      this.image = this.product.image
    } 
  }
}
</script>