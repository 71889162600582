const state = {
  newTransferID: null,
  indexTransferRoute: null
};

const getters = {
  newTransferID: state => state.newTransferID,
  indexTransferRoute: state => state.indexTransferRoute
};

const actions = {
  setNewTransferID({commit}, newTransferID){
    commit('setNewTransferID', newTransferID)
  },
  setIndexTransferRoute({commit}, fullPath){
    commit('setIndexTransferRoute', fullPath)
  }
};

const mutations = {
  setNewTransferID(state, newTransferID){
    state.newTransferID = newTransferID
  },
  setIndexTransferRoute(state, fullPath){
    state.indexTransferRoute = fullPath
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};