import axios from '@/axios'

export default {
  fetchAll(params){
    return axios.get(`/suppliers/all`, {params})
  },
  fetchLimit(params){
    return axios.get(`/suppliers`, {params})
  },  
  fetchById(id){
    return axios.get(`/suppliers/${id}`)
  },    
  create(payload){
    return axios.post(`/suppliers`, payload)
  },
  update(id, payload){
    return axios.put(`/suppliers/${id}`, payload)
  },
  delete(id){
    return axios.delete(`/suppliers/${id}`)
  }, 
  deleteMultiple(payload){
    return axios.delete(`/suppliers`, payload)
  },
  import(payload){
    return axios.post(`/suppliers/import`, payload)
  },
  export(){
    return axios.post(`/suppliers/export`)
  },
  exportDraft(){
    return axios.post(`/suppliers/draft`)
  },
  fetchDataOptions(params){
    return axios.get(`/suppliers/options`, {params})
  }    
}