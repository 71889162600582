<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="bg-white shadow-lg rounded-lg" @submit.prevent="handleSubmit(create)">
        <div class="p-10">
          <div class="pb-2 mb-4 border-b border-gray-200">
            <h2 class="text-gray-800 text-2xl font-medium">Create Supplier</h2>
          </div>
          <div class="md:flex gap-6">
            <div class="w-full mb-4">
              <ValidationProvider name="Supplier Name" rules="required" v-slot="{ errors }">
                <label for="supplier_name" class="label-control">Supplier Name <span class="text-red-600">*</span></label>
                <input id="supplier_name" ref="supplier_name" type="text" v-model="supplierName" maxlength="255" class="form-control" autofocus>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.supplier_name" class="text-sm text-red-600"><span>{{ error.supplier_name[0] }}</span></div>              
            </div>
          </div>          
          <div class="md:flex gap-6">
            <div class="w-full mb-4">
              <ValidationProvider name="Address" rules="" v-slot="{ errors }">
                <label for="address" class="label-control">Address</label>
                <textarea id="address" v-model="address" class="form-control" rows="5"></textarea>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.address" class="text-sm text-red-600"><span>{{ error.address[0] }}</span></div>                   
            </div>
          </div>
          <div class="md:flex gap-6">
            <div class="md:w-1/2 mb-4">
              <ValidationProvider name="Postal Code" rules="numeric" v-slot="{ errors }">
                <label for="postal_code" class="label-control">Postal Code</label>
                <input id="postal_code" type="text" v-model="postalCode" class="form-control">
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.postal_code" class="text-sm text-red-600"><span>{{ error.postal_code[0] }}</span></div>
            </div>
            <div class="md:w-1/2 mb-4">
              <ValidationProvider name="City" rules="alpha_spaces" v-slot="{ errors }">
                <label for="city" class="label-control">City</label>
                <input id="city" type="text" v-model="city" class="form-control">
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.city" class="text-sm text-red-600"><span>{{ error.city[0] }}</span></div>
            </div>
          </div>          
          <div class="md:flex gap-6">
            <div class="md:w-1/2 mb-4">
              <ValidationProvider name="Country" rules="alpha_spaces" v-slot="{ errors }">
                <label for="country" class="label-control">Country</label>
                <input id="country" type="text" v-model="country" class="form-control">
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.country" class="text-sm text-red-600"><span>{{ error.country[0] }}</span></div> 
            </div>
            <div class="md:w-1/2 mb-4"></div>
          </div>          
          <div class="md:flex gap-4 mt-4 mb-2">
            <button :disabled="isLoading" type="submit" class="btn btn--primary" alt="Save" title="Save">
              <span v-if="isLoading" class="block mx-5">
                <Loading />
              </span>
              <SaveIcon v-else>
                <span class="ml-2 md:block hidden">Save</span>
              </SaveIcon>
            </button>
            <button :disabled="isLoading" type="button" @click="goBack()" class="btn btn--secondary" alt="Back" title="Back">
              <BackIcon>
                <span class="ml-2 md:block hidden">Back</span>
              </BackIcon>              
            </button>
          </div>                                                  
        </div>
      </form>      
    </ValidationObserver>
  </div>
</template>

<script>
import supplierServices from '@/services/supplier/supplierServices'
import SaveIcon from '../../icons/SaveIcon'
import BackIcon from '../../icons/BackIcon'
import Loading from '../../icons/Loading'

export default {
  name: 'CreateSupplier',
  components: {
    SaveIcon,
    BackIcon,
    Loading
  },
  data(){
    return {
      supplierName: '',
      address: '',
      postalCode: '',
      city: '',
      country: '',
      error: [],
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async create(){
      try {
        this.isLoading = true       
        const payload = {
          supplier_name: this.supplierName,
          address: this.address,
          postal_code: this.postalCode,
          city: this.city,
          country: this.country
        }
        const response = await supplierServices.create(payload)
        if (response.data.status === 'success') {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false
                    
          /* CLEAR INPUT FORM */
          this.clearForm()

          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          /* SET FOCUS ON SUPPLIER NAME INPUT */
          this.$refs.supplier_name.focus()
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.supplier_name) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },
    clearForm(){
      this.supplierName = ''
      this.address = ''
      this.postalCode = ''
      this.city = ''
      this.country = ''
    },
    goBack() {
      window.history.length > 1 ? this.$router.back() : this.$router.push('/suppliers')
    }        
  }
}
</script>