import MaterialReport from '../components/feature/reports/Material'
import OrderReport from '../components/feature/reports/Order'

const routes = [
  {
    path: '/reports/materials',
    name: 'reportMaterial',
    component: MaterialReport,
    meta: {
      title: 'Material Reports',
      requiresAuth: true
    }    
  },
  {
    path: '/reports/orders',
    name: 'reportOrder',
    component: OrderReport,
    meta: {
      title: 'Order Reports',
      requiresAuth: true
    }    
  }
]

export default routes