<template>
  <div>
    <button type="button" class="w-full btn btn--success" @click="toggleModal" v-tooltip="'Add Material'">
      Add Material    
    </button>    
    <!-- Import Modal Dialog -->
    <modal :show="showModal" @close="showModal = false" addClass="modal-lg" modalOrientation="pt-16 md:pt-5 md:flex md:items-center md:justify-center">
      <template v-slot:header><h3>{{ modalTitle }}</h3></template>
      <template v-slot:body>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form id="materialForm" @submit.prevent="handleSubmit(create)">
            <div class="md:flex gap-2">
              <div class="md:w-1/2 mb-4">
                <ValidationProvider name="Type" rules="required" v-slot="{ errors }">
                  <label for="collection" class="label-control">Type <span class="text-red-600">*</span></label>
                  <MultiSelect id="collection" v-model="collection" :options="collections" :value="collection" :searchable="true" track-by="id" label="code" :multiple="true" placeholder="Type to Search">
                    <template slot="caret">
                      <div>
                        <div class="multiselect__select">
                          <span>
                            <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                              <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </template>
                  </MultiSelect>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="error.type" class="text-sm text-red-600"><span>{{ error.type[0] }}</span></div>
              </div>
              <div class="md:w-1/2 mb-4">
                <ValidationProvider name="Supplier" rules="required" v-slot="{ errors }">
                  <label for="supplier" class="label-control">Supplier <span class="text-red-600">*</span></label>
                  <MultiSelect id="supplier" ref="supplier" v-model="supplier" :options="suppliers" :value="supplier" :searchable="true" track-by="id" label="name" placeholder="Type to Search">
                    <template slot="caret">
                      <div>
                        <div class="multiselect__select">
                          <span>
                            <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                              <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </template>
                  </MultiSelect>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="error.supplier" class="text-sm text-red-600"><span>{{ error.supplier[0] }}</span></div>
              </div>
            </div>
            <div class="md:flex gap-2">
              <div class="w-full mb-4">
                <ValidationProvider name="Reference" rules="required" v-slot="{ errors }">
                  <label for="reference" class="label-control">Reference <span class="text-red-600">*</span></label>
                  <input id="reference" ref="reference" type="text" v-model="reference" maxlength="255" class="form-control">
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
                <div v-if="error.reference" class="text-sm text-red-600"><span>{{ error.reference[0] }}</span></div>              
              </div>
            </div>
            <div class="md:flex gap-6">
              <div class="w-full mb-4">
                <ValidationProvider name="Description" rules="" v-slot="{ errors }">
                  <label for="description" class="label-control">Description <span class="text-red-600">*</span></label>
                  <textarea id="description" ref="description" v-model="description" class="form-control"></textarea>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
                <div v-if="error.description" class="text-sm text-red-600"><span>{{ error.description[0] }}</span></div>              
              </div>
            </div>            
            <div class="md:flex gap-2">
              <div class="w-full md:w-1/2 mb-4">
                <ValidationProvider name="Color" rules="" v-slot="{ errors }">
                  <label for="color" class="label-control">Color</label>
                  <input id="color" type="text" v-model="color" class="form-control">
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
                <div v-if="error.color" class="text-sm text-red-600"><span>{{ error.color[0] }}</span></div>
              </div>
              <div class="w-full md:w-1/2 mb-4">
                <ValidationProvider name="Size" rules="" v-slot="{ errors }">
                  <label for="size" class="label-control">Size</label>
                  <input id="size" type="text" v-model="size" class="form-control">
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
                <div v-if="error.size" class="text-sm text-red-600"><span>{{ error.size[0] }}</span></div>
              </div>
            </div>
            <div class="md:flex gap-2">
              <div class="flex md:w-1/2 gap-4 md:gap-2 mb-4">
                <div class="w-full md:w-1/2">
                  <ValidationProvider name="Unit" rules="required" v-slot="{ errors }">
                    <label for="unit" class="label-control">Unit <span class="text-red-600">*</span></label>
                    <MultiSelect id="unit" ref="unit" v-model="unit" :options="units" :value="unit" :searchable="true" placeholder="Type to Search" :showLabels="false">
                      <template slot="caret">
                        <div>
                          <div class="multiselect__select">
                            <span>
                              <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                                <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </template>
                    </MultiSelect>
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.unit" class="text-sm text-red-600"><span>{{ error.unit[0] }}</span></div>              
                </div>
                <div class="w-full md:flex md:w-1/2 gap-2">
                  <div class="w-full md:w-1/2">
                    <ValidationProvider name="Buy Currency" rules="required" v-slot="{ errors }">
                      <label for="buy_currency" class="label-control">Buy Cur. <span class="text-red-600">*</span></label>
                      <MultiSelect @input="handleInputCurrency" id="buy_currency" ref="buy_currency" v-model="buyCurrency" :value="buyCurrency" :options="currencies" :searchable="false" label="currency" :showLabels="false" placeholder="">
                        <template slot="caret">
                          <div>
                            <div class="multiselect__select">
                              <span>
                                <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                                  <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </template>
                      </MultiSelect>
                      <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                    </ValidationProvider>
                    <div v-if="error.buy_currency" class="text-sm text-red-600"><span>{{ error.buy_currency[0] }}</span></div>                
                  </div>
                  <div class="w-full md:w-1/2">
                    <ValidationProvider name="Buy Rate" rules="required" v-slot="{ errors }">
                      <label for="buy_rate" class="label-control">Buy Rate <span class="text-red-600">*</span></label>
                      <input id="buy_rate" type="text" v-model="buyRate" class="form-control text-right" @input="handleInputRate($event)">
                      <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                    </ValidationProvider>
                    <div v-if="error.buy_rate" class="text-sm text-red-600"><span>{{ error.buy_rate[0] }}</span></div>                 
                  </div>
                </div>
              </div>
              <div class="flex md:w-1/2 gap-4 md:gap-2 mb-4">
                <div class="w-full md:w-1/2">
                  <ValidationProvider name="Buy Price" rules="required" v-slot="{ errors }">
                    <label for="buy_price" class="label-control">Buy Price <span class="text-red-600">*</span></label>
                    <div class="relative flex-1">
                      <span fill="currentColor" class="absolute left-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">{{ buyCurrency.symbol }}</span>
                      <input id="buy_price" type="text" v-model="buyPrice" class="form-control text-right" @input="handleInputPrice($event)">
                    </div>
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.buy_price" class="text-sm text-red-600"><span>{{ error.buy_price[0] }}</span></div>              
                </div>
                <div class="w-full md:w-1/2">
                  <ValidationProvider name="Price" rules="required" v-slot="{ errors }">
                    <label for="price" class="label-control">Convertion Price <span class="text-red-600">*</span></label>
                    <div class="relative flex-1">
                      <span fill="currentColor" class="absolute left-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
                      <input id="price" type="text" v-model="price" class="form-control text-right" readonly>
                    </div>
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.price" class="text-sm text-red-600"><span>{{ error.price[0] }}</span></div>            
                </div>
              </div>
            </div>                    
            <div class="md:flex gap-2">
              <div class="w-full flex md:w-1/2 gap-4 md:gap-2 mb-4">
                <div class="w-full md:w-1/2">
                  <ValidationProvider name="Minimum Stock" rules="required" v-slot="{ errors }">
                    <label for="minimum_stock" class="label-control">Minimum Stock <span class="text-red-600">*</span></label>
                    <input id="minimum_stock" type="text" v-model="minimumStock" class="form-control" @input="handleInputMinimumStock($event)">
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.minimum_stock" class="text-sm text-red-600"><span>{{ error.minimum_stock[0] }}</span></div>              
                </div>
                <div class="w-full md:w-1/2">
                  <ValidationProvider name="Status" rules="required" v-slot="{ errors }">
                    <label for="status" class="label-control">Status <span class="text-red-600">*</span></label>
                    <MultiSelect id="status" ref="status" v-model="status" :options="statusData" :value="status" track-by="id" label="name" :searchable="false" :showLabels="false">
                      <template slot="caret">
                        <div>
                          <div class="multiselect__select">
                            <span>
                              <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                                <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </template>
                    </MultiSelect>
                    <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                  </ValidationProvider>
                  <div v-if="error.status" class="text-sm text-red-600"><span>{{ error.status[0] }}</span></div>              
                </div>
              </div>
              <div class="md:w-1/2 mb-4"></div>
            </div>
          </form> 
        </ValidationObserver>        
      </template>
      <template v-slot:footer>
        <button :disabled="isLoading" type="submit" form="materialForm" class="btn btn--primary">
          Save
        </button>
      </template> 
    </modal>  
  </div>
</template>

<script>
import format from '@/helpers/formatNumber'
import collectionServices from '@/services/collection/collectionServices'
import supplierServices from '@/services/supplier/supplierServices'
import unitServices from '@/services/unit/unitServices'
import rateServices from '@/services/rate/rateServices'
import materialServices from '@/services/material/materialServices'
import Modal from '../../widgets/Modal'

export default {
  name: 'MaterialModal',
  components: {
    Modal,
  },
  data() {
    return {
      collection: [],
      supplier: '',
      reference: '',
      description: '',
      color: '',
      size: '',
      unit: '',
      euroRate: 0,
      buyCurrency: '',
      buyRate: '',
      buyPrice: '',
      price: '',
      minimumStock: '',
      status: '',
      statusData: [
        { id: 1, name: 'Discontinue' },
        { id: 0, name: 'Available' }        
      ],       
      collections: [],
      suppliers: [],
      units: [],
      currencies: [],
      error: [],
      isLoading: false,
      modalTitle: '',     
      showModal: false,      
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }         
    }
  },
  methods: {
    toggleModal() {
      this.showModal = true
      this.modalTitle = 'Materials'
    },
    async create(){
      try {
        this.isLoading = true   
        const payload = {
          type: this.collection,
          supplier: this.supplier.id,
          reference: this.reference,
          description: this.description,
          color: this.color,
          size: this.size,
          unit: this.unit,
          buy_currency: this.buyCurrency.currency,
          buy_rate: this.unformatNumber(this.buyRate),
          buy_price: this.unformatNumber(this.buyPrice),
          price: this.unformatNumber(this.price),
          minimum_stock: this.unformatNumber(this.minimumStock),
          status: this.status.id
        }

        const response = await materialServices.create(payload)
        if (response.data.status === 'success') {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false
                    
          /* CLEAR INPUT FORM */
          this.clearForm()

          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          this.fetchMaterial()

          this.showModal = false
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.type || responseReturn.supplier || responseReturn.reference || responseReturn.description || responseReturn.color || responseReturn.size || responseReturn.unit || responseReturn.currency || responseReturn.price || responseReturn.minimum_stock || responseReturn.status) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },
    async fetchCollectionData() {
      try {
        const response = await collectionServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.collections = []
          records.forEach(element => {
            this.collections.push({
              'id': element.id,
              'name': element.collection_name,
              'code': element.collection_code
            })
          });          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchSupplierData() {
      try {
        const response = await supplierServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.suppliers = []
          records.forEach(element => {
            this.suppliers.push({
              'id': element.id,
              'name': element.supplier_name
            })
          });
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchUnitData() {
      try {
        const response = await unitServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.units = []
          records.forEach(element => {
            this.units.push(element.unit)
          });
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    }, 
    async fetchCurrencyData() {
      try {
        const response = await rateServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.currencies = []
          records.forEach(element => {
            this.currencies.push({
              'currency': element.currency,
              'symbol': element.symbol,
              'rate': element.rate
            })
          });
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },             
    clearForm(){
      this.collection = []
      this.supplier = ''
      this.reference = ''
      this.description = ''
      this.color = ''
      this.size = ''
      this.unit = ''
      this.buyCurrency = ''
      this.buyRate = ''
      this.buyPrice = ''
      this.price = ''
      this.minimumStock = ''
      this.status = ''    
    },
    async fetchEuroRate() {
      try {
        const response = await rateServices.fetchByCurrency('EUR')
        if (response.data.status === 'success') {
          const record = response.data.data
          this.euroRate = record.rate
        } else {
          console.log(response.data.message)       
        }
      } catch (error) {
        console.log(error)
      }
    },    
    convertBuyPrice(price, currency) {
      if (currency === 'EUR') {
        return this.formatNumber(this.toFixed(price, 2))
      } else {
        let convertToEUR = price / this.buyCurrency.rate
        convertToEUR = this.formatNumber(this.toFixed(convertToEUR, 2))

        return convertToEUR
      }
    },
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    },   
    handleInputCurrency() {
      this.price = this.convertBuyPrice(this.unformatNumber(this.buyPrice), this.buyCurrency.currency)
      this.buyRate = this.formatNumber(this.buyCurrency.rate)
    },
    handleInputRate(event) {
      this.buyRate = this.formatNumber(event.target.value)
      this.price = this.convertBuyPrice(this.unformatNumber(this.buyPrice), this.buyCurrency.currency)
    },   
    handleInputPrice(event) {
      this.buyPrice = this.formatNumber(event.target.value)
      this.price = this.convertBuyPrice(this.unformatNumber(this.buyPrice), this.buyCurrency.currency)
    },
    handleInputMinimumStock(event) {
      this.minimumStock = this.formatNumber(event.target.value)
    },
    fetchMaterial() {
      this.$root.$emit('fetchMaterial')
    }    
  },
  created(){
    this.fetchCollectionData()
    this.fetchSupplierData()
    this.fetchUnitData()
    this.fetchCurrencyData()
    this.fetchEuroRate()
  },  
}
</script>