import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import Login from '../components/feature/pages/Login'
import Register from '../components/feature/pages/Register'
import ForgotPassword from '../components/feature/pages/ForgotPassword'
import ResetPassword from '../components/feature/pages/ResetPassword'
import Dashboard from '../components/feature/dashboard/Dashboard'
import Download from '../components/feature/pages/Download'

import supplierRoutes from '@/router/supplier'
import unitRoutes from '@/router/unit'
import seasonRoutes from '@/router/season'
import collectionRoutes from '@/router/collection'
import factoryRoutes from '@/router/factory'
import materialRoutes from '@/router/material'
import productRoutes from '@/router/product'
import purchaseRoutes from '@/router/purchase'
import receiveRoutes from '@/router/receive'
import adjustmentRoutes from '@/router/adjustment'
import transferStockRoutes from '@/router/transfer'
import opnameRoutes from '@/router/opname'
import orderRoutes from '@/router/order'
import calculationRoutes from '@/router/calculation'
import recapitulationRoutes from '@/router/recapitulation'
import materialAnalysisRoutes from '@/router/materialAnalysis'
import reportRoutes from '@/router/report'
import defaultVariableRoutes from '@/router/defaultVariable'
import exchangeRateRoutes from '@/router/exchangeRate'
import adjustmentReasonRoutes from '@/router/adjustmentReason'
import userRoutes from '@/router/user'
import activityLogRoutes from '@/router/activityLog'
import roleRoutes from '@/router/role'

Vue.use(VueRouter)

let routes = [
  {
    path: '/auth/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Login',
      guest: true
    }    
  },
  {
    path: '/auth/register',
    name: 'register',
    component: Register,
    meta: {
      title: 'Register Account',
      guest: true
    }    
  }, 
  {
    path: '/auth/forgot-password',
    name: 'forgotPassword',
    component: ForgotPassword,
    meta: {
      title: 'Forgot Password',
      guest: true
    }    
  }, 
  {
    path: '/auth/reset-password',
    name: 'resetPassword',
    component: ResetPassword,
    meta: {
      title: 'Reset Password',
      guest: true
    }    
  },
  {
    path: '/auth/download',
    name: 'download',
    component: Download,
    meta: {
      title: 'Download',
      requiresAuth: true
    }    
  },  
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      title: 'Dashboard',
      requiresAuth: true
    }    
  },                              
  {
    path: '*',
    redirect: { name: 'dashboard' }   
  }
]

/* JOINS ROUTES */
routes = routes.concat(supplierRoutes)
routes = routes.concat(unitRoutes)
routes = routes.concat(seasonRoutes)
routes = routes.concat(collectionRoutes)
routes = routes.concat(factoryRoutes)
routes = routes.concat(materialRoutes)
routes = routes.concat(productRoutes)
routes = routes.concat(purchaseRoutes)
routes = routes.concat(receiveRoutes)
routes = routes.concat(adjustmentRoutes)
routes = routes.concat(transferStockRoutes)
routes = routes.concat(opnameRoutes)
routes = routes.concat(orderRoutes)
routes = routes.concat(calculationRoutes)
routes = routes.concat(recapitulationRoutes)
routes = routes.concat(materialAnalysisRoutes)
routes = routes.concat(reportRoutes)
routes = routes.concat(defaultVariableRoutes)
routes = routes.concat(exchangeRateRoutes)
routes = routes.concat(adjustmentReasonRoutes)
routes = routes.concat(userRoutes)
routes = routes.concat(activityLogRoutes)
routes = routes.concat(roleRoutes)

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    /* THIS ROUTE REQUIRES AUTH, CHECK IF LOGGED IN */
    /* IF NOT, REDIRECT TO LOGIN PAGE */
    if (store.getters.isUserLoggedIn === false) {
      next({ name: 'login', query: { redirect: to.fullPath } })
    } else {
      next() /* GO TO  WHATEVER I'M GOING */
    }
  } else {
    next() /* DOES NOT REQUIRE AUTH, MAKE SURE  TO ALYWAYS CALL NEXT() */
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isUserLoggedIn) {
      next({ name: 'dashboard' }) /* IF USER LOGGED IN, REDIRECT TO DASHBOARD WHEN ACCESS LOGIN PAGE */
      return
    }
    next() /* GO TO  WHATEVER I'M GOING */
  } else {
    next() /* DOES NOT REQUIRE AUTH, MAKE SURE  TO ALYWAYS CALL NEXT() */
  }
})

export default router