import axios from '@/axios'

export default {
  fetchAll(params){
    return axios.get(`/factories/all`, {params})
  },
  fetchLimit(params){
    return axios.get(`/factories`, {params})
  },  
  fetchById(id){
    return axios.get(`/factories/${id}`)
  },    
  create(payload){
    return axios.post(`/factories`, payload)
  },
  update(id, payload){
    return axios.put(`/factories/${id}`, payload)
  },
  delete(id){
    return axios.delete(`/factories/${id}`)
  }, 
  deleteMultiple(payload){
    return axios.delete(`/factories`, payload)
  },
  export(){
    return axios.post(`/factories/export`)
  },
  fetchDataOptions(params){
    return axios.get(`/factories/options`, {params})
  }  
}