<template>
  <div>
    <!-- ACTION PANEL SECTION -->
    <div class="flex border-b border-gray-200 text-gray-800 justify-between items-center mb-4 pb-2">
      <div class="md:w-1/2 w-full items-center">
        <h2 class="text-2xl font-medium">Materials</h2>
      </div>
      <div class="flex items-center right-0">
        <button v-if="show" type="button" class="btn btn--success" id="createRecipe" @click="toggleNew">
          <PlusIcon>
            <span class="ml-2 md:block hidden">Add Material</span>
          </PlusIcon>
        </button>       
      </div>
    </div>     
    <!-- TABLE SECTION -->
    <div class="mb-4 bg-white shadow-lg rounded-lg overflow-y-auto">
      <table>
        <thead>
          <tr>
            <th scope="col" class="px-3 text-left">Supplier</th>
            <th scope="col" class="px-3 text-left">Reference</th>
            <th scope="col" class="px-3 text-left">Color</th>
            <th scope="col" class="px-3 text-left">Size</th>
            <th scope="col" class="px-3 text-left">Unit</th>
            <th scope="col" class="px-3 text-center price-column">Price</th>
            <th scope="col" class="px-3 text-center">Qty 1</th>
            <th scope="col" class="px-3 text-center">Qty 2</th>
            <th scope="col" class="px-3 text-center price-column">Total Price</th>
            <th scope="col" class="px-3 text-center" v-if="show">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="details.length === 0" class="border-b border-gray-200 hover:bg-gray-100"><td class="text-center" colspan="10">No data to display</td></tr>
          <tr v-for="(detail, index) in details" :key="detail.id" class="border-b border-gray-200 hover:bg-gray-100" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">
            <td class="px-3 text-left">{{ detail.material.supplier.supplier_name }}</td>
            <td class="px-3 text-left">{{ detail.material_name }}</td>
            <td class="px-3 text-left">{{ detail.color }}</td>
            <td class="px-3 text-left">{{ detail.size }}</td>
            <td class="px-3 text-left">{{ detail.unit }}</td>
            <td class="px-3 text-right">{{ detail.price }} {{ detail.currency_detail.symbol }}</td>
            <td class="px-3 text-right">{{ detail.qty1 }}</td>
            <td class="px-3 text-right">{{ detail.qty2 }}</td>
            <td class="px-3 text-right">{{ detail.total }} {{ detail.currency_detail.symbol }}</td>
            <td class="px-3 text-center" v-if="show">
              <div class="flex item-center justify-center">
                <button @click="toggleEdit( detail.id )" type="button" class="btn-edit" alt="Edit" v-tooltip="'Edit'">
                  <EditIcon />
                </button>        
                <button @click="confirmDialog( detail.id )" type="button" class="btn-delete" alt="Delete" v-tooltip="'Delete'">
                  <DeleteIcon />
                </button>
              </div>
            </td>
          </tr>
          <tr class="border-b border-gray-200 bg-gray-50">
            <td class="px-3 text-left" colspan="8"><span class="font-medium">TOTAL MATERIALS</span></td>
            <td class="px-3 text-right"><span class="font-medium">{{ this.grandTotal }} €</span></td>
            <td class="px-3 text-center" v-if="show"></td>
          </tr>
        </tbody>                              
      </table>
      <div class="flex px-5 py-5 bg-white justify-between items-center"></div>                            
    </div>
    <!-- Modal Dialog -->
    <modal :show="showModal" @close="showModal = false" addClass="modal-lg" modalOrientation="pt-16 md:flex md:pt-0 md:items-center md:justify-center">
      <template v-slot:header><h3>{{ modalTitle }}</h3></template>
      <template v-slot:body>
        <form id="modalForm" @submit.prevent="save">
          <div class="md:flex gap-6">
            <div class="w-full mb-4">
              <label for="material" class="label-control">Material <span class="text-red-600">*</span></label>
              <MultiSelect id="material" v-model="material" :options="materials" :value="material" :searchable="true" @input="toggleFocus" track-by="id" label="name" :custom-label="nameWithColor" placeholder="Type to Search">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>
              <div v-if="error.material" class="text-sm text-red-600"><span>{{ error.material[0] }}</span></div>             
            </div>
          </div>
          <div class="md:flex gap-6">
            <div class="w-full mb-4">
              <h3 class="text-lg">Details</h3>
            </div>
          </div>
          <div class="md:flex gap-6">
            <div class="w-full mb-4">
              <label for="reference" class="label-control">Reference</label>
              <input id="reference" type="text" v-model="reference" class="form-control" readonly>
              <div v-if="error.reference" class="text-sm text-red-600"><span>{{ error.reference[0] }}</span></div>                
            </div>
          </div>          
          <div class="md:flex gap-6">
            <div class="md:w-1/2 mb-4">
              <label for="color" class="label-control">Color</label>
              <input id="color" type="text" v-model="color" class="form-control" readonly>
              <div v-if="error.color" class="text-sm text-red-600"><span>{{ error.color[0] }}</span></div> 
            </div>
            <div class="flex md:w-1/2 gap-6 mb-4">
              <div class="md:w-1/2">
                <label for="size" class="label-control">Size</label>
                <input id="size" type="text" v-model="size" class="form-control" readonly>
                <div v-if="error.size" class="text-sm text-red-600"><span>{{ error.size[0] }}</span></div>              
              </div>
              <div class="md:w-1/2">
                <label for="unit" class="label-control">Unit</label>
                <input id="unit" type="text" v-model="unit" class="form-control" readonly>
                <div v-if="error.unit" class="text-sm text-red-600"><span>{{ error.unit[0] }}</span></div>
              </div>
            </div>
          </div>
          <div class="md:flex gap-6">
            <div class="flex md:w-1/2 gap-6 mb-4">
              <div class="md:w-1/2">
                <label for="price" class="label-control">Price <span class="text-red-600">*</span></label>
                <div class="relative flex-1">
                  <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
                  <input id="price" type="text" v-model="price" @input="handleInputPrice" class="form-control text-right right-symbol">
                </div>
                <div v-if="error.price" class="text-sm text-red-600"><span>{{ error.price[0] }}</span></div>
              </div>
              <div class="md:w-1/2">
                <label for="qty1" class="label-control">Qty 1 <span class="text-red-600">*</span></label>
                <input id="qty1" ref="qty1" type="text" v-model="qty1" @input="handleInputQty1" class="form-control text-right">
                <div v-if="error.qty1" class="text-sm text-red-600"><span>{{ error.qty1[0] }}</span></div>
              </div>
            </div>
            <div class="flex md:w-1/2 gap-6 mb-4">
              <div class="md:w-1/2">
                <label for="qty2" class="label-control">Qty 2 <span class="text-red-600">*</span></label>
                <input id="qty2" ref="qty2" type="text" v-model="qty2" @input="handleInputQty2" class="form-control text-right">
                <div v-if="error.qty2" class="text-sm text-red-600"><span>{{ error.qty2[0] }}</span></div>                          
              </div>
              <div class="md:w-1/2">
                <label for="total" class="label-control">Total Price</label>
                <div class="relative flex-1">
                  <span fill="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">€</span>
                  <input id="total" type="text" v-model="total" class="form-control text-right right-symbol" readonly>
                </div>
                <div v-if="error.total" class="text-sm text-red-600"><span>{{ error.total[0] }}</span></div>
              </div>
            </div>
          </div>
        </form>
      </template>
      <template v-slot:footer>
        <button :disabled="isLoading" type="submit" form="modalForm" class="btn btn--primary">
          Save
        </button>      
      </template> 
    </modal>
  </div>  
</template>

<script>
import format from '@/helpers/formatNumber'
import materialServices from '@/services/material/materialServices'
import productRecipeServices from '@/services/product/productRecipeServices'
import Modal from '../../widgets/Modal'
import PlusIcon from '../../icons/PlusIcon'
import EditIcon from '../../icons/EditIcon'
import DeleteIcon from '../../icons/DeleteIcon'

export default {
  name: 'ProductRecipe',
  components: {
    Modal,
    PlusIcon,
    EditIcon,
    DeleteIcon,
  },
  props: ['show'],
  data() {
    return {
      modalTitle: '',     
      showModal: false,
      details: [],
      detailId: '',
      material: '',
      reference: '',
      color: '',
      size: '',
      unit: '',
      currency: '',
      price: 0,
      qty1: 0,
      qty2: 0,
      total: 0,
      grandTotal: 0,
      materials: [],
      error: [],
      isEdit: false,
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async fetchDetailData() {
      try {
        this.isLoading = true

        const id = this.$route.params.id
        const params = {
          take: '',
          page: '',
          search: '',
          date_field: '',
          start: '',
          end: '',
          sort_field: 'id',
          sort_option: 'ASC'
        }        
        const response = await productRecipeServices.fetchAll(params, id)
        if (response.data.status === 'success') {
          this.isLoading =false

          this.details = response.data.data

          this.grandTotal = 0
          this.details.forEach(element => {
            this.grandTotal += parseFloat(element.total)
          });
          this.grandTotal = this.toFixed(this.grandTotal, 2)
          this.$store.dispatch('setRecipeTotal', this.grandTotal)
        } else {
          this.isLoading =false

          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        this.isLoading =false
        console.log(error)
      }
    },
    async fetchMaterialData() {
      try {
        const params = {
          take: '',
          page: '',
          search: '',
          date_field: '',
          start: '',
          end: '',
          sort_field: 'material_name',
          sort_option: 'ASC'
        }        
        const response = await materialServices.fetchDataOptions(params)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.materials = []
          records.forEach(element => {
            this.materials.push({
              'id': element.id,
              'supplier': element.supplier,
              'name': element.material_name,
              'color': element.color,
              'size': element.size,
              'unit': element.unit,
              'currency': element.currency,
              'price': element.current_price
            })
          })
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchData(id){
      try {
        const response = await productRecipeServices.fetchById(id)
        if (response.data.status === 'success') {
          this.isLoading = false
          this.record = response.data.data

          this.material = {
            'id': this.record.material_id,
            'supplier': this.record.supplier.supplier_name,
            'name': this.record.material_name,
            'color': this.record.color,
            'size': this.record.size,
            'unit': this.record.unit,
            'currency': this.record.currency,
            'price': this.record.price,
          }
          this.reference = this.record.material_name
          this.color = this.record.color
          this.size = this.record.size
          this.unit = this.record.unit
          this.currency = this.record.currency
          this.price = this.formatNumber(this.record.price)
          this.qty1 = this.formatNumber(this.record.qty1)
          this.qty2 = this.formatNumber(this.record.qty2)
          this.total = this.formatNumber(this.record.total)
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)            
        }
      } catch (error) {
        console.log(error)
      }
    },    
    async save(){
      try {
        this.isLoading = true

        const id = this.$route.params.id
        let payload = new FormData()
        payload.append('product_id', id)
        payload.append('material', this.material ? this.material.id : '')
        payload.append('reference', this.reference)
        payload.append('color', this.color ?? '')
        payload.append('size', this.size ?? '')
        payload.append('unit', this.unit ?? '')
        payload.append('currency', this.currency)
        payload.append('price', this.unformatNumber(this.price))
        payload.append('qty1', this.unformatNumber(this.qty1))
        payload.append('qty2', this.unformatNumber(this.qty2))
        payload.append('total', this.unformatNumber(this.total))
        
        let response = ''
        if (this.isEdit === true) {
          payload.append('_method', 'PUT')
          response = await productRecipeServices.update(this.detailId, payload)
        } else {
          response = await productRecipeServices.create(payload)
        }
        
        if (response.data.status === 'success') {
          /* SET IS EDIT STATE TO FALSE */
          this.isEdit = false

          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          if (this.detailId) {
            this.showModal = false
          }

          /* CLEAR INPUT FORM */
          this.clearForm()

          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          /* RELOAD DETAILS DATA */
          this.fetchDetailData()
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.material || responseReturn.price || responseReturn.qty1 || responseReturn.qty2 || responseReturn.total) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },
    async delete(id) {
      try {
        const response = await productRecipeServices.delete(id)
        return response.data
      } catch (error) {     
        return error
      }
    },
    async confirmDialog(id) {
      // console.log(id)
      this.$swal.fire({
        title: 'Are you sure ?',
        text: "You won't be able to revert this!",
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#4b5563',
        confirmButtonText: 'Yes'
      }).then(async (result) => {
        if (result.isConfirmed) {
          /* CALL DELETE FUNCTION */
          const result = this.delete(id)
          const response = await result

          if (response.status) {
            if (response.status === 'success') {
              /* REFRESH DATA */
              this.fetchDetailData()

              this.$swal.fire({
                title: 'Deleted!',
                text: "Your record has been deleted",
                icon: 'success',
                showConfirmButton: false,
                timer: 3000            
              })              
            } else {
              this.$swal.fire({
                title: 'Error!',
                text: response.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 3000            
              })              
            }
          } else {
            this.$toasted.error(response.message, this.toastedOptions)           
          }
        }
      })
    },     
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    },
    nameWithColor ({ supplier, name, color, size }) {
      if (color && size) {
        return `${supplier} ${name} — ${color} [ ${size} ]`
      } else if (color) {
        return `${supplier} ${name} — ${color}`
      }
      return `${supplier} ${name}`
    },
    toggleFocus() {
      this.$refs.qty1.focus()
    },
    handleInputPrice(event) {
      this.price = this.formatNumber(event.target.value)
    },
    handleInputQty1(event) {
      this.qty1 = this.formatNumber(event.target.value)
    },
    handleInputQty2(event) {
      this.qty2 = this.formatNumber(event.target.value)
    },             
    toggleNew() {
      this.isEdit = false
      this.error = []
      this.showModal = true
      this.modalTitle = 'Add Material'
      this.clearForm()
      this.detailId = ''
    },
    toggleEdit(id) {
      this.isLoading = true
      this.isEdit = true
      this.error = []
      this.showModal = true
      this.modalTitle = 'Edit Material'
      this.detailId = ''
      this.clearForm()
      this.detailId = id
      this.fetchData(id)
    },    
    calculateTotal() {
      const price = this.price ?? 0;
      const qty = this.qty2 ?? 0;
      let total = this.unformatNumber(price) * this.unformatNumber(qty)
      total = this.formatNumber(this.toFixed(total, 2));
      this.total = total;
    },
    clearForm() {
      this.material = ''
      this.reference = '' 
      this.color = ''
      this.size = ''
      this.unit = ''
      this.currency = ''
      this.price = 0
      this.qty1 = 0
      this.qty2 = 0
      this.total = 0
    }
  },
  created() {
    this.fetchDetailData()
    this.fetchMaterialData()
  }, 
  watch: {
    material: function() {
      if (this.material) {
        this.reference = this.material.name
        this.color = this.material.color
        this.size = this.material.size
        this.unit = this.material.unit
        this.currency = this.material.currency
        this.price = this.material.price        
      } else {
        this.clearForm()
      }
    },
    price: function() {
      this.calculateTotal()
    },
    qty1: function() {
      if (this.qty2 === 0 || this.qty2 === '0.' || this.qty2 === null || this.qty2 === '' || this.qty1 > this.qty2) {
        this.qty2 = this.qty1
      }
      this.calculateTotal()
    },
    qty2: function() {
      this.calculateTotal()
    }    
  }
}
</script>