var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"bg-white shadow-lg rounded-lg",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.create)}}},[_c('div',{staticClass:"p-10"},[_c('div',{staticClass:"pb-2 mb-4 border-b border-gray-200"},[_c('h2',{staticClass:"text-gray-800 text-2xl font-medium"},[_vm._v("Create Rate")])]),_c('div',{staticClass:"md:flex gap-6"},[_c('div',{staticClass:"md:w-1/2 mb-4"},[_c('ValidationProvider',{attrs:{"name":"Currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-control",attrs:{"for":"currency"}},[_vm._v("Currency "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currency),expression:"currency"}],ref:"currency",staticClass:"form-control",attrs:{"id":"currency","type":"text"},domProps:{"value":(_vm.currency)},on:{"input":function($event){if($event.target.composing){ return; }_vm.currency=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.currency)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.currency[0]))])]):_vm._e()],1),_c('div',{staticClass:"md:w-1/2 mb-4"},[_c('ValidationProvider',{attrs:{"name":"Symbol","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-control",attrs:{"for":"symbol"}},[_vm._v("Symbol "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.symbol),expression:"symbol"}],staticClass:"form-control",attrs:{"id":"symbol","type":"text"},domProps:{"value":(_vm.symbol)},on:{"input":function($event){if($event.target.composing){ return; }_vm.symbol=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.symbol)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.symbol[0]))])]):_vm._e()],1)]),_c('div',{staticClass:"md:flex gap-6"},[_c('div',{staticClass:"md:w-1/2 mb-4"},[_c('ValidationProvider',{attrs:{"name":"Rate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-control",attrs:{"for":"rate"}},[_vm._v("Rate "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rate),expression:"rate"}],staticClass:"form-control",attrs:{"id":"rate","type":"text"},domProps:{"value":(_vm.rate)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.rate=$event.target.value},_vm.handleInputRate]}}),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.rate)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.rate[0]))])]):_vm._e()],1),_c('div',{staticClass:"md:w-1/2 mb-4"})]),_c('div',{staticClass:"md:flex gap-4 mt-4 mb-2"},[_c('button',{staticClass:"btn btn--primary",attrs:{"disabled":_vm.isLoading,"type":"submit","alt":"Save","title":"Save"}},[(_vm.isLoading)?_c('span',{staticClass:"block mx-5"},[_c('Loading')],1):_c('SaveIcon',[_c('span',{staticClass:"ml-2 md:block hidden"},[_vm._v("Save")])])],1),_c('button',{staticClass:"btn btn--secondary",attrs:{"disabled":_vm.isLoading,"type":"button","alt":"Back","title":"Back"},on:{"click":function($event){return _vm.goBack()}}},[_c('BackIcon',[_c('span',{staticClass:"ml-2 md:block hidden"},[_vm._v("Back")])])],1)])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }