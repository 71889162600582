var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Add Exchange Rate'),expression:"'Add Exchange Rate'"}],staticClass:"w-full btn btn--success",attrs:{"type":"button"},on:{"click":_vm.toggleModal}},[_vm._v(" Add Rate ")]),_c('modal',{attrs:{"show":_vm.showModal,"addClass":"modal-sm","modalOrientation":"pt-16 md:pt-5 md:flex md:items-center md:justify-center"},on:{"close":function($event){_vm.showModal = false}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h3',[_vm._v(_vm._s(_vm.modalTitle))])]},proxy:true},{key:"body",fn:function(){return [_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"id":"rateForm"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.create)}}},[_c('div',{staticClass:"md:flex gap-6"},[_c('div',{staticClass:"md:w-1/2 mb-4"},[_c('ValidationProvider',{attrs:{"name":"Currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-control",attrs:{"for":"currency"}},[_vm._v("Currency "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currency),expression:"currency"}],ref:"currency",staticClass:"form-control",attrs:{"id":"currency","type":"text"},domProps:{"value":(_vm.currency)},on:{"input":function($event){if($event.target.composing){ return; }_vm.currency=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.currency)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.currency[0]))])]):_vm._e()],1),_c('div',{staticClass:"md:w-1/2 mb-4"},[_c('ValidationProvider',{attrs:{"name":"Symbol","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-control",attrs:{"for":"symbol"}},[_vm._v("Symbol "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.symbol),expression:"symbol"}],staticClass:"form-control",attrs:{"id":"symbol","type":"text"},domProps:{"value":(_vm.symbol)},on:{"input":function($event){if($event.target.composing){ return; }_vm.symbol=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.symbol)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.symbol[0]))])]):_vm._e()],1)]),_c('div',{staticClass:"md:flex gap-6"},[_c('div',{staticClass:"md:w-1/2 mb-4"},[_c('ValidationProvider',{attrs:{"name":"Rate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-control",attrs:{"for":"rate"}},[_vm._v("Rate "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rate),expression:"rate"}],staticClass:"form-control",attrs:{"id":"rate","type":"text"},domProps:{"value":(_vm.rate)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.rate=$event.target.value},_vm.handleInputRate]}}),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.rate)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.rate[0]))])]):_vm._e()],1),_c('div',{staticClass:"md:w-1/2 mb-4"})])])]}}])})]},proxy:true},{key:"footer",fn:function(){return [_c('button',{staticClass:"btn btn--primary",attrs:{"disabled":_vm.isLoading,"type":"submit","form":"rateForm"}},[_vm._v(" Save ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }