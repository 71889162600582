<template>
  <div>
    <button type="button" class="w-full btn btn--success" @click="toggleModal" v-tooltip="'Add Reason'">
      Add Reason   
    </button>    
    <!-- Import Modal Dialog -->
    <modal :show="showModal" @close="showModal = false" addClass="modal-sm" modalOrientation="pt-16 md:pt-5 md:flex md:items-center md:justify-center">
      <template v-slot:header><h3>{{ modalTitle }}</h3></template>
      <template v-slot:body>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form id="reasonForm" @submit.prevent="handleSubmit(create)">
            <div class="w-full gap-6 mb-4">
              <ValidationProvider name="Description" rules="required" v-slot="{ errors }">
                <label for="description" class="label-control">Description <span class="text-red-600">*</span></label>
                <input id="description" ref="description" type="text" v-model="description" maxlength="255" class="form-control" autofocus>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.description" class="text-sm text-red-600"><span>{{ error.description[0] }}</span></div> 
            </div>
            <div class="w-full md:flex gap-6">
              <div class="w-full md:w-1/2 mb-4">
                <ValidationProvider name="Disposition" rules="required" v-slot="{ errors }">
                  <label for="disposition" class="label-control">Disposition <span class="text-red-600">*</span></label>
                  <MultiSelect id="disposition" v-model="disposition" :options="dispositions" :value="disposition" :searchable="true" track-by="value" label="name" placeholder="Type to Search">
                    <template slot="caret">
                      <div>
                        <div class="multiselect__select">
                          <span>
                            <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                              <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </template>
                  </MultiSelect>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="error.disposition" class="text-sm text-red-600"><span>{{ error.disposition[0] }}</span></div>  
              </div>
              <div class="w-full md:w-1/2 mb-4"></div>
            </div>            
          </form> 
        </ValidationObserver>
      </template>
      <template v-slot:footer>
        <button :disabled="isLoading" type="submit" form="reasonForm" class="btn btn--primary">
          Save
        </button>      
      </template> 
    </modal>
  </div>
</template>

<script>
import adjustmentReasonServices from '@/services/adjustment/adjustmentReasonServices'
import Modal from '../../widgets/Modal'

export default {
  name: 'ReasonModal',
  components: {
    Modal,
  },
  data() {
    return {
      description: '',
      disposition: '',
      dispositions: [
        {
          "name": "+ STOCK ON HAND",
          "value": "+"
        },
        {
          "name": "- STOCK ON HAND",
          "value": "-"
        },
      ],
      error: [],
      isLoading: false, 
      modalTitle: '',     
      showModal: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }         
    }
  },
  methods: {
    toggleModal() {
      this.showModal = true
      this.modalTitle = 'Reasons'
    },
    async create(){
      try {
        this.isLoading = true       
        const payload = {
          description: this.description,
          disposition: this.disposition.value
        }
        const response = await adjustmentReasonServices.create(payload)
        if (response.data.status === 'success') {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false
                    
          /* CLEAR INPUT FORM */
          this.unit = ''

          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          this.fetchReason()

          this.showModal = false
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.unit) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },
    fetchReason() {
      this.$root.$emit('fetchReason')      
    }        
  }
}
</script>