var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col md:flex-row h-full items-center bg-gray-200"},[_c('div',{staticClass:"bg-white w-full md:max-w-md lg:max-w-full md:mx-auto md:w-1/2 xl:w-1/3 h-auto px-6 lg:px-16 xl:px-12\n        flex items-center justify-center rounded shadow-md lg:mt-8 lg:mb-8 xl:mt-8 xl:mb-8"},[_c('div',{staticClass:"w-full py-6"},[_c('span',[_vm._v("Register")]),_c('h1',{staticClass:"text-xl md:text-2xl font-bold leading-tight"},[_vm._v("Create your account")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"mt-6",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.registerUser)}}},[_c('div',{staticClass:"mt-4"},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-700",attrs:{"for":"name"}},[_vm._v("Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"w-full px-4 py-3 rounded bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none",attrs:{"type":"text","id":"name","placeholder":"Enter Name","autofocus":""},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.name)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.name[0]))])]):_vm._e()],1),_c('div',{staticClass:"mt-4"},[_c('ValidationProvider',{attrs:{"name":"Username","rules":"required|alpha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-700",attrs:{"for":"username"}},[_vm._v("Username")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],staticClass:"w-full px-4 py-3 rounded bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none",attrs:{"type":"text","id":"username","placeholder":"Enter Username"},domProps:{"value":(_vm.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.username=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.username)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.username[0]))])]):_vm._e()],1),_c('div',{staticClass:"mt-4"},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-700",attrs:{"for":"email"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"w-full px-4 py-3 rounded bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none",attrs:{"type":"text","id":"email","placeholder":"Enter Email Address","autocomplete":""},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))]),(errors.email)?_c('span',{staticClass:"text-red-600 w-full"},[_vm._v(_vm._s(errors.email[0]))]):_vm._e()]}}],null,true)}),(_vm.error.email)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.email[0]))])]):_vm._e()],1),_c('div',{staticClass:"mt-4"},[_c('ValidationProvider',{attrs:{"name":"Password","rules":"required|min:6|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-700",attrs:{"for":"password"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"w-full px-4 py-3 rounded bg-gray-200 mt-2 border focus:border-blue-500\n                    focus:bg-white focus:outline-none",attrs:{"type":"password","id":"password","placeholder":"Enter Password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.password)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.password[0]))])]):_vm._e()],1),_c('div',{staticClass:"mt-4"},[_c('ValidationProvider',{attrs:{"name":"Repeat Password","rules":"required|min:6","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-700",attrs:{"for":"password_confirmation"}},[_vm._v("Repeat Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password_confirmation),expression:"password_confirmation"}],staticClass:"w-full px-4 py-3 rounded bg-gray-200 mt-2 border focus:border-blue-500\n                    focus:bg-white focus:outline-none",attrs:{"type":"password","id":"password_confirmation","placeholder":"Repeat Password"},domProps:{"value":(_vm.password_confirmation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password_confirmation=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.password_confirmation)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.password_confirmation[0]))])]):_vm._e()],1),_c('button',{staticClass:"w-full block bg-indigo-600 hover:bg-indigo-700 focus:bg-indigo-700 text-white font-semibold rounded\n                px-4 py-3 mt-6 disabled:opacity-50",attrs:{"type":"submit","disabled":_vm.isLoading}},[_vm._v(" Register Account ")]),_c('p',{staticClass:"mt-8"},[(!_vm.isLoading)?_c('router-link',{staticClass:"text-gray-500 hover:text-blue-700",attrs:{"to":{ name: 'login' }}},[_vm._v("Already registered?")]):_c('span',{staticClass:"text-gray-500"},[_vm._v("Already registered?")])],1)])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }