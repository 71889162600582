import ListOrder from '../components/feature/order/List'
import CreateOrder from '../components/feature/order/Create'
import EditOrder from '../components/feature/order/Edit'
import ShowOrder from '../components/feature/order/Show'

const routes = [
  {
    path: '/orders',
    name: 'order',
    component: ListOrder,
    meta: {
      title: 'Orders',
      requiresAuth: true
    }    
  },
  {
    path: '/orders/create',
    name: 'orderCreate',
    component: CreateOrder,
    meta: {
      title: 'Create Order',
      requiresAuth: true
    }    
  },
  {
    path: '/orders/:id/edit',
    name: 'orderEdit',
    component: EditOrder,
    meta: {
      title: 'Edit Order',
      requiresAuth: true
    }    
  },
  {
    path: '/orders/:id',
    name: 'orderShow',
    component: ShowOrder,
    meta: {
      title: 'Show Order',
      requiresAuth: true
    }    
  }  
]

export default routes