import axios from '@/axios'

export default {
  fetchAll(params){
    return axios.get(`/adjustments/all`, {params})
  },
  fetchLimit(params){
    return axios.get(`/adjustments`, {params})
  },  
  fetchById(id){
    return axios.get(`/adjustments/${id}`)
  },    
  create(payload){
    return axios.post(`/adjustments`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  },
  update(id, payload){
    return axios.put(`/adjustments/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }      
    })
  },
  delete(id){
    return axios.delete(`/adjustments/${id}`)
  }, 
  deleteMultiple(payload){
    return axios.delete(`/adjustments`, payload)
  },
  validate(id){
    return axios.post(`/adjustments/validate/${id}`)
  },
  cancelValidation(id){
    return axios.post(`/adjustments/cancel/${id}`)
  },  
  import(payload){
    return axios.post(`/adjustments/import`, payload)
  },
  exportDraft(payload){
    return axios.post(`/adjustments/draft`, payload)
  }  
}