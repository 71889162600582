import ListReceive from '../components/feature/receive/List'
import CreateReceive from '../components/feature/receive/Create'
import EditReceive from '../components/feature/receive/Edit'
import ShowReceive from '../components/feature/receive/Show'

const routes = [
  {
    path: '/receives',
    name: 'receive',
    component: ListReceive,
    meta: {
      title: 'Receives',
      requiresAuth: true
    }    
  },
  {
    path: '/receives/create',
    name: 'receiveCreate',
    component: CreateReceive,
    meta: {
      title: 'Create Receive',
      requiresAuth: true
    }    
  },
  {
    path: '/receives/:id/edit',
    name: 'receiveEdit',
    component: EditReceive,
    meta: {
      title: 'Edit Receive',
      requiresAuth: true
    }    
  },
  {
    path: '/receives/:id',
    name: 'receiveShow',
    component: ShowReceive,
    meta: {
      title: 'Show Receive',
      requiresAuth: true
    }    
  }  
]

export default routes