<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="bg-white shadow-lg rounded-lg" @submit.prevent="handleSubmit(update)">
        <div class="p-10">
          <div class="pb-2 mb-4 border-b border-gray-200">
            <h2 class="text-gray-800 text-2xl font-medium">Edit Factory</h2>
          </div>
          <div class="md:flex gap-6">
            <div class="md:w-1/2 mb-4">
              <ValidationProvider name="Factory Name" rules="required" v-slot="{ errors }">
                <label for="factory_name" class="label-control">Factory Name <span class="text-red-600">*</span></label>
                <input id="factory_name" ref="factory_name" type="text" v-model="factoryName" maxlength="255" class="form-control" autofocus>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.factory_name" class="text-sm text-red-600"><span>{{ error.factory_name[0] }}</span></div>            
            </div>
            <div class="md:w-1/2 mb-4">
              <ValidationProvider name="Factory Code" rules="required" v-slot="{ errors }">
                <label for="factory_code" class="label-control">Factory Code <span class="text-red-600">*</span></label>
                <input id="factory_code" type="text" v-model="factoryCode" class="form-control">
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.factory_code" class="text-sm text-red-600"><span>{{ error.factory_code[0] }}</span></div>
            </div>
          </div>                   
          <div class="md:flex gap-6">
            <div class="w-full mb-4">
              <ValidationProvider name="Address" rules="" v-slot="{ errors }">
                <label for="address" class="label-control">Address</label>
                <textarea id="address" v-model="address" class="form-control" rows="5"></textarea>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.address" class="text-sm text-red-600"><span>{{ error.address[0] }}</span></div>                   
            </div>
          </div>
          <div class="md:flex gap-6">
            <div class="md:w-1/2 mb-4">
              <ValidationProvider name="City" rules="alpha_spaces" v-slot="{ errors }">
                <label for="city" class="label-control">City</label>
                <input id="city" type="text" v-model="city" class="form-control">
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.city" class="text-sm text-red-600"><span>{{ error.city[0] }}</span></div>
            </div>
            <div class="md:w-1/2 mb-4">
              <ValidationProvider name="Country" rules="alpha_spaces" v-slot="{ errors }">
                <label for="country" class="label-control">Country</label>
                <input id="country" type="text" v-model="country" class="form-control">
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.country" class="text-sm text-red-600"><span>{{ error.country[0] }}</span></div>
            </div>
          </div>
          <!-- CALL FACTORY CONTACTS -->
          <FactoryContact show="true" class="mb-10" />
          <!-- ACTIONS BUTTON -->          
          <div class="md:flex gap-4 mt-4 mb-2">
            <button :disabled="isLoading" type="submit" class="btn btn--primary" alt="Update" title="Update">
              <span v-if="isLoading" class="block mx-5">
                <Loading />
              </span>
              <SaveIcon v-else>
                <span class="ml-2 md:block hidden">Update</span>
              </SaveIcon>              
            </button>
            <button :disabled="isLoading" type="button" @click="goBack()" class="btn btn--secondary" alt="Back" title="Back">
              <BackIcon>
                <span class="ml-2 md:block hidden">Back</span>
              </BackIcon>              
            </button>
          </div>                                                  
        </div>
      </form>      
    </ValidationObserver>
  </div>
</template>

<script>
import factoryServices from '@/services/factory/factoryServices'
import SaveIcon from '../../icons/SaveIcon'
import BackIcon from '../../icons/BackIcon'
import Loading from '../../icons/Loading'
import FactoryContact from '../factory/Contact'

export default {
  name: 'EditFactory',
  components: {
    SaveIcon,
    BackIcon,
    Loading,
    FactoryContact
  },  
  data(){
    return {
      factoryName: '',
      factoryCode: '',
      address: '',
      city: '',
      country: '',
      record: [],
      error: [],
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async update(){
      try {
        this.isLoading = true

        const id = this.$route.params.id
        const payload = {
          factory_name: this.factoryName,
          factory_code: this.factoryCode,
          address: this.address,
          city: this.city,
          country: this.country
        }
        const response = await factoryServices.update(id, payload)
        if (response.data.status === 'success') {
          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          /* RETURN TO LIST */
          this.goBack()
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.factory_name || responseReturn.factory_code) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },
    async fetchData(){
      try {
        const id = this.$route.params.id
        const response = await factoryServices.fetchById(id)
        if (response.data.status === 'success') {
          this.record = response.data.data

          this.factoryName = this.record.factory_name
          this.factoryCode = this.record.factory_code
          this.address = this.record.address
          this.city = this.record.city
          this.country = this.record.country
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)            
        }
      } catch (error) {
        console.log(error)
      }
    },
    clearForm(){
      this.factoryName = ''
      this.factoryCode = ''
      this.address = ''
      this.city = ''
      this.country = ''
    },
    goBack() {
      window.history.length > 1 ? this.$router.back() : this.$router.push('/factories')
    }        
  },
  created(){
    this.fetchData()
  }
}
</script>