import axios from '@/axios'

export default {
  fetchAll(params){
    return axios.get(`/stock-opname/all`, {params})
  },
  fetchLimit(params){
    return axios.get(`/stock-opname`, {params})
  },  
  fetchById(id){
    return axios.get(`/stock-opname/${id}`)
  },    
  create(payload){
    return axios.post(`/stock-opname`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  },
  update(id, payload){
    return axios.put(`/stock-opname/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }      
    })
  },
  delete(id){
    return axios.delete(`/stock-opname/${id}`)
  }, 
  deleteMultiple(payload){
    return axios.delete(`/stock-opname`, payload)
  },
  validate(id){
    return axios.post(`/stock-opname/validate/${id}`)
  },
  cancelValidation(id){
    return axios.post(`/stock-opname/cancel/${id}`)
  },  
  import(payload){
    return axios.post(`/stock-opname/import`, payload)
  },
  export(id, payload){
    return axios.post(`/stock-opname/export/${id}`, payload)
  },
  exportDraft(payload){
    return axios.post(`/stock-opname/draft`, payload)
  }
}