<template>
  <div>
    <button type="button" class="w-full btn btn--success" @click="toggleModal" v-tooltip="'Add Season'">
      Add Season   
    </button>    
    <!-- Import Modal Dialog -->
    <modal :show="showModal" @close="showModal = false" addClass="modal-sm" modalOrientation="pt-16 md:pt-5 md:flex md:items-center md:justify-center">
      <template v-slot:header><h3>{{ modalTitle }}</h3></template>
      <template v-slot:body>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form id="seasonForm" @submit.prevent="handleSubmit(create)">
            <div class="w-full mb-4">
              <ValidationProvider name="Season Name" rules="required" v-slot="{ errors }">
                <label for="season_name" class="label-control">Season Name</label>
                <input id="season_name" ref="season_name" v-model="seasonName" type="text" class="form-control">
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
              </ValidationProvider>
              <div v-if="error.season_name" class="text-sm text-red-600"><span>{{ error.season_name[0] }}</span></div>
            </div>
            <div class="w-full mb-4">
              <ValidationProvider name="Description" rules="" v-slot="{ errors }">
                <label for="description" class="label-control">Description</label>
                <textarea id="description" v-model="description" class="form-control" rows="5"></textarea>
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.description" class="text-sm text-red-600"><span>{{ error.description[0] }}</span></div>                   
            </div>
            <div class="md:flex gap-6">
              <div class="md:w-1/2 mb-4">
                <ValidationProvider name="Status" rules="required" v-slot="{ errors }">
                  <label for="status" class="label-control">Status <span class="text-red-600">*</span></label>
                  <MultiSelect id="status" ref="status" v-model="status" :options="statusData" :value="status" track-by="id" label="name" :searchable="false" :showLabels="false">
                    <template slot="caret">
                      <div>
                        <div class="multiselect__select">
                          <span>
                            <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                              <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </template>
                  </MultiSelect>
                  <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
                </ValidationProvider>
                <div v-if="error.status" class="text-sm text-red-600"><span>{{ error.status[0] }}</span></div>
              </div>
              <div class="md:w-1/2 mb-4"></div>
            </div>            
          </form> 
        </ValidationObserver>
      </template>
      <template v-slot:footer>
        <button :disabled="isLoading" type="submit" form="seasonForm" class="btn btn--primary">
          Save
        </button>      
      </template> 
    </modal>
  </div>
</template>

<script>
import seasonServices from '@/services/season/seasonServices'
import Modal from '../../widgets/Modal'

export default {
  name: 'SeasonModal',
  components: {
    Modal,
  },
  data() {
    return {
      seasonName: '',
      description: '',
      status: '',
      statusData: [
        { id: 1, name: 'Active' },
        { id: 0, name: 'Inactive' }
      ],      
      error: [],
      isLoading: false, 
      modalTitle: '',     
      showModal: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }         
    }
  },
  methods: {
    toggleModal() {
      this.showModal = true
      this.modalTitle = 'Seasons'
    },
    async create(){
      try {
        this.isLoading = true       
        const payload = {
          season_name: this.seasonName,
          description: this.description,
          status: this.status.id
        }
        const response = await seasonServices.create(payload)
        if (response.data.status === 'success') {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false
                    
          /* CLEAR INPUT FORM */
          this.seasonName = ''
          this.description = ''
          this.status = ''

          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          this.fetchSeason()

          this.showModal = false
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.season_name || responseReturn.status) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },
    fetchSeason() {
      this.$root.$emit('fetchSeason')      
    }        
  }
}
</script>