<template>
  <div>  
    <!-- Import Modal Dialog -->
    <modal :show="showModal" @close="showModal = false" addClass="modal-2xl" modalOrientation="pt-16 md:pt-5 md:items-center">
      <template v-slot:header><h3>{{ modalTitle }}</h3></template>
      <template v-slot:body>
        <!-- TABLE FILTER SECTION -->
        <div class="border-b border-gray-100 mb-2 items-center">
          <p class="mb-2">ANALYSIS FOR: {{ material ? material.material_name + ' ' + material.color + ' ' + material.size : ''  }}</p>
        </div>        
        <!-- TABLE FILTER SECTION -->
        <div class="flex pb-2 justify-between items-center">
          <div class="relative">
            <select v-model="take" @change="updateTake()" class="px-4 py-2 pr-8 appearance-none rounded-md shadow-lg text-sm bg-white focus:bg-white text-black placeholder-gray-500 border border-gray-200 focus:border-purple-500 focus:outline-none">
              <option v-for="show in shows" :key="show.number" :value="show.number" :selected="show.number === take ? 'selected' : ''">{{ show.name }}</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
              <svg class="w-5 h-5 fill-current" viewBox="0 0 24 24">
              <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z" />
              </svg>
            </div>
          </div>
          <div class="flex w-96">
            <div class="relative flex-1">
              <svg width="20" height="20" fill="currentColor" class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"><path fill-rule="evenodd" clip-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"></path></svg>
              <input type="text" ref="search" v-model="search" @input="runSearch()" aria-label="Search" placeholder="Search ( Press &quot;/&quot; to focus )" class="w-full text-sm text-black placeholder-gray-500 border border-gray-200 rounded-md py-2 pl-10 focus:border-purple-500 focus:outline-none">  
            </div>
            <button :disabled="isLoading" type="button" @click="runSearch()" class="ml-2 btn-sm btn--success" alt="Update Result" title="Update Result">
              <span v-if="isLoading" class="block">
                Loading...
              </span>
              <RefreshIcon v-else>
                <span class="ml-2 text-sm md:block hidden">Refresh</span>
              </RefreshIcon>        
            </button>                               
          </div>
        </div>        
        <!-- TABLE SECTION -->
        <div class="mb-4 bg-white shadow-lg rounded-lg overflow-y-auto">
          <table>
            <thead>
              <tr>
                <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Season Creation</th>
                <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Collection</th>
                <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Ref</th>
                <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Color</th>
                <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Size</th>
                <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-center" rowspan="2">Description</th>
                <th scope="col" class="px-2 py-2 border-r border-b border-purple-300 border-opacity-50 text-center" colspan="2">EURO Price</th>
                <th scope="col" class="px-2 py-2 border-r border-b border-purple-300 border-opacity-50 text-center" colspan="2">USD Price</th>
                <th scope="col" class="px-2 py-2 text-center" rowspan="2">Actions</th>
              </tr>
              <tr>
                <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-center">Wholesale</th>
                <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-center">Retail</th>
                <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-center">Wholesale</th>
                <th scope="col" class="px-2 py-2 border-r border-purple-300 border-opacity-50 text-center">Retail</th>            
              </tr>            
            </thead>
            <tbody>
              <tr v-if="totalFiltered === 0" class="border-b border-gray-200 hover:bg-gray-100"><td class="text-center" colspan="11">No matching records found</td></tr>
              <tr v-for="(product, index) in products" :key="product.id" class="border-b border-gray-200 hover:bg-gray-100" :class="{ 'bg-gray-50' : (index % 2 !== 0) }">
                <td class="px-2 py-2 text-left">{{ product.season_creation }}</td>
                <td class="px-2 py-2 text-left">{{ product.collection }}</td>
                <td class="px-2 py-2 text-left">{{ product.reference }}</td>
                <td class="px-2 py-2 text-left">{{ product.color }}</td>
                <td class="px-2 py-2 text-left">{{ product.size }}</td>
                <td class="px-2 py-2 text-left">{{ product.description }}</td>
                <td class="px-2 py-2 text-right" :class="{ 'bg-red-100' : (product.approved_wholesale_price > 0) }">{{ formatNumber(toFixed(product.approved_wholesale_price, 0)) }} €</td>
                <td class="px-2 py-2 text-right" :class="{ 'bg-red-100' : (product.approved_retail_price > 0) }">{{ formatNumber(toFixed(product.approved_retail_price, 0)) }} €</td>
                <td class="px-2 py-2 text-right" :class="{ 'bg-yellow-100' : (product.approved_wholesale_price_usd > 0) }">{{ formatNumber(toFixed(product.approved_wholesale_price_usd, 0)) }} $</td>
                <td class="px-2 py-2 text-right" :class="{ 'bg-yellow-100' : (product.approved_retail_price_usd > 0) }">{{ formatNumber(toFixed(product.approved_retail_price_usd, 0)) }} $</td>            
                <td class="px-2 py-2 text-center">
                  <div class="flex item-center justify-center">
                    <router-link :to="{ name: 'productEdit', params: { id: product.id } }" target="_blank"  class="btn-edit" alt="Edit" v-tooltip="'Edit'">
                      <EditIcon />
                    </router-link>
                  </div>
                </td>
              </tr>
            </tbody>                              
          </table>
          <div class="flex px-5 py-5 bg-white justify-between items-center">
            <div class="flex-shrink text-sm">
              <span>Showing {{ fromRecord }} to {{ toRecord }} of {{ totalFiltered }} Entries </span>
            </div>
            <div class="pagination">
              <button type="button" :disabled="(currentPage - 1) === 0" @click="prevPage" class="page-item">
                <span class="text-sm">Prev</span>
              </button>
              <button type="button" :disabled="currentPage === lastPage || currentPage > lastPage" @click="nextPage" class="page-item">
                <span class="text-sm">Next</span>
              </button>
            </div>
          </div>                            
        </div>
      </template>
      <template v-slot:footer>
      </template> 
    </modal>  
  </div>
</template>

<script>
import format from '@/helpers/formatNumber'
import materialServices from '@/services/material/materialServices'
import materialAnalyzeServices from '@/services/material/materialAnalyzeServices'
import Modal from '../../widgets/Modal'
import RefreshIcon from '../../icons/RefreshIcon'
import EditIcon from '../../icons/EditIcon'

export default {
  name: 'MaterialAnalyzeModal',
  components: {
    Modal,
    RefreshIcon,
    EditIcon
  },
  data() {
    return {
      take: 25,
      page: 1,
      search: null,      
      awaitingSearch: false,
      totalFiltered: null,
      fromRecord: null,
      toRecord: null,
      currentPage: null,
      lastPage: null,
      shows: [],
      material: {
        material_name: '',
        color: '',
        size: ''
      },
      products: [],
      error: [],
      isLoading: false,
      selectedId: null,
      modalTitle: '',     
      showModal: false,      
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }         
    }
  },
  methods: {
    toggleModal(id) {
      this.selectedId = id
      this.showModal = true
      this.modalTitle = 'Material Analysis'
      this.material = {
        material_name: '',
        color: '',
        size: ''
      },      
      this.products = []
      this.fetchMaterialById(id)
      this.fetchData()
    },
    async fetchMaterialById(id) {
      try {
        this.isLoading = true
        const response = await materialServices.fetchById(id)
        if (response.data.status === 'success') {
          this.isLoading =false

          const record = response.data.data
          this.material = {
            material_name: record.material_name || '',
            color: record.color || '',
            size: record.size || ''
          }
        } else {
          this.isLoading =false

          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },    
    async fetchData() {
      try {
        this.isLoading = true

        const params = {
          take: this.take,
          page: this.page,
          search: this.search,
          material_id: this.selectedId
        }
        const response = await materialAnalyzeServices.fetchLimit(params)
        if (response.data.status === 'success') {
          this.isLoading =false

          const records = response.data.data
          this.totalFiltered = records.total_filter
          this.fromRecord = records.from
          this.toRecord = records.to
          this.currentPage = records.current_page
          this.lastPage = records.last_page
          this.shows = records.show
          this.products = records.data
        } else {
          this.isLoading =false

          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    searchFocus(event) {
      if (event.keyCode === 191) {
        setTimeout(() => {
          this.$refs.search.focus()
        }, 5)
      }
    },
    prevPage() {
      this.page = this.currentPage - 1
      this.fetchData()
    },
    nextPage() {
      this.page = this.currentPage + 1
      this.fetchData()
    },
    updateTake() {
      this.page = 1
      this.fetchData()      
    },
    runSearch() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.page = 1
          this.fetchData()
          this.awaitingSearch = false
        }, 1500)
      }
      this.awaitingSearch = true
    },    
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    }    
  },
  mounted() {
    const thisInstance = this
    this.$root.$on('toggleModal', function(){
      thisInstance.toggleModal()
    })
  }  
}
</script>