const state = {
  newProductID: null,
  indexProductRoute: null
};

const getters = {
  newProductID: state => state.newProductID,
  indexProductRoute: state => state.indexProductRoute
};

const actions = {
  setNewProductID({commit}, newProductID){
    commit('setNewProductID', newProductID)
  },
  setIndexProductRoute({commit}, fullPath){
    commit('setIndexProductRoute', fullPath)
  }
};

const mutations = {
  setNewProductID(state, newProductID){
    state.newProductID = newProductID
  },
  setIndexProductRoute(state, fullPath){
    state.indexProductRoute = fullPath
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};