<template>
  <div>
    <button type="button" class="w-full btn btn--success" @click="toggleModal" v-tooltip="'Add Unit'">
      Add Unit   
    </button>    
    <!-- Import Modal Dialog -->
    <modal :show="showModal" @close="showModal = false" addClass="modal-sm" modalOrientation="pt-16 md:pt-5 md:flex md:items-center md:justify-center">
      <template v-slot:header><h3>{{ modalTitle }}</h3></template>
      <template v-slot:body>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form id="unitForm" @submit.prevent="handleSubmit(create)">
            <div class="w-full gap-6 mb-4">
              <ValidationProvider name="Unit" rules="required" v-slot="{ errors }">
                <label for="unit" class="label-control">Unit</label>
                <input id="unit" ref="unit" v-model="unit" type="text" class="form-control">
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>
              </ValidationProvider>
              <div v-if="error.unit" class="text-sm text-red-600"><span>{{ error.unit[0] }}</span></div>
            </div>
          </form> 
        </ValidationObserver>
      </template>
      <template v-slot:footer>
        <button :disabled="isLoading" type="submit" form="unitForm" class="btn btn--primary">
          Save
        </button>      
      </template> 
    </modal>
  </div>
</template>

<script>
import unitServices from '@/services/unit/unitServices'
import Modal from '../../widgets/Modal'

export default {
  name: 'UnitModal',
  components: {
    Modal,
  },
  data() {
    return {
      unit: '',
      error: [],
      isLoading: false, 
      modalTitle: '',     
      showModal: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }         
    }
  },
  methods: {
    toggleModal() {
      this.showModal = true
      this.modalTitle = 'Units'
    },
    async create(){
      try {
        this.isLoading = true       
        const payload = {
          unit: this.unit
        }
        const response = await unitServices.create(payload)
        if (response.data.status === 'success') {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false
                    
          /* CLEAR INPUT FORM */
          this.unit = ''

          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          this.fetchUnit()

          this.showModal = false
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.unit) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },
    fetchUnit() {
      this.$root.$emit('fetchUnit')      
    }        
  }
}
</script>