<template>
  <div id="app"> 
    <div v-if="$route.path.indexOf('auth') === -1">
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar />
          <Content>
            <transition name="slide-fade">
              <router-view></router-view>
            </transition>
          </Content>
        </Container>
      </Wrapper>
    </div>
    <div v-if="$route.path.indexOf('auth') > -1">
      <transition name="slide-fade">
        <router-view></router-view>
      </transition>
    </div>
    <!-- set progressbar -->
    <vue-progress-bar></vue-progress-bar>    
  </div>
</template>

<script>
import Wrapper from './components/layout/content/Wrapper'
import Topbar from './components/layout/topbar/Topbar'
import Container from './components/layout/content/Container'
import Sidebar from './components/layout/sidebar/Sidebar'
import Content from './components/layout/content/Content'

const DEFAULT_TITLE = 'Dashboard'
const APP_TITLE = process.env.VUE_APP_TITLE

export default {
  name: 'App',
  components: {
    Wrapper,
    Topbar,
    Container,
    Sidebar,
    Content
  },
  mounted () {
    //  [App.vue specific] When App.vue is finish loading finish the progress bar
    this.$Progress.finish()
  },
  created () {
    //  [App.vue specific] When App.vue is first loaded start the progress bar
    this.$Progress.start()
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress
        // parse meta tags
        this.$Progress.parseMeta(meta)
      }
      //  start the progress bar
      this.$Progress.start()
      //  continue to next page
      next()
    })
    //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach(() => {
      //  finish the progress bar
      this.$Progress.finish()
    })
  },   
  watch: {
    '$route': {
      handler: (to) => {
        document.title = (to.meta.title || DEFAULT_TITLE) + ' - ' + APP_TITLE
      },
      immediate: true
    }
  }
}
</script>
