var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"bg-white shadow-lg rounded-lg",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.create)}}},[_c('div',{staticClass:"p-10"},[_c('div',{staticClass:"pb-2 mb-4 border-b border-gray-200"},[_c('h2',{staticClass:"text-gray-800 text-2xl font-medium"},[_vm._v("Create Transfer Stock")])]),_c('div',{staticClass:"w-full md:flex gap-4"},[_c('div',{staticClass:"w-full md:w-2/6 mb-4"},[_c('ValidationProvider',{attrs:{"name":"Transfer From","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-control",attrs:{"for":"transfer_from"}},[_vm._v("Transfer From "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('MultiSelect',{attrs:{"id":"transfer_from","options":_vm.locations,"value":_vm.transferFrom,"searchable":true,"track-by":"id","label":"name","placeholder":"Type to Search","showLabels":false},model:{value:(_vm.transferFrom),callback:function ($$v) {_vm.transferFrom=$$v},expression:"transferFrom"}},[_c('template',{slot:"caret"},[_c('div',[_c('div',{staticClass:"multiselect__select"},[_c('span',[_c('svg',{staticClass:"text-gray-500 mt-2 ml-2 w-5 h-5 fill-current",attrs:{"viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"}})])])])])])],2),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.transfer_from)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.transfer_from[0]))])]):_vm._e()],1),_c('div',{staticClass:"w-full md:w-2/6 mb-4"},[_c('ValidationProvider',{attrs:{"name":"Transfer To","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-control",attrs:{"for":"transfer_to"}},[_vm._v("Transfer To "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('MultiSelect',{attrs:{"id":"transfer_to","options":_vm.locations,"value":_vm.transferTo,"searchable":true,"track-by":"id","label":"name","placeholder":"Type to Search","showLabels":false},model:{value:(_vm.transferTo),callback:function ($$v) {_vm.transferTo=$$v},expression:"transferTo"}},[_c('template',{slot:"caret"},[_c('div',[_c('div',{staticClass:"multiselect__select"},[_c('span',[_c('svg',{staticClass:"text-gray-500 mt-2 ml-2 w-5 h-5 fill-current",attrs:{"viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"}})])])])])])],2),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.transfer_to)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.transfer_to[0]))])]):_vm._e()],1),_c('div',{staticClass:"w-full md:w-2/6 mb-4"},[_c('ValidationProvider',{attrs:{"name":"Transfer Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-date-picker',{attrs:{"mode":"date","masks":_vm.masks,"color":"purple","title-position":"left","popover":{ visibility: 'click' },"attributes":_vm.attrs},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('div',{staticClass:"md:flex gap-6"},[_c('div',{staticClass:"w-full"},[_c('label',{staticClass:"label-control",attrs:{"for":"transfer_date"}},[_vm._v("Transfer Date "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('div',{staticClass:"relative flex justify-between items-center"},[_c('input',_vm._g({staticClass:"form-control",attrs:{"id":"transfer_date","type":"text"},domProps:{"value":inputValue}},inputEvents)),_c('span',{staticClass:"h-full absolute pointer-events-none right-0"},[_c('DateRange',{staticClass:"m-3"})],1)]),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true),model:{value:(_vm.transferDate),callback:function ($$v) {_vm.transferDate=$$v},expression:"transferDate"}})]}}],null,true)}),(_vm.error.transfer_date)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.transfer_date[0]))])]):_vm._e()],1)]),_c('div',{staticClass:"md:flex gap-6"},[_c('div',{staticClass:"w-full mb-4"},[_c('ValidationProvider',{attrs:{"name":"Remarks","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-control",attrs:{"for":"remarks"}},[_vm._v("Remarks")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.remarks),expression:"remarks"}],staticClass:"form-control",attrs:{"id":"remarks","rows":"3"},domProps:{"value":(_vm.remarks)},on:{"input":function($event){if($event.target.composing){ return; }_vm.remarks=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600 w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.error.remarks)?_c('div',{staticClass:"text-sm text-red-600"},[_c('span',[_vm._v(_vm._s(_vm.error.remarks[0]))])]):_vm._e()],1)]),_c('div',{staticClass:"md:flex gap-4 mt-4 mb-2"},[_c('button',{staticClass:"btn btn--primary",attrs:{"disabled":_vm.isLoading,"type":"submit","alt":"Save","title":"Save"}},[(_vm.isLoading)?_c('span',{staticClass:"block mx-5"},[_c('Loading')],1):_c('SaveIcon',[_c('span',{staticClass:"ml-2 md:block hidden"},[_vm._v("Save & Continue")])])],1),_c('button',{staticClass:"btn btn--secondary",attrs:{"disabled":_vm.isLoading,"type":"button","alt":"Back","title":"Back"},on:{"click":function($event){return _vm.goBack()}}},[_c('BackIcon',[_c('span',{staticClass:"ml-2 md:block hidden"},[_vm._v("Back")])])],1)])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }