<template>
  <div class="lg:w-auto pl-8 flex-shrink-0 flex items-center justify-end space-x-6">
    <div class="block relative">
      <button @click="toggleDropdown" class="flex items-center focus:outline-none">
        <img class="block w-10 h-10 rounded-full object-cover" src="@/assets/images/user-circle.png" alt="User">
      </button>
      <transition v-if="this.isVisible">
        <ul class="absolute text-sm right-0 bg-white border-t border-gray-100 shadow overflow-hidden rounded-lg w-48 mt-2 py-1 z-50 lg:z-40">
          <li @click="toggleDropdown">
            <router-link :to="{ name: 'userProfile' }" class="flex items-center px-3 py-3 hover:bg-gray-50 hover:text-gray-700">
              <svg class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <span class="ml-2">Account</span>
            </router-link>
          </li>
          <li @click="toggleDropdown">
            <router-link :to="{ name: 'userUpdatePassword' }" class="flex items-center px-3 py-3 hover:bg-gray-50 hover:text-gray-700">
              <svg class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4" />
              </svg>
              <span class="ml-2">Change Password</span>
            </router-link>            
          </li>
          <li>
            <a @click="logoutUser" class="flex items-center px-3 py-3 hover:bg-gray-50 hover:text-gray-700 cursor-pointer">
              <svg class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
              </svg>
              <span class="ml-2">Logout</span>
            </a>
          </li>
        </ul>
      </transition>
    </div>
  </div>
</template>

<script>
import authServices from '@/services/user/authServices'

export default {
  data() {
    return {
      isVisible: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000  
      }      
    }
  },
  methods: {
    toggleDropdown(){
      this.isVisible = !this.isVisible
    },
    hideDropdown(e){
      if (!this.$el.contains(e.target)) {
        this.isVisible = false
      }
    },
    async logoutUser(){
      try {
        const response = await authServices.logout()
        if (response.data.status === 'success') {
          /* REMOVE DATA TO LOCAL STORAGE */
          localStorage.setItem('accessToken', null)

          this.$store.dispatch('setAuthentication', null)
          this.$store.dispatch('setUser', null)
          
          /* SUCCESS MESSAGE */
          this.$toasted.success(response.data.message, this.toastedOptions).goAway(1500)

          /* REDIRECT TO LOGIN PAGE */
          this.$router.push('/auth/login')          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)
        }
      } catch (error) {
        console.log(error)
        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    }
  },
  mounted(){
    document.addEventListener('click', this.hideDropdown)
  },
  beforeDestroy(){
    document.addEventListener('click', this.hideDropdown)
  }
}
</script>