import axios from '@/axios'

export default {
  fetchAll(params){
    return axios.get(`/transfer/all`, {params})
  },
  fetchLimit(params){
    return axios.get(`/transfer`, {params})
  },  
  fetchById(id){
    return axios.get(`/transfer/${id}`)
  },
  create(payload){
    return axios.post(`/transfer`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  },
  update(id, payload){
    return axios.put(`/transfer/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }      
    })
  },
  delete(id){
    return axios.delete(`/transfer/${id}`)
  }, 
  deleteMultiple(payload){
    return axios.delete(`/transfer`, payload)
  },
  validate(id){
    return axios.post(`/transfer/validate/${id}`)
  },
  cancelValidation(id){
    return axios.post(`/transfer/cancel/${id}`)
  },  
  export(id, payload){
    return axios.post(`/transfer/export/${id}`, payload)
  }  
}