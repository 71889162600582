import Recapitulation from '../components/feature/recapitulation/Order'

const routes = [
  {
    path: '/recapitulations',
    name: 'recapitulation',
    component: Recapitulation,
    meta: {
      title: 'Recapitulations',
      requiresAuth: true
    }    
  }
]

export default routes