<template>
  <div class="bg-white shadow-lg rounded-lg">
    <div class="p-10">
      <div class="pb-2 mb-4 border-b border-gray-200">
        <h2 class="text-gray-800 text-2xl font-medium">Show Collection</h2>
      </div>
      <div class="md:flex gap-6">
        <div class="md:flex-col w-full md:w-4/6">
          <div class="md:flex gap-6">
            <div class="md:w-1/2 mb-4">
              <label for="collection_name" class="label-control">Collection Name</label>
              <input id="collection_name" type="text" v-model="collection_name" class="form-control">
            </div>
            <div class="md:w-1/2 mb-4">
              <label for="collection_code" class="label-control">Collection Code</label>
              <input id="collection_code" type="text" v-model="collection_code" class="form-control">
            </div>
          </div>
          <div class="md:flex gap-6">
            <div class="w-full mb-4">
              <label for="description" class="label-control">Description</label>
              <textarea id="description" v-model="description" class="form-control" rows="5"></textarea>                 
            </div>
          </div>
          <div class="md:flex gap-6">
            <div class="md:w-1/2 mb-4">
              <label for="status" class="label-control">Status</label>
              <MultiSelect id="status" ref="status" v-model="status" :options="statusData" :value="status" track-by="id" label="name" :searchable="false" :showLabels="false">
                <template slot="caret">
                  <div>
                    <div class="multiselect__select">
                      <span>
                        <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                          <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </template>
              </MultiSelect>
            </div>
            <div class="md:w-1/2 mb-4"></div>
          </div>          
        </div>
        <div class="md:flex-col w-full md:w-2/6">
          <div class="md:flex gap-6">
            <div class="w-full mb-4">
              <label for="image" class="label-control">Image</label>
              <div v-if="!image">
                <div class="md:h-52 border-2 border-dashed border-gray-400 flex items-center justify-center p-2 rounded-lg mb-2">
                  <img class="m-auto" :src="fileBox" />
                </div>
              </div>
              <div v-else>
                <div class="h-auto border-2 border-dashed border-gray-400 flex items-center justify-center p-2 rounded-lg mb-2">
                  <img class="m-auto" :src="image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>       
      <button :disabled="isLoading" type="button" @click="goBack()" class="btn btn--secondary" alt="Back" title="Back">
        <BackIcon>
          <span class="ml-2 md:block hidden">Back</span>
        </BackIcon>              
      </button>                                                 
    </div>
  </div>
</template>

<script>
import collectionServices from '@/services/collection/collectionServices'
import BackIcon from '../../icons/BackIcon'
import imageBox from '../../../assets/images/file-box.png'

export default {
  name: 'ShowCollection',
  components: {
    BackIcon
  },
  data(){
    return {
      collection_name: '',
      collection_code: '',
      description: '',
      image: '',
      fileBox: imageBox,        
      status: '',
      statusData: [
        { id: 1, name: 'Active' },
        { id: 0, name: 'Inactive' }        
      ],      
      record: [],
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async fetchData(){
      try {
        const id = this.$route.params.id
        const response = await collectionServices.fetchById(id)
        if (response.data.status === 'success') {
          this.record = response.data.data

          this.collection_name = this.record.collection_name
          this.collection_code = this.record.collection_code
          this.description = this.record.description
          this.image = this.record.image
          this.status = this.record.status === 1 ? { id: 1, name: 'Active' } : { id: 0, name: 'Inactive' }
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)            
        }
      } catch (error) {
        console.log(error)
      }
    },
    goBack() {
      window.history.length > 1 ? this.$router.back() : this.$router.push('/collections')
    }       
  },
  created(){
    this.fetchData()
  }
}
</script>