<template>
  <div>  
    <!-- Import Modal Dialog -->
    <modal :show="showModal" @close="showModal = false" addClass="modal-xl" modalOrientation="pt-16 md:pt-5 md:items-center">
      <template v-slot:header><h3>{{ modalTitle }}</h3></template>
      <template v-slot:body>
        <div class="w-full md:flex gap-4">
          <div class="w-full md:w-1/2 mb-2">
            <label class="label-control uppercase">Module</label>
            <input type="text" v-model="activityLog.module" class="form-control" readonly>
          </div>
          <div class="w-full md:flex md:w-1/2 gap-4 mb-2">
            <div class="w-full md:w-1/2">
              <label class="label-control uppercase">Event</label>
              <input type="text" v-model="activityLog.event" class="form-control" readonly>
            </div>
            <div class="w-full md:w-1/2">
              <label class="label-control uppercase">Status</label>
              <span class="badge uppercase" :class="activityLog.status === 'error' ? 'badge--danger' : 'badge--success'">{{ activityLog.status }}</span>
            </div>
          </div>
        </div>
        <div class="w-full mb-2">
          <label class="label-control uppercase">Description</label>
          <textarea v-model="activityLog.description" class="form-control uppercase" rows="3" readonly></textarea>
        </div>
        <div class="w-full md:flex gap-4">
          <div class="w-full md:w-1/2 mb-2">
            <label class="label-control uppercase">Old Values</label>
            <div class="h-80 bg-gray-100 p-3 rounded-md border border-gray-200 overflow-y-scroll">
              <pre class="text-xs" v-html="oldValues"></pre>
            </div>
          </div>
          <div class="w-full md:w-1/2 mb-2">
            <label class="label-control uppercase">New Values</label>
            <div class="h-80 bg-gray-100 p-3 rounded-md border border-gray-200 overflow-y-scroll">
              <pre class="text-xs" v-html="newValues"></pre>
            </div>
          </div>
        </div>
        <div class="w-full md:flex gap-4">
          <div class="w-full md:w-1/2 mb-2">
            <label class="label-control uppercase">Url</label>
            <input type="text" v-model="activityLog.url" class="form-control" readonly>
          </div>
          <div class="w-full md:w-1/2 mb-2">
            <label class="label-control uppercase">IP Address</label>
            <input type="text" v-model="activityLog.ip_address" class="form-control" readonly>
          </div>
        </div>
        <div class="w-full mb-2">
          <label class="label-control uppercase">User Agent</label>
          <input type="text" v-model="activityLog.user_agent" class="form-control" readonly>
        </div>
        <div class="w-full md:flex gap-4">
          <div class="w-full md:w-1/2 mb-2">
            <label class="label-control uppercase">Created By</label>
            <input type="text" v-model="user" class="form-control" readonly>
          </div>
          <div class="w-full md:flex md:w-1/2 gap-4 mb-2">
            <div class="w-full md:w-1/2">
              <label class="label-control uppercase">Created At</label>
              <input type="text" v-model="activityLog.activity_date" class="form-control" readonly>
            </div>
            <div class="w-full md:w-1/2">
              <label class="label-control uppercase">Created Time (UTC)</label>
              <input type="text" v-model="activityLog.activity_time" class="form-control" readonly>
            </div>
          </div>
        </div>                       
      </template>
      <template v-slot:footer>
      </template> 
    </modal>  
  </div>
</template>

<script>
import activityLogServices from '@/services/activityLog/activityLogServices'
import Modal from '../../widgets/Modal'

export default {
  name: 'DetailActivityModal',
  components: {
    Modal,
  },
  data() {
    return {
      activityLog: [],
      oldValues: '',
      newValues: '',
      user: null,
      error: [],
      isLoading: false,
      modalTitle: '',     
      showModal: false,      
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }         
    }
  },
  methods: {
    toggleModal(id) {
      this.showModal = true
      this.modalTitle = 'Detail Activity Log'   
      this.clearItem()
      this.fetchActivityById(id)
    },   
    async fetchActivityById(id) {
      try {
        this.isLoading = true
        const response = await activityLogServices.fetchById(id)
        if (response.data.status === 'success') {
          this.isLoading =false
          this.activityLog = response.data.data
          this.oldValues = this.activityLog.old_values ? JSON.stringify(this.activityLog.old_values, null, 2) : ''
          this.newValues = this.activityLog.new_values ? JSON.stringify(this.activityLog.new_values, null, 2) : ''
          this.user = this.activityLog.user.name || ''
        } else {
          this.isLoading =false

          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    clearItem() {
      this.activityLog = [],
      this.oldValues = '',
      this.newValues = '',
      this.user = null
    }
  },
  mounted() {
    const thisInstance = this
    this.$root.$on('toggleModal', function(){
      thisInstance.toggleModal()
    })
  }  
}
</script>