<template>
  <div class="bg-white shadow-lg rounded-lg">  
    <div class="p-10">
      <div class="pb-2 mb-4 border-b border-gray-200">
        <h2 class="text-gray-800 text-2xl font-medium">Show Order</h2>
      </div>
      <div class="md:flex gap-14">
        <div class="md:flex-col w-full md:w-1/2">
          <div class="md:flex gap-4">
            <div class="flex md:w-1/2">
              <div class="w-full md:mb-2">
                <label for="order_no" class="label-control md:py-3">Order Number <span class="text-red-600">*</span></label>
              </div>
            </div>
            <div class="flex md:w-1/2">
              <div class="w-full mb-2">
                <div class="relative flex-1">
                  <span class="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm badge" :class="status === 1 ? 'badge--light-primary' : (status === 2 ? 'badge--success' : 'badge--danger')">{{ statusText }}</span>
                  <input id="order_no" type="text" v-model="orderNumber" class="form-control" readonly>
                </div>
              </div>
            </div>
          </div>
          <div class="md:flex gap-4">
            <div class="flex md:w-1/2">
              <div class="w-full md:mb-2">
                <label for="type" class="label-control md:py-3">Order Type <span class="text-red-600">*</span></label>
              </div>
            </div>
            <div class="flex md:w-1/2">
              <div class="w-full mb-2">
                <MultiSelect id="type" v-model="orderType" :options="orderTypes" :value="orderType" track-by="id" label="name" :showLabels="false">
                  <template slot="caret">
                    <div>
                      <div class="multiselect__select">
                        <span>
                          <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                            <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </template>
                </MultiSelect>
              </div>
            </div>
          </div>         
          <div class="md:flex gap-4">
            <div class="flex md:w-1/2">
              <div class="w-full md:mb-2">
                <label for="delivery_date_production" class="label-control md:py-3">Delivery Date Production <span class="text-red-600">*</span></label>
              </div>
            </div>
            <div class="flex md:w-1/2">
              <div class="w-full mb-2">
                <v-date-picker v-model="deliveryDateProduction" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs">
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="md:flex gap-4">
                      <div class="w-full">
                        <div class="relative flex justify-between items-center">
                          <input id="delivery_date_production" type="text" class="form-control" :value="inputValue" v-on="inputEvents">
                          <span class="h-full absolute pointer-events-none right-0">
                            <DateRange class="m-3" />
                          </span>                      
                        </div>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </div>
            </div>
          </div>
          <div class="md:flex gap-4">
            <div class="flex md:w-1/2">
              <div class="w-full md:mb-2">
                <label for="delivery_date_from" class="label-control md:py-3">Delivery Date for Customer From<span class="text-red-600">*</span></label>
              </div>
            </div>
            <div class="flex md:w-1/2">
              <div class="w-full mb-2">
                <v-date-picker v-model="customerDeliveryDateFrom" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs">
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="md:flex gap-4">
                      <div class="w-full">
                        <div class="relative flex justify-between items-center">
                          <input id="delivery_date_from" type="text" class="form-control" :value="inputValue" v-on="inputEvents">
                          <span class="h-full absolute pointer-events-none right-0">
                            <DateRange class="m-3" />
                          </span>                      
                        </div>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </div>
            </div>
          </div>
          <div class="md:flex gap-4">
            <div class="flex md:w-1/2">
              <div class="w-full md:mb-2">
                <label for="delivery_date_to" class="label-control md:py-3">Delivery Date for Customer To<span class="text-red-600">*</span></label>
              </div>
            </div>
            <div class="flex md:w-1/2">
              <div class="w-full mb-2">
                <v-date-picker v-model="customerDeliveryDateTo" mode="date" :masks="masks" color="purple" title-position="left" :popover="{ visibility: 'click' }" :attributes="attrs">
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="md:flex gap-4">
                      <div class="w-full">
                        <div class="relative flex justify-between items-center">
                          <input id="delivery_date_to" type="text" class="form-control" :value="inputValue" v-on="inputEvents">
                          <span class="h-full absolute pointer-events-none right-0">
                            <DateRange class="m-3" />
                          </span>                      
                        </div>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </div>
            </div>
          </div>              
        </div>
        <div class="md:flex-col w-full md:w-1/2">
          <div class="md:flex gap-4">
            <div class="flex md:w-1/6">
              <div class="w-full md:mb-2">
                <label for="customer_name" class="label-control md:py-3">Customer <span class="text-red-600">*</span></label>
              </div>
            </div>
            <div class="flex md:w-5/6">
              <div class="w-full mb-2">
                <input id="customer_name" type="text" v-model="customerName" class="form-control">
              </div>
            </div>
          </div>
          <div class="md:flex gap-4">
            <div class="flex md:w-1/6">
            </div>
            <div class="flex md:w-5/6">
              <div class="w-full mb-2">
                <input id="country" type="text" v-model="country" class="form-control">
              </div>
            </div>
          </div>
          <div class="md:flex gap-4">
            <div class="flex md:w-1/6">
              <div class="w-full md:mb-2">
                <label for="season" class="label-control md:py-3">Season <span class="text-red-600">*</span></label>
              </div>
            </div>
            <div class="flex md:w-5/6">
              <div class="w-full mb-2">
                <MultiSelect id="season" v-model="season" :options="seasons" :value="season" track-by="id" label="name" :showLabels="false">
                  <template slot="caret">
                    <div>
                      <div class="multiselect__select">
                        <span>
                          <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                            <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </template>
                </MultiSelect>
              </div>
            </div>
          </div>              
          <div class="md:flex gap-4">
            <div class="flex md:w-1/6">
              <div class="w-full md:mb-2">
                <label for="period" class="label-control md:py-3">Period <span class="text-red-600">*</span></label>
              </div>
            </div>
            <div class="flex md:w-5/6">
              <div class="w-full mb-2">
                <MultiSelect id="period" v-model="period" :options="periods" :value="period" track-by="id" label="name" :showLabels="false">
                  <template slot="caret">
                    <div>
                      <div class="multiselect__select">
                        <span>
                          <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                            <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </template>
                </MultiSelect>
              </div>
            </div>
          </div>
              <div class="md:flex gap-4">
                <div class="flex md:w-1/6">
                  <div class="w-full md:mb-2">
                    <label for="factory" class="label-control md:py-3">Factory <span class="text-red-600">*</span></label>
                  </div>
                </div>
                <div class="flex md:w-5/6">
                  <div class="w-full mb-2">
                    <MultiSelect id="factory" v-model="factory" :options="factories" :value="factory" track-by="id" label="name" :showLabels="false">
                      <template slot="caret">
                        <div>
                          <div class="multiselect__select">
                            <span>
                              <svg class="text-gray-500 mt-2 ml-2 w-5 h-5 fill-current" viewBox="0 0 24 24">
                                <path d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"/>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </template>
                    </MultiSelect>
                  </div>
                </div>
              </div>          
        </div>           
      </div>
      <!-- CALL PRODUCT RECIPE -->
      <TransactionDetail class="mb-10" />

      <!-- SUMMARY DATA SECTION -->
      <div class="md:flex gap-6">
        <div class="md:w-1/2 mb-4">
          <ValidationProvider name="Remarks" rules="" v-slot="{ errors }">
            <label for="remarks" class="label-control">Remarks</label>
            <textarea id="remarks" v-model="remarks" class="form-control" rows="3"></textarea>
            <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
          </ValidationProvider>
          <div v-if="error.remarks" class="text-sm text-red-600"><span>{{ error.remarks[0] }}</span></div>  
        </div>
        <div class="md:w-1/2 mb-4">
        </div>
      </div>
      <div class="md:flex gap-4 mt-10 mb-2">
        <button :disabled="isLoading" type="button" @click="goBack()" class="btn btn--secondary" alt="Back" title="Back">
          <BackIcon>
            <span class="ml-2 md:block hidden">Back</span>
          </BackIcon>              
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import format from '@/helpers/formatNumber'
import seasonServices from '@/services/season/seasonServices'
import factoryServices from '@/services/factory/factoryServices'
import orderServices from '@/services/order/orderServices'
import BackIcon from '../../icons/BackIcon'
import DateRange from '../../icons/DateRange'
import TransactionDetail from './Detail'

export default {
  name: 'ShowOrder',
  components: {
    BackIcon,
    DateRange,
    TransactionDetail
  },  
  data(){
    return {
      orderNumber: '',
      orderType: '',
      orderTypes: [
        { id: 1, name: 'RETAIL'},
        { id: 2, name: 'WHOLESALE'},
      ],
      season: '',
      seasons: [],
      factory: '',
      factories: [],            
      deliveryDateProduction: '',
      customerDeliveryDateFrom: '',
      customerDeliveryDateTo: '',
      period: '',
      periods: [
        { 'id': 1, 'name': 'Period 1' }, { 'id': 2, 'name': 'Period 2' }, { 'id': 3, 'name': 'Period 3' },
        { 'id': 4, 'name': 'Period 4' }, { 'id': 5, 'name': 'Period 5' }, { 'id': 6, 'name': 'Period 6' }
      ],      
      masks: {
        input: 'YYYY-MM-DD',
      },
      attrs: [
        {
          key: 'today',
          highlight: 'red',
          dates: new Date(),
        },
      ],      
      remarks: '',
      status: '',
      statusText: '',
      customerName: '',
      country: '',
      record: [],
      details: [],
      error: [],
      isLoading: false,
      activeTab: '1',
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async fetchSeasonData() {
      try {
        const response = await seasonServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.seasons = []
          records.forEach(element => {
            this.seasons.push({
              'id': element.id,
              'name': element.season_name
            })
          });          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchFactoryData() {
      try {
        const response = await factoryServices.fetchDataOptions(null)
        if (response.data.status === 'success') {
          const records = response.data.data
          this.factories = []
          records.forEach(element => {
            this.factories.push({
              'id': element.id,
              'name': element.factory_name
            })
          });          
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)          
        }
      } catch (error) {
        console.log(error)
      }
    },        
    async fetchData(){
      try {
        const id = this.$route.params.id
        const response = await orderServices.fetchById(id)
        if (response.data.status === 'success') {
          this.record = response.data.data
          const dataType = [ this.record.type ]
          const type = this.orderTypes.filter(item => dataType.includes(item.id))
          const dataPeriod = [ this.record.period ]
          const period = this.periods.filter(item => dataPeriod.includes(item.id))

          this.customerName = this.record.customer_name
          this.country = this.record.country
          this.orderNumber = this.record.order_no
          this.orderType = { id: type[0].id, name: type[0].name }
          this.season = { id: this.record.season_id, name: this.record.season.season_name }
          this.factory = { id: this.record.factory_id, name: this.record.factory.factory_name }
          this.deliveryDateProduction = this.record.delivery_date_production
          this.customerDeliveryDateFrom = this.record.delivery_date_from
          this.customerDeliveryDateTo = this.record.delivery_date_to
          this.period = { id: period[0].id, name: period[0].name }
          this.remarks = this.record.remarks
          this.status = this.record.status
          this.statusText = this.record.status_text
        } else {
          /* THROW ERROR MESSAGES */
          this.$toasted.error(response.data.message, this.toastedOptions)            
        }
      } catch (error) {
        console.log(error)
      }
    },
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    },
    convertToEuro(value) {
      let convert = this.unformatNumber(value) / this.unformatNumber(this.change_rate)
      convert = this.formatNumber(this.toFixed(convert, 2))

      return convert
    },
    convertToRupiah(value) {
      let convert = this.unformatNumber(value) * this.unformatNumber(this.change_rate)
      convert = this.formatNumber(this.toFixed(convert, 2))

      return convert
    },
    goBack() {
      window.history.length > 1 ? this.$router.back() : this.$router.push('/orders')
    }      
  },
  created(){
    this.fetchSeasonData()
    this.fetchFactoryData()
    this.fetchData()
  },
}
</script>