import ListSeason from '../components/feature/season/List'
import CreateSeason from '../components/feature/season/Create'
import EditSeason from '../components/feature/season/Edit'
import ShowSeason from '../components/feature/season/Show'

const routes = [
  {
    path: '/seasons',
    name: 'season',
    component: ListSeason,
    meta: {
      title: 'Seasons',
      requiresAuth: true
    }    
  },
  {
    path: '/seasons/create',
    name: 'seasonCreate',
    component: CreateSeason,
    meta: {
      title: 'Create Season',
      requiresAuth: true
    }    
  },
  {
    path: '/seasons/:id/edit',
    name: 'seasonEdit',
    component: EditSeason,
    meta: {
      title: 'Edit Season',
      requiresAuth: true
    }    
  },
  {
    path: '/seasons/:id',
    name: 'seasonShow',
    component: ShowSeason,
    meta: {
      title: 'Show Season',
      requiresAuth: true
    }    
  }  
]

export default routes