<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="bg-white shadow-lg rounded-lg" @submit.prevent="handleSubmit(create)">
        <div class="p-10">
          <div class="pb-2 mb-4 border-b border-gray-200">
            <h2 class="text-gray-800 text-2xl font-medium">Create Rate</h2>
          </div>
          <div class="md:flex gap-6">
            <div class="md:w-1/2 mb-4">
              <ValidationProvider name="Currency" rules="required" v-slot="{ errors }">
                <label for="currency" class="label-control">Currency <span class="text-red-600">*</span></label>
                <input id="currency" ref="currency" type="text" v-model="currency" class="form-control">
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.currency" class="text-sm text-red-600"><span>{{ error.currency[0] }}</span></div>
            </div>
            <div class="md:w-1/2 mb-4">
              <ValidationProvider name="Symbol" rules="required" v-slot="{ errors }">
                <label for="symbol" class="label-control">Symbol <span class="text-red-600">*</span></label>
                <input id="symbol" type="text" v-model="symbol" class="form-control">
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.symbol" class="text-sm text-red-600"><span>{{ error.symbol[0] }}</span></div>
            </div>
          </div>
          <div class="md:flex gap-6">
            <div class="md:w-1/2 mb-4">
              <ValidationProvider name="Rate" rules="required" v-slot="{ errors }">
                <label for="rate" class="label-control">Rate <span class="text-red-600">*</span></label>
                <input id="rate" type="text" v-model="rate" class="form-control" @input="handleInputRate">
                <span class="text-sm text-red-600 w-full">{{ errors[0] }}</span>           
              </ValidationProvider>
              <div v-if="error.rate" class="text-sm text-red-600"><span>{{ error.rate[0] }}</span></div>
            </div>
            <div class="md:w-1/2 mb-4"></div>
          </div>   
          <div class="md:flex gap-4 mt-4 mb-2">
            <button :disabled="isLoading" type="submit" class="btn btn--primary" alt="Save" title="Save">
              <span v-if="isLoading" class="block mx-5">
                <Loading />
              </span>
              <SaveIcon v-else>
                <span class="ml-2 md:block hidden">Save</span>
              </SaveIcon>
            </button>
            <button :disabled="isLoading" type="button" @click="goBack()" class="btn btn--secondary" alt="Back" title="Back">
              <BackIcon>
                <span class="ml-2 md:block hidden">Back</span>
              </BackIcon>              
            </button>
          </div>                                                  
        </div>
      </form>      
    </ValidationObserver>
  </div>
</template>

<script>
import format from '@/helpers/formatNumber'
import rateServices from '@/services/rate/rateServices'
import SaveIcon from '../../icons/SaveIcon'
import BackIcon from '../../icons/BackIcon'
import Loading from '../../icons/Loading'

export default {
  name: 'CreateRate',
  components: {
    SaveIcon,
    BackIcon,
    Loading
  },
  data(){
    return {
      currency: '',
      symbol: '',
      rate: '',
      error: [],
      isLoading: false,
      toastedOptions: {
        position: "top-right", 
        duration : 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }  
      }      
    }
  },
  methods: {
    async create(){
      try {
        this.isLoading = true       
        const payload = {
          currency: this.currency,
          symbol: this.symbol,
          rate: this.unformatNumber(this.rate)
        }
        const response = await rateServices.create(payload)
        if (response.data.status === 'success') {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false
                    
          /* CLEAR INPUT FORM */
          this.clearForm()

          /* EMPTY ERRORS VARIABLE */
          this.error = []
          
          /* SUCCESS MESSAGES */
          this.$toasted.success(response.data.message, this.toastedOptions)

          /* SET FOCUS ON SUPPLIER NAME INPUT */
          this.$refs.currency.focus()
        } else {
          /* SET LOADING STATE IS FALSE */
          this.isLoading = false

          /* EMPTY ERRORS VARIABLE */
          this.error = []

          /* STORE RESPONSE MESSAGE TO A VARIABLE */
          let responseReturn = response.data.message

          /* IF RESPONSE HAS OBJECT, STORE RESPONSE TO ERRORS VARIABLE */
          if (responseReturn.currency || responseReturn.symbol || responseReturn.rate) {
              this.error = response.data.message

          /* ELSE, THROW ERROR MESSAGES */
          } else {
            this.$toasted.error(response.data.message, this.toastedOptions)
          }          
        }
      } catch (error) {
        /* SET LOADING STATE IS FALSE */
        this.isLoading = false

        /* THROW ERROR MESSAGES */
        this.$toasted.error(error, this.toastedOptions)        
      }
    },
    formatNumber(num) {
      let result = format.formatNumber(num)
      return result
    },
    unformatNumber(num) {
      let result = format.unformatNumber(num)
      return result
    },
    toFixed(num, digit) {
      let result = format.toFixed(num, digit)
      return result
    },
    handleInputRate(event) {
      this.rate = this.formatNumber(event.target.value)
    },        
    clearForm(){
      this.currency = ''
      this.symbol = ''
      this.rate = ''
    },
    goBack() {
      window.history.length > 1 ? this.$router.back() : this.$router.push('/rates')
    }        
  }
}
</script>