import ListUnit from '../components/feature/unit/List'
import CreateUnit from '../components/feature/unit/Create'
import EditUnit from '../components/feature/unit/Edit'
import ShowUnit from '../components/feature/unit/Show'

const routes = [
  {
    path: '/units',
    name: 'unit',
    component: ListUnit,
    meta: {
      title: 'Units',
      requiresAuth: true
    }    
  },
  {
    path: '/units/create',
    name: 'unitCreate',
    component: CreateUnit,
    meta: {
      title: 'Create Unit',
      requiresAuth: true
    }    
  },
  {
    path: '/units/:id/edit',
    name: 'unitEdit',
    component: EditUnit,
    meta: {
      title: 'Edit Unit',
      requiresAuth: true
    }    
  },
  {
    path: '/units/:id',
    name: 'unitShow',
    component: ShowUnit,
    meta: {
      title: 'Show Unit',
      requiresAuth: true
    }    
  }  
]

export default routes